import { createSlice } from "@reduxjs/toolkit";

const initialState = null;


export const CategoriesData = createSlice({
    name: "CategoriesData",
    initialState,
    reducers: {
        setCategoriesData: (state, action) => {
            return action.payload
        }
    }

})

export default CategoriesData.reducer;
export const CategoriesDataActions = CategoriesData.actions;