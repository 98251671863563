import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Components :
import { BsCreditCard2Front, BsCalendar4Event } from "react-icons/bs";
import creditCard from "../../../Assets/Images/creditcard.png";
import { TfiLock } from "react-icons/tfi";
import { RiVisaFill, RiMastercardFill } from "react-icons/ri"
import { SiAmericanexpress } from "react-icons/si"
import { FaCcDinersClub, FaCcDiscover } from "react-icons/fa"


// APIs :
import { PostDonationAPI, PostTransactionAPI } from "../../../API/transaction";
// Redux :
import { useSelector } from "react-redux";
// Helpers :
import { toast } from "react-toastify";
import { getCreditCardNameByNumber } from 'creditcard.js';
import { Socket } from "socket.io-client";
import PrimaryButton from "Components/Atoms/PrimaryButton/PrimaryButton";

const DonationPayment = () => {

    const Location = useLocation();
    const Navigate = useNavigate()

    const amount = Location?.state?.amount;
    const shippingDetails = Location?.state?.shippingDetails;


    const initialValues = {
        cardNumber: "",
        expMonth: "",
        expYear: "",
        cvc: "",
    };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({
        cardNumber: null,
        expMonth: null,
        expYear: null,
        cvc: null,
    });
    const [loading, setLoading] = useState(false)


    const handleCardNumberInput = (e) => {
        const { value } = e.target;

        if (value.length > 16) {
            e.target.value = value.slice(0, 16); // Truncate the input value to 16 digits
        }
    };

    const handleMonthInput = (e) => {
        const { value } = e.target;

        if (value.length > 2) {
            e.target.value = value.slice(0, 2); // Truncate the input value to 2 digits
        }
    };

    const handleYearInput = (e) => {
        const { value } = e.target;

        if (value.length > 4) {
            e.target.value = value.slice(0, 4); // Truncate the input value to 4 digits
        }
    };

    const handleCvcInput = (e) => {
        const { value } = e.target;

        if (value.length > 3) {
            e.target.value = value.slice(0, 3); // Truncate the input value to 3 digits
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        const numericRegex = /^\d+$/; // Regex pattern to match only numbers

        switch (name) {
            case "cardNumber":
                if (value.length <= 0) {
                    setFormErrors({
                        ...formErrors,
                        cardNumber: "Card number is required.",
                    });
                }
                else if (!numericRegex.test(value)) {
                    setFormErrors({
                        ...formErrors,
                        cardNumber: "Only numbers are allowed for card number.",
                    });
                }
                else if (value.length > 16) {
                    setFormErrors({
                        ...formErrors,
                        cardNumber: "Card number should not exceed 16 digits.",
                    });
                } else {
                    setFormErrors({
                        ...formErrors,
                        cardNumber: null,
                    });
                }
                break;

            case "expMonth":
                if (value.length <= 0) {
                    setFormErrors({
                        ...formErrors,
                        expMonth: "Expiry Month is required.",
                    });
                }
                else if (!numericRegex.test(value)) {
                    setFormErrors({
                        ...formErrors,
                        expMonth: "Only numbers are allowed for expiry month.",
                    });
                }
                else if (value.length > 2) {
                    setFormErrors({
                        ...formErrors,
                        expMonth: "Expiry month should be 2 digits.",
                    });
                } else {
                    setFormErrors({
                        ...formErrors,
                        expMonth: null,
                    });
                }
                break;

            case "expYear":
                if (value.length <= 0) {
                    setFormErrors({
                        ...formErrors,
                        expYear: "Year is required.",
                    });
                }
                else if (!numericRegex.test(value)) {
                    setFormErrors({
                        ...formErrors,
                        expYear: "Only numbers are allowed for year.",
                    });
                }
                else if (value.length > 4) {
                    setFormErrors({
                        ...formErrors,
                        expYear: "Year should be 4 digits.",
                    });
                } else {
                    setFormErrors({
                        ...formErrors,
                        expYear: null,
                    });
                }
                break;

            case "cvc":
                if (value.length <= 0) {
                    setFormErrors({
                        ...formErrors,
                        cvc: "CVC is required.",
                    });
                }
                else if (!numericRegex.test(value)) {
                    setFormErrors({
                        ...formErrors,
                        cvc: "Only numbers are allowed for CVC.",
                    });
                }
                else if (value.length > 3) {
                    setFormErrors({
                        ...formErrors,
                        cvc: "CVC should be 3 digits.",
                    });
                } else {
                    setFormErrors({
                        ...formErrors,
                        cvc: null,
                    });
                }
                break;

            default:
                break;
        }

        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleConfirmPayment = async (e) => {
        setLoading(true)
        if (
            !formValues.cardNumber ||
            !formValues.expMonth ||
            !formValues.expYear ||
            !formValues.cvc
        ) {
            setFormErrors({
                cardNumber: formValues.cardNumber ? null : "Card number is required.",
                expMonth: formValues.expMonth ? null : "Expiry Month is required.",
                expYear: formValues.expYear ? null : "Year is required.",
                cvc: formValues.cvc ? null : "CVC is required.",
            });
            toast?.error("Please fill in all the required fields.");
            setLoading(false)
            return;
        }
        const payload = {
            cardNumber: formValues?.cardNumber,
            expMonth: formValues?.expMonth,
            expYear: formValues?.expYear,
            cvc: formValues?.cvc,
            shippingDetails,
            amount
        }
        const res = await PostDonationAPI(payload)
        if (res?.error != null) {
            toast?.error(res.error.message);
        } else {
            toast?.success(res.data.message);
            setTimeout(() => {
                window.location.href = "/donation"
            }, 500);
        }
        setLoading(false)
    };


    const validateCardName = (number) => {
        let name = getCreditCardNameByNumber(number)
        if (name == "Visa") {
            return <RiVisaFill className="h-6 w-6 text-gray-400" />
        }
        else if (name == "Mastercard") {
            return <RiMastercardFill className="h-6 w-6 text-gray-400" />
        }
        else if (name == "American Express") {
            return <SiAmericanexpress className="h-6 w-6 text-gray-400" />
        }
        else if (name == "Discover") {
            return <FaCcDiscover className="h-6 w-6 text-gray-400" />
        }
        else if (name == "Diners") {
            return <FaCcDinersClub className="h-6 w-6 text-gray-400" />
        }
        else {
            return <BsCreditCard2Front className="h-6 w-6 text-gray-400" />
        }
    }

    useEffect(() => {
        if (!amount) return Navigate("/donation");
    }, [])
    return (
        <>
            <section className="paymentSec DBlock pt-16 px-6">
                <div className="max-w-7xl mx-auto py-20">
                    <div className="grid grid-cols-1 lg:grid-cols-2 py-16 gap-8">
                        <div>
                            <h1 className="text-2xl font-bold text-black py-8">
                                Select a payment method:
                            </h1>
                            <div className="flex justify-between">

                                <h1 className="text-sm sm:text-lg font-bold text-black flex items-center">  <span className="w-3 h-3 sm:w-5 sm:h-5 rounded-full bg-yellow flex items-center justify-center mr-2">
                                    <span className="w-2 h-2 rounded-full bg-white"></span>
                                </span>Bank Cards</h1>
                                <img className="w-32 sm:w-auto" src={creditCard} alt="credit card" />
                            </div>
                            <div className="max-w-7xl grid grid-cols-1 md:grid-cols-2  gap-4 sm:gap-10 px-0 sm:px-7 lg:px-1 py-12">
                                <div className="col-span-2 block w-full">
                                    <div className="relative">
                                        <span className="absolute top-6 right-0 pr-10 flex items-center">
                                            {/* <BsCreditCard2Front className="h-5 w-5 text-gray-400" /> */}
                                            {validateCardName(formValues?.cardNumber)}
                                        </span>
                                        <input
                                            required
                                            type="text"
                                            onChange={handleFormChange}
                                            onInput={handleCardNumberInput}
                                            name="cardNumber"
                                            id="cardNumber"
                                            placeholder="4242424242424242"
                                            className="block w-full rounded-md bg-white shadow border-4 border-transparent px-3 py-6 pl-5"
                                        />
                                        {formErrors.cardNumber && (
                                            <p className=" h-5 pt-2 pb-7 text-sm text-red-500">
                                                {formErrors.cardNumber}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-span-2 md:col-span-1">
                                    <div className="relative">
                                        <span className="absolute top-6 right-0 pr-10 flex items-center">
                                            <BsCalendar4Event className="h-5 w-5 text-gray-400" />
                                        </span>
                                        <input
                                            required
                                            type="text"
                                            name="expMonth"
                                            onInput={handleMonthInput}
                                            onChange={handleFormChange}
                                            id="expiryMonth"
                                            placeholder="08"
                                            autoComplete="expiryMonth"
                                            className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-6 pl-5"
                                        />
                                        {formErrors.expMonth && (
                                            <p className=" h-5 pt-2 pb-7 text-sm text-red-500">
                                                {formErrors.expMonth}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-span-2 md:col-span-1">
                                    <div className="relative">
                                        <span className="absolute top-6 right-0 pr-10 flex items-center">
                                            <BsCalendar4Event className="h-5 w-5 text-gray-400" />
                                        </span>
                                        <input
                                            required
                                            type="text"
                                            name="expYear"
                                            onInput={handleYearInput}
                                            onChange={handleFormChange}
                                            id="expiryYear"
                                            placeholder="2026"
                                            autoComplete="expiryYear"
                                            className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-6 pl-5"
                                        />
                                        {formErrors.expYear && (
                                            <p className=" h-5 pt-2 pb-7 text-sm text-red-500">
                                                {formErrors.expYear}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-span-2 md:col-span-1">
                                    <div className="relative">
                                        <span className="absolute top-6 right-0 pr-10 flex items-center">
                                            <TfiLock className="h-5 w-5 text-gray-400" />
                                        </span>
                                        <input
                                            required
                                            type="text"
                                            onInput={handleCvcInput}
                                            onChange={handleFormChange}
                                            name="cvc"
                                            id="cvc"
                                            placeholder="586"
                                            autoComplete="cvc"
                                            className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-6 pl-5"
                                        />
                                        {formErrors.cvc && (
                                            <p className=" h-5 pt-2 pb-7 text-sm text-red-500">
                                                {formErrors.cvc}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="col-span-2 block w-full text-center">
                                    <p className="text-xs text-gray-400">
                                        By clicking GIVE NOW!, I agree to the Terms of Service and Privacy Policy
                                    </p>
                                </div>
                                <div className="col-span-2 w-full justify-center flex">
                                    <PrimaryButton primaryFun={handleConfirmPayment} loading={loading} heading="Give Now" />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center lg:justify-end items-center">
                            <div
                                style={{ width: "30rem" }}
                                className=" max-w-lg h-max block  rounded-md bg-white shadow  border-4 border-transparent px-10 py-7"
                            >
                                {/* <div className="flex justify-between">
                                    <p className="text-md text-gray-500">Subtotal</p>
                                    <p className="text-md text-gray-500">${totalPrice}</p>
                                </div>
                                <div className="flex justify-between py-2">
                                    <p className="text-md text-gray-500">Service Charges</p>
                                    <p className="text-md text-gray-500">${parseFloat(charges).toFixed(1)}</p>
                                </div> */}

                                {/* <div className="flex justify-between py-2">
                                    <p className="text-lg text-gray-500">Your currently selected donation amount is One-time donation</p>
                                </div>

                                <div className="border-y border-gray-300 my-3"></div> */}
                                <div className="flex justify-between my-5">
                                    <p className="text-md text-gray-500 text-xl font-bold">Total</p>
                                    <p className=" text-gray-600 text-xl font-bold">${parseFloat(amount).toFixed(1)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DonationPayment