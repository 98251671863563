import React from 'react'
import './PrimaryButton2.scss'

const PrimaryButton2 = (props) => {
  return (
    <>
      <div className='primary-button2'>
        <button className="pri-btn" onClick={props.primaryFun}>
          <span className='click'>Click!</span><span>{props.heading}</span>
        </button>
      </div>

    </>
  )
}

export default PrimaryButton2
