import React from 'react'

import payment from '../../Assets/Images/payment.png'
import PrimaryHeading from '../../Components/Atoms/PrimaryHeading/PrimaryHeading'
import PrimaryButton from '../../Components/Atoms/PrimaryButton/PrimaryButton'

import './Payment.scss'





const Payment = () => {
    return (
        <div className='payment-container'>
            <div className="image">
                <img src={payment} alt="" />
            </div>
            <div className="payment-info">
                <div className="payment">
                    <PrimaryHeading heading="Payment Information" />
                    <div className="divider"></div>
                </div>
                <div className="payment">
                    <div className="order">Order Total : <span>$50</span></div>
                    <div className="divider"></div>
                </div>
                <div className="payment">
                    <div className="order">Card Number </div>
                    <div className="divider"></div>
                </div>
                <div className="expiry">
                    <div className='flex'>
                        <div className="label">Expires</div>
                        <div className="input">
                        <input className='first' type="number" placeholder='02' />
                        <input className='second' type="number" placeholder='07' />
                        </div>
                    </div>
                    <div className='flex'>
                        <div className="label">ccv</div>
                        <input className='ccv' type="number" placeholder='832' />
                    </div>
                </div>
                <div className="payment">
                    <div className="order">Name</div>
                    <div className='name-field'>
                        <input type="text" placeholder='Muhammad Ali' />
                        <PrimaryButton heading="PAY NOW" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Payment
