import { combineReducers } from "@reduxjs/toolkit";
import BlogDataSclice from "./Slice/Blogs";
import BookDataSclice from "./Slice/Books";
import CartDataSclice from "./Slice/Carts"
import TeachersData from "./Slice/TeacherData";
import UserData from "./Slice/Users"
import LoadingStates from "./Slice/Loading";
import ToggleSideBar from "./Slice/ToggleSideBar";
import CommissionData from "./Slice/Commission";
import CategoriesData from "./Slice/Category";
import TagsData from "./Slice/Tag";






export const rootReducer = combineReducers({
    BlogsData: BlogDataSclice,
    BooksData: BookDataSclice,
    CartsData: CartDataSclice,
    TeachersData: TeachersData,
    UserData: UserData,
    LoadingStates: LoadingStates,
    SideBar: ToggleSideBar,
    CommissionData: CommissionData,
    CategoriesData: CategoriesData,
    TagsData: TagsData,
})
