import blog1 from "../Assets/Images/img/blogsTemp.jpg";
import blog2 from "../Assets/Images/about-img.png";
import blog3 from "../Assets/Images/bookName.png";
import blog4 from "../Assets/Images/card-img.jpg";
import blog5 from "../Assets/Images/course-img.png";
import { BsStarFill } from "react-icons/bs";

export const prayerData = [
    {
      id: 1,
      cityName: 'Lahore',
      fajarTime:'4:25',
      zuharTime:'1:30',
      asarTime:'5:15',
      magribTime:'6:39',
      ishaTime:'8:15',
    },
    {
      id: 2,
      cityName: 'Karachi',
      fajarTime:'4:25',
      zuharTime:'1:30',
      asarTime:'5:15',
      magribTime:'6:39',
      ishaTime:'8:15',
    },
    {
      id: 3,
      cityName: 'Peshawar',
      fajarTime:'4:25',
      zuharTime:'1:30',
      asarTime:'5:15',
      magribTime:'6:39',
      ishaTime:'8:15',
    },
    {
      id: 4,
      cityName: 'Islamabad',
      fajarTime:'4:25',
      zuharTime:'1:30',
      asarTime:'5:15',
      magribTime:'6:39',
      ishaTime:'8:15',
    },
    {
      id: 5,
      cityName: 'Quetta',
      fajarTime:'4:25',
      zuharTime:'1:30',
      asarTime:'5:15',
      magribTime:'6:39',
      ishaTime:'8:15',},
    {
      id: 6,
      cityName: 'KPK',
      fajarTime:'4:25',
      zuharTime:'1:30',
      asarTime:'5:15',
      magribTime:'6:39',
      ishaTime:'8:15',
    },
    
  ];

export const cardData=[
  {
    key: 1,
    content: (
      <div className=" flex flex-col  justify-between bg-gray-50  py-3 px-2  shadow-md border-2 rounded-lg group">
        <div className="h-56">
          <img
            className="rounded-lg  w-full"
            src={blog1}
            alt="portfolio image"
          />
        </div>

        <div className="p-4 item-center text-center">
          <h2 className="title text-2xl font-bold normal-case text-black py-2">
            Sahih AL-Bukhari
          </h2>
          <ul className="flex items-center justify-center ">
            <li className="font-bold mr-2">5.0</li>
            {[1, 2, 3, 4, 5].map((stars, ind) => (
              <li className="mx-1" key={`starRating${ind}`}>
                <BsStarFill fill="#FFCC17" />
              </li>
            ))}
          </ul>

          <div className="py-2">
            <a href="/" rel="author">
              <h5 className="text-yellow text-center">
                By :Muhammad ibn Ismail al-Bukhari
              </h5>
            </a>
          </div>

          <p className="details">
            Prophet Lut (“Prophet Lot”) was born and raised by his uncle Prophet
            Ibrahim, may peace be upon him. Prophet Lut loved, respected and
            believed in Ibrahim’s message even when everyone ridiculed him.
          </p>
        </div>
      </div>
    ),
  },
  {
    key: 2,
    content: (
      <div className=" flex flex-col  justify-between bg-gray-50  py-3 px-2  shadow-md border-2 rounded-lg group">
        <div className="h-56">
          <img
            className="rounded-lg  w-full "
            src={blog2}
            alt="portfolio image"
          />
        </div>

        <div className="p-4 item-center text-center">
          <h2 className="title text-2xl font-bold normal-case text-black py-2">
            Sahih AL-Bukhari
          </h2>
          <ul className="flex items-center justify-center ">
            <li className="font-bold mr-2">5.0</li>
            {[1, 2, 3, 4, 5].map((stars, ind) => (
              <li className="mx-1" key={`starRating${ind}`}>
                <BsStarFill fill="#FFCC17" />
              </li>
            ))}
          </ul>

          <div className="py-2">
            <a href="/" rel="author">
              <h5 className="text-yellow text-center">
                By :Muhammad ibn Ismail al-Bukhari
              </h5>
            </a>
          </div>

          <p className="details">
            Prophet Lut (“Prophet Lot”) was born and raised by his uncle Prophet
            Ibrahim, may peace be upon him. Prophet Lut loved, respected and
            believed in Ibrahim’s message even when everyone ridiculed him.
          </p>
        </div>
      </div>
    ),
  },
  {
    key: 3,
    content: (
      <div className=" flex flex-col  justify-between bg-gray-50  py-3 px-2  shadow-md border-2 rounded-lg group">
      <div className="h-56">
        <img
          className="rounded-lg  w-full "
          src={blog3}
          alt="portfolio image"
        />
      </div>

      <div className="p-4 item-center text-center">
        <h2 className="title text-2xl font-bold normal-case text-black py-2">
          Sahih AL-Bukhari
        </h2>
        <ul className="flex items-center justify-center ">
          <li className="font-bold mr-2">5.0</li>
          {[1, 2, 3, 4, 5].map((stars, ind) => (
            <li className="mx-1" key={`starRating${ind}`}>
              <BsStarFill fill="#FFCC17" />
            </li>
          ))}
        </ul>

        <div className="py-2">
          <a href="/" rel="author">
            <h5 className="text-yellow text-center">
              By :Muhammad ibn Ismail al-Bukhari
            </h5>
          </a>
        </div>

        <p className="details">
          Prophet Lut (“Prophet Lot”) was born and raised by his uncle Prophet
          Ibrahim, may peace be upon him. Prophet Lut loved, respected and
          believed in Ibrahim’s message even when everyone ridiculed him.
        </p>
      </div>
    </div>
    ),
  },
  {
    key: 4,
    content: (
      <div className=" flex flex-col  justify-between bg-gray-50  py-3 px-2  shadow-md border-2 rounded-lg group">
        <div className="h-56">
          <img
            className="rounded-lg  w-full "
            src={blog4}
            alt="portfolio image"
          />
        </div>

        <div className="p-4 item-center text-center">
          <h2 className="title text-2xl font-bold normal-case text-black py-2">
            Sahih AL-Bukhari
          </h2>
          <ul className="flex items-center justify-center ">
            <li className="font-bold mr-2">5.0</li>
            {[1, 2, 3, 4, 5].map((stars, ind) => (
              <li className="mx-1" key={`starRating${ind}`}>
                <BsStarFill fill="#FFCC17" />
              </li>
            ))}
          </ul>

          <div className="py-2">
            <a href="/" rel="author">
              <h5 className="text-yellow text-center">
                By :Muhammad ibn Ismail al-Bukhari
              </h5>
            </a>
          </div>

          <p className="details">
            Prophet Lut (“Prophet Lot”) was born and raised by his uncle Prophet
            Ibrahim, may peace be upon him. Prophet Lut loved, respected and
            believed in Ibrahim’s message even when everyone ridiculed him.
          </p>
        </div>
      </div>
    ),
  },
  {
    key: 5,
    content: (
      <div className=" flex flex-col  justify-between bg-gray-50  py-3 px-2  shadow-md border-2 rounded-lg group">
        <div className="h-56">
          <img
            className="rounded-lg  w-full "
            src={blog5}
            alt="portfolio image"
          />
        </div>

        <div className="p-4 item-center text-center">
          <h2 className="title text-2xl font-bold normal-case text-black py-2">
            Sahih AL-Bukhari
          </h2>
          <ul className="flex items-center justify-center ">
            <li className="font-bold mr-2">5.0</li>
            {[1, 2, 3, 4, 5].map((stars, ind) => (
              <li className="mx-1" key={`starRating${ind}`}>
                <BsStarFill fill="#FFCC17" />
              </li>
            ))}
          </ul>

          <div className="py-2">
            <a href="/" rel="author">
              <h5 className="text-yellow text-center">
                By :Muhammad ibn Ismail al-Bukhari
              </h5>
            </a>
          </div>

          <p className="details">
            Prophet Lut (“Prophet Lot”) was born and raised by his uncle Prophet
            Ibrahim, may peace be upon him. Prophet Lut loved, respected and
            believed in Ibrahim’s message even when everyone ridiculed him.
          </p>
        </div>
      </div>
    ),
  },
]


export const languages = [
  { value: "Abkhazian" },
  { value: "Afar" },
  { value: "Afrikaans" },
  { value: "Akan" },
  { value: "Albanian" },
  { value: "Amharic" },
  { value: "Arabic" },
  { value: "Aragonese" },
  { value: "Armenian" },
  { value: "Assamese" },
  { value: "Avaric" },
  { value: "Avestan" },
  { value: "Aymara" },
  { value: "Azerbaijani" },
  { value: "Bambara" },
  { value: "Bashkir" },
  { value: "Basque" },
  { value: "Belarusian" },
  { value: "Bengali" },
  { value: "Bihari languages" },
  { value: "Bislama" },
  { value: "Bosnian" },
  { value: "Breton" },
  { value: "Bulgarian" },
  { value: "Burmese" },
  { value: "Catalan, Valencian" },
  { value: "Central Khmer" },
  { value: "Chamorro" },
  { value: "Chechen" },
  { value: "Chichewa, Chewa, Nyanja" },
  { value: "Chinese" },
  { value: "Church Slavonic, Old Bulgarian, Old Church Slavonic" },
  { value: "Chuvash" },
  { value: "Cornish" },
  { value: "Corsican" },
  { value: "Cree" },
  { value: "Croatian" },
  { value: "Czech" },
  { value: "Danish" },
  { value: "Divehi, Dhivehi, Maldivian" },
  { value: "Dutch, Flemish" },
  { value: "Dzongkha" },
  { value: "English" },
  { value: "Esperanto" },
  { value: "Estonian" },
  { value: "Ewe" },
  { value: "Faroese" },
  { value: "Fijian" },
  { value: "Finnish" },
  { value: "French" },
  { value: "Fulah" },
  { value: "Gaelic, Scottish Gaelic" },
  { value: "Galician" },
  { value: "Ganda" },
  { value: "Georgian" },
  { value: "German" },
  { value: "Gikuyu, Kikuyu" },
  { value: "Greek (Modern)" },
  { value: "Greenlandic, Kalaallisut" },
  { value: "Guarani" },
  { value: "Gujarati" },
  { value: "Haitian, Haitian Creole" },
  { value: "Hausa" },
  { value: "Hebrew" },
  { value: "Herero" },
  { value: "Hindi" },
  { value: "Hiri Motu" },
  { value: "Hungarian" },
  { value: "Icelandic" },
  { value: "Ido" },
  { value: "Igbo" },
  { value: "Indonesian" },
  { value: "Interlingua (International Auxiliary Language Association)" },
  { value: "Interlingue" },
  { value: "Inuktitut" },
  { value: "Inupiaq" },
  { value: "Irish" },
  { value: "Italian" },
  { value: "Japanese" },
  { value: "Javanese" },
  { value: "Kannada" },
  { value: "Kanuri" },
  { value: "Kashmiri" },
  { value: "Kazakh" },
  { value: "Kinyarwanda" },
  { value: "Komi" },
  { value: "Kongo" },
  { value: "Korean" },
  { value: "Kwanyama, Kuanyama" },
  { value: "Kurdish" },
  { value: "Kyrgyz" },
  { value: "Lao" },
  { value: "Latin" },
  { value: "Latvian" },
  { value: "Letzeburgesch, Luxembourgish" },
  { value: "Limburgish, Limburgan, Limburger" },
  { value: "Lingala" },
  { value: "Lithuanian" },
  { value: "Luba-Katanga" },
  { value: "Macedonian" },
  { value: "Malagasy" },
  { value: "Malay" },
  { value: "Malayalam" },
  { value: "Maltese" },
  { value: "Manx" },
  { value: "Maori" },
  { value: "Marathi" },
  { value: "Marshallese" },
  { value: "Moldovan, Moldavian, Romanian" },
  { value: "Mongolian" },
  { value: "Nauru" },
  { value: "Navajo, Navaho" },
  { value: "Northern Ndebele" },
  { value: "Ndonga" },
  { value: "Nepali" },
  { value: "Northern Sami" },
  { value: "Norwegian" },
  { value: "Norwegian Bokmål" },
  { value: "Norwegian Nynorsk" },
  { value: "Nuosu, Sichuan Yi" },
  { value: "Occitan (post 1500)" },
  { value: "Ojibwa" },
  { value: "Oriya" },
  { value: "Oromo" },
  { value: "Ossetian, Ossetic" },
  { value: "Pali" },
  { value: "Panjabi, Punjabi" },
  { value: "Pashto, Pushto" },
  { value: "Persian" },
  { value: "Polish" },
  { value: "Portuguese" },
  { value: "Quechua" },
  { value: "Romansh" },
  { value: "Rundi" },
  { value: "Russian" },
  { value: "Samoan" },
  { value: "Sango" },
  { value: "Sanskrit" },
  { value: "Sardinian" },
  { value: "Serbian" },
  { value: "Shona" },
  { value: "Sindhi" },
  { value: "Sinhala, Sinhalese" },
  { value: "Slovak" },
  { value: "Slovenian" },
  { value: "Somali" },
  { value: "Sotho, Southern" },
  { value: "South Ndebele" },
  { value: "Spanish, Castilian" },
  { value: "Sundanese" },
  { value: "Swahili" },
  { value: "Swati" },
  { value: "Swedish" },
  { value: "Tagalog" },
  { value: "Tahitian" },
  { value: "Tajik" },
  { value: "Tamil" },
  { value: "Tatar" },
  { value: "Telugu" },
  { value: "Thai" },
  { value: "Tibetan" },
  { value: "Tigrinya" },
  { value: "Tonga (Tonga Islands)" },
  { value: "Tsonga" },
  { value: "Tswana" },
  { value: "Turkish" },
  { value: "Turkmen" },
  { value: "Twi" },
  { value: "Uighur, Uyghur" },
  { value: "Ukrainian" },
  { value: "Urdu" },
  { value: "Uzbek" },
  { value: "Venda" },
  { value: "Vietnamese" },
  { value: "Volap_k" },
  { value: "Walloon" },
  { value: "Welsh" },
  { value: "Western Frisian" },
  { value: "Wolof" },
  { value: "Xhosa" },
  { value: "Yiddish" },
  { value: "Yoruba" },
  { value: "Zhuang, Chuang" },
  { value: "Zulu" },
];

export const timeZones = [
  { value: "Europe/Andorra" },
  { value: "Asia/Dubai" },
  { value: "Asia/Kabul" },
  { value: "Europe/Tirane" },
  { value: "Asia/Yerevan" },
  { value: "Antarctica/Casey" },
  { value: "Antarctica/Davis" },
  { value: "Antarctica/DumontDUrville" },
  { value: "Antarctica/Mawson" },
  { value: "Antarctica/Palmer" },
  { value: "Antarctica/Rothera" },
  { value: "Antarctica/Syowa" },
  { value: "Antarctica/Troll" },
  { value: "Antarctica/Vostok" },
  { value: "America/Argentina/Buenos_Aires" },
  { value: "America/Argentina/Cordoba" },
  { value: "America/Argentina/Salta" },
  { value: "America/Argentina/Jujuy" },
  { value: "America/Argentina/Tucuman" },
  { value: "America/Argentina/Catamarca" },
  { value: "America/Argentina/La_Rioja" },
  { value: "America/Argentina/San_Juan" },
  { value: "America/Argentina/Mendoza" },
  { value: "America/Argentina/San_Luis" },
  { value: "America/Argentina/Rio_Gallegos" },
  { value: "America/Argentina/Ushuaia" },
  { value: "Pacific/Pago_Pago" },
  { value: "Europe/Vienna" },
  { value: "Australia/Lord_Howe" },
  { value: "Antarctica/Macquarie" },
  { value: "Australia/Hobart" },
  { value: "Australia/Currie" },
  { value: "Australia/Melbourne" },
  { value: "Australia/Sydney" },
  { value: "Australia/Broken_Hill" },
  { value: "Australia/Brisbane" },
  { value: "Australia/Lindeman" },
  { value: "Australia/Adelaide" },
  { value: "Australia/Darwin" },
  { value: "Australia/Perth" },
  { value: "Australia/Eucla" },
  { value: "Asia/Baku" },
  { value: "America/Barbados" },
  { value: "Asia/Dhaka" },
  { value: "Europe/Brussels" },
  { value: "Europe/Sofia" },
  { value: "Atlantic/Bermuda" },
  { value: "Asia/Brunei" },
  { value: "America/La_Paz" },
  { value: "America/Noronha" },
  { value: "America/Belem" },
  { value: "America/Fortaleza" },
  { value: "America/Recife" },
  { value: "America/Araguaina" },
  { value: "America/Maceio" },
  { value: "America/Bahia" },
  { value: "America/Sao_Paulo" },
  { value: "America/Campo_Grande" },
  { value: "America/Cuiaba" },
  { value: "America/Santarem" },
  { value: "America/Porto_Velho" },
  { value: "America/Boa_Vista" },
  { value: "America/Manaus" },
  { value: "America/Eirunepe" },
  { value: "America/Rio_Branco" },
  { value: "America/Nassau" },
  { value: "Asia/Thimphu" },
  { value: "Europe/Minsk" },
  { value: "America/Belize" },
  { value: "America/St_Johns" },
  { value: "America/Halifax" },
  { value: "America/Glace_Bay" },
  { value: "America/Moncton" },
  { value: "America/Goose_Bay" },
  { value: "America/Blanc-Sablon" },
  { value: "America/Toronto" },
  { value: "America/Nipigon" },
  { value: "America/Thunder_Bay" },
  { value: "America/Iqaluit" },
  { value: "America/Pangnirtung" },
  { value: "America/Atikokan" },
  { value: "America/Winnipeg" },
  { value: "America/Rainy_River" },
  { value: "America/Resolute" },
  { value: "America/Rankin_Inlet" },
  { value: "America/Regina" },
  { value: "America/Swift_Current" },
  { value: "America/Edmonton" },
  { value: "America/Cambridge_Bay" },
  { value: "America/Yellowknife" },
  { value: "America/Inuvik" },
  { value: "America/Creston" },
  { value: "America/Dawson_Creek" },
  { value: "America/Fort_Nelson" },
  { value: "America/Vancouver" },
  { value: "America/Whitehorse" },
  { value: "America/Dawson" },
  { value: "Indian/Cocos" },
  { value: "Europe/Zurich" },
  { value: "Africa/Abidjan" },
  { value: "Pacific/Rarotonga" },
  { value: "America/Santiago" },
  { value: "America/Punta_Arenas" },
  { value: "Pacific/Easter" },
  { value: "Asia/Shanghai" },
  { value: "Asia/Urumqi" },
  { value: "America/Bogota" },
  { value: "America/Costa_Rica" },
  { value: "America/Havana" },
  { value: "Atlantic/Cape_Verde" },
  { value: "America/Curacao" },
  { value: "Indian/Christmas" },
  { value: "Asia/Nicosia" },
  { value: "Asia/Famagusta" },
  { value: "Europe/Prague" },
  { value: "Europe/Berlin" },
  { value: "Europe/Copenhagen" },
  { value: "America/Santo_Domingo" },
  { value: "Africa/Algiers" },
  { value: "America/Guayaquil" },
  { value: "Pacific/Galapagos" },
  { value: "Europe/Tallinn" },
  { value: "Africa/Cairo" },
  { value: "Africa/El_Aaiun" },
  { value: "Europe/Madrid" },
  { value: "Africa/Ceuta" },
  { value: "Atlantic/Canary" },
  { value: "Europe/Helsinki" },
  { value: "Pacific/Fiji" },
  { value: "Atlantic/Stanley" },
  { value: "Pacific/Chuuk" },
  { value: "Pacific/Pohnpei" },
  { value: "Pacific/Kosrae" },
  { value: "Atlantic/Faroe" },
  { value: "Europe/Paris" },
  { value: "Europe/London" },
  { value: "Asia/Tbilisi" },
  { value: "America/Cayenne" },
  { value: "Africa/Accra" },
  { value: "Europe/Gibraltar" },
  { value: "America/Godthab" },
  { value: "America/Danmarkshavn" },
  { value: "America/Scoresbysund" },
  { value: "America/Thule" },
  { value: "Europe/Athens" },
  { value: "Atlantic/South_Georgia" },
  { value: "America/Guatemala" },
  { value: "Pacific/Guam" },
  { value: "Africa/Bissau" },
  { value: "America/Guyana" },
  { value: "Asia/Hong_Kong" },
  { value: "America/Tegucigalpa" },
  { value: "America/Port-au-Prince" },
  { value: "Europe/Budapest" },
  { value: "Asia/Jakarta" },
  { value: "Asia/Pontianak" },
  { value: "Asia/Makassar" },
  { value: "Asia/Jayapura" },
  { value: "Europe/Dublin" },
  { value: "Asia/Jerusalem" },
  { value: "Asia/Kolkata" },
  { value: "Indian/Chagos" },
  { value: "Asia/Baghdad" },
  { value: "Asia/Tehran" },
  { value: "Atlantic/Reykjavik" },
  { value: "Europe/Rome" },
  { value: "America/Jamaica" },
  { value: "Asia/Amman" },
  { value: "Asia/Tokyo" },
  { value: "Africa/Nairobi" },
  { value: "Asia/Bishkek" },
  { value: "Pacific/Tarawa" },
  { value: "Pacific/Enderbury" },
  { value: "Pacific/Kiritimati" },
  { value: "Asia/Pyongyang" },
  { value: "Asia/Seoul" },
  { value: "Asia/Almaty" },
  { value: "Asia/Qyzylorda" },
  { value: "Asia/Qostanay" },
  { value: "Asia/Aqtobe" },
  { value: "Asia/Aqtau" },
  { value: "Asia/Atyrau" },
  { value: "Asia/Oral" },
  { value: "Asia/Beirut" },
  { value: "Asia/Colombo" },
  { value: "Africa/Monrovia" },
  { value: "Europe/Vilnius" },
  { value: "Europe/Luxembourg" },
  { value: "Europe/Riga" },
  { value: "Africa/Tripoli" },
  { value: "Africa/Casablanca" },
  { value: "Europe/Monaco" },
  { value: "Europe/Chisinau" },
  { value: "Pacific/Majuro" },
  { value: "Pacific/Kwajalein" },
  { value: "Asia/Yangon" },
  { value: "Asia/Ulaanbaatar" },
  { value: "Asia/Hovd" },
  { value: "Asia/Choibalsan" },
  { value: "Asia/Macau" },
  { value: "America/Martinique" },
  { value: "Europe/Malta" },
  { value: "Indian/Mauritius" },
  { value: "Indian/Maldives" },
  { value: "America/Mexico_City" },
  { value: "America/Cancun" },
  { value: "America/Merida" },
  { value: "America/Monterrey" },
  { value: "America/Matamoros" },
  { value: "America/Mazatlan" },
  { value: "America/Chihuahua" },
  { value: "America/Ojinaga" },
  { value: "America/Hermosillo" },
  { value: "America/Tijuana" },
  { value: "America/Bahia_Banderas" },
  { value: "Asia/Kuala_Lumpur" },
  { value: "Asia/Kuching" },
  { value: "Africa/Maputo" },
  { value: "Africa/Windhoek" },
  { value: "Pacific/Noumea" },
  { value: "Pacific/Norfolk" },
  { value: "Africa/Lagos" },
  { value: "America/Managua" },
  { value: "Europe/Amsterdam" },
  { value: "Europe/Oslo" },
  { value: "Asia/Kathmandu" },
  { value: "Pacific/Nauru" },
  { value: "Pacific/Niue" },
  { value: "Pacific/Auckland" },
  { value: "Pacific/Chatham" },
  { value: "America/Panama" },
  { value: "America/Lima" },
  { value: "Pacific/Tahiti" },
  { value: "Pacific/Marquesas" },
  { value: "Pacific/Gambier" },
  { value: "Pacific/Port_Moresby" },
  { value: "Pacific/Bougainville" },
  { value: "Asia/Manila" },
  { value: "Asia/Karachi" },
  { value: "Europe/Warsaw" },
  { value: "America/Miquelon" },
  { value: "Pacific/Pitcairn" },
  { value: "America/Puerto_Rico" },
  { value: "Asia/Gaza" },
  { value: "Asia/Hebron" },
  { value: "Europe/Lisbon" },
  { value: "Atlantic/Madeira" },
  { value: "Atlantic/Azores" },
  { value: "Pacific/Palau" },
  { value: "America/Asuncion" },
  { value: "Asia/Qatar" },
  { value: "Indian/Reunion" },
  { value: "Europe/Bucharest" },
  { value: "Europe/Belgrade" },
  { value: "Europe/Kaliningrad" },
  { value: "Europe/Moscow" },
  { value: "Europe/Simferopol" },
  { value: "Europe/Kirov" },
  { value: "Europe/Astrakhan" },
  { value: "Europe/Volgograd" },
  { value: "Europe/Saratov" },
  { value: "Europe/Ulyanovsk" },
  { value: "Europe/Samara" },
  { value: "Asia/Yekaterinburg" },
  { value: "Asia/Omsk" },
  { value: "Asia/Novosibirsk" },
  { value: "Asia/Barnaul" },
  { value: "Asia/Tomsk" },
  { value: "Asia/Novokuznetsk" },
  { value: "Asia/Krasnoyarsk" },
  { value: "Asia/Irkutsk" },
  { value: "Asia/Chita" },
  { value: "Asia/Yakutsk" },
  { value: "Asia/Khandyga" },
  { value: "Asia/Vladivostok" },
  { value: "Asia/Ust-Nera" },
  { value: "Asia/Magadan" },
  { value: "Asia/Sakhalin" },
  { value: "Asia/Srednekolymsk" },
  { value: "Asia/Kamchatka" },
  { value: "Asia/Anadyr" },
  { value: "Asia/Riyadh" },
  { value: "Pacific/Guadalcanal" },
  { value: "Indian/Mahe" },
  { value: "Africa/Khartoum" },
  { value: "Europe/Stockholm" },
  { value: "Asia/Singapore" },
  { value: "America/Paramaribo" },
  { value: "Africa/Juba" },
  { value: "Africa/Sao_Tome" },
  { value: "America/El_Salvador" },
  { value: "Asia/Damascus" },
  { value: "America/Grand_Turk" },
  { value: "Africa/Ndjamena" },
  { value: "Indian/Kerguelen" },
  { value: "Asia/Bangkok" },
  { value: "Asia/Dushanbe" },
  { value: "Pacific/Fakaofo" },
  { value: "Asia/Dili" },
  { value: "Asia/Ashgabat" },
  { value: "Africa/Tunis" },
  { value: "Pacific/Tongatapu" },
  { value: "Europe/Istanbul" },
  { value: "America/Port_of_Spain" },
  { value: "Pacific/Funafuti" },
  { value: "Asia/Taipei" },
  { value: "Europe/Kiev" },
  { value: "Europe/Uzhgorod" },
  { value: "Europe/Zaporozhye" },
  { value: "Pacific/Wake" },
  { value: "America/New_York" },
  { value: "America/Detroit" },
  { value: "America/Kentucky/Louisville" },
  { value: "America/Kentucky/Monticello" },
  { value: "America/Indiana/Indianapolis" },
  { value: "America/Indiana/Vincennes" },
  { value: "America/Indiana/Winamac" },
  { value: "America/Indiana/Marengo" },
  { value: "America/Indiana/Petersburg" },
  { value: "America/Indiana/Vevay" },
  { value: "America/Chicago" },
  { value: "America/Indiana/Tell_City" },
  { value: "America/Indiana/Knox" },
  { value: "America/Menominee" },
  { value: "America/North_Dakota/Center" },
  { value: "America/North_Dakota/New_Salem" },
  { value: "America/North_Dakota/Beulah" },
  { value: "America/Denver" },
  { value: "America/Boise" },
  { value: "America/Phoenix" },
  { value: "America/Los_Angeles" },
  { value: "America/Anchorage" },
  { value: "America/Juneau" },
  { value: "America/Sitka" },
  { value: "America/Metlakatla" },
  { value: "America/Yakutat" },
  { value: "America/Nome" },
  { value: "America/Adak" },
  { value: "Pacific/Honolulu" },
  { value: "America/Montevideo" },
  { value: "Asia/Samarkand" },
  { value: "Asia/Tashkent" },
  { value: "America/Caracas" },
  { value: "Asia/Ho_Chi_Minh" },
  { value: "Pacific/Efate" },
  { value: "Pacific/Wallis" },
  { value: "Pacific/Apia" },
  { value: "Africa/Johannesburg" },
];