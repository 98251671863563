import React, { useState, useEffect } from 'react';
import './PrayerTime.scss';
import axios from 'axios';

import PrimaryHeading from '../../../Components/Atoms/PrimaryHeading/PrimaryHeading';
import Moon from '../../../Assets/Images/moon.png';
import LeftStar from '../../../Assets/Images/leftstar.png';
import RightStar from '../../../Assets/Images/rightstar.png';
import PreLoader from 'Components/Atoms/PreLoader/PreLoader';

function formatDate(date) {

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat']
  const day = dayNames[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${dayOfMonth} ${month}, ${year}`;
}

const defaultSaudiArabiaLocation = {
  latitude: 24.7136,
  longitude: 46.6753,
};

const PrayerTime = () => {
  const [timings, setTimings] = useState([]);
  const [data, setData] = useState([]);
  const [userLocation, setUserLocation] = useState(null);

  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const today = new Date().getDate();

  const todayDate = `${today}-${month}-${year}`;

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          if (error.code === 1) {
            setUserLocation(defaultSaudiArabiaLocation);
          } else {
            console.log('Error getting geolocation:', error);
          }
        }
      );
    } else {
      setUserLocation(defaultSaudiArabiaLocation);
    }
  }, []);

  // useEffect(() => {
  //   if (userLocation) {
  //     fetchPrayerTimings();
  //   }
  // }, [userLocation,timings]);

  useEffect(() => {
    if (userLocation) {
      fetchPrayerTimings(); // Initial fetch
      const interval = setInterval(fetchPrayerTimings, 5 * 60 * 1000); // Fetch every 5 minutes

      return () => {
        clearInterval(interval); // Cleanup the interval when the component unmounts
      };
    }
  }, [userLocation]);

  const fetchPrayerTimings = async () => {
    try {
      const response = await axios.get(
        `https://api.aladhan.com/v1/timings/${todayDate}?latitude=${userLocation.latitude}&longitude=${userLocation.longitude}&method=2`
      );
      const currentDayTimings = response.data.data;
      setTimings(currentDayTimings.timings);
      setData(currentDayTimings);
    } catch (error) {
      console.log('Error fetching prayer timings:', error);
    }
  };

  const date = new Date();

  return (
    <>
      <div className='prayertime-container'>
        <div className="container">
          <div className="prayer-section">
            <div className="prayer">
              <PrimaryHeading heading="Prayer Times" />
              <div className="para">
                Prayer times in {data?.meta?.timezone}
              </div>
              <a className='date'>{formatDate(date)}</a>
            </div>

            <div className="timer">
              {timings && (
                <>
                  {Object.entries(timings).map(([prayer, time]) => (
                    <div className="nimaz" key={prayer}>
                      {prayer} <span>{time}</span>
                    </div>
                  ))}
                </>
              )}
            </div>

            <div className="stars-img1 hidden lg:block">
              <img src={Moon} alt="" />
            </div>
            <div className="stars-img2 hidden lg:block">
              <img src={RightStar} alt="" />
            </div>
            <div className="stars-img3 hidden lg:block">
              <img src={LeftStar} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrayerTime;

