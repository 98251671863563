import React from 'react'

import PrimaryHeading from '../../../Components/Atoms/PrimaryHeading/PrimaryHeading'
import Quran from '../../../Assets/Images/about-course-logo.png'
import Sun from '../../../Assets/Images/sun.png'
import Learning from '../../../Assets/Images/learning.png'
import Allah from '../../../Assets/Images/allah.png'

import './AboutCourses.scss'





const AboutCourses = () => {
    return (
        <div className='aboutCourses-container'>
            <div className="container">
                <div className="course-section">
                    <div className="header">
                        <div className="event">Top Courses</div>
                        <PrimaryHeading heading="Our Popular Courses" />
                    </div>
                    <div className="courses">
                        <div className="course">
                            <div className="logo">
                                <img src={Quran} alt="" />
                            </div>
                            <div className="heading">Quran Memorization </div>
                            <div className="para">This course intends to help the students memorize Holy Quran. Students who wish to memorize a part or all the Quran.</div>
                        </div>
                        <div className="course">
                            <div className="logo">
                                <img src={Sun} alt="" />
                            </div>
                            <div className="heading">Junior Preschool</div>
                            <div className="para">This course intends to help the students memorize Holy Quran. Students who wish to memorize a part or all the Quran.</div>
                        </div>
                        <div className="course">
                            <div className="logo">
                                <img src={Learning} alt="" />
                            </div>
                            <div className="heading">Junior High School</div>
                            <div className="para">This course intends to help the students memorize Holy Quran. Students who wish to memorize a part or all the Quran.</div>
                        </div>
                        <div className="course">
                            <div className="logo">
                                <img src={Allah} alt="" />
                            </div>
                            <div className="heading">Islamic Education</div>
                            <div className="para">This course intends to help the students memorize Holy Quran. Students who wish to memorize a part or all the Quran.</div>
                        </div>
                        <div className="course">
                            <div className="logo">
                                <img src={Quran} alt="" />
                            </div>
                            <div className="heading">Arabic Language</div>
                            <div className="para">This course intends to help the students memorize Holy Quran. Students who wish to memorize a part or all the Quran.</div>
                        </div>
                        <div className="course">
                            <div className="logo">
                                <img src={Allah} alt="" />
                            </div>
                            <div className="heading">Learning Technologies</div>
                            <div className="para">This course intends to help the students memorize Holy Quran. Students who wish to memorize a part or all the Quran.</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AboutCourses
