import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ProfileAPI: null
}


export const LoadingStates = createSlice({
    name: "LoadingStates",
    initialState,
    reducers: {
        setLoadingStates: (state, action) => {
            return (
                {
                    ...initialState,
                    ...action.payload
                }
            )
        }
    }

})

export default LoadingStates.reducer;
export const LoadingStatesActions = LoadingStates.actions;