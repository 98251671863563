import { useState } from "react";
import { useNavigate } from "react-router-dom";

// MUI | ANT-D :

// Assets | ICONS :
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FiDollarSign } from "react-icons/fi";
import { BsTelephone } from "react-icons/bs";
import { RiFlag2Line } from "react-icons/ri";
import { TfiEmail } from "react-icons/tfi";
import { FaCity } from "react-icons/fa";
import { BiUser } from "react-icons/bi";

// Components :
import { countries } from "Components/Libarary/InfoForm/countries";
import PrimaryButton from "Components/Atoms/PrimaryButton/PrimaryButton";
import PhoneInput from "react-phone-input-2";


// APIs :
import { CreateTransactionAPI } from "API/transaction";
// Helpers :
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

// CSS :
import './Donation.scss'
import "react-phone-input-2/lib/style.css";





const DonateUs = () => {

    const [toggle, setToggle] = useState("One-Time");
    const [loading, setLoading] = useState(false)
    const Navigate = useNavigate();

    const [formValues, setFormValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        address: "",
        country: "",
        city: "",
        postalCode: "",
        contactNumber: "",
    });
    const [amount, setAmount] = useState("100");
    const [formError, setFormError] = useState({
        firstName: null,
        lastName: null,
        email: null,
        address: null,
        country: null,
        city: null,
        postalCode: null,
        contactNumber: null,
    });
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedCity, setSelectedCity] = useState("");


    const handleFormChange = (event) => {
        let { name, value } = event.target;
        switch (name) {
            case "firstName":
                if (value.length <= 0) {
                    setFormError({
                        ...formError,
                        firstName: "A first name is requried.",
                    });
                } else if (!/^[A-Za-z]*$/.test(value)) {
                    setFormError({
                        ...formError,
                        firstName:
                            "Invalid format. Please enter a first name without numbers or special characters.",
                    });
                } else {
                    setFormError({
                        ...formError,
                        firstName: null,
                    });
                }
                break;
            case "lastName":
                if (value.length <= 0) {
                    setFormError({
                        ...formError,
                        lastName: "A last name is requried.",
                    });
                } else if (!/^[A-Za-z]*$/.test(value)) {
                    setFormError({
                        ...formError,
                        lastName:
                            "Invalid format. Please enter a last name without numbers or special characters.",
                    });
                } else {
                    setFormError({
                        ...formError,
                        lastName: null,
                    });
                }
                break;
            case "email":
                if (
                    !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                        value
                    )
                ) {
                    setFormError({
                        ...formError,
                        email: "Invalid email address. Please enter a valid email address.",
                    });
                } else {
                    setFormError({
                        ...formError,
                        email: null,
                    });
                }
                break;
            case "address":
                if (value.length <= 0) {
                    setFormError({
                        ...formError,
                        address: "Address is required.",
                    });
                } else {
                    setFormError({
                        ...formError,
                        address: null,
                    });
                }
                break;
            case "country":
                if (value.length <= 0) {
                    setFormError({
                        ...formError,
                        country: "Country is required.",
                    });
                } else {
                    setFormError({
                        ...formError,
                        country: null,
                    });
                }
                break;
            case "city":
                if (value.length <= 0) {
                    setFormError({
                        ...formError,
                        city: "City is required.",
                    });
                } else {
                    setFormError({
                        ...formError,
                        city: null,
                    });
                }
                break;
            case "postalCode":
                if (value.length <= 0) {
                    setFormError({
                        ...formError,
                        postalCode: "Postal code is required.",
                    });
                } else if (value.length > 5) {
                    setFormError({
                        ...formError,
                        postalCode: "Postal code should not exceed 5 digits.",
                    });
                } else if (!/^[0-9]*$/.test(value)) {
                    setFormError({
                        ...formError,
                        contactNumber:
                            "Invalid format. Please enter a phone number without letters or special characters.",
                    });
                } else {
                    setFormError({
                        ...formError,
                        postalCode: null,
                    });
                }
                break;
            case "contactNumber":
                if (value.length <= 0) {
                    setFormError({
                        ...formError,
                        contactNumber: "Contact number is required.",
                    });
                } else {
                    setFormError({
                        ...formError,
                        contactNumber: null,
                    });
                }
                break;
            default:
                break;
        }
        setFormValues({
            ...formValues,
            [name]: value,
        });
        if (name === "country") {
            setSelectedCountry(value);
            setSelectedCity("");
        } else if (name === "city") {
            setSelectedCity(value);
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const payment = async () => {
        setLoading(true)
        if (!amount) return toast?.error("Please enter Amount to Donate");
        if (
            !formValues.firstName ||
            !formValues.lastName ||
            !formValues.email ||
            !formValues.address ||
            !formValues.country ||
            !formValues.city ||
            !formValues.postalCode
        ) {
            setFormError({
                firstName: formValues.firstName ? null : "First name is required.",
                lastName: formValues.lastName ? null : "Last name is required.",
                email: formValues.email ? null : "Email is required.",
                address: formValues.address ? null : "Address is required.",
                country: formValues.country ? null : "Country is required.",
                city: formValues.city ? null : "City is required.",
                postalCode: formValues.postalCode ? null : "Postal code is required.",
            });

            // Display an error message or handle it as desired
            toast?.error("Please fill in all the required fields.");
            return;
        }
        setLoading(false);

        Navigate("/donation/payment", {
            state: { shippingDetails: formValues, amount },
        });
    };


    return (
        <>
            <div className="mainContainer">
                <div className="topImg">
                    <div className="img"></div>
                    <div className="imgData">
                        <h2>The Quran transforms lives. Help us spread its light.</h2>
                        <p>Alhamdulillah, with your support, Quran.com has grown by 75% since last Ramadan, reaching 4 million monthly users.
                            Your donation will empower us to reach more people, continue to improve user experience, leverage AI,
                            grow scholarly content and enable community reflection.</p>
                    </div>
                </div>

                <>
                    <section id="contactInfoSec" >
                        <div className="max-w-7xl mx-auto py-10 px-2">
                            <div>

                                <div className=" paymentBtn col-span-2 block w-full">
                                    <div className="upperBtn flex gap-3 justify-center pb-[30px] flex-wrap ">

                                        <button
                                            className={`${toggle == "One-Time" ? "active" : ""} `}
                                        // onClick={handleToggle}
                                        >One-Time</button>
                                        {/* <button
                                            className={`${toggle == "Recurring" ? "active" : ""} `}
                                            onClick={handleToggle}
                                        >Recurring</button> */}
                                    </div>
                                    <div className="flex gap-3 justify-center flex-wrap">

                                        <button className={amount == "1000" ? "shouldYellow" : ""} onClick={() => setAmount(1000)}>$1000</button>
                                        <button className={amount == "500" ? "shouldYellow" : ""} onClick={() => setAmount(500)}>$500</button>
                                        <button className={amount == "100" ? "shouldYellow" : ""} onClick={() => setAmount(100)}>$100</button>
                                        <button className={amount == "50" ? "shouldYellow" : ""} onClick={() => setAmount(50)}>$50</button>

                                        <div className="relative flex items-center">
                                            <span className="absolute pl-10 flex items-center justify-center">
                                                <FiDollarSign className="h-5 w-5 text-gray-400" />
                                            </span>
                                            <input
                                                type="number"
                                                placeholder="Other"
                                                autoComplete="firstName"
                                                className="block w-full rounded-md bg-[#dddddd] border-4 border-transparent px-3 py-2 pl-16 shadow"
                                                onChange={(e) => setAmount(e.target.value)}
                                            />

                                        </div>
                                    </div>

                                </div>

                            </div>
                            <p className="font-bold text-base pt-5">Your Information</p>
                            <div
                                onSubmit={handleSubmit}
                                className="max-w-7xl grid grid-cols-1 md:grid-cols-2  gap-4 sm:gap-10 py-10"
                            >


                                <div className="col-span-2 md:col-span-1">
                                    <div className="relative">
                                        <span className="absolute bottom-6 pl-10 flex items-center">
                                            <BiUser className="h-5 w-5 text-gray-400" />
                                        </span>
                                        <input
                                            onChange={handleFormChange}
                                            required
                                            type="text"
                                            name="firstName"
                                            value={formValues?.firstName}
                                            id="first-name"
                                            placeholder="First Name"
                                            autoComplete="firstName"
                                            className="block w-full rounded-md bg-white  border-4 border-transparent px-3 py-4 pl-16 shadow"
                                        />

                                    </div>
                                    {formError.firstName && (
                                        <div className=" pt-2 text-sm text-red-500">
                                            {formError.firstName}
                                        </div>
                                    )}
                                </div>
                                <div className="col-span-2 md:col-span-1">
                                    <div className="relative">
                                        <span className="absolute bottom-6 pl-10 flex items-center">
                                            <BiUser className="h-5 w-5 text-gray-400" />
                                        </span>
                                        <input
                                            onChange={handleFormChange}
                                            required
                                            type="text"
                                            name="lastName"
                                            value={formValues?.lastName}
                                            id="first-name"
                                            placeholder="Last Name"
                                            autoComplete="lastName"
                                            className="block w-full rounded-md bg-white shadow   border-4 border-transparent px-3 py-4 pl-16 "
                                        />
                                    </div>
                                    {formError.lastName && (
                                        <div className="h-5 pt-2 pb-7 text-sm text-red-500">
                                            {formError.lastName}
                                        </div>
                                    )}
                                </div>
                                <div className="col-span-2 block w-full">
                                    <div className="relative">
                                        <span className="absolute bottom-6 pl-10 flex items-center">
                                            <TfiEmail className="h-5 w-5 text-gray-400" />
                                        </span>
                                        <input
                                            onChange={handleFormChange}
                                            required
                                            type="text"
                                            name="email"
                                            value={formValues?.email}
                                            id="email"
                                            placeholder="Email"
                                            autoComplete="email"
                                            className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-4 pl-16 "
                                        />
                                    </div>
                                    {formError.email && (
                                        <div className="h-5 pt-2 pb-7 text-sm text-red-500">
                                            {formError.email}
                                        </div>
                                    )}
                                </div>
                                <div className="col-span-2 block w-full">
                                    <div className="relative">
                                        <span className="absolute bottom-6 pl-10 flex items-center">
                                            <HiOutlineLocationMarker className="h-5 w-5 text-gray-400" />
                                        </span>
                                        <input
                                            onChange={handleFormChange}
                                            required
                                            type="text"
                                            name="address"
                                            value={formValues?.address}
                                            id="address"
                                            placeholder="Address"
                                            autoComplete="address"
                                            className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-4 pl-16 "
                                        />
                                    </div>
                                    {formError.address && (
                                        <div className="h-5 pt-2 text-sm text-red-500">
                                            {formError.address}
                                        </div>
                                    )}
                                </div>
                                <div className="col-span-2 block w-full">
                                    <div className="relative">
                                        <span className="absolute bottom-4 pl-10 flex items-center">
                                            <RiFlag2Line className="h-5 w-5 text-gray-400" />
                                        </span>
                                        <select
                                            onChange={handleFormChange}
                                            id="country"
                                            name="country"
                                            value={selectedCountry}
                                            className="custom-select block w-full text-gray-400 rounded-md bg-white shadow  border-1 px-3 py-5 pl-16 "
                                        >
                                            <option selected className="font-medium">
                                                Country
                                            </option>
                                            {countries?.map((country) => (
                                                <option key={country?.name} value={country?.name} >
                                                    {country?.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {formError.country && (
                                        <div className="h-5 pt-2 text-sm text-red-500">
                                            {formError.country}
                                        </div>
                                    )}
                                </div>

                                <div className="col-span-2 md:col-span-1">
                                    <div className="relative">
                                        <span className="absolute bottom-5 pl-10 flex items-center">
                                            <FaCity className="h-5 w-5 text-gray-400" />
                                        </span>
                                        <select
                                            onChange={handleFormChange}
                                            id="city"
                                            name="city"
                                            value={selectedCity}
                                            disabled={!selectedCountry}
                                            className="block w-full text-gray-400 rounded-md bg-white shadow border-4 border-transparent px-3 py-4 pl-16 "
                                        >
                                            <option value="" disabled>
                                                City
                                            </option>
                                            {selectedCountry &&
                                                countries?.find((country) => country?.name === selectedCountry)
                                                    .cities?.map((city) => (
                                                        <option key={city} value={city}>
                                                            {city}
                                                        </option>
                                                    ))}
                                        </select>
                                    </div>
                                    {formError.city && (
                                        <div className="h-5 pt-2 text-sm text-red-500">
                                            {formError.city}
                                        </div>
                                    )}
                                </div>
                                <div className="col-span-2 md:col-span-1">
                                    <div className="relative">
                                        <span className="absolute bottom-6 pl-10 flex items-center">
                                            <TfiEmail className="h-5 w-5 text-gray-400" />
                                        </span>
                                        <input
                                            onChange={handleFormChange}
                                            required
                                            type="text"
                                            name="postalCode"
                                            value={formValues?.postalCode}
                                            id="postalCode"
                                            placeholder="Postal Code"
                                            autoComplete="postalCode"
                                            className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-4 pl-16 "
                                        />
                                    </div>
                                    {formError.postalCode && (
                                        <div className="h-5 pt-2 text-sm text-red-500">
                                            {formError.postalCode}
                                        </div>
                                    )}
                                </div>

                                <div className="col-span-2 block w-full">
                                    <div className="relative">
                                        <span className="absolute bottom-6 pl-10 flex items-center">
                                            <BsTelephone className="h-5 w-5 text-gray-400" />
                                        </span>
                                        <PhoneInput
                                            country={"pk"}
                                            value={formValues.contactNumber}
                                            onChange={(value) =>
                                                setFormValues({
                                                    ...formValues,
                                                    contactNumber: value,
                                                })
                                            }
                                            inputClass="block w-full rounded-md bg-white shadow border-4 border-transparent px-3 py-4 pl-16 "
                                        />
                                    </div>
                                    {formError.contactNumber && (
                                        <div className="h-5 pt-2 text-sm text-red-500">
                                            {formError.contactNumber}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="justify-center flex">
                                <PrimaryButton heading="Continue" primaryFun={payment} loading={loading} />
                            </div>
                        </div>
                    </section>
                </>
            </div>
        </>
    )
}

export default DonateUs