import React from 'react'

// Componets :
import PrimaryHeading from '../../../Components/Atoms/PrimaryHeading/PrimaryHeading'
import PrimaryButton from '../../../Components/Atoms/PrimaryButton/PrimaryButton'

// Assets | ICONS :
import YLBg from '../../../Assets/Images/muslimManBg.png'
import man from '../../../Assets/Images/muslimMan.png'
import { CgEditBlackPoint } from "react-icons/cg"

// CSS :
import './Classes.scss'
import { useNavigate } from 'react-router-dom'

const Classes = () => {
    const navigate = useNavigate()

    const handleOpen = () => {
        navigate('/teacher')
    }

    return (
        <div className='class-container'>
            <div className="container">
                <div className="data-section">
                    <PrimaryHeading heading="Learn about online classes" />
                    <div className="para">Online Madrasa is a premier online Quran teaching platform, offering Quran classes and other Islamic courses to students worldwide at economical and affordable rates. Our mission is to provide top-notch Quran education for all age groups, especially children, using the latest technology. We also offer a variety of Islamic books to enhance your learning experience. Inspired by the need to support Muslim brothers and sisters in countries with limited access to Islamic education, Online Madrasa aims to deliver the best Quran learning experience directly to their homes. </div>
                    <div className="flex-publish">
                        <div className="publish">
                            <CgEditBlackPoint color='#f1c40f' size={"1.5rem"} />
                            <div className="heading">Quran Qirat classes</div>
                        </div>
                        <div className="publish">
                            <CgEditBlackPoint color='#f1c40f' size={"1.5rem"} />
                            <div className="heading">Tafheem ul Quran classes</div>
                        </div>
                        <div className="publish">
                            <CgEditBlackPoint color='#f1c40f' size={"1.5rem"} />
                            <div className="heading">Taleem ul Quran classes</div>
                        </div>
                    </div>
                    <div className="classes-btn">
                        <PrimaryButton heading="Join Us" className="btn" primaryFun={() => handleOpen()} />
                    </div>
                </div>
                <div className="image-section">
                    <img src={YLBg} alt="error" />
                    <div className="muslim-man">
                        <img src={man} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Classes
