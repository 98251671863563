import React, { useState, Fragment, useEffect } from 'react';

// Styling
import './SideBar.scss'

// Other Modules
import Drawer from '@mui/material/Drawer';
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CartsDataActions } from '../../Redux/Slice/Carts';
import ImgUrlGEN from '../../Utils/ImgUrlGen'
import MessagePrompt from 'Components/MessagePrompt/MessagePrompt';




export default function SideBar() {
    const location = new useLocation();
    const navigate = new useNavigate();
    const dispatch = new useDispatch()
    const [state, setState] = useState({ right: false });
    const [showFullHeading, setShowFullHeading] = useState(false);
    const [cartData, setCartData] = useState({})

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const cart = useSelector((state) => state?.CartsData)


    const toggleDrawer = (open) => {
        setState({ right: open });
    };
    const totalPrice = cart.reduce((sum, item) => sum + parseInt(item?.price), 0);
    const checkOut = () => {
        navigate("/libarary/detail/checkOut");
        setState({ right: false })
    };
    const viewBag = () => {
        navigate('/library/bag')
        setState({ right: false })
    }
    const shopping = () => {
        navigate('/library')
        setState({ right: false })
    }

    const goBack = () => {
        setState({ right: false })
    }


    const handleDeleteBook = async (bookId) => {
        let res = dispatch(CartsDataActions?.removeCartItem(bookId));
        if (res.payload != null || res.payload != undefined || res.payload != "") {
            showModal()
            setTimeout(() => {
                setIsModalOpen(false)
            }, 700);
        }
    }


    // Short Handing and Detail functions
    const shortenHeading = (text, maxLength) => {
        if (text?.length > maxLength) {
            return text.slice(0, maxLength) + "...";
        } else {
            return text;
        }
    };
    const sideBarManu = useSelector((state) => state?.SideBar)

    useEffect(() => {
        if (sideBarManu.sidemanu) {
            toggleDrawer(sideBarManu.sidemanu)
        }
    }, [sideBarManu])

    return (
        <>
            {isModalOpen && <MessagePrompt isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} message="Item remove Success" />}
            <div className='SideBarMainContainer'>
                <Fragment>
                    {/* <span className="sideBarBtn" onClick={() => { toggleDrawer(sideBarManu?.sidemanu); }}>rewasf</span> */}
                    {/* <span className="sideBarBtn" onClick={() => { toggleDrawer(true); }}>{sideBarManu}</span> */}
                    {/* {addBook && <strong onClick={()=>cartBook(addBook)}></strong>} */}
                    <Drawer
                        className='main'
                        anchor={"right"}
                        open={state["right"]}
                        onClose={() => toggleDrawer(false)}
                    >
                        <div className="mainSideBar">
                            <div className="SideBarContainerupper">
                                <div className="header">
                                    <div className="cartHeader">
                                        Shopping Cart
                                    </div>
                                    <div className="closeBtn" onClick={goBack}>Close</div>
                                </div>
                                {
                                    cart?.length >= 1 ?
                                        <>
                                            {
                                                cart?.map((data, i) => {
                                                    return (
                                                        <div className="bookContainer">
                                                            <div className="bookimg">
                                                                <img src={ImgUrlGEN(data?.image)} alt="Uploading" />
                                                            </div>
                                                            <div className="bookMain" >
                                                                <div className="title">{showFullHeading ? data?.title?.replace(/<[^>]+>/g, '') : shortenHeading(data?.title, 15)}</div>

                                                                <div className="bookDetail flexLine">

                                                                    <div className="publisher Header">
                                                                        <div className="pubHead paraDetail">Publisher: </div>
                                                                        <div className="autherHead paraDetail">Auther(S): </div>
                                                                        <div className="priceHead paraDetail">Price: </div>
                                                                    </div>

                                                                    <div className="auther para">
                                                                        <div className="pubName paraDetail">{showFullHeading ? data?.publisher?.replace(/<[^>]+>/g, '') : shortenHeading(data?.publisher || "UnKnown", 12)}</div>
                                                                        <div className="autherName paraDetail">{data?.auther?.firstName || "UnKnown"} {data?.auther?.lastName}</div>
                                                                        <div className="priceName paraDetail">$ {data?.price || 0}</div>
                                                                    </div>
                                                                </div>
                                                                <Button className="delBtn" onClick={() => handleDeleteBook(data._id)}>x</Button>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </>
                                        :
                                        <div className="nothing">Nothing is here</div>
                                }
                            </div>
                            <div className="SideBarFooter">
                                <div className="totalPrice">
                                    <div className="priceHead">Total Amount:</div>
                                    <div className="price">${totalPrice}</div>
                                </div>
                                <div className="fotterBtn">
                                    <Button className='viewBagBtn fBtn' onClick={viewBag}>View & Edit Bag</Button>
                                    {
                                        totalPrice == 0 ? (
                                            <Button disabled className='checkoutBtn fBtn' onClick={checkOut}>Check Out</Button>
                                        ) :
                                            <Button className='checkoutBtn fBtn' onClick={checkOut}>Check Out</Button>
                                    }
                                    <Button className='continueBtn fBtn' onClick={shopping}>Continue Shopping</Button>
                                </div>
                            </div>
                        </div>
                    </Drawer>
                </Fragment>
            </div >
        </>
    );
}
