import { createSlice } from "@reduxjs/toolkit";

const initialState = null;


export const BlogsData = createSlice({
    name: "BlogsData",
    initialState,
    reducers: {
        setBlogsData: (state, action) => {
            return action.payload
        }
    }

})

export default BlogsData.reducer;
export const BlogsDataActions = BlogsData.actions;