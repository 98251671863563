import { createSlice } from "@reduxjs/toolkit";

const initialState = null;


export const BooksData = createSlice({
    name: "BooksData",
    initialState,
    reducers: {
        setBooksData: (state, action) => {
            return action.payload
        }
    }

})

export default BooksData.reducer;
export const BooksDataActions = BooksData.actions;