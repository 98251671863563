import React from 'react'
import { useLocation } from 'react-router-dom';

import SecondaryButton from 'Components/Atoms/SecondaryButton/SecondaryButton';
import PrimaryButton from 'Components/Atoms/PrimaryButton/PrimaryButton';
import "./LoginPrompt.scss";





const LoginPrompt = ({ message, id, path }) => {
    let Location = useLocation()


    const handleRegister = () => {
        window.location.replace(id ? `${window?.location?.DashboardURL}/register?s=c&l=${path}&id=${id}` : `${window?.location?.DashboardURL}/register?s=c&l=${path}`)
    }
    const handleLogin = () => {
        window.location.replace(id ? `${window?.location?.DashboardURL}/login?s=c&l=${path}&id=${id}` : `${window?.location?.DashboardURL}/login?s=c&l=${path}`)
    }

    return (
        <>
            <div className="loginContainer">
                {/* <div className="heading">
                    Login / Register
                </div> */}
                <div className="content">
                    <p> {message} </p>
                    <div className="buttonBox">
                        <SecondaryButton heading="Register" secondaryFun={handleRegister} />
                        <PrimaryButton heading="Login" primaryFun={handleLogin} />
                    </div>
                </div>

            </div>
        </>
    )
}

export default LoginPrompt