import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';

// Componets :
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Blogs from './Pages/Blogs/Blogs';
import Payment from './Pages/Payment/Payment';
import Footer from './Components/Footer/Footer';
import Navbar from './Components/Navbar/Navbar';
import Contact from './Pages/ContactUs/Contact';
import Categories from './Pages/Categories/Categories';
import ScrollUpButton from './Components/Atoms/ScrollUpButton/ScrollUpButton';
import SideBar from './Components/SideBar/SideBar';
import Bag from './Pages/Bag/Bag'
import Teacher from './Pages/Teacher/Teacher';
// import TeacherDetail from './Pages/Teacher/TeacherDetail/TeacherDetail';

// APIs :
import { GetProfileDataAPI } from './API/users';
import { CommissionAPI } from 'API/commission';
import { CommissionDataActions } from 'Redux/Slice/Commission';
// Helpers :
import { ToastContainer, toast } from 'react-toastify';
// Redux :
import { useDispatch, useSelector } from 'react-redux';
import { userDataActions } from 'Redux/Slice/Users/index';
import { LoadingStatesActions } from 'Redux/Slice/Loading/index';

// Cross Domain Storage :
import createGuest from "cross-domain-storage/guest";

// css
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import BooksDetail from './Components/Libarary/BooksDetail/BooksDetail';
import FreeBook from './Components/Libarary/FreeBook/FreeBook';
import CardForm from './Components/Libarary/InfoForm/CardInfo';
import PaymentPage from './Components/Libarary/Payment/Payment';
import StudentPaymentPage from './Pages/Teacher/TeacherDetail/Payment/StudentPaymentPage';
import TeacherDetailPage from './Pages/Teacher/TeacherDetail/TeachersDetailPage';
import TeacherDetail from 'Pages/Teacher/TeacherCourseDetail/TeacherDetail';
import CallJoiningForm from './Pages/Teacher/TeacherDetail/CallJoiningForm/CallJoiningForm';
import PreLoader from 'Components/Atoms/PreLoader/PreLoader';
import Socket from 'Utils/Socket';
import QuranRecite from 'Pages/Recitation/QuranRecite';
import Donation from 'Pages/Donation/DonateUs';
import DonationPayment from 'Pages/Donation/DonationPayment/DonationPayment';
import DonateUs from 'Pages/Donation/DonateUs';
import Scroll from 'Helper/SmoothScrollbar';







const App = () => {
  let location = useLocation();
  let Dispatch = useDispatch()


  const [AuthToken, setAuthToken] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  const gettingProfileData = async (CreateGuest) => {
    Dispatch(LoadingStatesActions.setLoadingStates({ ProfileAPI: true }))
    let res = await GetProfileDataAPI()
    if (res.error != null) {

    } else {
      let userData = res.data?.result;
      Dispatch(userDataActions.setUserData(userData))
      localStorage.setItem("madrasaUserData", JSON.stringify(userData))
    }
    Dispatch(LoadingStatesActions.setLoadingStates({ ProfileAPI: false }))
  }
  var CreateGuest = createGuest(window?.location?.DashboardURL)
  useEffect(() => {

    // var CreateGuest = createGuest(process.env.REACT_APP_DASHBOARD_URL)


    // console.log("API HIT", CreateGuest);
    try {

      CreateGuest.get("madrasaToken", function (error, value) {
        if (error) {
          console.log("------- ERROR ---------", error);
          localStorage.removeItem("madrasaToken")
          setAuthToken(false)
        }
        if (value) {
          console.log("----------- VALUE ----------", value);
          setAuthToken(value)
          return
        } else {
          setAuthToken(false)
        }
        console.log("----------- VALUE-asdasd ----------", value, error);
        setAuthToken(false)
      })
    } catch (err) {
      console.log("----------- CATCH ----------", err);
    }

    // CreateGuest.close();

  }, [])
  const LogOut = () => {
    CreateGuest.remove("madrasaToken", (error, value) => {
      if (error) {
        console.log("------- ERROR-CDS ---------", error);
      }
      if (value) {
        console.log("------- VALUE-CDS ---------", value);
      }
      console.log("------- RES-CDS ---------", value, error);
    })
    localStorage.clear()
    toast.warn("Logout Success");
    setTimeout(() => {
      window.location.href = "/"
    }, 500);
  }


  useEffect(() => {
    if (AuthToken && AuthToken != undefined && AuthToken != null && AuthToken?.length >= 10) {
      localStorage.setItem("madrasaToken", AuthToken)
      gettingProfileData()
    } else {
      localStorage.removeItem("madrasaToken")
      localStorage.removeItem("madrasaUserData")
      Dispatch(userDataActions.setUserData(null))
    }

    if (AuthToken == false) {
      Dispatch(LoadingStatesActions.setLoadingStates({ ProfileAPI: false }))
    }

  }, [AuthToken])

  useEffect(() => {
    // Socket.connect()
    // Socket.on('connect', console.log("------- SOCKET CONNECTED -------"));
    return () => {
      // Socket.disconnect()
      // Socket.off('disconnect', console.log("------- SOCKET DISCONNECTED -------"));
    };
  }, [])



  //  Get Commission and Dispatch in state
  const GetCommission = async () => {
    let res = await CommissionAPI()
    if (res?.error != null) {
      toast.error(res?.error)
    } else {
      Dispatch(CommissionDataActions?.setCommissionData(res?.data?.result))
    }
  }

  useEffect(() => {
    GetCommission()
  }, [])

  return (

    <>
      <Scroll />
      {
        loading ?
          <PreLoader />
          :
          <>
            <ToastContainer
              position="top-center"
              autoClose={1000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />

            {
              location.pathname !== '/login' &&
              <Navbar LogOut={LogOut} />
            }
            <SideBar />
            <Routes>
              <Route exact path='/' element={<Home />} />
              <Route exact path='/recite' element={<QuranRecite />} />
              <Route exact path='/donation' element={<DonateUs />} />
              <Route exact path='/donation/payment' element={<DonationPayment />} />
              <Route exact path='/teacher' element={<Teacher />} />
              <Route exact path='/teacher/detail' element={<TeacherDetail />} />
              <Route exact path='/teacher/detailPage' element={<TeacherDetailPage />} />
              <Route exact path='/teacher/detailPage/joiningForm' element={<CallJoiningForm />} />
              <Route exact path='/teacher/detail/payment' element={<StudentPaymentPage />} />
              <Route exact path='library' element={<Categories />} />
              <Route exact path='/libarary/detail' element={<BooksDetail />} />
              <Route exact path='/libarary/detail/free-book' element={<FreeBook />} />
              <Route exact path='/libarary/detail/checkOut' element={<CardForm />} />
              <Route exact path='/libarary/detail/payment' element={<PaymentPage />} />
              <Route exact path='blogs/*' element={<Blogs />} />
              <Route exact path='about' element={<About />} />
              <Route exact path='contact' element={<Contact />} />
              <Route exact path='search' element={<Home />} />
              <Route exact path='shopping' element={<Payment />} />
              <Route exact path='/library/sidebar' element={<SideBar />} />
              <Route exact path='/library/bag' element={<Bag />} />
              <Route exact path="*" element={<Navigate to={'/'} replace />} />
            </Routes>
            {
              location.pathname !== '/login' &&
              <Footer />
            }

            {
              location.pathname !== '/login' &&
              <ScrollUpButton />
            }

          </>
      }
    </>
  );
}

export default App;
