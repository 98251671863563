import React, { useState } from "react";
import {
  Card,
  Radio,
  Space,
  Progress,
  Select,
  Slider,
} from "antd";
import { HiSearch } from "react-icons/hi";
import { message } from "antd";
import "./TeacherSideBar.scss";
import { languages, timeZones } from "../../../Helper/commonHelper";


const TeacherSideBar = ({ selectedGender, onGenderChange, selectedStudyPurpose, onStudyPurposeChange, selectedLanguage, onLanguageChange, selectedTimeZone, onTimeZoneChange, onSearchChange }) => {
  const [level, setLevel] = useState("Beginner");
  const levels = ["Beginner", "Intermediate", "Experience"];
  // const [gender, setGender] = useState('');
  const [studyPurpose, setStudyPurpose] = useState('');
  const [tZone, setTZone] = useState(1);
  const [content, setContent] = useState('');
  // const [searchValue, setSearchValue] = useState("");


  const handleSearchChange = (event) => {
    const searchQuery = event.target.value;
    onSearchChange(searchQuery);
  };

  const handleGender = (e) => {
    onGenderChange(e.target.value);
  };
  // const handleGender = (e) => {
  //   setGender(e.target.value);
  // };
  const handleStudy = (e) => {
    onStudyPurposeChange(e.target.value);
  };
  // const handleStudy = (e) => {
  //   setStudyPurpose(e.target.value);
  // };
  const handleLanguageChange = (selectedValues) => {
    if (selectedValues.length <= 5) {
      onLanguageChange(selectedValues);
    } else {
      message.warning("You can select up to 5 languages.");
    }
    // onLanguageChange(selectedValues);
  };
  const handleTimeZoneChange = (selectedValues) => {
    if (selectedValues.length <= 5) {
      onTimeZoneChange(selectedValues);
    } else {
      message.warning("You can select up to 5 TimeZone.");
    }
  };
  const handleTime = (e) => {
    setTZone(e.target.value);
  };
  const handleContent = (e) => {
    setContent(e.target.value);
  };

  let percent = 0;

  if (level === "Beginner") {
    percent = 33;
  } else if (level === "Intermediate") {
    percent = 66;
  } else if (level === "Experience") {
    percent = 100;
  }

  const handleLevelChange = (e) => {
    setLevel(e.target.value);
  };
  const handleProgressClick = (e) => {
    const { width, left } = e.target.getBoundingClientRect();
    const clickX = e.pageX - left;
    const clickPercent = (clickX / width) * 100;

    if (clickPercent < 33) {
      setLevel("Beginner");
    } else if (clickPercent < 66) {
      setLevel("Intermediate");
    } else {
      setLevel("Experience");
    }
  };

  return (
    <>
      <div className="TeachersContainer ">
        <div className="TeacherSideBarContainer">
          <div>
            <h1 className="barHeading">SEARCH CRITERIA</h1>

          </div>
          <div className="searchBox ">
            <input
              type="text"
              name=""
              id=""
              placeholder="Search Posts..."
              className="hover:shadow-md"
              // value={searchQuery} 
              onChange={handleSearchChange}
            />
            <div className="imgBox">
              <HiSearch />
            </div>
          </div>

          {/* Gender */}
          <div className="Teacher">
            <Card title=" Gender" bordered={false} className="genderCard">
              <Radio.Group onChange={handleGender} value={selectedGender}>
                <Space direction="vertical">
                  <Radio value="Male">Male</Radio>
                  <Radio value="Female">Female</Radio>
                  <Radio value=""> Both</Radio>
                </Space>
              </Radio.Group>
            </Card>
          </div>

          {/* Study Purpose
          <div className="Study">
          <Card title="Purpose" bordered={false} className="genderCard">
            <Radio.Group onChange={handleStudy} value={selectedStudyPurpose}>
              <Space direction="vertical">
                <Radio value="Nadra">Nadra</Radio>
                <Radio value="Memorization (Hifz)">Memorization (Hifz)</Radio>
                <Radio value="Recitation (Tilawa/ Tajweed)">Recitation (Tilawa/ Tajweed)</Radio>
                <Radio value="Revision"> Revision</Radio>
                <Radio value=""> All</Radio>
              </Space>
            </Radio.Group>
          </Card>
          </div> */}

          {/* Teacher Speaks*/}
          <div className="gender">
            <Card
              title="Teacher Speaks"
              bordered={false}
              className="genderCard"
            >
              <Select
                mode="multiple"
                showArrow
                placeholder="Select Language"
                style={{
                  width: "100%",
                }}
                options={languages}
                onChange={handleLanguageChange}
                value={selectedLanguage}
              />
            </Card>
          </div>

          {/* Time Zone */}
          <div className="gender">
            <Card title="Time Zone" bordered={false} className="genderCard">
              <Radio.Group onChange={handleTime} value={tZone}>
                <Space direction="vertical">
                  <Radio value={1}>Time difference is not important</Radio>
                  <Radio value={2}>Teacher Time Matters</Radio>
                </Space>
              </Radio.Group>
              {tZone === 2 && (
                <Select
                  mode="multiple"
                  showArrow
                  placeholder="Select TimeZone"
                  style={{
                    width: "100%",
                    marginTop: "12px",
                  }}
                  options={timeZones}
                  onChange={handleTimeZoneChange}
                  value={selectedTimeZone}
                />
              )}
            </Card>
          </div>

          {/* Content */}
          {/* <div className="content">
            <Card title="Content" bordered={false} className="genderCard">
              <Radio.Group onChange={handleContent} value={content}>
                <Space direction="vertical">
                  <Radio value={1}>Full Quran</Radio>
                  <Radio value={2}>Part of Quran</Radio>
                  <Radio value={3}> Assorted Selection</Radio>
                  <Radio value={4}> All</Radio>
                </Space>
              </Radio.Group>
            </Card>
          </div> */}

          {/* Assignment */}
          {/* <div className="assignment">
            <Card title="Assignment" bordered={false} className="genderCard">
              <Slider max={10}/>
            </Card>
          </div> */}

          {/* LEVEL */}
          {/* <div className="gender">
            <Card title="Level" bordered={false} className="genderCard">
              <div>
                <Progress
                  percent={
                    level === "Beginner"
                      ? 33
                      : level === "Intermediate"
                      ? 66
                      : 100
                  }
                  onClick={handleProgressClick}
                  style={{ cursor: "pointer" }}
                />
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  {levels.map((lvl) => (
                    <span
                      key={lvl}
                      onClick={() => setLevel(lvl)}
                      className="levelCourse"
                      style={{
                        cursor: "pointer",
                        marginRight: "10px",
                        fontWeight: level === lvl ? "bold" : "normal",
                      }}
                    >
                      {lvl}
                    </span>
                  ))}
                </div>
              </div>
            </Card>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default TeacherSideBar;
