import React from "react";
import AboutEvents from "./AboutEvents/AboutEvents";
import AboutCourses from "./AboutCourses/AboutCourses";
import AboutHome from "./AboutHome/AboutHome";
import NewsLetter from "./Subscribtion/NewsLetter";

const About = () => {
  return (
    <>
      <AboutHome />
      <NewsLetter />
      <AboutCourses />
      <AboutEvents />
    </>
  );
};

export default About;
