import React,{useState} from "react";


import assignmentSpeed  from '../../../Assets/svgs/assignmentSpeed.svg'
import courseContent from '../../../Assets/svgs/courseContent.svg'
import duration from '../../../Assets/svgs/duration.svg'
import language from '../../../Assets/svgs/language.svg'
import level from '../../../Assets/svgs/level.svg'
import startDate from '../../../Assets/svgs/startDate.svg'
import status from '../../../Assets/svgs/status.svg'
import  studyType  from '../../../Assets/svgs/studyType.svg'
import timeZone from '../../../Assets/svgs/timeZone.svg'

// Styling import
import "./TeacherDetail.scss";
import { Button, Rate, Table, Modal } from "antd";
import { FaFacebookSquare, FaGoogle } from "react-icons/fa";
import PrimaryButton from "../../../Components/Atoms/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../../Components/Atoms/SecondaryButton/SecondaryButton";

export default function TeacherDetail() {
  const [open, setOpen] = useState(false);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);


  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };
  const handleRegister = () => {
    setOpen(false);
    setRegisterModalOpen(true);
  };

  const handleRegisterModalCancel = () => {
    setRegisterModalOpen(false);
  };

  const desc = ["terrible", "bad", "normal", "good", "wonderful"];

  const outerColumns = [
    {
      title: "Course Setting",
      dataIndex: "setting",
      key: "setting",
      render: (text, record) => {
        if (record.image) {
          return (
            <div style={{display:"flex"}}>
              <img src={record.image} alt={text} className="setting-image px-2" />
              <span>{text}</span>
            </div>
          );
        }
        return text;
      },
    },
    {
      title: "Course Value",
      dataIndex: "value",
      key: "value",
      width: "60%",
    },
  ];

  const innerColumns = [
    {
      title: "Chapter",
      dataIndex: "chNo",
      key: "chNo",
    },
    {
      title: "Page No",
      dataIndex: "pageNo",
      key: "pageNo",
    },
    {
      title: "Topic",
      dataIndex: "topicName",
      key: "topicName",
    },
  ];

  const outerData = [
    {
      key: 1,
      image: studyType,
      setting: "Study Type",
      value: "Memorization (Hafiz)",
    },
    {
      key: 2,
      image: level,
      setting: "Level",
      value: "Beginner, Intermediate, Advance",
    },
    {
      key: 3,
      image: assignmentSpeed,
      setting: "Assignment Speed",
      value: "2 Assignments per Week",
    },
    {
      key: 4,
      image: language,
      setting: "Language",
      value: "English/Arabic",
    },
    {
      key: 5,
      image: timeZone,
      setting: "Time Zone",
      value: "Asia/Riyadh (+3:00) GMT",
    },
    {
      key: 6,
      image: courseContent,
      setting: "Course Content",
      children: [
        {
          chNo: "John Brown",
          pageNo: 42,
          topicName: "New York No. 2 Lake Park",
        },
        {
          chNo: "John Brown",
          pageNo: 42,
          topicName: "New York No. 2 Lake Park",
        },
        {
          chNo: "John Brown",
          pageNo: 42,
          topicName: "New York No. 2 Lake Park",
        },
        {
          chNo: "John Brown",
          pageNo: 42,
          topicName: "New York No. 2 Lake Park",
        },
      ],
    },
    {
      key: 7,
      image: status,
      setting: "Status",
      value: "Current",
    },
    {
      key: 8,
      image: duration,
      setting: "Duration",
      value: "12 Week(s)",
    },
    {
      key: 9,
      image: startDate,
      setting: "Start Date",
      value: "08/06/2023",
    },
  ];

  return (
    <>

            <Modal
              title="Login / Register"
              style={{
                top: 20,
              }}
              open={open}
              onOk={handleOk}
              onCancel={handleRegister}
              footer={[
                <div className="mr-5">
                  <SecondaryButton
                    heading="Register"
                    secondaryFun={handleRegister}
                  />
                </div>,
                <PrimaryButton heading="Login" primaryFun={handleOk} />,
              ]}
            >
              <p>To join course you need to Register/ login</p>
            </Modal>

            <Modal
              style={{
                top: 20,
              }}
              open={registerModalOpen}
              onCancel={handleRegisterModalCancel}
              onOk={handleOk}
              // onCancel={handleCancel}
              footer={[
                <div className="mr-5">
                  <Button
                    key="link"
                    href="https://www.facebook.com/FTSFirmTechServices/"
                    onClick={handleRegisterModalCancel}
                    className="flex items-center"
                  >
                    <FaFacebookSquare />
                  </Button>
                </div>,
                <Button
                  key="link"
                  href="https://google.com"
                  onClick={handleRegisterModalCancel}
                  className="flex items-center"
                >
                  <FaGoogle />
                </Button>,
              ]}
            >
              <Button>Create an account</Button>
              <p className="pt-5 font-semiBold text-base">Or</p>
            </Modal>
      <div className="teacherDetailMainContainer">
        <div className="name">Course Name Details</div>
        <div className="detailMain">
          <div className="tableMain">
            <table className="table">
              <Table
                className="components-table-demo-nested"
                columns={outerColumns}
                dataSource={outerData}
                pagination={false}
                expandable={{
                  expandedRowRender: (record) => (
                    <Table
                      columns={innerColumns}
                      dataSource={record.children}
                      pagination={false}
                    />
                  ),
                  rowExpandable: (record) => !!record.children,
                  defaultExpandAllRows: true,
                }}
              />

            </table>
          </div>
          <div className="ourTeacher">
            <div className="head teach">Our Teacher</div>
            <div className="teacherNameHead teach">Teacher Name</div>
            <div className="teacherName teach">Muhammad Junaid</div>
            <Rate
              style={{ fontSize: "14px" }}
              tooltips={desc}
              disabled
              value={4}
            />
            <div className="assignMain teach">Assignments Frequency</div>
            <div className="assignWeek teach">2 Assignments per Week</div>
            <div className="totalassign teach">Total Assignments: 14</div>
            <Button className="joinBtn" onClick={showModal}>Join for free</Button>
          </div>
        </div>
      </div>
    </>
  );
}
