import React from "react";
import { useNavigate } from "react-router-dom";
// ANT-D || MUI :
import { Select, Space } from "antd";

// Components :
import PrimaryButton from "../../../Components/Atoms/PrimaryButton/PrimaryButton";
import PrimaryHeading from "../../../Components/Atoms/PrimaryHeading/PrimaryHeading";

// Helpers
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//CSS :
import "./ChooseBook.scss";
import BooksCarasol from "../../../Components/Common/YourBook/BooksCarasol";


const ChooseBook = () => {
  const navigate = new useNavigate();

  const handleChange = (value) => {
    return value;
  };

  const HandleRegister = () => {
    window.open(`${window?.location?.DashboardURL}/register`, "_blank")
  }

  return (
    <>
      <div className="courses-container">
        <div className="heading container">
          <PrimaryHeading heading="Choose your Books" />
          <div className="courses-buttons">
            {/* <Space wrap>
              <Select
                defaultValue="Top Rated Books"
                // style={{ width: 200 }}
                onChange={handleChange}
                options={[
                  { value: "Trending Books", label: "Trending Books" },
                  { value: "Top Rated Books", label: "Top Rated Books" },
                ]}
              />
            </Space> */}
            <PrimaryButton heading="Register" primaryFun={HandleRegister} />
          </div>
        </div>
      </div>
      <BooksCarasol />
    </>

  );
};

export default ChooseBook;
