import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

// MUI | ANT-D :
import { Rate, Button, Input } from 'antd';

// Components :
import PrimaryButton from 'Components/Atoms/PrimaryButton/PrimaryButton';

// Assets | ICONS :
import Avater from "../../../../Assets/Images/img/profileAvater.png";
import { FaRegHeart, FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaGoogle } from "react-icons/fa";
import { AiOutlineCalendar, AiOutlineCheckCircle } from "react-icons/ai"
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";

// APIs :
import { CommentBlogAPI, ReviewBlogAPI } from 'API/blogs';
// Redux :
import { useSelector } from 'react-redux';
// Helper :
import HTMLParse from "html-react-parser"
import ImgURLGEN from '../../../../Utils/ImgUrlGen'

// CSS :
import "./SingleBlog.scss"
import { toast } from 'react-toastify';
import LoginPrompt from 'Components/LoginPrompt/LoginPrompt';
import SideBar from '../../Components/SideBar/SideBar';





const SingleBlog = () => {
    const Location = useLocation()

    let id = new URLSearchParams(Location.search).get("id")

    let bgWhite = "#fff"
    let padding = 0

    const UserData = useSelector(state => state.UserData)
    const BlogsData = useSelector(state => state.BlogsData)

    const [blogID, setBlogID] = useState(Location?.state?.blogID || id || null)
    const [selectedBlog, setSelectedBlog] = useState(null)

    const [comment, setComment] = useState("");
    const [postedComments, setPostedCommnets] = useState(null)
    const [loadingComment, setLoadingComment] = useState(false);
    const [reload, setReload] = useState(false);

    const [review, setReview] = useState({
        text: "",
        value: null
    });
    const [loadingReview, setLoadingReview] = useState(false);

    const enteringReview = (event) => {
        let { name, value } = event.target;
        setReview({
            ...review,
            [name]: value
        })
    }


    const SubmitComment = async () => {
        setLoadingComment(true);
        let res = await CommentBlogAPI({ id: selectedBlog?._id, comment });
        if (res.error != null) {
            toast.error(res.error)
        } else {
            toast.success(res?.data?.message);
            setComment("");
            setPostedCommnets([
                ...postedComments,
                {
                    UserData,
                    text: comment,
                    date: new Date()
                }
            ])
            // setReload(!reload);
        }
        setLoadingComment(false);
    }
    const SubmitReview = async () => {
        setLoadingReview(true);
        let res = await ReviewBlogAPI({ id: selectedBlog?._id, review });
        if (res.error != null) {
            toast.error(res.error)
        } else {
            toast.success(res?.data?.message);
            setReview({
                ...review,
                posted: true
            })
            // setReload(!reload);
        }
        setLoadingReview(false);
    }


    useEffect(() => {
        if (blogID) {
            if (BlogsData) {
                const findData = BlogsData.find(val => val?._id == blogID)
                let findBlog = {}
                if (findData) {
                    findBlog = {
                        ...findData
                    }
                    setSelectedBlog(findBlog);
                    console.log(".........................................", selectedBlog);
                    let findReview = findData?.review?.find(r => r?.UserData == UserData?._id);
                    setPostedCommnets(findBlog?.comments || [])
                    if (findReview) {
                        setReview({
                            text: findReview?.text,
                            value: findReview?.value,
                            posted: true
                        })
                    }
                } else {
                    alert("no Blog")
                }
            }
        } else {
            alert("no ID")
        }
    }, [BlogsData, blogID, reload])

    const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];

    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <>
            <div className="singleblogContainer">

                <div className="sideIcons">
                    <p>Share</p>
                    <div className="icon"><FaFacebookF /></div>
                    <div className="icon"><FaGoogle /></div>
                    <div className="icon"><FaLinkedin /></div>
                    <div className="icon"><FaTwitter /></div>
                    <div className="icon"><FaInstagram /></div>

                </div>

                <div className="blogContainer">
                    <div className="aboveImgDetails">
                        <div className="profileData">
                            <div className="avater">
                                <img className='autherImage' src={ImgURLGEN(selectedBlog?.auther?.profileImage)} alt="Error" />
                            </div>
                            <div className="details">
                                <div className="name">{selectedBlog?.auther?.firstName} {selectedBlog?.auther?.lastName}</div>
                                <div className="bio"> <AiOutlineCheckCircle className='icon' /> {selectedBlog?.status} </div>

                            </div>
                        </div>
                        <div className="publishedDate">
                            PUBLISHED ON: {new Date(selectedBlog?.createdAt).toLocaleDateString('en-US', {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric',
                            })}
                        </div>
                    </div>
                    <div className="blog">
                        <div className="imgBox">
                            <img src={ImgURLGEN(selectedBlog && selectedBlog?.image)} alt="ERROR" />
                        </div>
                        {/* <div className="authorBox">
                            <div className="avaterBox">
                                <img className='autherImage' src={ImgURLGEN(selectedBlog?.auther?.profileImage)} alt="ERROR" />
                                By {selectedBlog?.auther?.firstName} {selectedBlog?.auther?.lastName}
                            </div>
                            <div className="timeBox">
                                <AiOutlineCalendar />
                                {selectedBlog?.createdAt?.slice(0, 10)}
                            </div>
                            <div className="likeBox">
                                <FaRegHeart />
                                33
                            </div>
                        </div> */}
                        <div className="title darkFamily">
                            {selectedBlog?.title}
                        </div>
                        <div className="quotBox">
                            <div className="upperComma"><ImQuotesLeft /></div>
                            {selectedBlog?.quote}
                            <div className="lowerComma"><ImQuotesRight /></div>
                        </div>
                        <div className="blogDetail">
                            {
                                selectedBlog && selectedBlog.detail && selectedBlog.detail.length >= 2 &&
                                HTMLParse(selectedBlog.detail)
                            }
                        </div>
                        {/* <div className="tagBox">
                            <div className="tags">
                                {
                                    selectedBlog && selectedBlog.tags && Array.isArray(selectedBlog.tags) &&
                                    selectedBlog.tags.map((tag) => {
                                        return (
                                            <>
                                                <div className="tag">{tag?.name}</div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                            <div className="socialLinks">
                                <div className="icon"><FaFacebookF /></div>
                                <div className="icon"><FaTwitter /></div>
                                <div className="icon"><FaInstagram /></div>
                            </div>
                        </div> */}

                        <div className="profileBox">
                            <div className="avater">
                                <img className='autherImage' src={ImgURLGEN(selectedBlog?.auther?.profileImage)} alt="Error" />
                            </div>
                            <div className="details">
                                <div className="name">{selectedBlog?.auther?.firstName} {selectedBlog?.auther?.lastName}</div>
                                <div className="bio">      {UserData?.bio}</div>

                            </div>
                        </div>
                        {/* <div className="navigate">
                        <PrimaryButton heading="Previous" />
                        <PrimaryButton heading="Next Post" />
                    </div> */}
                    </div>
                    {
                        !UserData ?
                            <>
                                <div className="loginPrompt">
                                    <LoginPrompt message={"To comment or Review Please Login or Register First"} path={"blogs"} />
                                </div>
                            </>
                            :
                            <div className="commentSection">

                                {/* <div className="reviewComment">
                                    <div className='rComment'>
                                        <div className="title">
                                            Leave a Comment
                                        </div>
                                    </div>

                                    <div className="profileData">
                                        <div className="avater">
                                            <div className="img">
                                                <img className='autherImage' src={ImgURLGEN(UserData?.profileImage)} alt="Error" />
                                            </div>
                                            <div className="imgDetails">

                                                <div className="mailDetail">
                                                    {UserData?.firstName}
                                                    <span> </span>
                                                    {UserData?.lastName}
                                                </div>
                                                <div className="mail">
                                                    {UserData?.email}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="writePara">
                                        <textarea className='w-full' placeholder='Write Your Comment' value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
                                    </div>
                                    <div className="form">
                                        <Button className='btnComment' loading={loadingComment} onClick={SubmitComment}> Post </Button>
                                    </div>
                                </div>
                                 */}
                                <div className="reviewComment">
                                    <div className='rComment'>
                                        <div className="title">
                                            Give Review
                                        </div>

                                        {/* <hr className='hr' /> */}
                                    </div>

                                    <div className="profileData">
                                        <div className="avater">
                                            <div className="img">
                                                <img className='autherImage' src={ImgURLGEN(UserData?.profileImage)} alt="Error" />
                                            </div>
                                            <div className="imgDetails">

                                                <div className="mailDetail">
                                                    {UserData?.firstName}
                                                    <span> </span>
                                                    {UserData?.lastName}
                                                </div>
                                                <div className="mail">
                                                    {UserData?.email}
                                                </div>
                                                <div className="stars">
                                                    <Rate disabled={review?.posted ? true : false} tooltips={desc} value={review.value} onChange={(e) => enteringReview({ target: { name: "value", value: e } })} className="rate" /><span className="span"></span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="writePara">
                                        <textarea className='w-full' placeholder='Write Your Review' name='text' value={review?.text} disabled={review?.posted ? true : false} onChange={enteringReview}></textarea>
                                    </div>
                                    <div className="form">
                                        {!review?.posted ?
                                            <Button className='btnComment' disabled={review?.posted ? true : false} loading={loadingReview} onClick={SubmitReview}>{review?.posted ? "Posted" : "Post"}</Button>
                                            : ""
                                        }
                                    </div>
                                </div>
                            </div>
                    }

                    <div className="commentsBox">
                        {
                            !UserData ?
                                <>

                                </>
                                :
                                <div className="writeComment">
                                    <div className="img">
                                        <img className='userPic' src={ImgURLGEN(UserData?.profileImage)} alt="Error" />
                                    </div>
                                    <Input className='inputComment' placeholder='Write Your Comment' value={comment} onChange={(e) => setComment(e.target.value)} />
                                    <Button className='btnComment' loading={loadingComment} onClick={SubmitComment}> Post comment </Button>
                                </div>
                        }
                        <div className="title">
                            Comments
                        </div>
                        {
                            postedComments ?
                                postedComments.length >= 1 ?
                                    postedComments.map(comment => {
                                        return (
                                            <>
                                                <div className="comments">
                                                    <div className="leftDetails">
                                                        <div className="avater">
                                                            <img src={ImgURLGEN(comment?.UserData?.profileImage)} alt="ERROR" />
                                                        </div>
                                                        <div className="commentGiverprofile">
                                                            <div className="name">{comment?.UserData?.firstName} {comment?.UserData?.lastName}</div>
                                                            <div className="time">
                                                                {new Date(comment?.UserData?.createdAt).toLocaleDateString('en-US', {
                                                                    day: 'numeric',
                                                                    month: 'short',
                                                                    year: 'numeric',
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="details">
                                                        <div className="bio">{comment?.text}</div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })
                                    :
                                    "No Comment Found"
                                :
                                null
                        }

                    </div>
                </div>

                <div className="sidebar">
                    <SideBar className="sidebarInner" selectedBlog={selectedBlog} bgWhite={bgWhite} padding={padding} />
                </div>
            </div>
        </>
    )
}

export default SingleBlog