import React from 'react'

import { ReactComponent as Scroll } from '../../../Assets/Images/scrollBtn.svg'

import './ScrollUpButton.scss'





const ScrollButton = () => {

    const scrolling = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    return (
        <button className='scroll-btn cursor' onClick={scrolling}>
            <Scroll className='icon '/>
        </button>
    )
}

export default ScrollButton
