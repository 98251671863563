import React, { useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom';

// Components :
import SideBar from './Components/SideBar/SideBar';
import SingleBlog from './Pages/SingleBlog/SingleBlog';


// CSS :
import "./Blogs.scss";
import MyCard from './Pages/AllBlog/AllBlog';





const Blogs = () => {
    let location = useLocation()

    return (
        <>
            <div className="containerMainBlogs">
                <div className="imgContainer">

                    <div className="blogImgText">
                        <div className="textContainer">
                            <h1>Top Blogs from around the World</h1>
                            <p>
                                Madrasa's Blog, authored by Quran and Islam experts from around the world,
                                provides simplified articles on Quran, Islam, and Arabic studies to assist
                                global Muslims in learning about reading, recitation, tajweed, memorization,
                                Tafseer, Pillars of Islam, Faith, Hadith, and the Biography of Prophet Muhammad (PBUH).
                            </p>
                        </div>
                    </div>
                </div>
                <div className={`blogsContainer container ${location.pathname !== "/blogs" && "reverseFlex"}`}>
                    <Routes>
                        <Route path='/' element={<MyCard />} />
                        <Route path='blog' element={<SingleBlog />} />
                    </Routes>
                </div>
            </div>
        </>
    )
}

export default Blogs;