import React, { useState } from 'react'


// MUI components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// Ant Design Components
import { Button } from 'antd';
import Checkbox from '@mui/material/Checkbox';

// Styling
import './Bag.scss'


// Helpers
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ImgURL from '../../Utils/ImgUrlGen';
import { toast } from 'react-toastify';
import PrimaryButton from '../../Components/Atoms/PrimaryButton/PrimaryButton';
import { AiOutlineDelete } from 'react-icons/ai';
import { CartsDataActions } from 'Redux/Slice/Carts';
import MessagePrompt from 'Components/MessagePrompt/MessagePrompt';
import { message } from 'antd';



export default function Bag() {
    const dispatch = useDispatch()

    const [check, setCheck] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [message, setMessage] = useState(false)
    const [AllreadyEmpty, setAllreadyEmpty] = useState(false)
    const [loading, setLoading] = useState(false)

    const showModal = () => {
        setIsModalOpen(true);
    };
    const navigate = useNavigate()
    const checkboxChange = (e) => {
        setCheck(e.target.checked)
    };

    const handleDeleteBook = async (bookId) => {
        let res = dispatch(CartsDataActions?.removeCartItem(bookId));
        if (res.payload != null || res.payload != undefined || res.payload != "") {
            setMessage(true)
            showModal()

            setTimeout(() => {
                setMessage(false)
                setIsModalOpen(false)
            }, 700);
        }
    }

    const handleClearItem = () => {
        // toast.success("Items Remove Successfully")
        setLoading(true);
        let localCart = localStorage.getItem("cartData")
        if (!localCart) {
            setAllreadyEmpty(true)
            showModal()
            setTimeout(() => {
                setAllreadyEmpty(false)
                setIsModalOpen(false)
            }, 1500);
            return
        }
        localStorage.removeItem('cartData')
        showModal()
        setTimeout(() => {
            setIsModalOpen(false)
            window.location.reload(true)
        }, 1500);
        setLoading(false);

    }

    const bookData = useSelector((state) => state?.CartsData)

    const totalPrice = bookData.reduce((sum, item) => sum + parseInt(item.price), 0);
    return (
        <>
            <div className="bagMainContainer">
                <div className="bagHeader">
                    <div className="head">Shopping Cart</div>
                </div>
                <div className="bagBody">
                    <div className="bagProductsMainContainer">
                        <TableContainer className='tablemain'>
                            {/* <TableContainer className='tablemain' component={Paper}> */}
                            <Table className="table" sx={{ minWidth: 650 }} aria-label="a dense table">
                                <TableHead className="table">
                                    <TableCell className='tablecell tablehead'>Product</TableCell>
                                    <TableCell className='tablecell tablehead'>Name</TableCell>
                                    <TableCell className='tablecell tablehead'>Auther</TableCell>
                                    <TableCell className='tablecell tablehead'>Publisher</TableCell>
                                    <TableCell className='tablecell tablehead'>Price</TableCell>
                                    <TableCell className='tablecell tablehead'>Delete</TableCell>
                                </TableHead>
                                <TableBody className="table ">
                                    {bookData?.map((data, i) => (

                                        <TableRow
                                            className='tablebody'
                                            key={i}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell className='tablecell bookDetail'>

                                                <img className='imageBag' src={ImgURL(data?.image)} alt="" />
                                            </TableCell>
                                            <TableCell className='tablecell bookname'>{data?.title}</TableCell>
                                            <TableCell className='tablecell bookAuther'>{data?.auther?.firstName} {data?.auther?.lastName}</TableCell>
                                            <TableCell className='tablecell bookOwner'>{data?.publisher}</TableCell>
                                            <TableCell className='tablecell price'>${data?.price}</TableCell>
                                            <TableCell className='tablecell delete' ><AiOutlineDelete className="icon" size={24} onClick={() => handleDeleteBook(data?._id)} /></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="clearOut">
                            <PrimaryButton className='' heading={"Clear out"} primaryFun={handleClearItem} loading={loading} />
                            {isModalOpen && <MessagePrompt isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} message={message ? "Item Remove Successfully" : AllreadyEmpty ? "Cart is Empty" : "Items Remove Successfully"} />}
                        </div>
                    </div>
                    <div className="totalMainContainer">
                        <div className="headTotal">Cart Total</div>
                        <div className="totalContainer">
                            <div className="subtotalMain flexLine">
                                <div className="head boldhead">Sub Total:</div>
                                <div className="pricePara boldhead">${totalPrice}</div>
                            </div>
                            <div className="totalMain flexLine">
                                <div className="head boldhead">Total:</div>
                                <div className="pricePara boldhead">${totalPrice}</div>
                            </div>
                            <div className="checkbox">
                                Shipping & taxes calculated at checkout
                                {/* <Checkbox className='checkbtn' style={{height:"10px"}} defaultChecked disabled onChange={checkboxChange} /> Shipping & taxes calculated at checkout */}
                            </div>
                            {
                                totalPrice == 0 ? (
                                    <Button disabled className='checkoutBtn' onClick={() => { navigate('/libarary/detail/checkOut') }}>Proceed To Checkout</Button>
                                ) :
                                    <Button className='checkoutBtn' onClick={() => { navigate('/libarary/detail/checkOut') }}>Proceed To Checkout</Button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
