import axios from "../AxiosInstance";





const GetAllBooksAPI = async () => {
    let resolved = {
        error: null,
        data: null
    }

    try {
        let res = await axios({
            url: "/book/public",
            method: "GET",

        })
        resolved.data = res.data
    } catch (err) {
        if (err && err.response && err?.response?.data?.message) {
            resolved.error = err.response.data.message
        } else {
            resolved.error = "Something went Wrong"
        }
    }
    return resolved;
}


const CheckBooksSold = async (currentUser, bookId) => {
    let resolved = {
        error: null,
        data: null
    }

    try {
        let res = await axios({

            url: `/transaction/checkbook/${currentUser}/${bookId}`,
            method: "POST",
            data: currentUser,

        })
        resolved.data = res.data
    } catch (err) {
        if (err && err.response && err?.response?.data?.message) {
            resolved.error = err.response.data.message
        } else {
            resolved.error = "Something went Wrong"
        }
    }
    return resolved;
}



export { GetAllBooksAPI, CheckBooksSold };