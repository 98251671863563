import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BiUser, BiCloudDownload } from "react-icons/bi";
import { TfiEmail } from "react-icons/tfi";
import PrimaryButton from "../../Atoms/PrimaryButton/PrimaryButton";
import { toast } from "react-toastify";
import { FreeTransactionUser } from "../../../API/transaction";
import { useSelector } from "react-redux";
import MessagePrompt from "Components/MessagePrompt/MessagePrompt";
import "./FreeBook.scss"
import { Modal } from "antd";


const FreeBook = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [submitted, setSubmitted] = useState(false);
  const [bookDetail, setBookDetail] = useState();
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState({
    firstName: null,
    lastName: null,
    email: null,
    agreeTerms: null,
  });
  const [checked, setChecked] = useState(false);
  const [fileUrl, setFileUrl] = useState(null)
  const [popUp, openPopUp] = useState(false)
  const location = new useLocation()


  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const bookData = location?.state?.bookData
  const userData = useSelector(state => state?.UserData)
  useEffect(() => {
    if (userData == null || userData == undefined) {
      setFormValues({ ...formValues, email: "", firstName: "", lastName: "" })
    } else {
      setFormValues({ ...formValues, email: userData?.email, firstName: userData?.firstName, lastName: userData?.lastName })
    }

  }, [])

  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;

    switch (name) {
      case "firstName":
        if (value.length <= 0) {
          setFormError({
            ...formError,
            firstName: "A first name is requried.",
          });
        } else if (!/^[A-Za-z]*$/.test(value)) {
          setFormError({
            ...formError,
            firstName:
              "Invalid format. Please enter a first name without numbers or special characters.",
          });
        } else {
          setFormError({
            ...formError,
            firstName: null,
          });
        }
        break;
      case "lastName":
        if (value.length <= 0) {
          setFormError({
            ...formError,
            lastName: "A last name is requried.",
          });
        } else if (!/^[A-Za-z]*$/.test(value)) {
          setFormError({
            ...formError,
            lastName:
              "Invalid format. Please enter a last name without numbers or special characters.",
          });
        } else {
          setFormError({
            ...formError,
            lastName: null,
          });
        }
        break;
      case "email":
        if (
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            value
          )
        ) {
          setFormError({
            ...formError,
            email: "Invalid email address. Please enter a valid email address.",
          });
        } else {
          setFormError({
            ...formError,
            email: null,
          });
        }
        break;
      case "agreeTerms":
        setChecked(checked);
        setFormError({
          ...formError,
          agreeTerms: checked ? null : "Terms & Conditions is required.",
        });
      default:
        break;
    }

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const submit = async () => {
    // showModal()
    // return

    setLoading(true);

    if (
      !formValues.firstName ||
      !formValues.lastName ||
      !formValues.email ||
      !checked
    ) {
      setFormError({
        firstName: formValues.firstName ? null : "First name is required.",
        lastName: formValues.lastName ? null : "Last name is required.",
        email: formValues.email ? null : "Email is required.",
        agreeTerms: checked ? null : "Terms & Conditions is required.",
      });

      toast?.error("Please fill in all the required fields and agree to the Terms & Conditions.");
       setLoading(false);
      return;

    }
    let payload = {
      sources: [bookData?._id],
      orderType: "book",
      shippingDetails: formValues,
      orderPrice: bookData?.price
    }
    let res = await FreeTransactionUser(payload)
    setLoading(true);
    if (res.error != null) {
      toast.error(res.error)
    } else {
      openPopUp(true)
      setBookDetail(res?.data?.result)
      // toast.success(res.data?.message)
      setFileUrl(res.data?.url)
      setSubmitted(true);
      showModal()
      setFormValues(initialValues)
      setLoading(false);

    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const handleImageDownload = () => {
    const link = document.createElement('a');
    if (!fileUrl) return toast.error("Book Not Found");
    link.href = fileUrl
    link.download = 'book.pdf';
    link.target = "_blank"
    link.click();
  };

  return (
    <>
      <section className="freeBookSec DBlock py-16 px-6">

        <div className="max-w-7xl mx-auto py-14 pt-20 relative ">
          {/* <p className="flex cursor-pointer py-5" onClick={() => { navigate('/libarary/detail') }}  ><ArrowLeft style={{ cursor: "pointer" ,color:"#FCD117" }} /> <span className="px-3">Return to Detail Page</span></p> */}
          <div
            onSubmit={handleSubmit}
            method="POST"
            className="max-w-7xl grid grid-cols-1 md:grid-cols-2  gap-4 sm:gap-10 px-0 md:px-7 lg:px-4  pb-12"
          >
            <div className="col-span-2 md:col-span-1">
              <div className="relative">
                <span className="absolute top-5 pl-10 flex items-center">
                  <BiUser className="h-5 w-5 text-gray-400" />
                </span>
                <input
                  onChange={handleFormChange}
                  value={formValues.firstName}
                  type="text"
                  name="firstName"
                  id="first-name"
                  placeholder="First Name"
                  autoComplete="name"
                  // className="block w-full rounded-md bg-white shadow hover:shadow hover:shadow-lightY focus:border focus:border-lightY  hover:border-lightY hover:border  border-4 border-transparent px-3 py-6 pl-20"
                  className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-4 pl-16 "
                />
                {formError.firstName && (
                  <div className="h-5 pt-2 text-sm text-red-500">
                    {formError.firstName}
                  </div>
                )}
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="relative">
                <span className="absolute top-5 pl-10 flex items-center">
                  <BiUser className="h-5 w-5 text-gray-400" />
                </span>
                <input
                  onChange={handleFormChange}
                  value={formValues.lastName}
                  type="text"
                  name="lastName"
                  id="last-name"
                  placeholder="Last Name"
                  autoComplete="name"
                  className="block w-full rounded-md bg-white shadow border-4 border-transparent px-3 py-4 pl-16 "
                />
                {formError.lastName && (
                  <div className="h-5 pt-2 text-sm text-red-500">
                    {formError.lastName}
                  </div>
                )}
              </div>
            </div>
            <div className="col-span-2 block w-full">
              <div className="relative">
                <span className="absolute top-5 pl-10 flex items-center">
                  <TfiEmail className="h-5 w-5 text-gray-400" />
                </span>
                <input
                  onChange={handleFormChange}
                  value={formValues.email}
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email"
                  autoComplete="name"
                  className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-4 pl-16 "
                />
                {formError.email && (
                  <div className="h-5 pt-2 pb-8 text-sm text-red-500">
                    {formError.email}
                  </div>
                )}
              </div>
            </div>

            <div className="px-1">
              <div className="flex ">
                <input
                  type="checkbox"
                  name="agreeTerms"
                  required
                  checked={checked}
                  onChange={handleFormChange} className="pl-2" />
                <p className="px-2 text-gray-400  text-sm md:text-base">
                  Agree with the <span className="text-yellow">Terms & Conditions</span>
                </p>
              </div>
              {formError.agreeTerms && (
                <div className="h-5 pt-2 text-sm text-red-500">
                  {formError.agreeTerms}
                </div>
              )}
            </div>

            <div className="col-span-2 block w-full justify-center flex">
              <PrimaryButton heading="Continue" primaryFun={submit} loading={loading}/>

            </div>
          </div>

          {/* {submitted && (
            <div className="grid grid-cols-1">
              <div className="w-full md:w-3/6 mx-auto">
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 pl-2 md:pl-5 flex items-center">
                    <BiCloudDownload className="h-6 w-7 text-gray-400" />
                  </span>
                  <div className="block w-full rounded-md bg-white shadow-lg  border-4 border-transparent px-3 py-4  pl-10 md:pl-16  text-xs sm:text-sm md:text-base">
                    {"Download your book from here"}
                  </div>
                </div>
              </div>
              <div className="col-span-2 block w-full justify-center flex pt-10">
                <PrimaryButton heading="Download" primaryFun={handleImageDownload} />
              </div>
            </div>
          )} */}
        </div>

        {submitted && <div className="flex items-center justify-center popUpBook">
          <MessagePrompt isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} message={"Operation Successful"} heading="Download" url={"Download your book from here"} primaryFun={handleImageDownload} />
        </div>}
      </section>
    </>
  );
};

export default FreeBook;
