export const countries = [
  {
    name: "Afghanistan",
    cities: [
      "Herat",
      "Kabul",
      "Kandahar",
      "Molah",
      "Rana",
      "Shar",
      "Sharif",
      "Wazir Akbar Khan",
    ],
  },
  {
    name: "Albania",
    cities: [
      "Elbasan",
      "Petran",
      "Pogradec",
      "Shkoder",
      "Tirana",
      "Ura Vajgurore",
    ],
  },
  {
    name: "Algeria",
    cities: [
      "Algiers",
      "Annaba",
      "Azazga",
      "Batna City",
      "Blida",
      "Bordj",
      "Bordj Bou Arreridj",
      "Bougara",
      "Cheraga",
      "Chlef",
      "Constantine",
      "Djelfa",
      "Draria",
      "El Tarf",
      "Hussein Dey",
      "Illizi",
      "Jijel",
      "Kouba",
      "Laghouat",
      "Oran",
      "Ouargla",
      "Oued Smar",
      "Relizane",
      "Rouiba",
      "Saida",
      "Souk Ahras",
      "Tamanghasset",
      "Tiaret",
      "Tissemsilt",
      "Tizi",
      "Tizi Ouzou",
      "Tlemcen",
    ],
  },
  {
    name: "Andorra",
    cities: [
      "Andorra la Vella",
      "Canillo",
      "Encamp",
      "Engordany",
      "Escaldes-Engordany",
      "La Massana",
      "Llorts",
      "Ordino",
      "Santa Coloma",
      "Sispony",
      "Soldeu",
    ],
  },
  {
    name: "Angola",
    cities: [
      "Ambriz",
      "Benguela",
      "Cabinda",
      "Cacole",
      "Camabatela",
      "Cazeta",
      "Huambo",
      "Kuito",
      "Lobito",
      "Luanda",
      "Lubango",
      "Lucapa",
      "Lumeje",
      "Malanje",
      "Menongue",
      "Muxaluando",
      "Namibe",
      "Ondjiva",
      "Piri",
      "Saurimo",
      "Talatona",
    ],
  },
  {
    name: "Antigua and Barbuda",
    cities: [
      "All Saints",
      "Cassada Gardens",
      "Codrington",
      "Old Road",
      "Parham",
      "Woods",
    ],
  },
  {
    name: "Argentina",
    cities: [
      "28 de Noviembre",
      "Abasto",
      "Acassuso",
      "Acebal",
      "Acevedo",
      "Adelia Maria",
      "Agua de Oro",
      "Albardon",
      "Albarellos",
      "Alberdi",
      "Alberti",
      "Aldo Bonzi",
      "Alejandro Korn",
      "Alicia",
      "Allen",
      "Almafuerte",
      "Almagro",
      "Almirante Brown",
      "Alta Gracia",
      "Alta Italia",
      "Alvarez",
      "Alvear",
      "Anatuya",
      "Angelica",
      "Antonio Carboni",
      "Apostoles",
      "Arequito",
      "Armstrong",
      "Arrecifes",
      "Arroyito",
      "Arroyito Challaco",
      "Arroyo Seco",
      "Arteaga",
      "Asamblea",
      "Ascension",
      "Avellaneda",
      "Ayacucho",
      "Azara",
      "Azul",
      "Balcarce",
      "Balnearia",
      "Banda del Rio Sali",
      "Bandera",
      "Banfield",
      "Baradero",
      "Bariloche",
      "Barrio Fisherton",
      "Batan",
      "Beccar",
      "Belen de Escobar",
      "Belgrano",
      "Bell Ville",
      "Bella Vista",
      "Bella Vista",
      "Benavidez",
      "Berazategui",
      "Berisso",
      "Bernal",
      "Bernardo Larroude",
      "Bernasconi",
      "Bigand",
      "Bombal",
      "Bordenave",
      "Bosch",
      "Bosques",
      "Boulogne",
      "Bovril",
      "Bragado",
      "Brandsen",
      "Brinkmann",
      "Buenos Aires",
      "Burzaco",
      "Bustinza",
      "Caballito",
      "Calderon",
      "Caleta Olivia",
      "Caleufu",
      "Camilo Aldao",
      "Campana",
      "Canada de Gomez",
      "Canada del Ucle",
      "Canada Rosquin",
      "Canals",
      "Canning",
      "Canuelas",
      "Capilla del Monte",
      "Capilla del Senor",
      "Capitan Bermudez",
      "Carhue",
      "Carlos Casares",
      "Carlos Pellegrini",
      "Carlos Tejedor",
      "Caseros",
      "Casilda",
      "Castelar",
      "Castelli",
      "Castillo",
      "Catriel",
      "Catrilo",
      "Cavanagh",
      "Centenario",
      "Ceres",
      "Cervantes",
      "Chacabuco",
      "Chacarita",
      "Chajari",
      "Charata",
      "Chateaubriand",
      "Chilecito",
      "Chivilcoy",
      "Choele Choel",
      "Chorroarin",
      "Cinco Saltos",
      "Cipolletti",
      "City Bell",
      "Ciudad General Belgrano",
      "Ciudadela",
      "Claypole",
      "Clorinda",
      "Colon",
      "Colon",
      "Colonia Baron",
      "Colonia Caroya",
      "Colonia San Miguel Arcangel",
      "Comodoro Rivadavia",
      "Concepcion",
      "Concepcion",
      "Concordia",
      "Constituyentes",
      "Coronel Dorrego",
      "Coronel Martinez de Hoz",
      "Coronel Pringles",
      "Corral de Bustos",
      "Corralitos",
      "Corrientes",
      "Cosquin",
      "Coy Aike",
      "Cramer",
      "Crespo",
      "Cruz del Eje",
      "Curuzu Cuatia",
      "Cutral-Co",
      "Darregueira",
      "De Mayo",
      "Del Campillo",
      "Del Viso",
      "Despenaderos",
      "Devoto",
      "Diaz",
      "Diego de Alvear",
      "Doblas",
      "Dock Sud",
      "Dolores",
      "Don Bosco",
      "Don Torcuato",
      "Drabble",
      "Eduardo Castex",
      "El Calafate",
      "El Dorado",
      "El Hoyo",
      "El Palomar",
      "El Palomar",
      "El Talar",
      "El Trebol",
      "Eldorado",
      "Embalse",
      "Empalme Lobos",
      "Ensenada",
      "Esperanza",
      "Esquel",
      "Esteban Echeverria",
      "Ezeiza",
      "Ezpeleta",
      "Famailla",
      "Fatima",
      "Federal",
      "Fernandez",
      "Firmat",
      "Florencio Varela",
      "Florentino Ameghino",
      "Flores",
      "Floresta",
      "Florida",
      "Formosa",
      "Francisco Alvarez",
      "Franck",
      "Fray Luis A. Beltran",
      "Freyre",
      "Frias",
      "Funes",
      "Gaiman",
      "Galvez",
      "Garin",
      "Garupa",
      "General Acha",
      "General Alvear",
      "General Cabrera",
      "General Deheza",
      "General Guido",
      "General Juan Madariaga",
      "General Lagos",
      "General Las Heras",
      "General Lavalle",
      "General Mansilla",
      "General Martin Miguel de Guemes",
      "General Pacheco",
      "General Paz",
      "General Pico",
      "General Roca",
      "General Roca",
      "General Rodriguez",
      "General San Martin",
      "General San Martin",
      "General Viamonte",
      "General Villegas",
      "Germania",
      "Glew",
      "Gobernador Crespo",
      "Gobernador Galvez",
      "Godoy",
      "Godoy Cruz",
      "Gonzalez Catan",
      "Gonzalez Moreno",
      "Goya",
      "Granadero Baigorria",
      "Grand Bourg",
      "Gualeguay",
      "Guatrache",
      "Guernica",
      "Henderson",
      "Hernando",
      "Hersilia",
      "Hilario",
      "Hilario Ascasubi",
      "Hipatia",
      "Hipolito Yrigoyen",
      "Huanchillas",
      "Huanguelen",
      "Huinca Renanco",
      "Humahuaca",
      "Hurlingham",
      "Ibarlucea",
      "Ibicuy",
      "Independencia",
      "Ingeniero Beaugey",
      "Ingeniero Luiggi",
      "Ingeniero Maschwitz",
      "Intendente Alvear",
      "Isidro Casanova",
      "Ituzaingo",
      "James Craik",
      "Jauregui",
      "Jeppener",
      "Jesus Maria",
      "Jose Leon Suarez",
      "Jose Marmol",
      "Juan Pujol",
      "Justiniano Posse",
      "La Banda",
      "La Boca",
      "La Calera",
      "La Cumbre",
      "La Falda",
      "La Leonesa",
      "La Lucila",
      "La Madrid",
      "La Pampa",
      "La Para",
      "La Paz",
      "La Plata",
      "La Punta",
      "La Rioja",
      "La Tablada",
      "La Union",
      "La Violeta",
      "Laborde",
      "Laboulaye",
      "Laferrere",
      "Laguna Alsina",
      "Lanus",
      "Larroque",
      "Las Catitas",
      "Las Flores",
      "Las Heras",
      "Las Perdices",
      "Las Rosas",
      "Las Talitas",
      "Las Varillas",
      "Lavalle",
      "Leandro N. Alem",
      "Leones",
      "Libertad",
      "Lima",
      "Liniers",
      "Llavallol",
      "Lobos",
      "Lomas de Zamora",
      "Lomas del Mirador",
      "Longchamps",
      "Los Antiguos",
      "Los Cardales",
      "Los Molinos",
      "Los Polvorines",
      "Luis Guillon",
      "Lujan de Cuyo",
      "Luque",
      "Luzuriaga",
      "Lynch",
      "Macachin",
      "Magdalena",
      "Magdalena",
      "Maggiolo",
      "Maipu",
      "Manantial",
      "Manfredi",
      "Manuel J. Cobo",
      "Maquinista Savio",
      "Mar de Ajo",
      "Mar del Plata",
      "Mar del Tuyu",
      "Marcos Juarez",
      "Marcos Paz",
      "Margarita",
      "Maria Ignacia",
      "Maria Juana",
      "Mariano Acosta",
      "Mariano J. Haedo",
      "Mariano Moreno",
      "Martinez",
      "Matheu",
      "Mayor Buratovich",
      "Melincue",
      "Mendiolaza",
      "Mendoza",
      "Mercedes",
      "Merlo",
      "Minacar",
      "Miramar",
      "Monje",
      "Monte Hermoso",
      "Monteros",
      "Montserrat",
      "Moreno",
      "Moron",
      "Morteros",
      "Muniz",
      "Munro",
      "Navarro",
      "Necochea",
      "Nogoya",
      "Nordelta",
      "Nunez",
      "Obera",
      "Oliva",
      "Oliveros",
      "Olivos",
      "Oncativo",
      "Open Door",
      "Ordonez",
      "Palermo",
      "Palmira",
      "Palpala",
      "Partido de Jose C. Paz",
      "Pasco",
      "Paso del Rey",
      "Paternal",
      "Pavon",
      "Pedernales",
      "Pedro Luro",
      "Pellegrini",
      "Perez",
      "Pergamino",
      "Perico",
      "Perito Moreno",
      "Piamonte",
      "Pico de Salamanca",
      "Pico Truncado",
      "Pigue",
      "Pilar",
      "Pinamar",
      "Piquete Cabado",
      "Platanos",
      "Plaza Huincul",
      "Plottier",
      "Pontevedra",
      "Portena",
      "Posadas",
      "Pozo del Molle",
      "Presidente Derqui",
      "Puan",
      "Pueblo San Jose",
      "Puerto Madryn",
      "Puerto Rico",
      "Pueyrredon",
      "Punta Alta",
      "Quilmes",
      "Rada Tilly",
      "Rafael Calzada",
      "Rafael Castillo",
      "Rafael Obligado",
      "Rafaela",
      "Ramallo",
      "Ramos Mejia",
      "Ranchos",
      "Rancul",
      "Ranelagh",
      "Rawson",
      "Rawson",
      "Realico",
      "Recoleta",
      "Reconquista",
      "Remedios de Escalada",
      "Resistencia",
      "Retiro",
      "Rio Ceballos",
      "Rio Colorado",
      "Rio Grande",
      "Rio Piedras",
      "Rio Segundo",
      "Rio Tercero",
      "Rivadavia",
      "Rocamora",
      "Rodriguez Pena",
      "Rojas",
      "Roldan",
      "Roque Perez",
      "Rosario",
      "Rosas",
      "Rufino",
      "Sacanta",
      "Saenz Pena",
      "Saladillo",
      "Salguero",
      "Salsipuedes",
      "Salta",
      "Salto Grande",
      "Sampacho",
      "San Andres",
      "San Andres de Giles",
      "San Antonio de Areco",
      "San Antonio de Arredondo",
      "San Antonio de Obligado",
      "San Antonio de Padua",
      "San Antonio Oeste",
      "San Benito",
      "San Bernardo",
      "San Carlos de Bolivar",
      "San Cayetano",
      "San Clemente",
      "San Cristobal",
      "San Fernando",
      "San Fernando del Valle de Catamarca",
      "San Francisco",
      "San Francisco de Santa Fe",
      "San Francisco Solano",
      "San Genaro",
      "San Gregorio",
      "San Guillermo",
      "San Isidro",
      "San Isidro de Lules",
      "San Javier",
      "San Jeronimo Norte",
      "San Jorge",
      "San Jose",
      "San Jose de la Esquina",
      "San Juan",
      "San Justo",
      "San Lorenzo",
      "San Luis",
      "San Manuel",
      "San Martin",
      "San Martin de las Escobas",
      "San Miguel",
      "San Miguel del Monte",
      "San Nicolas",
      "San Pedro",
      "San Rafael",
      "San Salvador",
      "San Salvador de Jujuy",
      "San Telmo",
      "San Vicente",
      "Sanchez",
      "Santa Clara de Saguier",
      "Santa Elena",
      "Santa Fe",
      "Santa Lucia",
      "Santa Rita",
      "Santa Rosa",
      "Santa Teresita",
      "Santiago del Estero",
      "Santo Tome",
      "Santos Lugares",
      "Sarandi",
      "Sarmiento",
      "Segui",
      "Sierra de la Ventana",
      "Sierra de los Padres",
      "Sinsacate",
      "Suipacha",
      "Sunchales",
      "Tablada",
      "Tacuari",
      "Tafi Viejo",
      "Tandil",
      "Tapalque",
      "Tapiales",
      "Temperley",
      "Teodelina",
      "Thames",
      "Tigre",
      "Tio Pujio",
      "Todd",
      "Tornquist",
      "Tortuguitas",
      "Tostado",
      "Totoras",
      "Trelew",
      "Trenque Lauquen",
      "Tres Arroyos",
      "Trevelin",
      "Tristan Suarez",
      "Tunuyan",
      "Tupungato",
      "Turdera",
      "Ucacha",
      "Uriburu",
      "Ushuaia",
      "Valle Hermoso",
      "Vedia",
      "Veinticinco de Mayo",
      "Venado Tuerto",
      "Vera",
      "Veronica",
      "Viale",
      "Viamonte",
      "Vicente Lopez",
      "Victoria",
      "Vicuna Mackenna",
      "Viedma",
      "Villa Aberastain",
      "Villa Adelina",
      "Villa Allende",
      "Villa Alsina",
      "Villa Amelia",
      "Villa Angela",
      "Villa Ballester",
      "Villa Bosch",
      "Villa Canas",
      "Villa Carlos Paz",
      "Villa Constitucion",
      "Villa de Maria",
      "Villa de Mayo",
      "Villa del Parque",
      "Villa Dolores",
      "Villa Dominico",
      "Villa Elisa",
      "Villa General Belgrano",
      "Villa Gesell",
      "Villa Giardino",
      "Villa Huidobro",
      "Villa Insuperable",
      "Villa La Angostura",
      "Villa Las Rosas",
      "Villa Lugano",
      "Villa Luzuriaga",
      "Villa Madero",
      "Villa Maria Grande",
      "Villa Media Agua",
      "Villa Mercedes",
      "Villa Nueva",
      "Villa Nueva",
      "Villa Ocampo",
      "Villa Paranacito",
      "Villa Regina",
      "Villa Robles",
      "Villa Rosa",
      "Villa Trinidad",
      "Villa Urquiza",
      "Villaguay",
      "Vuelta de Obligado",
      "Warnes",
      "Wheelwright",
      "Wilde",
      "Winifreda",
      "Yerba Buena",
      "Zenon Pereyra",
    ],
  },
  {
    name: "Armenia",
    cities: [
      "Abovyan",
      "Agarak",
      "Apaga",
      "Aparan",
      "Arabkir",
      "Ashtarak",
      "Erebuni Fortress",
      "Hrazdan",
      "Ijevan",
      "Jermuk",
      "Kapan",
      "Tsaghkadzor",
      "Vanadzor",
      "Yerevan",
    ],
  },
  {
    name: "Australia",
    cities: ["Abbotsford",
    "Abbotsham",
    "Aberdeen",
    "Aberfoyle",
    "Aberglasslyn",
    "Abermain",
    "Acacia Ridge",
    "Adamstown",
    "Adelaide",
    "Adelong",
    "Advancetown",
    "Agnes Banks",
    "Agnes Water",
    "Airlie Beach",
    "Aitkenvale",
    "Albany",
    "Albany Creek",
    "Albert Park",
    "Alberton",
    "Albion",
    "Albion Park",
    "Albury",
    "Aldgate",
    "Aldinga",
    "Aldinga Beach",
    "Alexandra",
    "Alexandra",
    "Alexandria",
    "Alfredton",
    "Alice Springs",
    "Allambee",
    "Allansford",
    "Allanson",
    "Allora",
    "Alma",
    "Alphington",
    "Alpine",
    "Alstonville",
    "Alton Downs",
    "Altona",
    "Amamoor",
    "Amaroo",
    "Angaston",
    "Anglesea",
    "Anna Bay",
    "Annandale",
    "Apollo Bay",
    "Applecross",
    "Applethorpe",
    "Ararat",
    "Arcadia",
    "Argents Hill",
    "Armadale",
    "Armstrong Creek",
    "Arno Bay",
    "Arrawarra",
    "Artarmon",
    "Ascot",
    "Ascot Vale",
    "Ashburton",
    "Ashfield",
    "Ashgrove",
    "Ashton",
    "Aspendale",
    "Aspley",
    "Asquith",
    "Atherton",
    "Attadale",
    "Auburn",
    "Augusta",
    "Austinmer",
    "Austins Ferry",
    "Australind",
    "Avalon",
    "Avenue",
    "Avoca Beach",
    "Avondale",
    "Axedale",
    "Aylmerton",
    "Ayr",
    "Babinda",
    "Bacchus Marsh",
    "Bagdad",
    "Bahrs Scrub",
    "Bairnsdale",
    "Baker",
    "Balaclava",
    "Balaklava",
    "Bald Hills",
    "Balgowlah",
    "Balgownie",
    "Balhannah",
    "Ballan",
    "Ballarat",
    "Ballina",
    "Balmain",
    "Balmoral",
    "Balnarring",
    "Balwyn",
    "Balwyn North",
    "Bangalow",
    "Bangor",
    "Banksmeadow",
    "Bankstown",
    "Bannockburn",
    "Barcoo",
    "Barellan",
    "Bargara",
    "Bargo",
    "Baringhup",
    "Barkstead",
    "Barnsley",
    "Barry",
    "Barwon Downs",
    "Barwon Heads",
    "Barwon Heads",
    "Basin View",
    "Bass Hill",
    "Bassendean",
    "Batchelor",
    "Bateau Bay",
    "Batesford",
    "Bathurst",
    "Batlow",
    "Baulkham Hills",
    "Baxter",
    "Bayles",
    "Baynton",
    "Bayswater",
    "Beachmere",
    "Beacon",
    "Beaconsfield",
    "Beaconsfield Upper",
    "Beaudesert",
    "Beaumaris",
    "Beaumont",
    "Beauty Point",
    "Bedford Park",
    "Bedfordale",
    "Beeac",
    "Beechwood",
    "Beechworth",
    "Beenak",
    "Beenleigh",
    "Beerwah",
    "Bega",
    "Belair",
    "Belconnen",
    "Belgian Gardens",
    "Belgrave",
    "Belgrave Heights",
    "Bell",
    "Bellambi",
    "Bellara",
    "Bellbird",
    "Bellbrae",
    "Bellerive",
    "Bellevue Hill",
    "Belmont",
    "Belrose",
    "Benalla",
    "Benambra",
    "Bendigo",
    "Benowa",
    "Bensville",
    "Bentleigh",
    "Bentleigh East",
    "Bentley",
    "Beresfield",
    "Berkeley",
    "Berkeley Vale",
    "Berowra",
    "Berridale",
    "Berrima",
    "Berry",
    "Berwick",
    "Bethania Junction",
    "Beveridge",
    "Beverley",
    "Beverley",
    "Bexley",
    "Bicheno",
    "Bicton",
    "Big Pats Creek",
    "Bilinga",
    "Biloela",
    "Bilpin",
    "Binda",
    "Binnaway",
    "Birdwood",
    "Birkdale",
    "Birkenhead",
    "Black Hill",
    "Black Rock",
    "Blackburn",
    "Blackbutt",
    "Blackheath",
    "Blackmans Bay",
    "Blacktown",
    "Blackwall",
    "Blackwarry",
    "Blackwater",
    "Blackwood",
    "Blair Athol",
    "Blakehurst",
    "Blaxland",
    "Blayney",
    "Bli Bli",
    "Boambee",
    "Boat Harbour",
    "Bobin",
    "Boddington",
    "Bogangar",
    "Bohle Plains",
    "Bolton",
    "Bolwarra",
    "Bomaderry",
    "Bombala",
    "Bonbeach",
    "Bondi",
    "Boneo",
    "Bongaree",
    "Bonnells Bay",
    "Bonnet Bay",
    "Bonython",
    "Booker Bay",
    "Bool Lagoon",
    "Boolarra",
    "Boonah",
    "Boondall",
    "Booral",
    "Bordertown",
    "Boronia",
    "Botany",
    "Boulder",
    "Bourke",
    "Bowen",
    "Bowenfels",
    "Bowral",
    "Bowraville",
    "Box Hill",
    "Box Hill South",
    "Boyup Brook",
    "Bradbury",
    "Braddon",
    "Braeside",
    "Braidwood",
    "Brandon",
    "Brandy Hill",
    "Branxton",
    "Brewarrina",
    "Briar Hill",
    "Bridgetown",
    "Bridgewater",
    "Brighton",
    "Brighton East",
    "Brighton-Le-Sands",
    "Bringelly",
    "Brisbane",
    "Broadbeach",
    "Broadford",
    "Broadmeadows",
    "Broadwater",
    "Broadwater",
    "Broke",
    "Broken Hill",
    "Bronte",
    "Brookdale",
    "Brookfield",
    "Brooklyn",
    "Brookvale",
    "Broome",
    "Brown Hill",
    "Browns Plains",
    "Bruce",
    "Brunswick",
    "Buchan",
    "Buckland",
    "Buderim",
    "Budgewoi",
    "Bulahdelah",
    "Bulga",
    "Bulimba",
    "Bulla",
    "Bulleen",
    "Bulli",
    "Bullsbrook",
    "Bullyard",
    "Buln Buln",
    "Bunbury",
    "Bundaberg",
    "Bundalong",
    "Bundamba",
    "Bundanoon",
    "Bundeena",
    "Bundook",
    "Bundoora",
    "Bungendore",
    "Bungonia",
    "Buninyong",
    "Burekup",
    "Burleigh Heads",
    "Burnett Heads",
    "Burnie",
    "Burnley",
    "Burnside",
    "Burpengary",
    "Burradoo",
    "Burraneer",
    "Burrum",
    "Burrum Heads",
    "Burwood",
    "Burwood",
    "Bushland Beach",
    "Busselton",
    "Butchers Ridge",
    "Buxton",
    "Byford",
    "Byron Bay",
    "Caboolture",
    "Cairns",
    "Calen",
    "Calingiri",
    "Callala Bay",
    "Callington",
    "Caloundra",
    "Caltowie",
    "Camberwell",
    "Cambewarra",
    "Cambridge",
    "Camden",
    "Camden Haven",
    "Cammeray",
    "Campbell",
    "Campbellfield",
    "Campbelltown",
    "Camperdown",
    "Canadian",
    "Canberra",
    "Cannington",
    "Cannonvale",
    "Canterbury",
    "Canungra",
    "Capalaba",
    "Caragabal",
    "Caravonica",
    "Carbrook",
    "Cardiff",
    "Cardigan",
    "Cardinia",
    "Cardup",
    "Caringbah",
    "Carlingford",
    "Carlsruhe",
    "Carlton",
    "Carlton North",
    "Carlton South",
    "Carnarvon",
    "Carnegie",
    "Caroline Springs",
    "Carrick",
    "Carrington",
    "Carrum",
    "Carrum Downs",
    "Casino",
    "Casterton",
    "Castle Hill",
    "Castlecrag",
    "Castlemaine",
    "Caulfield",
    "Caulfield North",
    "Caulfield South",
    "Caversham",
    "Cawarral",
    "Cawongla",
    "Cedar Grove",
    "Ceduna",
    "Central Coast",
    "Cessnock",
    "Charlestown",
    "Chatswood",
    "Chatsworth Island",
    "Chelsea",
    "Cheltenham",
    "Chinchilla",
    "Chippendale",
    "Chipping Norton",
    "Christies",
    "Chullora",
    "Churchill",
    "City of Parramatta",
    "City of West Torrens",
    "Clare",
    "Claremont",
    "Clarence Town",
    "Clareville",
    "Clarinda",
    "Clarkefield",
    "Clayton North",
    "Cleveland",
    "Clifton",
    "Clifton Hill",
    "Clifton Springs",
    "Clontarf",
    "Closeburn",
    "Clovelly",
    "Clunes",
    "Clyde",
    "Clyde North",
    "Cobbitty",
    "Coburg",
    "Cockatoo",
    "Cockburn",
    "Coffs Harbour",
    "Colac",
    "Coleambally",
    "Coledale",
    "Coleraine",
    "Collaroy",
    "Collie",
    "Collingwood",
    "Collinsvale",
    "Colo",
    "Colo Vale",
    "Como",
    "Concord",
    "Condamine",
    "Condobolin",
    "Coniston",
    "Connells Point",
    "Coober Pedy",
    "Cooee",
    "Coogee",
    "Coogee",
    "Cook",
    "Cooktown",
    "Coolah",
    "Coolamon",
    "Coolangatta",
    "Cooloolabin",
    "Coolum",
    "Coolum Beach",
    "Coolup",
    "Cooma",
    "Coomera",
    "Coonawarra",
    "Coondoo",
    "Cooranbong",
    "Cooroy",
    "Coorparoo",
    "Cootamundra",
    "Copacabana",
    "Copmanhurst",
    "Corinda",
    "Corinthia",
    "Corio",
    "Corlette",
    "Coromandel Valley",
    "Corrimal",
    "Cotham",
    "Cottesloe",
    "Coutts Crossing",
    "Cowan",
    "Cowes",
    "Cowra",
    "Crafers",
    "Craigieburn",
    "Cranbourne",
    "Crawford",
    "Cremorne",
    "Cremorne",
    "Crescent Head",
    "Creswick",
    "Crib Point",
    "Cringila",
    "Cronulla",
    "Crookwell",
    "Crossley",
    "Crows Nest",
    "Crows Nest",
    "Croydon",
    "Croydon",
    "Croydon North",
    "Crystal Creek",
    "Cudlee Creek",
    "Cummins",
    "Cundletown",
    "Curl Curl",
    "Currumbin",
    "Cygnet",
    "Daceyville",
    "Daisy Hill",
    "Dakabin",
    "Dalby",
    "Dalkeith",
    "Dandenong",
    "Dapto",
    "Dardanup",
    "Darkan",
    "Darley",
    "Darling",
    "Darling Point",
    "Darlinghurst",
    "Darlington",
    "Darra",
    "Darwin",
    "Davidson",
    "Davistown",
    "Dawson",
    "Dayboro",
    "Daylesford",
    "Deagon",
    "Deakin",
    "Deception Bay",
    "Deepdene",
    "Deer Park",
    "Deloraine",
    "Denham",
    "Denman",
    "Dereel",
    "Derrimut",
    "Derwent",
    "Devonport",
    "Diamond Creek",
    "Diggers Rest",
    "Dilston",
    "Dimboola",
    "Dimbulah",
    "Dingley",
    "Dirranbandi",
    "Dodges Ferry",
    "Dolans Bay",
    "Don",
    "Doncaster",
    "Doncaster East",
    "Donnybrook",
    "Donvale",
    "Doonside",
    "Doreen",
    "Dorrigo",
    "Doubtful Creek",
    "Douglas",
    "Douglas Park",
    "Dover Heights",
    "Drayton",
    "Dromana",
    "Drouin",
    "Drouin South",
    "Drouin West",
    "Drummond Cove",
    "Drummoyne",
    "Drysdale",
    "Dubbo",
    "Dumbalk",
    "Dumbleyung",
    "Dundas",
    "Dundowran",
    "Dungog",
    "Dunkeld",
    "Dunmore",
    "Dunolly",
    "Dunsborough",
    "Dural",
    "Eagle Farm",
    "Eagle Heights",
    "Eagleby",
    "Eaglehawk",
    "Eaglemont",
    "Earlwood",
    "East Fremantle",
    "East Gosford",
    "East Maitland",
    "East Malvern",
    "Eastwood",
    "Eaton",
    "Ebbw Vale",
    "Ebenezer",
    "Echuca",
    "Echunga",
    "Eden",
    "Eden Park",
    "Edge Hill",
    "Edgecliff",
    "Edith",
    "Edithvale",
    "Edmonton",
    "Edwardstown",
    "Eight Mile Plains",
    "Elderslie",
    "Elizabeth",
    "Ellalong",
    "Ellangowan",
    "Ellendale",
    "Ellerslie",
    "Elsternwick",
    "Elsternwick",
    "Eltham",
    "Eltham North",
    "Elwood",
    "Emerald",
    "Emerald",
    "Empire Bay",
    "Emu Park",
    "Emu Plains",
    "Emu Vale",
    "Encounter Bay",
    "Endeavour",
    "Endeavour Hills",
    "Enfield",
    "Engadine",
    "Enmore",
    "Enoggera",
    "Epping",
    "Epping",
    "Erina",
    "Erskine",
    "Eschol",
    "Esk",
    "Esperance",
    "Essendon",
    "Ettalong Beach",
    "Eudlo",
    "Eudunda",
    "Eumundi",
    "Euroa",
    "Evandale",
    "Everton",
    "Exeter",
    "Exmouth",
    "Failford",
    "Fairfield",
    "Fairfield",
    "Fairview",
    "Fairy Meadow",
    "Fassifern",
    "Faulconbridge",
    "Fawkner",
    "Fern Tree",
    "Fern Tree Gully",
    "Ferndale",
    "Ferntree Gully",
    "Fernvale",
    "Ferny Creek",
    "Figtree",
    "Findon",
    "Fingal",
    "Fingal Bay",
    "Fitzroy",
    "Flemington",
    "Flinders",
    "Flinders",
    "Flynn",
    "Footscray",
    "Forbes",
    "Forest",
    "Forest Hill",
    "Forestville",
    "Forrest",
    "Forrest",
    "Forsayth",
    "Forster",
    "Foster",
    "Franklin",
    "Frankston",
    "Freeling",
    "Freemans Reach",
    "Fremantle",
    "Freshwater",
    "Fulham",
    "Fullerton",
    "Fyshwick",
    "Galston",
    "Garfield",
    "Gatton",
    "Gawler",
    "Gayndah",
    "Gaythorne",
    "Geelong",
    "Geelong West",
    "Gellibrand",
    "George Town",
    "Gepps Cross",
    "Geraldton",
    "Gerringong",
    "Gheerulla",
    "Gilberton",
    "Gilmore",
    "Gilston",
    "Gin Gin",
    "Girraween",
    "Gisborne",
    "Gladesville",
    "Gladstone",
    "Glass House Mountains",
    "Glebe",
    "Glen Huntly",
    "Glen Huon",
    "Glen Innes",
    "Glen Iris",
    "Glen Osmond",
    "Glen Waverley",
    "Glenbrook",
    "Glenburn",
    "Glenelg",
    "Glenfield",
    "Glengarry",
    "Glenhaven",
    "Glenorchy",
    "Glenorchy",
    "Glenore Grove",
    "Glenorie",
    "Glenreagh",
    "Glenroy",
    "Glenvale",
    "Glossodia",
    "Gloucester",
    "Golconda",
    "Gold Coast",
    "Golden Grove",
    "Goodna",
    "Goodwood",
    "Goolwa",
    "Goomeri",
    "Goonellabah",
    "Goornong",
    "Gooseberry Hill",
    "Gordon",
    "Gordon",
    "Gordonvale",
    "Gore Hill",
    "Gorokan",
    "Gosford",
    "Gosforth",
    "Gosnells",
    "Goulburn",
    "Gould",
    "Gowrie",
    "Gracemere",
    "Grafton",
    "Grandchester",
    "Grange",
    "Grant",
    "Grantham",
    "Granville",
    "Grassmere",
    "Gravesend",
    "Green Hill",
    "Green Point",
    "Greenbank",
    "Greendale",
    "Greensborough",
    "Greenvale",
    "Greenwich",
    "Gregadoo",
    "Grenfell",
    "Greta",
    "Griffith",
    "Grove",
    "Grovedale",
    "Guildford",
    "Guildford",
    "Gundaroo",
    "Gunnedah",
    "Gymea",
    "Gymea Bay",
    "Gympie",
    "Haberfield",
    "Hackham",
    "Haddon",
    "Hadspen",
    "Hagley",
    "Hahndorf",
    "Hall",
    "Hallam",
    "Hallett",
    "Halls Gap",
    "Hamilton",
    "Hamilton",
    "Hamilton",
    "Hamilton",
    "Hammond",
    "Hampton",
    "Hampton Park",
    "Happy Valley",
    "Harrington",
    "Harristown",
    "Harvey",
    "Hastings",
    "Hastings Point",
    "Hat Head",
    "Hawker",
    "Hawkesbury Heights",
    "Hawksburn",
    "Hawthorn",
    "Hawthorne",
    "Haymarket",
    "Hazelbrook",
    "Hazelwood",
    "Healesville",
    "Heathcote",
    "Heathcote",
    "Heathcote Junction",
    "Heatherton",
    "Heathmont",
    "Heddon Greta",
    "Heidelberg",
    "Helensburgh",
    "Helensvale",
    "Hendon",
    "Hepburn Springs",
    "Hervey Bay",
    "Hexham",
    "Highbury",
    "Highett",
    "Highfields",
    "Hill Top",
    "Hillcrest",
    "Hindmarsh",
    "Hobart",
    "Hoddles Creek",
    "Hollow Tree",
    "Holroyd",
    "Holsworthy",
    "Homebush",
    "Homebush",
    "Hope Island",
    "Hope Valley",
    "Hopetoun",
    "Hoppers Crossing",
    "Hornsby",
    "Horsham",
    "Hove",
    "Howard",
    "Howard Springs",
    "Howden",
    "Howe",
    "Howlong",
    "Hughesdale",
    "Hunter",
    "Hunters Hill",
    "Huntly",
    "Huonville",
    "Hurstbridge",
    "Hurstville",
    "Hurstville Grove",
    "Hyland Park",
    "Illawarra",
    "Illawong",
    "Iluka",
    "Indented Head",
    "Ingham",
    "Ingle Farm",
    "Ingleburn",
    "Inglewood",
    "Innisfail",
    "Inverell",
    "Inverloch",
    "Invermay",
    "Ipswich",
    "Isabella",
    "Ivanhoe",
    "Ivanhoe East",
    "Ives",
    "Jacobs Well",
    "Jannali",
    "Jarrahdale",
    "Jensen",
    "Jilliby",
    "Jimboomba",
    "Jindabyne",
    "Jindivick",
    "Judbury",
    "Junee",
    "Kadina",
    "Kalgoorlie",
    "Kallista",
    "Kalorama",
    "Kambalda",
    "Kangaroo Flat",
    "Kangaroo Point",
    "Kanwal",
    "Kapunda",
    "Karalee",
    "Karana Downs",
    "Kardella",
    "Kardella South",
    "Kareela",
    "Karnup",
    "Karoonda",
    "Karratha",
    "Katherine",
    "Katoomba",
    "Kawungan",
    "Keilor",
    "Keiraville",
    "Keith",
    "Kellyville",
    "Kelmscott",
    "Kelso",
    "Kelvin Grove",
    "Kempsey",
    "Kempton",
    "Kendall",
    "Kenilworth",
    "Kenmore",
    "Kennington",
    "Kensington",
    "Kensington",
    "Kensington and Norwood",
    "Kenthurst",
    "Kenwick",
    "Kerang",
    "Kerry",
    "Keswick",
    "Kettering",
    "Kew",
    "Kew",
    "Kew East",
    "Keysborough",
    "Keysbrook",
    "Kiama",
    "Kilcoy",
    "Kilcunda",
    "Killara",
    "Killarney",
    "Killarney",
    "Killcare",
    "Killingworth",
    "Kilmore",
    "Kilsyth",
    "Kincumber",
    "Kindred",
    "Kingaroy",
    "Kinglake",
    "Kings Cross",
    "Kings Park",
    "Kingscliff",
    "Kingsthorpe",
    "Kingston",
    "Kingston",
    "Kingston",
    "Kingston",
    "Kingswood",
    "Kinross",
    "Kirrawee",
    "Kirribilli",
    "Kirwan",
    "Koah",
    "Kobble",
    "Koetong",
    "Kogarah",
    "Kojonup",
    "Koo-Wee-Rup",
    "Koolewong",
    "Koorawatha",
    "Koornalla",
    "Kootingal",
    "Kooyong",
    "Koroit",
    "Korumburra",
    "Kotara",
    "Kununurra",
    "Kuranda",
    "Kurnell",
    "Kurrajong Heights",
    "Kurri Kurri",
    "Kyabram",
    "Kyneton",
    "Kyogle",
    "Laang",
    "Laguna",
    "Laidley",
    "Lake Cathie",
    "Lake Heights",
    "Lake Illawarra",
    "Lake Munmorah",
    "Lakemba",
    "Lakes Entrance",
    "Lalor",
    "Lambton",
    "Lamington",
    "Lancefield",
    "Lancelin",
    "Landsborough",
    "Lane Cove",
    "Lang Lang",
    "Langhorne Creek",
    "Langley",
    "Langwarrin",
    "Lara",
    "Largs North",
    "Latrobe",
    "Lauderdale",
    "Launceston",
    "Laverton",
    "Laverton",
    "Lavington",
    "Lawgi",
    "Lawnton",
    "Lawson",
    "Lebrina",
    "Leeton",
    "Legana",
    "Leichhardt",
    "Lemon Tree Passage",
    "Lennox Head",
    "Leongatha",
    "Leopold",
    "Lethbridge",
    "Leumeah",
    "Leura",
    "Lewiston",
    "Lidcombe",
    "Lilli Pilli",
    "Lilydale",
    "Limestone",
    "Linden",
    "Lindenow",
    "Lindfield",
    "Lindisfarne",
    "Lisarow",
    "Lismore",
    "Lithgow",
    "Little Bay",
    "Little Hampton",
    "Little River",
    "Liverpool",
    "Loch",
    "Lockhart",
    "Lockridge",
    "Loftus",
    "Logan",
    "Logan City",
    "Logan Village",
    "Loganholme",
    "Loganlea",
    "Long Jetty",
    "Long Plains",
    "Long Point",
    "Longford",
    "Longreach",
    "Longueville",
    "Longwarry",
    "Lovely Banks",
    "Lowanna",
    "Lower Chittering",
    "Lower Plenty",
    "Lowood",
    "Loxton",
    "Luddenham",
    "Ludlow",
    "Lugarno",
    "Lulworth",
    "Lutana",
    "Lyndhurst",
    "Lyndoch",
    "Lyneham",
    "Lyons",
    "Lysterfield",
    "Macarthur",
    "Macedon",
    "Mackay",
    "Macksville",
    "Maclean",
    "Macleod",
    "Macquarie Park",
    "Maddington",
    "Maffra",
    "Magill",
    "Maidenwell",
    "Maitland",
    "Malabar",
    "Maleny",
    "Mallala",
    "Mallanganee",
    "Malmsbury",
    "Malvern",
    "Mandalong",
    "Mandurah",
    "Mangalore",
    "Mangerton",
    "Manildra",
    "Manjimup",
    "Manly",
    "Manly",
    "Mannering Park",
    "Manning",
    "Manning Point",
    "Mannum",
    "Mansfield",
    "Mapleton",
    "Mareeba",
    "Margaret River",
    "Margate",
    "Margate",
    "Marian",
    "Maribyrnong",
    "Marino",
    "Marion",
    "Marlee",
    "Marmor",
    "Marong",
    "Maroochydore",
    "Maroota",
    "Maroubra",
    "Marrickville",
    "Marsden",
    "Marsden Park",
    "Marshalltown",
    "Martins Creek",
    "Marulan",
    "Maryborough",
    "Maryborough",
    "Maryland",
    "Marysville",
    "Mascot",
    "Maslin Beach",
    "Matcham",
    "Matraville",
    "Maudsland",
    "Maybole",
    "Mayfield",
    "Maylands",
    "McCrae",
    "McGraths Hill",
    "McKinnon",
    "McLachlan",
    "McLaren Flat",
    "McLaren Vale",
    "Meadows",
    "Medlow Bath",
    "Medowie",
    "Melbourne",
    "Melrose",
    "Melton",
    "Melton South",
    "Melville",
    "Menai",
    "Menangle",
    "Mentone",
    "Meredith",
    "Merewether",
    "Meringandan",
    "Merlynston",
    "Mernda",
    "Merredin",
    "Merriwa",
    "Merrylands",
    "Middlemount",
    "Middleton",
    "Middleton",
    "Midway Point",
    "Mildura",
    "Mile End",
    "Miles",
    "Milton",
    "Miners Rest",
    "Minlaton",
    "Minmi",
    "Minto",
    "Miranda",
    "Mirani",
    "Mirboo",
    "Mirboo North",
    "Mirrabooka",
    "Mission Beach",
    "Mitcham",
    "Mitcham",
    "Mitchell",
    "Mitchelton",
    "Mitiamo",
    "Mittagong",
    "Moama",
    "Moana",
    "Modbury",
    "Moggill",
    "Mona Vale",
    "Monash",
    "Monbulk",
    "Mont Albert North",
    "Monteagle",
    "Monterey",
    "Montmorency",
    "Montrose",
    "Montville",
    "Mooloolaba",
    "Mooloolah Valley",
    "Moonah",
    "Moonee Ponds",
    "Mooney Mooney",
    "Moorabbin",
    "Mooralla",
    "Moore",
    "Moore Park",
    "Moorooduc",
    "Mooroolbark",
    "Mooroopna",
    "Mooroopna",
    "Moranbah",
    "Morang",
    "Morayfield",
    "Mordialloc",
    "Moree",
    "Moriac",
    "Morisset",
    "Mornington",
    "Morphett Vale",
    "Mortdale",
    "Mortlake",
    "Moruya",
    "Morwell",
    "Mosman",
    "Mosman Park",
    "Moss Vale",
    "Mossman",
    "Mount Barker",
    "Mount Colah",
    "Mount Cotton",
    "Mount Dandenong",
    "Mount Direction",
    "Mount Druitt",
    "Mount Egerton",
    "Mount Eliza",
    "Mount Evelyn",
    "Mount Gambier",
    "Mount Gravatt",
    "Mount Helen",
    "Mount Helena",
    "Mount Isa",
    "Mount Julian",
    "Mount Keira",
    "Mount Larcom",
    "Mount Lofty",
    "Mount Macedon",
    "Mount Martha",
    "Mount Molloy",
    "Mount Morgan",
    "Mount Ousley",
    "Mount Perry",
    "Mount Pleasant",
    "Mount Saint Thomas",
    "Mount Waverley",
    "Mount White",
    "Mourilyan",
    "Muchea",
    "Mudgee",
    "Mudgeeraba",
    "Mudjimba",
    "Mulgoa",
    "Mulgrave",
    "Mullewa",
    "Mullumbimby",
    "Mundaring",
    "Mundijong",
    "Murray Bridge",
    "Murrumba",
    "Murrumbateman",
    "Murrumbeena",
    "Murrurundi",
    "Murtoa",
    "Murwillumbah",
    "Muswellbrook",
    "Mylor",
    "Myrrhee",
    "Myrtleford",
    "Nagambie",
    "Nairne",
    "Nambour",
    "Nambucca Heads",
    "Nana Glen",
    "Nanango",
    "Nannup",
    "Napoleons",
    "Nar Nar Goon",
    "Narangba",
    "Narara",
    "Narellan",
    "Narooma",
    "Narrabeen",
    "Narrabri",
    "Narrandera",
    "Narrawong",
    "Narre Warren",
    "Narre Warren North",
    "Narrogin",
    "Narromine",
    "Neath",
    "Nebo",
    "Nedlands",
    "Neerim South",
    "Nelson Bay",
    "Nerang",
    "Nerrigundah",
    "Nerrina",
    "Neutral Bay",
    "New Farm",
    "New Lambton",
    "New Town",
    "Newcastle",
    "Newmarket",
    "Newnham",
    "Newport",
    "Newport",
    "Newry",
    "Newstead",
    "Newstead",
    "Newstead",
    "Newtown",
    "Newtown",
    "Nhill",
    "Nindaroo",
    "Ningi",
    "Nirranda",
    "Noarlunga",
    "Noble Park",
    "Noosa",
    "Noosaville",
    "Norah",
    "Nords Wharf",
    "Norlane",
    "Norman Park",
    "Normanhurst",
    "Normanton",
    "Normanville",
    "North Beach",
    "North Fitzroy",
    "North Mackay",
    "North Melbourne",
    "North Tamborine",
    "North Turramurra",
    "Northam",
    "Northcote",
    "Northgate",
    "Northmead",
    "Norton Summit",
    "Norwood",
    "Notting Hill",
    "Nowra",
    "Nullawarre",
    "Nunawading",
    "Nuriootpa",
    "Nyora",
    "Oak Park",
    "Oakdale",
    "Oakey",
    "Oakhurst",
    "Oaklands",
    "Oakleigh",
    "Oakleigh South",
    "Oatlands",
    "Oatley",
    "Oberon",
    "Obi Obi",
    "Ocean Shores",
    "Officer",
    "Old Bar",
    "Old Beach",
    "Olinda",
    "Oman-Ama",
    "One Tree Hill",
    "Orange",
    "Orchard Hills",
    "Orford",
    "Orient Point",
    "Ormeau",
    "Ormiston",
    "Ormond",
    "Orroroo",
    "Osborne",
    "Ourimbah",
    "Oxenford",
    "Oxley",
    "Oxley",
    "Oyster Bay",
    "Paddington",
    "Padstow",
    "Pakenham",
    "Pallarenda",
    "Palm Beach",
    "Palm Cove",
    "Palm Island",
    "Palmer",
    "Palmwoods",
    "Pambula",
    "Paradise",
    "Paramatta",
    "Parap",
    "Park Ridge",
    "Parkdale",
    "Parkerville",
    "Parkes",
    "Parkhurst",
    "Parkville",
    "Parkwood",
    "Pearce",
    "Pearcedale",
    "Pechey",
    "Pelican",
    "Pendle Hills",
    "Penfield",
    "Penguin",
    "Pennant Hills",
    "Penrith",
    "Penshurst",
    "Perenjori",
    "Perth",
    "Perth",
    "Peterborough",
    "Petrie",
    "Phillip",
    "Pialba",
    "Piawaning",
    "Picnic Bay",
    "Picton",
    "Piggabeen",
    "Pimpama",
    "Pine Mountain",
    "Pinery",
    "Pinjarra",
    "Pinkenba",
    "Pioneer",
    "Pipers Brook",
    "Pitt Town",
    "Pittsworth",
    "Plympton",
    "Point Clare",
    "Point Cook",
    "Point Lonsdale",
    "Point Lookout",
    "Point Pass",
    "Point Vernon",
    "Pokolbin",
    "Pomona",
    "Pontville",
    "Poowong",
    "Port Adelaide",
    "Port Augusta",
    "Port Douglas",
    "Port Fairy",
    "Port Hacking",
    "Port Hedland",
    "Port Kembla",
    "Port Lincoln",
    "Port MacDonnell",
    "Port Macquarie",
    "Port Melbourne",
    "Port Noarlunga",
    "Port Pirie",
    "Port Sorell",
    "Portarlington",
    "Portland",
    "Portsea",
    "Potts Point",
    "Prahran",
    "Preston",
    "Proserpine",
    "Prospect",
    "Purnim",
    "Pymble",
    "Pyrmont",
    "Quakers Hill",
    "Queanbeyan",
    "Queenscliff",
    "Queensferry",
    "Queenstown",
    "Railton",
    "Ramsgate",
    "Randwick",
    "Rathmines",
    "Ravenswood",
    "Raworth",
    "Raymond Terrace",
    "Red Hill",
    "Redan",
    "Redbank",
    "Redcliffe",
    "Redfern",
    "Redhead",
    "Redland Bay",
    "Redlynch",
    "Reedy Creek",
    "Regents Park",
    "Research",
    "Reservoir",
    "Retreat",
    "Revesby",
    "Reynella",
    "Rhodes",
    "Riana",
    "Richmond",
    "Richmond",
    "Riddell",
    "Ridgley",
    "Ringwood",
    "Ringwood East",
    "Ringwood North",
    "Ripley",
    "Risdon",
    "River Heads",
    "Riverside",
    "Riverstone",
    "Riverton",
    "Riverview",
    "Riverwood",
    "Robertson",
    "Robertstown",
    "Robinvale",
    "Rochedale",
    "Rochester",
    "Rockbank",
    "Rockdale",
    "Rockhampton",
    "Rockingham",
    "Rocklea",
    "Rodborough",
    "Rokeby",
    "Roleystone",
    "Roma",
    "Romsey",
    "Rooty Hill",
    "Rosanna",
    "Rose Bay",
    "Rosebery",
    "Rosebery",
    "Rosebud",
    "Rosehill",
    "Roseville",
    "Rosewood",
    "Roseworthy",
    "Ross",
    "Ross Creek",
    "Ross River",
    "Rowville",
    "Royal Park",
    "Rozelle",
    "Rudall",
    "Rushworth",
    "Rutherford",
    "Rydalmere",
    "Ryde",
    "Rye",
    "Rylstone",
    "Safety Bay",
    "Saint Albans",
    "Saint Andrews",
    "Saint George",
    "Saint Georges Basin",
    "Saint Helena",
    "Saint Ives",
    "Saint James",
    "Saint Leonards",
    "Saint Lucia",
    "Saint Marys",
    "Saint Peters",
    "Saints",
    "Salamander Bay",
    "Sale",
    "Salisbury",
    "Salt Ash",
    "Samford",
    "San Remo",
    "Sanctuary Point",
    "Sandford",
    "Sandgate",
    "Sandgate",
    "Sandringham",
    "Sandstone Point",
    "Sandy Bay",
    "Sandy Hollow",
    "Sans Souci",
    "Sapphire",
    "Sarina",
    "Sarina Beach",
    "Sassafras",
    "Scarborough",
    "Scarborough",
    "Scarness",
    "Scarsdale",
    "Schofields",
    "Scone",
    "Scoresby",
    "Scottsdale",
    "Seacliff",
    "Seacombe",
    "Seaford",
    "Seaford",
    "Seaforth",
    "Seaton",
    "Seaview",
    "Sebastopol",
    "Selbourne",
    "Selby",
    "Sellicks Beach",
    "Sellicks Hill",
    "Semaphore",
    "Serpentine",
    "Seven Hills",
    "Seven Mile Beach",
    "Seville",
    "Seville East",
    "Seymour",
    "Sheffield",
    "Shellharbour",
    "Shenton Park",
    "Sherbrook",
    "Shoal Point",
    "Shorncliffe",
    "Silvan",
    "Silverdale",
    "Silverwater",
    "Singleton",
    "Sippy Downs",
    "Slacks Creek",
    "Smithfield",
    "Smithfield",
    "Smithton",
    "Smythes Creek",
    "Smythesdale",
    "Snowtown",
    "Somersby",
    "Somerset",
    "Somerset",
    "Somerton",
    "Somerville",
    "Sorrento",
    "South Melbourne",
    "South Perth",
    "South West Rocks",
    "South Yarra",
    "Southbank",
    "Southern Brook",
    "Southport",
    "Spearwood",
    "Spencer",
    "Spotswood",
    "Spreyton",
    "Spring Field",
    "Spring Hill",
    "Spring Hill",
    "Springfield",
    "Springvale",
    "Springvale South",
    "Springwood",
    "St Helens",
    "St Kilda",
    "Stanhope",
    "Stanley",
    "Stanmore",
    "Stannum",
    "Stanthorpe",
    "Stapylton",
    "Stawell",
    "Stieglitz",
    "Stirling",
    "Stirling",
    "Stockton",
    "Stockyard Creek",
    "Stoneville",
    "Stradbroke",
    "Stratford",
    "Stratford",
    "Strathalbyn",
    "Strathfield",
    "Strathfieldsaye",
    "Strathmore",
    "Strathpine",
    "Streaky Bay",
    "Stroud",
    "Stuart Park",
    "Sturt",
    "Subiaco",
    "Success",
    "Suffolk Park",
    "Sulphur Creek",
    "Summerholm",
    "Summertown",
    "Sunbury",
    "Sunnybank",
    "Sunnyside",
    "Sunshine",
    "Surfers Paradise",
    "Surrey Hills",
    "Sutherland",
    "Swan Hill",
    "Swan View",
    "Swansea",
    "Swansea",
    "Sydenham",
    "Sydney",
    "Sylvania",
    "Sylvania Waters",
    "Tahmoor",
    "Tallangatta",
    "Tallarook",
    "Tallebudgera",
    "Talmalmo",
    "Tambo Upper",
    "Tamborine",
    "Tammin",
    "Tamworth",
    "Tannum Sands",
    "Tanunda",
    "Taree",
    "Taren Point",
    "Tarlee",
    "Tarneit",
    "Taroona",
    "Tarragindi",
    "Tarrawanna",
    "Tarro",
    "Tarzali",
    "Tatura",
    "Tawonga",
    "Teesdale",
    "Telegraph Point",
    "Temora",
    "Tempe",
    "Templestowe",
    "Templestowe Lower",
    "Tenambit",
    "Tennyson",
    "Tenterfield",
    "Teralba",
    "Terrigal",
    "Terry Hills",
    "Tewantin",
    "Thagoona",
    "Thargomindah",
    "The Basin",
    "The Entrance",
    "The Oaks",
    "The Patch",
    "The Rocks",
    "Thebarton",
    "Thirlmere",
    "Thirroul",
    "Thomastown",
    "Thompson",
    "Thornbury",
    "Thornlands",
    "Thornleigh",
    "Thornton",
    "Thuringowa",
    "Thursday Island",
    "Tiaro",
    "Tieri",
    "Tinamba",
    "Tincurrin",
    "Tingoora",
    "Tolga",
    "Tom Price",
    "Tomago",
    "Tongala",
    "Toogoolawah",
    "Toogoom",
    "Toolangi",
    "Toolern Vale",
    "Toongabbie",
    "Toora",
    "Tooradin",
    "Toorak",
    "Toorbul",
    "Tooronga",
    "Toowong",
    "Toowoomba",
    "Torbanlea",
    "Toronto",
    "Torquay",
    "Torquay",
    "Tottenham",
    "Toukley",
    "Townsville",
    "Towradgi",
    "Trafalgar",
    "Traralgon",
    "Traralgon South",
    "Trentham",
    "Trinity Beach",
    "Truganina",
    "Trunkey",
    "Tuggerah",
    "Tuggeranong",
    "Tugun",
    "Tullamarine",
    "Tully",
    "Tumut",
    "Tuncurry",
    "Tunnack",
    "Turners Beach",
    "Turramurra",
    "Tweed Heads",
    "Two Rocks",
    "Two Wells",
    "Tyabb",
    "Tyalgum",
    "Tynong",
    "Uki",
    "Ulladulla",
    "Ulmarra",
    "Ultimo",
    "Ulverstone",
    "Umina",
    "Unanderra",
    "Underwood",
    "Unley",
    "Upper Brookfield",
    "Upper Burringbar",
    "Upper Caboolture",
    "Upper Coomera",
    "Upwey",
    "Uraidla",
    "Urangan",
    "Valla Beach",
    "Valley Heights",
    "Vaucluse",
    "Venus Bay",
    "Verdun",
    "Vermont",
    "Victor Harbor",
    "Victoria Park",
    "Victoria Point",
    "Villawood",
    "Vineyard",
    "Virginia",
    "Virginia",
    "Waaia",
    "Wacol",
    "Wagga Wagga",
    "Wahgunyah",
    "Wahroonga",
    "Waikerie",
    "Waitara",
    "Walgett",
    "Walhalla",
    "Wallacia",
    "Wallan",
    "Wallaroo",
    "Wallington",
    "Walliston",
    "Wallsend",
    "Walmer",
    "Wamberal",
    "Wamuran",
    "Wanbi",
    "Wandin North",
    "Wandoan",
    "Wangaratta",
    "Wangi Wangi",
    "Wanneroo",
    "Wantirna",
    "Wantirna South",
    "Waratah",
    "Warburton",
    "Warnbro",
    "Warnervale",
    "Warragul",
    "Warrandyte",
    "Warrane",
    "Warrawong",
    "Warren",
    "Warren Shire",
    "Warrigal",
    "Warrimoo",
    "Warrnambool",
    "Warwick",
    "Waterford",
    "Waterloo",
    "Waterloo",
    "Waterloo",
    "Waterloo Corner",
    "Watsonia",
    "Wattle Grove",
    "Wattle Grove",
    "Waubra",
    "Wauchope",
    "Waurn Ponds",
    "Waverley",
    "Waverton",
    "Wayville",
    "Wedderburn",
    "Wedderburn",
    "Weetah",
    "Weethalle",
    "Wellard",
    "Wellington",
    "Wellington Point",
    "Welshpool",
    "Wembley",
    "Wendouree",
    "Wentworth",
    "Wentworth Falls",
    "Wentworthville",
    "Werribee",
    "Werribee South",
    "West Burleigh",
    "West End",
    "West Hoxton",
    "West Pymble",
    "West Wyalong",
    "Westbrook",
    "Westfield",
    "Westmead",
    "Weston",
    "Weston Creek",
    "Wheelers Hill",
    "White Rock",
    "Whitfield",
    "Whittlesea",
    "Whyalla",
    "Wickham",
    "Wickham",
    "Wilberforce",
    "Willaston",
    "Willaura",
    "Williams",
    "Williamstown",
    "Williamstown",
    "Willow Grove",
    "Willow Vale",
    "Willunga",
    "Wilmot",
    "Wilson",
    "Wilton",
    "Wiluna",
    "Wimbledon",
    "Winchelsea",
    "Windang",
    "Windaroo",
    "Windellama",
    "Windermere Park",
    "Windsor",
    "Windsor",
    "Windsor",
    "Windsor",
    "Windsor Downs",
    "Wingham",
    "Winkleigh",
    "Wodonga",
    "Wollar",
    "Wollert",
    "Wollongbar",
    "Wollongong",
    "Womboota",
    "Won Wron",
    "Wondai",
    "Wonga Park",
    "Wongan Hills",
    "Wonthaggi",
    "Woodburn",
    "Woodend",
    "Woodford",
    "Woodford",
    "Woodgate",
    "Woodleigh",
    "Woodridge",
    "Woodside",
    "Woodstock",
    "Woodvale",
    "Woodville",
    "Woolamai",
    "Woolgoolga",
    "Woolloomooloo",
    "Woolooware",
    "Wooloweyah",
    "Woolwich",
    "Woombah",
    "Woombye",
    "Woonona",
    "Woori Yallock",
    "Woorim",
    "Wooroolin",
    "Worongary",
    "Woronora",
    "Woy Woy",
    "Wulkuraka",
    "Wyalkatchem",
    "Wyalong",
    "Wyee",
    "Wyndham",
    "Wynnum",
    "Wynyard",
    "Wyong",
    "Wyongah",
    "Wyreema",
    "Yaamba",
    "Yackandandah",
    "Yallingup",
    "Yallourn",
    "Yallourn North",
    "Yamba",
    "Yanchep",
    "Yandina",
    "Yangan",
    "Yankalilla",
    "Yapeen",
    "Yaroomba",
    "Yarpturk",
    "Yarra Glen",
    "Yarra Junction",
    "Yarragon",
    "Yarram",
    "Yarraman",
    "Yarrambat",
    "Yarraville",
    "Yarrawarrah",
    "Yass",
    "Yatala",
    "Yea",
    "Yellow Rock",
    "Yenda",
    "Yeoval",
    "Yeppoon",
    "Yerrinbool",
    "Yinnar",
    "Yolla",
    "York",
    "Yorkeys Knob",
    "Young",
    "Yowie Bay",
    "Zeehan"],
  },
  {
    name: "Austria",
    cities: ["Absam",
    "Absdorf",
    "Abtenau",
    "Abtsdorf",
    "Ach",
    "Achenkirch",
    "Achensee",
    "Admont",
    "Adnet",
    "Afritz",
    "Aggsbach",
    "Aichbach",
    "Aichdorf",
    "Aifersdorf",
    "Aigen",
    "Aigen im Muehlkreis",
    "Ainet",
    "Aistersheim",
    "Alberndorf in der Riedmark",
    "Alberschwende",
    "Aldrans",
    "Alkoven",
    "Alland",
    "Allhartsberg",
    "Alpbach",
    "Altach",
    "Altenberg",
    "Altenberg bei Linz",
    "Altenburg",
    "Altendorf",
    "Altendorf",
    "Altenhof am Hausruck",
    "Altenmarkt an der Triesting",
    "Altenmarkt im Pongau",
    "Altheim",
    "Althofen",
    "Altlengbach",
    "Altlichtenwarth",
    "Altmunster",
    "Altwaidhofen",
    "Am See",
    "Amaliendorf",
    "Ampass",
    "Ampfelwang",
    "Amstetten",
    "Andorf",
    "Anger",
    "Anif",
    "Annenheim",
    "Ansfelden",
    "Anthering",
    "Apfelberg",
    "Arbesthal",
    "Ardagger Markt",
    "Ardning",
    "Arnoldstein",
    "Arriach",
    "Asberg",
    "Aschach an der Donau",
    "Aschau",
    "Aspang Markt",
    "Asparn",
    "Asperhofen",
    "Asten",
    "Attersee",
    "Attnang-Puchheim",
    "Atzbach",
    "Au",
    "Auersthal",
    "Aufhausen",
    "Augsdorf",
    "Aurolzmunster",
    "Axams",
    "Bach",
    "Bad Blumau",
    "Bad Deutsch-Altenburg",
    "Bad Erlach",
    "Bad Fischau",
    "Bad Gams",
    "Bad Gastein",
    "Bad Gleichenberg",
    "Bad Goisern",
    "Bad Hall",
    "Bad Hofgastein",
    "Bad Ischl",
    "Bad Kreuzen",
    "Bad Pirawarth",
    "Bad Radkersburg",
    "Bad Schallerbach",
    "Bad Tatzmannsdorf",
    "Bad Voslau",
    "Baden bei Wien",
    "Baernbach",
    "Baldramsdorf",
    "Bartholomaberg",
    "Barwies",
    "Bauer",
    "Baumgarten im Burgenland",
    "Baumgartenberg",
    "Baumkirchen",
    "Berg",
    "Bergau",
    "Bergheim",
    "Berndorf",
    "Bernhardsthal",
    "Bernstein",
    "Berwang",
    "Bezau",
    "Biberbach",
    "Biberwier",
    "Bichlbach",
    "Biedermann",
    "Biedermannsdorf",
    "Birgitz",
    "Bisamberg",
    "Bizau",
    "Bleiberg ob Villach",
    "Bleiburg",
    "Blindenmarkt",
    "Bludenz",
    "Bludesch",
    "Blumau",
    "Bockfliess",
    "Bodensdorf",
    "Boheimkirchen",
    "Bosendurnbach",
    "Bramberg am Wildkogel",
    "Brandberg",
    "Braunau am Inn",
    "Bregenz",
    "Breitenbrunn",
    "Breitenfurt bei Wien",
    "Breitenwang",
    "Brixen im Thale",
    "Bruck am Ziller",
    "Bruck an der Grossglocknerstrasse",
    "Bruck an der Leitha",
    "Bruck an der Mur",
    "Bruckl",
    "Bruckneudorf",
    "Bruendl",
    "Bruggen",
    "Brunn am Gebirge",
    "Buch",
    "Buch bei Jenbach",
    "Buchkirchen",
    "Burgkirchen",
    "Burgschleinitz",
    "Burmoos",
    "Dalaas",
    "Dechantskirchen",
    "Dellach",
    "Desselbrunn",
    "Deutsch Goritz",
    "Deutsch-Wagram",
    "Deutschfeistritz",
    "Deutschkreutz",
    "Deutschlandsberg",
    "Diepoltsham",
    "Dietach",
    "Dietersdorf am Gnasbach",
    "Dobersberg",
    "Dobersdorf",
    "Doblbach",
    "Dobldorf",
    "Doebriach",
    "Donnerskirchen",
    "Doren",
    "Dorf",
    "Dorfgastein",
    "Dorfla",
    "Dornbirn",
    "Drassburg",
    "Drassmarkt",
    "Dross",
    "Durnstein",
    "Ebbs",
    "Eben im Pongau",
    "Ebenau",
    "Ebendorf",
    "Ebenfurth",
    "Ebensee",
    "Ebenthal",
    "Ebergassing",
    "Eberndorf",
    "Eberschwang",
    "Ebersdorf",
    "Eberstein",
    "Ebreichsdorf",
    "Eckartsau",
    "Edelschrott",
    "Edelstal",
    "Edlitz",
    "Edt bei Lambach",
    "Eferding",
    "Egg",
    "Egg am Faaker See",
    "Eggenburg",
    "Eggendorf",
    "Eggendorf",
    "Eggendorf im Thale",
    "Eggersdorf bei Graz",
    "Ehrenhausen",
    "Ehrwald",
    "Eibiswald",
    "Eichgraben",
    "Eisbach",
    "Eisenberg",
    "Eisenerz",
    "Eisenstadt",
    "Eisentratten",
    "Eisgarn",
    "Elixhausen",
    "Ellmau",
    "Elsbethen",
    "Emberg",
    "Emmersdorf an der Donau",
    "Engelhartszell",
    "Engersdorf",
    "Engerwitzdorf",
    "Enns",
    "Ennsdorf",
    "Enzenkirchen",
    "Enzenreith",
    "Enzersdorf an der Fischa",
    "Enzersfeld",
    "Enzesfeld",
    "Erlauf",
    "Ernst",
    "Ernstbrunn",
    "Ernsthofen",
    "Erpersdorf",
    "Erpfendorf",
    "Esternberg",
    "Eugendorf",
    "Faak am See",
    "Falkenburg",
    "Fehring",
    "Feistritz",
    "Feistritz an der Drau",
    "Feld am See",
    "Feldbach",
    "Feldkirch",
    "Feldkirchen an der Donau",
    "Feldkirchen bei Graz",
    "Feldkirchen bei Mattighofen",
    "Feldkirchen in Karnten",
    "Felixdorf",
    "Ferndorf",
    "Fernitz",
    "Feuchten",
    "Feuersbrunn",
    "Fieberbrunn",
    "Filzmoos",
    "Finkenberg",
    "Finsing",
    "Fischamend Dorf",
    "Fischbach",
    "Fischerndorf",
    "Fiss",
    "Flachau",
    "Fladnitz im Raabtal",
    "Flattach",
    "Fliess",
    "Flinsdorf",
    "Foederlach",
    "Fohnsdorf",
    "Forst",
    "Frankenburg",
    "Frankenmarkt",
    "Frattingsdorf",
    "Frauenkirchen",
    "Freienstein",
    "Freilassing",
    "Freinberg",
    "Freistadt",
    "Friedburg",
    "Friesach",
    "Frohnleiten",
    "Fuernitz",
    "Fuerstenfeld",
    "Fugen",
    "Fulpmes",
    "Furthof",
    "Fusch an der Glocknerstrasse",
    "Fuschl am See",
    "Fussach",
    "Gaaden",
    "Gaal",
    "Gablitz",
    "Gaflenz",
    "Gailitz",
    "Gaisberg",
    "Gallbrunn",
    "Gallneukirchen",
    "Gallspach",
    "Gallzein",
    "Galtur",
    "Gaming",
    "Gampern",
    "Gansbach",
    "Ganserndorf",
    "Gargellen",
    "Gars am Kamp",
    "Garsten",
    "Gaschurn",
    "Gaspoltshofen",
    "Gasthof",
    "Gattendorf",
    "Gaweinstal",
    "Geinberg",
    "Geras",
    "Gerasdorf am Steinfelde",
    "Gerasdorf bei Wien",
    "Gerersdorf",
    "Gerlos",
    "Getzersdorf",
    "Gfohl",
    "Giesshubl",
    "Glan",
    "Glaubendorf",
    "Gleisdorf",
    "Glinzendorf",
    "Gloggnitz",
    "Gmund",
    "Gmunden",
    "Gnadenwald",
    "Gnas",
    "Gobelsburg",
    "Goedersdorf",
    "Goesselsdorf",
    "Gofis",
    "Going",
    "Goldegg",
    "Gollersdorf",
    "Golling an der Salzach",
    "Gols",
    "Gortipohl",
    "Gosau",
    "Gosdorf",
    "Gosing",
    "Gostling an der Ybbs",
    "Gotzendorf",
    "Gotzendorf an der Leitha",
    "Gotzens",
    "Gotzis",
    "Grabelsdorf",
    "Graen",
    "Grafenbach",
    "Grafendorf",
    "Grafendorf bei Hartberg",
    "Grafendorf bei Stainz",
    "Grafenschlag",
    "Grafenstein",
    "Grafenweg",
    "Grafenworth",
    "Gramastetten",
    "Gramatneusiedl",
    "Grambach",
    "Gratkorn",
    "Gratwein",
    "Graz",
    "Grein",
    "Gresten",
    "Gries",
    "Gries am Brenner",
    "Grieselstein",
    "Grieskirchen",
    "Griffen",
    "Grimmenstein",
    "Grobming",
    "Grodig",
    "Gross",
    "Gross-Enzersdorf",
    "Gross-Schweinbarth",
    "Gross-Siegharts",
    "Grossarl",
    "Grossebersdorf",
    "Grossklein",
    "Grosspesendorf",
    "Grossraming",
    "Grossrussbach",
    "Grossweikersdorf",
    "Grunbach am Schneeberg",
    "Gruner Baum",
    "Gschnitz",
    "Guessing",
    "Gugging",
    "Guglwald",
    "Gumpoldskirchen",
    "Gunselsdorf",
    "Gunskirchen",
    "Guntersdorf",
    "Guntramsdorf",
    "Gurten",
    "Gurtis",
    "Gutau",
    "Gutenstein",
    "Guttaring",
    "Haag",
    "Haag",
    "Haag am Hausruck",
    "Hadersdorf am Kamp",
    "Hadersfeld",
    "Hadres",
    "Hagenberg im Muhlkreis",
    "Haibach ob der Donau",
    "Haid",
    "Haidershofen",
    "Haiming",
    "Haimingerberg",
    "Hainburg an der Donau",
    "Hainfeld",
    "Halbturn",
    "Haldensee",
    "Hall",
    "Hall in Tirol",
    "Hallein",
    "Haller",
    "Hallstatt",
    "Harbach",
    "Hard",
    "Hart",
    "Hartberg",
    "Hartkirchen",
    "Haslau an der Donau",
    "Hatting",
    "Hatzendorf",
    "Haus",
    "Hausbrunn",
    "Hausleiten",
    "Hausmannstatten",
    "Hautzendorf",
    "Heidenreichstein",
    "Heiligenkreuz am Waasen",
    "Heimschuh",
    "Heissberg",
    "Heissingfelding",
    "Heiterwang",
    "Hellmonsodt",
    "Henndorf am Wallersee",
    "Hennersdorf",
    "Hermagor",
    "Hernstein",
    "Herrnbaumgarten",
    "Herzogenburg",
    "Herzogsdorf",
    "Himberg",
    "Himmelreich",
    "Hinterbruehl",
    "Hinterburg",
    "Hinterglemm",
    "Hintersdorf",
    "Hinterstoder",
    "Hinterthiersee",
    "Hintertux",
    "Hinterwaldberg",
    "Hipping",
    "Hirm",
    "Hirschegg",
    "Hirtenberg",
    "Hochburg",
    "Hochgurgl",
    "Hochleithen",
    "Hochneukirchen",
    "Hochsoelden",
    "Hochst",
    "Hoehe",
    "Hof am Leithaberge",
    "Hof bei Salzburg",
    "Hofen",
    "Hofkirchen im Traunkreis",
    "Hoflein",
    "Hofstatten an der Raab",
    "Hofstetten",
    "Hohenau",
    "Hohenberg",
    "Hohenburg",
    "Hoheneich",
    "Hohenems",
    "Hohentauern",
    "Hohenwarth",
    "Hohenzell",
    "Hohnhart",
    "Hollabrunn",
    "Hollenthon",
    "Hollersbach im Pinzgau",
    "Holzhausen",
    "Hopfgarten im Brixental",
    "Horbranz",
    "Horersdorf",
    "Horn",
    "Hornstein",
    "Horsching",
    "Huetten",
    "Huttschlag",
    "Igls",
    "Im Brand",
    "Imst",
    "Innerkrems",
    "Innermanzing",
    "Innsbruck",
    "Inzersdorf im Kremstal",
    "Inzing",
    "Ischgl",
    "Isper",
    "Jagenbach",
    "Jagerberg",
    "Japons",
    "Jeging",
    "Jenbach",
    "Jennersdorf",
    "Jerzens",
    "Jochberg",
    "Jois",
    "Judenau",
    "Judenburg",
    "Judendorf",
    "Jungholz",
    "Kainach bei Voitsberg",
    "Kaindorf",
    "Kalsdorf bei Graz",
    "Kaltenbrunn",
    "Kaltenleutgeben",
    "Kanzelhoehe",
    "Kapelln",
    "Kapfenberg",
    "Kapfing",
    "Kappern",
    "Kappl",
    "Kaprun",
    "Karl",
    "Karlstetten",
    "Karteis",
    "Katsdorf",
    "Katzelsdorf",
    "Kaumberg",
    "Kefermarkt",
    "Kematen in Tirol",
    "Kennelbach",
    "Keutschach am See",
    "Kienberg",
    "Kierling",
    "Kilb",
    "Kindberg",
    "Kirchbach in Steiermark",
    "Kirchberg am Wagram",
    "Kirchberg in Tirol",
    "Kirchbichl",
    "Kirchdorf an der Krems",
    "Kirchdorf in Tirol",
    "Kirchham",
    "Kirchschlag",
    "Kirchschlag bei Linz",
    "Kittsee",
    "Klagenfurt",
    "Klam",
    "Klaus",
    "Klein-Harras",
    "Kleinbergl",
    "Kleinkirchheim",
    "Kleinstubing",
    "Klingfurth",
    "Klosterle",
    "Klostermarienberg",
    "Klosterneuburg",
    "Knappenberg",
    "Knittelfeld",
    "Kobenz",
    "Kobersdorf",
    "Koblach",
    "Koestenberg",
    "Koetschach",
    "Koflach",
    "Kollmitzberg",
    "Konigstetten",
    "Konigswiesen",
    "Kopfing im Innkreis",
    "Koppl",
    "Korneuburg",
    "Kossen",
    "Kottingbrunn",
    "Kottmannsdorf",
    "Kraig",
    "Kramsach",
    "Krems",
    "Kremsbruecke",
    "Kremsdorf",
    "Kremsmunster",
    "Krenglbach",
    "Kreuz",
    "Krieglach",
    "Krispl",
    "Kritzendorf",
    "Kroatisch Minihof",
    "Krottendorf bei Ligist",
    "Krumbach",
    "Krumbach Markt",
    "Krumegg",
    "Krumpendorf",
    "Kuchl",
    "Kuehnsdorf",
    "Kuehtai",
    "Kufstein",
    "Kumberg",
    "Kundl",
    "Laa an der Thaya",
    "Laab im Walde",
    "Laaben",
    "Laakirchen",
    "Labach",
    "Lambrechten",
    "Lamprechtshausen",
    "Landeck",
    "Landegg",
    "Landskron",
    "Lanersbach",
    "Langeck im Burgenland",
    "Langenlois",
    "Langenrohr",
    "Langenstein",
    "Langenzersdorf",
    "Lannach",
    "Lanzenkirchen",
    "Lassee",
    "Lassnitzhohe",
    "Latschach",
    "Launsdorf",
    "Lauterach",
    "Lavamund",
    "Lavant",
    "Laxenburg",
    "Lebring",
    "Lech",
    "Lechaschau",
    "Leibnitz, Styria",
    "Leisach",
    "Leiten",
    "Leitring",
    "Lembach im Muhlkreis",
    "Lend",
    "Lendorf",
    "Lenzing",
    "Leoben",
    "Leobendorf",
    "Leobersdorf",
    "Leogang",
    "Leonding",
    "Leonfelden",
    "Leopoldsdorf",
    "Lermoos",
    "Leutasch",
    "Leutschach",
    "Lichtenau",
    "Lichtenberg",
    "Lichtenegg",
    "Lichtenworth",
    "Liebenfels",
    "Lieboch",
    "Lienz",
    "Liesfeld",
    "Liezen",
    "Ligist",
    "Lilienfeld",
    "Lindenberg",
    "Linz",
    "Litschau",
    "Lobenstein",
    "Lochau",
    "Lochen",
    "Lofer",
    "Lohnsburg",
    "Loimersdorf",
    "Loipersbach",
    "Loosdorf",
    "Loretto",
    "Ludesch",
    "Luftenberg an der Donau",
    "Lungotz",
    "Lustenau",
    "Lutzmannsburg",
    "Mader",
    "Madseit",
    "Maglern",
    "Maiersdorf",
    "Maildorf",
    "Mairhof",
    "Maissau",
    "Mallnitz",
    "Mandling",
    "Mank",
    "Mannersdorf am Leithagebirge",
    "Mannersdorf an der Rabnitz",
    "Mannsworth",
    "Marbach",
    "Marchegg",
    "Marchtrenk",
    "Margarethen am Moos",
    "Maria Alm am Steinernen Meer",
    "Maria Buch",
    "Maria Elend",
    "Maria Ellend",
    "Maria Enzersdorf",
    "Maria Lankowitz",
    "Maria Lanzendorf",
    "Maria Saal",
    "Maria Schmolln",
    "Maria Taferl",
    "Maria Worth",
    "Maria-Anzbach",
    "Mariazell",
    "Markgrafneusiedl",
    "Markt Allhau",
    "Markt Neuhodis",
    "Markt Piesting",
    "Marktl",
    "Marz",
    "Mathon",
    "Matrei am Brenner",
    "Matrei in Osttirol",
    "Mattersburg",
    "Mattsee",
    "Mauerbach",
    "Mauerkirchen",
    "Maurach",
    "Mauterndorf",
    "Mauthausen",
    "Mayrhofen",
    "Meggenhofen",
    "Mehrnbach",
    "Meiningen",
    "Meisten",
    "Melk",
    "Mellach",
    "Mettmach",
    "Michelbach Markt",
    "Micheldorf in Oberoesterreich",
    "Mieming",
    "Miesenbach",
    "Miesenbach",
    "Millstatt",
    "Mistelbach",
    "Mittelberg",
    "Mitterdorf im Muerztal",
    "Mitterkirchen im Machland",
    "Mitterndorf an der Fischa",
    "Mittersill",
    "Moellbruecke",
    "Mollersdorf",
    "Molln",
    "Mollram",
    "Monchhof",
    "Mondsee",
    "Moos",
    "Moosburg",
    "Moosdorf",
    "Mooskirchen",
    "Mosern",
    "Motz",
    "Muckendorf an der Donau",
    "Muerzhofen",
    "Muerzsteg",
    "Muerzzuschlag",
    "Muhlbach am Manhartsberg",
    "Munchendorf",
    "Muntlix",
    "Munzbach",
    "Munzkirchen",
    "Murau",
    "Murfeld",
    "Mutters",
    "Naarn im Machlande",
    "Nassereith",
    "Natternbach",
    "Nauders",
    "Neckenmarkt",
    "Neidling",
    "Nenzing",
    "Nesselwangle",
    "Nestelbach bei Graz",
    "Neudau",
    "Neudorf bei Landsee",
    "Neudorf bei Staatz",
    "Neudorfl",
    "Neufeld an der Leitha",
    "Neuhofen",
    "Neuhofen an der Krems",
    "Neuhofen an der Ybbs",
    "Neukirchen",
    "Neukirchen am Walde",
    "Neukirchen an der Enknach",
    "Neukirchen an der Vockla",
    "Neulengbach",
    "Neumarkt am Wallersee",
    "Neumarkt an der Ybbs",
    "Neumarkt im Hausruckkreis",
    "Neumarkt im Muhlkreis",
    "Neumarkt in Steiermark",
    "Neunkirchen",
    "Neusiedl am See",
    "Neusiedl bei Guessing",
    "Neustift",
    "Neutal",
    "Neuzeug",
    "Nickelsdorf",
    "Niederachen",
    "Niederalm",
    "Niederdorf",
    "Niederkreuzstetten",
    "Niederland",
    "Niederleis",
    "Niederndorf",
    "Niederneukirchen",
    "Niederwaldkirchen",
    "Niederwolz",
    "Nochling",
    "Nussdorf",
    "Nussdorf am Haunsberg",
    "Nuziders",
    "Obdach",
    "Ober-Grafendorf",
    "Oberaich",
    "Oberaichwald",
    "Oberalm",
    "Oberau",
    "Obergruenburg",
    "Obergurgl",
    "Oberhausen",
    "Oberhofen im Inntal",
    "Oberkappel",
    "Oberkirchbach",
    "Oberkohlstaetten",
    "Obernberg",
    "Obernberg am Brenner",
    "Oberndorf an der Melk",
    "Oberndorf bei Salzburg",
    "Oberneukirchen",
    "Oberort",
    "Oberperfuss",
    "Oberpullendorf",
    "Oberrohrbach",
    "Oberschutzen",
    "Obersdorf",
    "Obersiebenbrunn",
    "Obertauern",
    "Obertrum am See",
    "Obervellach",
    "Oberwaltersdorf",
    "Oberwart",
    "Oberweis",
    "Oberwolbling",
    "Obritzberg",
    "Obsteig",
    "Oed",
    "Oehling",
    "Oepping",
    "Oetz",
    "OEtztal-Bahnhof",
    "Offenhausen",
    "Oftering",
    "Oggau",
    "Ohlsdorf",
    "Ollern",
    "Opponitz",
    "Ort im Innkreis",
    "Orth an der Donau",
    "Oslip",
    "Ossiach",
    "Ostermiething",
    "Ottendorf bei Gleisdorf",
    "Ottenheim",
    "Ottensheim",
    "Pabneukirchen",
    "Pachfurth",
    "Paldau",
    "Panzendorf",
    "Parndorf",
    "Parschlug",
    "Pasching",
    "Passail",
    "Passthurn",
    "Patsch",
    "Pattergassen",
    "Payerbach",
    "Peggau",
    "Perchtoldsdorf",
    "Perg",
    "Pernegg an der Mur",
    "Pernitz",
    "Pertisau",
    "Perwang am Grabensee",
    "Petersdorf",
    "Petronell-Carnuntum",
    "Pettenbach",
    "Pettnau",
    "Pettneu",
    "Petzenkirchen",
    "Peuerbach",
    "Pfaffstatten",
    "Pfons",
    "Pfunds",
    "Piberbach",
    "Pichl",
    "Pichl bei Wels",
    "Pichling bei Stainz",
    "Piesendorf",
    "Pillberg",
    "Pillichsdorf",
    "Pinggau",
    "Pinkafeld",
    "Pinsdorf",
    "Piringsdorf",
    "Pirka",
    "Pischeldorf",
    "Pischelsdorf am Engelbach",
    "Pischelsdorf in Steiermark",
    "Pitten",
    "Plangeross",
    "Plankenau",
    "Plescherken",
    "Pochlarn",
    "Poelten",
    "Poertschach am Woerthersee",
    "Poetting",
    "Poggstall",
    "Polfing",
    "Pottelsdorf",
    "Pottenbrunn",
    "Pottendorf",
    "Pottenstein",
    "Pottsching",
    "Potzneusiedl",
    "Poysbrunn",
    "Poysdorf",
    "Prabach",
    "Pram",
    "Preding",
    "Pregarten",
    "Pressbaum",
    "Prinzersdorf",
    "Puch",
    "Puch bei Hallein",
    "Puchberg am Schneeberg",
    "Puchenau",
    "Pucking",
    "Purbach",
    "Purbach am Neusiedlersee",
    "Purgg",
    "Purgstall",
    "Purgstall",
    "Purkersdorf",
    "Pyhra",
    "Raab",
    "Raaba",
    "Raabs an der Thaya",
    "Radfeld",
    "Radstadt",
    "Ramsau im Zillertal",
    "Randegg",
    "Rangersdorf",
    "Rankweil",
    "Rannersdorf",
    "Rappoltenkirchen",
    "Rauchenwarth",
    "Rauris",
    "Redlham",
    "Regau",
    "Regelsbrunn",
    "Reichenau an der Rax",
    "Reichenau im Muhlkreis",
    "Reichendorf",
    "Reichraming",
    "Reifnitz",
    "Reisenberg",
    "Reitdorf",
    "Reith",
    "Reith bei Seefeld",
    "Reith im Alpbachtal",
    "Rekawinkel",
    "Reutte",
    "Ried im Innkreis",
    "Ried im Traunkreis",
    "Ried im Zillertal",
    "Ried in der Riedmark",
    "Riedenthal",
    "Rieding",
    "Riedlingsdorf",
    "Riefensberg",
    "Riegersburg",
    "Riezlern",
    "Ringelsdorf",
    "Rinn",
    "Rohrau",
    "Rohrbach am Kulm",
    "Rohrbach am Steinfelde",
    "Rohrbach in Oberosterreich",
    "Rohrmoos",
    "Roppen",
    "Rosegg",
    "Rosenau am Hengstpass",
    "Rosenbach",
    "Rosental",
    "Rosental",
    "Rotenbach",
    "Rothis",
    "Rotholz",
    "Rottenbach",
    "Rottenmann",
    "Rubring",
    "Ruden",
    "Rudersdorf",
    "Rum",
    "Rutzenmoos",
    "Saalbach",
    "Saalfelden am Steinernen Meer",
    "Sachendorf",
    "Sachsenburg",
    "Saint Anton",
    "Salzburg",
    "Sankt Agatha",
    "Sankt Andra am Zicksee",
    "Sankt Andrae",
    "Sankt Florian",
    "Sankt Gallenkirch",
    "Sankt Georgen am Ybbsfelde",
    "Sankt Georgen im Attergau",
    "Sankt Johann im Pongau",
    "Sankt Kanzian",
    "Sankt Kathrein am Hauenstein",
    "Sankt Lorenzen",
    "Sankt Lorenzen im Muerztal",
    "Sankt Margarethen im Burgenland",
    "Sankt Marienkirchen am Hausruck",
    "Sankt Martin am Techelsberg",
    "Sankt Martin am Ybbsfelde",
    "Sankt Michael im Burgenland",
    "Sankt Michael in Obersteiermark",
    "Sankt Oswald",
    "Sankt Pankraz",
    "Sankt Peter",
    "Sankt Polten",
    "Sankt Ruprecht an der Raab",
    "Sankt Stefan im Rosental",
    "Sankt Ulrich bei Steyr",
    "Sankt Valentin",
    "Sankt Veit an der Glan",
    "Sankt Veit im Pongau",
    "Sankt Wolfgang",
    "Sankt Wolfgang",
    "Sarleinsbach",
    "Satteins",
    "Sauerbrunn",
    "Sausenstein",
    "Schachendorf",
    "Schalchen",
    "Schardenberg",
    "Scharding",
    "Scharnstein",
    "Scharten",
    "Schattendorf",
    "Scheffau am Wilden Kaiser",
    "Scheifling",
    "Schiefling am See",
    "Schladming",
    "Schleinbach",
    "Schlierbach",
    "Schlins",
    "Schlitters",
    "Schmirn",
    "Schneeberg",
    "Schoenau",
    "Schoenberg im Stubaital",
    "Schoerfling",
    "Schonbuhel an der Donau",
    "Schongrabern",
    "Schonkirchen",
    "Schoppernau",
    "Schrems",
    "Schruns",
    "Schutzen am Gebirge",
    "Schwadorf",
    "Schwaigs",
    "Schwanberg",
    "Schwanenstadt",
    "Schwarzach",
    "Schwarzach im Pongau",
    "Schwarzau am Steinfelde",
    "Schwarzau im Gebirge",
    "Schwarzenau",
    "Schwarzenberg am Boehmerwald",
    "Schwaz",
    "Schwechat",
    "Schweiggers",
    "Schwendau",
    "Schwertberg",
    "Schwoich",
    "Sebersdorf",
    "Seckau",
    "Seeboden",
    "Seefeld in Tirol",
    "Seeham",
    "Seekirchen am Wallersee",
    "Seewalchen",
    "Seiersberg",
    "Seitenstetten Markt",
    "Semmering",
    "Semriach",
    "Serfaus",
    "Sernau",
    "Sibratsgfall",
    "Siebenhirten",
    "Siebenhirten",
    "Siegendorf im Burgenland",
    "Sieghartskirchen",
    "Sierndorf",
    "Sierning",
    "Siezenheim",
    "Sillian",
    "Silz",
    "Sinabelkirchen",
    "Sinnersdorf",
    "Sipbachzell",
    "Sirnitz",
    "Sistrans",
    "Sitzenberg",
    "Solden",
    "Soll",
    "Sollenau",
    "Sommerein",
    "Sooss",
    "Spielberg bei Knittelfeld",
    "Spillern",
    "Spital am Pyhrn",
    "Spittal an der Drau",
    "Spitz",
    "Spratzern",
    "Staatz",
    "Stadelbach",
    "Stadl-Paura",
    "Stadtschlaining",
    "Stainz",
    "Stainz bei Straden",
    "Stams",
    "Stans",
    "Stattegg",
    "Statzendorf",
    "Steg",
    "Stegersbach",
    "Stein an der Donau",
    "Stein an der Enns",
    "Stein im Jauntal",
    "Steinabruckl",
    "Steinach am Brenner",
    "Steinakirchen am Forst",
    "Steinberg bei Ligist",
    "Steindorf am Ossiacher See",
    "Steinerberg",
    "Steinerkirchen an der Traun",
    "Steinfeld",
    "Steinhaus",
    "Steinhaus am Semmering",
    "Stettenhof",
    "Steyr",
    "Steyregg",
    "Stiegl",
    "Stockerau",
    "Stoob",
    "Straden",
    "Strass",
    "Strassen",
    "Strassengel",
    "Strasshof an der Nordbahn",
    "Strasswalchen",
    "Strau",
    "Strobl",
    "Stubach",
    "Stubenberg",
    "Studenzen",
    "Stuhlfelden",
    "Stuhlsdorf",
    "Stumm",
    "Stummerberg",
    "Sulz",
    "Sulz im Wienerwald",
    "Sulzau",
    "Sulzberg",
    "Tadten",
    "Tal",
    "Tamsweg",
    "Tannheim",
    "Tarrenz",
    "Tattendorf",
    "Taufkirchen an der Pram",
    "Taufkirchen an der Trattnach",
    "Tauplitz",
    "Taxenbach",
    "Telfes im Stubai",
    "Telfs",
    "Ternberg",
    "Ternitz",
    "Teufenbach",
    "Thal",
    "Thalgau",
    "Thalheim bei Wels",
    "Thalling",
    "Thaur",
    "Thaya",
    "Theresienfeld",
    "Thoerl",
    "Thuringen",
    "Tiefenbach",
    "Tiefgraben",
    "Tieschen",
    "Tirol",
    "Tobaj",
    "Toeschling",
    "Toesenssteinach",
    "Traboch",
    "Traisen",
    "Traiskirchen",
    "Traismauer",
    "Trasdorf",
    "Traun",
    "Traunkirchen",
    "Traunstein",
    "Trausdorf an der Wulka",
    "Trautmannsdorf an der Leitha",
    "Tribuswinkel",
    "Trieben",
    "Tristach",
    "Troepolach",
    "Trofaiach",
    "Trumau",
    "Tschagguns",
    "Tschoeran",
    "Tulln",
    "Tullnerbach-Lawies",
    "Tumeltsham",
    "Turnitz",
    "Turrach",
    "Tweng",
    "Uderns",
    "Ulmerfeld",
    "Ulrichsberg",
    "Ulrichskirchen",
    "Ungenach",
    "Unterberg",
    "Untergrub",
    "Unterjoch",
    "Unterlamm",
    "Unterparschenbrunn",
    "Unterpremstatten",
    "Unterpullendorf",
    "Unterpurkla",
    "Unterradlberg",
    "Unterrohr",
    "Unterrohr",
    "Untersiebenbrunn",
    "Unterwaltersdorf",
    "Unterweitersdorf",
    "Unzmarkt",
    "Uttendorf",
    "Utzenaich",
    "Veitsch",
    "Velden am Woerthersee",
    "Vent",
    "Vienna",
    "Vigaun",
    "Viktorsberg",
    "Viktring",
    "Villach",
    "Virgen",
    "Vitis",
    "Vocklabruck",
    "Vocklamarkt",
    "Voelkermarkt",
    "Voitsberg",
    "Volders",
    "Vols",
    "Vorau",
    "Vorchdorf",
    "Vorderlanersbach",
    "Vordernberg",
    "Vosendorf",
    "Wagna",
    "Wagrain",
    "Waidhofen an der Thaya",
    "Waidhofen an der Ybbs",
    "Waidmannsfeld",
    "Waidring",
    "Waizenkirchen",
    "Walbersdorf",
    "Walchsee",
    "Wald am Arlberg",
    "Waldenstein",
    "Walding",
    "Waldkirchen am Wesen",
    "Waldzell",
    "Wallern an der Trattnach",
    "Wallern im Burgenland",
    "Wallsee",
    "Wals",
    "Waltersdorf an der March",
    "Waltersdorf in Oststeiermark",
    "Wampersdorf",
    "Wang",
    "Wangle",
    "Wartberg",
    "Wartberg an der Krems",
    "Warth",
    "Warth",
    "Wattens",
    "Watzelsdorf",
    "Watzendorf",
    "Waxenberg",
    "Weibern",
    "Weiden am See",
    "Weigelsdorf",
    "Weikendorf",
    "Weikersdorf am Steinfelde",
    "Weilbach",
    "Weiler",
    "Weinburg am Sassbach",
    "Weissbriach",
    "Weissenbach am Lech",
    "Weissenkirchen in der Wachau",
    "Weisskirchen an der Traun",
    "Weisskirchen in Steiermark",
    "Weiten",
    "Weitersfeld",
    "Weitra",
    "Weiz",
    "Wels",
    "Weng bei Admont",
    "Weng im Innkreis",
    "Wenns",
    "Weppersdorf",
    "Werfen",
    "Werfenweng",
    "Werndorf",
    "Wernstein am Inn",
    "Westendorf",
    "Wettmannstatten",
    "Wiener Neudorf",
    "Wiener Neustadt",
    "Wienerherberg",
    "Wies",
    "Wieselburg",
    "Wiesing",
    "Wiesmath",
    "Wieting",
    "Wildon",
    "Wilfleinsdorf",
    "Wilhelmsburg",
    "Wilhering",
    "Wimpassing an der Leitha",
    "Wimpassing im Schwarzatale",
    "Winden am See",
    "Windischgarsten",
    "Winkl",
    "Winzendorf",
    "Woelfnitz",
    "Wolfau",
    "Wolfpassing",
    "Wolfsberg",
    "Wolfsgraben",
    "Wolfurt",
    "Wolkersdorf im Weinviertel",
    "Wollersdorf",
    "Wordern",
    "Worgl",
    "Wulkaprodersdorf",
    "Wundschuh",
    "Wurflach",
    "Wurmbrand",
    "Ybbs an der Donau",
    "Ybbsitz",
    "Zams",
    "Zeillern",
    "Zeiselmauer",
    "Zell am Moos",
    "Zell am See",
    "Zell am Ziller",
    "Zell an der Ybbs",
    "Zellerndorf",
    "Zeltweg",
    "Zemendorf",
    "Zettling",
    "Ziersdorf",
    "Zillingdorf",
    "Zillingtal",
    "Zipf",
    "Zirl",
    "Zistersdorf",
    "Zobing",
    "Zuers",
    "Zurndorf",
    "Zwentendorf",
    "Zwettl Stadt",
    "Zwolfaxing"],
  },
  {
    name: "Azerbaijan",
    cities: ["Baku",
    "Nakhchivan",
    "Quba",
    "Qusar",
    "Sulutapa",
    "Sumqayit",
    "Xirdalan",
    "Zurges"],
  },
  {
    name: "Bahamas",
    cities: [ "Andros Town",
    "Dunmore Town",
    "Freeport",
    "Marsh Harbour",
    "Nassau",
    "Palmetto Point",
    "Spanish Wells"],
  },
  {
    name: "Bahrain",
    cities: [ "Al Budayyi`",
    "Al Hadd",
    "Al Hamalah",
    "Al Janabiyah",
    "Al Markh",
    "Al Muharraq",
    "Bani Jamrah",
    "Barbar",
    "Jurdab",
    "Madinat `Isa",
    "Madinat Hamad",
    "Manama",
    "Oil City",
    "Sanabis",
    "Sanad",
    "Sitrah",
    "Tubli"],
  },
  {
    name: "Bangladesh",
    cities: ["Agrabad",
    "Bangla",
    "Barisal",
    "Bazar",
    "Chittagong",
    "Comilla",
    "Delta",
    "Dhaka",
    "Hossain",
    "Jessore",
    "Kabi",
    "Kazipur",
    "Mymensingh",
    "Narayanganj",
    "Rajshahi",
    "Tejgaon",
    "Uttara"],
  },
  {
    name: "Barbados",
    cities: ["Atlantic Shores",
    "Bagatelle",
    "Bloomsbury",
    "Bridgetown",
    "Bruce Vale",
    "Cave Hill",
    "Clapham",
    "Hastings",
    "Holetown",
    "Husbands",
    "Jackmans",
    "Oistins",
    "Pine Housing Estate",
    "Porters",
    "Rendezvous",
    "Rockley",
    "The Garden",
    "Wanstead",
    "Warners",
    "Warrens",
    "White Hill",
    "Wildey",
    "Worthing"],
  },
  {
    name: "Belarus",
    cities: [ "Baranovichi",
    "Borisov",
    "Brest",
    "Dzyarzhynsk",
    "Horki",
    "Hrodna",
    "Lahoysk",
    "Lida",
    "Lyakhovichi",
    "Lyaskavichy",
    "Mazyr",
    "Minsk",
    "Mogilev",
    "Navapolatsk",
    "Orsha",
    "Pinsk",
    "Polatsk",
    "Radoshkovichi",
    "Shklow",
    "Vitebsk",
    "Zaslawye",
    "Zhodzina"],
  },
  {
    name: "Belgium",
    cities: ["Aalbeke",
    "Aalst",
    "Aalter",
    "Aarschot",
    "Aarsele",
    "Aartrijke",
    "Aartselaar",
    "Achel",
    "Adegem",
    "Adinkerke",
    "Afsnee",
    "Agimont",
    "Alken",
    "Alleur",
    "Alsemberg",
    "Alveringem",
    "Amay",
    "Ampsin",
    "Andenne",
    "Anderlecht",
    "Anderlues",
    "Andrimont",
    "Angleur",
    "Ans",
    "Anseroeul",
    "Anthisnes",
    "Antwerp",
    "Anzegem",
    "Appels",
    "Appelterre-Eichem",
    "Arbrefontaine",
    "Ardooie",
    "Arendonk",
    "Argenteau",
    "Arlon",
    "Arquennes",
    "Arsimont",
    "As",
    "Asse",
    "Assebroek",
    "Assenede",
    "Assent",
    "Assesse",
    "Astene",
    "Astenet",
    "Ath",
    "Attenhoven",
    "Attre",
    "Aubange",
    "Aubel",
    "Auderghem",
    "Auvelais",
    "Avekapelle",
    "Avelgem",
    "Averbode",
    "Awans",
    "Awirs",
    "Aywaille",
    "Baal",
    "Baarle-Hertog",
    "Baasrode",
    "Baelen",
    "Baisy-Thy",
    "Balen",
    "Banneux",
    "Barchon",
    "Barry",
    "Basecles",
    "Bassenge",
    "Bassevelde",
    "Bastogne",
    "Battice",
    "Baudour",
    "Bavegem",
    "Bavikhove",
    "Bazel",
    "Beaufays",
    "Beauvechain",
    "Beernem",
    "Beerse",
    "Beersel",
    "Beervelde",
    "Beerzel",
    "Beez",
    "Begijnendijk",
    "Beigem",
    "Bekkevoort",
    "Belgrade",
    "Belle Fontaine",
    "Bellem",
    "Beloeil",
    "Belsele",
    "Berchem",
    "Berchem",
    "Berendrecht",
    "Beringen",
    "Berlaar",
    "Berlare",
    "Bernissart",
    "Bertem",
    "Bertrix",
    "Betekom",
    "Bevel",
    "Beveren",
    "Beveren",
    "Beverlo",
    "Beyne-Heusay",
    "Bierbeek",
    "Biercee",
    "Bierges",
    "Bierset",
    "Bilstain",
    "Bilzen",
    "Binche",
    "Bissegem",
    "Blaasveld",
    "Blanden",
    "Blankenberge",
    "Blegny",
    "Bocholt",
    "Boechout",
    "Boezinge",
    "Boncelles",
    "Bonheiden",
    "Bonlez",
    "Booischot",
    "Boom",
    "Boorsem",
    "Boortmeerbeek",
    "Borchtlombeek",
    "Borgerhout",
    "Borgloon",
    "Bornem",
    "Borsbeek",
    "Borsbeke",
    "Bouge",
    "Bouillon",
    "Boussu",
    "Bousval",
    "Boutersem",
    "Bouwel",
    "Bovekerke",
    "Bovesse",
    "Braine-le-Chateau",
    "Braine-le-Comte",
    "Braives",
    "Brasmenil",
    "Brasschaat",
    "Brecht",
    "Bredene",
    "Bree",
    "Bressoux",
    "Broechem",
    "Brugelette",
    "Bruges",
    "Brussegem",
    "Brussels",
    "Budingen",
    "Buggenhout",
    "Buizingen",
    "Bunsbeek",
    "Burcht",
    "Burdinne",
    "Burst",
    "Butgenbach",
    "Buvrinnes",
    "Carnieres",
    "Casteau",
    "Celles",
    "Cerexhe-Heuseux",
    "Chapelle-lez-Herlaimont",
    "Charleroi",
    "Charneux",
    "Chastre",
    "Chatelet",
    "Chatelineau",
    "Chaudfontaine",
    "Chaumont-Gistoux",
    "Chaussee-Notre-Dame-Louvignies",
    "Chenee",
    "Chercq",
    "Chevron",
    "Chievres",
    "Chimay",
    "Chiny",
    "Ciney",
    "Ciplet",
    "Clavier",
    "Colfontaine",
    "Comblain-au-Pont",
    "Comines",
    "Coret",
    "Corroy-le-Grand",
    "Couillet",
    "Cour-sur-Heure",
    "Courcelles",
    "Court-Saint-Etienne",
    "Couthuin",
    "Couvin",
    "Crisnee",
    "Cuesmes",
    "Dadizele",
    "Dalhem",
    "Damme",
    "Dampremy",
    "Dave",
    "De Haan",
    "De Klinge",
    "De Panne",
    "De Pinte",
    "Deerlijk",
    "Deftinge",
    "Deinze",
    "Denderbelle",
    "Denderhoutem",
    "Denderleeuw",
    "Dendermonde",
    "Dentergem",
    "Dessel",
    "Desselgem",
    "Destelbergen",
    "Deurle",
    "Deurne",
    "Diegem",
    "Diepenbeek",
    "Diest",
    "Diksmuide",
    "Dilbeek",
    "Dilsen",
    "Dinant",
    "Dison",
    "Donceel",
    "Dongelberg",
    "Dorinne",
    "Dottignies",
    "Dour",
    "Dranouter",
    "Drogenbos",
    "Drongen",
    "Dudzele",
    "Duffel",
    "Duisburg",
    "Dworp",
    "Ecaussinnes-Lalaing",
    "Edegem",
    "Eeklo",
    "Eernegem",
    "Eghezee",
    "Eigenbilzen",
    "Eke",
    "Ekeren",
    "Eksaarde",
    "Eksel",
    "Elen",
    "Elewijt",
    "Elingen",
    "Ellezelles",
    "Ellignies-Sainte-Anne",
    "Elverdinge",
    "Elversele",
    "Emblem",
    "Embourg",
    "Emptinne",
    "Enghien",
    "Engis",
    "Ensival",
    "Eppegem",
    "Erbaut",
    "Erembodegem",
    "Erezee",
    "Erondegem",
    "Erpe",
    "Erpent",
    "Erps-Kwerps",
    "Erquelinnes",
    "Ertvelde",
    "Esneux",
    "Essen",
    "Essene",
    "Estaimpuis",
    "Estinnes-au-Val",
    "Etalle",
    "Etikhove",
    "Etterbeek",
    "Eupen",
    "Everbeek",
    "Everberg",
    "Evere",
    "Evergem",
    "Faimes",
    "Falisolle",
    "Fallais",
    "Farciennes",
    "Fayt-lez-Manage",
    "Feluy",
    "Ferrieres",
    "Fexhe-le-Haut-Clocher",
    "Fexhe-Slins",
    "Filot",
    "Flawinne",
    "Flemalle-Grande",
    "Flemalle-Haute",
    "Flenu",
    "Fleron",
    "Fleurus",
    "Flobecq",
    "Floreffe",
    "Florennes",
    "Florenville",
    "Fontaine",
    "Fontaine-Valmont",
    "Forchies-la-Marche",
    "Forest",
    "Forrieres",
    "Fosse des Loups",
    "Frameries",
    "Franiere",
    "Frasnes-lez-Buissenal",
    "Freylange",
    "Gages",
    "Galmaarden",
    "Ganshoren",
    "Gavere",
    "Geel",
    "Geer",
    "Geetbets",
    "Gellik",
    "Gelrode",
    "Geluwe",
    "Gembloux",
    "Genk",
    "Gentbrugge",
    "Gentinnes",
    "Genval",
    "Geraardsbergen",
    "Gerpinnes",
    "Gesves",
    "Ghent",
    "Ghislenghien",
    "Ghlin",
    "Gierle",
    "Gijzegem",
    "Gilly",
    "Gingelom",
    "Gistel",
    "Gits",
    "Glabbeek",
    "Glimes",
    "Gobard",
    "Godarville",
    "Goe",
    "Gooik",
    "Goor",
    "Gosselies",
    "Gourdinne",
    "Goutroux",
    "Gouy-lez-Pieton",
    "Grand",
    "Grand-Leez",
    "Grand-Rechain",
    "Grand-Reng",
    "Grembergen",
    "Grez-Doiceau",
    "Grimbergen",
    "Grimminge",
    "Grivegnee",
    "Grobbendonk",
    "Groot-Bijgaarden",
    "Gruitrode",
    "Gullegem",
    "Haacht",
    "Haaltert",
    "Haasdonk",
    "Haasrode",
    "Haccourt",
    "Haine-Saint-Paul",
    "Halen",
    "Hallaar",
    "Halle",
    "Ham",
    "Ham",
    "Ham-sur-Heure",
    "Ham-sur-Sambre",
    "Hamme",
    "Hamme-Mille",
    "Hamoir",
    "Hamont",
    "Hampteau",
    "Haneffe",
    "Hannut",
    "Hansbeke",
    "Harelbeke",
    "Haren",
    "Hasselt",
    "Haut-Ittre",
    "Hautrage",
    "Havay",
    "Havre",
    "Hechtel",
    "Heers",
    "Heestert",
    "Heffen",
    "Heindonk",
    "Heist",
    "Heist-op-den-Berg",
    "Hekelgem",
    "Helchteren",
    "Hemiksem",
    "Henri-Chapelle",
    "Hensies",
    "Heppen",
    "Herdersem",
    "Herent",
    "Herentals",
    "Herenthout",
    "Herinnes",
    "Herk-de-Stad",
    "Herlaimont",
    "Hermalle-sous-Argenteau",
    "Hermee",
    "Herne",
    "Herseaux",
    "Herselt",
    "Herstal",
    "Herve",
    "Herzele",
    "Heule",
    "Heure-le-Romain",
    "Heusden",
    "Heusden",
    "Heusy",
    "Hever",
    "Heverlee",
    "Hingene",
    "Hoboken",
    "Hodeige",
    "Hoegaarden",
    "Hoeilaart",
    "Hoeleden",
    "Hoeselt",
    "Hoevenen",
    "Hofstade",
    "Hofstade",
    "Hognoul",
    "Hollain",
    "Holland",
    "Hollogne",
    "Holsbeek",
    "Hombeek",
    "Hooglede",
    "Hoogstraten",
    "Horion-Hozemont",
    "Hornu",
    "Hotton",
    "Houdeng-Goegnies",
    "Houffalize",
    "Housse",
    "Houtain-le-Val",
    "Houtaing",
    "Houthalen",
    "Houthem",
    "Houthulst",
    "Houtvenne",
    "Hove",
    "Huizingen",
    "Huldenberg",
    "Hulshout",
    "Humbeek",
    "Humbeek",
    "Huy",
    "Hyon",
    "Ichtegem",
    "Iddergem",
    "Idegem",
    "Impe",
    "Incourt",
    "Ingelmunster",
    "Ingooigem",
    "Isnes",
    "Itegem",
    "Itterbeek",
    "Ittre",
    "Ixelles-Elsene",
    "Izegem",
    "Izel",
    "Jabbeke",
    "Jambes",
    "Jamioulx",
    "Jauche",
    "Jemappes",
    "Jemelle",
    "Jemeppe-sur-Meuse",
    "Jemeppe-sur-Sambre",
    "Jette",
    "Jodoigne",
    "Jumet",
    "Jupille-sur-Meuse",
    "Juprelle",
    "Jurbise",
    "Kachtem",
    "Kain",
    "Kalken",
    "Kalmthout",
    "Kampenhout",
    "Kanegem",
    "Kapelle-op-den-Bos",
    "Kapellen",
    "Kaprijke",
    "Kasterlee",
    "Kaulille",
    "Keerbergen",
    "Kemzeke",
    "Kerkhove",
    "Kerksken",
    "Kermt",
    "Kersbeek-Miskom",
    "Kessel",
    "Kessel-Lo",
    "Kettenis",
    "Kinrooi",
    "Klein-Gelmen",
    "Klemskerke",
    "Knesselare",
    "Knokke",
    "Knokke-Heist",
    "Koekelare",
    "Koersel",
    "Koksijde",
    "Koningshooikt",
    "Kontich",
    "Korbeek-Dijle",
    "Korbeek-Lo",
    "Kortemark",
    "Kortenaken",
    "Kortenberg",
    "Kortessem",
    "Kortrijk",
    "Kortrijk-Dutsel",
    "Kozen",
    "Kraainem",
    "Kruibeke",
    "Kruishoutem",
    "Kuringen",
    "Kuurne",
    "Kwaadmechelen",
    "La Calamine",
    "La Hestre",
    "La Hulpe",
    "Laarne",
    "Labuissiere",
    "Laken",
    "Lambermont",
    "Lambermont",
    "Lanaken",
    "Landegem",
    "Landelies",
    "Landen",
    "Langdorp",
    "Langemark",
    "Lanklaar",
    "Latinne",
    "Lauwe",
    "Le Tilleul",
    "Lebbeke",
    "Lede",
    "Ledeberg",
    "Ledegem",
    "Leefdaal",
    "Leerbeek",
    "Leernes",
    "Leest",
    "Leffinge",
    "Leisele",
    "Lembeek",
    "Lendelede",
    "Lennik",
    "Leopoldsburg",
    "Lessines",
    "Letterhoutem",
    "Leuven",
    "Leuze",
    "Leval-Trahegnies",
    "Libramont",
    "Lichtaart",
    "Lichtervelde",
    "Liedekerke",
    "Lier",
    "Ligny",
    "Lille",
    "Lillois-Witterzee",
    "Limal",
    "Limbourg",
    "Limelette",
    "Lincent",
    "Linden",
    "Linkebeek",
    "Lint",
    "Lippelo",
    "Lisogne",
    "Lissewege",
    "Lives-sur-Meuse",
    "Lixhe",
    "Lobbes",
    "Lochristi",
    "Lodelinsart",
    "Loenhout",
    "Loker",
    "Lokeren",
    "Lommel",
    "Loncin",
    "Londerzeel",
    "Lontzen",
    "Loppem",
    "Lot",
    "Lotenhulle",
    "Loupoigne",
    "Louvain-la-Neuve",
    "Louveigne",
    "Lovendegem",
    "Loverval",
    "Lubbeek",
    "Lummen",
    "Luttre",
    "Maaseik",
    "Maasmechelen",
    "Machelen",
    "Magnee",
    "Maisieres",
    "Maldegem",
    "Malderen",
    "Malmedy",
    "Malonne",
    "Manage",
    "Manhay",
    "Marais",
    "Marche-en-Famenne",
    "Marche-lez-Ecaussinnes",
    "Marchienne-au-Pont",
    "Marchin",
    "Marcinelle",
    "Mariakerke",
    "Mariekerke",
    "Mariembourg",
    "Marke",
    "Markegem",
    "Massemen",
    "Maurage",
    "Mechelen",
    "Meensel-Kiezegem",
    "Meerbeke",
    "Meerhout",
    "Meerlaar",
    "Meerle",
    "Meersel",
    "Meeuwen",
    "Meigem",
    "Meise",
    "Meldert",
    "Melen",
    "Melkwezer",
    "Melle",
    "Melsbroek",
    "Melsele",
    "Membach",
    "Menen",
    "Merchtem",
    "Mere",
    "Merelbeke",
    "Merendree",
    "Merksem",
    "Merksplas",
    "Mespelare",
    "Messancy",
    "Mettet",
    "Meulebeke",
    "Middelburg",
    "Middelkerke",
    "Minderhout",
    "Modave",
    "Moen",
    "Moerbeke",
    "Moerzeke",
    "Moha",
    "Mol",
    "Molenbeek",
    "Molenbeek-Saint-Jean",
    "Molenstede",
    "Momalle",
    "Monceau-sur-Sambre",
    "Mons",
    "Mont-Saint-Guibert",
    "Mont-Sainte-Aldegonde",
    "Mont-sur-Marchienne",
    "Montegnee",
    "Montignies-lez-Lens",
    "Montignies-sur-Sambre",
    "Montzen",
    "Moorsel",
    "Moorslede",
    "Morialme",
    "Morkhoven",
    "Morlanwelz-Mariemont",
    "Mortsel",
    "Mouscron",
    "Muizen",
    "Muizen",
    "Munsterbilzen",
    "Naast",
    "Nalinnes",
    "Nameche",
    "Namur",
    "Nandrin",
    "Natoye",
    "Nazareth",
    "Neder-Over-Heembeek",
    "Nederbrakel",
    "Nederhasselt",
    "Neerharen",
    "Neerijse",
    "Neerlinter",
    "Neerpelt",
    "Nethen",
    "Neuville-en-Condroz",
    "Nevele",
    "Niel",
    "Nieuwenhove",
    "Nieuwerkerken",
    "Nieuwerkerken",
    "Nieuwpoort",
    "Nieuwpoort",
    "Nieuwrode",
    "Nijlen",
    "Nil Saint-Vincent",
    "Nimy",
    "Ninove",
    "Nivelles",
    "Nodebais",
    "Nokere",
    "Nossegem",
    "Nouvelles",
    "Nukerke",
    "Obaix",
    "Obourg",
    "Oedelem",
    "Oelegem",
    "Oeselgem",
    "Ohain",
    "Ohey",
    "Okegem",
    "Olen",
    "Olne",
    "Onze-Lieve-Vrouw-Waver",
    "Ooigem",
    "Oordegem",
    "Oostakker",
    "Oosteeklo",
    "Oostende",
    "Oosterzele",
    "Oostham",
    "Oostkamp",
    "Oostmalle",
    "Oostrozebeke",
    "Oostvleteren",
    "Opbrakel",
    "Opglabbeek",
    "Opgrimbie",
    "Ophain",
    "Opitter",
    "Opoeteren",
    "Oppem",
    "Oppuurs",
    "Opwijk",
    "Orbais",
    "Orp-le-Grand",
    "Ostend",
    "Otegem",
    "Othee",
    "Ottignies",
    "Oud-Heverlee",
    "Oud-Turnhout",
    "Oudegem",
    "Oudenaarde",
    "Oudenburg",
    "Ougree",
    "Oupeye",
    "Outer",
    "Outrijve",
    "Overheide",
    "Overijse",
    "Overmere",
    "Overpelt",
    "Paal",
    "Pamel",
    "Parike",
    "Park",
    "Passendale",
    "Peer",
    "Pellenberg",
    "Pepinster",
    "Peronnes",
    "Pervijze",
    "Perwez",
    "Petegem",
    "Peutie",
    "Pipaix",
    "Pittem",
    "Plainevaux",
    "Plancenoit",
    "Ploegsteert",
    "Poederlee",
    "Poelkapelle",
    "Pollare",
    "Pont-a-Celles",
    "Pont-de-Loup",
    "Poperinge",
    "Poppel",
    "Post",
    "Presles",
    "Profondeville",
    "Pulderbos",
    "Pulle",
    "Putte",
    "Puurs",
    "Quaregnon",
    "Quenast",
    "Quevaucamps",
    "Quievrain",
    "Ramet",
    "Ramsdonk",
    "Ramsel",
    "Ramskapelle",
    "Rance",
    "Ransart",
    "Ranst",
    "Ravels",
    "Rebaix",
    "Recht",
    "Reet",
    "Rekem",
    "Rekkem",
    "Relegem",
    "Remersdaal",
    "Remicourt",
    "Reninge",
    "Renlies",
    "Ressaix",
    "Ressegem",
    "Retie",
    "Retinne",
    "Rhisnes",
    "Richelle",
    "Riemst",
    "Rijkevorsel",
    "Rijmenam",
    "Rillaar",
    "Rixensart",
    "Rochefort",
    "Rocourt",
    "Rode",
    "Roeselare",
    "Roeulx",
    "Romsee",
    "Ronse",
    "Rosieres",
    "Rosmeer",
    "Rotselaar",
    "Roux",
    "Ruddervoorde",
    "Ruien",
    "Ruisbroek",
    "Ruisbroek",
    "Ruiselede",
    "Rumbeke",
    "Rummen",
    "Rumst",
    "Sablon",
    "Saint-Georges-sur-Meuse",
    "Saint-Ghislain",
    "Saint-Gilles",
    "Saint-Jean-Geest",
    "Saint-Josse-ten-Noode",
    "Saint-Leger",
    "Saint-Mard",
    "Saint-Nicolas",
    "Saint-Remy",
    "Saint-Sauveur",
    "Saint-Servais",
    "Saint-Symphorien",
    "Sainte-Ode",
    "Saintes",
    "Saive",
    "Sankt Vith",
    "Sart Melin",
    "Sart-Dames-Avelines",
    "Sart-en-Fagne",
    "Sart-lez-Spa",
    "Schaarbeek",
    "Schaffen",
    "Schalkhoven",
    "Scheldewindeke",
    "Schelle",
    "Schellebelle",
    "Schendelbeke",
    "Schepdaal",
    "Scherpenheuvel",
    "Schilde",
    "Schoonaarde",
    "Schorisse",
    "Schoten",
    "Schriek",
    "Schulen",
    "Sclayn",
    "Seilles",
    "Seneffe",
    "Seraing",
    "Serskamp",
    "Sijsele",
    "Sinaai",
    "Sint-Agatha-Berchem",
    "Sint-Agatha-Rode",
    "Sint-Amands",
    "Sint-Amandsberg",
    "Sint-Andries",
    "Sint-Baafs-Vijve",
    "Sint-Denijs-Westrem",
    "Sint-Genesius-Rode",
    "Sint-Gillis-bij-Dendermonde",
    "Sint-Gillis-Waas",
    "Sint-Katelijne-Waver",
    "Sint-Kornelis-Horebeke",
    "Sint-Lambrechts-Herk",
    "Sint-Laureins",
    "Sint-Lievens-Houtem",
    "Sint-Maria-Latem",
    "Sint-Martens-Latem",
    "Sint-Michiels",
    "Sint-Niklaas",
    "Sint-Pauwels",
    "Sint-Pieters op de Dijk",
    "Sint-Pieters-Leeuw",
    "Sint-Stevens-Woluwe",
    "Sint-Truiden",
    "Sirault",
    "Sleidinge",
    "Soignies",
    "Sombreffe",
    "Soumagne",
    "Souvret",
    "Spa",
    "Sprimont",
    "Stabroek",
    "Staden",
    "Stavelot",
    "Steendorp",
    "Steenokkerzeel",
    "Stekene",
    "Stembert",
    "Stene",
    "Ster",
    "Sterrebeek",
    "Stevoort",
    "Stokkem",
    "Stoumont",
    "Stree",
    "Strepy-Bracquegnies",
    "Strombeek-Bever",
    "Tamines",
    "Temse",
    "Teralfene",
    "Terhagen",
    "Ternat",
    "Tertre",
    "Tervuren",
    "Tessenderlo",
    "Testelt",
    "Theux",
    "Thimeon",
    "Thimister",
    "Thisnes",
    "Thuin",
    "Thulin",
    "Thy-le-Chateau",
    "Thynes",
    "Tiegem",
    "Tielt",
    "Tielt",
    "Tienen",
    "Tildonk",
    "Tilff",
    "Tilleur",
    "Tisselt",
    "Toernich",
    "Tohogne",
    "Tongeren",
    "Tongerlo",
    "Tongre-Notre-Dame",
    "Torhout",
    "Tournai",
    "Transinne",
    "Trazegnies",
    "Tremelo",
    "Trooz",
    "Tubize",
    "Turnhout",
    "Uccle",
    "Ulbeek",
    "Ursel",
    "Vance",
    "Varsenare",
    "Vaux",
    "Vaux-sous-Chevremont",
    "Vedrin",
    "Veerle",
    "Velaine-sur-Sambre",
    "Velaines",
    "Veldegem",
    "Velm",
    "Veltem-Beisem",
    "Verlaine",
    "Verrebroek",
    "Verviers",
    "Veurne",
    "Viane",
    "Vichte",
    "Vielsalm",
    "Viesville",
    "Villers-la-Ville",
    "Villers-le-Bouillet",
    "Villers-sur-Semois",
    "Vilvoorde",
    "Vinderhoute",
    "Virton",
    "Vise",
    "Vivegnis",
    "Vladslo",
    "Vlekkem",
    "Vlezenbeek",
    "Vliermaal",
    "Vliermaalroot",
    "Vlierzele",
    "Vlissegem",
    "Vollezele",
    "Voormezele",
    "Voroux-Goreux",
    "Vorselaar",
    "Vosselaar",
    "Vossem",
    "Vottem",
    "Vremde",
    "Waarloos",
    "Waarschoot",
    "Waasmunster",
    "Wachtebeke",
    "Wagnelee",
    "Wakken",
    "Waleffe Saint-Georges",
    "Walhain",
    "Walhorn",
    "Wanfercee-Baulet",
    "Wangenies",
    "Wanne",
    "Wanze",
    "Warchin",
    "Waregem",
    "Waremme",
    "Warneton",
    "Warquignies",
    "Warsage",
    "Wasmes",
    "Waterloo",
    "Watermael-Boitsfort",
    "Watervliet",
    "Waudrez",
    "Wavre",
    "Ways",
    "Wechelderzande",
    "Weelde",
    "Welkenraedt",
    "Welle",
    "Wellen",
    "Wemmel",
    "Wenduine",
    "Wepion",
    "Werchter",
    "Werken",
    "Wervik",
    "Wespelaar",
    "Westende",
    "Westerlo",
    "Westmalle",
    "Westouter",
    "Wetteren",
    "Wevelgem",
    "Wezemaal",
    "Wezembeek-Oppem",
    "Wichelen",
    "Wiekevorst",
    "Wielsbeke",
    "Wierde",
    "Wieze",
    "Wijgmaal",
    "Wijnegem",
    "Willebroek",
    "Wilrijk",
    "Wilsele",
    "Wingene",
    "Winksele",
    "Woesten",
    "Wolfsdonk",
    "Wolkrange",
    "Woluwe-Saint-Lambert",
    "Woluwe-Saint-Pierre - Sint-Pieters-Woluwe",
    "Wolvertem",
    "Wommelgem",
    "Wondelgem",
    "Wortegem",
    "Wuustwezel",
    "Xhendremael",
    "Xhoris",
    "Ypres",
    "Yvoir",
    "Zaffelare",
    "Zandbergen",
    "Zandhoven",
    "Zarren",
    "Zaventem",
    "Zedelgem",
    "Zeebrugge",
    "Zele",
    "Zellik",
    "Zelzate",
    "Zemst",
    "Zeveneken",
    "Zichem",
    "Zingem",
    "Zoersel",
    "Zolder",
    "Zomergem",
    "Zonhoven",
    "Zonnebeke",
    "Zottegem",
    "Zoutleeuw",
    "Zuienkerke",
    "Zulte",
    "Zutendaal",
    "Zwartenbroek",
    "Zwevegem",
    "Zwevezele",
    "Zwijnaarde",
    "Zwijndrecht"],
  },
  {
    name: "Belize",
    cities: ["Belize City",
    "Belmopan",
    "Benque Viejo del Carmen",
    "Freetown Sibun",
    "Ladyville",
    "San Ignacio",
    "San Pedro Town"],
  },
  {
    name: "Benin",
    cities: ["Porto-Novo", "Cotonou", "Parakou"],
  },
  {
    name: "Bhutan",
    cities: ["Thimphu", "Phuntsholing", "Punakha"],
  },
  {
    name: "Bolivia",
    cities: ["Anillo",
    "Aroma",
    "Bermejo",
    "Caracasa",
    "Cobija",
    "Cochabamba",
    "Cotoca",
    "Cruz",
    "Guayaramerin",
    "La Paz",
    "Oruro",
    "Riberalta",
    "Santa Cruz",
    "Santa Cruz",
    "Sucre",
    "Tarija",
    "Trinidad"],
  },
  {
    name: "Bosnia and Herzegovina",
    cities: ["Banja",
    "Banja Luka",
    "Bijeljina",
    "Bosanska Dubica",
    "Bosanska Krupa",
    "Brcko",
    "Breza",
    "Bugojno",
    "Cazin",
    "Core",
    "Doboj",
    "Donja Mahala",
    "Gracanica",
    "Ilidza",
    "Jajce",
    "Jelah",
    "Kiseljak",
    "Livno",
    "Ljubuski",
    "Lopare",
    "Lukavac",
    "Modrica",
    "Mostar",
    "Novi Travnik",
    "Odzak",
    "Prijedor",
    "Sanski Most",
    "Sarajevo",
    "Siroki Brijeg",
    "Srpska",
    "Stup",
    "Tesanj",
    "Travnik",
    "Tuzla",
    "Visoko",
    "Vitez",
    "Vogosca",
    "Zenica",
    "Zepce",
    "Zivinice"],
  },
  {
    name: "Botswana",
    cities: ["Francistown",
    "Gaborone",
    "Orapa",
    "Serowe",
    "Village"],
  },
  {
    name: "Brazil",
    cities: ["Abadiania",
    "Abaetetuba",
    "Abelardo Luz",
    "Abidos",
    "Abrantes",
    "Abreu",
    "Abreu e Lima",
    "Acarau",
    "Acopiara",
    "Acorizal",
    "Acu",
    "Acucena",
    "Adamantina",
    "Adustina",
    "Afogados da Ingazeira",
    "Afonso Claudio",
    "Agrestina",
    "Agua Boa",
    "Agua Clara",
    "Aguai",
    "Aguanil",
    "Aguas Claras",
    "Aguas da Prata",
    "Aguas de Chapeco",
    "Aguas de Lindoia",
    "Aguas de Sao Pedro",
    "Aguas Lindas",
    "Agudo",
    "Agudos",
    "Agulha",
    "Alagoa Grande",
    "Alagoa Nova",
    "Alagoinha",
    "Alagoinhas",
    "Alambari",
    "Aldeia",
    "Alegre",
    "Alegres",
    "Alegrete",
    "Alem Paraiba",
    "Alfenas",
    "Alfredo Chaves",
    "Almeirim",
    "Almirante Tamandare",
    "Aloandia",
    "Alta Floresta",
    "Altamira",
    "Altaneira",
    "Alterosa",
    "Altinho",
    "Altinopolis",
    "Alto",
    "Alto Alegre",
    "Alto do Rodrigues",
    "Alto Paraiso de Goias",
    "Alto Piquiri",
    "Alto Santo",
    "Altos",
    "Alvares Machado",
    "Alvorada",
    "Alvorada do Sul",
    "Amambai",
    "Amaraji",
    "Amarante",
    "Amarante do Maranhao",
    "Amazonas",
    "America de Cima",
    "America Dourada",
    "Americana",
    "Americo Brasiliense",
    "Amparo",
    "Anadia",
    "Anajas",
    "Ananindeua",
    "Anchieta",
    "Andarai",
    "Andradas",
    "Andradina",
    "Andre",
    "Andre da Rocha",
    "Angatuba",
    "Angical do Piaui",
    "Angicos",
    "Angra dos Reis",
    "Anhembi",
    "Anhumas",
    "Antas",
    "Antonina",
    "Antonio Carlos",
    "Antonio Carlos",
    "Antonio Prado",
    "Aparecida",
    "Aparecida do Rio Negro",
    "Aperibe",
    "Apiai",
    "Apiuna",
    "Apodi",
    "Apora",
    "Apucarana",
    "Apuiares",
    "Aquidaba",
    "Aquidauana",
    "Aquiraz",
    "Aracai",
    "Aracaju",
    "Aracariguama",
    "Aracas",
    "Aracati",
    "Aracatuba",
    "Araci",
    "Aracoiaba da Serra",
    "Aracruz",
    "Aracuai",
    "Aragarcas",
    "Araguari",
    "Arandu",
    "Arapiraca",
    "Arapongas",
    "Arapoti",
    "Arapua",
    "Araquari",
    "Ararangua",
    "Araraquara",
    "Araras",
    "Ararica",
    "Araripina",
    "Araruama",
    "Araruna",
    "Araujos",
    "Arceburgo",
    "Arcos",
    "Arcoverde",
    "Areado",
    "Areal",
    "Arealva",
    "Areia Branca",
    "Areial",
    "Aricanduva",
    "Ariquemes",
    "Armacao de Buzios",
    "Armazem",
    "Aroeiras",
    "Arraial",
    "Arraial do Cabo",
    "Arroio do Meio",
    "Arroio do Silva",
    "Arroio do Tigre",
    "Arroio dos Ratos",
    "Arroio Grande",
    "Artur Nogueira",
    "Ascurra",
    "Assis",
    "Assis Chateaubriand",
    "Atibaia",
    "Auriflama",
    "Avanhandava",
    "Avare",
    "Azurita",
    "Bady Bassitt",
    "Baia da Traicao",
    "Baiao",
    "Bairro da Velha Central",
    "Baixo Guandu",
    "Balsa Nova",
    "Balsamo",
    "Balsas",
    "Bambui",
    "Banabuiu",
    "Bananal",
    "Bandeirantes",
    "Bangu",
    "Barao de Cocais",
    "Barao de Cotegipe",
    "Barao de Grajau",
    "Barauna",
    "Barbacena",
    "Barbalha",
    "Barbosa",
    "Barcarena",
    "Barra",
    "Barra",
    "Barra Bonita",
    "Barra da Tijuca",
    "Barra de Sao Miguel",
    "Barra do Bugres",
    "Barra do Corda",
    "Barra do Jacare",
    "Barra do Ribeiro",
    "Barra Mansa",
    "Barra Velha",
    "Barracao",
    "Barras",
    "Barreiras",
    "Barretos",
    "Barrinha",
    "Barro",
    "Barrocas",
    "Barueri",
    "Batatais",
    "Batista",
    "Baturite",
    "Bauru",
    "Bayeux",
    "Bebedouro",
    "Beberibe",
    "Bela Vista",
    "Bela Vista",
    "Bela Vista",
    "Bela Vista de Goias",
    "Bela Vista do Paraiso",
    "Belem",
    "Belem",
    "Belem do Brejo do Cruz",
    "Belford Roxo",
    "Belo Horizonte",
    "Belo Horizonte",
    "Belo Jardim",
    "Benedito Novo",
    "Benevides",
    "Bento",
    "Berilo",
    "Bernardo",
    "Bertioga",
    "Betania",
    "Betim",
    "Bezerros",
    "Bicas",
    "Biguacu",
    "Birigui",
    "Biritiba Mirim",
    "Biritinga",
    "Bituruna",
    "Blumenau",
    "Boa Esperanca",
    "Boa Esperanca",
    "Boa Ventura",
    "Boa Vista",
    "Boa Vista",
    "Boa Vista",
    "Boa Vista",
    "Boa Vista",
    "Boa Vista",
    "Boa Vista do Burica",
    "Bocaiuva",
    "Bocaiuva do Sul",
    "Boituva",
    "Bom Conselho",
    "Bom Despacho",
    "Bom Jardim",
    "Bom Jardim de Minas",
    "Bom Jesus",
    "Bom Jesus",
    "Bom Jesus",
    "Bom Jesus da Lapa",
    "Bom Jesus do Amparo",
    "Bom Jesus do Itabapoana",
    "Bom Jesus dos Perdoes",
    "Bom Principio",
    "Bom Retiro do Sul",
    "Bombinhas",
    "Bonfim",
    "Bonito",
    "Bonito",
    "Bonito",
    "Bonito",
    "Boqueirao",
    "Boquim",
    "Borda da Mata",
    "Borrazopolis",
    "Botafogo",
    "Botafogo",
    "Botucatu",
    "Botupora",
    "Botuvera",
    "Braco do Norte",
    "Braganca",
    "Braganca Paulista",
    "Branco",
    "Bras",
    "Brasa",
    "Brasil",
    "Brasilandia",
    "Brasopolis",
    "Brejetuba",
    "Brejinho de Nazare",
    "Brejo da Madre de Deus",
    "Brejo Santo",
    "Breves",
    "Brochier",
    "Brodosqui",
    "Brotas",
    "Brumadinho",
    "Brumado",
    "Brusque",
    "Bueno Brandao",
    "Buique",
    "Buritama",
    "Buriti",
    "Buriti",
    "Buritizeiro",
    "Butia",
    "Buzios",
    "Caapora",
    "Caarapo",
    "Cabedelo",
    "Cabo de Santo Agostinho",
    "Cabo Frio",
    "Cabralia",
    "Cabralia Paulista",
    "Cabreuva",
    "Cabrobo",
    "Cacapava",
    "Cacapava do Sul",
    "Cacequi",
    "Caceres",
    "Cachoeira",
    "Cachoeira do Sul",
    "Cachoeira Grande",
    "Cachoeira Paulista",
    "Cachoeiras",
    "Cachoeiras de Macacu",
    "Cachoeirinha",
    "Cachoeiro de Itapemirim",
    "Cacimba de Dentro",
    "Cacique Doble",
    "Cacoal",
    "Caete",
    "Caetite",
    "Cafarnaum",
    "Cafelandia",
    "Caiana",
    "Caiaponia",
    "Caibate",
    "Caico",
    "Caieiras",
    "Caiua",
    "Cajamar",
    "Cajati",
    "Cajazeiras",
    "Cajobi",
    "Cajuru",
    "Calcado",
    "Calcoene",
    "Caldas",
    "Caldas Novas",
    "California",
    "Camacari",
    "Camamu",
    "Camanducaia",
    "Camaqua",
    "Camaragibe",
    "Cambe",
    "Camboriu",
    "Cambui",
    "Cambuquira",
    "Cameta",
    "Campestre",
    "Campina",
    "Campina Grande",
    "Campina Grande do Sul",
    "Campinas",
    "Campinas do Sul",
    "Campinorte",
    "Campo",
    "Campo Alegre",
    "Campo Alegre",
    "Campo Bom",
    "Campo Ere",
    "Campo Formoso",
    "Campo Grande",
    "Campo Grande",
    "Campo Largo",
    "Campo Magro",
    "Campo Maior",
    "Campo Mourao",
    "Campo Novo",
    "Campo Verde",
    "Campos",
    "Campos",
    "Campos do Jordao",
    "Campos dos Goytacazes",
    "Campos Gerais",
    "Campos Novos",
    "Campos Sales",
    "Canarana",
    "Canas",
    "Canavieiras",
    "Candeal",
    "Candeias",
    "Candeias",
    "Candelaria",
    "Candido Mota",
    "Candoi",
    "Canela",
    "Canelinha",
    "Cangucu",
    "Canhotinho",
    "Caninde",
    "Caninde de Sao Francisco",
    "Canoas",
    "Canoinhas",
    "Cansancao",
    "Cantagalo",
    "Cantanhede",
    "Capanema",
    "Capao Alto",
    "Capao Bonito",
    "Capao da Canoa",
    "Capao do Leao",
    "Caparao",
    "Capela",
    "Capela",
    "Capela do Alto",
    "Capelinha",
    "Capetinga",
    "Capinopolis",
    "Capistrano",
    "Capitao Poco",
    "Capivari",
    "Caputira",
    "Caraa",
    "Caraguatatuba",
    "Carambei",
    "Carangola",
    "Carapebus",
    "Carapicuiba",
    "Caratinga",
    "Caraubas",
    "Carazinho",
    "Cardeal da Silva",
    "Cardoso",
    "Cariacica",
    "Carinhanha",
    "Caripi",
    "Carius",
    "Carlos Barbosa",
    "Carmo da Mata",
    "Carmo do Cajuru",
    "Carmo do Rio Claro",
    "Carmopolis",
    "Carmopolis de Minas",
    "Carnaiba",
    "Carolina",
    "Carpina",
    "Caruaru",
    "Casa Nova",
    "Casca",
    "Cascavel",
    "Casimiro de Abreu",
    "Cassia",
    "Cassilandia",
    "Cassino",
    "Castanhal",
    "Castelo",
    "Castro",
    "Cataguases",
    "Catalao",
    "Catanduva",
    "Catanduvas",
    "Catarina",
    "Catende",
    "Catu",
    "Caucaia",
    "Caxambu",
    "Caxambu",
    "Caxias",
    "Caxias do Sul",
    "Ceara Mirim",
    "Cedral",
    "Cedro do Abaete",
    "Ceilandia",
    "Centenario do Sul",
    "Central",
    "Ceres",
    "Cerqueira Cesar",
    "Cerquilho",
    "Cerro Azul",
    "Cerro Branco",
    "Cerro Grande",
    "Cerro Largo",
    "Cesario Lange",
    "Ceu Azul",
    "Cha Grande",
    "Chapada",
    "Chapadao",
    "Chapadao",
    "Chapadinha",
    "Charqueada",
    "Charqueadas",
    "Chaval",
    "Chavantes",
    "Chopinzinho",
    "Cianorte",
    "Cicero Dantas",
    "Cidade",
    "Cidade",
    "Cidade de Deus",
    "Cidade Nova",
    "Cipotanea",
    "Claro dos Pocoes",
    "Claudio",
    "Clementina",
    "Clevelandia",
    "Coari",
    "Cocal",
    "Cocalinho",
    "Cocos",
    "Coelho",
    "Colatina",
    "Colina",
    "Colinas",
    "Colinas",
    "Colombo",
    "Colorado",
    "Comodoro",
    "Conceicao",
    "Conceicao",
    "Conceicao da Barra",
    "Conceicao das Alagoas",
    "Conceicao de Macabu",
    "Conceicao do Araguaia",
    "Conceicao do Coite",
    "Conceicao do Mato Dentro",
    "Conchal",
    "Conchas",
    "Concordia",
    "Condado",
    "Conde",
    "Conde",
    "Congonhas",
    "Conselheiro Lafaiete",
    "Contagem",
    "Contenda",
    "Convento da Penha",
    "Cordeiro",
    "Cordeiropolis",
    "Cordilheira",
    "Coreau",
    "Coremas",
    "Corguinho",
    "Cornelio Procopio",
    "Coroados",
    "Coroas",
    "Coroata",
    "Coromandel",
    "Coronel",
    "Coronel Fabriciano",
    "Correia Pinto",
    "Correntina",
    "Cortes",
    "Corumba de Goias",
    "Coruripe",
    "Cosmopolis",
    "Cosmorama",
    "Cotia",
    "Crateus",
    "Crato",
    "Cravinhos",
    "Cristais",
    "Cristalina",
    "Cristiano Otoni",
    "Cruz",
    "Cruz Alta",
    "Cruz das Almas",
    "Cruzeiro",
    "Cruzeiro da Fortaleza",
    "Cruzeiro do Oeste",
    "Cruzeiro do Sul",
    "Cruzeiro do Sul",
    "Cruzilia",
    "Cubatao",
    "Cuite",
    "Cunha Pora",
    "Cupira",
    "Curimata",
    "Curitiba",
    "Curitibanos",
    "Currais Novos",
    "Cururupu",
    "Curvelo",
    "Delfinopolis",
    "Descalvado",
    "Descanso",
    "Diadema",
    "Diamantina",
    "Diamantino",
    "Dias",
    "Dionisio Cerqueira",
    "Divinopolis",
    "Dix-sept Rosado",
    "Dobrada",
    "Dois Corregos",
    "Dois Irmaos",
    "Dois Vizinhos",
    "Dom Feliciano",
    "Dom Pedrito",
    "Dom Silverio",
    "Domingos Martins",
    "Dores do Indaia",
    "Dourado",
    "Dourados",
    "Doutor Camargo",
    "Doutor Mauricio Cardoso",
    "Dracena",
    "Duartina",
    "Duas Barras",
    "Dumont",
    "Duque de Caxias",
    "Eldorado",
    "Elias Fausto",
    "Elisiario",
    "Eloi Mendes",
    "Embu",
    "Embu Guacu",
    "Encantado",
    "Encruzilhada do Sul",
    "Engenheiro Beltrao",
    "Engenheiro Schmidt",
    "Entre Rios",
    "Entre Rios de Minas",
    "Erechim",
    "Erval Grande",
    "Erval Velho",
    "Ervalia",
    "Escada",
    "Esmeraldas",
    "Espera Feliz",
    "Esperanca",
    "Esperanca",
    "Esperantina",
    "Espinosa",
    "Espirito Santo",
    "Espirito Santo do Pinhal",
    "Espumoso",
    "Estancia",
    "Estancia Nova",
    "Estancia Velha",
    "Esteio",
    "Estiva",
    "Estreito",
    "Estrela",
    "Eugenio",
    "Eugenopolis",
    "Eusebio",
    "Extrema",
    "Extremoz",
    "Exu",
    "Farroupilha",
    "Fartura",
    "Fatima",
    "Faxinal",
    "Faxinal do Soturno",
    "Faxinal dos Guedes",
    "Fazenda Rio Grande",
    "Feira de Santana",
    "Feira Nova",
    "Feliz",
    "Fenix",
    "Fernando Prestes",
    "Fernandopolis",
    "Ferraz de Vasconcelos",
    "Flexeiras",
    "Florania",
    "Flores",
    "Flores da Cunha",
    "Floresta Azul",
    "Floriano",
    "Florinia",
    "Formiga",
    "Formosa",
    "Forquilha",
    "Forquilha",
    "Forquilhinha",
    "Fortaleza",
    "Fortaleza",
    "Fortaleza",
    "Fortuna",
    "Foz do Jordao",
    "Fragoso",
    "Franca",
    "Francisco Beltrao",
    "Francisco Morato",
    "Francisco Sa",
    "Franco da Rocha",
    "Frederico Westphalen",
    "Frei Paulo",
    "Freitas",
    "Fronteira",
    "Frutal",
    "Fundao",
    "Gameleira",
    "Gandu",
    "Garanhuns",
    "Garca",
    "Gardenia Azul",
    "Garibaldi",
    "Garimpo Novo",
    "Garopaba",
    "Garuva",
    "Gaspar",
    "Gaviao Peixoto",
    "General Carneiro",
    "General Salgado",
    "Getulio Vargas",
    "Girau do Ponciano",
    "Gloria",
    "Gloria do Goita",
    "Glorinha",
    "Goiana",
    "Goianapolis",
    "Goianesia",
    "Goianinha",
    "Goianira",
    "Goias",
    "Gomes",
    "Goncalves",
    "Governador Valadares",
    "Graca Aranha",
    "Grajau",
    "Gramado",
    "Granito",
    "Granja",
    "Grao Mogol",
    "Groairas",
    "Guacui",
    "Guai",
    "Guaianases",
    "Guaicara",
    "Guaira",
    "Guaira",
    "Guajeru",
    "Guamare",
    "Guanambi",
    "Guanhaes",
    "Guapiacu",
    "Guapimirim",
    "Guapo",
    "Guapore",
    "Guara",
    "Guara",
    "Guarabira",
    "Guaraci",
    "Guaraciaba",
    "Guaragi",
    "Guaramirim",
    "Guaranesia",
    "Guarani dOeste",
    "Guaranta",
    "Guarapari",
    "Guarapuava",
    "Guararapes",
    "Guararapes",
    "Guararema",
    "Guaratingueta",
    "Guaratuba",
    "Guarei",
    "Guariba",
    "Guaruja",
    "Guarulhos",
    "Guaxupe",
    "Gurjao",
    "Gurupi",
    "Heliodora",
    "Heliopolis",
    "Horizontina",
    "Iacanga",
    "Iacu",
    "Ibaiti",
    "Ibate",
    "Ibatiba",
    "Ibiaca",
    "Ibicoara",
    "Ibipeba",
    "Ibipora",
    "Ibirama",
    "Ibirarema",
    "Ibirataia",
    "Ibirite",
    "Ibiruba",
    "Ibitinga",
    "Ibiuna",
    "Icapui",
    "Icara",
    "Icarai",
    "Icem",
    "Ico",
    "Icoraci",
    "Igaracu do Tiete",
    "Igarapava",
    "Igarape",
    "Igarassu",
    "Igarata",
    "Igreja Nova",
    "Igrejinha",
    "Iguaba Grande",
    "Iguai",
    "Iguape",
    "Iguaracu",
    "Iguatu",
    "Ijui",
    "Ilha Solteira",
    "Ilhabela",
    "Ilhota",
    "Imbituba",
    "Imbituva",
    "Imperatriz",
    "Imperatriz",
    "Indaial",
    "Indaiatuba",
    "Indianopolis",
    "Inga",
    "Inhacora",
    "Inhangapi",
    "Inhauma",
    "Inhumas",
    "Inocencia",
    "Inubia Paulista",
    "Ipaba",
    "Ipameri",
    "Ipatinga",
    "Ipaucu",
    "Ipero",
    "Ipeuna",
    "Ipiau",
    "Ipira",
    "Ipiranga",
    "Ipojuca",
    "Ipora",
    "Ipu",
    "Ipua",
    "Ipubi",
    "Ipumirim",
    "Iracema",
    "Iracemapolis",
    "Iraceminha",
    "Irai",
    "Iranduba",
    "Irati",
    "Irece",
    "Iretama",
    "Irupi",
    "Itabaiana",
    "Itabaiana",
    "Itabaianinha",
    "Itabapoana",
    "Itaberaba",
    "Itabira",
    "Itabirinha de Mantena",
    "Itabirito",
    "Itaborai",
    "Itabuna",
    "Itacare",
    "Itaete",
    "Itagi",
    "Itagiba",
    "Itaguacu",
    "Itaguai",
    "Itaguatins",
    "Itaipava",
    "Itaipu",
    "Itaitinga",
    "Itaituba",
    "Itajobi",
    "Itajuba",
    "Itajuipe",
    "Italva",
    "Itamaraca",
    "Itamarati",
    "Itambaraca",
    "Itambe",
    "Itamogi",
    "Itamonte",
    "Itanhaem",
    "Itanhandu",
    "Itanhomi",
    "Itaocara",
    "Itapaci",
    "Itapagipe",
    "Itaparica",
    "Itape",
    "Itapecerica",
    "Itapecerica da Serra",
    "Itapecuru Mirim",
    "Itapejara",
    "Itapema",
    "Itaperuna",
    "Itapetinga",
    "Itapetininga",
    "Itapeva",
    "Itapevi",
    "Itapicuru",
    "Itapipoca",
    "Itapira",
    "Itapiranga",
    "Itapissuma",
    "Itapixuna",
    "Itapo",
    "Itapolis",
    "Itaporanga",
    "Itapua",
    "Itapuranga",
    "Itaquaquecetuba",
    "Itaqui",
    "Itarantim",
    "Itarare",
    "Itatiaia",
    "Itatiba",
    "Itatinga",
    "Itatira",
    "Itau de Minas",
    "Itauna",
    "Itinga da Serra",
    "Itirapina",
    "Itiuba",
    "Itobi",
    "Itororo",
    "Itu",
    "Itubera",
    "Ituiutaba",
    "Itumbiara",
    "Ituporanga",
    "Iturama",
    "Ituverava",
    "Iuna",
    "Ivaipora",
    "Ivoti",
    "Jaboatao dos Guararapes",
    "Jabora",
    "Jaborandi",
    "Jaboticabal",
    "Jaboticatubas",
    "Jacarau",
    "Jacarepagua",
    "Jacarezinho",
    "Jaci",
    "Jaciara",
    "Jacobina",
    "Jacuizinho",
    "Jacupiranga",
    "Jacutinga",
    "Jaguapita",
    "Jaguaquara",
    "Jaguarao",
    "Jaguaras",
    "Jaguariaiva",
    "Jaguaribe",
    "Jaguaripe",
    "Jaguariuna",
    "Jaguaruana",
    "Jaguaruna",
    "Jaiba",
    "Jales",
    "Jampruca",
    "Janauba",
    "Jandaia do Sul",
    "Jandira",
    "Japaratinga",
    "Japeri",
    "Japira",
    "Jardim Alegre",
    "Jardim de Piranhas",
    "Jardinopolis",
    "Jarinu",
    "Jaru",
    "Jatai",
    "Jataizinho",
    "Jequitinhonha",
    "Jerico",
    "Jeronimo Monteiro",
    "Jesuania",
    "Jetiba",
    "Ji Parana",
    "Jiquirica",
    "Joacaba",
    "Joanopolis",
    "Joao Camara",
    "Joao Lisboa",
    "Joao Monlevade",
    "Joao Neiva",
    "Joao Pinheiro",
    "Joaquim",
    "Joaquim Tavora",
    "Joinville",
    "Jose Boiteux",
    "Jose Bonifacio",
    "Jose de Freitas",
    "Juatuba",
    "Juazeiro",
    "Juazeiro do Norte",
    "Jucara",
    "Jucurutu",
    "Juiz de Fora",
    "Julio de Castilhos",
    "Julio Mesquita",
    "Jumirim",
    "Jundiai do Sul",
    "Junqueiro",
    "Junqueiropolis",
    "Juquia",
    "Juquitiba",
    "Juripiranga",
    "Juru",
    "Juruaia",
    "Juruena",
    "Juruti",
    "Jussara",
    "Jussari",
    "Ladario",
    "Lagarto",
    "Lages",
    "Lagoa",
    "Lagoa da Canoa",
    "Lagoa da Prata",
    "Lagoa do Carro",
    "Lagoa dos Gatos",
    "Lagoa Salgada",
    "Lagoa Santa",
    "Lagoa Seca",
    "Lagoa Vermelha",
    "Laguna",
    "Laguna Carapa",
    "Lajeado",
    "Lajinha",
    "Lambari",
    "Lambari",
    "Lapa",
    "Lapa",
    "Laranjal Paulista",
    "Laranjeiras",
    "Laranjeiras do Sul",
    "Lauro",
    "Lavinia",
    "Lavras",
    "Lavras da Mangabeira",
    "Lavras do Sul",
    "Lavrinhas",
    "Lebon Regis",
    "Leme",
    "Lencois",
    "Lencois Paulista",
    "Leopoldina",
    "Liberdade",
    "Lima Campos",
    "Limeira",
    "Limoeiro",
    "Limoeiro do Norte",
    "Linhares",
    "Lins",
    "Loanda",
    "Londrina",
    "Lontra",
    "Lorena",
    "Louveira",
    "Lucena",
    "Luis Antonio",
    "Luis Antonio",
    "Luis Correia",
    "Lumiar",
    "Luminarias",
    "Luz",
    "Luzerna",
    "Luziania",
    "Macaiba",
    "Macaparana",
    "Macatuba",
    "Macau",
    "Macaubas",
    "Macedonia",
    "Machadinho",
    "Machado",
    "Machados",
    "Macuco",
    "Madre de Deus",
    "Mafra",
    "Mage",
    "Mairi",
    "Mairinque",
    "Malacacheta",
    "Mamanguape",
    "Mambore",
    "Mamonas",
    "Manacapuru",
    "Manaus",
    "Mandaguacu",
    "Mandaguari",
    "Mandirituba",
    "Manga",
    "Mangabeira",
    "Mangaratiba",
    "Mangueirinha",
    "Manhuacu",
    "Manhumirim",
    "Mantena",
    "Mantenopolis",
    "Mar de Espanha",
    "Maracaju",
    "Maracana",
    "Maracana",
    "Maracanau",
    "Maragogipe",
    "Maraial",
    "Maranguape",
    "Marataizes",
    "Marau",
    "Maravilha",
    "Marcelino Ramos",
    "Marcelino Vieira",
    "Marco",
    "Marcolandia",
    "Marechal Candido Rondon",
    "Marechal Deodoro",
    "Maria da Fe",
    "Marialva",
    "Mariana",
    "Marica",
    "Marilandia",
    "Mariluz",
    "Marituba",
    "Marques",
    "Martinopolis",
    "Maruim",
    "Marumbi",
    "Marzagao",
    "Massape",
    "Massaranduba",
    "Matao",
    "Matelandia",
    "Mateus Leme",
    "Matina",
    "Matinhos",
    "Matipo",
    "Mato Grosso",
    "Matozinhos",
    "Matutina",
    "Maua",
    "Maxaranguape",
    "Medeiros Neto",
    "Medianeira",
    "Meier",
    "Mendes",
    "Mendes Pimentel",
    "Mendonca",
    "Mesquita",
    "Messias",
    "Miguel Pereira",
    "Miguelopolis",
    "Milagres",
    "Minas",
    "Mineiros",
    "Mineiros do Tiete",
    "Miracatu",
    "Miracema",
    "Miracema do Tocantins",
    "Miraima",
    "Miranda",
    "Miranda",
    "Mirandopolis",
    "Mirassol",
    "Mirim Doce",
    "Mirinzal",
    "Missao Velha",
    "Mococa",
    "Moema",
    "Mogeiro",
    "Mogi das Cruzes",
    "Mogi Mirim",
    "Mogi-Gaucu",
    "Mongagua",
    "Monte Alegre",
    "Monte Alegre",
    "Monte Alto",
    "Monte Aprazivel",
    "Monte Azul",
    "Monte Azul Paulista",
    "Monte Belo",
    "Monte Carmelo",
    "Monte Dourado",
    "Monte Mor",
    "Monte Santo",
    "Monte Siao",
    "Monteiro",
    "Monteiro Lobato",
    "Montenegro",
    "Montes Altos",
    "Montes Claros",
    "Montividiu",
    "Morada Nova",
    "Moreno",
    "Morretes",
    "Morrinhos",
    "Morrinhos",
    "Morro Agudo",
    "Morro da Fumaca",
    "Morro de Sao Paulo",
    "Morro do Chapeu",
    "Morro Reuter",
    "Morungaba",
    "Mossoro",
    "Mostardas",
    "Mucuri",
    "Muliterno",
    "Munhoz",
    "Muniz Ferreira",
    "Muniz Freire",
    "Muqui",
    "Muritiba",
    "Mutum",
    "Mutum",
    "Muzambinho",
    "Nanuque",
    "Nao Me Toque",
    "Narandiba",
    "Natal",
    "Natercia",
    "Navegantes",
    "Navirai",
    "Nazare",
    "Nazare da Mata",
    "Nazare Paulista",
    "Neopolis",
    "Neropolis",
    "Nilo Pecanha",
    "Nilopolis",
    "Niquelandia",
    "Niteroi",
    "Niteroi",
    "Nobres",
    "Norte",
    "Nossa Senhora",
    "Nossa Senhora da Gloria",
    "Nossa Senhora Das Gracas",
    "Nossa Senhora do Socorro",
    "Nova",
    "Nova",
    "Nova Alianca",
    "Nova Araca",
    "Nova Aurora",
    "Nova Bassano",
    "Nova Brescia",
    "Nova Canaa",
    "Nova Cruz",
    "Nova Esperanca",
    "Nova Esperanca",
    "Nova Friburgo",
    "Nova Independencia",
    "Nova Itarana",
    "Nova Lima",
    "Nova Londrina",
    "Nova Odessa",
    "Nova Palma",
    "Nova Petropolis",
    "Nova Ponte",
    "Nova Prata",
    "Nova Resende",
    "Nova Serrana",
    "Nova Timboteua",
    "Nova Trento",
    "Nova Venecia",
    "Novo Hamburgo",
    "Novo Horizonte",
    "Novo Mundo",
    "Novo Oriente",
    "Novo Recreio",
    "Nucleo Bandeirante",
    "Nunes Freire",
    "Nuporanga",
    "Ocara",
    "Oeiras",
    "Olimpia",
    "Olinda",
    "Oliveira",
    "Oratorios",
    "Orlandia",
    "Orleans",
    "Orobo",
    "Osasco",
    "Osorio",
    "Otacilio Costa",
    "Ouricangas",
    "Ouricuri",
    "Ourinhos",
    "Ouro Branco",
    "Ouro Fino",
    "Ouro Preto",
    "Ouro Preto do Oeste",
    "Ouro Verde",
    "Ouvidor",
    "Pacaembu",
    "Pacatuba",
    "Pachecos",
    "Padre",
    "Paicandu",
    "Palestina",
    "Palestina",
    "Palhoca",
    "Palma",
    "Palmares",
    "Palmares do Sul",
    "Palmas",
    "Palmas",
    "Palmeira",
    "Palmeira",
    "Palmeira das Missoes",
    "Palmeira dos Indios",
    "Palmeiras de Goias",
    "Palmelo",
    "Palmital",
    "Palmital",
    "Palmitinho",
    "Palmitos",
    "Palotina",
    "Panambi",
    "Pancas",
    "Panorama",
    "Pao de Acucar",
    "Papagaios",
    "Para de Minas",
    "Paracambi",
    "Paracuru",
    "Paragominas",
    "Paraguacu",
    "Paraguacu Paulista",
    "Parai",
    "Paraiba do Sul",
    "Paraibano",
    "Paraibuna",
    "Paraipaba",
    "Paraiso",
    "Paraiso",
    "Paraiso do Tocantins",
    "Paraisopolis",
    "Paraitinga",
    "Parana",
    "Parana",
    "Parana",
    "Paranaiba",
    "Paranapanema",
    "Paranavai",
    "Paranoa",
    "Paraopeba",
    "Parapeuna",
    "Paraty",
    "Parelhas",
    "Pariquera Acu",
    "Parnaiba",
    "Parnamirim",
    "Parnamirim",
    "Parobe",
    "Passo Fundo",
    "Passos",
    "Pastos Bons",
    "Pato Branco",
    "Patos",
    "Patos de Minas",
    "Patrocinio",
    "Patrocinio Paulista",
    "Pau Brasil",
    "Pau dos Ferros",
    "Pauini",
    "Pauliceia",
    "Paulinia",
    "Paulista",
    "Paulista",
    "Paulo Afonso",
    "Paulo Jacinto",
    "Paulo Lopes",
    "Paverama",
    "Peabiru",
    "Pecem",
    "Pederneiras",
    "Pedra Bonita",
    "Pedra Grande",
    "Pedranopolis",
    "Pedreira",
    "Pedreira",
    "Pedro Afonso",
    "Pedro Avelino",
    "Pedro de Toledo",
    "Pedro Leopoldo",
    "Pelotas",
    "Pena",
    "Penapolis",
    "Pendencias",
    "Penedo",
    "Penha",
    "Perdigao",
    "Perdoes",
    "Pereira Barreto",
    "Pereiro",
    "Peruibe",
    "Pesqueira",
    "Petrolina",
    "Piabeta",
    "Picada",
    "Picos",
    "Picui",
    "Piedade",
    "Piedade do Rio Grande",
    "Piedade dos Gerais",
    "Pien",
    "Pilao Arcado",
    "Pilar do Sul",
    "Pimenta",
    "Pimenta Bueno",
    "Pindamonhangaba",
    "Pindobacu",
    "Pindoretama",
    "Pinhais",
    "Pinhalao",
    "Pinhalzinho",
    "Pinheiral",
    "Pinhoes",
    "Piquet Carneiro",
    "Piracaia",
    "Piracanjuba",
    "Piracema",
    "Piracicaba",
    "Pirai",
    "Pirai do Sul",
    "Piraju",
    "Pirajuba",
    "Pirajui",
    "Pirangi",
    "Pirapora do Bom Jesus",
    "Pirapozinho",
    "Piraquara",
    "Pirassununga",
    "Piratininga",
    "Piritiba",
    "Pitanga",
    "Pitangueiras",
    "Pitangueiras",
    "Pitangui",
    "Piuma",
    "Planalto",
    "Planalto",
    "Platina",
    "Poa",
    "Poco Branco",
    "Poco das Antas",
    "Poco Verde",
    "Pojuca",
    "Pombal",
    "Pomerode",
    "Pompeia",
    "Pompeu",
    "Pongai",
    "Ponta Grossa",
    "Ponta Pora",
    "Pontal",
    "Pontal do Parana",
    "Pontalina",
    "Ponte de Itabapoana",
    "Ponte Nova",
    "Ponte Serrada",
    "Pontes e Lacerda",
    "Porangatu",
    "Porecatu",
    "Portao",
    "Porteira do Pinhal",
    "Porto",
    "Porto Alegre",
    "Porto Amazonas",
    "Porto Belo",
    "Porto Esperidiao",
    "Porto Feliz",
    "Porto Ferreira",
    "Porto Grande",
    "Porto Nacional",
    "Porto Real",
    "Porto Sao Jose Velho",
    "Porto Seguro",
    "Porto Uniao",
    "Porto Velho",
    "Porto Velho",
    "Posse",
    "Posto Fiscal Rolim de Moura",
    "Pote",
    "Potirendaba",
    "Pouso Alegre",
    "Pradopolis",
    "Praia Grande",
    "Prata",
    "Pratania",
    "Pratapolis",
    "Presidente Bernardes",
    "Presidente Dutra",
    "Presidente Epitacio",
    "Presidente Getulio",
    "Presidente Kennedy",
    "Presidente Medici",
    "Presidente Olegario",
    "Presidente Prudente",
    "Presidente Venceslau",
    "Primavera",
    "Primeiro de Maio",
    "Progresso",
    "Progresso",
    "Promissao",
    "Propria",
    "Prudentopolis",
    "Putinga",
    "Puxinana",
    "Quarai",
    "Quata",
    "Quatigua",
    "Quatis",
    "Quatro Barras",
    "Quatro Pontes",
    "Quebrangulo",
    "Queimadas",
    "Queimados",
    "Querencia",
    "Querencia do Norte",
    "Quilombo",
    "Quipapa",
    "Quirinopolis",
    "Quissama",
    "Quitandinha",
    "Quixada",
    "Quixeramobim",
    "Quixere",
    "Rafard",
    "Rancharia",
    "Rancho Queimado",
    "Raposa (1)",
    "Raposos",
    "Raul Soares",
    "Realeza",
    "Reboucas",
    "Recanto",
    "Recife",
    "Redencao",
    "Redencao",
    "Reduto",
    "Registro",
    "Remigio",
    "Renascenca",
    "Repartimento",
    "Resende",
    "Resende Costa",
    "Restinga",
    "Restinga Seca",
    "Riachao do Jacuipe",
    "Riacho das Almas",
    "Riacho Fundo",
    "Riacho Fundo",
    "Rialma",
    "Ribas do Rio Pardo",
    "Ribeira do Pombal",
    "Ribeirao",
    "Ribeirao Bonito",
    "Ribeirao Corrente",
    "Ribeirao do Pinhal",
    "Ribeirao Pires",
    "Rio Acima",
    "Rio Azul",
    "Rio Bananal",
    "Rio Bonito",
    "Rio Bonito",
    "Rio Branco",
    "Rio Branco",
    "Rio Branco do Sul",
    "Rio Brilhante",
    "Rio Casca",
    "Rio Claro",
    "Rio Claro",
    "Rio das Ostras",
    "Rio das Pedras",
    "Rio de Janeiro",
    "Rio do Pires",
    "Rio do Sul",
    "Rio dos Cedros",
    "Rio Formoso",
    "Rio Grande",
    "Rio Grande da Serra",
    "Rio Largo",
    "Rio Manso",
    "Rio Negrinho",
    "Rio Negro",
    "Rio Novo",
    "Rio Novo do Sul",
    "Rio Paranaiba",
    "Rio Pardo",
    "Rio Pardo de Minas",
    "Rio Piracicaba",
    "Rio Tinto",
    "Rio Verde de Mato Grosso",
    "Roca Sales",
    "Rodeio Bonito",
    "Rodeiro",
    "Rodrigues",
    "Rolandia",
    "Rolante",
    "Ronda Alta",
    "Rondon",
    "Rosalia",
    "Rosario",
    "Rosario do Sul",
    "Roseira",
    "Rubiataba",
    "Rubim",
    "Russas",
    "Sabara",
    "Sabaudia",
    "Sabinopolis",
    "Sacramento",
    "Sales",
    "Sales Oliveira",
    "Salesopolis",
    "Salgado",
    "Salgueiro",
    "Salinas da Margarida",
    "Salto",
    "Salto",
    "Salto de Pirapora",
    "Salto Grande",
    "Salvador",
    "Salvador",
    "Salvador",
    "Samambaia",
    "Sampaio Correia",
    "Sananduva",
    "Sangao",
    "Sanharo",
    "Santa",
    "Santa Adelia",
    "Santa Barbara",
    "Santa Barbara do Sul",
    "Santa Branca",
    "Santa Cruz",
    "Santa Cruz Cabralia",
    "Santa Cruz da Baixa Verde",
    "Santa Cruz do Capibaribe",
    "Santa Cruz do Rio Pardo",
    "Santa Cruz do Sul",
    "Santa Fe",
    "Santa Fe do Sul",
    "Santa Gertrudes",
    "Santa Helena",
    "Santa Helena",
    "Santa Ines",
    "Santa Isabel",
    "Santa Isabel do Ivai",
    "Santa Isabel do Para",
    "Santa Leopoldina",
    "Santa Lucia",
    "Santa Luzia",
    "Santa Luzia",
    "Santa Luzia",
    "Santa Luzia",
    "Santa Maria",
    "Santa Maria",
    "Santa Maria da Vitoria",
    "Santa Maria de Itabira",
    "Santa Rita",
    "Santa Rita do Passa Quatro",
    "Santa Rita do Sapucai",
    "Santa Rosa",
    "Santa Teresa",
    "Santa Teresa",
    "Santa Teresinha",
    "Santa Terezinha",
    "Santana",
    "Santana da Vargem",
    "Santana de Parnaiba",
    "Santana do Cariri",
    "Santana do Ipanema",
    "Santana do Livramento",
    "Santana do Matos",
    "Santana do Paraiso",
    "Santarem Novo",
    "Santiago",
    "Santo",
    "Santo Aleixo",
    "Santo Amaro",
    "Santo Amaro",
    "Santo Amaro da Imperatriz",
    "Santo Anastacio",
    "Santo Antonio",
    "Santo Antonio da Patrulha",
    "Santo Antonio da Platina",
    "Santo Antonio de Jesus",
    "Santo Antonio de Lisboa",
    "Santo Antonio de Padua",
    "Santo Antonio de Posse",
    "Santo Antonio do Amparo",
    "Santo Antonio do Caiua",
    "Santo Antonio do Grama",
    "Santo Antonio do Jardim",
    "Santo Antonio do Leverger",
    "Santo Antonio do Monte",
    "Santo Antonio do Pinhal",
    "Santo Antonio do Retiro",
    "Santo Estevao",
    "Santo Expedito",
    "Santo Inacio",
    "Santos",
    "Santos Dumont",
    "Sao Bento",
    "Sao Bento",
    "Sao Bento do Sapucai",
    "Sao Bernardo",
    "Sao Caetano",
    "Sao Caetano do Sul",
    "Sao Carlos",
    "Sao Cristovao",
    "Sao Domingos",
    "Sao Domingos",
    "Sao Domingos",
    "Sao Domingos",
    "Sao Domingos",
    "Sao Felipe",
    "Sao Felix",
    "Sao Fidelis",
    "Sao Francisco",
    "Sao Francisco",
    "Sao Francisco de Paula",
    "Sao Francisco do Conde",
    "Sao Francisco do Sul",
    "Sao Gabriel",
    "Sao Gabriel",
    "Sao Gabriel",
    "Sao Gabriel da Cachoeira",
    "Sao Geraldo do Araguaia",
    "Sao Goncalo",
    "Sao Goncalo do Amarante",
    "Sao Goncalo do Para",
    "Sao Goncalo do Sapucai",
    "Sao Goncalo dos Campos",
    "Sao Gotardo",
    "Sao Jeronimo",
    "Sao Joao",
    "Sao Joao Batista",
    "Sao Joao da Barra",
    "Sao Joao da Boa Vista",
    "Sao Joao de Meriti",
    "Sao Joao de Pirabas",
    "Sao Joao do Paraiso",
    "Sao Joao Evangelista",
    "Sao Joao Nepomuceno",
    "Sao Joaquim",
    "Sao Joaquim da Barra",
    "Sao Joaquim de Bicas",
    "Sao Joaquim do Monte",
    "Sao Jose",
    "Sao Jose",
    "Sao Jose",
    "Sao Jose da Barra",
    "Sao Jose da Laje",
    "Sao Jose de Mipibu",
    "Sao Jose de Piranhas",
    "Sao Jose de Ribamar",
    "Sao Jose do Calcado",
    "Sao Jose do Cedro",
    "Sao Jose do Egito",
    "Sao Jose do Goiabal",
    "Sao Jose do Inhacora",
    "Sao Jose do Ouro",
    "Sao Jose do Rio Pardo",
    "Sao Jose do Rio Preto",
    "Sao Lourenco da Mata",
    "Sao Lourenco da Serra",
    "Sao Lourenco do Sul",
    "Sao Lourenco dOeste",
    "Sao Luis",
    "Sao Luis de Montes Belos",
    "Sao Luis do Paraitinga",
    "Sao Luiz Gonzaga",
    "Sao Manoel",
    "Sao Manuel",
    "Sao Marcos",
    "Sao Miguel",
    "Sao Miguel",
    "Sao Miguel Arcanjo",
    "Sao Miguel do Araguaia",
    "Sao Miguel do Iguacu",
    "Sao Miguel dos Campos",
    "Sao Paulo",
    "Sao Paulo",
    "Sao Paulo do Potengi",
    "Sao Pedro",
    "Sao Pedro da Aldeia",
    "Sao Pedro do Ivai",
    "Sao Pedro do Sul",
    "Sao Pedro dos Ferros",
    "Sao Roque",
    "Sao Roque",
    "Sao Salvador",
    "Sao Sebastiao",
    "Sao Sebastiao",
    "Sao Sebastiao",
    "Sao Sebastiao",
    "Sao Sebastiao",
    "Sao Sebastiao da Amoreira",
    "Sao Sebastiao da Grama",
    "Sao Sebastiao do Cai",
    "Sao Sebastiao do Maranhao",
    "Sao Sebastiao do Paraiso",
    "Sao Sepe",
    "Sao Simao",
    "Sao Tome",
    "Sao Vicente",
    "Sao Vicente",
    "Sao Vicente de Paula",
    "Sape",
    "Sapeacu",
    "Sapiranga",
    "Sapucaia",
    "Sapucaia",
    "Sapucaia do Sul",
    "Saquarema",
    "Sarandi",
    "Sarandi",
    "Sarapui",
    "Sardoa",
    "Sarzedo",
    "Satuba",
    "Saudades",
    "Schroeder",
    "Seabra",
    "Seara",
    "Sebastianopolis do Sul",
    "Seberi",
    "Sede Nova",
    "Sem Peixe",
    "Senador Canedo",
    "Senhor do Bonfim",
    "Serafina",
    "Seropedica",
    "Serra",
    "Serra",
    "Serra",
    "Serra",
    "Serra Azul",
    "Serra Negra",
    "Serra Preta",
    "Serra Talhada",
    "Serrana",
    "Serrania",
    "Serrinha",
    "Serro",
    "Sertania",
    "Sertanopolis",
    "Sertao",
    "Sertaozinho",
    "Sete Lagoas",
    "Sidrolandia",
    "Silva Jardim",
    "Silveiras",
    "Simao Dias",
    "Simao Pereira",
    "Simoes",
    "Simplicio Mendes",
    "Siqueira Campos",
    "Sitio Figueira",
    "Sitio Novo",
    "Sobradinho",
    "Sobral",
    "Socorro",
    "Soledade",
    "Sombrio",
    "Sorocaba",
    "Sorriso",
    "Sousa",
    "Stio Ouro Fino",
    "Sulina",
    "Sumare",
    "Suzano",
    "Tabatinga",
    "Tabira",
    "Taboao da Serra",
    "Tabuleiro do Norte",
    "Taguatinga",
    "Taguatinga",
    "Taiacu",
    "Taiobeiras",
    "Taiuva",
    "Tamandare",
    "Tambau",
    "Tamboril",
    "Tamoios",
    "Tanabi",
    "Tangua",
    "Tanque Novo",
    "Tapejara",
    "Tapira",
    "Tapirai",
    "Taquara",
    "Taquaral",
    "Taquarana",
    "Taquari",
    "Taquaritinga",
    "Tarumirim",
    "Tatuamunha",
    "Taua",
    "Taubate",
    "Teixeira",
    "Teixeira",
    "Telemaco Borba",
    "Tenente Ananias",
    "Tenorio",
    "Teodoro Sampaio",
    "Terenos",
    "Teresina",
    "Teresopolis",
    "Terra Boa",
    "Terra Nova",
    "Terra Nova",
    "Terra Rica",
    "Terra Roxa",
    "Terra Roxa",
    "Teutonia",
    "Tiangua",
    "Tibagi",
    "Tibau",
    "Tiete",
    "Tijuca",
    "Tijucas",
    "Timbauba",
    "Timbe",
    "Timbo",
    "Timon",
    "Timoteo",
    "Tiradentes",
    "Tobias Barreto",
    "Tocantins",
    "Toledo",
    "Tomar do Geru",
    "Tome Acu",
    "Toritama",
    "Torres",
    "Touros",
    "Tramandai",
    "Tremembe",
    "Tres Barras",
    "Tres Cachoeiras",
    "Tres Coroas",
    "Tres Marias",
    "Tres Passos",
    "Tres Pontas",
    "Tres Rios",
    "Trindade",
    "Trindade",
    "Triunfo",
    "Tubarao",
    "Tucum",
    "Tucunduva",
    "Tucurui",
    "Tuiuti",
    "Tumiritinga",
    "Tupa",
    "Tupaciguara",
    "Tupanatinga",
    "Tupancireta",
    "Tuparendi",
    "Tupi Paulista",
    "Turmalina",
    "Turvo",
    "Turvo",
    "Uaua",
    "Uba",
    "Ubaira",
    "Ubaitaba",
    "Ubarana",
    "Ubata",
    "Ubatuba",
    "Uberaba",
    "Uberlandia",
    "Uchoa",
    "Uibai",
    "Uirauna",
    "Umbauba",
    "Umbuzeiro",
    "Umirim",
    "Umuarama",
    "Unai",
    "Uniao da Vitoria",
    "Urai",
    "Urania",
    "Urubici",
    "Uruburetama",
    "Urucania",
    "Urucuca",
    "Uruguaiana",
    "Urupes",
    "Urussanga",
    "Vacaria",
    "Valenca",
    "Valente",
    "Valentim Gentil",
    "Valerio",
    "Valinhos",
    "Valparaiso",
    "Vargem Alta",
    "Vargem Grande do Sul",
    "Vargem Grande Paulista",
    "Varginha",
    "Varjota",
    "Varzea",
    "Varzea Paulista",
    "Varzelandia",
    "Vassouras",
    "Velha",
    "Venancio Aires",
    "Venda Nova",
    "Vera Cruz",
    "Vera Cruz",
    "Vera Cruz",
    "Vera Cruz",
    "Veranopolis",
    "Vespasiano",
    "Vespasiano Correa",
    "Viana",
    "Vicencia",
    "Vicente",
    "Vicosa",
    "Vicosa",
    "Vicosa do Ceara",
    "Videira",
    "Vila Maria",
    "Vila Muriqui",
    "Vila Rica",
    "Vila Velha",
    "Vilar dos Teles",
    "Vilhena",
    "Vinhedo",
    "Virgem da Lapa",
    "Virginia",
    "Virginopolis",
    "Virmond",
    "Visconde do Rio Branco",
    "Vista Alegre do Alto",
    "Vitoria de Santo Antao",
    "Vitorino Freire",
    "Volta Grande",
    "Volta Redonda",
    "Votorantim",
    "Votuporanga",
    "Wenceslau Braz",
    "Witmarsum",
    "Xanxere",
    "Xavantina",
    "Xaxim"],
  },
  {
    name: "Brunei",
    cities: ["Bandar Seri Begawan",
    "Bangar",
    "Kampong Jerudong",
    "Kuala Balai",
    "Kuala Belait",
    "Panaga",
    "Seria",
    "Tutong"],
  },
  {
    name: "Bulgaria",
    cities: ["Akhtopol",
    "Aksakovo",
    "Aleksandriya",
    "Alfatar",
    "Anton",
    "Antonovo",
    "Ardino",
    "Asenovgrad",
    "Aytos",
    "Babovo",
    "Baltchik",
    "Banite",
    "Bankya",
    "Bansko",
    "Barutin",
    "Batak",
    "Belene",
    "Belogradchik",
    "Beloslav",
    "Belovo",
    "Berkovitsa",
    "Bistritsa",
    "Blagoevgrad",
    "Boboshevo",
    "Bobovdol",
    "Borovan",
    "Borovo",
    "Botevgrad",
    "Bratsigovo",
    "Bregare",
    "Bukovo",
    "Bukovo",
    "Burgas",
    "Byala",
    "Byala",
    "Byala Slatina",
    "Chakalarovo",
    "Chernolik",
    "Chernomorets",
    "Cherven",
    "Cherven Bryag",
    "Chiprovtsi",
    "Chirpan",
    "Choba",
    "Debelets",
    "Devin",
    "Dimitrovgrad",
    "Dobrich",
    "Dolna Banya",
    "Dolna Mitropoliya",
    "Dolna Oryakhovitsa",
    "Dospat",
    "Dragash Voyvoda",
    "Draginovo",
    "Dragizhevo",
    "Dryanovo",
    "Dulgopol",
    "Dulovo",
    "Dupnitsa",
    "Elena",
    "Elin Pelin",
    "Elkhovo",
    "Etropole",
    "Gabrovo",
    "Ganchovo",
    "General-Toshevo",
    "Glogovo",
    "Godech",
    "Gorna Oryakhovitsa",
    "Gotse Delchev",
    "Gulubovo",
    "Gurkovo",
    "Gurmazovo",
    "Haskovo",
    "Ikhtiman",
    "Iskrets",
    "Isperikh",
    "Isperikhovo",
    "Ivaylovgrad",
    "Kableshkovo",
    "Kamenovo",
    "Kardzhali",
    "Karlovo",
    "Karnobat",
    "Kavarna",
    "Kazanlak",
    "Kazashka Reka",
    "Kharmanli",
    "Khisarya",
    "Knezha",
    "Konush",
    "Koprivets",
    "Koprivlen",
    "Kostenets",
    "Kostievo",
    "Kostinbrod",
    "Kovachite",
    "Kozloduy",
    "Kran",
    "Krichim",
    "Krivodol",
    "Krumovgrad",
    "Krupnik",
    "Kula",
    "Kyustendil",
    "Lom",
    "Lovech",
    "Lozno",
    "Lukovit",
    "Lyaskovets",
    "Lyulyakovo",
    "Madan",
    "Malo Konare",
    "Marikostinovo",
    "Markovo",
    "Mesta",
    "Mezdra",
    "Mikrevo",
    "Moderno Predgradie",
    "Momchilgrad",
    "Montana",
    "Moravka",
    "Muglizh",
    "Nesebar",
    "Nikopol",
    "Nova Zagora",
    "Novi Iskur",
    "Novi Pazar",
    "Obzor",
    "Omurtag",
    "Orizovo",
    "Orlovets",
    "Oryakhovets",
    "Oryakhovitsa",
    "Ovoshtnik",
    "Panagyurishte",
    "Pavel Banya",
    "Pavlikeni",
    "Pazardzhik",
    "Pchelishte",
    "Pernik",
    "Peshtera",
    "Petrich",
    "Pleven",
    "Plovdiv",
    "Poleto",
    "Polski Trumbesh",
    "Pomorie",
    "Popovo",
    "Pravets",
    "Prespa",
    "Provadiya",
    "Radnevo",
    "Raduil",
    "Rakitovo",
    "Rakovski",
    "Ravda",
    "Razgrad",
    "Razlog",
    "Rila",
    "Rodopi",
    "Rousse",
    "Rozino",
    "Rudozem",
    "Ruen",
    "Samokov",
    "Samovodene",
    "Samuil",
    "Sandanski",
    "Sandrovo",
    "Septemvri",
    "Sevlievo",
    "Shabla",
    "Shivachevo",
    "Shumen",
    "Silistra",
    "Simeonovgrad",
    "Simitli",
    "Skravena",
    "Slatina",
    "Sliven",
    "Slivnitsa",
    "Smolyan",
    "Smyadovo",
    "Sofia",
    "Sofronievo",
    "Sokolets",
    "Sopot",
    "Sozopol",
    "Sredets",
    "Stamboliyski",
    "Stara Zagora",
    "Strazhitsa",
    "Strelcha",
    "Suedinenie",
    "Svilengrad",
    "Svishtov",
    "Svoge",
    "Targovishte",
    "Tervel",
    "Tochilari",
    "Topoli",
    "Topolovo",
    "Troyan",
    "Trustenik",
    "Tryavna",
    "Tutrakan",
    "Varna",
    "Veliko",
    "Veliko Tarnovo",
    "Velingrad",
    "Vetren",
    "Vidin",
    "Vievo",
    "Vlado Trichkov",
    "Vrachesh",
    "Vratsa",
    "Yakoruda",
    "Yambol",
    "Zavet",
    "Zlatitrap",
    "Zlatograd"],
  },
  {
    name: "Burkina Faso",
    cities: ["Ouagadougou", "Bobo-Dioulasso", "Koudougou"],
  },
  {
    name: "Burundi",
    cities: ["Bujumbura", "Gitega", "Ngozi"],
  },
  {
    name: "Cabo Verde",
    cities: ["Praia", "Mindelo", "Santa Maria"],
  },
  {
    name: "Cambodia",
    cities: ["Moung Roessei",
    "Phnom Penh",
    "Phumi Boeng (1)",
    "Phumi Chhuk",
    "Phumi Preah Haoh",
    "Phumi Prei",
    "Phumi Prek Mrinh",
    "Phumi Siem Reab",
    "Phumi Thmei",
    "Phumi Thnal",
    "Phumi Vott Phnum",
    "Sihanoukville",
    "Tuol Kok",
    "Vott Kampong Svay"],
  },
  {
    name: "Cameroon",
    cities: [ "Bafia",
    "Bafoussam",
    "Bamenda",
    "Buea",
    "Douala",
    "Kribi",
    "Kumba",
    "Ringo",
    "Tibati"],
  },
  {
    name: "Canada",
    cities: ["100 Mile House",
    "Abbey",
    "Abbotsford",
    "Acadia Valley",
    "Acme",
    "Acton",
    "Acton Vale",
    "Agassiz",
    "Agincourt",
    "Ailsa Craig",
    "Airdrie",
    "Ajax",
    "Aklavik",
    "Alameda",
    "Albanel",
    "Alberton",
    "Aldergrove",
    "Alert Bay",
    "Alexander",
    "Alexandria",
    "Alfred",
    "Allan",
    "Allardville",
    "Allenford",
    "Alliston",
    "Alma",
    "Alma",
    "Almonte",
    "Alton",
    "Altona",
    "Alvinston",
    "Amherst",
    "Amherstburg",
    "Amos",
    "Amqui",
    "Ancaster",
    "Andrew",
    "Ange-Gardien",
    "Angliers",
    "Angus",
    "Annapolis Royal",
    "Antigonish",
    "Apohaqui",
    "Apple Hill",
    "Apsley",
    "Arborfield",
    "Arborg",
    "Arcadia",
    "Arcola",
    "Arctic Bay",
    "Ardmore",
    "Ardrossan",
    "Arelee",
    "Arichat",
    "Arkona",
    "Armagh",
    "Armstrong",
    "Arnprior",
    "Arthabaska",
    "Arthur",
    "Arundel",
    "Arva",
    "Arviat",
    "Asbestos",
    "Ascot Corner",
    "Ashcroft",
    "Ashmont",
    "Ashville",
    "Assiniboia",
    "Aston-Jonction",
    "Athabasca",
    "Athens",
    "Atikameg",
    "Atikokan",
    "Atlin",
    "Attawapiskat",
    "Atwood",
    "Audet",
    "Aurora",
    "Austin",
    "Authier",
    "Avonmore",
    "Aylesford",
    "Aylmer",
    "Aylmer",
    "Aylsham",
    "Ayr",
    "Ayton",
    "Baddeck",
    "Baden",
    "Baie Verte",
    "Baie-Comeau",
    "Baie-Des-Sables",
    "Baie-du-Febvre",
    "Baie-Saint-Paul",
    "Baie-Sainte-Catherine",
    "Bala",
    "Balcarres",
    "Baldonnel",
    "Baldur",
    "Baltimore",
    "Bamfield",
    "Bancroft",
    "Banff",
    "Barkerville",
    "Barnaby River",
    "Barons",
    "Barraute",
    "Barrhead",
    "Barrie",
    "Barriere",
    "Barrington",
    "Barrington Passage",
    "Bashaw",
    "Bassano",
    "Bath",
    "Bathurst",
    "Batiscan",
    "Battleford",
    "Bay Bulls",
    "Bay de Verde",
    "Bay Roberts",
    "Bayfield",
    "Beachburg",
    "Beaconsfield",
    "Beamsville",
    "Bear River",
    "Bearn",
    "Bearskin Lake",
    "Beauceville",
    "Beauharnois",
    "Beaumont",
    "Beauport",
    "Beaupre",
    "Beausejour",
    "Beauval",
    "Beaux-Rivages--Lac-des-Ecorces--Val-Barrette",
    "Beaver Bank",
    "Beaver Crossing",
    "Beaverlodge",
    "Beaverton",
    "Becancour",
    "Bedford",
    "Bedford",
    "Beeton",
    "Begin",
    "Beiseker",
    "Bell Island",
    "Bella Bella",
    "Belle River",
    "Belleville",
    "Bellevue",
    "Bellevue",
    "Beloeil",
    "Belwood",
    "Bentley",
    "Berry Mills",
    "Berthier-Sur-Mer",
    "Berthierville",
    "Berwick",
    "Berwyn",
    "Bethune",
    "Betsiamites",
    "Bic",
    "Biencourt",
    "Bienfait",
    "Big River",
    "Big Valley",
    "Biggar",
    "Binscarth",
    "Birch Hills",
    "Birchy Bay",
    "Birtle",
    "Black Creek",
    "Black Diamond",
    "Blackfalds",
    "Blackie",
    "Blacks Harbour",
    "Blackville",
    "Blaine Lake",
    "Blainville",
    "Blairmore",
    "Blandford",
    "Blenheim",
    "Blind River",
    "Bloomfield",
    "Blue River",
    "Bluewater",
    "Bobcaygeon",
    "Bois-des-Filion",
    "Boisbriand",
    "Boischatel",
    "Boissevain",
    "Bolton",
    "Bonaventure",
    "Bonne Bay",
    "Bonnyville",
    "Bonsecours",
    "Bonshaw",
    "Borden",
    "Borden-Carleton",
    "Boswell",
    "Boucherville",
    "Bouctouche",
    "Bourget",
    "Bow Island",
    "Bowden",
    "Bowmanville",
    "Bowser",
    "Boyle",
    "Bracebridge",
    "Brackendale",
    "Bradford",
    "Bramalea",
    "Brampton",
    "Brandon",
    "Brantford",
    "Brechin",
    "Bredenbury",
    "Brentwood Bay",
    "Breslau",
    "Breton",
    "Bridge Lake",
    "Bridgetown",
    "Bridgewater",
    "Brigham",
    "Brighton",
    "Brigus",
    "Britt",
    "Broadview",
    "Brochet",
    "Brockville",
    "Brome",
    "Bromhead",
    "Bromont",
    "Brookfield",
    "Brooklyn",
    "Brooks",
    "Brossard",
    "Brossard",
    "Bruno",
    "Bryson",
    "Buckhorn",
    "Buckingham",
    "Buffalo Narrows",
    "Burgeo",
    "Burgessville",
    "Burin",
    "Burlington",
    "Burnaby",
    "Burns Lake",
    "Burnside",
    "Cabano",
    "Cabri",
    "Cache Creek",
    "Calder",
    "Caledon",
    "Caledon",
    "Caledonia",
    "Caledonia",
    "Calgary",
    "Calixa-Lavallee",
    "Callander",
    "Calmar",
    "Cambridge",
    "Cambridge",
    "Cambridge Bay",
    "Camlachie",
    "Campbell River",
    "Campbellford",
    "Campbellton",
    "Campbellville",
    "Camrose",
    "Canal Flats",
    "Candiac",
    "Canfield",
    "Canim Lake",
    "Canmore",
    "Canning",
    "Cannington",
    "Canoe Narrows",
    "Canora",
    "Canso",
    "Canterbury",
    "Canwood",
    "Canyon",
    "Cap-aux-Meules",
    "Cap-Chat",
    "Cap-Saint-Ignace",
    "Cap-Sante",
    "Cape Dorset",
    "Caraquet",
    "Carberry",
    "Carbonear",
    "Cardigan",
    "Cardinal",
    "Cardston",
    "Carignan",
    "Carleton Place",
    "Carleton-sur-Mer",
    "Carlisle",
    "Carlyle",
    "Carmacks",
    "Carman",
    "Carmel",
    "Carnduff",
    "Caroline",
    "Caronport",
    "Carp",
    "Carrot River",
    "Carrying Place",
    "Carseland",
    "Carstairs",
    "Carters Cove",
    "Cartier",
    "Cartwright",
    "Casimir",
    "Casselman",
    "Castle Bay",
    "Castlegar",
    "Cat Lake",
    "Causapscal",
    "Cavendish",
    "Cayley",
    "Cayuga",
    "Cedar Valley",
    "Central Bedeque",
    "Centreville",
    "Ceylon",
    "Chalk River",
    "Chambly",
    "Champlain",
    "Chandler",
    "Chapais",
    "Chapel Arm",
    "Chapleau",
    "Charlemagne",
    "Charlesbourg",
    "Charlie Lake",
    "Charlottetown",
    "Charny",
    "Chase",
    "Chateau-Richer",
    "Chateauguay",
    "Chatham",
    "Chatsworth",
    "Cheapside",
    "Chelmsford",
    "Chelsea",
    "Chemainus",
    "Cheneville",
    "Cherry Grove",
    "Chertsey",
    "Chesley",
    "Chester",
    "Chestermere",
    "Chesterville",
    "Cheticamp",
    "Chibougamau",
    "Chicoutimi",
    "Chicoutimi",
    "Chilliwack",
    "Chipman",
    "Church Point",
    "Churchill",
    "Churchill",
    "Churchville",
    "Chute-aux-Outardes",
    "Circonscription electorale de Jacques-Cartier",
    "Clair",
    "Clairmont",
    "Claremont",
    "Clarence",
    "Clarence-Rockland",
    "Clarenville",
    "Clarenville-Shoal Harbour",
    "Claresholm",
    "Clarke City",
    "Clarkson",
    "Clearwater",
    "Clementsvale",
    "Clermont",
    "Cleveland",
    "Clifford",
    "Clinton",
    "Clinton",
    "Clive",
    "Clyde River",
    "Coal Harbour",
    "Coaldale",
    "Coaticook",
    "Cobble Hill",
    "Cobden",
    "Coboconk",
    "Cobourg",
    "Coburg",
    "Cochrane",
    "Cochrane",
    "Colborne",
    "Cold Lake",
    "Coldstream",
    "Coldwater",
    "Coleman",
    "Coleman",
    "Coleraine",
    "Coleville",
    "Colinet",
    "Colliers",
    "Collingwood",
    "Colonsay",
    "Colville Lake",
    "Comber",
    "Comox",
    "Compton",
    "Conception Bay South",
    "Concord",
    "Conestogo",
    "Coniston",
    "Conquest",
    "Consort",
    "Contrecoeur",
    "Cookshire-Eaton",
    "Copper Cliff",
    "Coquitlam",
    "Corbyville",
    "Corner Brook",
    "Cornwall",
    "Cornwall",
    "Cornwallis",
    "Cote-Saint-Luc",
    "Cottam",
    "Courtenay",
    "Courtland",
    "Courtright",
    "Coutts",
    "Cow Head",
    "Cowansville",
    "Cowichan Bay",
    "Cowley",
    "Crabtree",
    "Craik",
    "Cranberry",
    "Cranberry Portage",
    "Cranbrook",
    "Crapaud",
    "Creemore",
    "Creston",
    "Crofton",
    "Cromer",
    "Cross Lake",
    "Crossfield",
    "Cudworth",
    "Cumberland",
    "Cumberland",
    "Cumberland Bay",
    "Cumberland House",
    "Cupar",
    "Cut Knife",
    "Czar",
    "Dalhousie",
    "Dalmeny",
    "Danville",
    "Dartmouth",
    "Dashwood",
    "Dauphin",
    "Daveluyville",
    "Davidson",
    "Dawson City",
    "Dawson Creek",
    "Dawson Settlement",
    "Daysland",
    "Debden",
    "Debert",
    "DeBolt",
    "Deep River",
    "Deer Lake",
    "Deer Lake",
    "Delaware",
    "Delaware West",
    "Delburne",
    "Delhi",
    "Deline",
    "Delisle",
    "Delson",
    "Delta",
    "Denfield",
    "Denman Island",
    "Deroche",
    "Desbiens",
    "Deschaillons-sur-Saint-Laurent",
    "Deschambault",
    "Deseronto",
    "Deux-Montagnes",
    "Devon",
    "Didsbury",
    "Dieppe",
    "Digby",
    "Digby",
    "Dinsmore",
    "Disraeli",
    "Dixville",
    "Doaktown",
    "Dog Creek",
    "Dolbeau-Mistassini",
    "Dollard-Des Ormeaux",
    "Domain",
    "Dominion City",
    "Domremy",
    "Don Mills",
    "Donnacona",
    "Donnelly",
    "Dorchester",
    "Dorion-Gardens",
    "Dorset",
    "Dorval",
    "Dover",
    "Downsview",
    "Drayton",
    "Drayton Valley",
    "Dresden",
    "Drumbo",
    "Drumheller",
    "Drummondville",
    "Dryden",
    "Dublin",
    "Duchess",
    "Duck Lake",
    "Dudswell",
    "Dufresne",
    "Dugald",
    "Duhamel",
    "Duncan",
    "Dundalk",
    "Dundas",
    "Dundurn",
    "Dunham",
    "Dunnville",
    "Dunsford",
    "Dupuy",
    "Durham",
    "Eaglesham",
    "Earlton",
    "East Angus",
    "East Broughton",
    "East Chezzetcook",
    "East Coulee",
    "East Margaree",
    "Eastern Passage",
    "Eastman",
    "Eastport",
    "Eatonville",
    "Echo Bay",
    "Eckville",
    "Economy",
    "Edam",
    "Eden",
    "Edgerton",
    "Edgewood",
    "Edmonton",
    "Edmundston",
    "Edson",
    "Eganville",
    "Elbow",
    "Elgin",
    "Elgin",
    "Elk Point",
    "Elliot Lake",
    "Elliston",
    "Elma",
    "Elmira",
    "Elmsdale",
    "Elmvale",
    "Elora",
    "Elrose",
    "Embrun",
    "Emerald",
    "Emerald Junction",
    "Emo",
    "Empress",
    "Emsdale",
    "Enderby",
    "Enfield",
    "Englehart",
    "Enterprise",
    "Entwistle",
    "Eriksdale",
    "Erin",
    "Espanola",
    "Esprit-Saint",
    "Essex",
    "Esterhazy",
    "Estevan",
    "Eston",
    "Etobicoke",
    "Eureka",
    "Everett",
    "Exeter",
    "Fabreville",
    "Fairmont Hot Springs",
    "Fairview, Alberta",
    "Farnham",
    "Fenelon Falls",
    "Fergus",
    "Ferintosh",
    "Ferme-Neuve",
    "Fermeuse",
    "Fermont",
    "Fernie",
    "Field",
    "Field",
    "Fillmore",
    "Finch",
    "Flat River",
    "Flesherton",
    "Flin Flon",
    "Florenceville-Bristol",
    "Foam Lake",
    "Fogo",
    "Fonthill",
    "Foremost",
    "Forest",
    "Forestburg",
    "Forestville",
    "Fort Albany",
    "Fort Erie",
    "Fort Frances",
    "Fort Good Hope",
    "Fort Hope",
    "Fort Kent",
    "Fort Macleod",
    "Fort McMurray",
    "Fort McPherson",
    "Fort Nelson",
    "Fort Saskatchewan",
    "Fort Severn",
    "Fort Simpson",
    "Fort Smith",
    "Fort St. James",
    "Fort St. John",
    "Fort Vermilion",
    "Fort-Coulonge",
    "Fortierville",
    "Fortune",
    "Fox Creek",
    "Foxtrap",
    "Foymount",
    "Francis",
    "Frankford",
    "Fraser Lake",
    "Fredericton",
    "Fredericton Junction",
    "Frelighsburg",
    "Frobisher",
    "Fruitvale",
    "Gabriola",
    "Gadsby",
    "Galahad",
    "Gambo",
    "Gananoque",
    "Gander",
    "Gander Bay",
    "Ganges",
    "Garden River",
    "Garibaldi",
    "Garibaldi Highlands",
    "Garnish",
    "Garrick",
    "Garson",
    "Gatineau",
    "Gem",
    "Georgetown",
    "Geraldton",
    "Gibsons",
    "Gilbert Plains",
    "Gillam",
    "Gimli",
    "Girouxville",
    "Glace Bay",
    "Gladstone",
    "Glaslyn",
    "Gleichen",
    "Glen Robertson",
    "Glencoe",
    "Glendon",
    "Glentworth",
    "Glenwood",
    "Glenwood",
    "Gloucester",
    "Glovertown",
    "Goderich",
    "Gogama",
    "Gold Bridge",
    "Golden",
    "Golden Lake",
    "Gore",
    "Gore Bay",
    "Granby",
    "Grand Bend",
    "Grand Falls",
    "Grand Falls-Windsor",
    "Grand Forks",
    "Grand Rapids",
    "Grand Valley",
    "Grand-Remous",
    "Grand-Saint-Esprit",
    "Grande Cache",
    "Grande Prairie",
    "Grande-Anse",
    "Grande-Riviere",
    "Grandora",
    "Granton",
    "Granum",
    "Granville Ferry",
    "Grasmere",
    "Gravelbourg",
    "Gravenhurst",
    "Gray Creek",
    "Great Village",
    "Greater Sudbury",
    "Greely",
    "Greenfield Park",
    "Greenspond",
    "Greenwood",
    "Greenwood",
    "Grenfell",
    "Grenville",
    "Gretna",
    "Grimsby",
    "Grimshaw",
    "Grindrod",
    "Griswold",
    "Gronlid",
    "Grono Road",
    "Guelph",
    "Guigues",
    "Gull Lake",
    "Guysborough",
    "Hafford",
    "Hagensborg",
    "Hagersville",
    "Haileybury",
    "Halfmoon Bay",
    "Haliburton",
    "Halifax",
    "Halifax",
    "Ham-Nord",
    "Hamilton",
    "Hamiota",
    "Hampden",
    "Hampton",
    "Hampton",
    "Hanley",
    "Hanna",
    "Hanover",
    "Hantsport",
    "Happy Valley-Goose Bay",
    "Harbour Grace",
    "Harbour Main",
    "Hardisty",
    "Hare Bay",
    "Harriston",
    "Harrow",
    "Hartland",
    "Hartley Bay",
    "Hartney",
    "Harvey",
    "Hastings",
    "Havelock",
    "Havre Boucher",
    "Havre-Aubert",
    "Havre-Saint-Pierre",
    "Hawkesbury",
    "Hawkestone Beach",
    "Hay Lakes",
    "Hay River",
    "Hazelton",
    "Head of Jeddore",
    "Hearst",
    "Hebertville",
    "Hebron",
    "Hemmingford",
    "Henryville",
    "Hensall",
    "Herbert",
    "Heron Bay",
    "Hespeler",
    "High Level",
    "High Prairie",
    "High River",
    "Hillsburgh",
    "Hinton",
    "Hobbema",
    "Holden",
    "Holman",
    "Holyrood",
    "Honeymoon Bay",
    "Hope",
    "Hornepayne",
    "Houston",
    "Howick",
    "Howley",
    "Hubbards",
    "Hudson",
    "Hudson Bay",
    "Hull",
    "Humboldt",
    "Hunter River",
    "Huntingdon",
    "Huntsville",
    "Huron East",
    "Hythe",
    "Ignace",
    "Ilderton",
    "Ile-a-la-Crosse",
    "Indian Head",
    "Ingersoll",
    "Ingleside",
    "Inglewood",
    "Inglis",
    "Ingonish",
    "Innisfail",
    "Innisfil",
    "Inukjuak",
    "Inuvik",
    "Invermere",
    "Inverness",
    "Inwood",
    "Iona",
    "Iqaluit",
    "Irma",
    "Iroquois",
    "Iroquois Falls",
    "Irricana",
    "Islington",
    "Italy Cross",
    "Ituna",
    "Ivujivik",
    "Jacksons",
    "Jasper",
    "Jenner",
    "Jerseyville",
    "Joliette",
    "Jonquiere",
    "Jordan",
    "Jordan Falls",
    "Kagawong",
    "Kamloops",
    "Kamouraska",
    "Kamsack",
    "Kanata",
    "Kangiqsujuaq",
    "Kangirsuk",
    "Kapuskasing",
    "Kars",
    "Kaslo",
    "Kazabazua",
    "Kedgwick",
    "Keene",
    "Keewatin",
    "Kelowna",
    "Kelvington",
    "Kemptville",
    "Kenora",
    "Kensington",
    "Kent Bridge",
    "Kentville",
    "Kerrobert",
    "Kerwood",
    "Keswick",
    "Keswick Beach",
    "Kettleby",
    "Killarney",
    "Kilworthy",
    "Kimberley",
    "Kimmirut",
    "Kincardine",
    "Kindersley",
    "King City",
    "Kingsey Falls",
    "Kingston",
    "Kingston",
    "Kingsville",
    "Kingsville",
    "Kinistino",
    "Kinuso",
    "Kippen",
    "Kirkland",
    "Kirkland Lake",
    "Kirkton",
    "Kisbey",
    "Kispiox",
    "Kitchener",
    "Kitchener",
    "Kitimat",
    "Kitscoty",
    "Kitwanga",
    "Klemtu",
    "Knowlton",
    "Kohler",
    "Komoka",
    "Kugaaruk",
    "Kugluktuk",
    "Kuujjuaq",
    "Kuujjuarapik",
    "La Baie",
    "La Conception",
    "La Corey",
    "La Durantaye",
    "La Guadeloupe",
    "La Loche",
    "La Malbaie",
    "La Morandiere",
    "La Patrie",
    "La Pocatiere",
    "La Prairie",
    "La Presentation",
    "La Ronge",
    "La Salle",
    "La Sarre",
    "La Tabatiere",
    "La Tuque",
    "La Visitation",
    "Labelle",
    "Labrador City",
    "Lac du Bonnet",
    "Lac La Biche",
    "Lac-aux-Sables",
    "Lac-Drolet",
    "Lac-Megantic",
    "Lachenaie",
    "Lachine",
    "Lachute",
    "Lacolle",
    "Lacombe",
    "Ladysmith",
    "Ladysmith",
    "Lafleche",
    "LaHave",
    "Lake Cowichan",
    "Lake Lenore",
    "Lake Louise",
    "Lakeburn",
    "Lakefield",
    "Lambton",
    "Lamont",
    "Lampman",
    "Lanark",
    "Lancaster",
    "Lancaster Park",
    "Landrienne",
    "Lang",
    "Langdon",
    "Langenburg",
    "Langham",
    "Langley",
    "Lanigan",
    "Lanoraie",
    "Lantzville",
    "Lasalle",
    "Lashburn",
    "Laurentia Beach",
    "Laurier-Station",
    "Laval",
    "Lavaltrie",
    "Laverlochere",
    "Lavillette",
    "Lawrencetown",
    "Leader",
    "Leamington",
    "Leduc",
    "Lefroy",
    "Legal",
    "Lemberg",
    "Leonard",
    "Lery",
    "Les Cedres",
    "Les Coteaux",
    "Les Escoumins",
    "Les Mechins",
    "Leslieville",
    "Lethbridge",
    "Lethbridge",
    "Levack",
    "Levis",
    "Lewisporte",
    "Lillooet",
    "Limoges",
    "Lindsay",
    "Lipton",
    "Listowel",
    "Little Current",
    "Little Narrows",
    "Lively",
    "Liverpool",
    "Lloydminster",
    "Logan Lake",
    "London",
    "Londonderry",
    "Long Sault",
    "Longueuil",
    "Lorraine",
    "Lotbiniere",
    "Lougheed",
    "Louisbourg",
    "Louiseville",
    "Lourdes",
    "Lower Island Cove",
    "Lucan",
    "Lucknow",
    "Lucky Lake",
    "Lumby",
    "Lumsden",
    "Lumsden",
    "Lunenburg",
    "Luseland",
    "Luskville",
    "Lyn",
    "Lyster",
    "Lytton",
    "Maberly",
    "Mabou",
    "Macamic",
    "MacGregor",
    "Mackenzie",
    "Macklin",
    "MacTier",
    "Madoc",
    "Magog",
    "Magrath",
    "Mahone Bay",
    "Maidstone",
    "Maidstone",
    "Malagawatch",
    "Malakwa",
    "Malartic",
    "Mallaig",
    "Malton",
    "Mandeville",
    "Manitou",
    "Manitouwadge",
    "Maniwaki",
    "Mankota",
    "Mannville",
    "Manor",
    "Manotick Station",
    "Manseau",
    "Mansonville",
    "Maple",
    "Maple Creek",
    "Maple Ridge",
    "Mar",
    "Marathon",
    "Marbleton",
    "Marchand",
    "Margaree Valley",
    "Margaretsville",
    "Maria",
    "Marieville",
    "Markdale",
    "Markham",
    "Markstay",
    "Marmora",
    "Martinville",
    "Marwayne",
    "Maryfield",
    "Marystown",
    "Marysville",
    "Mascouche",
    "Maskinonge",
    "Masset",
    "Massey",
    "Masson-Angers",
    "Massueville",
    "Matagami",
    "Matane",
    "Matheson",
    "Maxville",
    "Mayne",
    "McBride",
    "McCreary",
    "McGregor",
    "McLennan",
    "McMasterville",
    "Meadow Lake",
    "Meaford",
    "Medicine Hat",
    "Melbourne Ridge",
    "Melfort",
    "Melita",
    "Melocheville",
    "Melville",
    "Memphremagog",
    "Memramcook",
    "Mercier",
    "Merlin",
    "Merrickville",
    "Merritt",
    "Merville",
    "Messines",
    "Metabetchouan-Lac-a-la-Croix",
    "Meteghan",
    "Meteghan River",
    "Metis-sur-Mer",
    "Miami",
    "Midale",
    "Middle Arm",
    "Middle Musquodoboit",
    "Middleton",
    "Midhurst",
    "Midland",
    "Milden",
    "Mildmay",
    "Milford",
    "Milford Station",
    "Milk River",
    "Mill Village",
    "Millarville",
    "Millbrook",
    "Millet",
    "Millville",
    "Milton",
    "Milton",
    "Milverton",
    "Minden",
    "Minnedosa",
    "Minto",
    "Mira Gut",
    "Mirabel",
    "Miramichi",
    "Miscouche",
    "Mission",
    "Mississauga",
    "Mistatim",
    "Mitchell",
    "Moberly Lake",
    "Mobile",
    "Moffat",
    "Moncton",
    "Mont-Joli",
    "Mont-Laurier",
    "Mont-Royal",
    "Mont-Saint-Gregoire",
    "Mont-Saint-Hilaire",
    "Mont-Saint-Michel",
    "Mont-Tremblant",
    "Montague",
    "Montebello",
    "Montmagny",
    "Montmartre",
    "Montreal",
    "Montreal East",
    "Moonbeam",
    "Mooretown",
    "Moose Creek",
    "Moose Jaw",
    "Moose Lake",
    "Moosehorn",
    "Moosomin",
    "Moosonee",
    "Moraviantown",
    "Morden",
    "Morell",
    "Morewood",
    "Morinville",
    "Morrin",
    "Morris",
    "Morrisburg",
    "Mortlach",
    "Mount Brydges",
    "Mount Elgin",
    "Mount Forest",
    "Mount Pearl",
    "Mount Stewart",
    "Mount Uniacke",
    "Mountain View",
    "Muirkirk",
    "Mulgrave",
    "Mundare",
    "Munson",
    "Munster",
    "Murdochville",
    "Murray Harbour",
    "Murray River",
    "Musquodoboit Harbour",
    "Myrnam",
    "Nackawic",
    "Naicam",
    "Nakina",
    "Nakusp",
    "Nampa",
    "Namur",
    "Nanaimo",
    "Nanoose Bay",
    "Nantes",
    "Nanton",
    "Napanee",
    "Napanee",
    "Napierville",
    "Nauwigewauk",
    "Neepawa",
    "Neguac",
    "Neilburg",
    "Nelson",
    "Nepean",
    "Neudorf",
    "Neuville",
    "New Aiyansh",
    "New Carlisle",
    "New Denver",
    "New Dundee",
    "New Glasgow",
    "New Hamburg",
    "New Harbour",
    "New Liskeard",
    "New Ross",
    "New Waterford",
    "New Westminster",
    "New-Richmond",
    "New-Wes-Valley",
    "Newburgh",
    "Newcastle Creek",
    "Newcastle Village",
    "Newdale",
    "Newmarket",
    "Newport",
    "Newton",
    "Newtown",
    "Niagara",
    "Niagara Falls",
    "Niagara-on-the-Lake",
    "Nicolet",
    "Nipawin",
    "Nipigon",
    "Nisku",
    "Niverville",
    "Nobleford",
    "Nobleton",
    "Nokomis",
    "Nominingue",
    "Norfolk County",
    "Norman Wells",
    "Normandin",
    "Norquay",
    "North Battleford",
    "North Bay",
    "North Delta",
    "North Hatley",
    "North Lunenburg",
    "North Perth",
    "North Portal",
    "North Russell",
    "North Rustico",
    "North Saanich",
    "North Star",
    "North Sydney",
    "North Vancouver",
    "North York",
    "Norval",
    "Norway House",
    "Norwich",
    "Norwood",
    "Notre-Dame-de-Lourdes",
    "Notre-Dame-du-Bon-Conseil",
    "Notre-Dame-du-Mont-Carmel",
    "Notre-Dame-du-Nord",
    "Notre-Dame-du-Portage",
    "Nouvelle",
    "Noyan",
    "Oak Lake",
    "Oak Point",
    "Oakville",
    "Obedjiwan",
    "Odessa",
    "Ogoki",
    "Ohsweken",
    "Oil Springs",
    "Oka",
    "Okanagan Centre",
    "Okotoks",
    "Old Perlican",
    "Oldcastle",
    "Olds",
    "Oliver",
    "Omemee",
    "Omerville",
    "Onanole",
    "Onion Lake",
    "Onoway",
    "Orangeville",
    "Orillia",
    "Orleans",
    "Ormstown",
    "Oromocto",
    "Orono",
    "Orton",
    "Oshawa",
    "Osoyoos",
    "Ottawa",
    "Otter Lake",
    "Otterburn Park",
    "Otterburne",
    "Outlook",
    "Owen Sound",
    "Oxbow",
    "Oxdrift",
    "Oxford",
    "Oxford House",
    "Oyama",
    "Pain Court",
    "Paisley",
    "Pakenham",
    "Palmarolle",
    "Palmerston",
    "Papineauville",
    "Paradise Cove",
    "Paradise Valley",
    "Parham",
    "Paris",
    "Parisville",
    "Parkhill",
    "Parksville",
    "Parry Sound",
    "Parson",
    "Pasadena",
    "Pascal",
    "Paspebiac",
    "Pass Lake",
    "Paulatuk",
    "Peace River",
    "Peachland",
    "Pefferlaw",
    "Pelly",
    "Pemberton",
    "Pembroke",
    "Penetanguishene",
    "Penhold",
    "Pennfield",
    "Penobsquis",
    "Penticton",
    "Penzance",
    "Perth",
    "Perth",
    "Perth-Andover",
    "Petawawa",
    "Peterborough",
    "Petherton",
    "Petit-de-Grat",
    "Petitcodiac",
    "Petite-Riviere-Saint-Francois",
    "Petrolia",
    "Pickering",
    "Picton",
    "Pictou",
    "Pierreville",
    "Pierson",
    "Pikangikum",
    "Pilot Butte",
    "Pilot Mound",
    "Pinantan Lake",
    "Pinawa",
    "Pincher Creek",
    "Pincourt",
    "Pine Falls",
    "Piney",
    "Pitt Meadows",
    "Placentia",
    "Plamondon",
    "Plantagenet Station",
    "Plaster Rock",
    "Plessisville",
    "Plum Coulee",
    "Plumas",
    "Plympton",
    "Point Edward",
    "Pointe-a-la-Croix",
    "Pointe-aux-Trembles",
    "Pointe-Calumet",
    "Pointe-Claire",
    "Pointe-Fortune",
    "Pointe-Lebel",
    "Pointe-Verte",
    "Ponoka",
    "Pont-Rouge",
    "Pont-Viau",
    "Pontypool",
    "Poplar Hill",
    "Port Alberni",
    "Port Alice",
    "Port au Choix",
    "Port au Port",
    "Port Burwell",
    "Port Clements",
    "Port Colborne",
    "Port Coquitlam",
    "Port Credit",
    "Port Dover",
    "Port Edward",
    "Port Elgin",
    "Port Elgin",
    "Port Hardy",
    "Port Hawkesbury",
    "Port Hope",
    "Port Lorne",
    "Port McNeill",
    "Port McNicoll",
    "Port Medway",
    "Port Moody",
    "Port Morien",
    "Port Perry",
    "Port Renfrew",
    "Port Royal",
    "Port Saunders",
    "Port Stanley",
    "Port-Cartier",
    "Port-Daniel",
    "Portage",
    "Portage la Prairie",
    "Portage-du-Fort",
    "Porters Lake",
    "Portneuf",
    "Portugal Cove",
    "Potter",
    "Pouce Coupe",
    "Pouch Cove",
    "Powassan",
    "Powell River",
    "Preeceville",
    "Prescott",
    "Price",
    "Priceville",
    "Prince",
    "Prince Albert",
    "Prince George",
    "Prince Rupert",
    "Princeton",
    "Princeville",
    "Pritchard",
    "Prospect",
    "Provost",
    "Pugwash",
    "Pukatawagan",
    "Puslinch",
    "Puvirnituq",
    "Pynns",
    "Qualicum Beach",
    "Quaqtaq",
    "Quathiaski Cove",
    "Quebec",
    "Queen Charlotte",
    "Quesnel",
    "Quill Lake",
    "Quispamsis",
    "Quyon",
    "Racine",
    "Radisson",
    "Radville",
    "Rainbow Lake",
    "Raleigh",
    "Ramea",
    "Rankin Inlet",
    "Rapid City",
    "Rawdon",
    "Raymond",
    "Raymore",
    "Red Deer",
    "Red Lake",
    "Red Rock",
    "Redcliff",
    "Redvers",
    "Redwater",
    "Regina",
    "Rencontre East",
    "Renfrew",
    "Renous",
    "Repentigny",
    "Revelstoke",
    "Rexdale",
    "Rhein",
    "Richibucto",
    "Richmond",
    "Richmond",
    "Richmond",
    "Richmond Hill",
    "Ridgetown",
    "Ridgeway",
    "Rigaud",
    "Rimbey",
    "Rimouski",
    "Ripley",
    "Ripon",
    "Ripples",
    "River Hebert",
    "River John",
    "Rivers",
    "Riverside-Albert",
    "Riverton",
    "Riverview",
    "Riviere-Beaudette",
    "Riviere-Bleue",
    "Riviere-Ouelle",
    "Riviere-Rouge",
    "Riviere-Saint-Paul",
    "Roberts Creek",
    "Roberval",
    "Roblin",
    "Roblin",
    "Rocanville",
    "Rockland",
    "Rockville",
    "Rockwood",
    "Rocky Mountain House",
    "Roddickton",
    "Rogersville",
    "Roland",
    "Rosalind",
    "Rosedale",
    "Rosemary",
    "Rosemere",
    "Roseneath",
    "Rosetown",
    "Rossburn",
    "Rossland",
    "Rossport",
    "Rosthern",
    "Rothesay",
    "Rougemont",
    "Rouleau",
    "Rouyn-Noranda",
    "Roxboro",
    "Roxton Falls",
    "Roxton Pond",
    "Rumsey",
    "Russell",
    "Rycroft",
    "Saanichton",
    "Sable River",
    "Sabrevois",
    "Sackville",
    "Saguenay",
    "Saint Andrews",
    "Saint Etienne-De-Lauzon",
    "Saint John",
    "Saint Romuald",
    "Saint-Adalbert",
    "Saint-Adelphe",
    "Saint-Agapit",
    "Saint-Aime-du-Lac-des-Iles",
    "Saint-Alban",
    "Saint-Alexis-des-Monts",
    "Saint-Ambroise",
    "Saint-Anaclet-de-Lessard",
    "Saint-Andre",
    "Saint-Andre-Avellin",
    "Saint-Anicet",
    "Saint-Anselme",
    "Saint-Antoine-de-Tilly",
    "Saint-Antoine-sur-Richelieu",
    "Saint-Antonin",
    "Saint-Apollinaire",
    "Saint-Arsene",
    "Saint-Aubert",
    "Saint-Augustin",
    "Saint-Augustin-de-Desmaures",
    "Saint-Barnabe-Sud",
    "Saint-Barthelemy",
    "Saint-Basile",
    "Saint-Basile-le-Grand",
    "Saint-Benjamin",
    "Saint-Bruno",
    "Saint-Bruno-de-Montarville",
    "Saint-Camille",
    "Saint-Camille",
    "Saint-Casimir",
    "Saint-Cesaire",
    "Saint-Charles-de-Bellechasse",
    "Saint-Charles-sur-Richelieu",
    "Saint-Clement",
    "Saint-Colomban",
    "Saint-Come",
    "Saint-Come--Liniere",
    "Saint-Cyprien",
    "Saint-Cyrille-de-Wendover",
    "Saint-Damase",
    "Saint-Denis",
    "Saint-Denis",
    "Saint-Dominique",
    "Saint-Donat",
    "Saint-Elie",
    "Saint-Elzear",
    "Saint-Etienne-des-Gres",
    "Saint-Eusebe",
    "Saint-Eustache",
    "Saint-Fabien",
    "Saint-Fabien-de-Panet",
    "Saint-Faustin--Lac-Carre",
    "Saint-Felicien",
    "Saint-Felix-de-Kingsey",
    "Saint-Felix-de-Valois",
    "Saint-Ferdinand",
    "Saint-Ferreol-les-Neiges",
    "Saint-Flavien",
    "Saint-Francois-du-Lac",
    "Saint-Frederic",
    "Saint-Gabriel",
    "Saint-Gabriel-de-Valcartier",
    "Saint-Gedeon",
    "Saint-Georges",
    "Saint-Georges-de-Windsor",
    "Saint-Germain-de-Grantham",
    "Saint-Gilles",
    "Saint-Godefroi",
    "Saint-Guillaume",
    "Saint-Henri",
    "Saint-Hilaire",
    "Saint-Honore-de-Temiscouata",
    "Saint-Hubert-de-Riviere-du-Loup",
    "Saint-Hugues",
    "Saint-Hyacinthe",
    "Saint-Irenee",
    "Saint-Isidore",
    "Saint-Jacques",
    "Saint-Jean-de-Dieu",
    "Saint-Jean-de-Matha",
    "Saint-Jean-Port-Joli",
    "Saint-Jean-sur-Richelieu",
    "Saint-Jerome",
    "Saint-Joachim",
    "Saint-Joachim-de-Shefford",
    "Saint-Joseph-de-Beauce",
    "Saint-Jude",
    "Saint-Lambert",
    "Saint-Lambert",
    "Saint-Lazare-de-Bellechasse",
    "Saint-Leon-de-Standon",
    "Saint-Leonard",
    "Saint-Liboire",
    "Saint-Lin",
    "Saint-Lin-Laurentides",
    "Saint-Louis de Kent",
    "Saint-Louis-de-Gonzague",
    "Saint-Luc",
    "Saint-Ludger",
    "Saint-Malachie",
    "Saint-Marc-de-Figuery",
    "Saint-Marc-des-Carrieres",
    "Saint-Marc-sur-Richelieu",
    "Saint-Mathieu",
    "Saint-Maurice",
    "Saint-Michel",
    "Saint-Michel-de-Bellechasse",
    "Saint-Michel-des-Saints",
    "Saint-Nazaire",
    "Saint-Odilon-de-Cranbourne",
    "Saint-Pacome",
    "Saint-Pamphile",
    "Saint-Pascal",
    "Saint-Paul",
    "Saint-Paul",
    "Saint-Paul-de-la-Croix",
    "Saint-Paulin",
    "Saint-Philemon",
    "Saint-Philippe",
    "Saint-Philippe-de-Neri",
    "Saint-Pie",
    "Saint-Pierre",
    "Saint-Pierre-de-Broughton",
    "Saint-Pierre-les-Becquets",
    "Saint-Placide",
    "Saint-Polycarpe",
    "Saint-Prosper",
    "Saint-Quentin",
    "Saint-Raphael",
    "Saint-Raymond",
    "Saint-Remi",
    "Saint-Roch",
    "Saint-Roch-de-Richelieu",
    "Saint-Roch-des-Aulnaies",
    "Saint-Sauveur",
    "Saint-Sebastien",
    "Saint-Severe",
    "Saint-Simon-de-Rimouski",
    "Saint-Stanislas",
    "Saint-Stanislas-de-Kostka",
    "Saint-Sylvere",
    "Saint-Sylvestre",
    "Saint-Telesphore",
    "Saint-Thomas",
    "Saint-Tite",
    "Saint-Ubalde",
    "Saint-Ulric",
    "Saint-Urbain",
    "Saint-Urbain-Premier",
    "Saint-Vallier",
    "Saint-Venant-de-Paquette",
    "Saint-Victor",
    "Saint-Wenceslas",
    "Saint-Zacharie",
    "Saint-Zenon",
    "Saint-Zotique",
    "Sainte-Adele",
    "Sainte-Agathe-des-Monts",
    "Sainte-Angele-de-Premont",
    "Sainte-Anne-de-Beaupre",
    "Sainte-Anne-de-Bellevue",
    "Sainte-Anne-de-la-Perade",
    "Sainte-Anne-des-Monts",
    "Sainte-Anne-du-Lac",
    "Sainte-Brigitte-des-Saults",
    "Sainte-Catherine",
    "Sainte-Cecile-de-Masham",
    "Sainte-Cecile-de-Whitton",
    "Sainte-Claire",
    "Sainte-Clotilde-de-Horton",
    "Sainte-Croix",
    "Sainte-Famille",
    "Sainte-Foy",
    "Sainte-Gertrude",
    "Sainte-Helene-de-Bagot",
    "Sainte-Helene-de-Breakeyville",
    "Sainte-Julie",
    "Sainte-Julienne",
    "Sainte-Justine",
    "Sainte-Marcelline-de-Kildare",
    "Sainte-Marguerite",
    "Sainte-Marie",
    "Sainte-Marthe",
    "Sainte-Marthe-sur-le-Lac",
    "Sainte-Martine",
    "Sainte-Melanie",
    "Sainte-Monique",
    "Sainte-Perpetue",
    "Sainte-Thecle",
    "Sainte-Therese",
    "Salaberry-de-Valleyfield",
    "Salisbury",
    "Salmo",
    "Salmon Arm",
    "Salmon River",
    "Sandy Bay",
    "Sandy Lake",
    "Sanikiluaq",
    "Sardis",
    "Sarnia",
    "Saskatoon",
    "Saulnierville",
    "Sault Ste. Marie",
    "Savona",
    "Sawyerville",
    "Sayabec",
    "Sayward",
    "Scarborough",
    "Schefferville",
    "Schomberg",
    "Schreiber",
    "Scotland",
    "Scotsburn",
    "Scotstown",
    "Scott",
    "Scoudouc",
    "Seaforth",
    "Searchmont",
    "Sechelt",
    "Seldom-Little Seldom",
    "Selkirk",
    "Senneterre",
    "Senneville",
    "Sept-Iles",
    "Seton Portage",
    "Severn Bridge",
    "Sexsmith",
    "Shamattawa",
    "Shanklin",
    "Shannon",
    "Shannonville",
    "Sharbot Lake",
    "Shaunavon",
    "Shawinigan",
    "Shawinigan-Sud",
    "Shawnigan Lake",
    "Shawville",
    "Shediac",
    "Sheho",
    "Shelburne",
    "Shelburne",
    "Shellbrook",
    "Sherbrooke",
    "Sherbrooke",
    "Sherrington",
    "Sherwood Park",
    "Shigawake",
    "Shoal Lake",
    "Shubenacadie",
    "Sicamous",
    "Sidney",
    "Sillery",
    "Silton",
    "Simcoe",
    "Sioux Lookout",
    "Skidegate",
    "Slave Lake",
    "Slocan",
    "Smithers",
    "Smiths Falls",
    "Smithville",
    "Smoky Lake",
    "Smooth Rock Falls",
    "Snow Lake",
    "Sointula",
    "Sombra",
    "Sooke",
    "Sorel",
    "Sorel-Tracy",
    "Sorrento",
    "Souris",
    "Souris",
    "South Augusta",
    "South Gower",
    "South Indian Lake",
    "South Lancaster",
    "South Mountain",
    "South Porcupine",
    "South River",
    "South Slocan",
    "Southend Reindeer",
    "Southey",
    "Spanish",
    "Sparta",
    "Spencerville",
    "Sperling",
    "Spillimacheen",
    "Spirit River",
    "Spiritwood",
    "Spring Hill",
    "Springdale",
    "Springfield",
    "Springhill",
    "Spruce Grove",
    "Spy Hill",
    "Squamish",
    "St-Hubert",
    "St-Lazare",
    "St-Timothee",
    "St. Albert",
    "St. Andrews",
    "St. Anthony",
    "St. Brieux",
    "St. Catharines",
    "St. Jacobs",
    "St. Louis",
    "St. Marys",
    "St. Paul",
    "St. Peters Bay",
    "St. Stephen",
    "St. Thomas",
    "St. Walburg",
    "Stanbridge East",
    "Standard",
    "Stanley Bridge, Hope River, Bayview, Cavendish and North Rustico",
    "Star City",
    "Stayner",
    "Steinbach",
    "Stella",
    "Stellarton",
    "Stephenville",
    "Stephenville Crossing",
    "Stettler",
    "Stevensville",
    "Stewiacke",
    "Stirling",
    "Stirling",
    "Stittsville",
    "Stoneham",
    "Stonewall",
    "Stoney Creek",
    "Stony Mountain",
    "Stony Plain",
    "Stouffville",
    "Stoughton",
    "Strasbourg",
    "Stratford",
    "Stratford",
    "Strathmore",
    "Strathroy",
    "Streetsville",
    "Sturgeon Falls",
    "Sturgis",
    "Summerland",
    "Summerside",
    "Sunderland",
    "Sundridge",
    "Surrey",
    "Sussex",
    "Sutton",
    "Sutton",
    "Swan River",
    "Swastika",
    "Swift Current",
    "Sydenham",
    "Sydney",
    "Sydney",
    "Sydney Mines",
    "Sylvan Lake",
    "Taber",
    "Tadoussac",
    "Tahsis",
    "Takla Landing",
    "Tappen",
    "Taschereau",
    "Tasiujaq",
    "Tatamagouche",
    "Tavistock",
    "Taylor",
    "Tecumseh",
    "Tecumseh",
    "Teeswater",
    "Temiscaming",
    "Tennion",
    "Terrace",
    "Terrace Bay",
    "Terre Noire",
    "Terrebonne",
    "Teulon",
    "Thamesford",
    "Thamesville",
    "The Pas",
    "Thedford",
    "Theodore",
    "Theresa",
    "Thetford-Mines",
    "Thompson",
    "Thorhild",
    "Thornbury",
    "Thorndale",
    "Thornhill",
    "Thorold",
    "Three Hills",
    "Three Mile Plains",
    "Thunder Bay",
    "Thurso",
    "Tilbury",
    "Tilley",
    "Tillsonburg",
    "Timberlea",
    "Timmins",
    "Tisdale",
    "Tlell",
    "Tofield",
    "Tofino",
    "Tompkins",
    "Tompkins",
    "Torbay",
    "Toronto",
    "Torrington",
    "Tottenham",
    "Tracadie",
    "Trail",
    "Treherne",
    "Trenton",
    "Trenton",
    "Tring-Jonction",
    "Trochu",
    "Trois Pistoles",
    "Trout Creek",
    "Trout River",
    "Troy",
    "Truro",
    "Tsawwassen",
    "Tuktoyaktuk",
    "Tumbler Ridge",
    "Turner Valley",
    "Turtleford",
    "Tusket",
    "Tweed",
    "Twillingate",
    "Two Hills",
    "Tyndall",
    "Ucluelet",
    "Union Bay",
    "Unionville",
    "Unity",
    "Upper Island Cove",
    "Upton",
    "Utterson",
    "Uxbridge",
    "Val-Brillant",
    "Val-David",
    "Val-des-Monts",
    "Val-Morin",
    "Valcourt",
    "Valemount",
    "Vallee-Jonction",
    "Valley",
    "Valleyview",
    "Van Anda",
    "Vancouver",
    "Vanderhoof",
    "Vankleek Hill",
    "Varennes",
    "Vaudreuil-Dorion",
    "Vaughan",
    "Vauxhall",
    "Vegreville",
    "Vercheres",
    "Verdun",
    "Vermilion",
    "Verner",
    "Vernon",
    "Vernon Bridge",
    "Victoria",
    "Victoria",
    "Victoria Cove",
    "Victoria Harbour",
    "Victoriaville",
    "Vieux-Saint-Laurent",
    "Viking",
    "Villa Marie",
    "Ville-Marie",
    "Vineland Station",
    "Virden",
    "Viscount",
    "Vonda",
    "Vulcan",
    "Wabana",
    "Wabigoon",
    "Wabush",
    "Wadena",
    "Wainfleet",
    "Wainwright",
    "Wakaw",
    "Wakefield",
    "Waldeck",
    "Waldheim",
    "Walkerton",
    "Wallace",
    "Wallaceburg",
    "Warkworth",
    "Warman",
    "Warner",
    "Warsaw",
    "Warwick",
    "Wasaga Beach",
    "Washago",
    "Waterdown",
    "Waterford",
    "Waterloo",
    "Waterloo",
    "Waterville",
    "Watford",
    "Watrous",
    "Watson",
    "Watson Lake",
    "Waubaushene",
    "Wawa",
    "Waweig",
    "Wawota",
    "Weedon",
    "Weir",
    "Weldon",
    "Welland",
    "Wellesley",
    "Wellington",
    "Wellington",
    "Wembley",
    "Wemindji",
    "West Bay",
    "West Chezzetcook",
    "West Hill",
    "West Lake",
    "West Osgoode",
    "Westbank",
    "Western Bay",
    "Westlock",
    "Westmount",
    "Weston",
    "Westport",
    "Westree",
    "Westville",
    "Wetaskiwin",
    "Weyburn",
    "Wheatley",
    "Whistler Village",
    "Whitby",
    "White City",
    "White Fox",
    "White River",
    "White Rock",
    "Whitecourt",
    "Whitehorse",
    "Whitemouth",
    "Whites Lake",
    "Whitney",
    "Whycocomagh",
    "Wiarton",
    "Wickham",
    "Wilcox",
    "Wildwood",
    "Wilkie",
    "Williams Lake",
    "Williamsburg",
    "Willingdon",
    "Willow Bunch",
    "Willowdale",
    "Willowvale",
    "Winchester",
    "Windermere",
    "Windham Centre",
    "Windsor",
    "Windsor",
    "Windsor",
    "Winfield",
    "Winfield",
    "Wingham",
    "Winkler",
    "Winnipeg",
    "Winnipegosis",
    "Wiseton",
    "Wishart",
    "Witless Bay",
    "Wittenburg",
    "Wolfville",
    "Wolseley",
    "Wood Mountain",
    "Woodbridge",
    "Woodlands",
    "Woodridge",
    "Woodstock",
    "Woodstock",
    "Worsley",
    "Wrentham",
    "Wright-Gracefield-Northfield",
    "Wymark",
    "Wynyard",
    "Wyoming",
    "Yahk",
    "Yamachiche",
    "Yamaska",
    "Yarker",
    "Yarmouth",
    "Yellow Grass",
    "Yellowknife",
    "York",
    "Yorkton",
    "Youbou",
    "Young",
    "Youngstown"],
  },
  {
    name: "Central African Republic",
    cities: ["Bangui", "Bimbo", "Berberati"],
  },
  {
    name: "Chad",
    cities: ["N'Djamena", "Moundou", "Sarh"],
  },
  {
    name: "Chile",
    cities: [  "Algarrobo",
    "Angol",
    "Antofagasta",
    "Apoquindo",
    "Arauco",
    "Arica",
    "Buin",
    "Bulnes",
    "Calama",
    "Caldera",
    "Castro",
    "Catemu",
    "Centro",
    "Cerrillos de Tamaya",
    "Chicureo Abajo",
    "Chiguayante",
    "Chillan",
    "Chillan Viejo",
    "Chimbarongo",
    "Chonchi",
    "Chuquicamata",
    "Coihaique",
    "Colina",
    "Collipulli",
    "Concon",
    "Constitucion",
    "Coquimbo",
    "Coronel",
    "Cunco",
    "Curacavi",
    "Dalcahue",
    "El Arco",
    "El Bosque",
    "El Monte",
    "El Quisco",
    "El Salto",
    "El Salvador",
    "El Valle",
    "Fresia",
    "Galvarino",
    "Graneros",
    "Hanga Roa",
    "Iquique",
    "La Calera",
    "La Cisterna",
    "La Granja",
    "La Laja",
    "La Ligua",
    "La Reina",
    "La Serena",
    "La Union",
    "Lampa",
    "Las Condes",
    "Lautaro",
    "Llanquihue",
    "Lo Barnechea",
    "Lolol",
    "Los Andes",
    "Los Angeles",
    "Machali",
    "Macul",
    "Maipu",
    "Maquina Atacama",
    "Maule",
    "Mejillones",
    "Melipilla",
    "Miraflores",
    "Molina",
    "Monte Aguila",
    "Nacimiento",
    "Nogales",
    "Nunoa",
    "Olmue",
    "Osorno",
    "Ovalle",
    "Paillaco",
    "Penablanca",
    "Penaflor",
    "Port Montt",
    "Providencia",
    "Puchuncavi",
    "Pucon",
    "Puente Alto",
    "Puerto Aisen",
    "Puerto Natales",
    "Puerto Varas",
    "Punta Arenas",
    "Puren",
    "Purranque",
    "Quellon",
    "Quemchi",
    "Quilicura",
    "Quillota",
    "Rancagua",
    "Recoleta",
    "Renaca",
    "Renca",
    "Rengo",
    "Rinconada",
    "Rio Bueno",
    "San Antonio",
    "San Bernardo",
    "San Carlos",
    "San Felipe",
    "San Fernando",
    "San Javier",
    "San Martin",
    "San Pedro",
    "Santa Barbara",
    "Santiago",
    "Talagante",
    "Talca",
    "Talcahuano",
    "Tarapaca",
    "Temuco",
    "Tome",
    "Valdivia",
    "Vallenar",
    "Villa Alemana",
    "Villarrica",
    "Vitacura"
  ],
  },
  {
    name: "China",
    cities: ["Aishang",
    "Aizhou",
    "Aksu",
    "Anbang",
    "Anbu",
    "Ancheng",
    "Anda",
    "Andong",
    "Angu",
    "Anguo",
    "Anhu",
    "Anji",
    "Anjiang",
    "Ankang",
    "Anke",
    "Anqing",
    "Anqiu",
    "Anshan",
    "Anshun",
    "Ansu",
    "Anxi",
    "Anyang",
    "Anying",
    "Anzhou",
    "Anzi",
    "Aojiang",
    "Aoma",
    "Bagong",
    "Baicheng",
    "Baidu",
    "Baihua",
    "Baile",
    "Bailicun",
    "Baipo",
    "Baise City",
    "Baisha",
    "Baishan",
    "Baishi",
    "Baitang",
    "Baixiang",
    "Baixin",
    "Baiyashi",
    "Baiyi",
    "Baiyin",
    "Baiyu",
    "Baiyun",
    "Baizhang",
    "Baliqiao",
    "Bamao",
    "Bangke",
    "Bangtou",
    "Bantou",
    "Banxi",
    "Banyou",
    "Baocheng",
    "Baodi",
    "Baodian",
    "Baoding",
    "Baojiang",
    "Baoluo",
    "Baoqing",
    "Baoshan",
    "Baotao",
    "Baotian",
    "Baotou",
    "Baotou",
    "Baoxing",
    "Baoyu",
    "Base",
    "Baying",
    "Bazhou",
    "Baziqiang",
    "Beichan",
    "Beidou",
    "Beihai",
    "Beijie",
    "Beijing",
    "Beilin",
    "Beilun",
    "Beishan",
    "Beixi",
    "Beixiao",
    "Beiying",
    "Beiyuan",
    "Beiyuan",
    "Bengbu",
    "Benxi",
    "Bianzhuang",
    "Bibo",
    "Bijie",
    "Binhe",
    "Binjiang",
    "Binzhou",
    "Biyang",
    "Bobai",
    "Bohai",
    "Boji",
    "Bole",
    "Bole",
    "Boli",
    "Boshan",
    "Boxing",
    "Bozhou",
    "Buxin",
    "Caijing",
    "Caishen",
    "Caiyuan",
    "Caiyuan",
    "Cangnan",
    "Cangshang",
    "Cangzhou",
    "Caopu",
    "Caoxi",
    "Caozhai",
    "Cengang",
    "Cenyang",
    "Cezi",
    "Chadianzi",
    "Chaicun",
    "Chaigoubu",
    "Chamdo",
    "Changchao",
    "Changchun",
    "Changde",
    "Changfeng",
    "Changhe",
    "Changjian",
    "Changjiang",
    "Changle",
    "Changle",
    "Changning",
    "Changping",
    "Changqing",
    "Changsha",
    "Changshan",
    "Changsheng",
    "Changsheng",
    "Changshu City",
    "Changting",
    "Changtu",
    "Changxiacun",
    "Changxin",
    "Changzheng",
    "Changzhi",
    "Changzhong",
    "Changzhou",
    "Chaohu",
    "Chaoyang",
    "Chaoyang",
    "Chaozhou",
    "Chen Xian",
    "Chendu",
    "Chengbei",
    "Chengdong",
    "Chengdong",
    "Chengdu",
    "Chengfeng",
    "Chengguan",
    "Chenghua",
    "Chengjian",
    "Chengmai",
    "Chengnan",
    "Chengqiao",
    "Chenguang",
    "Chengxi",
    "Chengxian Chengguanzhen",
    "Chengxiang",
    "Chengxiang",
    "Chengyang",
    "Chengzhong",
    "Chenyang",
    "Chenzhou",
    "Chifeng",
    "Chiping",
    "Chizhou",
    "Chongqing",
    "Chongwen",
    "Chongxian",
    "Choucheng",
    "Chuansha",
    "Chum",
    "Chunhua",
    "Chunjiang",
    "Chuxiong",
    "Chuzhou",
    "Cicheng",
    "Cili",
    "Citai",
    "Cixi",
    "Cuibei",
    "Cuiyuan",
    "Cuizhu",
    "Cuntou",
    "Dabaizhuang",
    "Dabao",
    "Dabu",
    "Dachen",
    "Dachuan",
    "Dagang",
    "Dahai",
    "Dahuaishu",
    "Daidong",
    "Daishan",
    "Daixi",
    "Daizhong",
    "Daji",
    "Dajie",
    "Daju",
    "Dali",
    "Dalian",
    "Dampu",
    "Dancheng",
    "Dandong",
    "Danfeng",
    "Dangshan",
    "Dangyang",
    "Danjiang",
    "Danjiangkou",
    "Danyang",
    "Danzao",
    "Danzhou",
    "Daojiang",
    "Daotou",
    "Daoxu",
    "Daqiao",
    "Daqing",
    "Daqing",
    "Daqiuzhuang",
    "Dasha",
    "Dashiqiao",
    "Dashun",
    "Datang",
    "Datong",
    "Dawa",
    "Dawang",
    "Dawukou",
    "Daxie",
    "Daxing",
    "Daxing",
    "Dayan",
    "Dayang",
    "Daye",
    "Dayun",
    "Dazhong",
    "Dazhou",
    "Dazu",
    "Defeng",
    "Dehui",
    "Dengbu",
    "Dengzhou",
    "Deqing",
    "Deyang",
    "Deyuan",
    "Deyue",
    "Dezhou",
    "Diankou",
    "Dianzhong",
    "Dianzi",
    "Digang",
    "Dingcheng",
    "Dinghai",
    "Dingqiao",
    "Dingtao",
    "Dingxi",
    "Dingzha",
    "Dingzhou",
    "Dipu",
    "Dongchang",
    "Dongcheng",
    "Dongcheng",
    "Dongcun",
    "Dongdu",
    "Dongfang",
    "Dongfeng",
    "Dongguan",
    "Donghai",
    "Donghe",
    "Donghong",
    "Donghuxu",
    "Dongjiaying",
    "Dongli",
    "Donglin",
    "Dongliu",
    "Dongning",
    "Dongshen",
    "Dongsheng",
    "Dongshi",
    "Dongtou",
    "Dongxi",
    "Dongxiao",
    "Dongxin",
    "Dongxing",
    "Dongyang",
    "Dongying",
    "Doujiang",
    "Doumen",
    "Dujiacun",
    "Dungdo",
    "Duoba",
    "Dushu",
    "Duwei",
    "Duyun",
    "Edong",
    "Ejia",
    "Enshi",
    "Erqi",
    "Eshan",
    "Ezhou",
    "Fangqiao",
    "Fangshan",
    "Fangxian Chengguanzhen",
    "Fangzhuang",
    "Fangzi",
    "Fanjing",
    "Fanzhong",
    "Feihong",
    "Feilong",
    "Feixian",
    "Feiyun",
    "Feiyun",
    "Fendou",
    "Fendou",
    "Fengcheng",
    "Fengcheng",
    "Fenggang",
    "Fengjiang",
    "Fenglin",
    "Fengling",
    "Fengming",
    "Fengnan",
    "Fengqi",
    "Fengqiao",
    "Fengqing",
    "Fengshan",
    "Fengshang",
    "Fengshun",
    "Fengtai",
    "Fengting",
    "Fengxian",
    "Fengxiang",
    "Fengzhou",
    "Fenshang",
    "Fenyang",
    "Foshan",
    "Fotang",
    "Fuchun",
    "Fuchunjiang",
    "Fudao",
    "Fuding",
    "Fugu",
    "Fuhao",
    "Fuling",
    "Fumin",
    "Fuqiang",
    "Furong",
    "Fusheng",
    "Fushun",
    "Futian",
    "Fuxin",
    "Fuyang",
    "Fuyang",
    "Fuzhou",
    "Fuzhou",
    "Gaiwei",
    "Gaiyu",
    "Gamlung",
    "Gangcun",
    "Ganjiang",
    "Gannan",
    "Ganyao",
    "Ganzhou",
    "Gaobeidian",
    "Gaobu",
    "Gaocheng",
    "Gaoji",
    "Gaoping",
    "Gaoqiao",
    "Gaotang",
    "Gaoting",
    "Gaoxin",
    "Gaozhao",
    "Gaozhou",
    "Gejiu",
    "Gongchang",
    "Gongjiang",
    "Gongjiao",
    "Gongli",
    "Gongyi",
    "Gongyuan",
    "Goushan",
    "Gouzhuang",
    "Guali",
    "Guancheng",
    "Guangchang",
    "Guangdong",
    "Guangming",
    "Guangtai",
    "Guangtong",
    "Guangxi",
    "Guangyuan",
    "Guangzhou",
    "Guanli",
    "Guanqiao",
    "Guantian",
    "Gucheng",
    "Gucheng",
    "Guigang",
    "Guigang",
    "Guili",
    "Guilin",
    "Guixi",
    "Guiyang",
    "Guiyuan",
    "Guizhoumanzuxiang",
    "Gujiang",
    "Gujiao",
    "Guli",
    "Gunan",
    "Guodian",
    "Guohe",
    "Guoji",
    "Guoshang",
    "Guozhen",
    "Gushan",
    "Gushi",
    "Guxiang",
    "Guyong",
    "Guyuan",
    "Gyari",
    "Haibei",
    "Haicheng",
    "Haidian",
    "Haifu",
    "Haihong",
    "Haijing",
    "Haikou",
    "Hailar",
    "Hailin",
    "Hainan",
    "Haitang",
    "Haixing",
    "Haiyang",
    "Haiyu",
    "Haizhou",
    "Handan",
    "Hangbu",
    "Hangong",
    "Hangu",
    "Hangzhou",
    "Hanjiang",
    "Hanshan",
    "Hanshang",
    "Hanting",
    "Hanzhong",
    "Haocheng",
    "Haozhuang",
    "Harbin",
    "Hebei",
    "Hebei",
    "Hebi",
    "Hecheng",
    "Hechi",
    "Hechi",
    "Hechun",
    "Hecun",
    "Hedong",
    "Hefei",
    "Hegang",
    "Heibei",
    "Heihe",
    "Hejiang",
    "Hejin",
    "Hekou",
    "Heliang",
    "Hengdian",
    "Henghe",
    "Hengjie",
    "Hengshui",
    "Hengyang",
    "Hengzhou",
    "Heping",
    "Heshun",
    "Hetao",
    "Hetian",
    "Hexi",
    "Hexun",
    "Heyi",
    "Heyuan",
    "Heyuan",
    "Heze",
    "Hezhou",
    "Hezhu",
    "Hezuo",
    "Hohhot",
    "Hongchang",
    "Honglin",
    "Hongling",
    "Hongpan",
    "Hongqiao",
    "Hongshan",
    "Hongshun",
    "Hongtang",
    "Hongwei",
    "Hongxi",
    "Hongyan",
    "Hongyangshu",
    "Hongyuan",
    "Hongyun",
    "Houxiangcheng",
    "Houzhai",
    "Huabei",
    "Huacheng",
    "Huadian",
    "Huadong",
    "Huafeng",
    "Huagong",
    "Huagongchang",
    "Huaibei",
    "Huaihe",
    "Huaihua",
    "Huainan",
    "Huairou",
    "Huajiashan",
    "Huajie",
    "Huali",
    "Hualin",
    "Hualong",
    "Huamei",
    "Huanan",
    "Huangbao",
    "Huangdao",
    "Huangdu",
    "Huanggang",
    "Huanghou",
    "Huanghu",
    "Huanghua",
    "Huangjia",
    "Huangjue",
    "Huangshan",
    "Huangshi",
    "Huangshi",
    "Huangxuan",
    "Huangyan",
    "Huangyuan Chengguanzhen",
    "Huangze",
    "Huangzhai",
    "Huangzhou",
    "Huanzhen",
    "Huaqiang",
    "Huaqiao",
    "Huashan",
    "Huatan",
    "Huating",
    "Huawei",
    "Huaxia",
    "Huaxin",
    "Huaxing",
    "Huayang",
    "Huaying",
    "Huayuan",
    "Huayue",
    "Huayuji",
    "Huazhong",
    "Huazhou",
    "Huge",
    "Huicheng",
    "Huigang",
    "Huilongba",
    "Huimin",
    "Huimin",
    "Huixin",
    "Huiyang",
    "Huizhou",
    "Hulan",
    "Huling",
    "Hulu",
    "Huludao",
    "Huoshan",
    "Huozhou",
    "Hupi",
    "Huquan",
    "Hushan",
    "Huzhen",
    "Huzhou",
    "Jiadi",
    "Jiading",
    "Jiafu",
    "Jiahao",
    "Jiahe Chengguanzhen",
    "Jiahu",
    "Jialing",
    "Jiamusi",
    "Jianci",
    "Jiangbei",
    "Jiangchuanlu",
    "Jiangdong",
    "Jiangguantun",
    "Jiangjia",
    "Jiangjin Qu",
    "Jiangkou",
    "Jiangmen",
    "Jiangnan",
    "Jiangnan",
    "Jiangshan",
    "Jiangtian",
    "Jiangwan",
    "Jiangwang",
    "Jiangyan",
    "Jiangyin",
    "Jiangyou",
    "Jianning",
    "Jianxing",
    "Jianyang",
    "Jianyuan",
    "Jianzhong",
    "Jiaojiang",
    "Jiaokou",
    "Jiaotong",
    "Jiaotou",
    "Jiaowei",
    "Jiaoyu",
    "Jiaoyuan",
    "Jiaoyun",
    "Jiaozhou",
    "Jiaozuo",
    "Jiaqin",
    "Jiaxin",
    "Jiaxing",
    "Jiaying",
    "Jiayuan",
    "Jiayue",
    "Jiazhuang",
    "Jicheng",
    "Jiedong",
    "Jiefang",
    "Jiehe",
    "Jielong",
    "Jielong",
    "Jielong",
    "Jieshou",
    "Jiexi",
    "Jiexiu",
    "Jieyang",
    "Jifeng",
    "Jiguang",
    "Jilin City",
    "Jimo",
    "Jin Jiang",
    "Jinan",
    "Jinbi",
    "Jincheng",
    "Jinchuan",
    "Jinci",
    "Jinda",
    "Jinde",
    "Jindi",
    "Jindian",
    "Jinding",
    "Jindou",
    "Jindu",
    "Jingbei",
    "Jingcheng",
    "Jingdezhen",
    "Jingdong",
    "Jingdu",
    "Jingfeng",
    "Jinghai",
    "Jinghe",
    "Jinghong",
    "Jinghu",
    "Jinghua",
    "Jingjiang",
    "Jinglian",
    "Jinglong",
    "Jingmen",
    "Jingning Chengguanzhen",
    "Jingshan",
    "Jingtian",
    "Jinguang",
    "Jingxi",
    "Jingyuan",
    "Jingzhong",
    "Jingzhou",
    "Jinhai",
    "Jinhe",
    "Jinhong",
    "Jinhu",
    "Jinhua",
    "Jining",
    "Jinjiang",
    "Jinli",
    "Jinling",
    "Jinlong",
    "Jinlu",
    "Jinlun",
    "Jinma",
    "Jinmao",
    "Jinmen",
    "Jinming",
    "Jinnan",
    "Jinpan",
    "Jinpu",
    "Jinqiao",
    "Jinqingzhen",
    "Jinsha",
    "Jinshadi",
    "Jinshan",
    "Jinshi",
    "Jintan",
    "Jintian",
    "Jinwo",
    "Jinxiangzhen",
    "Jinxing",
    "Jinyang",
    "Jinyi",
    "Jinyin",
    "Jinying",
    "Jinyuan",
    "Jinyun",
    "Jinzao",
    "Jinzhong",
    "Jinzhou",
    "Jishou",
    "Jishu",
    "Jiuba",
    "Jiuguan",
    "Jiuzong",
    "Jixi",
    "Jiya",
    "Jiyang",
    "Jiyang",
    "Jizhou",
    "Jondag",
    "Juchuan",
    "Juhua",
    "Julong",
    "Jundu",
    "Junlin",
    "Junping",
    "Junpu",
    "Kaifeng",
    "Kaihua",
    "Kaili",
    "Kaiyuan",
    "Kaiyun",
    "Kandun",
    "Kang Xian",
    "Kangcun",
    "Kangda",
    "Karamay",
    "Kashgar",
    "Kecheng",
    "Keqiao",
    "Keshan",
    "Korla",
    "Kouqian",
    "Kouquan",
    "Kuangyan",
    "Kuicheng",
    "Kuiju",
    "Kuishan",
    "Kumul",
    "Kunlun",
    "Kunming",
    "Kunshan",
    "Kunshan",
    "Kunyang",
    "Kuytun",
    "Laibin",
    "Laiwu",
    "Laiyi",
    "Lake",
    "Langfang",
    "Langli",
    "Langzhong",
    "Lanhai",
    "Lanjiang",
    "Lanping",
    "Lantang",
    "Lanxi",
    "Lanzhou",
    "Laohekou",
    "Laonian",
    "Lecheng",
    "Ledong",
    "Lengshuijiang",
    "Leshan",
    "Lhasa",
    "Lianghu",
    "Liangping",
    "Liangzhu",
    "Lianhua",
    "Lianjiang",
    "Liannan",
    "Lianping",
    "Lianshan",
    "Lianshi",
    "Lianshui",
    "Lianyuan",
    "Lianyungang",
    "Liaocheng",
    "Liaoyang",
    "Liaoyang",
    "Liaoyuan",
    "Libang",
    "Licheng",
    "Licheng",
    "Lida",
    "Lidong",
    "Lidu",
    "Lihou",
    "Lijiang",
    "Lijing",
    "Linbei",
    "Lincang",
    "Lincheng",
    "Lincheng",
    "Lindian",
    "Linfen",
    "Lingang",
    "Lingcheng",
    "Lingcheng",
    "Lingdong",
    "Linghu",
    "Lingnga",
    "Lingshi",
    "Lingshui",
    "Lingxi",
    "Lingzhi",
    "Linhai",
    "Linjiang",
    "Linjiang",
    "Linkou",
    "Linnan",
    "Linping",
    "Linqi",
    "Linqing",
    "Linqu",
    "Lintong",
    "Linxia Chengguanzhen",
    "Linyi",
    "Linze",
    "Linzhou",
    "Lishang",
    "Lishu",
    "Lishui",
    "Liu`an",
    "Liucheng",
    "Liudao",
    "Liudong",
    "Liuhe",
    "Liuheng",
    "Liujian",
    "Liujiang",
    "Liulin",
    "Liushi",
    "Liuzhou",
    "Liwu",
    "Liyuan",
    "Lize",
    "Longchuan",
    "Longdong",
    "Longfeng",
    "Longgang",
    "Longgong",
    "Longhu",
    "Longhua",
    "Longhua",
    "Longjian",
    "Longjiang",
    "Longjin",
    "Longlin",
    "Longmao",
    "Longmen",
    "Longnan",
    "Longquan",
    "Longshan",
    "Longtai",
    "Longteng",
    "Longxiang",
    "Longxin",
    "Longxing",
    "Longxun",
    "Longyan",
    "Longzhou",
    "Loufan",
    "Lucheng",
    "Lugouqiao",
    "Luhua",
    "Lujiazhi",
    "Lung",
    "Luobu",
    "Luocheng",
    "Luofang",
    "Luohe",
    "Luohu",
    "Luohu",
    "Luoling",
    "Luomen",
    "Luorong",
    "Luoxi",
    "Luoyang",
    "Luoyang",
    "Luoyang",
    "Luoyuan",
    "Luozhuang",
    "Luqiao",
    "Luqiao",
    "Luquan",
    "Lushang",
    "Luwan",
    "Luzhou",
    "Luzhou",
    "Macao",
    "Maji",
    "Majin",
    "Maoba",
    "Maodian",
    "Maodun",
    "Maoming",
    "Maotai",
    "Mapai",
    "Mawei",
    "Mayao",
    "Mayu",
    "Meicheng",
    "Meijiang",
    "Meilian",
    "Meilin",
    "Meishan",
    "Meixi",
    "Meiyu",
    "Meiyuan",
    "Meizhou",
    "Meizhou",
    "Mengshan",
    "Mengyin",
    "Mengzhou",
    "Mengzi",
    "Mianyang",
    "Miaogao",
    "Mingde",
    "Mingdu",
    "Mingjia",
    "Mingjing",
    "Mingshui",
    "Mingxi",
    "Mingxing",
    "Mingzhou",
    "Mingzhou",
    "Mingzhu",
    "Minhang",
    "Minjiang",
    "Minjie",
    "Minzhu",
    "Minzu",
    "Mishan",
    "Miyun",
    "Moushan",
    "Mudanjiang",
    "Muling",
    "Nagqu",
    "Name",
    "Nanchang",
    "Nanchong",
    "Nanda",
    "Nandu",
    "Nanfang",
    "Nanguo",
    "Nangxian",
    "Nanhai",
    "Nanhan",
    "Nanhe",
    "Nanhu",
    "Nanjiang",
    "Nanjin",
    "Nanjing",
    "Nankai",
    "Nanlong",
    "Nanma",
    "Nanmen",
    "Nanning",
    "Nanning",
    "Nanping",
    "Nanshan",
    "Nantang",
    "Nantong",
    "Nanxi",
    "Nanxianglong",
    "Nanxun",
    "Nanyang",
    "Nanying",
    "Nanyuan",
    "Nanzhao",
    "Nanzhuang",
    "Napo",
    "Naxi Yaozuxiang",
    "Neijiang",
    "Nenjiang",
    "Niaojiang",
    "Ningbo",
    "Ningde",
    "Ninghai",
    "Ninghe",
    "Ningyi",
    "Niulanshan",
    "Niunaichang",
    "Nongkeng",
    "Nyingchi County",
    "Oucheng",
    "Oujiang",
    "Panjin Shi",
    "Panxi",
    "Panzhihua",
    "Pengcheng",
    "Penglai",
    "Pengshan",
    "Pengyu",
    "Pingchuan",
    "Pingdingshan",
    "Pinghu",
    "Pinghu",
    "Pingliang",
    "Pinglu",
    "Pingwang",
    "Pingxiang",
    "Pingyang",
    "Pingyao County",
    "Pingyi",
    "Pingyin",
    "Pingyuan",
    "Pizhou",
    "Potang",
    "Pucheng",
    "Pudong",
    "Puning",
    "Puning",
    "Puqing",
    "Putian",
    "Putian",
    "Putuo",
    "Putuoshan",
    "Puyang",
    "Puyang",
    "Puyuan",
    "Qiandao",
    "Qiandaohu",
    "Qianhai",
    "Qianjiang",
    "Qianjin",
    "Qianling",
    "Qianqing",
    "Qianshan",
    "Qiantan",
    "Qiantang",
    "Qianyu",
    "Qiaodou",
    "Qiaolian",
    "Qiaoting",
    "Qiaoxia",
    "Qiaoyuan",
    "Qiaozhen",
    "Qidu",
    "Qijiashan",
    "Qike",
    "Qingdao",
    "Qingfeng Chengguanzhen",
    "Qinggang",
    "Qinghai",
    "Qinghu",
    "Qingma",
    "Qingnian",
    "Qingquan",
    "Qingshan",
    "Qingshuiwan",
    "Qingtian",
    "Qingtian",
    "Qingtong",
    "Qingxin",
    "Qingyang",
    "Qingyang",
    "Qingyuan",
    "Qingyuan",
    "Qingzhen",
    "Qingzhou",
    "Qinhuangdao",
    "Qinjiang",
    "Qinzhou",
    "Qionghai",
    "Qiongshan",
    "Qiqihar",
    "Qitai",
    "Quanzhou",
    "Quanzhou",
    "Qujiang",
    "Qujing",
    "Qushan",
    "Quzhou",
    "Renhe",
    "Renhou",
    "Renmin",
    "Renqiu",
    "Renrong",
    "Rizhao",
    "Rongjiang",
    "Rongjiang",
    "Rongjin",
    "Rongle",
    "Rongshan",
    "Rongsheng",
    "Rongxian",
    "Rongxing",
    "Ruicheng",
    "Ruifeng",
    "Ruihu",
    "Ruili",
    "Ruiting",
    "Ruixing",
    "Ruiying",
    "Rujia",
    "Sailing",
    "Sandu",
    "Sanguan",
    "Sanhe",
    "Sanjiang",
    "Sanjiangkou",
    "Sanjiaocheng",
    "Sanjiu",
    "Sanlian",
    "Sanmao",
    "Sanmen",
    "Sanmenxia",
    "Sanming",
    "Sanpailou",
    "Santai",
    "Santiaolou",
    "Sanya",
    "Sanyi",
    "Sanyou",
    "Sanyuanpu",
    "Sanzhang",
    "Shamen",
    "Shancheng",
    "Shancheng",
    "Shangbao",
    "Shangbu",
    "Shangbu",
    "Shangdong",
    "Shangfang",
    "Shanghai",
    "Shanghu",
    "Shangjiang",
    "Shangluo",
    "Shangma",
    "Shangnan",
    "Shangqiu",
    "Shangrao",
    "Shangsha",
    "Shangsi",
    "Shangtang",
    "Shangwang",
    "Shangwu",
    "Shangxi",
    "Shangye",
    "Shangying",
    "Shangzhang",
    "Shangzhi",
    "Shangzhou",
    "Shanhe",
    "Shanhu",
    "Shanrui",
    "Shanshui",
    "Shantou",
    "Shantou",
    "Shanwei",
    "Shanzhuang",
    "Shaoguan",
    "Shaoguang",
    "Shaowu",
    "Shaoxing",
    "Shashan",
    "Shashi",
    "Shecheng",
    "Shejiazhuang",
    "Shekou",
    "Shengdu",
    "Shengfang",
    "Shengli",
    "Shengping",
    "Shengshan",
    "Shenjiamen",
    "Shenlong",
    "Shennan",
    "Shensha",
    "Shenwan",
    "Shenyan",
    "Shenyang",
    "Shenzhen",
    "Shenzhou",
    "Shibei",
    "Shifan",
    "Shifang",
    "Shihezi",
    "Shiji",
    "Shijiazhuang",
    "Shijiazhuang",
    "Shijing",
    "Shijingxiang",
    "Shiling",
    "Shilou",
    "Shima",
    "Shinian",
    "Shipu",
    "Shiqi",
    "Shiqiaopu",
    "Shishi",
    "Shishi",
    "Shixia",
    "Shiyan",
    "Shizheng",
    "Shizhong",
    "Shizhu",
    "Shouchang",
    "Shouchun",
    "Shouguang",
    "Shouyang",
    "Shuangcheng",
    "Shuangcheng",
    "Shuanglin",
    "Shuanglong",
    "Shuangqiao",
    "Shuangshipu",
    "Shuangtang",
    "Shuangxi",
    "Shuangyashan",
    "Shuguang",
    "Shuhong",
    "Shuigang",
    "Shuiku",
    "Shuitian",
    "Shuitou",
    "Shuiwu",
    "Shule",
    "Shuliang",
    "Shunde",
    "Shunshui",
    "Shuozhou",
    "Shuyi",
    "Sichuan",
    "Side",
    "Siduxu",
    "Sijiqing",
    "Sili",
    "Simao",
    "Simcun",
    "Simen",
    "Siting",
    "Siyang",
    "Sizhou",
    "Songling",
    "Songyang",
    "Songyuan",
    "Suihua",
    "Suining",
    "Suixi",
    "Suji",
    "Sundun",
    "Sungang",
    "Suqian",
    "Suqian",
    "Suxi",
    "Suzhou",
    "Suzhou",
    "Suzhuang",
    "Taicang",
    "Taihe",
    "Taikang",
    "Tailai",
    "Taili",
    "Taimen",
    "Taiping",
    "Taiping",
    "Taipingyan",
    "Taishan",
    "Taishan",
    "Taixing",
    "Taiyuan",
    "Taizhou",
    "Tancheng",
    "Tancheng",
    "Tang Xian",
    "Tangbian",
    "Tangchang",
    "Tangcheng",
    "Tangdukou",
    "Tangtou",
    "Tangxi",
    "Tangxia",
    "Tangyuan",
    "Tanshi",
    "Taocheng",
    "Taohua",
    "Taoshan",
    "Taoshan",
    "Taoxi",
    "Taoyuan",
    "Taozhuang",
    "Tara",
    "Tashi",
    "Taxi",
    "Tengjiao",
    "Tengzhou",
    "Tianbao",
    "Tianchang",
    "Tianchang",
    "Tiancheng",
    "Tianchi",
    "Tiandong",
    "Tianfu",
    "Tiangang",
    "Tianhai",
    "Tianhong",
    "Tianjin",
    "Tianle",
    "Tianli",
    "Tianma",
    "Tianning",
    "Tianshan",
    "Tianshi",
    "Tianshui",
    "Tiantai Chengguanzhen",
    "Tianyang",
    "Tianyi",
    "Tianyuan",
    "Tianzhen",
    "Tianzhu",
    "Tieli",
    "Tieling",
    "Tieshan",
    "Tingpang",
    "Tongbai",
    "Tongchuan",
    "Tongcun",
    "Tongji",
    "Tongliao",
    "Tongling",
    "Tonglu",
    "Tongmin",
    "Tongren",
    "Tongwang",
    "Tongxiang",
    "Tongyu",
    "Tongyuan",
    "Tumu",
    "Turpan Diqu",
    "Wanbao",
    "Wangfu",
    "Wangluo",
    "Wangshang",
    "Wangsu",
    "Wanjia",
    "Wanning",
    "Wanquan",
    "Wansong",
    "Wanxi",
    "Weibo",
    "Weidun",
    "Weifang",
    "Weihai",
    "Weinan",
    "Weitang",
    "Weitang",
    "Weiyang",
    "Wenchang",
    "Wenhou",
    "Wenlan",
    "Wenlin",
    "Wenling",
    "Wenquan",
    "Wenshan",
    "Wenshang",
    "Wenxi",
    "Wenxing",
    "Wenzhou",
    "Wuhai",
    "Wuhai",
    "Wuhan",
    "Wuhou",
    "Wuhu",
    "Wuling",
    "Wuning",
    "Wushan",
    "Wutai",
    "Wutong",
    "Wuwei",
    "Wuxi",
    "Wuxia",
    "Wuxing",
    "Wuyang",
    "Wuyi",
    "Wuyishan",
    "Wuzhen",
    "Wuzhishan",
    "Wuzhong",
    "Wuzhou",
    "Xiabancheng",
    "Xiadianmiao",
    "Xiakou",
    "Xiamen",
    "Xianchang",
    "Xiandu",
    "Xiangcheng",
    "Xiangfan",
    "Xiangfeng",
    "Xiangjiang",
    "Xiangnan",
    "Xiangru",
    "Xiangshan",
    "Xiangsheng",
    "Xiangsi",
    "Xiangtan",
    "Xiangtong",
    "Xiangxi",
    "Xiangyi",
    "Xiangyuan",
    "Xianjiang",
    "Xianlin",
    "Xianning",
    "Xianqiao",
    "Xianren",
    "Xianrenqiao",
    "Xiantao",
    "Xianyang",
    "Xianyou",
    "Xiaogan",
    "Xiaogang",
    "Xiaoheshan",
    "Xiaojiahe",
    "Xiaojiang",
    "Xiaomi",
    "Xiaoqu",
    "Xiaoshan",
    "Xiaoshun",
    "Xiaosi",
    "Xiaoxi",
    "Xiaozheng",
    "Xiapu",
    "Xiasha",
    "Xiashi",
    "Xiatangyunxiang",
    "Xiayuan",
    "Xiazhi",
    "Xiazhuang",
    "Xibei",
    "Xibu",
    "Xichang",
    "Xichong",
    "Xidian",
    "Xiedian",
    "Xieqiao",
    "Xiezhou",
    "Xigu",
    "Xiguan",
    "Xihu",
    "Xijiao",
    "Xijiao",
    "Xijing",
    "Xikou",
    "Xiling",
    "Ximeng",
    "Xinao",
    "Xincao",
    "Xinchang",
    "Xinchen",
    "Xincheng",
    "Xindi",
    "Xindu",
    "Xingang",
    "Xingchang",
    "Xingchen",
    "Xingcheng",
    "Xingfeng",
    "Xingguang",
    "Xinghua",
    "Xingqiao",
    "Xingtai",
    "Xinguang",
    "Xingwang",
    "Xingxian",
    "Xingxiangcun",
    "Xingxing",
    "Xingye",
    "Xingyi",
    "Xingyuan",
    "Xinhua",
    "Xinhua",
    "Xinhuang",
    "Xinhui",
    "Xining",
    "Xinjian",
    "Xinjiang",
    "Xinmin",
    "Xinning",
    "Xinqiao",
    "Xinrong",
    "Xinsha",
    "Xinshijie",
    "Xintai",
    "Xintangbian",
    "Xintian",
    "Xinxiang",
    "Xinxing",
    "Xinyang",
    "Xinyi",
    "Xinyou",
    "Xinzha",
    "Xinzheng",
    "Xinzhou",
    "Xiping",
    "Xiqu",
    "Xishan",
    "Xitang",
    "Xiushan",
    "Xiushui",
    "Xiuying",
    "Xiuyu",
    "Xixi",
    "Xiyang",
    "Xiyue",
    "Xizhang",
    "Xizhou",
    "Xuancheng",
    "Xuanwu",
    "Xuanzhou",
    "Xuchang",
    "Xucheng",
    "Xucun",
    "Xuefeng",
    "Xuexi",
    "Xuexiao",
    "Xuezhuang",
    "Xuhui",
    "Xunxian",
    "Xunyi Chengguanzhen",
    "Xushan",
    "Xuzhou",
    "Yahao",
    "Yajin",
    "Yalan",
    "Yalongwan",
    "Yanchang",
    "Yancheng",
    "Yancheng",
    "Yandang",
    "Yandao",
    "Yangce",
    "Yangcun",
    "Yangda",
    "Yangfen",
    "Yanggu",
    "Yangjiang",
    "Yangling",
    "Yanglou",
    "Yangmiao",
    "Yangpu",
    "Yangqitan",
    "Yangqu",
    "Yangquan",
    "Yangshan",
    "Yanguan",
    "Yangxunqiao",
    "Yangzhong",
    "Yangzhou",
    "Yangzhou",
    "Yanhai",
    "Yanji",
    "Yanjiang",
    "Yanjiao",
    "Yanjiao",
    "Yanjing",
    "Yanming",
    "Yanshi",
    "Yanta",
    "Yanta",
    "Yantai",
    "Yantan",
    "Yaocheng",
    "Yaofeng",
    "Yaohua",
    "Yaoluoping",
    "Yaozhuang",
    "Yayu",
    "Yibin",
    "Yichang",
    "Yicheng",
    "Yicheng",
    "Yichun",
    "Yidong",
    "Yifeng",
    "Yifu",
    "Yijin",
    "Yijing",
    "Yili",
    "Yinchuan",
    "Yindian",
    "Yinfang",
    "Yingcai",
    "Yingchuan",
    "Yingdu",
    "Yinggen",
    "Yingjia",
    "Yingjie",
    "Yingqian",
    "Yingtai",
    "Yinhai",
    "Yining",
    "Yinjiang",
    "Yinliang",
    "Yinshan",
    "Yintian",
    "Yinxin",
    "Yinzhou",
    "Yiqiao",
    "Yisa",
    "Yishan",
    "Yishui",
    "Yitian",
    "Yiting",
    "Yiwu",
    "Yiwu",
    "Yiyang",
    "Yizheng",
    "Yizhong",
    "Yizhou",
    "Yongchuan",
    "Yongjia",
    "Yongjiawan",
    "Yongjin",
    "Yongjiu",
    "Yongkang",
    "Yongning",
    "Yongqiang",
    "Yongxing",
    "Yongzhou",
    "Youchegang",
    "Youjia",
    "Youjiang",
    "Youshi",
    "Youyi",
    "Yuanchang",
    "Yuanheng",
    "Yuanli",
    "Yuanling",
    "Yuanping",
    "Yuanpu",
    "Yuanqu",
    "Yuantong",
    "Yuanyang",
    "Yucai",
    "Yucheng",
    "Yucheng",
    "Yuchi",
    "Yuecheng",
    "Yuehu",
    "Yuehua",
    "Yuele",
    "Yueliangwan",
    "Yueqing",
    "Yuetai",
    "Yuhai",
    "Yuhan",
    "Yuhang",
    "Yuhong",
    "Yuhuan",
    "Yuhuayuan",
    "Yuhui",
    "Yujing",
    "Yulin",
    "Yulong",
    "Yuncheng",
    "Yunfeng",
    "Yunfu",
    "Yungang",
    "Yungcun",
    "Yunling",
    "Yunnan",
    "Yunting",
    "Yunyang",
    "Yuting",
    "Yutou",
    "Yuxi",
    "Yuxi",
    "Yuxiang",
    "Yuyao",
    "Yuyuan",
    "Yuzhen",
    "Yuzhong Chengguanzhen",
    "Yuzi",
    "Zaoyang",
    "Zaozhuang",
    "Zhancheng",
    "Zhanghe",
    "Zhanghou",
    "Zhangjiagang",
    "Zhangjiagang",
    "Zhangjiajie",
    "Zhangjiakou",
    "Zhangjiang",
    "Zhangqi",
    "Zhangqiu",
    "Zhangshan",
    "Zhangshu",
    "Zhangye",
    "Zhangzhai",
    "Zhangzhou",
    "Zhangzhuang",
    "Zhanjiang",
    "Zhanmao",
    "Zhaobaoshan",
    "Zhaodong",
    "Zhaoqing",
    "Zhaotong",
    "Zhaoyu",
    "Zhaozhou",
    "Zhapu",
    "Zhedong",
    "Zhengda",
    "Zhengdi",
    "Zhengding",
    "Zhenghai",
    "Zhengjiang",
    "Zhengyuan",
    "Zhengzhou",
    "Zhenhua",
    "Zhenjiang",
    "Zhenxi",
    "Zhenxing",
    "Zhenzhou",
    "Zhenzhuquan",
    "Zheshang",
    "Zhicheng",
    "Zhijiang",
    "Zhili",
    "Zhima",
    "Zhixing",
    "Zhiye",
    "Zhiyuan",
    "Zhizhong",
    "Zhongdian",
    "Zhongfang",
    "Zhongfu",
    "Zhonggongmiao",
    "Zhongji",
    "Zhongjian",
    "Zhonglin",
    "Zhongnan",
    "Zhongqiao",
    "Zhongshan",
    "Zhongshan",
    "Zhongtang",
    "Zhongtian",
    "Zhongtu",
    "Zhongwei",
    "Zhongxiang",
    "Zhongxiang",
    "Zhongxin",
    "Zhongxing",
    "Zhongyang",
    "Zhongyicun",
    "Zhongyin",
    "Zhongyuan",
    "Zhongzhou",
    "Zhongzhou",
    "Zhoukou",
    "Zhoushan",
    "Zhoushan",
    "Zhouwangmiao",
    "Zhouxiang",
    "Zhuhai",
    "Zhuji",
    "Zhujiajian",
    "Zhujiajiao",
    "Zhulin",
    "Zhumadian",
    "Zhuozhou",
    "Zhuxi Chengguanzhen",
    "Zhuxiang",
    "Zhuyuan",
    "Zibo",
    "Zicheng",
    "Zigong",
    "Zijin",
    "Ziyang",
    "Ziyu",
    "Zongxoi",
    "Zoucheng",
    "Zunyi",
    "Zuxi"],
  },
  {
    name: "Colombia",
    cities: ["Acacias",
    "Acevedo",
    "Aguachica",
    "Antioquia",
    "Arauca",
    "Armenia",
    "Atlantico",
    "Barrancabermeja",
    "Barranquilla",
    "Bello",
    "Bermudez",
    "Boyaca",
    "Bucaramanga",
    "Buenaventura",
    "Buga",
    "Cajica",
    "Calamar",
    "Caldas",
    "Candelaria",
    "Cartagena",
    "Cartago",
    "Cauca",
    "Chia",
    "Chiquinquira",
    "Chocho",
    "Columbia",
    "Corozal",
    "Cota",
    "Cumaribo",
    "Cundinamarca",
    "Dosquebradas",
    "Duitama",
    "El Colegio",
    "Engativa",
    "Envigado",
    "Espinal",
    "Florencia",
    "Floridablanca",
    "Fonseca",
    "Fundacion",
    "Fusagasuga",
    "Galapa",
    "Ginebra",
    "Giron",
    "Guarne",
    "Ibague",
    "Inirida",
    "Ipiales",
    "Itagui",
    "La Ceja",
    "La Estrella",
    "La Mesa",
    "La Union",
    "Los Patios",
    "Madrid",
    "Magdalena",
    "Manizales",
    "Miami",
    "Mitu",
    "Montenegro",
    "Mosquera",
    "Municipio de Copacabana",
    "Neiva",
    "Obando",
    "Palmira",
    "Pamplona",
    "Pasto",
    "Pereira",
    "Piedecuesta",
    "Pitalito",
    "Planadas",
    "Popayan",
    "Puerta Roja",
    "Puerto Tejada",
    "Purificacion",
    "Riohacha",
    "Rionegro",
    "Risaralda",
    "Sabana de Torres",
    "Sabaneta",
    "Sachica",
    "San Clemente",
    "San Diego",
    "San Gil",
    "San Martin",
    "Santa Marta",
    "Santa Rosa de Cabal",
    "Santa Rosa del Sur",
    "Santiago de Cali",
    "Silvania",
    "Sincelejo",
    "Soacha",
    "Sogamoso",
    "Soledad",
    "Sopo",
    "Tarapaca",
    "Tauramena",
    "Tenjo",
    "Tocancipa",
    "Tunja",
    "Turbaco",
    "Ubaque",
    "Urumita",
    "Valledupar",
    "Velez",
    "Villa del Rosario",
    "Villamaria",
    "Villavicencio",
    "Yopal",
    "Yotoco",
    "Yumbo"],
  },
  {
    name: "Comoros",
    cities: ["Moroni", "Mutsamudu", "Fomboni"],
  },
  {
    name: "Congo",
    cities: ["Banana",
    "Goma",
    "Kinshasa",
    "Likasi",
    "Lubumbashi"],
  },
  {
    name: "Costa Rica",
    cities: ["Alajuela",
    "Alajuelita",
    "Alfaro",
    "Aserri",
    "Atenas",
    "Barva",
    "Cartago",
    "Colon",
    "Corazon de Jesus",
    "Coronado",
    "Coyol",
    "Curridabat",
    "Desamparados",
    "Escazu",
    "Esparza",
    "Filadelfia",
    "Grecia",
    "Guachipelin",
    "Guacima",
    "Guadalupe",
    "Guapiles",
    "Heredia",
    "Isla de Tibas",
    "Jaco",
    "La Garita",
    "Liberia",
    "Llorente",
    "Llorente",
    "Mercedes",
    "Mexico",
    "Miramar",
    "Moravia",
    "Palmares",
    "Paraiso",
    "Pavas",
    "Perez",
    "Poas",
    "Puerto Jimenez",
    "Puntarenas",
    "Quepos",
    "Quesada",
    "Sabanilla",
    "San Diego",
    "San Francisco",
    "San Francisco",
    "San Isidro",
    "San Joaquin",
    "San Jose de la Montana",
    "San Josecito",
    "San Juan",
    "San Marcos",
    "San Pablo",
    "San Pedro",
    "San Rafael",
    "San Rafael",
    "San Ramon",
    "Santa Ana",
    "Santa Cecilia",
    "Santa Cruz",
    "Santo Domingo",
    "Sarchi",
    "Siquirres",
    "Tres Rios",
    "Turrialba",
    "Turrucares",
    "Zapote",
    "Zarcero"],
  },
  {
    name: "Croatia",
    cities: ["Antunovac",
    "Baska",
    "Baska Voda",
    "Bedekovcina",
    "Bestovje",
    "Betina",
    "Bibinje",
    "Bizovac",
    "Bjelovar",
    "Bracevci",
    "Brdovec",
    "Bregana",
    "Brela",
    "Brsadin",
    "Buje",
    "Cabuna",
    "Cavtat",
    "Cepin",
    "Cestica",
    "Core",
    "Crikvenica",
    "Dalj",
    "Darda",
    "Delnice",
    "Desinic",
    "Donja Pusca",
    "Donja Zelina",
    "Donji Kraljevec",
    "Dubrovnik",
    "Dugo Selo",
    "Fazana",
    "Fuzine",
    "Galgovo",
    "Glina",
    "Gornja Lomnica",
    "Gornji Kriz",
    "Gornji Stupnik",
    "Hreljin",
    "Icici",
    "Ivanec",
    "Ivanic-Grad",
    "Karlovac",
    "Karojba",
    "Kastel Gomilica",
    "Kastel Luksic",
    "Kastel Sucurac",
    "Koprivnica",
    "Kostrena",
    "Kraljevec na Sutli",
    "Krapina",
    "Kriz",
    "Krk",
    "Krsan",
    "Kutjevo",
    "Labin",
    "Lapad",
    "Lekenik",
    "Lepoglava",
    "Lovran",
    "Lukavec",
    "Lupoglav",
    "Lupoglav",
    "Makarska",
    "Matulji",
    "Medulin",
    "Mlini",
    "Mursko Sredisce",
    "Nedelisce",
    "Nova Gradiska",
    "Novi Marof",
    "Novi Vinodolski",
    "Novo Cice",
    "Nustar",
    "Okrug Gornji",
    "Opatija",
    "Orahovica",
    "Orebic",
    "Orle",
    "Oroslavje",
    "Osijek",
    "Pakrac",
    "Pazin",
    "Petrcane",
    "Petrinja",
    "Piskorevci",
    "Podastrana",
    "Podgajci Posavski",
    "Popovaca",
    "Postira",
    "Pregrada",
    "Prelog",
    "Primosten",
    "Privlaka",
    "Pula",
    "Rab",
    "Rakitje",
    "Rijeka",
    "Rijeka",
    "Rovinj",
    "Samobor",
    "Sapjane",
    "Senkovec",
    "Sesvete",
    "Sesvetski Kraljevec",
    "Sice",
    "Silas",
    "Simuni",
    "Sinj",
    "Sisak",
    "Slatina",
    "Slavonski Brod",
    "Sokolovac",
    "Solin",
    "Split",
    "Splitska",
    "Strmec",
    "Strmec Samoborski",
    "Sveti Ivan Zelina",
    "Tar",
    "Torcec",
    "Trogir",
    "Tuzno",
    "Umag",
    "Varazdinske Toplice",
    "Vardarac",
    "Velika Gorica",
    "Vetovo",
    "Vinica",
    "Vinkovci",
    "Virje",
    "Virovitica",
    "Viskovci",
    "Vojnic",
    "Vrata",
    "Vratisinec",
    "Vrbnik",
    "Vrbovec",
    "Vukovar",
    "Zabok",
    "Zadar",
    "Zagreb",
    "Zapresic",
    "Zlatar"],
  },
  {
    name: "Cuba",
    cities: ["Bayamo",
    "Cienfuegos",
    "Habana",
    "Havana",
    "La Habana",
    "Las Tunas",
    "Matanzas",
    "Santiago de Cuba",
    "Varadero",
    "Villa"],
  },
  {
    name: "Cyprus",
    cities: [ "Agia Anna",
    "Aradippou",
    "Ayia Marina",
    "Chlorakas",
    "Deryneia",
    "Famagusta",
    "Geroskipou",
    "Kato Lakatamia",
    "Kato Polemidia",
    "Kiti",
    "Kyrenia",
    "Larnaca",
    "Laxia",
    "Limassol",
    "Livadia",
    "Morfou",
    "Mouttagiaka",
    "Nicosia",
    "Paphos",
    "Paralimni",
    "Parekklisha",
    "Prodromi",
    "Sotira",
    "Strovolos",
    "Tera"],
  },
  {
    name: "Czech Republic",
    cities: ["Adamov",
    "Albrechtice",
    "Arnoltice",
    "As",
    "Babice",
    "Banov",
    "Baska",
    "Batelov",
    "Bavory",
    "Bechyne",
    "Bela pod Bezdezem",
    "Belotin",
    "Benatky nad Jizerou",
    "Benesov",
    "Benesovice",
    "Bernartice",
    "Bernartice nad Odrou",
    "Beroun",
    "Bezdekov",
    "Bilina",
    "Bilina",
    "Bilovice",
    "Bilovice nad Svitavou",
    "Bitovany",
    "Blansko",
    "Blatna",
    "Blizevedly",
    "Blovice",
    "Blucina",
    "Bludov",
    "Bludovice",
    "Bochov",
    "Bohumin",
    "Bohunovice",
    "Bojanov",
    "Bojkovice",
    "Bolatice",
    "Bolebor",
    "Bor",
    "Boretice",
    "Borohradek",
    "Borotin",
    "Borovany",
    "Borsice",
    "Bosin",
    "Boskovice",
    "Bradlec",
    "Brandys nad Labem-Stara Boleslav",
    "Branky",
    "Branov",
    "Bratrikov",
    "Brezi",
    "Brezno",
    "Brezolupy",
    "Brezova",
    "Brno",
    "Brod",
    "Brodce",
    "Brodek",
    "Broumov",
    "Brtnice",
    "Bruntal",
    "Brusperk",
    "Bucovice",
    "Budyne nad Ohri",
    "Bukovec",
    "Bustehrad",
    "Bylnice",
    "Bystrice",
    "Bystrice",
    "Bystrice",
    "Bystrice nad Pernstejnem",
    "Bystrice pod Hostynem",
    "Cakovice",
    "Caslav",
    "Cebin",
    "Cechtice",
    "Cejc",
    "Cejkovice",
    "Celadna",
    "Celakovice",
    "Cepi",
    "Cercany",
    "Cerekvice nad Loucnou",
    "Cerhenice",
    "Cerna Hora",
    "Cerna za Bory",
    "Cerncice",
    "Cernosice",
    "Cernotin",
    "Cervene Pecky",
    "Cerveny Kostelec",
    "Ceska",
    "Ceska Skalice",
    "Ceska Trebova",
    "Ceska Ves",
    "Ceske Mezirici",
    "Cesky Brod",
    "Cesky Dub",
    "Cestice",
    "Cheb",
    "Cheznovice",
    "Chlumcany",
    "Chlumec",
    "Chlumec nad Cidlinou",
    "Chlupice",
    "Chocen",
    "Chocerady",
    "Chodov",
    "Chodov",
    "Chodova Plana",
    "Chomutov",
    "Chotebor",
    "Chotesov",
    "Chotetov",
    "Chotoviny",
    "Choustnik",
    "Choustnikovo Hradiste",
    "Chrast",
    "Chrast",
    "Chrastna",
    "Chribska",
    "Chroustovice",
    "Chrudim",
    "Chvalec",
    "Chyne",
    "Cista",
    "Citoliby",
    "Citov",
    "Citov",
    "Ckyne",
    "Ctidruzice",
    "Cvikov",
    "Dalovice",
    "Damnice",
    "Darkovicky",
    "Davle",
    "Deblin",
    "Dehylov",
    "Dejvice",
    "Desna",
    "Detmarovice",
    "Dily",
    "Divisov",
    "Dlouha Trebova",
    "Dlouhe",
    "Dobra",
    "Dobra Voda",
    "Dobrany",
    "Dobre",
    "Dobrichovice",
    "Dobris",
    "Dobriv",
    "Dobronin",
    "Dobroslavice",
    "Dobruska",
    "Doksy",
    "Dolany",
    "Dolni Becva",
    "Dolni Benesov",
    "Dolni Berkovice",
    "Dolni Bojanovice",
    "Dolni Brezany",
    "Dolni Dunajovice",
    "Dolni Jircany",
    "Dolni Kounice",
    "Dolni Nemci",
    "Dolni Poustevna",
    "Dolni Radechova",
    "Dolni Sucha",
    "Dolni Sytova",
    "Dolni Terlicko",
    "Dolni Zandov",
    "Domasov",
    "Domazelice",
    "Domazlice",
    "Doubravnik",
    "Doubravy",
    "Doudleby nad Orlici",
    "Drahotuse",
    "Drisy",
    "Drnovice",
    "Drzkov",
    "Drzovice",
    "Dubec",
    "Dubenec",
    "Dubi",
    "Dubicko",
    "Dubnany",
    "Dubne",
    "Duchcov",
    "Dukovany",
    "Dusejov",
    "Dvorce",
    "Dvur Kralove nad Labem",
    "Dymokury",
    "Ejpovice",
    "Filipova",
    "Francova Lhota",
    "Frantiskovy Lazne",
    "Frenstat pod Radhostem",
    "Frydek-Mistek",
    "Frydlant",
    "Frydlant nad Ostravici",
    "Frystak",
    "Fulnek",
    "Golcuv Jenikov",
    "Grucovice",
    "Habartov",
    "Haj ve Slezsku",
    "Haje",
    "Hajnice",
    "Halenkov",
    "Hamr",
    "Harrachov",
    "Havrice",
    "Hermanuv Mestec",
    "Hlasna Treban",
    "Hlinsko",
    "Hlubocepy",
    "Hlubocky",
    "Hluboka nad Vltavou",
    "Hlucin",
    "Hodice",
    "Hodslavice",
    "Holasice",
    "Holesov",
    "Holice",
    "Holoubkov",
    "Holubice",
    "Holysov",
    "Horesedly",
    "Horice",
    "Horin",
    "Horni Becva",
    "Horni Benesov",
    "Horni Bojanovice",
    "Horni Briza",
    "Horni Lutyne",
    "Horni Nemci",
    "Horni Pocernice",
    "Horni Podluzi",
    "Horni Redice",
    "Horni Slavkov",
    "Horomerice",
    "Horousanky",
    "Horovice",
    "Horsovsky Tyn",
    "Hospozin",
    "Hosteradice",
    "Hostice",
    "Hostin",
    "Hostinne",
    "Hostivice",
    "Hostomice",
    "Hostomice",
    "Hovezi",
    "Hovorany",
    "Hovorcovice",
    "Hrabetice",
    "Hradcovice",
    "Hradec",
    "Hradec nad Moravici",
    "Hradek",
    "Hradek nad Nisou",
    "Hradiste",
    "Hradiste",
    "Hranice",
    "Hranice",
    "Hrdejovice",
    "Hronov",
    "Hrusovany nad Jevisovkou",
    "Hrusovany u Brna",
    "Hudlice",
    "Hukvaldy",
    "Hulin",
    "Humpolec",
    "Hustopece",
    "Hvozd",
    "Hysly",
    "Ivancice",
    "Ivanovice na Hane",
    "Jablonec nad Nisou",
    "Jablonne nad Orlici",
    "Jablonne v Podjestedi",
    "Jablunka",
    "Jablunkov",
    "Jalubi",
    "Janovice",
    "Janovice nad Uhlavou",
    "Jaromer",
    "Jaroslav",
    "Jaroslavice",
    "Jedovnice",
    "Jenec",
    "Jesenice",
    "Jesenik",
    "Jevicko",
    "Jevisovice",
    "Jezov",
    "Jihlava",
    "Jilemnice",
    "Jilove u Prahy",
    "Jilovec",
    "Jiloviste",
    "Jince",
    "Jindrichuv Hradec",
    "Jinocany",
    "Jirice",
    "Jirickov",
    "Jirkov",
    "Jirny",
    "Jistebnik",
    "Kacerov",
    "Kacice",
    "Kadan",
    "Kamenice",
    "Kamenice",
    "Kamenicky Senov",
    "Kamenne Zehrovice",
    "Kamenny Privoz",
    "Kamyk",
    "Kaplice",
    "Karlin",
    "Karlovy Vary",
    "Karvina",
    "Kaznejov",
    "Kbely",
    "Kdyne",
    "Kladno",
    "Klasterec nad Ohri",
    "Klatovy",
    "Klecany",
    "Klicany",
    "Klimkovice",
    "Klobouky",
    "Knezmost",
    "Kochovice",
    "Koci",
    "Kolodeje",
    "Komarice",
    "Komorni Lhotka",
    "Konice",
    "Kopidlno",
    "Koprivnice",
    "Korycany",
    "Kosmonosy",
    "Kostelec na Hane",
    "Kostelec nad Cernymi Lesy",
    "Kostelec nad Labem",
    "Kostelec nad Orlici",
    "Kostelni Hlavno",
    "Kostice",
    "Kotencice",
    "Kourim",
    "Kouty",
    "Kozmice",
    "Kralice na Hane",
    "Kraliky",
    "Kralovice",
    "Kralupy nad Vltavou",
    "Kraluv Dvur",
    "Kraslice",
    "Krasna Hora nad Vltavou",
    "Krasna Lipa",
    "Kratonohy",
    "Kravare",
    "Krchleby",
    "Krenovice",
    "Krepenice",
    "Kresice",
    "Kretin",
    "Krhova",
    "Krivoklat",
    "Krizanov",
    "Krmelin",
    "Krnov",
    "Krnsko",
    "Krouna",
    "Krtiny",
    "Krumvir",
    "Krupka",
    "Ktis",
    "Kuncice",
    "Kuncina",
    "Kunovice",
    "Kunratice",
    "Kunstat",
    "Kurim",
    "Kvetnice",
    "Kyjov",
    "Kyjov",
    "Kylesovice",
    "Lachov",
    "Ladvi",
    "Lampertice",
    "Lanskroun",
    "Lanzhot",
    "Lasenice",
    "Lazne Bohdanec",
    "Lazne Libverda",
    "Ledec nad Sazavou",
    "Lednice",
    "Leskovec",
    "Lesonice",
    "Letohrad",
    "Letovice",
    "Lety",
    "Lhota",
    "Lhota",
    "Lhota",
    "Lhota pod Libcany",
    "Lhotka",
    "Liban",
    "Libcany",
    "Liberec",
    "Libesice",
    "Libeznice",
    "Libice nad Cidlinou",
    "Libis",
    "Libocany",
    "Libochovicky",
    "Libos",
    "Lichkov",
    "Lichnov (o. Novy Jicin)",
    "Line",
    "Lipence",
    "Lipnik nad Becvou",
    "Lipova",
    "Lisov",
    "Litava",
    "Liten",
    "Litovel",
    "Litvinov",
    "Lodenice",
    "Loket",
    "Lomnice",
    "Lomnice nad Luznici",
    "Lomnice nad Popelkou",
    "Loucany",
    "Loucen",
    "Loukov",
    "Lounovice pod Blanikem",
    "Louny",
    "Lovosice",
    "Lubenec",
    "Luby",
    "Luhacovice",
    "Luka",
    "Lukov",
    "Lustenice",
    "Lutin",
    "Luze",
    "Luzna",
    "Lysa nad Labem",
    "Lysice",
    "Mala Skala",
    "Malcice",
    "Malenice",
    "Malenovice",
    "Malesov",
    "Maly Ujezd",
    "Marianska",
    "Markvartovice",
    "Marov u Upice",
    "Mcely",
    "Mechenice",
    "Menany",
    "Merklin",
    "Merklin",
    "Mesice",
    "Mestec",
    "Mesto Touskov",
    "Mezibori",
    "Mezilesi",
    "Michle",
    "Mikulov",
    "Mikulovice",
    "Milevsko",
    "Milikov",
    "Milin",
    "Milonice",
    "Milotice",
    "Milovice",
    "Mimon",
    "Miroslav",
    "Mirosov",
    "Mirosovice",
    "Mlada Vozice",
    "Mlade Buky",
    "Mladkov",
    "Mlazovice",
    "Mnichovice",
    "Mnisek pod Brdy",
    "Modrice",
    "Mohelnice",
    "Mokra Horakov",
    "Mokrany",
    "Moran",
    "Moravicany",
    "Moravska Nova Ves",
    "Moravska Trebova",
    "Moravske Budejovice",
    "Moravsky Beroun",
    "Moravsky Krumlov",
    "Moravsky Pisek",
    "Moravsky Zizkov",
    "Most",
    "Mostek",
    "Mosty u Jablunkova",
    "Mratin",
    "Mrlinek",
    "Msec",
    "Mseno",
    "Mukarov",
    "Mutenice",
    "Nachod",
    "Naklo",
    "Napajedla",
    "Nasavrky",
    "Navsi u Jablunkova",
    "Nechanice",
    "Nedakonice",
    "Nedomice",
    "Nedvedice",
    "Nehvizdy",
    "Nejdek",
    "Nelahozeves",
    "Nenkovice",
    "Nepomysl",
    "Neratovice",
    "Nesovice",
    "Nezamyslice",
    "Nezvestice",
    "Nikolcice",
    "Nivnice",
    "Nova Cerekev",
    "Nova Lhota",
    "Nova Paka",
    "Nova Vcelnice",
    "Nova Ves",
    "Nove Dvory",
    "Nove Mesto na Morave",
    "Nove Mesto nad Metuji",
    "Nove Straseci",
    "Nove Veseli",
    "Novosedly",
    "Novy Bor",
    "Novy Bydzov",
    "Novy Hrozenkov",
    "Novy Jicin",
    "Novy Knin",
    "Novy Oldrichov",
    "Nucice",
    "Nupaky",
    "Nydek",
    "Nymburk",
    "Nyrany",
    "Nyrsko",
    "Obrany",
    "Obristvi",
    "Ochoz",
    "Odolena Voda",
    "Odry",
    "Ohrobec",
    "Okrisky",
    "Olomouc",
    "Olomucany",
    "Olovi",
    "Olsany",
    "Ondrejov",
    "Oparany",
    "Opatovice nad Labem",
    "Opava",
    "Opocno",
    "Orlova",
    "Osecna",
    "Osek",
    "Osik",
    "Oslavany",
    "Ostopovice",
    "Ostrava",
    "Ostromer",
    "Ostrov",
    "Ostrov u Macochy",
    "Ostrozska Lhota",
    "Ostrozska Nova Ves",
    "Otnice",
    "Otrokovice",
    "Pacov",
    "Palkovice",
    "Pardubice",
    "Pariz",
    "Pavlice",
    "Pchery",
    "Pecin",
    "Pecky",
    "Pelhrimov",
    "Perstejn",
    "Petrovice",
    "Petrovice u Karvine",
    "Petrvald",
    "Pilsen",
    "Pisecna",
    "Pisecna",
    "Pistovice",
    "Plana",
    "Planany",
    "Plouznice",
    "Pocatky",
    "Pocenice",
    "Podborany",
    "Podebrady",
    "Podivin",
    "Polepy",
    "Polesovice",
    "Police nad Metuji",
    "Policka",
    "Polna",
    "Poricany",
    "Poritsch",
    "Postoloprty",
    "Postrelmov",
    "Potstejn",
    "Prachatice",
    "Prackovice nad Labem",
    "Prague",
    "Prazmo",
    "Preckov",
    "Predklasteri",
    "Predmerice nad Jizerou",
    "Predmerice nad Labem",
    "Predmesti",
    "Prelouc",
    "Prepere",
    "Prerov nad Labem",
    "Prestice",
    "Prezletice",
    "Pribor",
    "Pribyslav",
    "Prikazy",
    "Primda",
    "Prlov",
    "Prosetin",
    "Protivin",
    "Provodov",
    "Pruhonice",
    "Prusanky",
    "Psary",
    "Pustejov",
    "Pysely",
    "Radim",
    "Radnice",
    "Radostice",
    "Radostin",
    "Radun",
    "Raj",
    "Rajec-Jestrebi",
    "Rajecko",
    "Rakovnik",
    "Rapotice",
    "Rapotin",
    "Raspenava",
    "Rataje",
    "Ratibor",
    "Ratiskovice",
    "Rebesovice",
    "Repy",
    "Ricany",
    "Ricany",
    "Rikovice",
    "Ritka",
    "Rokycany",
    "Rokytnice",
    "Rokytnice nad Jizerou",
    "Ronov nad Doubravou",
    "Rosice",
    "Rosice",
    "Roudnice nad Labem",
    "Roudnicek",
    "Rousinov",
    "Rovensko pod Troskami",
    "Rovina",
    "Rovna",
    "Rozdrojovice",
    "Rozmital pod Tremsinem",
    "Roznov pod Radhostem",
    "Rozsochatec",
    "Roztoky",
    "Rtyne",
    "Ruda nad Moravou",
    "Rudikov",
    "Rudna",
    "Rudolfov",
    "Rudoltice",
    "Rybna",
    "Rychnov",
    "Rychnov nad Kneznou",
    "Rychvald",
    "Rymarov",
    "Sadska",
    "Sanov",
    "Sany",
    "Satov",
    "Seberov",
    "Sebrov",
    "Sedlcany",
    "Sedlec",
    "Sedliste",
    "Seibersdorf",
    "Semily",
    "Senec",
    "Senice na Hane",
    "Senohraby",
    "Senov",
    "Sestajovice",
    "Sezemice",
    "Sibrina",
    "Silherovice",
    "Siluvky",
    "Sivice",
    "Skalice",
    "Skalsko",
    "Skripov",
    "Skuhrov nad Belou",
    "Skutec",
    "Slany",
    "Slapanice",
    "Slapy",
    "Slavicin",
    "Slavkov u Brna",
    "Slavonice",
    "Sloup",
    "Sluknov",
    "Slusovice",
    "Smirice",
    "Smrzovka",
    "Snedovice",
    "Snezne",
    "Sobeslav",
    "Sobotka",
    "Sokolov",
    "Spalene Porici",
    "Spytihnev",
    "Stalkov",
    "Stankov",
    "Stara Paka",
    "Stara Voda",
    "Stare Mesto",
    "Stare Mesto",
    "Stare Sedliste",
    "Starec",
    "Starovice",
    "Starovicky",
    "Stary Bydzov",
    "Statenice",
    "Stenovice",
    "Stepanov",
    "Sterboholy",
    "Sternberk",
    "Steti",
    "Stezery",
    "Stitina",
    "Stity",
    "Stochov",
    "Stod",
    "Stoky",
    "Strachotice",
    "Strachotin",
    "Strakonice",
    "Strancice",
    "Strasice",
    "Straz nad Nisou",
    "Straz pod Ralskem",
    "Strazek",
    "Straznice",
    "Strelice",
    "Stribrec",
    "Stribrna Skalice",
    "Stribro",
    "Stritez nad Ludinou",
    "Strunkovice nad Blanici",
    "Studenka",
    "Sucha Loz",
    "Sudkov",
    "Sumice",
    "Sumperk",
    "Susice",
    "Svatava",
    "Svaty Jan nad Malsi",
    "Svetla nad Sazavou",
    "Sviadnov",
    "Svihov",
    "Svitavka",
    "Svitavy",
    "Svojek",
    "Svojkov",
    "Syrovice",
    "Tachlovice",
    "Tachov",
    "Tehov",
    "Telc",
    "Telnice",
    "Temice",
    "Teplice",
    "Tesetice",
    "Tetin",
    "Tisice",
    "Tisnov",
    "Tlumacov",
    "Tman",
    "Tosovice",
    "Trebechovice pod Orebem",
    "Trebestovice",
    "Trebon",
    "Tremesna",
    "Tremosna",
    "Trhovy Stepanov",
    "Trinec",
    "Trmice",
    "Troja",
    "Trstenice",
    "Trutnov",
    "Tucapy",
    "Tuchlovice",
    "Tuchomerice",
    "Tupesy",
    "Turnov",
    "Tursko",
    "Tvarozna",
    "Tvrdonice",
    "Tynec nad Sazavou",
    "Tyniste nad Orlici",
    "Uhersky Brod",
    "Uhersky Ostroh",
    "Uhonice",
    "Uhrice",
    "Ujezd",
    "Ujezd",
    "Ujezd nad Lesy",
    "Unetice",
    "Unicov",
    "Usti nad Orlici",
    "Ustin",
    "Uvaly",
    "Uzice",
    "Vacenovice",
    "Valasske Klobouky",
    "Valasske Mezirici",
    "Valtice",
    "Vamberk",
    "Varnsdorf",
    "Vcelna",
    "Velehrad",
    "Velesin",
    "Velesovice",
    "Velka Bites",
    "Velka Bystrice",
    "Velka Dobra",
    "Velka Jesenice",
    "Velka Polom",
    "Velke Albrechtice",
    "Velke Brezno",
    "Velke Heraltice",
    "Velke Hydcice",
    "Velke Losiny",
    "Velke Mezirici",
    "Velke Opatovice",
    "Velke Popovice",
    "Velke Prilepy",
    "Velke Svatonovice",
    "Velky",
    "Velky Borek",
    "Velky Osek",
    "Veltrusy",
    "Velvary",
    "Vendryne",
    "Vernovice",
    "Veseli nad Luznici",
    "Veseli nad Moravou",
    "Veverska Bityska",
    "Veverske Kninice",
    "Vidce",
    "Vimperk",
    "Vinarice",
    "Vinicne Sumice",
    "Visnove",
    "Vitkov",
    "Vizovice",
    "Vlachovice",
    "Vladislav",
    "Vlasim",
    "Vlastislav",
    "Vlcovice",
    "Vochov",
    "Vodnany",
    "Vojkovice",
    "Volyne",
    "Votice",
    "Vracov",
    "Vrane nad Vltavou",
    "Vratimov",
    "Vratkov",
    "Vrbice",
    "Vrbno pod Pradedem",
    "Vrchlabi",
    "Vresina",
    "Vsechovice",
    "Vsechovice",
    "Vselibice",
    "Vsenory",
    "Vsestary",
    "Vsetaty",
    "Vsetin",
    "Vtelno",
    "Vyprachtice",
    "Vysker",
    "Vysoka",
    "Vysoka nad Labem",
    "Vysoke Myto",
    "Vysoke Veseli",
    "Zabcice",
    "Zabreh",
    "Zadni Treban",
    "Zajeci",
    "Zamberk",
    "Zampach",
    "Zandov",
    "Zasova",
    "Zatcany",
    "Zborovice",
    "Zbuch",
    "Zbysov",
    "Zdanice",
    "Zdeslav",
    "Zdiby",
    "Zdice",
    "Zdirec",
    "Zdounky",
    "Zehun",
    "Zelenec",
    "Zelenka",
    "Zelesice",
    "Zeletava",
    "Zeleznice",
    "Zelezny Brod",
    "Zeliv",
    "Zeravice",
    "Zidlochovice",
    "Zilina",
    "Zipotin",
    "Zizkov",
    "Zlata",
    "Zlate Hory",
    "Zlicin",
    "Zliv",
    "Znojmo",
    "Zubri",
    "Zulova",
    "Zvole"],
  },
  {
    name: "Denmark",
    cities: ["Aabenraa",
    "Aabybro",
    "Aalborg",
    "Aarhus",
    "Aars",
    "Abyhoj",
    "Agedrup",
    "Agerbaek",
    "Agerskov",
    "Akirkeby",
    "Albaek",
    "Albertslund",
    "Ale",
    "Alestrup",
    "Aller",
    "Allerod",
    "Allingabro",
    "Allinge",
    "Almind",
    "Alsgarde",
    "Alslev",
    "Alslev",
    "Anholt",
    "Ans",
    "Ansager",
    "Arden",
    "Arre",
    "Arslev",
    "Arslev",
    "Arup",
    "Asa",
    "Askeby",
    "Asnaes",
    "Asperup",
    "Assens",
    "Astrup",
    "Augustenborg",
    "Auning",
    "Avlum",
    "Baekke",
    "Baekmarksbro",
    "Baelum",
    "Bagsvaerd",
    "Balle",
    "Ballerup",
    "Bandholm",
    "Baring",
    "Barrit",
    "Beder",
    "Bedsted",
    "Bevtoft",
    "Billund",
    "Bjaert",
    "Bjaeverskov",
    "Bjerringbro",
    "Blavand",
    "Blokhus",
    "Blommenslyst",
    "Boeslunde",
    "Bogense",
    "Bogo By",
    "Bolderslev",
    "Bording Stationsby",
    "Borkop",
    "Borre",
    "Borum",
    "Borup",
    "Bovlingbjerg",
    "Bovrup",
    "Brabrand",
    "Braedstrup",
    "Bramming",
    "Brande",
    "Branderup",
    "Bredballe",
    "Bredebro",
    "Bredsten",
    "Brejning",
    "Brenderup",
    "Broager",
    "Brobyvaerk",
    "Brondby Strand",
    "Bronshoj",
    "Brorup",
    "Brovst",
    "Bruunshab",
    "Bryrup",
    "Bylderup-Bov",
    "Charlottenlund",
    "Christiansfeld",
    "Copenhagen",
    "Dalmose",
    "Dannemare",
    "Daugard",
    "Dianalund",
    "Dong",
    "Dronninglund",
    "Dronningmolle",
    "Dybvad",
    "Ebberup",
    "Ebeltoft",
    "Ega",
    "Egernsund",
    "Egtved",
    "Ejby",
    "Ejby",
    "Ejstrup",
    "Elsinore",
    "Engesvang",
    "Errindlev",
    "Erslev",
    "Esbjerg",
    "Eskebjerg",
    "Eskilstrup",
    "Espergaerde",
    "Faaborg",
    "Fakse",
    "Farevejle",
    "Farum",
    "Farup",
    "Farvang",
    "Fensmark",
    "Ferritslev",
    "Ferslev",
    "Fjenneslev",
    "Fjerritslev",
    "Flauenskjold",
    "Flemming",
    "Follenslev",
    "Fovling",
    "Fredensborg",
    "Fredericia",
    "Frederiksberg",
    "Frederiksberg",
    "Frederiksdal",
    "Frederikshavn",
    "Frederikssund",
    "Frorup",
    "Frostrup",
    "Fuglebjerg",
    "Gadbjerg",
    "Gadstrup",
    "Galten",
    "Gammel Svebolle",
    "Gandrup",
    "Ganlose",
    "Gedved",
    "Gelsted",
    "Gentofte",
    "Gesten",
    "Gilleleje",
    "Gislev",
    "Gislinge",
    "Gistrup",
    "Give",
    "Gjerlev",
    "Gjern",
    "Gjol",
    "Gladsaxe",
    "Glamsbjerg",
    "Glejbjerg",
    "Glesborg",
    "Glostrup",
    "Glumso",
    "Glyngore",
    "Gording",
    "Gorlose",
    "Graested",
    "Gram",
    "Gredstedbro",
    "Greve",
    "Greve Strand",
    "Grevinge",
    "Grindsted",
    "Gudbjerg",
    "Gudhjem",
    "Gudme",
    "Guldborg",
    "Haderslev",
    "Haderup",
    "Hadsten",
    "Hadsund",
    "Hals",
    "Hammel",
    "Hammershoj",
    "Hampen",
    "Hanstholm",
    "Harboore",
    "Harby",
    "Harlev",
    "Harlev",
    "Harndrup",
    "Harpelunde",
    "Hasle",
    "Haslev",
    "Hasselager",
    "Havdrup",
    "Havndal",
    "Hedehusene",
    "Hedensted",
    "Hejls",
    "Hejnsvig",
    "Hellebaek",
    "Hellerup",
    "Helsinge",
    "Hemmet",
    "Henne",
    "Henne Strand",
    "Herfolge",
    "Herlev",
    "Herlufmagle",
    "Herning",
    "Hesselager",
    "Himmelev",
    "Hinnerup",
    "Hirtshals",
    "Hjallerup",
    "Hjerm",
    "Hjortshoj",
    "Hobro",
    "Hodsager",
    "Hojbjerg",
    "Hojby",
    "Hoje Tastrup",
    "Hojslev",
    "Holbaek",
    "Holeby",
    "Holme",
    "Holme-Olstrup",
    "Holstebro",
    "Holsted",
    "Holte",
    "Hoptrup",
    "Horbelev",
    "Hornbaek",
    "Horning",
    "Hornslet",
    "Hornsyld",
    "Horsens",
    "Horslunde",
    "Horve",
    "Hosterkob",
    "Hou",
    "Hovedgard",
    "Humble",
    "Humlebaek",
    "Hundested",
    "Hundslund",
    "Hurup",
    "Hvide Sande",
    "Hvidovre",
    "Hyllinge",
    "Idestrup",
    "Ikast",
    "Janderup",
    "Jelling",
    "Jerslev",
    "Jerslev",
    "Jerup",
    "Jordrup",
    "Jorlunde",
    "Juelsminde",
    "Jyderup",
    "Jyllinge",
    "Jystrup",
    "Kaldred",
    "Kalundborg",
    "Kalvehave",
    "Karise",
    "Karlslunde",
    "Karlstrup",
    "Karrebaeksminde",
    "Karup",
    "Kastrup",
    "Kastrup",
    "Kejlstrup",
    "Kerteminde",
    "Kettinge",
    "Kibaek",
    "Kirke Eskilstrup",
    "Kirke Hvalso",
    "Kirke Saby",
    "Kirke-Hyllinge",
    "Kjargaard",
    "Kjellerup",
    "Klampenborg",
    "Klarup",
    "Klemensker",
    "Klippinge",
    "Klokkerholm",
    "Klovborg",
    "Knebel",
    "Kokkedal",
    "Koldby",
    "Kolding",
    "Kolind",
    "Kolsen",
    "Kongens Lyngby",
    "Kongerslev",
    "Krusa",
    "Kvaerndrup",
    "Kvistgard",
    "Kvols",
    "Laeso",
    "Lambjerg",
    "Langa",
    "Langeskov",
    "Lasby",
    "Laurbjerg",
    "Ledoje",
    "Lejre",
    "Lem",
    "Lemming",
    "Lemvig",
    "Lille Skensved",
    "Liseleje",
    "Logstrup",
    "Lokken",
    "Losning",
    "Lundby Stationsby",
    "Lunderskov",
    "Lyngby",
    "Lynge",
    "Lystrup",
    "Malling",
    "Malov",
    "Mariager",
    "Maribo",
    "Marslet",
    "Marslev",
    "Marstal",
    "Martofte",
    "Melby",
    "Mern",
    "Middelfart",
    "Millinge",
    "Moldrup",
    "Morke",
    "Morkov",
    "Morud",
    "Munke Bjergby",
    "Munkebo",
    "Naerum",
    "Naesby",
    "Naestved",
    "Nakskov",
    "Nexo",
    "Nibe",
    "Nim",
    "Nimtofte",
    "Niva",
    "No",
    "Nodebo",
    "Norager",
    "Nordborg",
    "Nordby",
    "Nordenskov",
    "Norre Aby",
    "Norre Asmindrup",
    "Norre Bindslev",
    "Norre Nebel",
    "Norre Snede",
    "Norreballe",
    "Norresundby",
    "Nyborg",
    "Nygard",
    "Nykobing Falster",
    "Nykobing Mors",
    "Nykobing Sjaelland",
    "Nysted",
    "Odder",
    "Oddesund Syd",
    "Odense",
    "Odsted",
    "Oksbol",
    "Olsted",
    "Olstykke",
    "Omme",
    "Orbaek",
    "Ornhoj",
    "Orsted",
    "Orum",
    "Osby",
    "Ostbirk",
    "Oster Assels",
    "Ostermarie",
    "Ostervra",
    "Otterup",
    "Oure",
    "Padborg",
    "Pandrup",
    "Ramlose",
    "Randbol",
    "Randers",
    "Ranum",
    "Rebaek",
    "Refsvindinge",
    "Regstrup",
    "Ribe",
    "Ringe",
    "Ringsted",
    "Risskov",
    "Rodding",
    "Rodkaersbro",
    "Rodvig",
    "Ronde",
    "Roskilde",
    "Roslev",
    "Rude",
    "Ruds-Vedby",
    "Rungsted",
    "Ry",
    "Rynkeby",
    "Ryomgard",
    "Ryslinge",
    "Sabro",
    "Saeby",
    "Salten",
    "Saltum",
    "Sandager",
    "Sanderum",
    "Sandved",
    "Sherrebek",
    "Silkeborg",
    "Sindal",
    "Sjolund",
    "Skaelskor",
    "Skaering",
    "Skagen",
    "Skals",
    "Skamby",
    "Skanderborg",
    "Skarup",
    "Skibby",
    "Skive",
    "Skjern",
    "Skodsborg",
    "Skodstrup",
    "Skovlunde",
    "Slagelse",
    "Slangerup",
    "Slet",
    "Snedsted",
    "Snekkersten",
    "Snertinge",
    "Soborg",
    "Soby",
    "Soesmarke",
    "Solbjerg",
    "Sollested",
    "Solrod",
    "Sommersted",
    "Sonder Omme",
    "Sonder Stenderup",
    "Sonderso",
    "Soro",
    "Sorring",
    "Sorvad",
    "Spentrup",
    "Spjald",
    "Spottrup",
    "Stakroge",
    "Stege",
    "Stenderup",
    "Stenlille",
    "Stenlose",
    "Stenstrup",
    "Stensved",
    "Stoholm",
    "Stokkemarke",
    "Store Fuglede",
    "Store Heddinge",
    "Store Merlose",
    "Storvorde",
    "Stouby",
    "Stovring",
    "Strandby",
    "Strib",
    "Stroby",
    "Struer",
    "Suldrup",
    "Sulsted",
    "Sundby",
    "Sunds",
    "Svaneke",
    "Svanninge",
    "Svendborg",
    "Svenstrup",
    "Svinninge",
    "Taastrup",
    "Tagerup",
    "Tappernoje",
    "Tarbaek",
    "Tarm",
    "Tars",
    "Tejn",
    "Terndrup",
    "Them",
    "Thisted",
    "Thorso",
    "Tikob",
    "Tilst",
    "Tim",
    "Tinglev",
    "Tistrup",
    "Tisvildeleje",
    "Tjaereborg",
    "Tjebberup",
    "Toftlund",
    "Tollose",
    "Tommerup",
    "Toreby",
    "Tornby",
    "Torrig",
    "Torring",
    "Torring",
    "Tranbjerg",
    "Tranebjerg",
    "Tranekaer",
    "Trelde",
    "Trige",
    "TRUE",
    "Trustrup",
    "Tullebolle",
    "Tune",
    "Tureby",
    "Tylstrup",
    "Ugerlose",
    "Uldum",
    "Ulfborg",
    "Ullerslev",
    "Ulstrup",
    "Vadum",
    "Vaeggerlose",
    "Vaerlose",
    "Vamdrup",
    "Vandel",
    "Vantinge",
    "Varde",
    "Vasby",
    "Vebbestrup",
    "Vedbaek",
    "Vederso",
    "Veflinge",
    "Vejby",
    "Vejen",
    "Vejle",
    "Vejstrup",
    "Vekso",
    "Vemb",
    "Vemmelev",
    "Verninge",
    "Vestbjerg",
    "Vester Hassing",
    "Vester-Skerninge",
    "Vesterborg",
    "Vestervig",
    "Viborg",
    "Viby",
    "Viby",
    "Vig",
    "Vildbjerg",
    "Vils",
    "Vinderup",
    "Vinkel",
    "Vipperod",
    "Virring",
    "Virum",
    "Vissenbjerg",
    "Viuf",
    "Vivild",
    "Vodskov",
    "Vojens",
    "Vorbasse",
    "Vordingborg",
    "Vra"],
  },
  {
    name: "Djibouti",
    cities: ["Djibouti", "Ali Sabieh", "Tadjoura"],
  },
  {
    name: "Dominica",
    cities: ["Roseau", "Portsmouth", "Marigot"],
  },
  {
    name: "Dominican Republic",
    cities: ["Arenazo",
    "Bavaro",
    "Boca Chica",
    "Cabarete",
    "Cotui",
    "Dominica",
    "Guaricano",
    "Hato Mayor del Rey",
    "Jimani",
    "La Romana",
    "Los Alcarrizos",
    "Los Prados",
    "Moca",
    "Pedernales",
    "Puerto Plata",
    "Punta Cana",
    "Sabaneta",
    "San Cristobal",
    "San Fernando de Monte Cristi",
    "San Jose de Ocoa",
    "Santa Cruz de Barahona",
    "Santiago de los Caballeros",
    "Santo Domingo"],
  },
  {
    name: "Ecuador",
    cities: ["Ambato",
    "Atacames",
    "Atuntaqui",
    "Bahia de Caraquez",
    "Banos",
    "Calderon",
    "Cayambe",
    "Cuenca",
    "Daule",
    "El Carmen",
    "El Naranjal",
    "Esmeraldas",
    "Florida",
    "General Leonidas Plaza Gutierrez",
    "Guabo",
    "Gualaceo",
    "Guamote",
    "Guayaquil",
    "Guayas",
    "Guayllabamba",
    "Hacienda Bolivia",
    "Hacienda Duran",
    "Hacienda Ibarra",
    "Hacienda Santa Catalina",
    "Huaquillas",
    "Ibarra",
    "Jipijapa",
    "La Esperanza",
    "La Libertad",
    "La Troncal",
    "Latacunga",
    "Loja",
    "Loreto",
    "Macara",
    "Macas",
    "Machachi",
    "Machala",
    "Manta",
    "Milagro",
    "Montecristi",
    "Nabon",
    "Olmedo",
    "Otavalo",
    "Paccha",
    "Pasaje",
    "Pelileo",
    "Pichincha",
    "Playas",
    "Portoviejo",
    "Puerto Francisco de Orellana",
    "Puyo",
    "Quevedo",
    "Quito",
    "Riobamba",
    "Salinas",
    "Samborondon",
    "San Miguel",
    "San Rafael",
    "Santa Cruz",
    "Santa Elena",
    "Santo Domingo de los Colorados",
    "Sucre",
    "Tababela",
    "Tarapoa",
    "Tena",
    "Vinces",
    "Zamora",
    "Zaruma",
    "Zumba"],
  },
  {
    name: "Egypt",
    cities: ["Abu Hammad",
    "Al Mahallah al Kubra",
    "Al Mansurah",
    "Al Marj",
    "Alexandria",
    "Almazah",
    "Ar Rawdah",
    "Assiut",
    "Az Zamalik",
    "Badr",
    "Banha",
    "Bani Suwayf",
    "Cairo",
    "Damietta",
    "Faraskur",
    "Flaminj",
    "Giza",
    "Heliopolis",
    "Helwan",
    "Hurghada",
    "Ismailia",
    "Kafr ash Shaykh",
    "Luxor",
    "Madinat an Nasr",
    "Madinat as Sadis min Uktubar",
    "Minya",
    "Nasr",
    "New Cairo",
    "Port Said",
    "Rafah",
    "Ramsis",
    "Sadat",
    "Shirbin",
    "Shubra",
    "Sohag",
    "Suez",
    "Tanta",
    "Toukh",
    "Zagazig"],
  },
  {
    name: "El Salvador",
    cities: ["Ahuachapan",
    "Antiguo Cuscatlan",
    "Apaneca",
    "Apopa",
    "Ayutuxtepeque",
    "Botoncillal El Botoncillo",
    "Coatepeque",
    "Colon",
    "Colonia Escalon",
    "Cuscatancingo",
    "Delgado",
    "Gigante",
    "Guazapa",
    "Ilopango",
    "La Libertad",
    "Lourdes",
    "Mejicanos",
    "Nuevo Cuscatlan",
    "Ozatlan",
    "San Jose Villanueva",
    "San Marcos",
    "San Miguel",
    "San Salvador",
    "Santa Ana",
    "Santa Tecla",
    "Santiago Texacuangos",
    "Sonsonate",
    "Soyapango",
    "Usulutan",
    "Zaragoza"],
  },
  {
    name: "Equatorial Guinea",
    cities: ["Malabo", "Bata", "Ebebiyin"],
  },
  {
    name: "Eritrea",
    cities: ["Asmara", "Keren", "Massawa"],
  },
  {
    name: "Estonia",
    cities: ["Aasmae",
    "Aaviku",
    "Aespa",
    "Ahtma",
    "Alliku",
    "Ambla",
    "Antsla",
    "Ardu",
    "Avinurme",
    "Elva",
    "Emmaste",
    "Haabneeme",
    "Haage",
    "Haapsalu",
    "Halinga",
    "Harju",
    "Harku",
    "Kaarma",
    "Kadrina",
    "Kehra",
    "Keila",
    "Kiili",
    "Kohila",
    "Kohtla",
    "Kose",
    "Kulna",
    "Kuressaare",
    "Kuusalu",
    "Laagri",
    "Lagedi",
    "Lahtse",
    "Lepna",
    "Loksa",
    "Loo",
    "Lubja",
    "Maardu",
    "Muraste",
    "Narva",
    "Otepaeae",
    "Paide",
    "Paikuse",
    "Paldiski",
    "Palivere",
    "Peetri",
    "Puhja",
    "Rakke",
    "Rakvere",
    "Rapla",
    "Saku",
    "Saue",
    "Sindi",
    "Somerpalu",
    "Syare",
    "Tabasalu",
    "Tabivere",
    "Tallinn",
    "Tamsalu",
    "Tapa",
    "Tartu",
    "Toila",
    "Vaida",
    "Valga",
    "Vara",
    "Venevere",
    "Viimsi",
    "Viljandi",
    "Voka"],
  },
  {
    name: "Eswatini",
    cities: ["Mbabane", "Manzini", "Lobamba"],
  },
  {
    name: "Ethiopia",
    cities: ["Addis Ababa", "Dire Dawa", "Mekelle"],
  },
  {
    name: "Fiji",
    cities: ["Suva", "Nadi", "Lautoka"],
  },
  {
    name: "Finland",
    cities: ["Aapajoki",
    "Aavasaksa",
    "Aitoo",
    "Akaa",
    "Alastaro",
    "Alaveteli",
    "Alavieska",
    "Alavus",
    "Alvettula",
    "Angelniemi",
    "Anjala",
    "Anttila",
    "Askola",
    "Aura",
    "Bergoe",
    "Box",
    "Broby",
    "Degerby",
    "Eno",
    "Erajarvi",
    "Espoo",
    "Eura",
    "Eurajoki",
    "Evijaervi",
    "Evitskog",
    "Fiskars",
    "Forsby",
    "Forssa",
    "Gammelby",
    "Haapavesi",
    "Haeme",
    "Haermae",
    "Haijaa",
    "Halikko",
    "Halkia",
    "Halli",
    "Halsua",
    "Hamina",
    "Hammaslahti",
    "Hanko",
    "Harjavalta",
    "Harviala",
    "Hattula",
    "Hauho",
    "Haukipudas",
    "Haukkala",
    "Hausjaervi",
    "Heimari",
    "Heinola",
    "Helsingby",
    "Helsinki",
    "Hervanta",
    "Hiltulanlahti",
    "Himanka",
    "Hinthaara",
    "Hirvensalmi",
    "Hirvihaara",
    "Hoegsara",
    "Hoeytiae",
    "Hollola",
    "Hollola",
    "Huittinen",
    "Humppila",
    "Huuvari",
    "Hyvinkaeae",
    "Ihode",
    "Ii",
    "Iisalmi",
    "Iittala",
    "Ilkka",
    "Ilmajoki",
    "Ilmarinen",
    "Ilveskylae",
    "Imatra",
    "Inkeroinen",
    "Isojoki",
    "Itaekylae",
    "Ivalo",
    "Jaervelae",
    "Jakobstad",
    "Janakkala",
    "Joensuu",
    "Jokela",
    "Jokikunta",
    "Jokimaa",
    "Jokioinen",
    "Jormua",
    "Joroinen",
    "Jorvas",
    "Joutseno",
    "Jurva",
    "Juuka",
    "Juupajoki",
    "Jyskae",
    "Kaakamo",
    "Kaarina",
    "Kaemmenniemi",
    "Kaerrby",
    "Kaipiainen",
    "Kaipola",
    "Kaivanto",
    "Kajaani",
    "Kalajoki",
    "Kalanti",
    "Kangasala",
    "Kangasniemi",
    "Kankaanpaeae",
    "Kannonkoski",
    "Kannus",
    "Kantomaanpaeae",
    "Kantti",
    "Kantvik",
    "Karhula",
    "Karinkanta",
    "Karjaa",
    "Karjalohja",
    "Karkkila",
    "Karkku",
    "Karstula",
    "Karttula",
    "Karunki",
    "Karvia",
    "Kaskinen",
    "Kauhajoki",
    "Kauhakorpi",
    "Kauhava",
    "Kauniainen",
    "Kausala",
    "Kaustinen",
    "Kauttua",
    "Kauvatsa",
    "Kello",
    "Kellokoski",
    "Kemi",
    "Keminmaa",
    "Kempele",
    "Kerava",
    "Kerkkoo",
    "Keuruu",
    "Kihnioe",
    "Kiiminki",
    "Kiistala",
    "Kiljava",
    "Kimito",
    "Kinnula",
    "Kirjala",
    "Kirkkonummi",
    "Kisko",
    "Kitee",
    "Kiuruvesi",
    "Kivijaervi",
    "Klaukkala",
    "Kokkola",
    "Kokkola",
    "Kolari",
    "Kolinkylae",
    "Koljonvirta",
    "Kontiolahti",
    "Kontiomaeki",
    "Kopsa",
    "Koria",
    "Kormu",
    "Korplax",
    "Korppoo",
    "Korsholm",
    "Koskenkorva",
    "Koskioinen",
    "Kotka",
    "Kouvola",
    "Kronoby",
    "Kruusila",
    "Kuhmalahti",
    "Kuhmo",
    "Kuivakangas",
    "Kuivaniemi",
    "Kulho",
    "Kuni",
    "Kuohu",
    "Kuopio",
    "Kuortane",
    "Kurikka",
    "Kuusamo",
    "Kuusankoski",
    "Kuusisto",
    "Kvevlax",
    "Kylmaekoski",
    "Kyroeskoski",
    "Kyynaeroe",
    "Laeyliaeinen",
    "Lahela",
    "Lahti",
    "Laihia",
    "Laikko",
    "Laitikkala",
    "Laitila",
    "Lammi",
    "Lampinsaari",
    "Lapinlahti",
    "Lappajaervi",
    "Lappeenranta",
    "Lapua",
    "Larsmo",
    "Latovainio",
    "Laukaa",
    "Laukkoski",
    "Launonen",
    "Lautiosaari",
    "Lehmo",
    "Lehtimaeki",
    "Lemi",
    "Lepaa",
    "Lepplax",
    "Levanto",
    "Lieksa",
    "Lielax",
    "Lieto",
    "Lievestuore",
    "Liljendal",
    "Liminka",
    "Linna",
    "Linnavuori",
    "Liperi",
    "Littoinen",
    "Lohja",
    "Lohtaja",
    "Loimaa",
    "Loppi",
    "Louhi",
    "Loviisa",
    "Lumijoki",
    "Luohua",
    "Luoma-aho",
    "Maenttae",
    "Maentyharju",
    "Majvik",
    "Mankila",
    "Mantila",
    "Martensby",
    "Marttila",
    "Masala",
    "Masku",
    "Mattila",
    "Mehtaekylae",
    "Merimasku",
    "Metsaekansa",
    "Mieto",
    "Mikkeli",
    "Mouhijaervi",
    "Muhos",
    "Multia",
    "Munsala",
    "Muonio",
    "Muurame",
    "Muurla",
    "Myllykoski",
    "Mynaemaeki",
    "Naantali",
    "Naervijoki",
    "Nahkela",
    "Nakkila",
    "Narva",
    "Nastola",
    "Neuvoton",
    "Niinijoki",
    "Niittylahti",
    "Nivala",
    "Nokia",
    "Noormarkku",
    "Nousiainen",
    "Nukari",
    "Nummela",
    "Nummenkylae",
    "Nummenkylae",
    "Nummi",
    "Nunnanlahti",
    "Nuppulinna",
    "Nurmes",
    "Nurmo",
    "Nuutajaervi",
    "Nykarleby",
    "OEvermalax",
    "Oitti",
    "Oja",
    "Okskoski",
    "Orimattila",
    "Orivesi",
    "Otalampi",
    "Oulainen",
    "Oulu",
    "Oulunsalo",
    "Outokumpu",
    "Paalijaervi",
    "Paattinen",
    "Padasjoki",
    "Paelkaene",
    "Paimio",
    "Paippinen",
    "Palojoki",
    "Palokka",
    "Pankakoski",
    "Pargas",
    "Parkano",
    "Parkkuu",
    "Pattijoki",
    "Peipohja",
    "Pellinki",
    "Pello",
    "Peraelae",
    "Peraseinajoki",
    "Pernioe",
    "Pertteli",
    "Perttula",
    "Pihtipudas",
    "Piispanristi",
    "Pilkanmaa",
    "Pirkkala",
    "Pjelax",
    "Pockar",
    "Poeytyae",
    "Pohjola",
    "Poikelus",
    "Poitsila",
    "Pori",
    "Porlammi",
    "Pornainen",
    "Porvoo",
    "Pukkila",
    "Punkaharju",
    "Punkalaidun",
    "Puolanka",
    "Purmo",
    "Purola",
    "Pusula",
    "Puumala",
    "Pyhaejoki",
    "Pyhaentae",
    "Pyhamaa",
    "Raahe",
    "Raisio",
    "Rantasalmi",
    "Raseborg",
    "Rauma",
    "Rautalampi",
    "Rautjaervi",
    "Rautjaervi",
    "Ravijoki",
    "Revonlahti",
    "Riihikoski",
    "Riistavesi",
    "Ristiina",
    "Roeykkae",
    "Roismala",
    "Rovaniemi",
    "Ruokolahti",
    "Ruovesi",
    "Rusko",
    "Ruto",
    "Ruutana",
    "Ryttylae",
    "Saaksmaki",
    "Saarijaervi",
    "Saekylae",
    "Saeviae",
    "Sahalahti",
    "Sajaniemi",
    "Salo",
    "Saloinen",
    "Salomaa",
    "Sammaljoki",
    "Sappee",
    "Saukkola",
    "Saunalahti",
    "Sauvo",
    "Savitaipale",
    "Savonlinna",
    "Saynatsalo",
    "Seinaejoki",
    "Sievi",
    "Siikainen",
    "Siivikkala",
    "Simoniemi",
    "Simpele",
    "Sipoo",
    "Siuntio",
    "Siuro",
    "Skinnarby",
    "Skuru",
    "Soederkulla",
    "Soeoermarkku",
    "Solberg",
    "Somerniemi",
    "Somero",
    "Sotkamo",
    "Sotkuma",
    "Suhmura",
    "Sundby",
    "Sundom",
    "Suolahti",
    "Suomusjaervi",
    "Suomussalmi",
    "Suonenjoki",
    "Svarta",
    "Sykaeraeinen",
    "Taavetti",
    "Taipalsaari",
    "Tammela",
    "Tampella",
    "Tampere",
    "Tarttila",
    "Tarvasjoki",
    "Tenala",
    "Terjaerv",
    "Tervajoki",
    "Tervakoski",
    "Tervalampi",
    "Teuva",
    "Tikkakoski",
    "Toelby",
    "Toholampi",
    "Toijala",
    "Toivala",
    "Tolkis",
    "Tornio",
    "Tortinmaeki",
    "Tottijarvi",
    "Tuomikylae",
    "Turenki",
    "Turku",
    "Tuulos",
    "Tuuri",
    "Tuusula",
    "Tyrnaevae",
    "Uimaharju",
    "Ulvila",
    "Utsjoki",
    "Utti",
    "Uurainen",
    "Uusikaupunki",
    "Vaajakoski",
    "Vaasa",
    "Vaeaeksy",
    "Vaesterskog",
    "Vahto",
    "Vakkola",
    "Valkama",
    "Valkeakoski",
    "Valkeala",
    "Valkola",
    "Vammala",
    "Vanhalinna",
    "Vantaa",
    "Varkaus",
    "Vasarainen",
    "Vaskio",
    "Vaulammi",
    "Vehniae",
    "Veikkola",
    "Venetheitto",
    "Vesanka",
    "Veteli",
    "Viantie",
    "Vihtavuori",
    "Vihti",
    "Viiala",
    "Viitasaari",
    "Viljakkala",
    "Vilppula",
    "Vimpeli",
    "Virrat",
    "Vuokatti",
    "Ylaemylly",
    "Ylihaermae",
    "Ylitornio",
    "Ylivieska",
    "Ypaejae"],
  },
  {
    name: "France",
    cities: ["Aast",
    "Abancourt",
    "Abbans-Dessus",
    "Abbaretz",
    "Abbecourt",
    "Abbeville",
    "Abbeville-les-Conflans",
    "Abeilhan",
    "Abere",
    "Abergement-Saint-Jean",
    "Abilly",
    "Ablain-Saint-Nazaire",
    "Ablaincourt-Pressoir",
    "Ablainzevelle",
    "Ablis",
    "Ablon-sur-Seine",
    "Abondance",
    "Abondant",
    "Abreschviller",
    "Abrest",
    "Abscon",
    "Abzac",
    "Abzac",
    "Achatel",
    "Achenheim",
    "Acheres",
    "Acheville",
    "Achicourt",
    "Achiet-le-Grand",
    "Achiet-le-Petit",
    "Acigne",
    "Acq",
    "Acquigny",
    "Acy-en-Multien",
    "Adam-les-Vercel",
    "Adamswiller",
    "Adelans-et-le-Val-de-Bithaine",
    "Adissan",
    "Adriers",
    "Afa",
    "Agay",
    "Agde",
    "Agen",
    "Agneaux",
    "Agnetz",
    "Agnin",
    "Agnos",
    "Agny",
    "Aguilcourt",
    "Ahaxe-Alciette-Bascassan",
    "Ahuille",
    "Ahun",
    "Ahuy",
    "Aiffres",
    "Aigaliers",
    "Aiglemont",
    "Aignan",
    "Aigne",
    "Aigny",
    "Aigonnay",
    "Aigre",
    "Aigrefeuille",
    "Aigrefeuille-sur-Maine",
    "Aigremont",
    "Aigremont",
    "Aiguebelette-le-Lac",
    "Aiguebelle",
    "Aigueblanche",
    "Aiguefonde",
    "Aigueperse",
    "Aigueperse",
    "Aigues-Mortes",
    "Aigues-Vives",
    "Aigues-Vives",
    "Aiguillon",
    "Ailette",
    "Aillas",
    "Aillevillers-et-Lyaumont",
    "Aillon-le-Vieux",
    "Ailly-le-Haut-Clocher",
    "Ailly-sur-Noye",
    "Ailly-sur-Somme",
    "Aimargues",
    "Aime",
    "Ainay-le-Chateau",
    "Aincourt",
    "Ainhoa",
    "Airaines",
    "Aire-sur-la-Lys",
    "Airel",
    "Airvault",
    "Aiserey",
    "Aissey",
    "Aiton",
    "Aix-en-Ergny",
    "Aix-en-Issart",
    "Aix-en-Othe",
    "Aix-en-Provence",
    "Aix-les-Bains",
    "Aix-Noulette",
    "Aixe-sur-Vienne",
    "Aizenay",
    "Ajaccio",
    "Alairac",
    "Alata",
    "Alban",
    "Albas",
    "Albefeuille-Lagarde",
    "Albens",
    "Albert",
    "Albertville",
    "Albi",
    "Albias",
    "Albigny-sur-Saone",
    "Albiosc",
    "Alboussiere",
    "Albussac",
    "Alby-sur-Cheran",
    "Alenya",
    "Alfortville",
    "Algolsheim",
    "Algrange",
    "Alignan-du-Vent",
    "Alissas",
    "Alix",
    "Alixan",
    "Alizay",
    "Allain",
    "Allaire",
    "Allan",
    "Allassac",
    "Allauch",
    "Allegre",
    "Alleins",
    "Allemans-du-Dropt",
    "Allennes-les-Marais",
    "Alleriot",
    "Allery",
    "Allevard",
    "Alliancelles",
    "Allier",
    "Allieres",
    "Allinges",
    "Allogny",
    "Allondrelle-la-Malmaison",
    "Allonne",
    "Allonne",
    "Allonnes",
    "Allonnes",
    "Allonville",
    "Allonzier-la-Caille",
    "Allos",
    "Allouagne",
    "Alloue",
    "Allouis",
    "Allouville-Bellefosse",
    "Alluy",
    "Alluyes",
    "Ally",
    "Alos-Sibas-Abense",
    "Alteckendorf",
    "Altkirch",
    "Altorf",
    "Altviller",
    "Alvimare",
    "Alzonne",
    "Amagney",
    "Amailloux",
    "Amancey",
    "Amancy",
    "Amange",
    "Amanlis",
    "Amanvillers",
    "Amathay-Vesigneux",
    "Amaye-sur-Orne",
    "Ambazac",
    "Amberac",
    "Amberieux",
    "Amberieux-en-Dombes",
    "Ambert",
    "Ambes",
    "Ambierle",
    "Ambleny",
    "Ambleteuse",
    "Amboise",
    "Ambon",
    "Ambonnay",
    "Ambres",
    "Ambrieres",
    "Ambronay",
    "Ambrus",
    "Ambutrix",
    "Amelecourt",
    "Amelie-les-Bains-Palalda",
    "Amen",
    "Ames",
    "Amfreville",
    "Amfreville-la-Mi-Voie",
    "Amfreville-sous-les-Monts",
    "Amiens",
    "Amigny",
    "Amigny-Rouy",
    "Amillis",
    "Amilly",
    "Ammerschwihr",
    "Ammerzwiller",
    "Amneville",
    "Amou",
    "Amplepuis",
    "Amponville",
    "Ampuis",
    "Ampus",
    "Amure",
    "Anais",
    "Ance",
    "Anceaumeville",
    "Ancemont",
    "Ancenis",
    "Ancerville",
    "Anche",
    "Anchenoncourt-et-Chazel",
    "Ancinnes",
    "Ancizan",
    "Ancone",
    "Ancy",
    "Ancy-le-Franc",
    "Ancy-sur-Moselle",
    "Andance",
    "Andancette",
    "Andard",
    "Ande",
    "Andechy",
    "Andel",
    "Andelot-Morval",
    "Andernos-les-Bains",
    "Andeville",
    "Andigne",
    "Andilly",
    "Andilly",
    "Andilly",
    "Andoins",
    "Andolsheim",
    "Andon",
    "Andonville",
    "Andouille",
    "Andouille-Neuville",
    "Andres",
    "Andrest",
    "Andresy",
    "Andreze",
    "Andrezieux-Boutheon",
    "Anduze",
    "Anet",
    "Anetz",
    "Ange",
    "Angeac-Champagne",
    "Angeac-Charente",
    "Angely",
    "Angers",
    "Angerville-la-Martel",
    "Angervilliers",
    "Angevillers",
    "Angicourt",
    "Angiens",
    "Anglade",
    "Anglefort",
    "Angles",
    "Anglet",
    "Angliers",
    "Anglure",
    "Angoulins",
    "Angoume",
    "Angoville-sur-Ay",
    "Angres",
    "Anguerny",
    "Angy",
    "Anhaux",
    "Anhiers",
    "Aniane",
    "Aniche",
    "Anizy-le-Chateau",
    "Anjou",
    "Anjoutey",
    "Anlhiac",
    "Annay",
    "Annay-sur-Serein",
    "Annebault",
    "Annecy",
    "Annecy-le-Vieux",
    "Annemasse",
    "Annepont",
    "Annequin",
    "Annesse-et-Beaulieu",
    "Annet-sur-Marne",
    "Anneville-Ambourville",
    "Anneyron",
    "Annezay",
    "Annezin",
    "Annoeullin",
    "Annois",
    "Annonay",
    "Annot",
    "Annouville-Vilmesnil",
    "Anor",
    "Anould",
    "Ansac-sur-Vienne",
    "Ansauvillers",
    "Anse",
    "Ansouis",
    "Antagnac",
    "Anteuil",
    "Anthe",
    "Antheuil-Portes",
    "Anthon",
    "Anthy-sur-Leman",
    "Antibes",
    "Antignac",
    "Antignac",
    "Antigny",
    "Antilly",
    "Antilly",
    "Antogny le Tillac",
    "Antony",
    "Antrain",
    "Anzat-le-Luguet",
    "Anzin",
    "Anzin-Saint-Aubin",
    "Anzy-le-Duc",
    "Aoste",
    "Aougny",
    "Aouste-sur-Sye",
    "Apach",
    "Apcher",
    "Apcher",
    "Appenwihr",
    "Appeville",
    "Appeville-Annebault",
    "Appietto",
    "Appoigny",
    "Apprieu",
    "Apremont",
    "Apremont",
    "Apt",
    "Araches-la-Frasse",
    "Aragon",
    "Aramits",
    "Aramon",
    "Arancou",
    "Arbent",
    "Arberats-Sillegue",
    "Arbin",
    "Arbois",
    "Arbonne",
    "Arboucave",
    "Arbus",
    "Arbusigny",
    "Arc-et-Senans",
    "Arc-sur-Tille",
    "Arcachon",
    "Arcay",
    "Arcens",
    "Arces",
    "Archamps",
    "Arche",
    "Arches",
    "Archettes",
    "Archiac",
    "Archingeay",
    "Arcine",
    "Arcis-sur-Aube",
    "Arcon",
    "Arcon",
    "Arcueil",
    "Arcy-Sainte-Restitue",
    "Ardenay-sur-Merize",
    "Ardentes",
    "Ardes",
    "Ardiege",
    "Ardillieres",
    "Ardoix",
    "Ardon",
    "Ardres",
    "Areines",
    "Arengosse",
    "Arenthon",
    "Ares",
    "Aressy",
    "Arette",
    "Argancy",
    "Argelers",
    "Argeles-Gazost",
    "Argeliers",
    "Argelliers",
    "Argences",
    "Argens",
    "Argent-sur-Sauldre",
    "Argentan",
    "Argentat",
    "Argenteuil",
    "Argentiere",
    "Argentine",
    "Argenton-les-Vallees",
    "Argenton-sur-Creuse",
    "Argentre",
    "Argentre-du-Plessis",
    "Argeville",
    "Argol",
    "Argueil",
    "Arinthod",
    "Arlanc",
    "Arlay",
    "Arlebosc",
    "Arles",
    "Arles",
    "Arleux",
    "Arleux-en-Gohelle",
    "Armaucourt",
    "Armbouts-Cappel",
    "Armenonville-les-Gatineaux",
    "Armes",
    "Armoy",
    "Arnac-Pompadour",
    "Arnage",
    "Arnas",
    "Arnay-le-Duc",
    "Arnieres-sur-Iton",
    "Arnouville",
    "Arnouville-les-Mantes",
    "Aron",
    "Arpaillargues-et-Aureillac",
    "Arpajon",
    "Arpajon-sur-Cere",
    "Arpenans",
    "Arpheuilles-Saint-Priest",
    "Arques",
    "Arques-la-Bataille",
    "Arquettes-en-Val",
    "Arrabloy",
    "Arradon",
    "Arras",
    "Arraute-Charritte",
    "Arreau",
    "Arrenes",
    "Arrigas",
    "Arrigny",
    "Arrou",
    "Ars",
    "Ars-en-Re",
    "Ars-Laquenexy",
    "Ars-sur-Formans",
    "Ars-sur-Moselle",
    "Arsac",
    "Arsac-en-Velay",
    "Arsague",
    "Artannes-sur-Indre",
    "Artas",
    "Artenay",
    "Arthaz-Pont-Notre-Dame",
    "Arthes",
    "Arthez-de-Bearn",
    "Arthon",
    "Arthon-en-Retz",
    "Artigat",
    "Artigueloutan",
    "Artiguelouve",
    "Artigues",
    "Artigues-pres-Bordeaux",
    "Artix",
    "Artolsheim",
    "Arudy",
    "Arvert",
    "Arveyres",
    "Arvillard",
    "Arville",
    "Arvillers",
    "Arx",
    "Arzacq-Arraziguet",
    "Arzal",
    "Arzano",
    "Arzon",
    "Asasp-Arros",
    "Ascain",
    "Ascheres-le-Marche",
    "Ascoux",
    "Ascq",
    "Aslonnes",
    "Asnans-Beauvoisin",
    "Asnieres",
    "Asnieres-sur-Oise",
    "Asnieres-sur-Seine",
    "Aspach-le-Bas",
    "Aspach-le-Haut",
    "Asperes",
    "Aspet",
    "Aspremont",
    "Asque",
    "Assas",
    "Assat",
    "Assay",
    "Assevent",
    "Assier",
    "Assignan",
    "Asson",
    "Astaffort",
    "Astille",
    "Astis",
    "Athee",
    "Athesans-Etroitefontaine",
    "Athies",
    "Athies-sous-Laon",
    "Athis-Mons",
    "Attenschwiller",
    "Attiches",
    "Attichy",
    "Attignat-Oncin",
    "Attigny",
    "Atton",
    "Atur",
    "Aubagne",
    "Aubais",
    "Aubas",
    "Aubenas",
    "Auberchicourt",
    "Aubergenville",
    "Auberives-sur-Vareze",
    "Aubers",
    "Auberville-la-Campagne",
    "Auberville-la-Renault",
    "Aubervilliers",
    "Aubevoye",
    "Aubiac",
    "Aubie-et-Espessas",
    "Aubiere",
    "Aubiet",
    "Aubignan",
    "Aubigne",
    "Aubigne-Racan",
    "Aubigney",
    "Aubigny",
    "Aubigny-au-Bac",
    "Aubigny-en-Artois",
    "Aubigny-en-Laonnois",
    "Aubigny-en-Plaine",
    "Aubin",
    "Aubin",
    "Aubin-Saint-Vaast",
    "Aubinges",
    "Aubord",
    "Auboue",
    "Aubrac",
    "Aubrives",
    "Aubry-du-Hainaut",
    "Aubusson",
    "Auby",
    "Aucamville",
    "Auch",
    "Auchel",
    "Auchy-les-Hesdin",
    "Auchy-les-Mines",
    "Auchy-lez-Orchies",
    "Audelange",
    "Audenge",
    "Audes",
    "Audierne",
    "Audignies",
    "Audincourt",
    "Audincthun",
    "Audruicq",
    "Audun-le-Roman",
    "Audun-le-Tiche",
    "Auffargis",
    "Auffay",
    "Auffreville-Brasseuil",
    "Auga",
    "Augignac",
    "Augny",
    "Aulhat-Saint-Privat",
    "Aulnat",
    "Aulnay",
    "Aulnay-sous-Bois",
    "Aulnay-sur-Marne",
    "Aulnay-sur-Mauldre",
    "Aulnoye-Aymeries",
    "Ault",
    "Aumagne",
    "Aumale",
    "Aumenancourt",
    "Aumetz",
    "Aumont",
    "Aumont-Aubrac",
    "Aumont-en-Halatte",
    "Aumontzey",
    "Aumur",
    "Aunay-les-Bois",
    "Aunay-sous-Auneau",
    "Aunay-sur-Odon",
    "Auneau",
    "Auneuil",
    "Aups",
    "Auray",
    "Aurec-sur-Loire",
    "Aureil",
    "Aureilhan",
    "Aureilhan",
    "Auriac",
    "Auriac",
    "Auriac-sur-Vendinelle",
    "Auribeau-sur-Siagne",
    "Aurignac",
    "Aurillac",
    "Auriol",
    "Aurons",
    "Aussevielle",
    "Aussillon",
    "Aussonce",
    "Aussonne",
    "Autechaux",
    "Auterive",
    "Auterive",
    "Auteuil",
    "Auteuil",
    "Autevielle-Saint-Martin-Bideren",
    "Authevernes",
    "Authie",
    "Authie",
    "Authon",
    "Authou",
    "Authouillet",
    "Autoreille",
    "Autrans",
    "Autreche",
    "Autremencourt",
    "Autreville",
    "Autry-le-Chatel",
    "Autun",
    "Auvergny",
    "Auvers",
    "Auvers-Saint-Georges",
    "Auvers-sur-Oise",
    "Auvillar",
    "Auvillars",
    "Auvillers-les-Forges",
    "Auvilliers-en-Gatinais",
    "Auxais",
    "Auxances",
    "Auxange",
    "Auxerre",
    "Auxey-Duresses",
    "Auxi-le-Chateau",
    "Auxon",
    "Auxon-Dessous",
    "Auxon-Dessus",
    "Auxonne",
    "Auxy",
    "Auzances",
    "Auzeville-Tolosane",
    "Auzielle",
    "Auzon",
    "Auzouer-en-Touraine",
    "Availles-Limouzine",
    "Avallon",
    "Avancon",
    "Avanne-Aveney",
    "Avanton",
    "Avaray",
    "Aveize",
    "Aveizieux",
    "Avelin",
    "Aveluy",
    "Avenay",
    "Avensan",
    "Aventignan",
    "Averdoingt",
    "Averdon",
    "Averton",
    "Avesnelles",
    "Avesnes-en-Bray",
    "Avesnes-le-Comte",
    "Avesnes-le-Sec",
    "Avesnes-les-Aubert",
    "Avesnes-sur-Helpe",
    "Aveze",
    "Aveze",
    "Aviernoz",
    "Avignon",
    "Avignonet-Lauragais",
    "Avion",
    "Aviron",
    "Avize",
    "Avoine",
    "Avon",
    "Avon-la-Peze",
    "Avord",
    "Avot",
    "Avrainville",
    "Avranches",
    "Avremesnil",
    "Avressieux",
    "Avril",
    "Avrille",
    "Ax-les-Thermes",
    "Aydat",
    "Ayencourt",
    "Ayette",
    "Ayguemorte-les-Graves",
    "Ayguesvives",
    "Aymeries",
    "Aynac",
    "Ayrens",
    "Ayse",
    "Aytre",
    "Azas",
    "Azay-le-Brule",
    "Azay-le-Rideau",
    "Azay-sur-Cher",
    "Azay-sur-Indre",
    "Aze",
    "Aze",
    "Azerables",
    "Azerailles",
    "Azet",
    "Azille",
    "Azur",
    "Azy-sur-Marne",
    "Babeau-Bouldoux",
    "Baccarat",
    "Bach",
    "Bachant",
    "Bachy",
    "Bacilly",
    "Baconnes",
    "Bacqueville",
    "Bacqueville-en-Caux",
    "Baden",
    "Badevel",
    "Bagard",
    "Bagas",
    "Bage-la-Ville",
    "Bages",
    "Bagnac-sur-Cele",
    "Bagneaux",
    "Bagneaux-sur-Loing",
    "Bagneux",
    "Bagnolet",
    "Bagnols",
    "Bagnols",
    "Bagnols-en-Foret",
    "Bagnols-sur-Ceze",
    "Baguer-Morvan",
    "Baguer-Pican",
    "Baho",
    "Baignes-Sainte-Radegonde",
    "Baigneux-les-Juifs",
    "Baillargues",
    "Bailleau-le-Pin",
    "Baillet-en-France",
    "Bailleul",
    "Bailleul-le-Soc",
    "Bailleul-Sir-Berthoult",
    "Bailleul-sur-Therain",
    "Bailly",
    "Bailly",
    "Bailly-Carrois",
    "Bailly-le-Franc",
    "Bailly-Romainvilliers",
    "Bain-de-Bretagne",
    "Bains",
    "Bains-les-Bains",
    "Bains-sur-Oust",
    "Bainville-sur-Madon",
    "Baisieux",
    "Baixas",
    "Bajus",
    "Balagny-sur-Therain",
    "Balan",
    "Balanod",
    "Balaruc-le-Vieux",
    "Balaruc-les-Bains",
    "Balaze",
    "Balbigny",
    "Baldenheim",
    "Baldersheim",
    "Balgau",
    "Ballainvilliers",
    "Ballaison",
    "Ballan-Mire",
    "Ballancourt-sur-Essonne",
    "Balleroy",
    "Ballon",
    "Ballon",
    "Balma",
    "Balschwiller",
    "Balzac",
    "Ban-de-Laveline",
    "Banassac",
    "Bandol AOC",
    "Bangor",
    "Banize",
    "Bannalec",
    "Banne",
    "Bannes",
    "Bannieres",
    "Banon",
    "Bantigny",
    "Bantzenheim",
    "Banville",
    "Banvou",
    "Banyuls de la Marenda",
    "Banyuls-dels-Aspres",
    "Bapaume",
    "Bar-le-Duc",
    "Bar-sur-Aube",
    "Bar-sur-Seine",
    "Baraqueville",
    "Barastre",
    "Barbaira",
    "Barbaste",
    "Barbatre",
    "Barbazan-Debat",
    "Barbechat",
    "Barbentane",
    "Barberaz",
    "Barbery",
    "Barbeville",
    "Barbezieux-Saint-Hilaire",
    "Barbieres",
    "Barbizon",
    "Barby",
    "Barc",
    "Barcelonnette",
    "Bardos",
    "Barembach",
    "Barentin",
    "Barenton-Bugny",
    "Barfleur",
    "Barges",
    "Barinque",
    "Barisey-au-Plain",
    "Barisey-la-Cote",
    "Barjac",
    "Barjols",
    "Barjon",
    "Barjouville",
    "Barlin",
    "Barneville-Carteret",
    "Baron",
    "Baron",
    "Baronville",
    "Barr",
    "Barraux",
    "Barreme",
    "Barro",
    "Barrou",
    "Bartenheim",
    "Barzy-en-Thierache",
    "Bas-en-Basset",
    "Bascons",
    "Bassan",
    "Basse-Goulaine",
    "Basse-Ham",
    "Basse-Rentgen",
    "Basse-sur-le-Rupt",
    "Bassemberg",
    "Bassens",
    "Bassens",
    "Bassigney",
    "Bassillac",
    "Bassoles-Aulers",
    "Bassou",
    "Bassu",
    "Bassussarry",
    "Bastennes",
    "Bastia",
    "Batilly",
    "Batilly-en-Gatinais",
    "Battenheim",
    "Battrans",
    "Batz-sur-Mer",
    "Batzendorf",
    "Baud",
    "Baudemont",
    "Baudreix",
    "Baulay",
    "Baule",
    "Baulne",
    "Baulon",
    "Baume-les-Dames",
    "Baume-les-Messieurs",
    "Baune",
    "Baupte",
    "Baurech",
    "Bauvin",
    "Bavans",
    "Bavay",
    "Bavent",
    "Bavilliers",
    "Bavincourt",
    "Baye",
    "Bayet",
    "Bayeux",
    "Bayon",
    "Bayonne",
    "Bazailles",
    "Bazancourt",
    "Bazarnes",
    "Bazas",
    "Bazeilles",
    "Bazemont",
    "Bazens",
    "Bazet",
    "Bazian",
    "Bazicourt",
    "Baziege",
    "Bazien",
    "Bazincourt-sur-Epte",
    "Bazoches",
    "Bazoches-les-Gallerandes",
    "Bazoches-sur-Guyonne",
    "Bazoches-sur-Hoene",
    "Bazoches-sur-le-Betz",
    "Bazoges-en-Paillers",
    "Bazoilles-sur-Meuse",
    "Bazoncourt",
    "Bazouges-la-Perouse",
    "Bazouges-sous-Hede",
    "Bazouges-sur-le-Loir",
    "Bazuel",
    "Beaucaire",
    "Beaucamps-le-Vieux",
    "Beaucamps-Ligny",
    "Beauce",
    "Beauchamp",
    "Beauchamps",
    "Beauchamps",
    "Beauchamps-sur-Huillard",
    "Beauchastel",
    "Beauchene",
    "Beaucourt",
    "Beaucouze",
    "Beaucroissant",
    "Beaudricourt",
    "Beaufort",
    "Beaufort",
    "Beaufort",
    "Beaufort-en-Vallee",
    "Beaufou",
    "Beaugency",
    "Beaujeu",
    "Beaulieu-sous-Parthenay",
    "Beaulieu-sur-Dordogne",
    "Beaulieu-sur-Loire",
    "Beaulieu-sur-Mer",
    "Beaumetz-les-Loges",
    "Beaumont",
    "Beaumont",
    "Beaumont",
    "Beaumont-de-Lomagne",
    "Beaumont-de-Pertuis",
    "Beaumont-du-Gatinais",
    "Beaumont-du-Perigord",
    "Beaumont-en-Veron",
    "Beaumont-Hague",
    "Beaumont-le-Roger",
    "Beaumont-les-Autels",
    "Beaumont-les-Nonains",
    "Beaumont-les-Valence",
    "Beaumont-Pied-de-Boeuf",
    "Beaumont-sur-Oise",
    "Beaumont-sur-Sarthe",
    "Beaune",
    "Beaune-la-Rolande",
    "Beaupont",
    "Beaupreau",
    "Beaupuy",
    "Beauquesne",
    "Beaurains",
    "Beaurainville",
    "Beauregard-Baret",
    "Beauregard-Vendon",
    "Beaurepaire",
    "Beaurepaire",
    "Beaurepaire",
    "Beaurevoir",
    "Beaurieux",
    "Beausemblant",
    "Beausoleil",
    "Beaussault",
    "Beautheil",
    "Beautiran",
    "Beautor",
    "Beautot",
    "Beauvais",
    "Beauvais-sur-Matha",
    "Beauval",
    "Beauvallon",
    "Beauvene",
    "Beauvernois",
    "Beauvezer",
    "Beauville",
    "Beauvoir",
    "Beauvoir",
    "Beauvoir-de-Marc",
    "Beauvoir-sur-Mer",
    "Beauvoir-sur-Niort",
    "Beauvoisin",
    "Beauvoisin",
    "Beauvoisin",
    "Beauzac",
    "Beauzelle",
    "Bechy",
    "Becon-les-Granits",
    "Bedarrides",
    "Bedee",
    "Bedous",
    "Beganne",
    "Begard",
    "Begles",
    "Beguey",
    "Behren-les-Forbach",
    "Beignon",
    "Beine-Nauroy",
    "Beinheim",
    "Beire-le-Chatel",
    "Bel Air",
    "Belarga",
    "Belaye",
    "Belbeuf",
    "Belcodene",
    "Belfort",
    "Belfort-du-Quercy",
    "Belgentier",
    "Belgodere",
    "Belhade",
    "Beliet",
    "Beligneux",
    "Bellac",
    "Bellaing",
    "Bellancourt",
    "Belle-Isle-en-Terre",
    "Belleau",
    "Bellebrune",
    "Bellechaume",
    "Bellefond",
    "Bellefontaine",
    "Bellefosse",
    "Bellegarde",
    "Bellegarde-du-Razes",
    "Bellegarde-en-Diois",
    "Bellegarde-Sainte-Marie",
    "Bellegarde-sur-Valserine",
    "Bellemagny",
    "Bellenaves",
    "Bellencombre",
    "Belleneuve",
    "Bellengreville",
    "Bellentre",
    "Belleray",
    "Bellerive-sur-Allier",
    "Bellesserre",
    "Belleu",
    "Bellevaux",
    "Bellevesvre",
    "Belleville",
    "Belleville",
    "Belleville-en-Caux",
    "Belleville-sur-Loire",
    "Belleville-sur-Mer",
    "Belleville-sur-Meuse",
    "Belleville-sur-Vie",
    "Bellevue-la-Montagne",
    "Belley",
    "Bellignat",
    "Belloy-en-France",
    "Belmont",
    "Belmont",
    "Belmont-sur-Rance",
    "Belmontet",
    "Belpech",
    "Belrupt-en-Verdunois",
    "Belveze",
    "Belz",
    "Benamenil",
    "Bene",
    "Benerville-sur-Mer",
    "Benesse-Maremne",
    "Benestroff",
    "Benfeld",
    "Benifontaine",
    "Benney",
    "Bennwihr",
    "Benon",
    "Benouville",
    "Beon",
    "Berat",
    "Bercenay-en-Othe",
    "Berck",
    "Berck-Plage",
    "Berdoues",
    "Berenx",
    "Berg-sur-Moselle",
    "Bergerac",
    "Bergheim",
    "Bergholtz",
    "Bergonne",
    "Bergues",
    "Berjou",
    "Berlaimont",
    "Berles-au-Bois",
    "Bermeries",
    "Bermicourt",
    "Bernadets",
    "Bernard",
    "Bernaville",
    "Bernay",
    "Bernay-Saint-Martin",
    "Berne",
    "Bernes-sur-Oise",
    "Berneuil-sur-Aisne",
    "Berneval-le-Grand",
    "Bernex",
    "Bernieres-le-Patry",
    "Bernieres-sur-Mer",
    "Bernin",
    "Bernis",
    "Bernos-Beaulac",
    "Berriac",
    "Berric",
    "Berry-Bouy",
    "Bersaillin",
    "Bersee",
    "Berson",
    "Berthecourt",
    "Berthelange",
    "Berthen",
    "Bertholene",
    "Bertin",
    "Bertincourt",
    "Bertrange",
    "Bertrimoutier",
    "Bertry",
    "Berville-en-Roumois",
    "Berville-la-Campagne",
    "Berville-sur-Seine",
    "Berviller-en-Moselle",
    "Berzeme",
    "Berzet",
    "Besayes",
    "Besne",
    "Bessais-le-Fromental",
    "Bessan",
    "Bessancourt",
    "Bessay",
    "Besse",
    "Besse",
    "Besse-sur-Braye",
    "Besse-sur-Issole",
    "Besseges",
    "Bessenay",
    "Bessens",
    "Bessey-les-Citeaux",
    "Bessieres",
    "Bessines",
    "Bessines-sur-Gartempe",
    "Besson",
    "Bessoncourt",
    "Bessonies",
    "Betaille",
    "Bethancourt-en-Valois",
    "Bethencourt-sur-Mer",
    "Betheniville",
    "Betheny",
    "Bethisy-Saint-Pierre",
    "Bethoncourt",
    "Betschdorf",
    "Bettancourt-la-Ferree",
    "Bettignies",
    "Betting",
    "Betton",
    "Bettviller",
    "Bettwiller",
    "Betz",
    "Beugin",
    "Beugneux",
    "Beure",
    "Beurlay",
    "Beuste",
    "Beutal",
    "Beuvillers",
    "Beuvrages",
    "Beuvraignes",
    "Beuvron-en-Auge",
    "Beuvry-la-Foret",
    "Beuzec-Cap-Sizun",
    "Beuzeville",
    "Bevenais",
    "Beychac-et-Caillau",
    "Beynes",
    "Beynost",
    "Beyrede-Jumet",
    "Beyrie-en-Bearn",
    "Beyssenac",
    "Bezac",
    "Bezannes",
    "Bezons",
    "Bezouce",
    "Bezu-Saint-Eloi",
    "Biache-Saint-Vaast",
    "Biard",
    "Biarritz",
    "Biars-sur-Cere",
    "Biaudos",
    "Bichancourt",
    "Bicqueley",
    "Bidache",
    "Bidarray",
    "Bidart",
    "Bief-du-Fourg",
    "Bielle",
    "Bienville",
    "Bienvillers-au-Bois",
    "Biermont",
    "Bierne",
    "Bierry-les-Belles-Fontaines",
    "Biesheim",
    "Biesles",
    "Bietlenheim",
    "Bieville-Beuville",
    "Bievres",
    "Biganos",
    "Bignac",
    "Bignan",
    "Bignoux",
    "Biguglia",
    "Bihorel",
    "Bilieu",
    "Billere",
    "Billey",
    "Billiers",
    "Billom",
    "Billy",
    "Billy",
    "Billy-Berclau",
    "Billy-Montigny",
    "Billy-sous-les-Cotes",
    "Billy-sur-Ourcq",
    "Bindernheim",
    "Binges",
    "Binic",
    "Bining",
    "Bionville-sur-Nied",
    "Biot",
    "Biot",
    "Bioule",
    "Birac-sur-Trec",
    "Biriatou",
    "Birieux",
    "Biscarrosse",
    "Bischheim",
    "Bischoffsheim",
    "Bischwihr",
    "Bischwiller",
    "Bisel",
    "Bitche",
    "Biviers",
    "Biville-sur-Mer",
    "Bizanos",
    "Blacourt",
    "Blacqueville",
    "Blacy",
    "Blaesheim",
    "Blagnac",
    "Blagny",
    "Blaignan",
    "Blain",
    "Blainville",
    "Blainville-sur-Mer",
    "Blainville-sur-Orne",
    "Blaise",
    "Blaise-sous-Arzillieres",
    "Blaison-Gohier",
    "Blamont",
    "Blamont",
    "Blan",
    "Blancafort",
    "Blanchefosse-et-Bay",
    "Blangy-sur-Bresle",
    "Blanot",
    "Blanquefort",
    "Blanzat",
    "Blanzay-sur-Boutonne",
    "Blanzy",
    "Blanzy-la-Salonnaise",
    "Blaringhem",
    "Blaudeix",
    "Blausasc",
    "Blauzac",
    "Blavozy",
    "Blaye",
    "Blaye-les-Mines",
    "Bleigny-le-Carreau",
    "Blendecques",
    "Blenod-les-Pont-a-Mousson",
    "Blenod-les-Toul",
    "Blerancourdelle",
    "Blere",
    "Bleriot-Plage",
    "Blesle",
    "Bletterans",
    "Bleury",
    "Bligny-les-Beaune",
    "Blincourt",
    "Blingel",
    "Blodelsheim",
    "Blois",
    "Blomard",
    "Blondefontaine",
    "Blonville-sur-Mer",
    "Blotzheim",
    "Blou",
    "Bloye",
    "Blyes",
    "Bobigny",
    "Bocquegney",
    "Boe",
    "Boege",
    "Boeil-Bezing",
    "Boen-sur-Lignon",
    "Boersch",
    "Boeschepe",
    "Boeseghem",
    "Boesenbiesen",
    "Bohain-en-Vermandois",
    "Bohal",
    "Boigneville",
    "Boigny",
    "Boigny-sur-Bionne",
    "Bois",
    "Bois-Anzeray",
    "Bois-Arnault",
    "Bois-Colombes",
    "Bois-de-Cene",
    "Bois-de-Gand",
    "Bois-de-la-Pierre",
    "Bois-Grenier",
    "Bois-Guillaume",
    "Bois-Jerome-Saint-Ouen",
    "Bois-le-Roi",
    "Bois-le-Roi",
    "Boisbergues",
    "Boiscommun",
    "Boisemont",
    "Boisemont",
    "Boisjean",
    "Boisleux-au-Mont",
    "Boismont",
    "Boismorand",
    "Boisse-Penchot",
    "Boisseron",
    "Boisset-et-Gaujac",
    "Boisseuil",
    "Boissey-le-Chatel",
    "Boissezon",
    "Boissieres",
    "Boissise-la-Bertrand",
    "Boissise-le-Roi",
    "Boissy-Fresnoy",
    "Boissy-le-Bois",
    "Boissy-le-Chatel",
    "Boissy-le-Girard",
    "Boissy-Maugis",
    "Boissy-Mauvoisin",
    "Boissy-sous-Saint-Yon",
    "Boisville-la-Saint-Pere",
    "Boisyvon",
    "Bolandoz",
    "Bolbec",
    "Bollene",
    "Bolleville",
    "Bollezeele",
    "Bollwiller",
    "Bolsenheim",
    "Bommes",
    "Bompas",
    "Bompas",
    "Bon-Encontre",
    "Bonchamp-les-Laval",
    "Boncourt-le-Bois",
    "Boncourt-sur-Meuse",
    "Bondeville",
    "Bondigoux",
    "Bondoufle",
    "Bondues",
    "Bondy",
    "Bongheat",
    "Bonifacio",
    "Bonlier",
    "Bonnard",
    "Bonnat",
    "Bonnebosq",
    "Bonnee",
    "Bonnegarde",
    "Bonneguete",
    "Bonneil",
    "Bonnelles",
    "Bonnemain",
    "Bonnes",
    "Bonnet",
    "Bonnet",
    "Bonnet",
    "Bonnetable",
    "Bonnetage",
    "Bonnetan",
    "Bonneuil-en-France",
    "Bonneuil-en-Valois",
    "Bonneuil-les-Eaux",
    "Bonneuil-sur-Marne",
    "Bonneval",
    "Bonnevaux-le-Prieure",
    "Bonnevent-Velloreille",
    "Bonneville",
    "Bonneville",
    "Bonneville-Aptot",
    "Bonneville-la-Louvet",
    "Bonnieres-sur-Seine",
    "Bonnieux",
    "Bonny-sur-Loire",
    "Bonrepos",
    "Bonrepos-Riquet",
    "Bons-en-Chablais",
    "Bonsecours",
    "Bonson",
    "Bonzac",
    "Boos",
    "Boos",
    "Boqueho",
    "Bor-et-Bar",
    "Boran-sur-Oise",
    "Bord",
    "Bord",
    "Bordeaux",
    "Borderes",
    "Borderes-et-Lamensans",
    "Bordes",
    "Bords",
    "Borgo",
    "Bormes-les-Mimosas",
    "Bornay",
    "Bornel",
    "Bosc Renoult",
    "Bosc-Guerard-Saint-Adrien",
    "Bosc-le-Hard",
    "Bosc-Mesnil",
    "Bosgouet",
    "Bosguerard-de-Marcouville",
    "Bosjean",
    "Bosmont-sur-Serre",
    "Bosrobert",
    "Bossancourt",
    "Bossay-sur-Claise",
    "Bossendorf",
    "Bossey",
    "Botsorhel",
    "Bouafles",
    "Bouan",
    "Bouaye",
    "Boubers-sur-Canche",
    "Boubiers",
    "Bouc-Bel-Air",
    "Boucau",
    "Bouchain",
    "Bouchemaine",
    "Boucheporn",
    "Bouchet",
    "Boucoiran-et-Nozieres",
    "Bouee",
    "Bouer",
    "Bouere",
    "Bouex",
    "Bouffemont",
    "Bouffere",
    "Bougarber",
    "Bouge-Chambalud",
    "Bougival",
    "Bougnon",
    "Bougue",
    "Bouguenais",
    "Bougy",
    "Bouhet",
    "Bouillargues",
    "Bouilly",
    "Bouilly",
    "Bouilly-en-Gatinais",
    "Bouin",
    "Boujan-sur-Libron",
    "Boulages",
    "Boulancourt",
    "Boulange",
    "Boulay-les-Barres",
    "Boulay-Moselle",
    "Boulazac",
    "Bouleurs",
    "Bouliac",
    "Boulieu-les-Annonay",
    "Bouligney",
    "Bouligny",
    "Boullay-les-Troux",
    "Boulleret",
    "Boulleville",
    "Bouloc",
    "Boulogne-Billancourt",
    "Boulogne-la-Grasse",
    "Boulogne-sur-Gesse",
    "Boulogne-sur-Mer",
    "Bouloire",
    "Boult-sur-Suippe",
    "Boulzicourt",
    "Bouniagues",
    "Bouquet",
    "Bouquetot",
    "Bouray-sur-Juine",
    "Bourbon-Lancy",
    "Bourbonne-les-Bains",
    "Bourbourg",
    "Bourbriac",
    "Bourcefranc-le-Chapus",
    "Bourdenay",
    "Bourdon",
    "Bouresse",
    "Bourg",
    "Bourg-Achard",
    "Bourg-Argental",
    "Bourg-Blanc",
    "Bourg-de-Thizy",
    "Bourg-en-Bresse",
    "Bourg-la-Reine",
    "Bourg-le-Comte",
    "Bourg-le-Roi",
    "Bourg-les-Valence",
    "Bourg-Saint-Andeol",
    "Bourg-Saint-Maurice",
    "Bourg-Sainte-Marie",
    "Bourg-sous-Chatelet",
    "Bourganeuf",
    "Bourgbarre",
    "Bourges",
    "Bourget",
    "Bourghelles",
    "Bourgneuf",
    "Bourgneuf",
    "Bourgneuf-en-Retz",
    "Bourgogne",
    "Bourgogne",
    "Bourgoin",
    "Bourguebus",
    "Bourgueil",
    "Bourguignon-sous-Coucy",
    "Bourlon",
    "Bourneville",
    "Bournezeau",
    "Bourogne",
    "Bourran",
    "Bourron-Marlotte",
    "Bours",
    "Bours",
    "Boursault",
    "Bourseville",
    "Boursieres",
    "Bourth",
    "Boury-en-Vexin",
    "Bousbach",
    "Bousbecque",
    "Bousies",
    "Boussac",
    "Boussac",
    "Boussay",
    "Boussenac",
    "Boussens",
    "Boussieres",
    "Boussois",
    "Boussy-Saint-Antoine",
    "Boust",
    "Boutancourt",
    "Boutiers-Saint-Trojan",
    "Boutigny-Prouais",
    "Boutigny-sur-Essonne",
    "Bouvaincourt-sur-Bresle",
    "Bouvante",
    "Bouvelinghem",
    "Bouvesse-Quirieu",
    "Bouvigny-Boyeffles",
    "Bouville",
    "Bouvincourt-en-Vermandois",
    "Bouvron",
    "Bouxieres-aux-Chenes",
    "Bouxieres-aux-Dames",
    "Bouxwiller",
    "Bouzel",
    "Bouzigues",
    "Bouzincourt",
    "Bouzonville",
    "Bouzonville-en-Beauce",
    "Bouzy",
    "Bouzy-la-Foret",
    "Boves",
    "Boyer",
    "Boynes",
    "Boz",
    "Bozel",
    "Bozouls",
    "Brachy",
    "Bracieux",
    "Brain-sur-Allonnes",
    "Brain-sur-Vilaine",
    "Braine",
    "Brains",
    "Bram",
    "Bramans",
    "Branches",
    "Brancourt-en-Laonnois",
    "Brandivy",
    "Brando",
    "Brandonnet",
    "Branges",
    "Branne",
    "Branoux-les-Taillades",
    "Bransles",
    "Braquis",
    "Bras",
    "Brasles",
    "Brasparts",
    "Brassac",
    "Brassac-les-Mines",
    "Brassempouy",
    "Braucourt",
    "Braud-et-Saint-Louis",
    "Brax",
    "Brax",
    "Bray",
    "Bray-Dunes",
    "Bray-en-Val",
    "Bray-et-Lu",
    "Bray-Saint-Christophe",
    "Bray-sur-Seine",
    "Bray-sur-Somme",
    "Braye-sous-Faye",
    "Brazey-en-Plaine",
    "Breal-sous-Montfort",
    "Brebieres",
    "Brece",
    "Brecey",
    "Brech",
    "Breconchaux",
    "Bree",
    "Bregy",
    "Brehal",
    "Brehand",
    "Breil-sur-Roya",
    "Breitenbach-Haut-Rhin",
    "Breles",
    "Bremenil",
    "Bremontier-Merval",
    "Brenouille",
    "Brens",
    "Bresles",
    "Bresse-sur-Grosne",
    "Bressolles",
    "Bressolles",
    "Bressols",
    "Bresson",
    "Bressuire",
    "Brest",
    "Bretagne",
    "Breteil",
    "Breteniere",
    "Bretenoux",
    "Breteuil",
    "Breteuil",
    "Bretignolles",
    "Bretignolles-sur-Mer",
    "Bretigny",
    "Bretigny",
    "Bretteville-sur-Laize",
    "Bretteville-sur-Odon",
    "Breuil",
    "Breuil",
    "Breuil-le-Sec",
    "Breuil-le-Vert",
    "Breuil-Magne",
    "Breuillet",
    "Breuillet",
    "Breuilpont",
    "Breuschwickersheim",
    "Breuvannes-en-Bassigny",
    "Breux-sur-Avre",
    "Breval",
    "Breviandes",
    "Brexent-Enocq",
    "Breze",
    "Brezins",
    "Brezolles",
    "Brianconnet",
    "Briare",
    "Briarres-sur-Essonne",
    "Briatexte",
    "Bricon",
    "Bricquebec",
    "Bricqueville-la-Blouette",
    "Bricqueville-sur-Mer",
    "Bricy",
    "Brides-les-Bains",
    "Bridore",
    "Brie",
    "Brie",
    "Brie-Comte-Robert",
    "Brie-et-Angonnes",
    "Briec",
    "Brienne-la-Vieille",
    "Briennon",
    "Brienon-sur-Armancon",
    "Brieres-les-Scelles",
    "Brieuil-sur-Chize",
    "Brieulles-sur-Meuse",
    "Briey",
    "Brignac-la-Plaine",
    "Brignais",
    "Brigne",
    "Brignoles",
    "Brignon",
    "Brignoud",
    "Brigueuil",
    "Briis-sous-Forges",
    "Brimeux",
    "Brin-sur-Seille",
    "Brinay",
    "Brindas",
    "Bringolo",
    "Brinon-sur-Beuvron",
    "Briollay",
    "Brion",
    "Brion",
    "Brionne",
    "Briord",
    "Brioude",
    "Brioux-sur-Boutonne",
    "Briouze",
    "Briscous",
    "Brison-Saint-Innocent",
    "Brissac",
    "Brissac-Quince",
    "Brissy-Hamegicourt",
    "Brive",
    "Brive-la-Gaillarde",
    "Brives-Charensac",
    "Brives-sur-Charente",
    "Brix",
    "Brochon",
    "Broglie",
    "Brognon",
    "Broin",
    "Brombos",
    "Bron",
    "Broons",
    "Brossac",
    "Brosville",
    "Brou",
    "Brou-sur-Chantereine",
    "Brouay",
    "Brouckerque",
    "Brouderdorff",
    "Broue",
    "Brouennes",
    "Brouilla",
    "Brouqueyran",
    "Brousse",
    "Brousse",
    "Brousseval",
    "Brouviller",
    "Bru",
    "Bruch",
    "Brue-Auriac",
    "Bruebach",
    "Bruges",
    "Brugheas",
    "Brugny-Vaudancourt",
    "Bruguieres",
    "Bruille-Saint-Amand",
    "Bruley",
    "Brullioles",
    "Brulon",
    "Brumath",
    "Brunoy",
    "Brunstatt",
    "Brussey",
    "Brussieu",
    "Brusvily",
    "Brux",
    "Bruyeres-et-Montberault",
    "Bruyeres-le-Chatel",
    "Bruyeres-sur-Oise",
    "Bruz",
    "Bry-sur-Marne",
    "Bu",
    "Buanes",
    "Bubry",
    "Buc",
    "Buceels",
    "Bucey-les-Gy",
    "Bucheres",
    "Buchy",
    "Bucquoy",
    "Bucy-le-Long",
    "Bucy-les-Cerny",
    "Budos",
    "Bueil",
    "Buellas",
    "Buffard",
    "Bugeat",
    "Bugnicourt",
    "Buhl",
    "Buigny-les-Gamaches",
    "Buire-le-Sec",
    "Buironfosse",
    "Buis-les-Baronnies",
    "Bulat-Pestivien",
    "Bulgneville",
    "Bulhon",
    "Bulles",
    "Bully",
    "Bully-les-Mines",
    "Buno-Bonnevaux",
    "Bunzac",
    "Burbure",
    "Bures-sur-Yvette",
    "Burie",
    "Burlats",
    "Burnhaupt-le-Haut",
    "Buros",
    "Bury",
    "Busigny",
    "Busloup",
    "Busque",
    "Bussac-Foret",
    "Bussang",
    "Bussiere-Galant",
    "Bussiere-Poitevine",
    "Bussieres-les-Belmont",
    "Bussunarits-Sarrasquette",
    "Bussus-Bussuel",
    "Bussy-le-Chateau",
    "Bussy-le-Grand",
    "Bussy-les-Daours",
    "Bussy-Lettree",
    "Bussy-Saint-Georges",
    "Busy",
    "Buthiers",
    "Butry-sur-Oise",
    "Buxerolles",
    "Buxeuil",
    "Buxieres-les-Mines",
    "Buxy",
    "Buzan",
    "Buzancais",
    "Buzet-sur-Baise",
    "Buzet-sur-Tarn",
    "Buzy",
    "Cabanac",
    "Cabanac-et-Villagrains",
    "Cabannes",
    "Cabara",
    "Cabariot",
    "Cabasse",
    "Cabestany",
    "Cabourg",
    "Cabries",
    "Cabris",
    "Cachan",
    "Cadalen",
    "Cadaujac",
    "Caden",
    "Cadenet",
    "Cadillac",
    "Cadillac-en-Fronsadais",
    "Cadolive",
    "Cadours",
    "Caen",
    "Caestre",
    "Cagnac-les-Mines",
    "Cagnes-sur-Mer",
    "Cagnicourt",
    "Cagny",
    "Cagny",
    "Cahagnes",
    "Cahors",
    "Cahuzac",
    "Cahuzac",
    "Cahuzac-sur-Vere",
    "Caille",
    "Caillouel-Crepigny",
    "Cailloux-sur-Fontaines",
    "Cairanne",
    "Cairon",
    "Caissargues",
    "Cajarc",
    "Calais",
    "Calas",
    "Calavante",
    "Calcatoggio",
    "Calenzana",
    "Callac",
    "Callac",
    "Callas",
    "Calleville",
    "Callian",
    "Calmels-et-le-Viala",
    "Calmont",
    "Calonne-Ricouart",
    "Calonne-sur-la-Lys",
    "Caluire-et-Cuire",
    "Calvi",
    "Calvisson",
    "Camaret-sur-Aigues",
    "Camaret-sur-Mer",
    "Camarsac",
    "Cambes",
    "Cambieure",
    "Camblain-Chatelain",
    "Camblanes-et-Meynac",
    "Cambo-les-Bains",
    "Cambounet-sur-le-Sor",
    "Cambrai",
    "Cambrin",
    "Cambronne-les-Ribecourt",
    "Came",
    "Cameyrac",
    "Camiac-et-Saint-Denis",
    "Camiers",
    "Camlez",
    "Camoel",
    "Camon",
    "Camon",
    "Camors",
    "Campagnan",
    "Campagne",
    "Campagne",
    "Campagne-les-Hesdin",
    "Campagne-les-Wardrecques",
    "Campagnolles",
    "Campeaux",
    "Campeneac",
    "Campet-et-Lamolere",
    "Camphin-en-Carembault",
    "Camphin-en-Pevele",
    "Campigneulles-les-Petites",
    "Campremy",
    "Camprond",
    "Camps-la-Source",
    "Campuac",
    "Campugnan",
    "Canaples",
    "Cancale",
    "Cancon",
    "Cande",
    "Cande-sur-Beuvron",
    "Candillargues",
    "Candresse",
    "Canejan",
    "Canet-de-Salars",
    "Canet-Plage",
    "Cangey",
    "Canisy",
    "Canlers",
    "Canly",
    "Cannes",
    "Cannes-Ecluse",
    "Cannet",
    "Canohes",
    "Canouville",
    "Canteleu",
    "Cantin",
    "Cany-Barville",
    "Capbreton",
    "Capdenac-Gare",
    "Capelle",
    "Capelle-Fermont",
    "Capendu",
    "Capens",
    "Capestang",
    "Capinghem",
    "Capoulet-et-Junac",
    "Cappel",
    "Cappelle-Brouck",
    "Cappelle-en-Pevele",
    "Cappelle-la-Grande",
    "Captieux",
    "Capvern",
    "Caragoudes",
    "Caraman",
    "Carantec",
    "Carantilly",
    "Carbon-Blanc",
    "Carbonne",
    "Carcans",
    "Carcassonne",
    "Carces",
    "Carcopino",
    "Cardaillac",
    "Cardesse",
    "Cardonville",
    "Cardroc",
    "Carency",
    "Carentan",
    "Carentoir",
    "Carhaix-Plouguer",
    "Carignan",
    "Carling",
    "Carlipa",
    "Carlucet",
    "Carlus",
    "Carmaux",
    "Carnac",
    "Carneville",
    "Carnieres",
    "Carnin",
    "Carnon-Plage",
    "Carnoules",
    "Carolles",
    "Caromb",
    "Carpentras",
    "Carpiquet",
    "Carquefou",
    "Carqueiranne",
    "Carresse-Cassaber",
    "Carrieres-sous-Poissy",
    "Carrieres-sur-Seine",
    "Carros",
    "Carrouges",
    "Carry-le-Rouet",
    "Carsac-Aillac",
    "Carspach",
    "Cartelegue",
    "Cartignies",
    "Carville-la-Folletiere",
    "Carvin",
    "Casanova",
    "Cases-de-Pene",
    "Cassagne",
    "Cassagnes",
    "Cassagnes-Begonhes",
    "Cassagnoles",
    "Cassel",
    "Casseneuil",
    "Cassis",
    "Casson",
    "Cast",
    "Castagnede",
    "Castanet-le-Haut",
    "Castanet-Tolosan",
    "Casteide-Cami",
    "Castelginest",
    "Casteljaloux",
    "Castellane",
    "Castellar",
    "Castellare-di-Mercurio",
    "Castello-di-Rostino",
    "Castelmoron-sur-Lot",
    "Castelnau-de-Brassac",
    "Castelnau-de-Levis",
    "Castelnau-de-Montmiral",
    "Castelnau-Durban",
    "Castelnau-le-Lez",
    "Castelnau-Montratier",
    "Castelnau-sur-Gupie",
    "Castelnau-Valence",
    "Castelnaud-de-Gratecambe",
    "Castelnaudary",
    "Castels",
    "Castelsagrat",
    "Castelsarrasin",
    "Castera-Loubix",
    "Castet",
    "Castetner",
    "Castets-en-Dorthe",
    "Castiglione",
    "Castillon-Debats",
    "Castillon-du-Gard",
    "Castillon-la-Bataille",
    "Castillon-Massas",
    "Castillonnes",
    "Castilly",
    "Castres",
    "Castres-Gironde",
    "Castries",
    "Catenay",
    "Catenoy",
    "Cattenom",
    "Catus",
    "Caucalieres",
    "Caudan",
    "Caudebec-en-Caux",
    "Caudecoste",
    "Caudrot",
    "Caudry",
    "Cauge",
    "Caullery",
    "Caulnes",
    "Caumont",
    "Caumont",
    "Caumont-sur-Durance",
    "Caumont-sur-Garonne",
    "Cauneille",
    "Caunes-Minervois",
    "Caurel",
    "Cauroir",
    "Cauroy-les-Hermonville",
    "Caussade-Riviere",
    "Causse-de-la-Selle",
    "Cauvigny",
    "Cauville-sur-Mer",
    "Caux",
    "Caux-et-Sauzens",
    "Cauzac",
    "Cavaillon",
    "Cavalaire-sur-Mer",
    "Cavan",
    "Caveirac",
    "Cavignac",
    "Cavillargues",
    "Cavillon",
    "Cayeux-sur-Mer",
    "Caylus",
    "Cayrac",
    "Cazals-des-Bayles",
    "Cazaux",
    "Cazaux-Layrisse",
    "Cazavet",
    "Cazeres",
    "Cazes-Mondenard",
    "Cazevieille",
    "Cazilhac",
    "Cazouls-les-Beziers",
    "Ceauce",
    "Ceaux",
    "Cebazat",
    "Ceignes",
    "Ceintrey",
    "Celle-Levescault",
    "Celles-en-Bassigny",
    "Celles-sur-Belle",
    "Celles-sur-Durolle",
    "Cellettes",
    "Cellieu",
    "Celon",
    "Cenac",
    "Cendras",
    "Cenon",
    "Cenon-sur-Vienne",
    "Centres",
    "Cepet",
    "Cepie",
    "Cepoy",
    "Cerans-Foulletourte",
    "Cercier",
    "Cercoux",
    "Cerdon",
    "Cere",
    "Cerelles",
    "Cereste",
    "Ceret",
    "Cergy",
    "Cerilly",
    "Cerise",
    "Cerisiers",
    "Cerisy-la-Foret",
    "Cerisy-la-Salle",
    "Cerizay",
    "Cernay",
    "Cernay-la-Ville",
    "Cerneux",
    "Cerny",
    "Cerons",
    "Cers",
    "Certines",
    "Cervens",
    "Cervera de la Marenda",
    "Cervieres",
    "Cervione",
    "Cesar",
    "Cesarches",
    "Cescau",
    "Cesny-aux-Vignes",
    "Cesny-Bois-Halbout",
    "Cessales",
    "Cessenon-sur-Orb",
    "Cesseras",
    "Cessey",
    "Cessieu",
    "Cesson",
    "Cesson",
    "Cessy",
    "Cestas",
    "Ceton",
    "Ceyrat",
    "Ceyreste",
    "Ceyssat",
    "Ceyzeriat",
    "Cezay",
    "Chaban",
    "Chabanais",
    "Chabestan",
    "Chabeuil",
    "Chablis",
    "Chabons",
    "Chabottes",
    "Chabrillan",
    "Chabris",
    "Chacenay",
    "Chadeleuf",
    "Chadrac",
    "Chadron",
    "Chaffois",
    "Chagey",
    "Chagny",
    "Chaignay",
    "Chail",
    "Chaillac",
    "Chaillac-sur-Vienne",
    "Chaille-les-Marais",
    "Chailles",
    "Chaillevette",
    "Chailloue",
    "Chailly-en-Biere",
    "Chailly-en-Gatinais",
    "Chainaz-les-Frasses",
    "Chaingy",
    "Chaix",
    "Chalais",
    "Chalamont",
    "Chalampe",
    "Chalandry-Elaire",
    "Chaleins",
    "Chalencon",
    "Chalette-sur-Loing",
    "Chalette-sur-Voire",
    "Chaleze",
    "Chalezeule",
    "Chaligny",
    "Chalindrey",
    "Challain-la-Potherie",
    "Challans",
    "Challes-les-Eaux",
    "Challet",
    "Challex",
    "Chalmaison",
    "Chalo-Saint-Mars",
    "Chalon",
    "Chalonnes-sur-Loire",
    "Chalons-en-Champagne",
    "Chalonvillars",
    "Chalou-Moulineux",
    "Chamagne",
    "Chamagnieu",
    "Chambalud",
    "Chamberet",
    "Chambilly",
    "Chamblac",
    "Chamblanc",
    "Chambles",
    "Chamblet",
    "Chambley-Bussieres",
    "Chambly",
    "Chamboeuf",
    "Chambon",
    "Chambon-sur-Lac",
    "Chambon-sur-Voueize",
    "Chambonas",
    "Chambord",
    "Chamboret",
    "Chamborigaud",
    "Chambors",
    "Chambost-Longessaigne",
    "Chamboulive",
    "Chambourcy",
    "Chambourg-sur-Indre",
    "Chambrecy",
    "Chambretaud",
    "Chambry",
    "Chamelet",
    "Chameyrat",
    "Chamigny",
    "Chamonix",
    "Chamouilley",
    "Chamoux",
    "Chamoux-sur-Gelon",
    "Champ",
    "Champ-le-Duc",
    "Champ-sur-Drac",
    "Champagnac",
    "Champagnac-le-Vieux",
    "Champagnat",
    "Champagne",
    "Champagne",
    "Champagne-les-Marais",
    "Champagne-Mouton",
    "Champagne-sur-Oise",
    "Champagne-sur-Seine",
    "Champagney",
    "Champagnole",
    "Champanges",
    "Champcenest",
    "Champcerie",
    "Champcevinel",
    "Champcueil",
    "Champdieu",
    "Champdotre",
    "Champdray",
    "Champeaux",
    "Champeaux",
    "Champeix",
    "Champenay",
    "Champenoux",
    "Champeon",
    "Champey-sur-Moselle",
    "Champforgeuil",
    "Champfromier",
    "Champhol",
    "Champien",
    "Champier",
    "Champigne",
    "Champigneulles",
    "Champigny",
    "Champigny-sur-Marne",
    "Champlan",
    "Champlemy",
    "Champlitte",
    "Champniers",
    "Champoly",
    "Champoulet",
    "Champs",
    "Champs-sur-Marne",
    "Champs-sur-Tarentaine-Marchal",
    "Champs-sur-Yonne",
    "Champsac",
    "Champtercier",
    "Champtoce-sur-Loire",
    "Champtoceaux",
    "Champvans",
    "Chanas",
    "Chanceaux-sur-Choisille",
    "Chancelade",
    "Chancenay",
    "Change",
    "Changis-sur-Marne",
    "Chaniers",
    "Chanonat",
    "Chanoz-Chatenay",
    "Chanteau",
    "Chantecorps",
    "Chanteheux",
    "Chanteloup",
    "Chanteloup-en-Brie",
    "Chanteloup-les-Bois",
    "Chanteloup-les-Vignes",
    "Chantemerle-sur-la-Soie",
    "Chantepie",
    "Chanteuges",
    "Chantilly",
    "Chantome",
    "Chantonnay",
    "Chantraine",
    "Chantrans",
    "Chanveaux",
    "Chaon",
    "Chapareillan",
    "Chapdes-Beaufort",
    "Chapeiry",
    "Chapelle-Vallon",
    "Chapelle-Voland",
    "Chaponnay",
    "Chaponost",
    "Chaptelat",
    "Chaptuzat",
    "Charancieu",
    "Charantonnay",
    "Charavines",
    "Charbonnieres-les-Bains",
    "Charbonnieres-les-Varennes",
    "Charbuy",
    "Charentay",
    "Charenton-le-Pont",
    "Charge",
    "Chargey-les-Gray",
    "Chargey-les-Port",
    "Charleval",
    "Charleval",
    "Charleville",
    "Charlieu",
    "Charly",
    "Charly-sur-Marne",
    "Charmant",
    "Charmes",
    "Charmes",
    "Charmes-sur-Rhone",
    "Charmont-en-Beauce",
    "Charmont-sous-Barbuise",
    "Charnas",
    "Charnay",
    "Charnay",
    "Charnay-les-Macon",
    "Charnecles",
    "Charny",
    "Charolles",
    "Charonville",
    "Charost",
    "Charquemont",
    "Charrecey",
    "Charron",
    "Charroux",
    "Charsonville",
    "Charteves",
    "Chartres",
    "Chartres-de-Bretagne",
    "Chartrettes",
    "Charvieu-Chavagneux",
    "Charvonnex",
    "Chas",
    "Chasnais",
    "Chasne-sur-Illet",
    "Chaspinhac",
    "Chassagnes",
    "Chassagny",
    "Chasse-sur-Rhone",
    "Chasselay",
    "Chassemy",
    "Chassenard",
    "Chasseneuil",
    "Chasseneuil-du-Poitou",
    "Chasseneuil-sur-Bonnieure",
    "Chassey-le-Camp",
    "Chassiers",
    "Chassieu",
    "Chataincourt",
    "Chateau",
    "Chateau-Arnoux-Saint-Auban",
    "Chateau-Chervix",
    "Chateau-Chinon(Ville)",
    "Chateau-du-Loir",
    "Chateau-Gontier",
    "Chateau-Landon",
    "Chateau-Porcien",
    "Chateau-Renault",
    "Chateau-Salins",
    "Chateau-sur-Epte",
    "Chateaubleau",
    "Chateaubourg",
    "Chateaubriand",
    "Chateaubriant",
    "Chateaudouble",
    "Chateaufort",
    "Chateaugay",
    "Chateaugiron",
    "Chateaumeillant",
    "Chateauneuf",
    "Chateauneuf-de-Bordette",
    "Chateauneuf-de-Gadagne",
    "Chateauneuf-de-Galaure",
    "Chateauneuf-du-Faou",
    "Chateauneuf-du-Rhone",
    "Chateauneuf-en-Thymerais",
    "Chateauneuf-Grasse",
    "Chateauneuf-la-Foret",
    "Chateauneuf-le-Rouge",
    "Chateauneuf-les-Martigues",
    "Chateauneuf-sur-Charente",
    "Chateauneuf-sur-Loire",
    "Chateauneuf-sur-Sarthe",
    "Chateaurenard",
    "Chateauvillain",
    "Chatel",
    "Chatel-de-Neuvre",
    "Chatel-Guyon",
    "Chatel-Saint-Germain",
    "Chatel-sur-Moselle",
    "Chatelaillon-Plage",
    "Chatelaines",
    "Chatelais",
    "Chatelaudren",
    "Chateldon",
    "Chatelet",
    "Chatelus",
    "Chatelus-le-Marcheix",
    "Chatenay-sur-Seine",
    "Chatenet",
    "Chatenois",
    "Chatenois",
    "Chatenois-les-Forges",
    "Chatenoy",
    "Chatenoy-le-Royal",
    "Chatignonville",
    "Chatillon",
    "Chatillon-Coligny",
    "Chatillon-la-Palud",
    "Chatillon-le-Duc",
    "Chatillon-le-Roi",
    "Chatillon-les-Sons",
    "Chatillon-sur-Chalaronne",
    "Chatillon-sur-Cher",
    "Chatillon-sur-Indre",
    "Chatillon-sur-Loire",
    "Chatillon-sur-Saone",
    "Chatillon-sur-Seiche",
    "Chatillon-sur-Thouet",
    "Chatonnay",
    "Chatonrupt-Sommermont",
    "Chatou",
    "Chatres",
    "Chatres-sur-Cher",
    "Chatte",
    "Chatuzange-le-Goubet",
    "Chauche",
    "Chaudefonds-sur-Layon",
    "Chaudefontaine",
    "Chaudon",
    "Chaudron-en-Mauges",
    "Chauffailles",
    "Chauffry",
    "Chaufour-les-Bonnieres",
    "Chaufour-Notre-Dame",
    "Chaulnes",
    "Chaumes",
    "Chaumes-en-Brie",
    "Chaumont",
    "Chaumont",
    "Chaumont-en-Vexin",
    "Chaumontel",
    "Chaumousey",
    "Chauny",
    "Chauray",
    "Chauriat",
    "Chaussin",
    "Chaussoy-Epagny",
    "Chaussy",
    "Chauve",
    "Chauvigny",
    "Chauvincourt-Provemont",
    "Chauvry",
    "Chaux",
    "Chavagne",
    "Chavagnes-en-Paillers",
    "Chavagnes-les-Redoux",
    "Chavaignes",
    "Chavanay",
    "Chavannes-sur-Reyssouze",
    "Chavanod",
    "Chavanoz",
    "Chavaroux",
    "Chavenay",
    "Chavigny",
    "Chaville",
    "Chazelles",
    "Chazelles-sur-Lyon",
    "Checy",
    "Chedigny",
    "Chef-Boutonne",
    "Cheffes",
    "Chehery",
    "Chelan",
    "Chelles",
    "Chemaze",
    "Chemere",
    "Chemere-le-Roi",
    "Chemery",
    "Chemery-les-Deux",
    "Chemille-Melay",
    "Chemilly-sur-Serein",
    "Cheminot",
    "Chemy",
    "Chenailler-Mascheix",
    "Chenaud",
    "Chenay-le-Chatel",
    "Chenerailles",
    "Chenevelles",
    "Chenex",
    "Chenimenil",
    "Chennevieres-sur-Marne",
    "Chenonceaux",
    "Chenou",
    "Chenove",
    "Chens-sur-Leman",
    "Cheny",
    "Chepoix",
    "Cheptainville",
    "Chepy",
    "Cherac",
    "Cherbourg-Octeville",
    "Chereng",
    "Cheret",
    "Cherier",
    "Cherisey",
    "Cherisy",
    "Cheron",
    "Cheroy",
    "Cherre",
    "Cherreau",
    "Cherves-Chatelars",
    "Cherves-Richemont",
    "Chervettes",
    "Cherveux",
    "Chesley",
    "Chessy",
    "Chessy",
    "Chessy-les-Pres",
    "Cheux",
    "Chevaigne",
    "Cheval-Blanc",
    "Chevalet-le-Haut",
    "Chevanceaux",
    "Chevannes",
    "Chevannes",
    "Cheverny",
    "Chevigny",
    "Chevigny-Saint-Sauveur",
    "Chevillon",
    "Chevillon",
    "Chevillon-sur-Huillard",
    "Chevilly",
    "Chevilly-Larue",
    "Chevincourt",
    "Chevire-le-Rouge",
    "Chevrainvilliers",
    "Chevremont",
    "Chevreuse",
    "Chevrier",
    "Chevrieres",
    "Chevrieres",
    "Chevrotaine",
    "Chevru",
    "Chevry",
    "Chevry-Cossigny",
    "Chevry-en-Sereine",
    "Cheyssieu",
    "Chezery-Forens",
    "Chezy",
    "Chezy-sur-Marne",
    "Chiche",
    "Chichery",
    "Chierry",
    "Chieulles",
    "Chignin",
    "Chigny-les-Roses",
    "Chigy",
    "Chilhac",
    "Chille",
    "Chilly",
    "Chilly-Mazarin",
    "Chimilin",
    "Chinon",
    "Chire-en-Montreuil",
    "Chirens",
    "Chiry-Ourscamp",
    "Chissay-en-Touraine",
    "Chisseaux",
    "Chissey-en-Morvan",
    "Chitenay",
    "Chivres-en-Laonnois",
    "Chize",
    "Chocques",
    "Choisel",
    "Choisey",
    "Choisy",
    "Choisy-au-Bac",
    "Choisy-en-Brie",
    "Choisy-le-Roi",
    "Cholet",
    "Chomerac",
    "Chorges",
    "Chourgnac",
    "Chouy",
    "Choye",
    "Chozeau",
    "Chuelles",
    "Chuzelles",
    "Ciboure",
    "Ciel",
    "Cierrey",
    "Cieurac",
    "Cieux",
    "Cinq-Mars-la-Pile",
    "Cinqueux",
    "Cintegabelle",
    "Cintheaux",
    "Cintre",
    "Cires-les-Mello",
    "Cirey",
    "Cirey-sur-Vezouze",
    "Ciron",
    "Ciry-le-Noble",
    "Cissac-Medoc",
    "Cisse",
    "Cistrieres",
    "Citerne",
    "Citry",
    "Civaux",
    "Civens",
    "Civrac-de-Blaye",
    "Civrac-en-Medoc",
    "Civray",
    "Civray",
    "Civray-de-Touraine",
    "Civrieux",
    "Civry-en-Montagne",
    "Cize",
    "Cizely",
    "Claira",
    "Clairac",
    "Clairfontaine",
    "Clairmarais",
    "Clairoix",
    "Clairvaux-dAveyron",
    "Clairvaux-les-Lacs",
    "Claix",
    "Claix",
    "Clamart",
    "Clamecy",
    "Clamecy",
    "Claouey",
    "Clapiers",
    "Clarac",
    "Claracq",
    "Clarafond",
    "Clarensac",
    "Clarques",
    "Clary",
    "Classun",
    "Clasville",
    "Clavel",
    "Clavette",
    "Claviers",
    "Claville",
    "Claye-Souilly",
    "Cleder",
    "Cleguer",
    "Cleguerec",
    "Clelles",
    "Clement",
    "Clemery",
    "Clemont",
    "Clenay",
    "Cleon",
    "Clerieux",
    "Clermont",
    "Clermont",
    "Clermont",
    "Clermont",
    "Clermont-Ferrand",
    "Clerval",
    "Clery-Saint-Andre",
    "Clesse",
    "Clety",
    "Clevilliers",
    "Clichy",
    "Clichy-sous-Bois",
    "Clinchamps-sur-Orne",
    "Cliron",
    "Clisson",
    "Clohars-Carnoet",
    "Clohars-Fouesnant",
    "Clouange",
    "Clouzeau",
    "Cloyes-sur-le-Loir",
    "Cluny",
    "Cluses",
    "Coaraze",
    "Coarraze",
    "Cobrieux",
    "Cocheren",
    "Cocumont",
    "Codognan",
    "Codolet",
    "Coesmes",
    "Coeuvres-et-Valsery",
    "Coex",
    "Cogles",
    "Cogna",
    "Cognac",
    "Cognin",
    "Cogolin",
    "Coignet",
    "Coignieres",
    "Coimeres",
    "Coincy",
    "Coirac",
    "Coise-Saint-Jean-Pied-Gauthier",
    "Coivrel",
    "Colayrac-Saint-Cirq",
    "Colembert",
    "Coligny",
    "Collandres-Quincarnon",
    "Collegien",
    "Colleret",
    "Colleville",
    "Colleville-Montgomery",
    "Collias",
    "Collioure",
    "Collobrieres",
    "Collonges-les-Premieres",
    "Collonges-sous-Saleve",
    "Collorgues",
    "Colmar",
    "Colmars",
    "Cologne",
    "Colomars",
    "Colombe",
    "Colombelles",
    "Colombes",
    "Colombier",
    "Colombier",
    "Colombier-Fontaine",
    "Colombier-le-Cardinal",
    "Colombier-le-Vieux",
    "Colombier-Saugnieu",
    "Colombieres-sur-Orb",
    "Colombiers",
    "Colomby-sur-Thaon",
    "Colomiers",
    "Colomieu",
    "Colondannes",
    "Colonzelle",
    "Colpo",
    "Coltainville",
    "Combas",
    "Comblanchien",
    "Combloux",
    "Combon",
    "Combourg",
    "Combrand",
    "Combree",
    "Combrit",
    "Combronde",
    "Combs-la-Ville",
    "Comines",
    "Commelle-Vernay",
    "Commenchon",
    "Commensacq",
    "Commentry",
    "Commeny",
    "Commequiers",
    "Commer",
    "Commercy",
    "Commes",
    "Commissey",
    "Communay",
    "Compans",
    "Compertrix",
    "Compolibat",
    "Compreignac",
    "Comps",
    "Concarneau",
    "Conceze",
    "Conches-en-Ouche",
    "Conches-sur-Gondoire",
    "Conchy-les-Pots",
    "Condac",
    "Condat",
    "Condat-sur-Vienne",
    "Conde-en-Brie",
    "Conde-Northen",
    "Conde-Sainte-Libiaire",
    "Conde-sur-Iton",
    "Conde-sur-Marne",
    "Conde-sur-Noireau",
    "Conde-sur-Sarthe",
    "Conde-sur-Suippe",
    "Conde-sur-Vesgre",
    "Conde-sur-Vire",
    "Condecourt",
    "Condeon",
    "Condette",
    "Condezaygues",
    "Condillac",
    "Condom",
    "Condrieu",
    "Conflans-en-Jarnisy",
    "Conflans-Sainte-Honorine",
    "Conflans-sur-Loing",
    "Conflans-sur-Seine",
    "Confolens",
    "Congenies",
    "Conliege",
    "Connantray-Vaurefroy",
    "Conne-de-Labarde",
    "Connerre",
    "Conquereuil",
    "Conques-sur-Orbiel",
    "Cons-Sainte-Colombe",
    "Contamine-sur-Arve",
    "Contes",
    "Contigne",
    "Contoire",
    "Contre",
    "Contres",
    "Contrexeville",
    "Contrisson",
    "Contz-les-Bains",
    "Copponex",
    "Coquelles",
    "Corancez",
    "Coray",
    "Corbara",
    "Corbarieu",
    "Corbas",
    "Corbehem",
    "Corbeil-Cerf",
    "Corbeil-Essonnes",
    "Corbeilles",
    "Corbelin",
    "Corbeny",
    "Corbere-Aberes",
    "Corbere-les-Cabanes",
    "Corbie",
    "Corbieres",
    "Corbieres",
    "Corbigny",
    "Corbonod",
    "Corbreuse",
    "Corcelles-les-Citeaux",
    "Corcelles-les-Monts",
    "Corcieux",
    "Corconne",
    "Corcy",
    "Cordelle",
    "Cordemais",
    "Cordes-sur-Ciel",
    "Cordes-Tolosannes",
    "Cordon",
    "Corenc",
    "Corgnac-sur-lIsle",
    "Corgoloin",
    "Corignac",
    "Corlay",
    "Cormainville",
    "Corme-Ecluse",
    "Corme-Royal",
    "Cormeilles",
    "Cormeilles-en-Parisis",
    "Cormeilles-en-Vexin",
    "Cormelles-le-Royal",
    "Cormeray",
    "Cormery",
    "Cormes",
    "Cormicy",
    "Cormolain",
    "Cormontreuil",
    "Cormoranche-sur-Saone",
    "Cornac",
    "Cornas",
    "Corne",
    "Cornebarrieu",
    "Corneilla-de-Conflent",
    "Corneilla-del-Vercol",
    "Corneilla-la-Riviere",
    "Cornier",
    "Cornille",
    "Cornillon-Confoux",
    "Cornillon-en-Trieves",
    "Cornimont",
    "Cornus",
    "Corny-sur-Moselle",
    "Coron",
    "Corpe",
    "Corps-Nuds",
    "Correze",
    "Corroy",
    "Cors",
    "Corsept",
    "Corseul",
    "Corte",
    "Corveissiat",
    "Corze",
    "Cosges",
    "Cosmes",
    "Cosne-Cours-sur-Loire",
    "Cosqueville",
    "Cosse-le-Vivien",
    "Cossesseville",
    "Costaros",
    "Cotdoussan",
    "Cotignac",
    "Cottenchy",
    "Cottevrard",
    "Cottun",
    "Couargues",
    "Coublevie",
    "Coubon",
    "Coubron",
    "Couches",
    "Couchey",
    "Coucy-le-Chateau-Auffrique",
    "Couddes",
    "Coudehard",
    "Coudekerque-Branche",
    "Coudes",
    "Coudoux",
    "Coudray",
    "Coudray-au-Perche",
    "Coudray-Rabut",
    "Coudroy",
    "Coudun",
    "Coueron",
    "Couesmes-Vauce",
    "Coufouleux",
    "Couhe",
    "Couilly-Pont-aux-Dames",
    "Couiza",
    "Coulaines",
    "Coulandon",
    "Coulans-sur-Gee",
    "Coulaures",
    "Coullons",
    "Coulogne",
    "Couloisy",
    "Coulombiers",
    "Coulombiers",
    "Coulomby",
    "Coulommes",
    "Coulommiers",
    "Coulommiers-la-Tour",
    "Coulon",
    "Coulonces",
    "Coulonges",
    "Coulonges-Thouarsais",
    "Coulounieix-Chamiers",
    "Coulouvray-Boisbenatre",
    "Coulvain",
    "Coulx",
    "Coume",
    "Coupelle-Vieille",
    "Coupesarte",
    "Coupeville",
    "Coupigny",
    "Coupru",
    "Coupvray",
    "Cour-Cheverny",
    "Cour-et-Buis",
    "Cour-sur-Loire",
    "Courances",
    "Courbeveille",
    "Courbevoie",
    "Courcais",
    "Courcay",
    "Courcelles",
    "Courcelles",
    "Courcelles-Chaussy",
    "Courcelles-en-Bassee",
    "Courcelles-Epayelles",
    "Courcelles-les-Gisors",
    "Courcelles-les-Lens",
    "Courcelles-sur-Nied",
    "Courcelles-sur-Seine",
    "Courcelles-sur-Viosne",
    "Courcemont",
    "Courcerac",
    "Courchaton",
    "Courchelettes",
    "Courchevel",
    "Courcon",
    "Courcouronnes",
    "Courcy",
    "Courcy",
    "Courcy-aux-Loges",
    "Courdemanche",
    "Courdemanche",
    "Courdimanche-sur-Essonne",
    "Courgis",
    "Courlans",
    "Courlaoux",
    "Courlay",
    "Courmelles",
    "Courmemin",
    "Cournon",
    "Cournon-dAuvergne",
    "Cournonsec",
    "Cournonterral",
    "Courpalay",
    "Courpiere",
    "Courquetaine",
    "Cours",
    "Cours-de-Monsegur",
    "Cours-la-Ville",
    "Cours-les-Bains",
    "Cours-les-Barres",
    "Coursac",
    "Coursan",
    "Courseulles-sur-Mer",
    "Courson",
    "Courson-les-Carrieres",
    "Courson-Monteloup",
    "Courtelevant",
    "Courtenay",
    "Courtenay",
    "Courtenot",
    "Courteranges",
    "Courthezon",
    "Courtisols",
    "Courtomer",
    "Courtonne-la-Meurdrac",
    "Courtry",
    "Courville-sur-Eure",
    "Courzieu",
    "Cousance",
    "Cousances-les-Forges",
    "Cousolre",
    "Coussay-les-Bois",
    "Coutances",
    "Coutarnoux",
    "Couterne",
    "Couternon",
    "Couteuges",
    "Coutevroult",
    "Coutiches",
    "Coutras",
    "Couvains",
    "Couville",
    "Couvron-et-Aumencourt",
    "Coux",
    "Couy",
    "Couze-et-Saint-Front",
    "Couzeix",
    "Couzon",
    "Coye-la-Foret",
    "Coyriere",
    "Cozes",
    "Crach",
    "Craincourt",
    "Cramant",
    "Cran-Gevrier",
    "Crancey",
    "Crancot",
    "Cransac",
    "Cranves-Sales",
    "Craon",
    "Craonne",
    "Craponne",
    "Cras-sur-Reyssouze",
    "Cravanche",
    "Cravans",
    "Cravant",
    "Cravant-les-Coteaux",
    "Cravent",
    "Crayssac",
    "Crazannes",
    "Creances",
    "Creches-sur-Saone",
    "Crecy-sur-Serre",
    "Credin",
    "Cregy-les-Meaux",
    "Crehange",
    "Crehen",
    "Creil",
    "Creissan",
    "Creissels",
    "Cremeaux",
    "Cremieu",
    "Crepey",
    "Cresancey",
    "Crespieres",
    "Crespin",
    "Crespin",
    "Cressat",
    "Cresse",
    "Cresserons",
    "Crest",
    "Creully",
    "Creusot",
    "Creutzwald",
    "Creuzier-le-Neuf",
    "Creuzier-le-Vieux",
    "Crevant-Laveine",
    "Crevechamps",
    "Crevecoeur-le-Grand",
    "Crevin",
    "Crezancy",
    "Criel-sur-Mer",
    "Crillon-le-Brave",
    "Crimolois",
    "Crisenoy",
    "Crisolles",
    "Crissey",
    "Crissey",
    "Crochte",
    "Crocq",
    "Croisette",
    "Croisilles",
    "Croisilles",
    "Croisilles",
    "Croissanville",
    "Croissy-Beaubourg",
    "Croissy-sur-Celle",
    "Croissy-sur-Seine",
    "Croix",
    "Croix",
    "Croix-Chapeau",
    "Croix-de-Vie",
    "Croix-Mare",
    "Croixanvec",
    "Crolles",
    "Cros-de-Ronesque",
    "Crosne",
    "Crottet",
    "Crouay",
    "Croutelle",
    "Crouttes",
    "Crouy-sur-Cosson",
    "Crouy-sur-Ourcq",
    "Croze",
    "Crozes-Hermitage",
    "Crozon",
    "Cruas",
    "Cruet",
    "Crugny",
    "Cruscades",
    "Cruseilles",
    "Crusnes",
    "Cruzilles-les-Mepillat",
    "Cruzy",
    "Cublac",
    "Cubry-les-Soing",
    "Cubzac-les-Ponts",
    "Cucq",
    "Cucuron",
    "Cuers",
    "Cuffies",
    "Cuffy",
    "Cugand",
    "Cuges-les-Pins",
    "Cugnaux",
    "Cuigy-en-Bray",
    "Cuinchy",
    "Cuincy",
    "Cuinzier",
    "Cuise-la-Motte",
    "Cuiseaux",
    "Cuisery",
    "Culhat",
    "Culmont",
    "Culoz",
    "Cumieres",
    "Cunfin",
    "Cunlhat",
    "Cuon",
    "Cuperly",
    "Cuqueron",
    "Curchy",
    "Curdin",
    "Curel",
    "Curgies",
    "Curtafond",
    "Curzay-sur-Vonne",
    "Cussay",
    "Cusset",
    "Cussey-les-Forges",
    "Cussy-les-Forges",
    "Custines",
    "Cusy",
    "Cuttoli-Corticchiato",
    "Cuvergnon",
    "Cuverville",
    "Cuvilly",
    "Cuvry",
    "Cuxac-Cabardes",
    "Cuy",
    "Cuy-Saint-Fiacre",
    "Cuzieu",
    "Cysoing",
    "Dabo",
    "Dachstein",
    "Dadonville",
    "Dagneux",
    "Dainville",
    "Dalhunden",
    "Dallet",
    "Dalmas",
    "Damazan",
    "Dambach-la-Ville",
    "Dambenois",
    "Dame-Marie",
    "Damelevieres",
    "Damerey",
    "Damery",
    "Damgan",
    "Damiatte",
    "Damigny",
    "Dammarie-les-Lys",
    "Dammarie-sur-Saulx",
    "Dammartin-en-Goele",
    "Dammartin-en-Serve",
    "Damparis",
    "Dampierre",
    "Dampierre-au-Temple",
    "Dampierre-en-Burly",
    "Dampierre-en-Crot",
    "Dampierre-les-Bois",
    "Dampierre-les-Conflans",
    "Dampierre-sur-Blevy",
    "Dampierre-sur-Boutonne",
    "Dampmart",
    "Damprichard",
    "Dampsmesnil",
    "Dampvalley-les-Colombe",
    "Damville",
    "Damvix",
    "Dancevoir",
    "Dangeau",
    "Dangeul",
    "Danjoutin",
    "Danne-et-Quatre-Vents",
    "Dannemoine",
    "Daoulas",
    "Dardilly",
    "Dareize",
    "Dargnies",
    "Dargoire",
    "Darmont",
    "Darnetal",
    "Darney",
    "Darnieulles",
    "Darvoy",
    "Dasle",
    "Daubensand",
    "Daubeuf-la-Campagne",
    "Dauendorf",
    "Daumeray",
    "Daux",
    "Dauzat-sur-Vodable",
    "Davenescourt",
    "Davezieux",
    "Dax",
    "Deauville",
    "Decazeville",
    "Dechy",
    "Decines-Charpieu",
    "Decize",
    "Delettes",
    "Delle",
    "Delme",
    "Demouville",
    "Demuin",
    "Denain",
    "Denat",
    "Denee",
    "Denevre",
    "Denice",
    "Denier",
    "Dennebroeucq",
    "Denneville",
    "Denney",
    "Denting",
    "Dercy",
    "Dernancourt",
    "Derval",
    "Desaignes",
    "Desertines",
    "Desvres",
    "Detrier",
    "Dettwiller",
    "Deuil-la-Barre",
    "Deulemont",
    "Devecey",
    "Devillac",
    "Deville-les-Rouen",
    "Devrouze",
    "Deyme",
    "Deyvillers",
    "Dezize-les-Maranges",
    "Dhuisy",
    "Diarville",
    "Didenheim",
    "Die",
    "Diebling",
    "Dieffenbach-au-Val",
    "Diemeringen",
    "Diemoz",
    "Dienville",
    "Dieppe",
    "Dieppe-sous-Douaumont",
    "Dierre",
    "Dietwiller",
    "Dieue-sur-Meuse",
    "Dieulefit",
    "Dieulouard",
    "Dieupentale",
    "Dieuze",
    "Dieval",
    "Diffembach-les-Hellimer",
    "Diges",
    "Digne-les-Bains",
    "Digoin",
    "Dijon",
    "Dimbsthal",
    "Dimechaux",
    "Dinan",
    "Dinard",
    "Dinge",
    "Dingsheim",
    "Dingy-en-Vuache",
    "Dingy-Saint-Clair",
    "Dinoze",
    "Dionay",
    "Dissay",
    "Disse-sous-Ballon",
    "Distre",
    "Distroff",
    "Dive",
    "Dives-sur-Mer",
    "Divion",
    "Divonne-les-Bains",
    "Dixmont",
    "Dizy",
    "Doazit",
    "Docelles",
    "Doeuil-sur-le-Mignon",
    "Dogneville",
    "Doingt",
    "Doissin",
    "Doix",
    "Dol-de-Bretagne",
    "Dole",
    "Dollon",
    "Dolo",
    "Dolomieu",
    "Domagne",
    "Domaize",
    "Domalain",
    "Domancy",
    "Domart-sur-la-Luce",
    "Domats",
    "Dombasle-sur-Meurthe",
    "Domene",
    "Domerat",
    "Domessargues",
    "Domessin",
    "Domevre-sur-Durbion",
    "Domezain-Berraute",
    "Domfront",
    "Domgermain",
    "Domjevin",
    "Dommartin",
    "Dommartin",
    "Dommartin-les-Remiremont",
    "Dommartin-les-Toul",
    "Domont",
    "Dompierre-les-Ormes",
    "Dompierre-sur-Besbre",
    "Dompierre-sur-Mer",
    "Dompierre-sur-Veyle",
    "Dompierre-sur-Yon",
    "Donchery",
    "Doncourt-les-Conflans",
    "Donges",
    "Donjeux",
    "Donnemarie-Dontilly",
    "Donnery",
    "Donneville",
    "Donnezac",
    "Dontrien",
    "Donville-les-Bains",
    "Donzacq",
    "Donzere",
    "Donzy",
    "Donzy-le-Pertuis",
    "Dordives",
    "Dorlisheim",
    "Dormans",
    "Dormelles",
    "Dornes",
    "Dornot",
    "Dortan",
    "Dorval",
    "Douai",
    "Douarnenez",
    "Doubs",
    "Douchy",
    "Douchy-les-Mines",
    "Doudeville",
    "Doue-la-Fontaine",
    "Douilly",
    "Doulezon",
    "Doullens",
    "Doumy",
    "Dounoux",
    "Dourdain",
    "Dourdan",
    "Dourges",
    "Dourgne",
    "Doussard",
    "Douvaine",
    "Douville",
    "Douvres",
    "Douvres-la-Delivrande",
    "Douvrin",
    "Doux",
    "Douy-la-Ramee",
    "Douzy",
    "Doville",
    "Doyet",
    "Dozule",
    "Drace",
    "Drachenbronn-Birlenbach",
    "Dracy-le-Fort",
    "Draguignan",
    "Draillant",
    "Drain",
    "Drancy",
    "Drap",
    "Draveil",
    "Dreffeac",
    "Dremil-Lafage",
    "Dreslincourt",
    "Dreuil-les-Amiens",
    "Dreux",
    "Dreville",
    "Drocourt",
    "Drom",
    "Droupt-Saint-Basle",
    "Drouville",
    "Druillat",
    "Drulingen",
    "Drusenheim",
    "Druye",
    "Druyes-les-Belles-Fontaines",
    "Dry",
    "Ducey",
    "Duclair",
    "Duerne",
    "Dugny",
    "Dugny-sur-Meuse",
    "Duingt",
    "Duisans",
    "Dullin",
    "Dun-le-Poelier",
    "Dun-sur-Auron",
    "Duneau",
    "Dunes",
    "Duniere",
    "Dunkirk",
    "Duppigheim",
    "Durance",
    "Duranus",
    "Durban-Corbieres",
    "Durcet",
    "Durette",
    "Durfort",
    "Durlinsdorf",
    "Durmenach",
    "Durrenbach",
    "Durrenentzen",
    "Durstel",
    "Durtal",
    "Durtol",
    "Dury",
    "Duttlenheim",
    "Eance",
    "Eaubonne",
    "Eaucourt-sur-Somme",
    "Eaunes",
    "Eaux-Bonnes",
    "Eaux-Puiseaux",
    "Eauze",
    "Ebersviller",
    "Eblange",
    "Ecaillon",
    "Ecalles-Alix",
    "Ecaquelon",
    "Ecardenville-la-Campagne",
    "Ecardenville-sur-Eure",
    "Ecault",
    "Eccles",
    "Echalas",
    "Echarcon",
    "Echauffour",
    "Echavanne",
    "Echemire",
    "Echenans",
    "Echenevex",
    "Echenon",
    "Echenoz-la-Meline",
    "Echevannes",
    "Echevis",
    "Echillais",
    "Echinghen",
    "Echire",
    "Eckbolsheim",
    "Eckwersheim",
    "Eclaron-Braucourt-Sainte-Liviere",
    "Ecleux",
    "Ecluzelles",
    "Ecoche",
    "Ecole",
    "Ecole-Valentin",
    "Ecommoy",
    "Ecorcei",
    "Ecos",
    "Ecouen",
    "Ecouflant",
    "Ecourt-Saint-Quentin",
    "Ecouviez",
    "Ecoyeux",
    "Ecques",
    "Ecquevilly",
    "Ecretteville-les-Baons",
    "Ecrosnes",
    "Ecrouves",
    "Ectot-les-Baons",
    "Ecueil",
    "Ecueille",
    "Ecuelles",
    "Ecuille",
    "Ecuires",
    "Ecuisses",
    "Ecully",
    "Ecury-sur-Coole",
    "Edern",
    "Effiat",
    "Effry",
    "Egletons",
    "Egligny",
    "Egly",
    "Egreville",
    "Eguilles",
    "Eichhoffen",
    "Einville-au-Jard",
    "el Volo",
    "Elbeuf",
    "Eletot",
    "Eleu-dit-Leauwette",
    "Elincourt",
    "Elincourt-Sainte-Marguerite",
    "Elliant",
    "Ellon",
    "Elna",
    "Elnes",
    "Eloise",
    "Eloyes",
    "els Banys de la Presta",
    "Elven",
    "Emance",
    "Embrun",
    "Embry",
    "Emerainville",
    "Emieville",
    "Emmerin",
    "Enchenberg",
    "Enencourt-le-Sec",
    "Enghien-les-Bains",
    "Engins",
    "Englefontaine",
    "Englos",
    "Ennery",
    "Ennery",
    "Ennevelin",
    "Ennezat",
    "Ennordres",
    "Enquin-les-Mines",
    "Ensisheim",
    "Ensues-la-Redonne",
    "Entraigues-sur-la-Sorgue",
    "Entrains-sur-Nohain",
    "Entrammes",
    "Entrange",
    "Entraygues-sur-Truyere",
    "Entre-deux-Guiers",
    "Entrecasteaux",
    "Entremont-le-Vieux",
    "Entressen",
    "Entzheim",
    "Enveig",
    "Envermeu",
    "Epagne-Epagnette",
    "Epagny",
    "Epaignes",
    "Epegard",
    "Epenancourt",
    "Epenede",
    "Epercieux-Saint-Paul",
    "Eperlecques",
    "Epernon",
    "Epertully",
    "Epervans",
    "Epfig",
    "Epiais-Rhus",
    "Epieds-en-Beauce",
    "Epierre",
    "Epinac",
    "Epinay",
    "Epinay-sous-Senart",
    "Epineuil-le-Fleuriel",
    "Epinouze",
    "Epinoy",
    "Episy",
    "Epoisses",
    "Epone",
    "Epouville",
    "Eppe-Sauvage",
    "Eppeville",
    "Epping",
    "Epuisay",
    "Equeurdreville-Hainneville",
    "Equihen-Plage",
    "Er",
    "Eragny",
    "Erbray",
    "Erbree",
    "Erce-en-Lamee",
    "Erce-pres-Liffre",
    "Erceville",
    "Ercheu",
    "Erdeven",
    "Ergersheim",
    "Ergny",
    "Ergue-Gaberic",
    "Erize-Saint-Dizier",
    "Ermenonville",
    "Ermont",
    "Ernee",
    "Ernemont-sur-Buchy",
    "Ernestviller",
    "Ernolsheim-Bruche",
    "Erome",
    "Eroudeville",
    "Erquinghem-Lys",
    "Erquinvillers",
    "Erquy",
    "Erre",
    "Errouville",
    "Erstein",
    "Ervauville",
    "Esbarres",
    "Esbly",
    "Escalquens",
    "Escames",
    "Escassefort",
    "Escaudain",
    "Escaudoeuvres",
    "Escautpont",
    "Escazeaux",
    "Eschau",
    "Eschbach-au-Val",
    "Eschentzwiller",
    "Esches",
    "Esclainvillers",
    "Escolives-Sainte-Camille",
    "Escombres-et-le-Chesnois",
    "Escondeaux",
    "Escorneboeuf",
    "Escou",
    "Escout",
    "Escoutoux",
    "Escurolles",
    "Esery",
    "Eslettes",
    "Esmery-Hallon",
    "Esnandes",
    "Esnouveaux",
    "Espagnac",
    "Espalais",
    "Espalion",
    "Espaly-Saint-Marcel",
    "Esparron-de-Verdon",
    "Espedaillac",
    "Espelette",
    "Espeluche",
    "Espezel",
    "Espiet",
    "Espinasses",
    "Espira-de-Conflent",
    "Espirat",
    "Espondeilhan",
    "Esquay-Notre-Dame",
    "Esquay-sur-Seulles",
    "Esquelbecq",
    "Esquerchin",
    "Esquerdes",
    "Esquibien",
    "Esquieze-Sere",
    "Essegney",
    "Essert",
    "Essertaux",
    "Esserts-Saleve",
    "Essey",
    "Essey-les-Nancy",
    "Essia",
    "Essigny-le-Grand",
    "Esson",
    "Essoyes",
    "Estadens",
    "Estagel",
    "Estaing",
    "Estaires",
    "Esternay",
    "Estevelles",
    "Estillac",
    "Estivareilles",
    "Estouches",
    "Estouteville-Ecalles",
    "Estouy",
    "Estrablin",
    "Estree-Blanche",
    "Estrees",
    "Estrees-les-Crecy",
    "Estrees-Saint-Denis",
    "Estrees-sur-Noye",
    "Esvres",
    "Etable",
    "Etables",
    "Etables-sur-Mer",
    "Etaimpuis",
    "Etaing",
    "Etainhus",
    "Etalondes",
    "Etampes-sur-Marne",
    "Etang-sur-Arroux",
    "Etaules",
    "Etauliers",
    "Etaux",
    "Etaves-et-Bocquiaux",
    "Eteimbes",
    "Etel",
    "Etelfay",
    "Etercy",
    "Eternoz",
    "Eterville",
    "Etevaux",
    "Eth",
    "Etigny",
    "Etiolles",
    "Etival",
    "Etival-Clairefontaine",
    "Etival-les-le-Mans",
    "Etoges",
    "Etoile-sur-Rhone",
    "Etourvy",
    "Etouvans",
    "Etouvy",
    "Etrechy",
    "Etrechy",
    "Etreillers",
    "Etrelles",
    "Etrembieres",
    "Etrepagny",
    "Etrepilly",
    "Etreux",
    "Etrez",
    "Etriac",
    "Etriche",
    "Etricourt-Manancourt",
    "Etroeungt",
    "Etroussat",
    "Ettendorf",
    "Etting",
    "Etupes",
    "Etuz",
    "Etzling",
    "Eu",
    "Eulmont",
    "Euville",
    "Evans",
    "Evenos",
    "Everly",
    "Eveux",
    "Evin-Malmaison",
    "Evran",
    "Evrecy",
    "Evron",
    "Excenevex",
    "Excideuil",
    "Exideuil",
    "Exincourt",
    "Exireuil",
    "Exoudun",
    "Eybens",
    "Eybouleuf",
    "Eygalieres",
    "Eyguieres",
    "Eygurande-et-Gardedeuil",
    "Eyjeaux",
    "Eyliac",
    "Eymet",
    "Eymoutiers",
    "Eyragues",
    "Eyrans",
    "Eysines",
    "Eyzin-Pinet",
    "Ezanville",
    "Eze",
    "Ezy-sur-Eure",
    "Fabregues",
    "Fabrezan",
    "Faches",
    "Faches-Thumesnil",
    "Fagnieres",
    "Faimbe",
    "Fains-Veel",
    "Falaise",
    "Falaise",
    "Falck",
    "Falicon",
    "Fallerans",
    "Falleron",
    "Falletans",
    "Famars",
    "Fameck",
    "Fampoux",
    "Fanjeaux",
    "Faramans",
    "Farbus",
    "Farebersviller",
    "Fareins",
    "Faremoutiers",
    "Farges-les-Chalon",
    "Fargues",
    "Fargues",
    "Fargues-Saint-Hilaire",
    "Farincourt",
    "Fatouville-Grestain",
    "Fauch",
    "Faucigny",
    "Faucogney-et-la-Mer",
    "Faucompierre",
    "Faulquemont",
    "Faulx",
    "Faumont",
    "Fauquembergues",
    "Fauville-en-Caux",
    "Favars",
    "Faverges",
    "Faverges-de-la-Tour",
    "Faverolles-la-Campagne",
    "Faverolles-sur-Cher",
    "Favieres",
    "Favieres",
    "Fay-aux-Loges",
    "Fay-de-Bretagne",
    "Fay-les-Nemours",
    "Fayence",
    "Fayet-le-Chateau",
    "Fayl-Billot",
    "Fays-la-Chapelle",
    "Fayssac",
    "Feas",
    "Fechain",
    "Fegersheim",
    "Fegreac",
    "Feigeres",
    "Feignies",
    "Feillens",
    "Fein",
    "Feissons-sur-Isere",
    "Feldkirch",
    "Felines",
    "Felines",
    "Felix",
    "Fellering",
    "Felletin",
    "Fenain",
    "Fenay",
    "Fendeille",
    "Fenetrange",
    "Feneu",
    "Feneyrols",
    "Fenioux",
    "Fenouillet",
    "Fenouillet",
    "Fere-Champenoise",
    "Fere-en-Tardenois",
    "Ferel",
    "Ferfay",
    "Fericy",
    "Ferin",
    "Fermanville",
    "Ferney-Voltaire",
    "Ferolles-Attilly",
    "Ferques",
    "Ferrals-les-Corbieres",
    "Ferreol",
    "Ferrette",
    "Ferreux-Quincey",
    "Ferriere-la-Grande",
    "Ferriere-la-Petite",
    "Ferriere-Larcon",
    "Ferrieres",
    "Ferrieres",
    "Ferrieres-en-Brie",
    "Ferrieres-en-Gatinais",
    "Ferrieres-Haut-Clocher",
    "Ferrieres-les-Scey",
    "Ferrieres-sur-Ariege",
    "Fesches-le-Chatel",
    "Fesques",
    "Fessenheim",
    "Festieux",
    "Festubert",
    "Feternes",
    "Feucherolles",
    "Feuges",
    "Feuguerolles-sur-Seulles",
    "Feuilla",
    "Feuquieres",
    "Feuquieres-en-Vimeu",
    "Feurs",
    "Feves",
    "Fey",
    "Feytiat",
    "Feyzin",
    "Fiefs",
    "Fiennes",
    "Fierville-Bray",
    "Fierville-les-Mines",
    "Figanieres",
    "Figeac",
    "Fignieres",
    "Filain",
    "Fille",
    "Fillievres",
    "Fillinges",
    "Filstroff",
    "Finhan",
    "Firmi",
    "Firminy",
    "Fislis",
    "Fismes",
    "Fitilieu",
    "Fitz-James",
    "Flagnac",
    "Flamanville",
    "Flamarens",
    "Flancourt-Catelon",
    "Flangebouche",
    "Flaucourt",
    "Flaux",
    "Flavacourt",
    "Flavigny",
    "Flavigny-sur-Moselle",
    "Flavin",
    "Flavy-le-Martel",
    "Flaxlanden",
    "Flayosc",
    "Fleac",
    "Flechin",
    "Flee",
    "Flers",
    "Flers-en-Escrebieux",
    "Flers-sur-Noye",
    "Fletrange",
    "Fletre",
    "Fleurance",
    "Fleurbaix",
    "Fleure",
    "Fleurey-sur-Ouche",
    "Fleurie",
    "Fleuriel",
    "Fleury",
    "Fleury-en-Biere",
    "Fleury-les-Aubrais",
    "Fleury-Merogis",
    "Fleury-sur-Andelle",
    "Fleury-sur-Orne",
    "Fleville-devant-Nancy",
    "Flevy",
    "Flines-les-Mortagne",
    "Flines-lez-Raches",
    "Flins-sur-Seine",
    "Flixecourt",
    "Floing",
    "Floirac",
    "Florac",
    "Florange",
    "Florensac",
    "Florent-en-Argonne",
    "Florentin",
    "Florentin",
    "Floyon",
    "Flumet",
    "Foameix-Ornel",
    "Foecy",
    "Foissiat",
    "Foix",
    "Folelli",
    "Folembray",
    "Folgensbourg",
    "Follainville-Dennemont",
    "Folles",
    "Folleville",
    "Folschviller",
    "Fonbeauzard",
    "Foncine-le-Haut",
    "Fondettes",
    "Fons",
    "Fons",
    "Fonsorbes",
    "Font-Romeu-Odeillo-Via",
    "Fontain",
    "Fontaine",
    "Fontaine",
    "Fontaine-au-Bois",
    "Fontaine-Bonneleau",
    "Fontaine-en-Dormois",
    "Fontaine-Etoupefour",
    "Fontaine-Guerin",
    "Fontaine-Heudebourg",
    "Fontaine-la-Mallet",
    "Fontaine-le-Comte",
    "Fontaine-le-Dun",
    "Fontaine-le-Pin",
    "Fontaine-les-Bassets",
    "Fontaine-les-Dijon",
    "Fontaine-les-Gres",
    "Fontaine-les-Luxeuil",
    "Fontaine-Milon",
    "Fontaine-Notre-Dame",
    "Fontaine-Notre-Dame",
    "Fontaine-Simon",
    "Fontaine-sous-Jouy",
    "Fontaine-sous-Preaux",
    "Fontaine-sur-Somme",
    "Fontaine-Uterte",
    "Fontainebleau",
    "Fontaines",
    "Fontaines",
    "Fontaines-Saint-Martin",
    "Fontaines-sur-Saone",
    "Fontanes",
    "Fontanil-Cornillon",
    "Fontannes",
    "Fontcouverte",
    "Fontenai-les-Louvets",
    "Fontenay",
    "Fontenay-aux-Roses",
    "Fontenay-en-Parisis",
    "Fontenay-le-Comte",
    "Fontenay-le-Fleury",
    "Fontenay-le-Vicomte",
    "Fontenay-sous-Bois",
    "Fontenay-sur-Loing",
    "Fontenay-sur-Vegre",
    "Fontenay-Tresigny",
    "Fontenelle-en-Brie",
    "Fontenilles",
    "Fontenoy",
    "Fontenoy-la-Joute",
    "Fontenoy-le-Chateau",
    "Fontes",
    "Fontjoncouse",
    "Fontpedrosa",
    "Fontrabiouse",
    "Fontvannes",
    "Fontvieille",
    "Forbach",
    "Forcalqueiret",
    "Forcalquier",
    "Force",
    "Forest-sur-Marque",
    "Foret-la-Folie",
    "Forfry",
    "Forges",
    "Forges-les-Bains",
    "Forges-les-Eaux",
    "Forleans",
    "Formerie",
    "Fors",
    "Fort-Mahon-Plage",
    "Fort-Mardyck",
    "Fortschwihr",
    "Fos-sur-Mer",
    "Fosse",
    "Fossemanant",
    "Fosses",
    "Fosses-et-Baleyssac",
    "Fouchy",
    "Fouday",
    "Fouencamps",
    "Fouesnant",
    "Foug",
    "Fougax-et-Barrineuf",
    "Fougere",
    "Fougeres",
    "Fougeres",
    "Fougeres-sur-Bievre",
    "Fougerolles",
    "Fouillard",
    "Fouilleuse",
    "Foulangues",
    "Foulayronnes",
    "Foulcrey",
    "Fouquebrune",
    "Fouquieres-les-Lens",
    "Four",
    "Fouras",
    "Fourchambault",
    "Fourches",
    "Fourdrinoy",
    "Fourg",
    "Fourmies",
    "Fournes-en-Weppes",
    "Fournier",
    "Fournival",
    "Fournols",
    "Fouronnes",
    "Fourques",
    "Fourques-sur-Garonne",
    "Fourqueux",
    "Fours-Saint-Laurent",
    "Foussemagne",
    "Fragnes",
    "Fraisans",
    "Fraisses",
    "Fraize",
    "Fralignes",
    "Frambouhans",
    "Framerville-Rainecourt",
    "Francaltroff",
    "Francardo",
    "Francheleins",
    "Francheville",
    "Francieres",
    "Franclens",
    "Francois",
    "Franconville",
    "Francourville",
    "Frangy",
    "Franois",
    "Franqueville",
    "Franvillers",
    "Franxault",
    "Frauenberg",
    "Frebuans",
    "Frechede",
    "Frechencourt",
    "Frechou",
    "Fredille",
    "Fregimont",
    "Fregouville",
    "Freistroff",
    "Frejairolles",
    "Freland",
    "Frelinghien",
    "Fremainville",
    "Fremifontaine",
    "Fremonville",
    "Frencq",
    "Frenes",
    "Freneuse",
    "Frepillon",
    "Fresnay-en-Retz",
    "Fresnay-le-Gilmert",
    "Fresnay-sur-Sarthe",
    "Fresne",
    "Fresne-Cauverville",
    "Fresne-la-Mere",
    "Fresne-Saint-Mames",
    "Fresneaux-Montchevreuil",
    "Fresnes",
    "Fresnes-au-Mont",
    "Fresnes-en-Woevre",
    "Fresnes-les-Montauban",
    "Fresnes-sur-Escaut",
    "Fresnes-sur-Marne",
    "Fresnicourt-le-Dolmen",
    "Fresnoy-la-Riviere",
    "Fresnoy-le-Grand",
    "Frespech",
    "Fressac",
    "Fressain",
    "Fresse",
    "Fresse-sur-Moselle",
    "Fressenneville",
    "Fressies",
    "Fressines",
    "Frethun",
    "Fretigney-et-Velloreille",
    "Fretin",
    "Frevent",
    "Frevillers",
    "Frevin-Capelle",
    "Friauville",
    "Frieres-Faillouel",
    "Friesen",
    "Friville-Escarbotin",
    "Frocourt",
    "Froges",
    "Froidestrees",
    "Froidfond",
    "Froissy",
    "Fromelles",
    "Fromont",
    "Fromonville",
    "Froncles",
    "Fronsac",
    "Frontenac",
    "Frontenas",
    "Frontenay-Rohan-Rohan",
    "Frontenay-sur-Dive",
    "Frontenex",
    "Frontignan",
    "Frontignan-Saves",
    "Fronton",
    "Frontonas",
    "Frossay",
    "Frouard",
    "Frouzins",
    "Frozes",
    "Frucourt",
    "Frugeres-les-Mines",
    "Fruges",
    "Fuisse",
    "Fultot",
    "Fumay",
    "Fumel",
    "Furchhausen",
    "Furiani",
    "Fussy",
    "Fuveau",
    "Fye",
    "Gaas",
    "Gabaston",
    "Gabat",
    "Gace",
    "Gagnac-sur-Garonne",
    "Gagnieres",
    "Gagny",
    "Gahard",
    "Gailhan",
    "Gaillac",
    "Gaillac-Toulza",
    "Gaillan-en-Medoc",
    "Gaillard",
    "Gaillefontaine",
    "Gaillon",
    "Galametz",
    "Galapian",
    "Galargues",
    "Galfingue",
    "Galgon",
    "Galie",
    "Gallardon",
    "Gallician",
    "Galluis",
    "Gamaches",
    "Gamarde-les-Bains",
    "Gambais",
    "Gambsheim",
    "Gan",
    "Ganac",
    "Gandrange",
    "Ganges",
    "Gannat",
    "Gap",
    "Garancieres-en-Drouais",
    "Garanou",
    "Garat",
    "Garche",
    "Garches",
    "Garchizy",
    "Gardanne",
    "Gardonne",
    "Garencieres",
    "Garennes-sur-Eure",
    "Gareoult",
    "Gargenville",
    "Garlan",
    "Garlin",
    "Garons",
    "Garrevaques",
    "Garrigues",
    "Garris",
    "Garrosse",
    "Gas",
    "Gasny",
    "Gassin",
    "Gasville-Oiseme",
    "Gatelles",
    "Gatey",
    "Gattieres",
    "Gauchy",
    "Gauciel",
    "Gauriac",
    "Gauriaguet",
    "Gauville-la-Campagne",
    "Gavarnie",
    "Gavray",
    "Gaye",
    "Geanges",
    "Geaune",
    "Geay",
    "Gee",
    "Geispolsheim",
    "Gelannes",
    "Gelles",
    "Gelos",
    "Geloux",
    "Gemeaux",
    "Gemenos",
    "Gemil",
    "Gemozac",
    "Genac",
    "Genas",
    "Genay",
    "Gencay",
    "Gendrey",
    "Gene",
    "Genebrieres",
    "Genech",
    "Genelard",
    "Generac",
    "Generargues",
    "Genet",
    "Geneuille",
    "Geney",
    "Genille",
    "Genis",
    "Genissac",
    "Genissiat",
    "Genissieux",
    "Genlis",
    "Gennes",
    "Gennes-sur-Seiche",
    "Gennetines",
    "Gennevilliers",
    "Gensac",
    "Gensac-la-Pallue",
    "Gente",
    "Gentilly",
    "Georges",
    "Geovreisset",
    "Ger",
    "Ger",
    "Gerard",
    "Gerbaix",
    "Gerbecourt-et-Haplemont",
    "Gerbeviller",
    "Gergy",
    "Gerland",
    "Germaine",
    "Germainville",
    "Germenay",
    "Germigny-sur-Loire",
    "Germond-Rouvre",
    "Gerstheim",
    "Gervais",
    "Gervans",
    "Gerzat",
    "Gesnes-le-Gandelin",
    "Geste",
    "Gestel",
    "Gesvres",
    "Getigne",
    "Geudertheim",
    "Geveze",
    "Gevrey-Chambertin",
    "Gevrieux",
    "Gex",
    "Geyssans",
    "Gezaincourt",
    "Gezoncourt",
    "Ghisonaccia",
    "Ghyvelde",
    "Giat",
    "Giberville",
    "Gidy",
    "Gien",
    "Gieres",
    "Gievres",
    "Gif-sur-Yvette",
    "Gigean",
    "Gignac",
    "Gignac",
    "Gignac-la-Nerthe",
    "Gigny-sur-Saone",
    "Gilette",
    "Gilles",
    "Gilley",
    "Gilly-les-Citeaux",
    "Gilly-sur-Isere",
    "Gimbrett",
    "Gimeux",
    "Gimont",
    "Ginasservis",
    "Ginestas",
    "Ginestet",
    "Girancourt",
    "Giraumont",
    "Girolles",
    "Giromagny",
    "Giron",
    "Gironcourt-sur-Vraine",
    "Gironde-sur-Dropt",
    "Giroussens",
    "Gisors",
    "Givenchy-en-Gohelle",
    "Givet",
    "Givors",
    "Givraines",
    "Givrand",
    "Givrauval",
    "Givron",
    "Givry",
    "Glanon",
    "Glatigny",
    "Gleize",
    "Glenic",
    "Glomel",
    "Glos-sur-Risle",
    "Goderville",
    "Godewaersvelde",
    "Goersdorf",
    "Goes",
    "Goetzenbruck",
    "Goeulzin",
    "Goin",
    "Goincourt",
    "Golbey",
    "Golfe-Juan",
    "Gombergean",
    "Gomelange",
    "Gomene",
    "Gomer",
    "Gometz-la-Ville",
    "Gometz-le-Chatel",
    "Gommegnies",
    "Gommerville",
    "Gommerville",
    "Goncelin",
    "Gondecourt",
    "Gondeville",
    "Gondrecourt-le-Chateau",
    "Gondreville",
    "Gonesse",
    "Gonfaron",
    "Gonfreville-Caillot",
    "Gonfreville-lOrcher",
    "Gonnehem",
    "Gonneville-la-Mallet",
    "Gonneville-sur-Mer",
    "Gontier",
    "Gorbio",
    "Gorcy",
    "Gordes",
    "Gorges",
    "Gornac",
    "Gorre",
    "Gorron",
    "Gorze",
    "Gosnay",
    "Gosselming",
    "Gottenhouse",
    "Gouaix",
    "Gouaux",
    "Goudelin",
    "Goudourville",
    "Gouesnach",
    "Gouesnou",
    "Gouezec",
    "Gouillons",
    "Gouise",
    "Goulet",
    "Goult",
    "Goupillieres",
    "Goupillieres",
    "Gourdan-Polignan",
    "Gourdon",
    "Gourge",
    "Gourhel",
    "Gourin",
    "Gourlizon",
    "Gournay-en-Bray",
    "Gournay-sur-Marne",
    "Gourvillette",
    "Goury",
    "Goussainville",
    "Goussainville",
    "Gousse",
    "Goutrens",
    "Gouts",
    "Gouttieres",
    "Gouvernes",
    "Gouves",
    "Gouvieux",
    "Gouville-sur-Mer",
    "Goux-les-Dambelin",
    "Goux-les-Usiers",
    "Gouy-en-Artois",
    "Gouy-en-Ternois",
    "Gouy-Saint-Andre",
    "Gouy-sous-Bellonne",
    "Gouze",
    "Gouzeaucourt",
    "Goven",
    "Goxwiller",
    "Goyrans",
    "Grabels",
    "Gracay",
    "Graces",
    "Gradignan",
    "Gragnague",
    "Graincourt-les-Havrincourt",
    "Grainville",
    "Grainville-la-Teinturiere",
    "Grainville-sur-Odon",
    "Graissessac",
    "Gramat",
    "Grammond",
    "Grand",
    "Grand Champ",
    "Grand-Auverne",
    "Grand-Charmont",
    "Grand-Fort-Philippe",
    "Grand-Fougeray",
    "Grand-Laviers",
    "Grand-Rozoy",
    "Grandchamp",
    "Grandchamps-des-Fontaines",
    "Grande-Synthe",
    "Grandfontaine",
    "Grandfontaine-Fournets",
    "Grandfresnoy",
    "Grandpre",
    "Grandris",
    "Grandsaigne",
    "Grandvaux",
    "Grandvillars",
    "Grandville-Gaudreville",
    "Grandvillers",
    "Grandvilliers",
    "Grane",
    "Granges",
    "Granges-le-Bourg",
    "Granges-sur-Vologne",
    "Grangues",
    "Grans",
    "Granville",
    "Grasse",
    "Grassendorf",
    "Gratentour",
    "Gratot",
    "Graulhet",
    "Grauves",
    "Gravelines",
    "Graveron-Semerville",
    "Graveson",
    "Gravigny",
    "Gray",
    "Grazac",
    "Grazay",
    "Greasque",
    "Gremecey",
    "Gremevillers",
    "Gremonville",
    "Grenade",
    "Grenay",
    "Greneville-en-Beauce",
    "Grenoble",
    "Grentheville",
    "Grentzingen",
    "Greolieres",
    "Greoux-les-Bains",
    "Grepiac",
    "Gresse-en-Vercors",
    "Gresswiller",
    "Gressy",
    "Gresy-sur-Aix",
    "Gresy-sur-Isere",
    "Gretz-Armainvilliers",
    "Grez-en-Bouere",
    "Grez-sur-Loing",
    "Grezieu-la-Varenne",
    "Grezillac",
    "Grezolles",
    "Gries",
    "Griesbach-au-Val",
    "Griesheim-pres-Molsheim",
    "Grignan",
    "Grignols",
    "Grigny",
    "Grigny",
    "Grillon",
    "Grilly",
    "Grimaud",
    "Gripport",
    "Griselles",
    "Griselles",
    "Grisolles",
    "Grisy-Suisnes",
    "Grisy-sur-Seine",
    "Grivillers",
    "Groffliers",
    "Groissiat",
    "Groisy",
    "Groix",
    "Gron",
    "Gros-Rederching",
    "Grosbliederstroff",
    "Grosbreuil",
    "Groslay",
    "Grosrouvre",
    "Grosseto-Prugna",
    "Grossoeuvre",
    "Grozon",
    "Gruchet-le-Valasse",
    "Gruey-les-Surance",
    "Gruffy",
    "Grugies",
    "Gruissan",
    "Grun-Bordas",
    "Grundviller",
    "Gruson",
    "Grussenheim",
    "Guebwiller",
    "Guecelard",
    "Guegon",
    "Guemar",
    "Guemene-Penfao",
    "Guemene-sur-Scorff",
    "Guenange",
    "Guengat",
    "Guenin",
    "Guenrouet",
    "Guer",
    "Guerande",
    "Guerard",
    "Guercheville",
    "Gueret",
    "Guerigny",
    "Guerlesquin",
    "Guernes",
    "Guerting",
    "Guerville",
    "Guerville",
    "Guesnain",
    "Guessling-Hemering",
    "Guethary",
    "Gueugnon",
    "Gueures",
    "Gueux",
    "Guewenheim",
    "Guiche",
    "Guichen",
    "Guiclan",
    "Guidel",
    "Guignen",
    "Guignes",
    "Guigneville",
    "Guignicourt",
    "Guignicourt-sur-Vence",
    "Guiler-sur-Goyen",
    "Guilers",
    "Guilherand-Granges",
    "Guillestre",
    "Guilliers",
    "Guilly",
    "Guilvinec",
    "Guines",
    "Guingamp",
    "Guipavas",
    "Guipel",
    "Guipronvel",
    "Guipry",
    "Guiscard",
    "Guise",
    "Guiseniers",
    "Guitalens",
    "Guivry",
    "Gujan-Mestras",
    "Gumbrechtshoffen",
    "Gundershoffen",
    "Gundolsheim",
    "Guntzviller",
    "Guny",
    "Gurs",
    "Guyancourt",
    "Gy-en-Sologne",
    "Habas",
    "Habere-Lullin",
    "Habsheim",
    "Hadol",
    "Hagenbach",
    "Hagenthal-le-Haut",
    "Hagetmau",
    "Hageville",
    "Hagondange",
    "Haguenau",
    "Haillicourt",
    "Haironville",
    "Haisnes",
    "Hallencourt",
    "Hallennes-lez-Haubourdin",
    "Hallering",
    "Hallines",
    "Halloy-les-Pernois",
    "Halluin",
    "Ham",
    "Ham-en-Artois",
    "Ham-sous-Varsberg",
    "Ham-sur-Meuse",
    "Hamars",
    "Hambach",
    "Hambers",
    "Hamel",
    "Hammeville",
    "Han-sur-Nied",
    "Hanches",
    "Hangenbieten",
    "Hangest-en-Santerre",
    "Hannogne-Saint-Martin",
    "Hantay",
    "Hanvec",
    "Happonvilliers",
    "Haramont",
    "Haraucourt",
    "Haraucourt",
    "Harbonnieres",
    "Harcanville",
    "Harcigny",
    "Harcourt",
    "Hardelot-Plage",
    "Hardencourt",
    "Hardifort",
    "Hardinvast",
    "Harfleur",
    "Hargicourt",
    "Hargnies",
    "Harnes",
    "Haroue",
    "Harprich",
    "Harreberg",
    "Harreville-les-Chanteurs",
    "Harskirchen",
    "Hartmannswiller",
    "Hary",
    "Hasnon",
    "Hasparren",
    "Haspres",
    "Hastingues",
    "Hatten",
    "Hattenville",
    "Hattstatt",
    "Haubourdin",
    "Hauconcourt",
    "Haucourt-Moulaine",
    "Haudiomont",
    "Haudivillers",
    "Haulchin",
    "Haussignemont",
    "Haussimont",
    "Haussy",
    "Haut-Clocher",
    "Haute-Goulaine",
    "Haute-Isle",
    "Haute-Rivoire",
    "Haute-Vigneulles",
    "Hautecloque",
    "Hautefage-la-Tour",
    "Hautefort",
    "Hauteluce",
    "Hauterive",
    "Hauterive",
    "Hauterives",
    "Hauteville-les-Dijon",
    "Hauteville-Lompnes",
    "Hauteville-sur-Mer",
    "Hautmont",
    "Hautot-sur-Mer",
    "Hautvillers",
    "Hautvillers-Ouville",
    "Haveluy",
    "Haverskerque",
    "Havrincourt",
    "Hayange",
    "Haybes",
    "Hazebrouck",
    "Hebecourt",
    "Hebecrevon",
    "Hebuterne",
    "Hede-Bazouges",
    "Hegenheim",
    "Heidwiller",
    "Heiligenberg",
    "Heillecourt",
    "Heilly",
    "Heimersdorf",
    "Heimsbrunn",
    "Heiteren",
    "Helesmes",
    "Helette",
    "Helfaut",
    "Hellemmes-Lille",
    "Hellimer",
    "Hem",
    "Hemevillers",
    "Heming",
    "Hemonstoir",
    "Henansal",
    "Hendaye",
    "Henin-Beaumont",
    "Henin-sur-Cojeul",
    "Hennebont",
    "Henon",
    "Henonville",
    "Henouville",
    "Henrichemont",
    "Henridorff",
    "Henvic",
    "Herbeys",
    "Herbignac",
    "Herblay",
    "Herchies",
    "Herepian",
    "Heres",
    "Hergnies",
    "Heric",
    "Hericourt",
    "Hericy",
    "Herimoncourt",
    "Herin",
    "Herisson",
    "Herlies",
    "Hermanville-sur-Mer",
    "Hermelange",
    "Hermeray",
    "Hermes",
    "Hermies",
    "Hermillon",
    "Herny",
    "Herouvillette",
    "Herrlisheim-pres-Colmar",
    "Herserange",
    "Hersin-Coupigny",
    "Hery-sur-Alby",
    "Herzeele",
    "Hesdin",
    "Hesingue",
    "Hestrus",
    "Hettange-Grande",
    "Heuchin",
    "Heudebouville",
    "Heudicourt",
    "Heudicourt-sous-les-Cotes",
    "Heudreville-sur-Eure",
    "Heugas",
    "Heuqueville",
    "Heurtevent",
    "Heutregiville",
    "Heyrieux",
    "Higueres-Souye",
    "Hilbesheim",
    "Hillion",
    "Hindlingen",
    "Hinges",
    "Hipsheim",
    "Hirel",
    "Hirsingue",
    "Hirson",
    "Hochfelden",
    "Hochstatt",
    "Hocquigny",
    "Hodenc-en-Bray",
    "Hodeng-Hodenger",
    "Hoenheim",
    "Hoerdt",
    "Hohfrankenheim",
    "Hohwiller",
    "Holnon",
    "Holtzheim",
    "Holtzwihr",
    "Holving",
    "Hombleux",
    "Homblieres",
    "Hombourg",
    "Hombourg-Budange",
    "Hombourg-Haut",
    "Homecourt",
    "Homps",
    "Hon-Hergies",
    "Hondeghem",
    "Hondouville",
    "Hondschoote",
    "Honfleur",
    "Honnecourt-sur-Escaut",
    "Hopital-Camfrout",
    "Horbourg-Wihr",
    "Hordain",
    "Horgues",
    "Hornaing",
    "Hossegor",
    "Houchin",
    "Houdain-lez-Bavay",
    "Houdan",
    "Houdelmont",
    "Houdemont",
    "Houeilles",
    "Houilles",
    "Houlbec-Cocherel",
    "Houlette",
    "Houlgate",
    "Houlle",
    "Houplin-Ancoisne",
    "Houplines",
    "Houppeville",
    "Hourtin",
    "Houry",
    "Houssen",
    "Housseville",
    "Houville-la-Branche",
    "Houx",
    "Hoymille",
    "Hubersent",
    "Huberville",
    "Huchenneville",
    "Hucqueliers",
    "Hudimesnil",
    "Huelgoat",
    "Huest",
    "Huez",
    "Hugier",
    "Huiron",
    "Huisseau-sur-Cosson",
    "Huisseau-sur-Mauves",
    "Hulluch",
    "Hultehouse",
    "Hundling",
    "Hundsbach",
    "Huningue",
    "Hunspach",
    "Huppy",
    "Hure",
    "Huriel",
    "Hurigny",
    "Husseren-les-Chateaux",
    "Husseren-Wesserling",
    "Hussigny-Godbrange",
    "Huttenheim",
    "Hyemondans",
    "Idrac-Respailles",
    "Idron",
    "Iffendic",
    "Ifs",
    "Igney",
    "Igny",
    "Igon",
    "Igoville",
    "Illange",
    "Ille-sur-Tet",
    "Illfurth",
    "Illhaeusern",
    "Illiat",
    "Illiers-Combray",
    "Illifaut",
    "Illkirch-Graffenstaden",
    "Illois",
    "Illzach",
    "Imling",
    "Imphy",
    "Incheville",
    "Inchy",
    "Indre",
    "Ingersheim",
    "Ingolsheim",
    "Ingrandes",
    "Ingre",
    "Inguiniel",
    "Ingwiller",
    "Innenheim",
    "Inxent",
    "Ippling",
    "Irai",
    "Irancy",
    "Irigny",
    "Irissarry",
    "Irodouer",
    "Irouleguy",
    "Irreville",
    "Is-sur-Tille",
    "Isbergues",
    "Isches",
    "Isigny-le-Buat",
    "Isigny-sur-Mer",
    "Isle",
    "Isle-et-Bardais",
    "Isles-les-Villenoy",
    "Isneauville",
    "Isola",
    "Ispoure",
    "Isques",
    "Issancourt-et-Rumel",
    "Issenhausen",
    "Issenheim",
    "Issoire",
    "Issou",
    "Issoudun",
    "Issus",
    "Issy-les-Moulineaux",
    "Istres",
    "Itancourt",
    "Iteuil",
    "Ittenheim",
    "Itteville",
    "Itxassou",
    "Iville",
    "Ivoy-le-Pre",
    "Ivry-la-Bataille",
    "Ivry-le-Temple",
    "Ivry-sur-Seine",
    "Iwuy",
    "Izeaux",
    "Izernore",
    "Izeron",
    "Izier",
    "Izon",
    "Izotges",
    "Jabreilles-les-Bordes",
    "Jacob-Bellecombette",
    "Jacou",
    "Jainvillotte",
    "Jallais",
    "Jallans",
    "Jallerange",
    "Jalognes",
    "Jalogny",
    "Jalons",
    "Jambles",
    "Jametz",
    "Jandun",
    "Janneyrias",
    "Janville",
    "Janville",
    "Janville-sur-Juine",
    "Janvilliers",
    "Janvry",
    "Janze",
    "Jarcieu",
    "Jard-sur-Mer",
    "Jardin",
    "Jardres",
    "Jargeau",
    "Jarmenil",
    "Jarnac-Champagne",
    "Jarnages",
    "Jarny",
    "Jarrie",
    "Jarrier",
    "Jars",
    "Jarville-la-Malgrange",
    "Jarze",
    "Jassans-Riottier",
    "Jasseron",
    "Jaucourt",
    "Jaujac",
    "Jaunay-Clan",
    "Jaure",
    "Jausiers",
    "Jaux",
    "Javene",
    "Javrezac",
    "Jayat",
    "Jazeneuil",
    "Jeandelaincourt",
    "Jeanmenil",
    "Jebsheim",
    "Jegun",
    "Jenlain",
    "Jesonville",
    "Jettingen",
    "Jeu-les-Bois",
    "Jeufosse",
    "Jeumont",
    "Jeune",
    "Jeurre",
    "Jeux-les-Bard",
    "Jeuxey",
    "Jezainville",
    "Job",
    "Jobourg",
    "Joch",
    "Joeuf",
    "Joigny",
    "Joigny-sur-Meuse",
    "Joinville",
    "Joinville-le-Pont",
    "Jolimetz",
    "Jolivet",
    "Jonage",
    "Jonchery-sur-Vesle",
    "Joncourt",
    "Jonquerets-de-Livet",
    "Jonquerettes",
    "Jonquieres",
    "Jonquieres",
    "Jonquieres",
    "Jons",
    "Jonvelle",
    "Jonvilliers",
    "Jonzac",
    "Jonzier-Epagny",
    "Jonzieux",
    "Josnes",
    "Josse",
    "Josselin",
    "Jossigny",
    "Jouarre",
    "Jouars-Pontchartrain",
    "Jouaville",
    "Joudreville",
    "Joue-sur-Erdre",
    "Jougne",
    "Jouhet",
    "Jouques",
    "Journans",
    "Journiac",
    "Journy",
    "Joux",
    "Jouy-en-Josas",
    "Jouy-le-Chatel",
    "Jouy-le-Moutier",
    "Jouy-le-Potier",
    "Jouy-sous-Thelle",
    "Jouy-sur-Eure",
    "Jouy-sur-Morin",
    "Juan-les-Pins",
    "Jugeals-Nazareth",
    "Jugon-les-Lacs",
    "Juigne-sur-Loire",
    "Juigne-sur-Sarthe",
    "Juillac",
    "Juillan",
    "Juilles",
    "Juilly",
    "Juilly",
    "Jujurieux",
    "Julienas",
    "Julienne",
    "Jully-sur-Sarce",
    "Jumeauville",
    "Jumelles",
    "Jumelles",
    "Jumilhac-le-Grand",
    "Junay",
    "Jungholtz",
    "Juniville",
    "Jupilles",
    "Jurancon",
    "Jury",
    "Jussey",
    "Jussy",
    "Jussy",
    "Juvaincourt",
    "Juvignac",
    "Juvigny",
    "Juvigny",
    "Juvigny-en-Perthois",
    "Juville",
    "Juvisy-sur-Orge",
    "Juziers",
    "Kaltenhouse",
    "Kappelen",
    "Katzenthal",
    "Kaysersberg",
    "Kedange-sur-Canner",
    "Kembs",
    "Kerbach",
    "Kerfot",
    "Kerfourn",
    "Kergloff",
    "Kerien",
    "Kerity",
    "Kerlaz",
    "Kerlouan",
    "Kernascleden",
    "Kerpert",
    "Kervignac",
    "Kesseldorf",
    "Kienheim",
    "Kientzheim",
    "Killem",
    "Kilstett",
    "Kingersheim",
    "Kintzheim",
    "Knutange",
    "Koenigsmacker",
    "Koeur-la-Petite",
    "Krafft",
    "Krautergersheim",
    "Kriegsheim",
    "Kruth",
    "Kunheim",
    "Kuntzig",
    "Kurtzenhouse",
    "Kutzenhausen",
    "La Baconniere",
    "La Barben",
    "La Barre-de-Monts",
    "La Barre-en-Ouche",
    "La Barthe",
    "La Bassee",
    "La Bastide",
    "La Bastide",
    "La Bastide",
    "La Bathie",
    "La Batie",
    "La Baule-Escoublac",
    "La Baume-de-Transit",
    "La Baussaine",
    "La Bazoche-Gouet",
    "La Bazoge",
    "La Begue",
    "La Bernerie-en-Retz",
    "La Berthenoux",
    "La Bigne",
    "La Biolle",
    "La Bohalle",
    "La Boissiere-des-Landes",
    "La Bosse",
    "La Bosse-de-Bretagne",
    "La Bouexiere",
    "La Bouilladisse",
    "La Bourboule",
    "La Bourgonce",
    "La Boussac",
    "La Bresse",
    "La Bretonne",
    "La Bridoire",
    "La Brigue",
    "La Brillanne",
    "La Broque",
    "La Brosse",
    "La Bruffiere",
    "La Brulatte",
    "La Buisse",
    "La Buissiere",
    "La Bussiere",
    "La Calmette",
    "La Calotterie",
    "La Canourgue",
    "La Capelle",
    "La Caunette",
    "La Cavalerie",
    "La Celle",
    "La Celle",
    "La Celle",
    "La Celle-Saint-Cloud",
    "La Celle-sous-Montmirail",
    "La Celle-sur-Seine",
    "La Cerlangue",
    "La Chaise",
    "La Chaise",
    "La Chaise-Baudouin",
    "La Chaize-le-Vicomte",
    "La Chapelaude",
    "La Chapelle",
    "La Chapelle-aux-Bois",
    "La Chapelle-aux-Lys",
    "La Chapelle-Baton",
    "La Chapelle-Blanche",
    "La Chapelle-de-Brain",
    "La Chapelle-de-Guinchay",
    "La Chapelle-en-Vercors",
    "La Chapelle-Gaudin",
    "La Chapelle-Gauthier",
    "La Chapelle-Heulin",
    "La Chapelle-Laurent",
    "La Chapelle-Montligeon",
    "La Chapelle-Orthemale",
    "La Chapelle-Saint-Luc",
    "La Chapelle-sur-Chezy",
    "La Chapelle-Yvon",
    "La Charme",
    "La Charmee",
    "La Chartre-sur-le-Loir",
    "La Chataigneraie",
    "La Chatre",
    "La Chatre-Langlin",
    "La Chaumusse",
    "La Chaussaire",
    "La Chaussee",
    "La Chaux-du-Dombief",
    "La Cheneviere",
    "La Chevallerais",
    "La Chevroliere",
    "La Ciotat",
    "La Clayette",
    "La Clisse",
    "La Clotte",
    "La Clusaz",
    "La Cluse-et-Mijoux",
    "La Colle-sur-Loup",
    "La Combelle",
    "La Copechagniere",
    "La Coquille",
    "La Cornuaille",
    "La Cote",
    "La Cote",
    "La Couarde-sur-Mer",
    "La Coucourde",
    "La Coudre",
    "La Coulonche",
    "La Courneuve",
    "La Couronne",
    "La Couronne",
    "La Couture-Boussey",
    "La Crau",
    "La Croix-Valmer",
    "La Cropte",
    "La Dagueniere",
    "La Defense",
    "La Destrousse",
    "La Dominelais",
    "La Fare-en-Champsaur",
    "La Farlede",
    "La Faute-sur-Mer",
    "La Faye",
    "La Fere",
    "La Ferriere",
    "La Ferriere-Bochard",
    "La Ferte",
    "La Ferte-Alais",
    "La Ferte-Mace",
    "La Ferte-Milon",
    "La Ferte-Saint-Cyr",
    "La Ferte-Saint-Samson",
    "La Ferte-sous-Jouarre",
    "La Feuillade",
    "La Feuillie",
    "La Feuillie",
    "La Flachere",
    "La Fleche",
    "La Flocelliere",
    "La Flotte",
    "La Force",
    "La Forest-Landerneau",
    "La Foret",
    "La Foret",
    "La Foret-Fouesnant",
    "La Forie",
    "La Fouillade",
    "La Fouillouse",
    "La Foux",
    "La Foye-Monjault",
    "La Frasse",
    "La Frenaye",
    "La Fresnais",
    "La Frette",
    "La Frette",
    "La Frette-sur-Seine",
    "La Gacilly",
    "La Gaillarde",
    "La Gaillarde",
    "La Garde",
    "La Garde",
    "La Garenne",
    "La Garenne-Colombes",
    "La Garnache",
    "La Gaubretiere",
    "La Gaude",
    "La Gavotte",
    "La Glacerie",
    "La Gorgue",
    "La Gouesniere",
    "La Grand-Croix",
    "La Grande-Motte",
    "La Grandville",
    "La Grave",
    "La Graverie",
    "La Gree-Saint-Laurent",
    "La Greve-sur-Mignon",
    "La Grigonnais",
    "La Groise",
    "La Guerche",
    "La Guerche-de-Bretagne",
    "La Gueroulde",
    "La Guierche",
    "La Guyonniere",
    "La Haie",
    "La Halle",
    "La Hallotiere",
    "La Haye",
    "La Haye-Saint-Sylvestre",
    "La Houblonniere",
    "La Houssaye-en-Brie",
    "La Houssoye",
    "La Jaille-Yvon",
    "La Jarne",
    "La Jarrie",
    "La Jubaudiere",
    "La Jumelliere",
    "La Laigne",
    "La Lande",
    "La Lande",
    "La Landec",
    "La Laupie",
    "La Lechere",
    "La Limouziniere",
    "La Liviniere",
    "La Londe",
    "La Londe-les-Maures",
    "La Loubiere",
    "La Loupe",
    "La Lucerne-dOutremer",
    "La Machine",
    "La Madeleine",
    "La Madeleine",
    "La Madeleine-sur-Loing",
    "La Magdelaine-sur-Tarn",
    "La Mailleraye-sur-Seine",
    "La Malhoure",
    "La Marche",
    "La Marolle-en-Sologne",
    "La Martiniere",
    "La Maxe",
    "La Meauffe",
    "La Meaugon",
    "La Meignanne",
    "La Meilleraie-Tillay",
    "La Meilleraye-de-Bretagne",
    "La Membrolle-sur-Choisille",
    "La Membrolle-sur-Longuenee",
    "La Menitre",
    "La Meziere",
    "La Milesse",
    "La Montagne",
    "La Montagne",
    "La Montagne",
    "La Mothe-Saint-Heray",
    "La Motte",
    "La Motte-Chalancon",
    "La Motte-du-Caire",
    "La Motte-Fouquet",
    "La Motte-Saint-Jean",
    "La Motte-Saint-Martin",
    "La Motte-Servolex",
    "La Mulatiere",
    "La Muraz",
    "La Mure",
    "La Napoule",
    "La Nerthe",
    "La Neuveville-devant-Lepanges",
    "La Neuville-du-Bosc",
    "La Noe",
    "La Norville",
    "La Palud",
    "La Paute",
    "La Penne-sur-Huveaune",
    "La Perche",
    "La Place",
    "La Plagne",
    "La Plaine",
    "La Plaine",
    "La Plaine-Saint-Denis",
    "La Planche",
    "La Plane",
    "La Pointe",
    "La Poiteviniere",
    "La Pommeraye",
    "La Porcherie",
    "La Porte",
    "La Possonniere",
    "La Poste",
    "La Poterie",
    "La Poterie-Mathieu",
    "La Poueze",
    "La Puye",
    "La Queue-en-Brie",
    "La Queue-les-Yvelines",
    "La Ravoire",
    "La Remuee",
    "La Renaudiere",
    "La Reole",
    "La Reorthe",
    "La Ricamarie",
    "La Richardais",
    "La Riche",
    "La Riviere",
    "La Riviere",
    "La Riviere",
    "La Riviere",
    "La Riviere-de-Corps",
    "La Rixouse",
    "La Roche",
    "La Roche",
    "La Roche",
    "La Roche",
    "La Roche-Blanche",
    "La Roche-des-Arnauds",
    "La Roche-sur-le-Buis",
    "La Roche-sur-Yon",
    "La Rochefoucauld",
    "La Rochegiron",
    "La Rochelle",
    "La Rochenard",
    "La Rochette",
    "La Rochette",
    "La Rochette-sur-Crest",
    "La Romagne",
    "La Ronde",
    "La Roque-Baignard",
    "La Roquebrussanne",
    "La Roquette-sur-Siagne",
    "La Rouine",
    "La Rouquette",
    "La Rouviere",
    "La Salette-Fallavaux",
    "La Salle-de-Vihiers",
    "La Salvetat-Lauragais",
    "La Salvetat-Saint-Gilles",
    "La Salvetat-sur-Agout",
    "La Saulce",
    "La Sauve",
    "La Sauvetat-de-Saveres",
    "La Seauve-sur-Semene",
    "La Seguiniere",
    "La Selle-en-Hermoy",
    "La Selve",
    "La Sentinelle",
    "La Seyne-sur-Mer",
    "La Sone",
    "La Souche",
    "La Souterraine",
    "La Suze-sur-Sarthe",
    "La Tagniere",
    "La Taillee",
    "La Talaudiere",
    "La Tardiere",
    "La Terrasse",
    "La Tessoualle",
    "La Teste-de-Buch",
    "La Tour",
    "La Tour",
    "La Tour-dAuvergne",
    "La Tour-de-Scay",
    "La Tour-du-Crieu",
    "La Tour-Saint-Gelin",
    "La Tour-sur-Orb",
    "La Tourette",
    "La Tranche-sur-Mer",
    "La Treille",
    "La Tremblade",
    "La Trimouille",
    "La Trinite",
    "La Trinite-de-Reville",
    "La Tronche",
    "La Tuiliere",
    "La Turballe",
    "La Turbie",
    "La Vacherie",
    "La Valette-du-Var",
    "La Vallee de Dormelles",
    "La Varenne",
    "La Varenne",
    "La Vaupaliere",
    "La Verpilliere",
    "La Verrie",
    "La Verriere",
    "La Vespiere",
    "La Veze",
    "La Ville",
    "La Ville aux Chiens",
    "La Ville-aux-Bois",
    "La Villedieu",
    "La Villedieu-les-Quenoche",
    "La Villeneuve",
    "La Villetelle",
    "La Voulte-sur-Rhone",
    "La Vraie-Croix",
    "La Walck",
    "La Wantzenau",
    "Laas",
    "Labaroche",
    "Labarthe-sur-Leze",
    "Labastide-Beauvoir",
    "Labastide-Cezeracq",
    "Labastide-dArmagnac",
    "Labastide-de-Virac",
    "Labastide-Gabausse",
    "Labastide-Rouairoux",
    "Labastide-Saint-Pierre",
    "Labastidette",
    "Labatut",
    "Labege",
    "Labenne",
    "Labessiere-Candeil",
    "Labeuvriere",
    "Lablachere",
    "Labouheyre",
    "Labouquerie",
    "Labourse",
    "Labretonie",
    "Labruguiere",
    "Labruyere",
    "Labry",
    "Labuissiere",
    "Lacabarede",
    "Lacadee",
    "Lacanau",
    "Lacapelle-Cabanac",
    "Lacapelle-Marival",
    "Lacapelle-Viescamp",
    "Lacaune",
    "Lacaussade",
    "Lachapelle",
    "Lachapelle-sous-Chaux",
    "Lachapelle-sous-Rougemont",
    "Lachaux",
    "Lacour",
    "Lacq",
    "Lacroix-Falgarde",
    "Lacroix-Saint-Ouen",
    "Lacroix-sur-Meuse",
    "Lacrost",
    "Lacs",
    "Ladoix-Serrigny",
    "Ladon",
    "Laduz",
    "Lafare",
    "Lafeuillade-en-Vezie",
    "Lafitole",
    "Lafox",
    "Lagardelle-sur-Leze",
    "Lagardere",
    "Lagarrigue",
    "Lagnes",
    "Lagnieu",
    "Lagny-le-Sec",
    "Lagor",
    "Lagord",
    "Lagrave",
    "Laguenne",
    "Laguiole",
    "Lagupie",
    "Lahitte-Toupiere",
    "Lahonce",
    "Lahontan",
    "Lahosse",
    "Lahourcade",
    "Laifour",
    "Laigne",
    "Laigne-en-Belin",
    "Laignelet",
    "Laignes",
    "Laigneville",
    "LAiguillon-sur-Mer",
    "Laille",
    "Lailly-en-Val",
    "Lainsecq",
    "Laissac",
    "Laissaud",
    "Laissey",
    "Laiz",
    "Laize",
    "Lalanne-Arque",
    "Lalbenque",
    "Laleu",
    "Lalinde",
    "Lallaing",
    "Lalonquette",
    "Laloubere",
    "Lamagdelaine",
    "Lamagistere",
    "Lamaire",
    "Lamalou-les-Bains",
    "Lamanon",
    "Lamarche",
    "Lamarche-sur-Saone",
    "Lamastre",
    "Lamativie",
    "Lamaziere-Basse",
    "Lamballe",
    "Lambersart",
    "Lambert",
    "Lambesc",
    "Lambres-lez-Douai",
    "Lamnay",
    "Lamorlaye",
    "Lamorville",
    "Lamothe-Landerron",
    "Lamothe-Montravel",
    "Lamotte-Beuvron",
    "Lampaul-Guimiliau",
    "Lampaul-Plouarzel",
    "Lampertheim",
    "Lampertsloch",
    "Lancey",
    "Lancie",
    "Lancieux",
    "Lancon-Provence",
    "Lancrans",
    "Landas",
    "Landebia",
    "Landeda",
    "Landelles",
    "Landelles-et-Coupigny",
    "Landemont",
    "Landepereuse",
    "Landerneau",
    "Landeronde",
    "Landes",
    "Landevant",
    "Landevieille",
    "Landivisiau",
    "Landogne",
    "Landos",
    "Landrecies",
    "Landres",
    "Landrethun-le-Nord",
    "Landrethun-les-Ardres",
    "Landrevarzec",
    "Landry",
    "Landser",
    "Landujan",
    "Landunvez",
    "Lanester",
    "Laneuvelotte",
    "Laneuveville-aux-Bois",
    "Laneuveville-devant-Bayon",
    "Laneuveville-devant-Nancy",
    "Langan",
    "Langeac",
    "Langeais",
    "Langensoultzbach",
    "Langlade",
    "Langoat",
    "Langoelan",
    "Langoiran",
    "Langolen",
    "Langon",
    "Langon",
    "Langonnet",
    "Langourla",
    "Langres",
    "Langrune-sur-Mer",
    "Langueux",
    "Languidic",
    "Lanhelin",
    "Laning",
    "Lanleff",
    "Lanmerin",
    "Lanmeur",
    "Lanne",
    "Lanne-en-Baretous",
    "Lannebert",
    "Lannemezan",
    "Lannilis",
    "Lannion",
    "Lannoy",
    "Lannoy-Cuillere",
    "Lanobre",
    "Lanouee",
    "Lanrelas",
    "Lanrodec",
    "Lans",
    "Lans-en-Vercors",
    "Lansac",
    "Lansargues",
    "Lanta",
    "Lantenne-Vertiere",
    "Lanthenans",
    "Lantignie",
    "Lantilly",
    "Lanton",
    "Lantriac",
    "Lanvallay",
    "Lanvaudan",
    "Lanveoc",
    "Lanvollon",
    "Lanzac",
    "Laon",
    "Lapalisse",
    "Lapalme",
    "Lapalud",
    "Laparrouquial",
    "Lapenche",
    "Laperriere-sur-Saone",
    "Lapeyrouse-Fossat",
    "Lapeyrouse-Mornay",
    "Lapoutroie",
    "Lapouyade",
    "Lapte",
    "Lapugnoy",
    "Laquenexy",
    "Laqueuille",
    "Laragne-Monteglin",
    "LArbresle",
    "Larcay",
    "Larchant",
    "Larche",
    "Lardy",
    "Largeasse",
    "Largillay-Marsonnay",
    "Lariviere-Arnoncourt",
    "Larmor-Baden",
    "Larmor-Plage",
    "Laroche-Saint-Cydroine",
    "Laroin",
    "Laroque-de-Fa",
    "Larrazet",
    "Larressore",
    "Larriviere-Saint-Savin",
    "Laruns",
    "Larzicourt",
    "Lasalle",
    "Lasbordes",
    "Lassay-les-Chateaux",
    "Lassay-sur-Croisne",
    "Lasseran",
    "Lasserre",
    "Lasseube",
    "Lassigny",
    "Lasson",
    "Lassy",
    "Lastic",
    "Lathuile",
    "Latille",
    "Latour",
    "Latour-Bas-Elne",
    "Latresne",
    "Latronquiere",
    "Lattainville",
    "Lattes",
    "Lattre-Saint-Quentin",
    "Laudun-lArdoise",
    "Laugnac",
    "Launaguet",
    "Launay",
    "Laurabuc",
    "Laure",
    "Laurede",
    "Laurenan",
    "Laurens",
    "Laurent",
    "Lauret",
    "Lauris",
    "Laussonne",
    "Lautenbach",
    "Lauterbourg",
    "Lautrec",
    "Lauzerte",
    "Lauzerville",
    "Lauzun",
    "Laval",
    "Laval-sur-Doulon",
    "Laval-sur-Vologne",
    "Lavannes",
    "Lavans-les-Saint-Claude",
    "Lavans-Vuillafans",
    "Lavardac",
    "Lavardens",
    "Lavardin",
    "Lavardin",
    "Lavau",
    "Lavaur",
    "Lavaur",
    "Lavaurette",
    "Lavelanet",
    "Laventie",
    "Lavergne",
    "Lavernose-Lacasse",
    "Laversine",
    "Laversines",
    "Laverune",
    "Lavit",
    "Lavours",
    "Lavoute-Chilhac",
    "Laxou",
    "Laye",
    "Layrac",
    "Layrisse",
    "Le Baizil",
    "Le Ban-Saint-Martin",
    "Le Bar-sur-Loup",
    "Le Barboux",
    "Le Barcares",
    "Le Bardon",
    "Le Barp",
    "Le Beausset",
    "Le Bernard",
    "Le Bessat",
    "Le Bez",
    "Le Bignon",
    "Le Blanc",
    "Le Blanc-Mesnil",
    "Le Bleymard",
    "Le Bocasse",
    "Le Bois",
    "Le Bois-Plage-en-Re",
    "Le Bonhomme",
    "Le Bono",
    "Le Bosc",
    "Le Bosc-Roger-en-Roumois",
    "Le Bouchage",
    "Le Boulay-Morin",
    "Le Boupere",
    "Le Bourg",
    "Le Bourget",
    "Le Bourget",
    "Le Bourgneuf-la-Foret",
    "Le Bouscat",
    "Le Bousquet",
    "Le Breil",
    "Le Breuil",
    "Le Breuil",
    "Le Breuil",
    "Le Breuil",
    "Le Breuil-sur-Couze",
    "Le Broc",
    "Le Broc",
    "Le Brugeron",
    "Le Buisson-de-Cadouin",
    "Le Cailar",
    "Le Cannet",
    "Le Castellet",
    "Le Castera",
    "Le Cateau-Cambresis",
    "Le Caylar",
    "Le Cellier",
    "Le Cendre",
    "Le Cergne",
    "Le Chambon",
    "Le Chambon",
    "Le Chambon",
    "Le Champ-pres-Froges",
    "Le Champ-Saint-Pere",
    "Le Chateau",
    "Le Chateau Gaillard",
    "Le Chatel",
    "Le Chatelard",
    "Le Chatelet-en-Brie",
    "Le Chatelet-sur-Retourne",
    "Le Chaylard",
    "Le Chesnay",
    "Le Chesne",
    "Le Cheylas",
    "Le Collet-de-Deze",
    "Le Comte",
    "Le Conquet",
    "Le Coteau",
    "Le Coudray",
    "Le Coudray-Baillet",
    "Le Coudray-Montceaux",
    "Le Cres",
    "Le Crest",
    "Le Creusot",
    "Le Croisic",
    "Le Crotoy",
    "Le Deluge",
    "Le Deschaux",
    "Le Dezert",
    "Le Donjon",
    "Le Dorat",
    "Le Doulieu",
    "Le Falgoux",
    "Le Faou",
    "Le Faouet",
    "Le Fayet",
    "Le Fenouiller",
    "Le Ferre",
    "Le Fidelaire",
    "Le Fief-Sauvin",
    "Le Foeil",
    "Le Folgoet",
    "Le Fossat",
    "Le Fousseret",
    "Le Fraysse",
    "Le Fresne-Camilly",
    "Le Fuilet",
    "Le Garric",
    "Le Gavre",
    "Le Gond",
    "Le Grand Village",
    "Le Grand-Bornand",
    "Le Grand-Pressigny",
    "Le Grand-Quevilly",
    "Le Gratteris",
    "Le Grau-du-Roi",
    "Le Gua",
    "Le Guerno",
    "Le Guildo",
    "Le Haillan",
    "Le Havre",
    "Le Herie-la-Vieville",
    "Le Hohwald",
    "Le Houlme",
    "Le Kremlin-Bicetre",
    "Le Landin",
    "Le Landreau",
    "Le Lardin-Saint-Lazare",
    "Le Lavandou",
    "Le Lez",
    "Le Longeron",
    "Le Loroux",
    "Le Loroux-Bottereau",
    "Le Louroux-Beconnais",
    "Le Luart",
    "Le Luc",
    "Le Lude",
    "Le Manoir",
    "Le Mans",
    "Le Marillais",
    "Le Martinet",
    "Le Mas",
    "Le May-sur-Evre",
    "Le Mayet",
    "Le Mazeau",
    "Le Mee-sur-Seine",
    "Le Meix-Saint-Epoing",
    "Le Mele-sur-Sarthe",
    "Le Menil",
    "Le Meriot",
    "Le Merlerault",
    "Le Mesnil-Amelot",
    "Le Mesnil-en-Thelle",
    "Le Mesnil-en-Vallee",
    "Le Mesnil-Esnard",
    "Le Mesnil-Fuguet",
    "Le Mesnil-le-Roi",
    "Le Mesnil-Ozenne",
    "Le Mesnil-Vigot",
    "Le Mesnil-Villeman",
    "Le Meux",
    "Le Molard",
    "Le Molay",
    "Le Monastier-Pin-Mories",
    "Le Monastier-sur-Gazeille",
    "Le Mont-Dore",
    "Le Monteil",
    "Le Montel",
    "Le Muy",
    "Le Neubourg",
    "Le Noyer",
    "Le Palais",
    "Le Pallet",
    "Le Parc",
    "Le Parcq",
    "Le Passage",
    "Le Passage",
    "Le Pecq",
    "Le Pellerin",
    "Le Perray-en-Yvelines",
    "Le Perreux-sur-Marne",
    "Le Perrier",
    "Le Petit-Quevilly",
    "Le Pian-sur-Garonne",
    "Le Pin",
    "Le Pin",
    "Le Pin",
    "Le Pin",
    "Le Pin-la-Garenne",
    "Le Pizou",
    "Le Plan-de-la-Tour",
    "Le Planois",
    "Le Plessier-sur-Bulles",
    "Le Plessis",
    "Le Plessis-Belleville",
    "Le Plessis-Bouchard",
    "Le Plessis-Grammoire",
    "Le Plessis-Hebert",
    "Le Plessis-Pate",
    "Le Plessis-Robinson",
    "Le Plessis-Trevise",
    "Le Poet",
    "Le Poet-Laval",
    "Le Poinconnet",
    "Le Poire-sur-Velluire",
    "Le Pont",
    "Le Pont",
    "Le Pont-de-Claix",
    "Le Pontet",
    "Le Porge",
    "Le Port",
    "Le Port",
    "Le Port-Marly",
    "Le Portel",
    "Le Pouget",
    "Le Pouliguen",
    "Le Pout",
    "Le Pouzin",
    "Le Pradet",
    "Le Pre-Saint-Gervais",
    "Le Puy",
    "Le Puy-en-Velay",
    "Le Puy-Sainte-Reparade",
    "Le Quesnel",
    "Le Quesnoy",
    "Le Raincy",
    "Le Relecq-Kerhuon",
    "Le Renouard",
    "Le Revest-les-Eaux",
    "Le Rheu",
    "Le Roncenay-Authenay",
    "Le Rouget",
    "Le Rouret",
    "Le Rove",
    "Le Sacq",
    "Le Sap",
    "Le Segur",
    "Le Soler",
    "Le Sourn",
    "Le Syndicat",
    "Le Taillan-Medoc",
    "Le Tallud",
    "Le Teich",
    "Le Teil",
    "Le Teilleul",
    "Le Temple-de-Bretagne",
    "Le Theil",
    "Le Theil-de-Bretagne",
    "Le Thieulin",
    "Le Thillay",
    "Le Thillot",
    "Le Tholonet",
    "Le Tholy",
    "Le Thor",
    "Le Thou",
    "Le Thoureil",
    "Le Thuit-Signol",
    "Le Thuit-Simer",
    "Le Tignet",
    "Le Torquesne",
    "Le Touquet-Paris-Plage",
    "Le Tourne",
    "Le Tourneur",
    "Le Touvet",
    "Le Trait",
    "Le Treport",
    "Le Trevoux",
    "Le Tronchet",
    "Le Tronquay",
    "Le Val",
    "Le Val-dAjol",
    "Le Vaudioux",
    "Le Vaudreuil",
    "Le Vaumain",
    "Le Verger",
    "Le Vernet",
    "Le Vernet",
    "Le Versoud",
    "Le Vesinet",
    "Le Vieux",
    "Le Vieux Bourg",
    "Le Vieux Bourg",
    "Le Vigan",
    "Le Vigean",
    "Le Vigeant",
    "Le Vigen",
    "Le Vivier-sur-Mer",
    "Le Voide",
    "Lealvillers",
    "Leaz",
    "Lecelles",
    "Lechatelet",
    "Lechelle",
    "Lecousse",
    "Lectoure",
    "Ledenon",
    "Lederzeele",
    "Leers",
    "Leffincourt",
    "Leffrinckoucke",
    "Leforest",
    "Lege",
    "Lege-Cap-Ferret",
    "Leguevin",
    "Lehon",
    "Leigneux",
    "Leintrey",
    "Lelling",
    "Lemainville",
    "Lembach",
    "Lemberg",
    "Leme",
    "Lemmes",
    "Lempdes",
    "Lempdes-sur-Allagnon",
    "Lemps",
    "Lencloitre",
    "Lennon",
    "Lenoncourt",
    "Lens",
    "Lens-Lestang",
    "Lent",
    "Lentigny",
    "Lentilly",
    "Lentiol",
    "Leojac",
    "Leon",
    "Leouville",
    "Lepin-le-Lac",
    "Lepuix",
    "Lepuy",
    "Lere",
    "Lery",
    "Les Abrets",
    "Les Ageux",
    "Les Alleuds",
    "Les Allies",
    "Les Allues",
    "Les Ancizes-Comps",
    "Les Andelys",
    "Les Angles",
    "Les Arcs",
    "Les Ardillats",
    "Les Artigues-de-Lussac",
    "Les Assions",
    "Les Aubiers",
    "Les Avanchers-Valmorel",
    "Les Avenieres",
    "Les Bains",
    "Les Baux de Provence",
    "Les Baux-de-Breteuil",
    "Les Billanges",
    "Les Bordes",
    "Les Bordes",
    "Les Bordes-sur-Lez",
    "Les Breviaires",
    "Les Brouzils",
    "Les Cabanes",
    "Les Cabannes",
    "Les Cars",
    "Les Champs",
    "Les Chapelles",
    "Les Charmontois",
    "Les Cheres",
    "Les Clayes-sous-Bois",
    "Les Clouzeaux",
    "Les Combes",
    "Les Deux-Fays",
    "Les Echelles",
    "Les Ecrennes",
    "Les Eglisottes-et-Chalaures",
    "Les Eparres",
    "Les Epesses",
    "Les Essards",
    "Les Essards-Taignevaux",
    "Les Essarts",
    "Les Essarts",
    "Les Essarts",
    "Les Etangs",
    "Les Eyzies-de-Tayac-Sireuil",
    "Les Fins",
    "Les Fontaines",
    "Les Fonts",
    "Les Forges",
    "Les Forges",
    "Les Fourgs",
    "Les Gets",
    "Les Gours",
    "Les Grands-Chezeaux",
    "Les Granges",
    "Les Granges",
    "Les Gras",
    "Les Halles",
    "Les Hays",
    "Les Herbiers",
    "Les Hogues",
    "Les Hopitaux-Vieux",
    "Les Hotelleries",
    "Les Houches",
    "Les Jumeaux",
    "Les Landes",
    "Les Landes-Genusson",
    "Les Laumes",
    "Les Lilas",
    "Les Loges",
    "Les Loges",
    "Les Loges-en-Josas",
    "Les Lucs-sur-Boulogne",
    "Les Mages",
    "Les Marches",
    "Les Martres",
    "Les Mathes",
    "Les Maures",
    "Les Mees",
    "Les Mesneux",
    "Les Mesnuls",
    "Les Milles",
    "Les Moitiers-en-Bauptois",
    "Les Molieres",
    "Les Montils",
    "Les Mureaux",
    "Les Nouillers",
    "Les Ollieres-sur-Eyrieux",
    "Les Olmes",
    "Les Ormes",
    "Les Ormes",
    "Les Ormes-sur-Voulzie",
    "Les Palais",
    "Les Pavillons-sous-Bois",
    "Les Peintures",
    "Les Pennes-Mirabeau",
    "Les Petites Loges",
    "Les Pieux",
    "Les Pineaux",
    "Les Pins",
    "Les Pins",
    "Les Pins",
    "Les Places",
    "Les Rives",
    "Les Rivieres-Henruel",
    "Les Roches",
    "Les Rosiers",
    "Les Rosiers-sur-Loire",
    "Les Rousses",
    "Les Sables",
    "Les Sauvages",
    "Les Sorinieres",
    "Les Taillades",
    "Les Thilliers-en-Vexin",
    "Les Touches",
    "Les Vallees",
    "Les Vans",
    "Les Varennes",
    "Les Vigneaux",
    "Les Villedieu",
    "Lescar",
    "Lescheraines",
    "Lescherolles",
    "Lesches",
    "Lesconil",
    "Lescout",
    "Lescure",
    "Lesdain",
    "Lesigny",
    "Lesneven",
    "Lespesses",
    "Lespielle",
    "Lespignan",
    "Lespinasse",
    "Lesquin",
    "Lessay",
    "Lesse",
    "Lessy",
    "Lestelle-Betharram",
    "Lestiou",
    "Lestrem",
    "Letanne",
    "Letricourt",
    "Leuc",
    "Leucate",
    "Leudeville",
    "Leugny",
    "Leuilly-sous-Coucy",
    "Leuville-sur-Orge",
    "Leuvrigny",
    "Levainville",
    "Leval",
    "Leval",
    "Levallois-Perret",
    "Levens",
    "Levernois",
    "Leves",
    "Levet",
    "Levie",
    "Levier",
    "Levignacq",
    "Levis",
    "Levroux",
    "Lewarde",
    "Lexy",
    "Leyme",
    "Leyr",
    "Leyrieu",
    "Leysse",
    "Lezan",
    "Lezardrieux",
    "Lezat-sur-Leze",
    "Lezay",
    "Lezennes",
    "Lezignan-Corbieres",
    "Lezigne",
    "Lezigneux",
    "Lezoux",
    "Lheraule",
    "Lherm",
    "Lhommaize",
    "Lhuis",
    "Liancourt",
    "Liancourt-Saint-Pierre",
    "Liart",
    "Libercourt",
    "Libourne",
    "Licques",
    "Liepvre",
    "Lieramont",
    "Liergues",
    "Liernais",
    "Lieu-Saint-Amand",
    "Lieuran-les-Beziers",
    "Lieurey",
    "Lieuron",
    "Lieusaint",
    "Lieuvillers",
    "Liffol-le-Grand",
    "Liffre",
    "Ligescourt",
    "Lignan-sur-Orb",
    "Ligne",
    "Ligne",
    "Lignerolles",
    "Lignerolles",
    "Lignieres-Chatelain",
    "Lignieres-la-Carelle",
    "Lignol",
    "Ligny-en-Barrois",
    "Ligny-en-Cambresis",
    "Ligny-le-Chatel",
    "Ligny-le-Ribault",
    "Ligsdorf",
    "Ligueil",
    "Liguge",
    "Lihons",
    "Lihus",
    "Lille",
    "Lillebonne",
    "Lillers",
    "Limas",
    "Limay",
    "Limeil-Brevannes",
    "Limeray",
    "Limersheim",
    "Limerzel",
    "Limesy",
    "Limetz-Villez",
    "Limoges",
    "Limoges-Fourches",
    "Limogne-en-Quercy",
    "Limonest",
    "Limony",
    "Limours",
    "Limoux",
    "Linars",
    "Linas",
    "Lincel",
    "Lingolsheim",
    "Lingreville",
    "Linselles",
    "Linxe",
    "Liocourt",
    "Lion-en-Sullias",
    "Lion-sur-Mer",
    "Liouc",
    "Lipsheim",
    "Lire",
    "Lisieux",
    "Lisle",
    "LIsle-dEspagnac",
    "LIsle-sur-le-Doubs",
    "Lisle-sur-Tarn",
    "Lisors",
    "Lisses",
    "Lissieu",
    "Lissy",
    "Lit-et-Mixe",
    "Livarot",
    "Liverdun",
    "Liverdy-en-Brie",
    "Livernon",
    "Livet-et-Gavet",
    "Livet-sur-Authou",
    "Livinhac-le-Haut",
    "Livre-sur-Changeon",
    "Livron",
    "Livron-sur-Drome",
    "Livry-Gargan",
    "Livry-sur-Seine",
    "Lixhausen",
    "Lixheim",
    "Lizac",
    "Lizy-sur-Ourcq",
    "Llupia",
    "Loche",
    "Loche-sur-Indrois",
    "Locmaria-Grand-Champ",
    "Locmaria-Plouzane",
    "Locmariaquer",
    "Locmine",
    "Locmiquelic",
    "Locoal-Mendon",
    "Locon",
    "Locquemeau",
    "Locquirec",
    "Loctudy",
    "Loge-Fougereuse",
    "Logelbach",
    "Logelheim",
    "Lognes",
    "Logonna-Daoulas",
    "Logonna-Quimerch",
    "Logrian-Florian",
    "Lohr",
    "Loire",
    "Loire-les-Marais",
    "Loire-sur-Rhone",
    "Loiron",
    "Loisin",
    "Loison-sous-Lens",
    "Loisy",
    "Loisy-sur-Marne",
    "Loix",
    "Lokmaria",
    "Lombers",
    "Lombez",
    "Lombron",
    "Lomme",
    "Lommerange",
    "Lompret",
    "Londigny",
    "Londinieres",
    "Longages",
    "Longchamp",
    "Longchamp-sous-Chatenois",
    "Longeault",
    "Longecourt-en-Plaine",
    "Longes",
    "Longessaigne",
    "Longevelle-sur-Doubs",
    "Longeves",
    "Longeville-en-Barrois",
    "Longeville-les-Metz",
    "Longeville-les-Saint-Avold",
    "Longeville-sur-Mer",
    "Longfosse",
    "Longjumeau",
    "Longlaville",
    "Longnes",
    "Longpont-sur-Orge",
    "Longpre-les-Corps-Saints",
    "Longue-Jumelles",
    "Longueau",
    "Longueil-Annel",
    "Longueil-Sainte-Marie",
    "Longuenesse",
    "Longueville",
    "Longueville-sur-Scie",
    "Longuyon",
    "Longvic",
    "Longwy",
    "Lons",
    "Lons-le-Saunier",
    "Loon-Plage",
    "Loos",
    "Loos-en-Gohelle",
    "Loperhet",
    "Lorcieres",
    "Lorette",
    "Lorey",
    "Lorgies",
    "Lorgues",
    "Lorient",
    "Lorignac",
    "Loriol-du-Comtat",
    "Loriol-sur-Drome",
    "Lorlanges",
    "Lormaison",
    "Lormaye",
    "Lormont",
    "Lorquin",
    "Lorrez-le-Bocage-Preaux",
    "Lorris",
    "Lorry-Mardigny",
    "Los Masos",
    "Losne",
    "Louailles",
    "Louannec",
    "Louargat",
    "Louatre",
    "Loubaresse",
    "Loubes-Bernac",
    "Loubeyrat",
    "Loubieng",
    "Loubigne",
    "Loubille",
    "Loudeac",
    "Loudes",
    "Loudrefing",
    "Loudun",
    "Loue",
    "Louer",
    "Lougres",
    "Louhans",
    "Loupershouse",
    "Loupes",
    "Loupiac-de-la-Reole",
    "Loupian",
    "Louplande",
    "Lourches",
    "Lourdes",
    "Lourenties",
    "Loures-Barousse",
    "Louresse-Rochemenier",
    "Lourmarin",
    "Lourties-Monbrun",
    "Loury",
    "Louveciennes",
    "Louvemont",
    "Louverne",
    "Louvie-Juzon",
    "Louviers",
    "Louvigne-de-Bais",
    "Louvignies-Quesnoy",
    "Louvigny",
    "Louvigny",
    "Louvigny",
    "Louvil",
    "Louvrechy",
    "Louvres",
    "Louvroil",
    "Louzouer",
    "Louzy",
    "Lovagny",
    "Loyat",
    "Loyes",
    "Loyettes",
    "Lozanne",
    "Lozinghem",
    "Lozon",
    "Luant",
    "Lubersac",
    "Luc",
    "Luc-sur-Mer",
    "Luc-sur-Orbieu",
    "Lucciana",
    "Luce",
    "Luce",
    "Luceau",
    "Lucenay",
    "Lucey",
    "Luchy",
    "Lucon",
    "Lucq-de-Bearn",
    "Lucy-sur-Cure",
    "Ludon-Medoc",
    "Ludres",
    "Luemschwiller",
    "Lugny-Champagne",
    "Lugny-les-Charolles",
    "Lugrin",
    "Luisant",
    "Luitre",
    "Lullin",
    "Lully",
    "Lumbin",
    "Lumbres",
    "Lumeau",
    "Lumio",
    "Lunel",
    "Lunel-Viel",
    "Luneray",
    "Lupe",
    "Luppy",
    "Lupstein",
    "Luquet",
    "Lurais",
    "Luray",
    "Lurcy",
    "Lurcy-Levis",
    "Lure",
    "Lury-sur-Arnon",
    "Lus-la-Croix-Haute",
    "Lusanger",
    "Lusignan",
    "Lusignan-Grand",
    "Lusigny",
    "Lusigny-sur-Barse",
    "Lussac",
    "Lussac-les-Chateaux",
    "Lussac-les-Eglises",
    "Lussant",
    "Lussas",
    "Lussat",
    "Luttange",
    "Lutter",
    "Lutterbach",
    "Lutz-en-Dunois",
    "Lux",
    "Lux",
    "Luxe",
    "Luxeuil-les-Bains",
    "Luxey",
    "Luynes",
    "Luynes",
    "Luz-Saint-Sauveur",
    "Luzarches",
    "Luze",
    "Luzille",
    "Luzinay",
    "Luzy",
    "Lyaud",
    "Lynde",
    "Lyon",
    "Mably",
    "Macau",
    "Mache",
    "Machecoul",
    "Machemont",
    "Macheren",
    "Machezal",
    "Maclas",
    "Macot-la-Plagne",
    "Madirac",
    "Madre",
    "Madriat",
    "Mael-Carhaix",
    "Maennolsheim",
    "Maffliers",
    "Magagnosc",
    "Magalas",
    "Magenta",
    "Magescq",
    "Magland",
    "Magnan",
    "Magnanville",
    "Magne",
    "Magneux-Haute-Rive",
    "Magnieres",
    "Magny",
    "Magny-Cours",
    "Magny-en-Vexin",
    "Magny-le-Desert",
    "Magny-le-Hongre",
    "Magny-les-Hameaux",
    "Magny-les-Villers",
    "Magny-sur-Tille",
    "Magrie",
    "Magstatt-le-Haut",
    "Maiche",
    "Maidieres",
    "Maigne",
    "Maignelay-Montigny",
    "Maillane",
    "Maille",
    "Maillebois",
    "Mailly-Champagne",
    "Mailly-la-Ville",
    "Mailly-le-Camp",
    "Mailly-Maillet",
    "Mailly-Raineval",
    "Maincy",
    "Maing",
    "Maintenon",
    "Mainvilliers",
    "Mainxe",
    "Mairieux",
    "Mairy-sur-Marne",
    "Maisdon-sur-Sevre",
    "Maisnil",
    "Maisnil-les-Ruitz",
    "Maison-Ponthieu",
    "Maisons-Alfort",
    "Maisons-Laffitte",
    "Maisonsgoutte",
    "Maisontiers",
    "Maisse",
    "Maizeroy",
    "Maizieres-les-Metz",
    "Malabry",
    "Malafretaz",
    "Malain",
    "Malakoff",
    "Malancourt-la-Montagne",
    "Malange",
    "Malansac",
    "Malataverne",
    "Malaucene",
    "Malaumont",
    "Malaunay",
    "Malause",
    "Malaussanne",
    "Malay-le-Grand",
    "Malegoude",
    "Malemort-du-Comtat",
    "Malemort-sur-Correze",
    "Malesherbes",
    "Malestroit",
    "Maleville",
    "Malguenac",
    "Malicorne",
    "Malicorne-sur-Sarthe",
    "Malijai",
    "Malincourt",
    "Malintrat",
    "Malissard",
    "Malleloy",
    "Mallemoisson",
    "Mallemort",
    "Malling",
    "Malmerspach",
    "Malmont",
    "Malpas",
    "Malras",
    "Malrevers",
    "Malves-en-Minervois",
    "Malville",
    "Malzeville",
    "Mamers",
    "Mametz",
    "Mamey",
    "Mamirolle",
    "Manchecourt",
    "Mancieulles",
    "Mandagout",
    "Mandelieu-la-Napoule",
    "Mandeure",
    "Mandres-la-Cote",
    "Mandres-les-Roses",
    "Manduel",
    "Mane",
    "Maneglise",
    "Mangiennes",
    "Mangonville",
    "Manicamp",
    "Maninghen-Henne",
    "Manneville-es-Plains",
    "Mannevillette",
    "Manom",
    "Manoncourt-sur-Seille",
    "Manonviller",
    "Manosque",
    "Manot",
    "Mansigne",
    "Mansle",
    "Manspach",
    "Mantes-la-Jolie",
    "Mantes-la-Ville",
    "Manthelan",
    "Mantoche",
    "Mantry",
    "Manzat",
    "Manziat",
    "Marange-Silvange",
    "Marange-Zondrange",
    "Marans",
    "Maraussan",
    "Marbache",
    "Marc",
    "Marcamps",
    "Marcay",
    "Marce-sur-Esves",
    "Marcelcave",
    "Marcellaz-Albanais",
    "Marchainville",
    "Marchaux",
    "Marche-Allouarde",
    "Marcheprime",
    "Marchesieux",
    "Marcheville",
    "Marchiennes",
    "Marciac",
    "Marcigny",
    "Marcilhac-sur-Cele",
    "Marcillac",
    "Marcillac-la-Croisille",
    "Marcillat-en-Combraille",
    "Marcilloles",
    "Marcilly-en-Bassigny",
    "Marcilly-en-Beauce",
    "Marcilly-en-Villette",
    "Marcilly-sur-Eure",
    "Marcilly-sur-Seine",
    "Marcilly-sur-Tille",
    "Marcilly-sur-Vienne",
    "Marck",
    "Marckolsheim",
    "Marcoing",
    "Marcollin",
    "Marconnelle",
    "Marcoussis",
    "Marcouville",
    "Marcoux",
    "Marcq",
    "Marcq-en-Baroeul",
    "Marcq-en-Ostrevent",
    "Mardeuil",
    "Mardie",
    "Mardyck",
    "Mareau-aux-Bois",
    "Mareau-aux-Pres",
    "Mareil-en-France",
    "Mareil-Marly",
    "Mareil-sur-Loir",
    "Mareil-sur-Mauldre",
    "Marenla",
    "Marennes",
    "Marennes",
    "Marest",
    "Marestaing",
    "Mareuil",
    "Mareuil",
    "Mareuil-Caubert",
    "Mareuil-en-Brie",
    "Mareuil-le-Port",
    "Mareuil-les-Meaux",
    "Mareuil-sur-Cher",
    "Mareuil-sur-Lay-Dissais",
    "Mareuil-sur-Ourcq",
    "Margaux",
    "Margencel",
    "Margency",
    "Margerie-Chantagret",
    "Margny",
    "Margny-les-Compiegne",
    "Margon",
    "Marguerittes",
    "Margut",
    "Marie",
    "Marienthal",
    "Marignac",
    "Marignac-Lasclares",
    "Marignane",
    "Marigne",
    "Marignier",
    "Marigny",
    "Marigny",
    "Marigny-Brizay",
    "Marigny-Chemereau",
    "Marigny-en-Orxois",
    "Marigny-le-Chatel",
    "Marigny-les-Usages",
    "Marigny-Marmande",
    "Marin",
    "Marines",
    "Maringes",
    "Maringues",
    "Marle",
    "Marlenheim",
    "Marles-en-Brie",
    "Marles-les-Mines",
    "Marliens",
    "Marlioz",
    "Marlotte",
    "Marly",
    "Marly",
    "Marly-la-Ville",
    "Marly-le-Roi",
    "Marmagne",
    "Marmande",
    "Marmanhac",
    "Marmoutier",
    "Marnand",
    "Marnaz",
    "Marnes",
    "Marnes-la-Coquette",
    "Maroeuil",
    "Marolles",
    "Marolles-en-Brie",
    "Marolles-en-Hurepoix",
    "Marols",
    "Maromme",
    "Maron",
    "Maron",
    "Marpent",
    "Marquay",
    "Marquefave",
    "Marqueglise",
    "Marques",
    "Marquillies",
    "Marquion",
    "Marquise",
    "Marquixanes",
    "Mars",
    "Mars",
    "Mars-sur-Allier",
    "Marsac",
    "Marsac-en-Livradois",
    "Marsac-sur-lIsle",
    "Marsainvilliers",
    "Marsais",
    "Marsais-Sainte-Radegonde",
    "Marsaneix",
    "Marsangy",
    "Marsannay-le-Bois",
    "Marsanne",
    "Marsat",
    "Marsaz",
    "Marseillan",
    "Marseille",
    "Marseille-en-Beauvaisis",
    "Marseilles-les-Aubigny",
    "Marsillargues",
    "Marsilly",
    "Marsilly",
    "Marssac-sur-Tarn",
    "Martainville-Epreville",
    "Martel",
    "Marthod",
    "Martiel",
    "Martignas-sur-Jalle",
    "Martigne-Briand",
    "Martigne-Ferchaud",
    "Martigny",
    "Martigues",
    "Martillac",
    "Martin-Eglise",
    "Martinet",
    "Martinvast",
    "Martot",
    "Martres-Tolosane",
    "Marval",
    "Marvejols",
    "Marville-les-Bois",
    "Marzan",
    "Marzy",
    "Mas-Grenier",
    "Mas-Saintes-Puelles",
    "Masevaux",
    "Maslacq",
    "Maslives",
    "Masnieres",
    "Masny",
    "Masparraute",
    "Massat",
    "Masserac",
    "Masseret",
    "Massiac",
    "Massieu",
    "Massieux",
    "Massingy-les-Semur",
    "Massugas",
    "Massy",
    "Mastaing",
    "Matha",
    "Mathay",
    "Mathenay",
    "Mathieu",
    "Mathieu",
    "Matignon",
    "Matigny",
    "Matour",
    "Mattaincourt",
    "Mattexey",
    "Matzenheim",
    "Maubeuge",
    "Maubourguet",
    "Mauchamps",
    "Maucor",
    "Mauguio",
    "Maule",
    "Mauleon-Licharre",
    "Maulevrier",
    "Mauperthuis",
    "Mauprevoir",
    "Mauran",
    "Maure",
    "Maure-de-Bretagne",
    "Maurecourt",
    "Maureilhan",
    "Maureillas-las-Illas",
    "Mauremont",
    "Maurens",
    "Maurepas",
    "Mauressargues",
    "Maureville",
    "Mauriac",
    "Maurois",
    "Mauron",
    "Maurrin",
    "Maurs",
    "Maussane-les-Alpilles",
    "Mauves",
    "Mauves-sur-Huisne",
    "Mauves-sur-Loire",
    "Mauvezin-de-Prat",
    "Maux",
    "Mauzac",
    "Mauze-sur-le-Mignon",
    "Mauzens-et-Miremont",
    "Maxent",
    "Maxeville",
    "Maxilly-sur-Saone",
    "May-en-Multien",
    "May-sur-Orne",
    "Mayenne",
    "Mayet",
    "Maysel",
    "Mazamet",
    "Mazan",
    "Mazange",
    "Maze",
    "Mazeray",
    "Mazeres-de-Neste",
    "Mazeres-sur-Salat",
    "Mazerolles",
    "Mazerolles-le-Salin",
    "Mazet-Saint-Voy",
    "Mazeuil",
    "Mazieres-en-Gatine",
    "Mazieres-en-Mauges",
    "Mazingarbe",
    "Mazion",
    "Meaudre",
    "Meaulte",
    "Meaux",
    "Meauzac",
    "Medan",
    "Medis",
    "Medreac",
    "Mees",
    "Megrit",
    "Meharicourt",
    "Mehun-sur-Yevre",
    "Meigneux",
    "Meilhac",
    "Meilhan",
    "Meilhan",
    "Meilhan-sur-Garonne",
    "Meillant",
    "Meillerie",
    "Meillon",
    "Meillonnas",
    "Meisenthal",
    "Mejannes-le-Clap",
    "Mejannes-les-Ales",
    "Melay",
    "Melay",
    "Melesse",
    "Melgven",
    "Mellac",
    "Melle",
    "Mellecey",
    "Melleran",
    "Melleray",
    "Mellionnec",
    "Mello",
    "Melrand",
    "Melsheim",
    "Melun",
    "Membrey",
    "Menat",
    "Mende",
    "Mendionde",
    "Meneac",
    "Menerbes",
    "Menesplet",
    "Menestreau-en-Villette",
    "Menetou-Salon",
    "Menetreol-sur-Sauldre",
    "Menetrol",
    "Menil-Erreux",
    "Menil-Hermei",
    "Menil-la-Horgne",
    "Menil-sur-Belvitte",
    "Menilles",
    "Mennecy",
    "Mennevret",
    "Mens",
    "Mensignac",
    "Menthon-Saint-Bernard",
    "Menton",
    "Menucourt",
    "Menville",
    "Meounes-les-Montrieux",
    "Mer",
    "Mercenac",
    "Merckeghem",
    "Mercues",
    "Mercurol",
    "Mercy-le-Bas",
    "Merdrignac",
    "Mere",
    "Mereau",
    "Merenvielle",
    "Mereville",
    "Merey-sous-Montrond",
    "Mergey",
    "Mericourt",
    "Meriel",
    "Merignac",
    "Merignas",
    "Merignat",
    "Merignies",
    "Merigny",
    "Merindol",
    "Merlas",
    "Merlebach",
    "Merlevenez",
    "Merlimont",
    "Merlines",
    "Mernel",
    "Meroux",
    "Merry-la-Vallee",
    "Merry-sur-Yonne",
    "Mers-les-Bains",
    "Mertzen",
    "Mertzwiller",
    "Meru",
    "Mervans",
    "Merville",
    "Merville",
    "Merville-Franceville-Plage",
    "Merxheim",
    "Mery",
    "Mery-es-Bois",
    "Mery-sur-Oise",
    "Mery-sur-Seine",
    "Mesanger",
    "Mesangueville",
    "Meschers-sur-Gironde",
    "Mescoules",
    "Mesland",
    "Meslay",
    "Meslay-du-Maine",
    "Meslay-le-Vidame",
    "Meslin",
    "Mesnil-Raoul",
    "Mesnil-Saint-Georges",
    "Mesnil-Saint-Nicaise",
    "Mesnil-Sellieres",
    "Mesnil-sous-Vienne",
    "Mesplede",
    "Messac",
    "Messanges",
    "Messei",
    "Messein",
    "Messia-sur-Sorne",
    "Messigny-et-Vantoux",
    "Messimy",
    "Messon",
    "Messy",
    "Metabief",
    "Meteren",
    "Mettray",
    "Metz",
    "Metz-Tessy",
    "Metzeral",
    "Metzeresche",
    "Metzervisse",
    "Meucon",
    "Meudon",
    "Meulan-en-Yvelines",
    "Meung-sur-Loire",
    "Meurchin",
    "Meursault",
    "Meuse",
    "Meusnes",
    "Meuzac",
    "Mevoisins",
    "Meximieux",
    "Mexy",
    "Meyenheim",
    "Meylan",
    "Meymac",
    "Meynes",
    "Meyrargues",
    "Meyras",
    "Meyreuil",
    "Meyssac",
    "Meythet",
    "Meyzieu",
    "Meze",
    "Mezel",
    "Mezel",
    "Mezeres",
    "Mezeriat",
    "Mezidon-Canon",
    "Mezieres-en-Drouais",
    "Mezieres-lez-Clery",
    "Mezieres-sous-Lavardin",
    "Mezieres-sur-Couesnon",
    "Mezieres-sur-Seine",
    "Mezzavia",
    "Michelbach-le-Bas",
    "Mielan",
    "Miellin",
    "Mietesheim",
    "Mieussy",
    "Migennes",
    "Miglos",
    "Mignaloux-Beauvoir",
    "Migne-Auxances",
    "Mignieres",
    "Migron",
    "Milhaud",
    "Milizac",
    "Millac",
    "Millas",
    "Millau",
    "Millencourt",
    "Millery",
    "Millery",
    "Milly",
    "Milly-la-Foret",
    "Milly-Lamartine",
    "Milly-sur-Therain",
    "Milon-la-Chapelle",
    "Mimbaste",
    "Mimet",
    "Mimizan",
    "Mine de Bert",
    "Mingot",
    "Miniac-Morvan",
    "Miniac-sous-Becherel",
    "Minihy-Treguier",
    "Minversheim",
    "Minzier",
    "Mionnay",
    "Mions",
    "Mios",
    "Mirabel-aux-Baronnies",
    "Miradoux",
    "Miramas",
    "Mirambeau",
    "Miramont-de-Guyenne",
    "Miramont-Sensacq",
    "Mirande",
    "Mirebeau",
    "Mirebeau-sur-Beze",
    "Mirecourt",
    "Miremont",
    "Mirepeix",
    "Mirepoix",
    "Mirepoix-sur-Tarn",
    "Mireval-Lauragais",
    "Miribel",
    "Miserey",
    "Miserey-Salines",
    "Mison",
    "Misse",
    "Missillac",
    "Missiriac",
    "Misson",
    "Missy-sur-Aisne",
    "Mitry-Mory",
    "Mittelbronn",
    "Mittelhausbergen",
    "Mittelwihr",
    "Mitterand",
    "Mittersheim",
    "Modane",
    "Moelan-sur-Mer",
    "Moens",
    "Moernach",
    "Moeslains",
    "Mogneville",
    "Mogues",
    "Mohon",
    "Moineville",
    "Moinville-la-Jeulin",
    "Moirans",
    "Moirans-en-Montagne",
    "Moire",
    "Moisdon-la-Riviere",
    "Moislains",
    "Moissac",
    "Moissat",
    "Moisselles",
    "Moissieu-sur-Dolon",
    "Moissy-Cramayel",
    "Moisville",
    "Molac",
    "Molandier",
    "Molesme",
    "Moliens",
    "Molieres-sur-Ceze",
    "Moliets-et-Maa",
    "Molinet",
    "Molineuf",
    "Molleges",
    "Molliens-au-Bois",
    "Mollkirch",
    "Moloy",
    "Molsheim",
    "Moltifao",
    "Momas",
    "Mombrier",
    "Momeres",
    "Mommenheim",
    "Momy",
    "Monbahus",
    "Monbalen",
    "Monbrun",
    "Moncale",
    "Monce-en-Belin",
    "Monceau-le-Neuf-et-Faucouzy",
    "Monceau-le-Waast",
    "Monceau-Saint-Waast",
    "Monceaux",
    "Moncel-sur-Seille",
    "Moncetz-Longevas",
    "Moncheaux-les-Frevent",
    "Monchecourt",
    "Monchy-Breton",
    "Monchy-Humieres",
    "Monchy-Saint-Eloi",
    "Monclar-de-Quercy",
    "Moncontour",
    "Moncoutant",
    "Mondelange",
    "Mondescourt",
    "Mondetour",
    "Mondeville",
    "Mondeville",
    "Mondon",
    "Mondonville",
    "Mondragon",
    "Mondrainville",
    "Mondrepuis",
    "Monein",
    "Monestier-de-Clermont",
    "Monesties",
    "Monetay-sur-Loire",
    "Moneteau",
    "Monferran-Plaves",
    "Monferran-Saves",
    "Monflanquin",
    "Monistrol-sur-Loire",
    "Monnaie",
    "Monnetier-Mornex",
    "Monneville",
    "Monnieres",
    "Monpazier",
    "Mons",
    "Mons",
    "Mons",
    "Mons",
    "Mons-en-Baroeul",
    "Mons-en-Montois",
    "Mons-en-Pevele",
    "Monsac",
    "Monsegur",
    "Monsempron-Libos",
    "Monsireigne",
    "Monsteroux-Milieu",
    "Mont",
    "Mont",
    "Mont",
    "Mont-Bonvillers",
    "Mont-Cauvaire",
    "Mont-de-Marsan",
    "Mont-Notre-Dame",
    "Mont-pres-Chambord",
    "Mont-Saint-Aignan",
    "Mont-Saint-Martin",
    "Mont-Saint-Martin",
    "Mont-Saint-Pere",
    "Mont-Saxonnex",
    "Mont-sur-Meurthe",
    "Mont-sur-Monnet",
    "Montabard",
    "Montady",
    "Montagnac",
    "Montagnat",
    "Montagne",
    "Montagney",
    "Montagnieu",
    "Montagnole",
    "Montagny-en-Vexin",
    "Montagny-les-Lanches",
    "Montagny-les-Seurre",
    "Montagny-Sainte-Felicite",
    "Montagny-sur-Grosne",
    "Montagrier",
    "Montagut",
    "Montaigu",
    "Montaigu",
    "Montaigu",
    "Montaigu-de-Quercy",
    "Montaigut-sur-Save",
    "Montainville",
    "Montalet-le-Bois",
    "Montalieu-Vercieu",
    "Montamise",
    "Montanay",
    "Montardon",
    "Montargis",
    "Montarlot-les-Rioz",
    "Montarnaud",
    "Montastruc-de-Salies",
    "Montastruc-la-Conseillere",
    "Montataire",
    "Montauban",
    "Montauban-de-Bretagne",
    "Montaud",
    "Montaudin",
    "Montaulieu",
    "Montaure",
    "Montauroux",
    "Montaut",
    "Montaut",
    "Montayral",
    "Montbard",
    "Montbartier",
    "Montbazin",
    "Montbazon",
    "Montbel",
    "Montbellet",
    "Montberon",
    "Montbert",
    "Montbeton",
    "Montbeugny",
    "Montbizot",
    "Montblanc",
    "Montboillon",
    "Montbonnot-Saint-Martin",
    "Montboucher-sur-Jabron",
    "Montbouton",
    "Montbrison",
    "Montbron",
    "Montbronn",
    "Montbrun-Bocage",
    "Montcaret",
    "Montcarra",
    "Montceau",
    "Montceau-les-Mines",
    "Montceaux",
    "Montceaux-les-Meaux",
    "Montcel",
    "Montcenis",
    "Montcey",
    "Montchamp",
    "Montchanin",
    "Montchaude",
    "Montclar",
    "Montcornet",
    "Montcourt-Fromonville",
    "Montcoy",
    "Montcuq",
    "Montcy-Notre-Dame",
    "Montdidier",
    "Montdoumerc",
    "Montdragon",
    "Monteaux",
    "Montebourg",
    "Montech",
    "Montecheroux",
    "Monteglin",
    "Monteils",
    "Montel-de-Gelat",
    "Montelier",
    "Montemboeuf",
    "Montendre",
    "Montenescourt",
    "Montenois",
    "Montenoison",
    "Monterblanc",
    "Montereau-Fault-Yonne",
    "Monterfil",
    "Montescot",
    "Montesquieu",
    "Montesquieu-des-Alberes",
    "Montesquieu-Volvestre",
    "Montessaux",
    "Montesson",
    "Montestruc-sur-Gers",
    "Montestrucq",
    "Monteux",
    "Montevrain",
    "Monteynard",
    "Montfarville",
    "Montfaucon",
    "Montfaucon",
    "Montfaucon",
    "Montfaucon-en-Velay",
    "Montfaucon-Montigne",
    "Montfavet",
    "Montfermeil",
    "Montfermy",
    "Montferrand-du-Perigord",
    "Montferrand-le-Chateau",
    "Montferrat",
    "Montferrier-sur-Lez",
    "Montfleur",
    "Montfort",
    "Montfort-en-Chalosse",
    "Montfort-sur-Meu",
    "Montfrin",
    "Montfroc",
    "Montfuron",
    "Montgaillard",
    "Montgaroult",
    "Montgermont",
    "Montgeron",
    "Montgesty",
    "Montgirod",
    "Montgiscard",
    "Montgivray",
    "Montherme",
    "Monthieux",
    "Monthodon",
    "Monthou-sur-Bievre",
    "Monthou-sur-Cher",
    "Monthureux-sur-Saone",
    "Monthyon",
    "Monticello",
    "Montier-en-Der",
    "Montierchaume",
    "Montiers",
    "Montignac-de-Lauzun",
    "Montignac-le-Coq",
    "Montigne-le-Brillant",
    "Montigne-les-Rairies",
    "Montigny",
    "Montigny",
    "Montigny-en-Cambresis",
    "Montigny-en-Gohelle",
    "Montigny-le-Bretonneux",
    "Montigny-le-Chartif",
    "Montigny-Lengrain",
    "Montigny-les-Cormeilles",
    "Montigny-sur-Loing",
    "Montilly",
    "Montilly-sur-Noireau",
    "Montiron",
    "Montivilliers",
    "Montjavoult",
    "Montjean-sur-Loire",
    "Montlhery",
    "Montliard",
    "Montlieu-la-Garde",
    "Montlignon",
    "Montlivault",
    "Montlouis",
    "Montlouis-sur-Loire",
    "Montluel",
    "Montmacq",
    "Montmagny",
    "Montmalin",
    "Montmarault",
    "Montmartin",
    "Montmartin-en-Graignes",
    "Montmartin-sur-Mer",
    "Montmelian",
    "Montmerle-sur-Saone",
    "Montmeyran",
    "Montmirail",
    "Montmiral",
    "Montmirat",
    "Montmoreau-Saint-Cybard",
    "Montmorency",
    "Montmorency-Beaufort",
    "Montmorillon",
    "Montmorot",
    "Montoir-de-Bretagne",
    "Montoire-sur-le-Loir",
    "Montois-la-Montagne",
    "Montoison",
    "Montolivet",
    "Montournais",
    "Montpellier",
    "Montpeyroux",
    "Montpeyroux",
    "Montpezat",
    "Montpezat-de-Quercy",
    "Montpezat-sous-Bauzon",
    "Montpincon",
    "Montpinier",
    "Montpitol",
    "Montpon-Menesterol",
    "Montpothier",
    "Montrabe",
    "Montreal",
    "Montreal",
    "Montreal-la-Cluse",
    "Montredon-des-Corbieres",
    "Montredon-Labessonnie",
    "Montrejeau",
    "Montrelais",
    "Montrem",
    "Montrequienne",
    "Montresor",
    "Montret",
    "Montreuil",
    "Montreuil",
    "Montreuil-aux-Lions",
    "Montreuil-Bonnin",
    "Montreuil-en-Caux",
    "Montreuil-sous-Perouse",
    "Montreuil-sur-Blaise",
    "Montreuil-sur-Breche",
    "Montreuil-sur-Lozon",
    "Montreuil-sur-Mer",
    "Montreux-Jeune",
    "Montrevel",
    "Montrevel-en-Bresse",
    "Montrichard",
    "Montricoux",
    "Montrodat",
    "Montrond",
    "Montrond",
    "Montrond-les-Bains",
    "Montrouge",
    "Montrouveau",
    "Montry",
    "Monts",
    "Monts-en-Bessin",
    "Monts-sur-Guesnes",
    "Montsalvy",
    "Montsauche-les-Settons",
    "Montsegur-sur-Lauzon",
    "Montseveroux",
    "Montsoreau",
    "Montsoult",
    "Montussan",
    "Montvalen",
    "Montvalent",
    "Montvendre",
    "Montverdun",
    "Montville",
    "Moon-sur-Elle",
    "Moosch",
    "Moragne",
    "Morainvilliers",
    "Morance",
    "Morancez",
    "Morangis",
    "Morangis",
    "Morbecque",
    "Morbier",
    "Morcenx",
    "Mordelles",
    "Moreac",
    "Moree",
    "Morelmaison",
    "Morestel",
    "Moret-sur-Loing",
    "Moreuil",
    "Morez",
    "Morgny",
    "Morgny-la-Pommeraye",
    "Morhange",
    "Morienval",
    "Morieres-les-Avignon",
    "Morieux",
    "Morigny-Champigny",
    "Morisel",
    "Moriville",
    "Morlaas",
    "Morlaix",
    "Mormant",
    "Mornac",
    "Mornac-sur-Seudre",
    "Mornant",
    "Mornay",
    "Mornay-sur-Allier",
    "Moroges",
    "Morogues",
    "Morre",
    "Morsain",
    "Morsang-sur-Orge",
    "Morsbach",
    "Morsbronn-les-Bains",
    "Morschwiller-le-Bas",
    "Mortagne",
    "Mortagne-au-Perche",
    "Mortagne-du-Nord",
    "Mortagne-sur-Sevre",
    "Mortain",
    "Mortcerf",
    "Morteau",
    "Morteaux-Couliboeuf",
    "Mortefontaine",
    "Mortefontaine-en-Thelle",
    "Mortree",
    "Mortzwiller",
    "Morvillers-Saint-Saturnin",
    "Morvilliers",
    "Morzine",
    "Moslins",
    "Mosnac",
    "Mosson",
    "Motteville",
    "Mouans-Sartoux",
    "Mouaze",
    "Mouchin",
    "Mouflers",
    "Mougins",
    "Mougon",
    "Mouguerre",
    "Mouilleron-en-Pareds",
    "Mouilleron-le-Captif",
    "Mouledous",
    "Moules",
    "Mouliets-et-Villemartin",
    "Moulin",
    "Moulin-Mage",
    "Moulin-Neuf",
    "Moulin-sous-Touvent",
    "Moulineaux",
    "Moulines",
    "Moulinet",
    "Moulinet",
    "Moulins",
    "Moulins-Engilbert",
    "Moulins-les-Metz",
    "Moulins-sur-Cephons",
    "Moulins-sur-Yevre",
    "Moulle",
    "Moult",
    "Moumour",
    "Mourenx",
    "Moureze",
    "Mouries",
    "Mourioux-Vieilleville",
    "Mourmelon-le-Grand",
    "Mourmelon-le-Petit",
    "Mouroux",
    "Mours",
    "Mours-Saint-Eusebe",
    "Moussan",
    "Mousseaux-Neuville",
    "Moussey",
    "Mousson",
    "Moussoulens",
    "Moussy",
    "Moussy-le-Neuf",
    "Moussy-le-Vieux",
    "Mousteru",
    "Moustier-en-Fagne",
    "Mouthe",
    "Mouthier-en-Bresse",
    "Mouthiers-sur-Boeme",
    "Moutiers",
    "Moutiers",
    "Moutiers-les-Mauxfaits",
    "Moutiers-sur-le-Lay",
    "Mouvaux",
    "Mouxy",
    "Mouy",
    "Mouzeil",
    "Mouzieys-Teulet",
    "Mouzillon",
    "Mouzon",
    "Moyaux",
    "Moyencourt",
    "Moyenmoutier",
    "Moyenneville",
    "Moyenneville",
    "Moyeuvre-Grande",
    "Moyon",
    "Moyrazes",
    "Moyvillers",
    "Mozac",
    "Muespach-le-Haut",
    "Muides-sur-Loire",
    "Muidorge",
    "Muirancourt",
    "Muizon",
    "Mulcent",
    "Mulcey",
    "Mulhouse",
    "Mulsanne",
    "Munchhouse",
    "Mundolsheim",
    "Munster",
    "Munster",
    "Mur-de-Bretagne",
    "Mur-de-Sologne",
    "Murat",
    "Mures",
    "Muret",
    "Murianette",
    "Muro",
    "Murol",
    "Muron",
    "Murs-Erigne",
    "Murviel-les-Beziers",
    "Murviel-les-Montpellier",
    "Mus",
    "Mussidan",
    "Mussig",
    "Mutzig",
    "Muzillac",
    "Nadillac",
    "Nages-et-Solorgues",
    "Nailloux",
    "Naintre",
    "Naizin",
    "Najac",
    "Nalliers",
    "Nancy",
    "Nandy",
    "Nangis",
    "Nans-les-Pins",
    "Nanterre",
    "Nantes",
    "Nanteuil-en-Vallee",
    "Nanteuil-le-Haudouin",
    "Nanteuil-les-Meaux",
    "Nantiat",
    "Nantoin",
    "Nanton",
    "Nantua",
    "Naours",
    "Narbonne",
    "Narbonne-Plage",
    "Nargis",
    "Narrosse",
    "Nattages",
    "Naucelle",
    "Naujan-et-Postiac",
    "Naux",
    "Navailles-Angos",
    "Navarrenx",
    "Naves",
    "Nay",
    "Nayemont-les-Fosses",
    "Nazelles-Negron",
    "Neauphle-le-Chateau",
    "Neauphlette",
    "Nebian",
    "Nebouzat",
    "Nedonchel",
    "Neewiller-pres-Lauterbourg",
    "Neffes",
    "Neffies",
    "Nefiach",
    "Negrepelisse",
    "Negron",
    "Nehou",
    "Nemours",
    "Neoules",
    "Nercillac",
    "Nere",
    "Neris-les-Bains",
    "Neron",
    "Neronde-sur-Dore",
    "Ners",
    "Nersac",
    "Nerville-la-Foret",
    "Nery",
    "Nesle",
    "Nesles",
    "Nesles-la-Montagne",
    "Nesles-la-Vallee",
    "Nesmy",
    "Nessa",
    "Neuf Eglise",
    "Neuf-Brisach",
    "Neufchateau",
    "Neufchatel-en-Bray",
    "Neufchef",
    "Neufgrange",
    "Neuflize",
    "Neufmanil",
    "Neufmesnil",
    "Neufmoutiers-en-Brie",
    "Neufvy-sur-Aronde",
    "Neuil",
    "Neuille",
    "Neuilly",
    "Neuilly-en-Thelle",
    "Neuilly-le-Bisson",
    "Neuilly-le-Real",
    "Neuilly-les-Dijon",
    "Neuilly-Plaisance",
    "Neuilly-Saint-Front",
    "Neuilly-sous-Clermont",
    "Neuilly-sur-Marne",
    "Neuilly-sur-Seine",
    "Neulliac",
    "Neung-sur-Beuvron",
    "Neure",
    "Neussargues-Moissac",
    "Neuve-Maison",
    "Neuvecelle",
    "Neuves-Maisons",
    "Neuvic",
    "Neuvic-Entier",
    "Neuvicq-le-Chateau",
    "Neuville",
    "Neuville",
    "Neuville-aux-Bois",
    "Neuville-Bosc",
    "Neuville-de-Poitou",
    "Neuville-en-Ferrain",
    "Neuville-Ferrieres",
    "Neuville-les-Dames",
    "Neuville-les-Dieppe",
    "Neuville-Saint-Remy",
    "Neuville-Saint-Vaast",
    "Neuville-sur-Ain",
    "Neuville-sur-Brenne",
    "Neuville-sur-Escaut",
    "Neuville-sur-Oise",
    "Neuville-sur-Saone",
    "Neuville-sur-Sarthe",
    "Neuville-sur-Seine",
    "Neuviller-sur-Moselle",
    "Neuvireuil",
    "Neuvy",
    "Neuvy",
    "Neuvy-Bouin",
    "Neuvy-en-Beauce",
    "Neuvy-en-Sullias",
    "Neuvy-le-Roi",
    "Neuvy-Saint-Sepulchre",
    "Nevers",
    "Nevez",
    "Nevian",
    "Neville",
    "Nexon",
    "Neydens",
    "Nibas",
    "Nibelle",
    "Nice",
    "Nicole",
    "Nicorps",
    "Niderhoff",
    "Niderviller",
    "Niederbronn-les-Bains",
    "Niederbruck",
    "Niederentzen",
    "Niederhaslach",
    "Niederhausbergen",
    "Niedermodern",
    "Niederschaeffolsheim",
    "Nieppe",
    "Niergnies",
    "Nieuil",
    "Nieul-le-Virouil",
    "Nieul-les-Saintes",
    "Nieul-sur-Mer",
    "Nievroz",
    "Niffer",
    "Niherne",
    "Nilvange",
    "Niort",
    "Nissan-lez-Enserune",
    "Nitting",
    "Nivillac",
    "Nivolas-Vermelle",
    "Nizas",
    "Noailhac",
    "Noaillac",
    "Noaillan",
    "Noailles",
    "Noailles",
    "Noe",
    "Noeux-les-Auxi",
    "Noeux-les-Mines",
    "Nogent",
    "Nogent-le-Phaye",
    "Nogent-le-Roi",
    "Nogent-le-Rotrou",
    "Nogent-sur-Eure",
    "Nogent-sur-Marne",
    "Nogent-sur-Oise",
    "Nogent-sur-Seine",
    "Nogent-sur-Vernisson",
    "Nohanent",
    "Nohic",
    "Noidans-le-Ferroux",
    "Noidans-les-Vesoul",
    "Noilhan",
    "Nointel",
    "Nointot",
    "Noiron",
    "Noiron-sous-Gevrey",
    "Noirterre",
    "Noiseau",
    "Noisiel",
    "Noisseville",
    "Noisy-le-Grand",
    "Noisy-le-Roi",
    "Noisy-le-Sec",
    "Noisy-Rudignon",
    "Noisy-sur-Ecole",
    "Noisy-sur-Oise",
    "Nolay",
    "Nomain",
    "Nomeny",
    "Nomexy",
    "Nommay",
    "Nonancourt",
    "Nonette",
    "Nonneville",
    "Nontron",
    "Nonville",
    "Nonvilliers-Grandhoux",
    "Noordpeene",
    "Nordausques",
    "Nordhouse",
    "Norges-la-Ville",
    "Normanville",
    "Normier",
    "Norrent-Fontes",
    "Norrey-en-Auge",
    "Norroy-le-Sec",
    "Norroy-le-Veneur",
    "Norroy-les-Pont-a-Mousson",
    "Nort-sur-Erdre",
    "Nostang",
    "Nothalten",
    "Notre-Dame-de-Boisset",
    "Notre-Dame-de-Bondeville",
    "Notre-Dame-de-Cenilly",
    "Notre-Dame-de-Commiers",
    "Notre-Dame-de-Gravenchon",
    "Notre-Dame-de-la-Rouviere",
    "Notre-Dame-de-Livaye",
    "Notre-Dame-de-Livoye",
    "Notre-Dame-de-Mesage",
    "Notre-Dame-de-Riez",
    "Notre-Dame-de-Sanilhac",
    "Notre-Dame-des-Landes",
    "Notre-Dame-du-Pre",
    "Nouaille-Maupertuis",
    "Nouainville",
    "Nouan-le-Fuzelier",
    "Noueilles",
    "Nouilly",
    "Nousseviller-les-Bitche",
    "Nousseviller-Saint-Nabor",
    "Nouvelle-Eglise",
    "Nouvion-le-Comte",
    "Nouvion-le-Vineux",
    "Nouvion-sur-Meuse",
    "Nouvoitou",
    "Nouzonville",
    "Novalaise",
    "Noveant-sur-Moselle",
    "Noves",
    "Novillard",
    "Novillars",
    "Novion-Porcien",
    "Noyal-Muzillac",
    "Noyal-Pontivy",
    "Noyal-sur-Vilaine",
    "Noyant-de-Touraine",
    "Noyant-la-Gravoyere",
    "Noyant-la-Plaine",
    "Noyarey",
    "Noyelles-Godault",
    "Noyelles-sous-Lens",
    "Noyelles-sur-Escaut",
    "Noyen-sur-Sarthe",
    "Noyers",
    "Noyers",
    "Noyers-Bocage",
    "Noyers-sur-Cher",
    "Noyon",
    "Nozay",
    "Nozay",
    "Nuaille",
    "Nuaille-sur-Boutonne",
    "Nueil-sur-Layon",
    "Nuelles",
    "Nuits-Saint-Georges",
    "Nyoiseau",
    "Nyons",
    "Obenheim",
    "Oberbronn",
    "Oberentzen",
    "Oberhaslach",
    "Oberhausbergen",
    "Oberhergheim",
    "Oberhoffen-sur-Moder",
    "Oberlauterbach",
    "Obermodern-Zutzendorf",
    "Obermorschwihr",
    "Obermorschwiller",
    "Obernai",
    "Obersaasheim",
    "Oberschaeffolsheim",
    "Objat",
    "Oblinghem",
    "Obtree",
    "Ochey",
    "Octeville",
    "Octeville-sur-Mer",
    "Oderen",
    "Odomez",
    "Odos",
    "Oelleville",
    "OEting",
    "Oeyreluy",
    "Offekerque",
    "Offemont",
    "Offendorf",
    "Offoy",
    "Offranville",
    "Ogenne-Camptort",
    "Oger",
    "Ogeu-les-Bains",
    "Ogeviller",
    "Ogy",
    "Oignies",
    "Oingt",
    "Oinville-sur-Montcient",
    "Oiron",
    "Oiselay-et-Grachaux",
    "Oisemont",
    "Oisly",
    "Oisseau",
    "Oissel",
    "Oisy",
    "Oisy-le-Verger",
    "Oizon",
    "Olargues",
    "Olby",
    "Olemps",
    "Oletta",
    "Olivet",
    "Ollainville",
    "Olle",
    "Ollezy",
    "Ollieres",
    "Olliergues",
    "Ollioules",
    "Olloix",
    "Olmet",
    "Olonne-sur-Mer",
    "Olonzac",
    "Oloron-Sainte-Marie",
    "Olwisheim",
    "Omessa",
    "Omonville-la-Rogue",
    "Ondes",
    "Ondres",
    "Ondreville-sur-Essonne",
    "Onet-le-Chateau",
    "Onnaing",
    "Onnion",
    "Ons-en-Bray",
    "Onzain",
    "Opio",
    "Oppede",
    "Optevoz",
    "Oradour",
    "Oradour-Fanais",
    "Oradour-sur-Glane",
    "Oraison",
    "Orangis",
    "Orban",
    "Orbec",
    "Orbeil",
    "Orbey",
    "Orcet",
    "Orchaise",
    "Orchamps-Vennes",
    "Orches",
    "Orchies",
    "Orcier",
    "Orcines",
    "Ordan-Larroque",
    "Ordiarp",
    "Oregue",
    "Oresmaux",
    "Orgelet",
    "Orgeres",
    "Orgerus",
    "Orgeval",
    "Orgon",
    "Orgueil",
    "Orignolles",
    "Origny-en-Thierache",
    "Origny-le-Sec",
    "Origny-Sainte-Benoite",
    "Oriol-en-Royans",
    "Orion",
    "Orleat",
    "Orlienas",
    "Orlu",
    "Orly",
    "Ormersviller",
    "Ormes",
    "Ormes-et-Ville",
    "Ormesson-sur-Marne",
    "Ormoy",
    "Ormoy",
    "Ormoy-Villers",
    "Ornacieux",
    "Ornans",
    "Ornex",
    "Orny",
    "Orphin",
    "Orrouer",
    "Orrouy",
    "Orry-la-Ville",
    "Orsan",
    "Orsanco",
    "Orsonville",
    "Orthez",
    "Ortoncourt",
    "Orval",
    "Orval",
    "Orvault",
    "Orvaux",
    "Orveau-Bellesauve",
    "Orvillers-Sorel",
    "Osne-le-Val",
    "Osny",
    "Ossages",
    "Osse",
    "Osses",
    "Ossun",
    "Ostheim",
    "Ostricourt",
    "Ostwald",
    "Ota",
    "Othis",
    "Ottange",
    "Ottmarsheim",
    "Ottonville",
    "Ottrott",
    "Ouanne",
    "Ouarville",
    "Ouchamps",
    "Oucques",
    "Oudon",
    "Ouerre",
    "Ouezy",
    "Ouffieres",
    "Ougney",
    "Ouhans",
    "Ouilly-le-Tesson",
    "Ouilly-le-Vicomte",
    "Ouistreham",
    "Oulchy-le-Chateau",
    "Oullins",
    "Oupia",
    "Ourches",
    "Ouroux",
    "Ouroux-sur-Saone",
    "Oursbelille",
    "Ousse",
    "Ousson-sur-Loire",
    "Outarville",
    "Outille",
    "Outines",
    "Outreau",
    "Ouveillan",
    "Ouville",
    "Ouville",
    "Ouzilly",
    "Ouzouer-le-Marche",
    "Ouzouer-sur-Loire",
    "Ouzouer-sur-Trezee",
    "Ovanches",
    "Ovillers-la-Boisselle",
    "Oye-Plage",
    "Oyeu",
    "Oyonnax",
    "Oytier-Saint-Oblas",
    "Ozan",
    "Ozoir-la-Ferriere",
    "Ozolles",
    "Ozouer-le-Voulgis",
    "Pabu",
    "Pace",
    "Pact",
    "Pacy-sur-Eure",
    "Pageas",
    "Pagny-la-Ville",
    "Pagny-sur-Meuse",
    "Pagny-sur-Moselle",
    "Paillart",
    "Paillencourt",
    "Paillet",
    "Paimboeuf",
    "Paimpol",
    "Paimpont",
    "Painblanc",
    "Pair-et-Grandrupt",
    "Paix",
    "Paizay-le-Chapt",
    "Paizay-le-Sec",
    "Paizay-le-Tort",
    "Palaiseau",
    "Palaja",
    "Palalda",
    "Palaminy",
    "Palau-del-Vidre",
    "Palavas-les-Flots",
    "Paleyrac",
    "Palinges",
    "Palleau",
    "Palluau",
    "Palluau-sur-Indre",
    "Pallud",
    "Pamfou",
    "Pamiers",
    "Pamproux",
    "Panassac",
    "Panazol",
    "Panissieres",
    "Pannece",
    "Pannes",
    "Panossas",
    "Pantin",
    "Panzoult",
    "Paradou",
    "Paray-le-Monial",
    "Paray-sous-Briailles",
    "Paray-Vieille-Poste",
    "Paraza",
    "Parcay-Meslay",
    "Parce-sur-Sarthe",
    "Parcieux",
    "Pareds",
    "Parempuyre",
    "Parent",
    "Parentis-en-Born",
    "Pargny-Filain",
    "Pargny-la-Dhuys",
    "Pargny-les-Bois",
    "Pargny-sur-Saulx",
    "Parigne",
    "Parigne-sur-Braye",
    "Parigny",
    "Paris",
    "Paris 17 Batignolles-Monceau",
    "Parisot",
    "Parly",
    "Parmain",
    "Parnans",
    "Parnay",
    "Parne-sur-Roc",
    "Paron",
    "Parsac",
    "Parthenay",
    "Parthenay-de-Bretagne",
    "Parzac",
    "Pas-en-Artois",
    "Paslieres",
    "Pasly",
    "Pasques",
    "Passel",
    "Passy",
    "Patay",
    "Pau",
    "Pauilhac",
    "Pauillac Haut",
    "Paul",
    "Paulhac",
    "Paulhaguet",
    "Paulhan",
    "Paulx",
    "Pavie",
    "Pavilly",
    "Payrac",
    "Payrin-Augmontel",
    "Payroux",
    "Payzac",
    "Payzac",
    "Peaugres",
    "Peaule",
    "Pebrac",
    "Pechabou",
    "Pechbonnieu",
    "Pecquencourt",
    "Pecqueuse",
    "Pegomas",
    "Peillac",
    "Peille",
    "Peillon",
    "Peillonnex",
    "Peipin",
    "Peisey-Nancroix",
    "Pelissanne",
    "Pellegrue",
    "Pellevoisin",
    "Pellouailles-les-Vignes",
    "Pelousey",
    "Peltre",
    "Pelussin",
    "Penchard",
    "Pencran",
    "Pende",
    "Penestin",
    "Penguily",
    "Penmarch",
    "Pennautier",
    "Penne-dAgenais",
    "Pennedepie",
    "Penol",
    "Penta-di-Casinca",
    "Penvenan",
    "Pepieux",
    "Peray",
    "Percy",
    "Pere",
    "Perenchies",
    "Peret",
    "Peri",
    "Periers",
    "Perignac",
    "Perignat-les-Sarlieve",
    "Perigne",
    "Perigneux",
    "Perigny",
    "Perigny",
    "Perillos",
    "Pern",
    "Pernay",
    "Pernes",
    "Pernes-les-Boulogne",
    "Pernes-les-Fontaines",
    "Perols",
    "Perols",
    "Peron",
    "Peronnas",
    "Peronne",
    "Peronne",
    "Peronne-en-Melantois",
    "Perouse",
    "Peroy-les-Gombries",
    "Perpezac-le-Noir",
    "Perpignan",
    "Perrecy-les-Forges",
    "Perreux",
    "Perreux",
    "Perrex",
    "Perrier",
    "Perriers-sur-Andelle",
    "Perrignier",
    "Perrigny",
    "Perrigny-les-Dijon",
    "Perrogney-les-Fontaines",
    "Perros-Guirec",
    "Perrou",
    "Perruel",
    "Pers",
    "Pers-Jussy",
    "Persan",
    "Persquen",
    "Perthes-les-Brienne",
    "Pertheville-Ners",
    "Pertuis",
    "Pessac",
    "Pessac-sur-Dordogne",
    "Pessan",
    "Pessans",
    "Pessat-Villeneuve",
    "Petit Chaumont",
    "Petit-Couronne",
    "Petit-Landau",
    "Petit-Mars",
    "Petit-Palais-et-Cornemps",
    "Petite-Foret",
    "Petite-Rosselle",
    "Petiville",
    "Petreto-Bicchisano",
    "Peumerit",
    "Pexonne",
    "Pey",
    "Peymeinade",
    "Peynier",
    "Peypin",
    "Peyrat-le-Chateau",
    "Peyrehorade",
    "Peyriac-de-Mer",
    "Peyriac-Minervois",
    "Peyrignac",
    "Peyrilhac",
    "Peyrilles",
    "Peyrins",
    "Peyrissas",
    "Peyrolles-en-Provence",
    "Peyruis",
    "Peyrus",
    "Peyrusse-Massas",
    "Pezenas",
    "Pezens",
    "Pezilla-la-Riviere",
    "Pezou",
    "Pfaffenheim",
    "Pfaffenhoffen",
    "Pfastatt",
    "Pfettisheim",
    "Pfulgriesheim",
    "Phaffans",
    "Phalempin",
    "Phalsbourg",
    "Pia",
    "Piazza",
    "Piblange",
    "Pibrac",
    "Picauville",
    "Picquigny",
    "Piegros-la-Clastre",
    "Piegut-Pluviers",
    "Piennes",
    "Pierre",
    "Pierre-Benite",
    "Pierre-de-Bresse",
    "Pierre-la-Treiche",
    "Pierre-Levee",
    "Pierrefeu-du-Var",
    "Pierrefitte-Nestalas",
    "Pierrefitte-sur-Seine",
    "Pierrefonds",
    "Pierrefort",
    "Pierregot",
    "Pierrelatte",
    "Pierrelaye",
    "Pierremande",
    "Pierrepont-sur-Avre",
    "Pierrerue",
    "Pierres",
    "Pierrevert",
    "Pierrevillers",
    "Pierric",
    "Pierry",
    "Pietralba",
    "Pieusse",
    "Pignan",
    "Pignans",
    "Pignols",
    "Pihem",
    "Pimbo",
    "Pimprez",
    "Pin",
    "Pinet",
    "Pineuilh",
    "Piney",
    "Pinon",
    "Pinsaguel",
    "Piolenc",
    "Pionsat",
    "Pipriac",
    "Piquecos",
    "Pire-sur-Seiche",
    "Pirey",
    "Pirou",
    "Piscop",
    "Piseux",
    "Pissos",
    "Pissotte",
    "Pissy-Poville",
    "Pithiviers",
    "Pitres",
    "Pizay",
    "Plabennec",
    "Place",
    "Plailly",
    "Plaimpied-Givaudins",
    "Plaine-de-Walsch",
    "Plaine-Haute",
    "Plaintel",
    "Plaisance",
    "Plaisance-du-Touch",
    "Plaisir",
    "Plan-de-Cuques",
    "Planay",
    "Plancher-Bas",
    "Planches",
    "Planguenoual",
    "Planzolles",
    "Plappeville",
    "Plassay",
    "Plateau",
    "Plaudren",
    "Plauzat",
    "Pleboulle",
    "Plechatel",
    "Pledran",
    "Plehedel",
    "Plelan-le-Grand",
    "Plelan-le-Petit",
    "Plelauff",
    "Plelo",
    "Plemet",
    "Plenee-Jugon",
    "Pleneuf-Val-Andre",
    "Plerguer",
    "Plerin",
    "Plerneuf",
    "Plescop",
    "Plesidy",
    "Plesnois",
    "Plessala",
    "Plesse",
    "Plessis-Barbuise",
    "Plessis-de-Roye",
    "Plestan",
    "Plestin-les-Greves",
    "Pleucadeuc",
    "Pleudihen-sur-Rance",
    "Pleugriffet",
    "Pleugueneuc",
    "Pleumartin",
    "Pleumeleuc",
    "Pleumeur-Bodou",
    "Pleure",
    "Pleurs",
    "Pleurtuit",
    "Pleuven",
    "Pleyben",
    "Pleyber-Christ",
    "Plobannalec-Lesconil",
    "Plobsheim",
    "Ploemel",
    "Ploemeur",
    "Ploerdut",
    "Ploeren",
    "Ploermel",
    "Ploeuc-sur-Lie",
    "Ploeven",
    "Plogonnec",
    "Plomb",
    "Plombieres-les-Bains",
    "Plombieres-les-Dijon",
    "Plomelin",
    "Plomeur",
    "Plonevez-du-Faou",
    "Plorec-sur-Arguenon",
    "Plottes",
    "Plouagat",
    "Plouaret",
    "Plouarzel",
    "Plouasne",
    "Plouay",
    "Ploubalay",
    "Ploubazlanec",
    "Ploubezre",
    "Ploudalmezeau",
    "Ploudaniel",
    "Plouec-du-Trieux",
    "Plouedern",
    "Plouer-sur-Rance",
    "Plouescat",
    "Plouezec",
    "Ploufragan",
    "Plougar",
    "Plougasnou",
    "Plougastel-Daoulas",
    "Plougonvelin",
    "Plougonven",
    "Plougoulm",
    "Plougoumelen",
    "Plougourvest",
    "Plougrescant",
    "Plouguerneau",
    "Plouguin",
    "Plouha",
    "Plouharnel",
    "Plouigneau",
    "Ploumagoar",
    "Ploumilliau",
    "Ploumoguer",
    "Plouneour-Menez",
    "Plouneour-Trez",
    "Plounerin",
    "Plounevez-Lochrist",
    "Plounevezel",
    "Plourin",
    "Plourivo",
    "Plouvain",
    "Plouvara",
    "Plouvien",
    "Plouvorn",
    "Plouzane",
    "Plozevet",
    "Pludual",
    "Pluduno",
    "Pluguffan",
    "Pluherlin",
    "Plumaugat",
    "Plumeliau",
    "Plumelin",
    "Plumergat",
    "Plumoison",
    "Pluneret",
    "Plurien",
    "Plusquellec",
    "Plussulien",
    "Pluvault",
    "Pluvigner",
    "Pluzunet",
    "Poce-sur-Cisse",
    "Podensac",
    "Poeuilly",
    "Poey-de-Lescar",
    "Poggio-dOletta",
    "Pogny",
    "Poigny-la-Foret",
    "Poilly-lez-Gien",
    "Poilly-sur-Tholon",
    "Poinsenot",
    "Pointis-Inard",
    "Poisat",
    "Poiseul-les-Saulx",
    "Poisieux",
    "Poisson",
    "Poissons",
    "Poissy",
    "Poisy",
    "Poitiers",
    "Poix",
    "Poix-de-Picardie",
    "Poix-du-Nord",
    "Poligny",
    "Poligny",
    "Pollestres",
    "Polliat",
    "Pomacle",
    "Pomarez",
    "Pomerols",
    "Pomeys",
    "Pommard",
    "Pommeret",
    "Pommerit-Jaudy",
    "Pommerit-le-Vicomte",
    "Pommeuse",
    "Pommevic",
    "Pommiers",
    "Pommiers-la-Placette",
    "Pompadour",
    "Pompaire",
    "Pompertuzat",
    "Pompey",
    "Pompidou",
    "Pompignan",
    "Pomponne",
    "Pomport",
    "Poncey-les-Athee",
    "Ponchon",
    "Poncin",
    "Poncins",
    "Pons",
    "Pons",
    "Pont",
    "Pont-a-Marcq",
    "Pont-a-Vendin",
    "Pont-Audemer",
    "Pont-Aven",
    "Pont-de-Barret",
    "Pont-de-Beauvoisin",
    "Pont-de-Briques",
    "Pont-de-Buis-les-Quimerch",
    "Pont-de-Cheruy",
    "Pont-de-Labeaume",
    "Pont-de-Larn",
    "Pont-de-Metz",
    "Pont-de-Roide",
    "Pont-de-Salars",
    "Pont-de-Vaux",
    "Pont-de-Veyle",
    "Pont-du-Casse",
    "Pont-du-Chateau",
    "Pont-en-Royans",
    "Pont-Eveque",
    "Pont-Hebert",
    "Pont-Noyelles",
    "Pont-Remy",
    "Pont-Saint-Esprit",
    "Pont-Saint-Mard",
    "Pont-Saint-Martin",
    "Pont-Saint-Pierre",
    "Pont-Saint-Vincent",
    "Pont-Sainte-Marie",
    "Pont-Sainte-Maxence",
    "Pont-Salomon",
    "Pont-Scorff",
    "Pont-sur-Sambre",
    "Pont-sur-Seine",
    "Pont-sur-Yonne",
    "Pontacq",
    "Pontailler-sur-Saone",
    "Pontarlier",
    "Pontarme",
    "Pontaubault",
    "Pontault-Combault",
    "Pontaumur",
    "Pontaut",
    "Pontavert",
    "Pontcarre",
    "Pontcharra",
    "Pontcharra-sur-Turdine",
    "Pontchateau",
    "Pontcirq",
    "Ponte-Leccia",
    "Ponteilla",
    "Pontenx-les-Forges",
    "Pontgibaud",
    "Pontgouin",
    "Ponthevrard",
    "Ponthierry",
    "Pontigne",
    "Pontigny",
    "Pontivy",
    "Pontlevoy",
    "Pontoise",
    "Pontorson",
    "Pontours",
    "Pontpoint",
    "Pontruet",
    "Ponts",
    "Pontvallain",
    "Popian",
    "Porcelette",
    "Porcheres",
    "Porcheresse",
    "Porcheville",
    "Porcieu-Amblagnieu",
    "Pordic",
    "Pornic",
    "Pornichet",
    "Port-Brillet",
    "Port-Cros",
    "Port-de-Bouc",
    "Port-des-Barques",
    "Port-en-Bessin-Huppain",
    "Port-Joinville",
    "Port-la-Nouvelle",
    "Port-Launay",
    "Port-Louis",
    "Port-Mort",
    "Port-Saint-Louis-du-Rhone",
    "Port-Saint-Pere",
    "Port-Sainte-Foy-et-Ponchapt",
    "Port-Sainte-Marie",
    "Port-sur-Saone",
    "Portbail",
    "Portel-des-Corbieres",
    "Portes-les-Valence",
    "Portet-sur-Garonne",
    "Portets",
    "Porticcio",
    "Portieux",
    "Portiragnes",
    "Porto",
    "Porto-Vecchio",
    "Portrieux",
    "Ports",
    "Portvendres",
    "Poses",
    "Potigny",
    "Pouan-les-Vallees",
    "Pouance",
    "Pouffonds",
    "Pougny",
    "Pougues-les-Eaux",
    "Pougy",
    "Pouille",
    "Pouille",
    "Pouillenay",
    "Pouilley-Francais",
    "Pouilley-les-Vignes",
    "Pouillon",
    "Pouilloux",
    "Pouilly",
    "Pouilly",
    "Pouilly-en-Auxois",
    "Pouilly-le-Monial",
    "Pouilly-les-Nonains",
    "Pouilly-sous-Charlieu",
    "Pouilly-sur-Loire",
    "Pouilly-sur-Serre",
    "Pouilly-sur-Vingeanne",
    "Poulainville",
    "Poulan-Pouzols",
    "Pouldergat",
    "Pouldreuzic",
    "Poule-les-Echarmeaux",
    "Pouligney-Lusans",
    "Poullan-sur-Mer",
    "Poullaouen",
    "Poulx",
    "Pournoy-la-Grasse",
    "Pourrain",
    "Pourrieres",
    "Pouru-Saint-Remy",
    "Poussan",
    "Pouxeux",
    "Pouydesseaux",
    "Pouzauges",
    "Pouzay",
    "Pouzilhac",
    "Pouzolles",
    "Pouzols-Minervois",
    "Poville",
    "Pradelles",
    "Prades",
    "Prades-le-Lez",
    "Pradieres",
    "Prahecq",
    "Pralognan-la-Vanoise",
    "Pranzac",
    "Prasville",
    "Prat",
    "Prats-de-Carlux",
    "Pray",
    "Prayssac",
    "Prayssas",
    "Praz",
    "Praz-sur-Arly",
    "Pre-en-Pail",
    "Preaux",
    "Precey",
    "Prechac",
    "Prechac",
    "Precieux",
    "Precigne",
    "Precorbin",
    "Precy-sous-Thil",
    "Precy-sur-Marne",
    "Precy-sur-Oise",
    "Precy-sur-Vrin",
    "Prefailles",
    "Prefontaines",
    "Preguillac",
    "Preignac",
    "Preignan",
    "Premanon",
    "Premery",
    "Premian",
    "Premontre",
    "Preseau",
    "Presles-en-Brie",
    "Presles-et-Thierny",
    "Pressagny-le-Val",
    "Pressignac-Vicq",
    "Pressigny",
    "Pressigny-les-Pins",
    "Pretot-Vicquemare",
    "Preuschdorf",
    "Prevessin-Moens",
    "Prey",
    "Prigonrieux",
    "Primarette",
    "Primat",
    "Prin-Deyrancon",
    "Princay",
    "Pringy",
    "Pringy",
    "Prinquiau",
    "Prisse",
    "Privas",
    "Prix",
    "Prix-les-Mezieres",
    "Proissans",
    "Prompsat",
    "Pronville",
    "Propriano",
    "Prouais",
    "Prouilly",
    "Prouvy",
    "Prouzel",
    "Provencheres-sur-Fave",
    "Provencheres-sur-Meuse",
    "Proveysieux",
    "Proville",
    "Provin",
    "Provins",
    "Proyart",
    "Prudhomat",
    "Prunay-Cassereau",
    "Prunay-le-Gillon",
    "Prunay-sur-Essonne",
    "Prunelli-di-Fiumorbo",
    "Prunieres",
    "Pruniers",
    "Pruzilly",
    "Publier",
    "Puceul",
    "Puchevillers",
    "Puget-sur-Argens",
    "Puget-Ville",
    "Pugey",
    "Pugieu",
    "Pugnac",
    "Pugny-Chatenod",
    "Puicheric",
    "Puihardy",
    "Puilacher",
    "Puilboreau",
    "Puimichel",
    "Puiseaux",
    "Puiseux-en-France",
    "Puiseux-le-Hauberger",
    "Puisieulx",
    "Puisieux",
    "Puissalicon",
    "Puisseguin",
    "Puisserguier",
    "Pujaudran",
    "Pujaut",
    "Pujo-le-Plan",
    "Pujols",
    "Puligny-Montrachet",
    "Pulligny",
    "Pulnoy",
    "Pulversheim",
    "Pusignan",
    "Pussay",
    "Puteaux",
    "Putot-en-Bessin",
    "Puttelange-les-Thionville",
    "Puy-Guillaume",
    "Puy-Saint-Andre",
    "Puy-Saint-Martin",
    "Puybegon",
    "Puybrun",
    "Puycelsi",
    "Puycornet",
    "Puygouzon",
    "Puygros",
    "Puylaurens",
    "Puyloubier",
    "Puymiclan",
    "Puyravault",
    "Puyravault",
    "Puyreaux",
    "Puyricard",
    "Quaedypre",
    "Quarouble",
    "Quarre-les-Tombes",
    "Quatremare",
    "Queaux",
    "Quebriac",
    "Quedillac",
    "Queige",
    "Quelaines-Saint-Gault",
    "Quelneuc",
    "Quenoche",
    "Querenaing",
    "Quernes",
    "Querqueville",
    "Querrien",
    "Querrieu",
    "Quers",
    "Quesnoy-sur-Deule",
    "Quessoy",
    "Questembert",
    "Quetigny",
    "Quettehou",
    "Quettreville-sur-Sienne",
    "Quevauvillers",
    "Queven",
    "Quevert",
    "Quevillon",
    "Queyrac",
    "Queyrieres",
    "Quezac",
    "Quiberon",
    "Quiberville",
    "Quibou",
    "Quiers",
    "Quievrechain",
    "Quievrecourt",
    "Quievy",
    "Quillan",
    "Quillebeuf-sur-Seine",
    "Quilly",
    "Quilly",
    "Quily",
    "Quimper",
    "Quincampoix",
    "Quincie-en-Beaujolais",
    "Quincieu",
    "Quincy",
    "Quincy-sous-Senart",
    "Quincy-Voisins",
    "Quineville",
    "Quingey",
    "Quinsac",
    "Quint-Fonsegrives",
    "Quintenic",
    "Quintin",
    "Quissac",
    "Rabastens-de-Bigorre",
    "Rabat-les-Trois-Seigneurs",
    "Rablay-sur-Layon",
    "Rabou",
    "Raches",
    "Racquinghem",
    "Racrange",
    "Radinghem",
    "Radinghem-en-Weppes",
    "Radon",
    "Radonvilliers",
    "Raedersheim",
    "Raffetot",
    "Rahon",
    "Rai",
    "Raids",
    "Raimbeaucourt",
    "Rainfreville",
    "Rainneville",
    "Rainville",
    "Rainvillers",
    "Raismes",
    "Raizeux",
    "Ramatuelle",
    "Rambert",
    "Rambervillers",
    "Rambouillet",
    "Rammersmatt",
    "Ramonchamp",
    "Ramonville-Saint-Agne",
    "Rance",
    "Rancogne",
    "Randan",
    "Randan",
    "Randens",
    "Ranes",
    "Rang",
    "Rang-du-Fliers",
    "Rans",
    "Ranspach",
    "Rantigny",
    "Ranville",
    "Raon-aux-Bois",
    "Raon-sur-Plaine",
    "Rarecourt",
    "Ratieres",
    "Ratte",
    "Raucourt-et-Flaba",
    "Rauville-la-Place",
    "Rauwiller",
    "Rauzan",
    "Ravenel",
    "Ravieres",
    "Ray-sur-Saone",
    "Rayssac",
    "Razac-sur-lIsle",
    "Real",
    "Realmont",
    "Realville",
    "Reaumont",
    "Reaumur",
    "Rebais",
    "Rebenacq",
    "Rebrechien",
    "Rebreuve-sur-Canche",
    "Rebreuviette",
    "Recey-sur-Ource",
    "Rechesy",
    "Rechicourt-le-Chateau",
    "Recloses",
    "Recologne",
    "Recologne-les-Rioz",
    "Recques-sur-Course",
    "Recquignies",
    "Redene",
    "Redessan",
    "Reding",
    "Redon",
    "Reffuveille",
    "Regniowez",
    "Reguiny",
    "Reguisheim",
    "Regusse",
    "Rehainviller",
    "Rehon",
    "Reichshoffen",
    "Reichstett",
    "Reignac-sur-Indre",
    "Reignier-Esery",
    "Reillanne",
    "Reilly",
    "Reims",
    "Reims-la-Brulee",
    "Reiners",
    "Reiningue",
    "Reipertswiller",
    "Reitwiller",
    "Relanges",
    "Remaucourt",
    "Remecourt",
    "Remelfang",
    "Remelfing",
    "Remenoville",
    "Remerangles",
    "Remereville",
    "Remering-les-Puttelange",
    "Remigny",
    "Remilly",
    "Reminiac",
    "Remiremont",
    "Remoulins",
    "Remungol",
    "Remuzat",
    "Remy",
    "Renage",
    "Renaison",
    "Renault",
    "Renaze",
    "Rene",
    "Renescure",
    "Rennes",
    "Renneville",
    "Repaix",
    "Reparsac",
    "Replonges",
    "Requeil",
    "Requista",
    "Ressons-le-Long",
    "Ressons-sur-Matz",
    "Restinclieres",
    "Rethel",
    "Rethondes",
    "Retiers",
    "Retonfey",
    "Retournac",
    "Retschwiller",
    "Rettel",
    "Rety",
    "Reuil-en-Brie",
    "Reuil-sur-Breche",
    "Reuilly",
    "Reuilly",
    "Reuilly-Sauvigny",
    "Reumont",
    "Reuves",
    "Revel",
    "Revel-Tourdan",
    "Reventin-Vaugris",
    "Revest-des-Brousses",
    "Reviers",
    "Revigny",
    "Revigny-sur-Ornain",
    "Reville",
    "Revin",
    "Reynies",
    "Reyrieux",
    "Reyssouze",
    "Rezonville",
    "Rhinau",
    "Riaille",
    "Rians",
    "Rians",
    "Riantec",
    "Ribaute-les-Tavernes",
    "Ribecourt-Dreslincourt",
    "Ribemont",
    "Ribemont-sur-Ancre",
    "Riberac",
    "Ribiers",
    "Ricarville",
    "Richardmenil",
    "Richebourg",
    "Richebourg",
    "Richelieu",
    "Richeling",
    "Richemont",
    "Richemont",
    "Richwiller",
    "Ricquebourg",
    "Riec-sur-Belon",
    "Riedisheim",
    "Riedseltz",
    "Riencourt-les-Bapaume",
    "Rieulay",
    "Rieumes",
    "Rieupeyroux",
    "Rieux",
    "Rieux",
    "Rieux",
    "Rieux-de-Pelleport",
    "Rieux-en-Cambresis",
    "Rieux-Minervois",
    "Riez",
    "Rignac",
    "Rignieux-le-Franc",
    "Rilhac-Rancon",
    "Rillieux-la-Pape",
    "Rimbaud",
    "Rimogne",
    "Ringendorf",
    "Rinxent",
    "Riocaud",
    "Riom",
    "Riom-es-Montagnes",
    "Rion-des-Landes",
    "Rions",
    "Riorges",
    "Riotord",
    "Rioz",
    "Ris-Orangis",
    "Rittershoffen",
    "Rivarennes",
    "Rive-de-Gier",
    "Rivecourt",
    "Rivedoux-Plage",
    "Rivery",
    "Rivesaltes",
    "Riviere",
    "Rivieres",
    "Rivieres",
    "Riville",
    "Rixheim",
    "Roaillan",
    "Roanne",
    "Roannes-Saint-Mary",
    "Robert",
    "Robion",
    "Rocbaron",
    "Roche",
    "Roche",
    "Roche-la-Moliere",
    "Roche-le-Peyroux",
    "Roche-lez-Beaupre",
    "Rochechouart",
    "Rochecorbon",
    "Rochefort",
    "Rochefort-du-Gard",
    "Rochefort-en-Terre",
    "Rochefort-sur-Loire",
    "Rochefort-sur-Nenon",
    "Rochegude",
    "Rochemaure",
    "Rocher",
    "Roches",
    "Roches-les-Blamont",
    "Roches-Premarie-Andille",
    "Roches-sur-Marne",
    "Rocheserviere",
    "Rochesson",
    "Rochetoirin",
    "Rochetrejoux",
    "Rochy-Conde",
    "Rocquemont",
    "Rocquencourt",
    "Rocroi",
    "Rodelinghem",
    "Roderen",
    "Rodern",
    "Rodez",
    "Roeschwoog",
    "Roeulx",
    "Roeux",
    "Roeze-sur-Sarthe",
    "Roffey",
    "Roffiac",
    "Rogerville",
    "Rognac",
    "Rognaix",
    "Rognes",
    "Rognonas",
    "Rohan",
    "Rohan",
    "Rohr",
    "Rohrbach-les-Bitche",
    "Roiffieux",
    "Roisel",
    "Roissy-en-Brie",
    "Roissy-en-France",
    "Rolampont",
    "Rollancourt",
    "Rolleville",
    "Rollot",
    "Romagnat",
    "Romagne",
    "Romagne",
    "Romagne",
    "Romagnieu",
    "Romain",
    "Romain-sur-Meuse",
    "Romaine",
    "Romainville",
    "Romaneche-Thorins",
    "Romange",
    "Romans",
    "Rombas",
    "Romegoux",
    "Romeny-sur-Marne",
    "Romeries",
    "Romille",
    "Romilly-sur-Andelle",
    "Romilly-sur-Seine",
    "Romorantin-Lanthenay",
    "Rompon",
    "Roncey",
    "Ronchin",
    "Roncq",
    "Ronnet",
    "Ronno",
    "Ronsenac",
    "Ronthon",
    "Roost-Warendin",
    "Roppe",
    "Roppenheim",
    "Roppentzwiller",
    "Roquebilliere",
    "Roquebrune",
    "Roquebrune-Cap-Martin",
    "Roquebrune-sur-Argens",
    "Roquecor",
    "Roquecourbe",
    "Roquefort",
    "Roquefort",
    "Roquefort-de-Sault",
    "Roquefort-des-Corbieres",
    "Roquefort-la-Bedoule",
    "Roquefort-sur-Garonne",
    "Roquefort-sur-Soulzon",
    "Roquelaure-Saint-Aubin",
    "Roquemaure",
    "Roques",
    "Roqueseriere",
    "Roquesteron",
    "Roquetoire",
    "Roquettes",
    "Roquevaire",
    "Rosay",
    "Rosay-sur-Lieure",
    "Roscoff",
    "Rosel",
    "Rosenwiller",
    "Rosheim",
    "Rosieres",
    "Rosieres-aux-Salines",
    "Rosieres-en-Haye",
    "Rosieres-en-Santerre",
    "Rosieres-pres-Troyes",
    "Rosieres-sur-Mance",
    "Rosny-sous-Bois",
    "Rosny-sur-Seine",
    "Rosoy-en-Multien",
    "Rospez",
    "Rosporden",
    "Rosselange",
    "Rossfeld",
    "Rosteig",
    "Rostrenen",
    "Rosult",
    "Rothau",
    "Rotherens",
    "Rots",
    "Rott",
    "Rouans",
    "Roubaix",
    "Roubia",
    "Roubion",
    "Roucy",
    "Rouen",
    "Rouffach",
    "Rouffiac-Tolosan",
    "Rouffignac",
    "Rouffignac-Saint-Cernin-de-Reilhac",
    "Rouge-Perriers",
    "Rougegoutte",
    "Rougemont",
    "Rougiers",
    "Rouillac",
    "Rouille",
    "Rouillon",
    "Rouilly-Sacey",
    "Roujan",
    "Roulans",
    "Roullens",
    "Roullet-Saint-Estephe",
    "Roumare",
    "Roumazieres",
    "Roumegoux",
    "Roumoules",
    "Rountzenheim",
    "Rouperroux",
    "Rousies",
    "Roussay",
    "Rousseloy",
    "Rousset",
    "Roussillon",
    "Roussillon",
    "Rousson",
    "Roussy",
    "Roussy-le-Village",
    "Routot",
    "Rouville",
    "Rouvillers",
    "Rouvre",
    "Rouvres-la-Chetive",
    "Rouvroy",
    "Rouvroy",
    "Rouvroy-sur-Audry",
    "Rouvroy-sur-Marne",
    "Rouxmesnil-Bouteilles",
    "Rouy-le-Grand",
    "Rouziers-de-Touraine",
    "Roville-aux-Chenes",
    "Royan",
    "Royas",
    "Royat",
    "Roybon",
    "Roye",
    "Roye",
    "Roye-sur-Matz",
    "Rozay-en-Brie",
    "Rozerieulles",
    "Rozier-en-Donzy",
    "Rozieres-en-Beauce",
    "Rozoy-Bellevalle",
    "Rozoy-sur-Serre",
    "Ruaudin",
    "Rubelles",
    "Rubempre",
    "Ruch",
    "Rucqueville",
    "Rue",
    "Rueil-Malmaison",
    "Ruelisheim",
    "Ruelle",
    "Ruelle-sur-Touvre",
    "Ruesnes",
    "Ruffec",
    "Ruffey-les-Beaune",
    "Ruffey-les-Echirey",
    "Ruffey-sur-Seille",
    "Ruffiac",
    "Rugles",
    "Ruille-Froid-Fonds",
    "Ruille-le-Gravelais",
    "Ruitz",
    "Rully",
    "Rully",
    "Rumaucourt",
    "Rumegies",
    "Rumersheim-le-Haut",
    "Rumigny",
    "Rumilly",
    "Rumilly-en-Cambresis",
    "Rungis",
    "Ruoms",
    "Rupt",
    "Rupt-sur-Moselle",
    "Rurange-les-Thionville",
    "Russ",
    "Ruy",
    "Ry",
    "Saacy-sur-Marne",
    "Saales",
    "Sabatier",
    "Sable-sur-Sarthe",
    "Sablonceaux",
    "Sablons",
    "Sabres",
    "Sacey",
    "Sachin",
    "Saclas",
    "Saclay",
    "Sacquenville",
    "Sacy",
    "Sadirac",
    "Saessolsheim",
    "Saffre",
    "Sagy",
    "Sahurs",
    "Saignes",
    "Sail-sous-Couzan",
    "Saillans",
    "Sailly-en-Ostrevent",
    "Sailly-Flibeaucourt",
    "Sailly-Labourse",
    "Sailly-le-Sec",
    "Sailly-lez-Cambrai",
    "Sailly-sur-la-Lys",
    "Sain-Bel",
    "Sainghin-en-Melantois",
    "Sainghin-en-Weppes",
    "Sainneville",
    "Sainpuits",
    "Sains-du-Nord",
    "Sains-en-Amienois",
    "Sains-en-Gohelle",
    "Saint Nicolas Les Arras",
    "Saint-Abit",
    "Saint-Adrien",
    "Saint-Affrique",
    "Saint-Agathon",
    "Saint-Agnant",
    "Saint-Agoulin",
    "Saint-Agreve",
    "Saint-Aignan-des-Noyers",
    "Saint-Aignan-sur-Roe",
    "Saint-Aigulin",
    "Saint-Albain",
    "Saint-Alban-de-Roche",
    "Saint-Alban-du-Rhone",
    "Saint-Alban-Leysse",
    "Saint-Alexandre",
    "Saint-Amancet",
    "Saint-Amand-de-Belves",
    "Saint-Amand-de-Coly",
    "Saint-Amand-en-Puisaye",
    "Saint-Amand-les-Eaux",
    "Saint-Amand-Magnazeix",
    "Saint-Amand-Montrond",
    "Saint-Amand-sur-Fion",
    "Saint-Amans-Soult",
    "Saint-Amans-Valtoret",
    "Saint-Amant-de-Boixe",
    "Saint-Amant-Tallende",
    "Saint-Amarin",
    "Saint-Ambroix",
    "Saint-Amour",
    "Saint-Andeol-de-Vals",
    "Saint-Andeux",
    "Saint-Andiol",
    "Saint-Andre",
    "Saint-Andre-de-Chalencon",
    "Saint-Andre-de-Corcy",
    "Saint-Andre-de-Messei",
    "Saint-Andre-de-Roquelongue",
    "Saint-Andre-de-Rosans",
    "Saint-Andre-de-Sangonis",
    "Saint-Andre-des-Eaux",
    "Saint-Andre-en-Vivarais",
    "Saint-Andre-Farivillers",
    "Saint-Andre-le-Gaz",
    "Saint-Andre-le-Puy",
    "Saint-Andre-les-Alpes",
    "Saint-Andre-les-Vergers",
    "Saint-Andre-lez-Lille",
    "Saint-Andre-sur-Orne",
    "Saint-Andre-Treize-Voies",
    "Saint-Androny",
    "Saint-Angeau",
    "Saint-Angel",
    "Saint-Antoine-Cumond",
    "Saint-Antoine-du-Rocher",
    "Saint-Antoine-la-Foret",
    "Saint-Antonin-sur-Bayon",
    "Saint-Apollinaire",
    "Saint-Appolinard",
    "Saint-Aquilin-de-Pacy",
    "Saint-Armel",
    "Saint-Arnoult",
    "Saint-Arnoult",
    "Saint-Arnoult-des-Bois",
    "Saint-Arnoult-en-Yvelines",
    "Saint-Astier",
    "Saint-Auban",
    "Saint-Aubert",
    "Saint-Aubin",
    "Saint-Aubin-de-Baubigne",
    "Saint-Aubin-de-Lanquais",
    "Saint-Aubin-de-Medoc",
    "Saint-Aubin-des-Chateaux",
    "Saint-Aubin-du-Cormier",
    "Saint-Aubin-du-Plain",
    "Saint-Aubin-en-Bray",
    "Saint-Aubin-en-Charollais",
    "Saint-Aubin-les-Elbeuf",
    "Saint-Aubin-les-Forges",
    "Saint-Aubin-sous-Erquery",
    "Saint-Aubin-sur-Gaillon",
    "Saint-Aubin-sur-Mer",
    "Saint-Aubin-sur-Scie",
    "Saint-Augustin",
    "Saint-Augustin",
    "Saint-Augustin",
    "Saint-Aunes",
    "Saint-Avaugourd-des-Landes",
    "Saint-Ave",
    "Saint-Avertin",
    "Saint-Avit",
    "Saint-Avit-les-Guespieres",
    "Saint-Avold",
    "Saint-Avre",
    "Saint-Ay",
    "Saint-Aygulf",
    "Saint-Baldoph",
    "Saint-Bardoux",
    "Saint-Barnabe",
    "Saint-Barnabe",
    "Saint-Barthelemy-de-Bussiere",
    "Saint-Barthelemy-de-Vals",
    "Saint-Barthelemy-Lestra",
    "Saint-Baudille-de-la-Tour",
    "Saint-Bauzille-de-Montmel",
    "Saint-Bauzille-de-Putois",
    "Saint-Beat",
    "Saint-Benezet",
    "Saint-Benin-dAzy",
    "Saint-Benoit",
    "Saint-Benoit-des-Ondes",
    "Saint-Benoit-sur-Loire",
    "Saint-Berain-sur-Dheune",
    "Saint-Bernard",
    "Saint-Beron",
    "Saint-Berthevin",
    "Saint-Bihy",
    "Saint-Blaise-la-Roche",
    "Saint-Bohaire",
    "Saint-Boil",
    "Saint-Boingt",
    "Saint-Bonnet-de-Mure",
    "Saint-Bonnet-le-Chateau",
    "Saint-Bonnet-le-Troncy",
    "Saint-Bonnet-les-Allier",
    "Saint-Branchs",
    "Saint-Brandan",
    "Saint-Bres",
    "Saint-Brevin-les-Pins",
    "Saint-Briac-sur-Mer",
    "Saint-Brice",
    "Saint-Brice-de-Landelles",
    "Saint-Brice-sous-Foret",
    "Saint-Brieuc",
    "Saint-Bris-le-Vineux",
    "Saint-Brisson-sur-Loire",
    "Saint-Calais",
    "Saint-Cannat",
    "Saint-Caprais-de-Bordeaux",
    "Saint-Carne",
    "Saint-Carreuc",
    "Saint-Cassin",
    "Saint-Cast-le-Guildo",
    "Saint-Cere",
    "Saint-Cergues",
    "Saint-Cesaire",
    "Saint-Cezaire-sur-Siagne",
    "Saint-Cezert",
    "Saint-Chamassy",
    "Saint-Chamond",
    "Saint-Chaptes",
    "Saint-Chartres",
    "Saint-Chef",
    "Saint-Cheron-du-Chemin",
    "Saint-Chinian",
    "Saint-Christo-en-Jarez",
    "Saint-Christol",
    "Saint-Christol",
    "Saint-Christol-les-Ales",
    "Saint-Christophe-de-Valains",
    "Saint-Christophe-du-Bois",
    "Saint-Christophe-du-Ligneron",
    "Saint-Christophe-en-Boucherie",
    "Saint-Christophe-en-Brionnais",
    "Saint-Christophe-sur-Avre",
    "Saint-Christophe-sur-Guiers",
    "Saint-Christophe-sur-le-Nais",
    "Saint-Christophe-sur-Roc",
    "Saint-Ciers-sur-Gironde",
    "Saint-Cirgues",
    "Saint-Clair",
    "Saint-Clair-de-la-Tour",
    "Saint-Clair-du-Rhone",
    "Saint-Clair-sur-Galaure",
    "Saint-Clar-de-Riviere",
    "Saint-Claud",
    "Saint-Claude",
    "Saint-Claude-de-Diray",
    "Saint-Clement",
    "Saint-Clement",
    "Saint-Clement",
    "Saint-Clement-de-la-Place",
    "Saint-Clement-des-Levees",
    "Saint-Clet",
    "Saint-Cloud",
    "Saint-Colomban",
    "Saint-Congard",
    "Saint-Contest",
    "Saint-Corneille",
    "Saint-Cornier-des-Landes",
    "Saint-Coulitz",
    "Saint-Coulomb",
    "Saint-Creac",
    "Saint-Crepin-Ibouvillers",
    "Saint-Crespin-sur-Moine",
    "Saint-Cyprien",
    "Saint-Cyprien",
    "Saint-Cyprien-Plage",
    "Saint-Cyr-du-Gault",
    "Saint-Cyr-en-Pail",
    "Saint-Cyr-en-Val",
    "Saint-Cyr-la-Campagne",
    "Saint-Cyr-le-Chatoux",
    "Saint-Cyr-les-Champagnes",
    "Saint-Cyr-sur-le-Rhone",
    "Saint-Cyr-sur-Loire",
    "Saint-Cyr-sur-Menthon",
    "Saint-Cyr-sur-Mer",
    "Saint-Cyr-sur-Morin",
    "Saint-Denis",
    "Saint-Denis-dAnjou",
    "Saint-Denis-de-Cabanne",
    "Saint-Denis-de-Palin",
    "Saint-Denis-de-Pile",
    "Saint-Denis-de-Vaux",
    "Saint-Denis-des-Murs",
    "Saint-Denis-en-Bugey",
    "Saint-Denis-en-Val",
    "Saint-Denis-le-Gast",
    "Saint-Denis-le-Thiboult",
    "Saint-Denis-les-Rebais",
    "Saint-Denis-sur-Coise",
    "Saint-Denis-sur-Sarthon",
    "Saint-Derrien",
    "Saint-Desir",
    "Saint-Desirat",
    "Saint-Desire",
    "Saint-Dezery",
    "Saint-Didier-de-Formans",
    "Saint-Didier-de-la-Tour",
    "Saint-Didier-en-Donjon",
    "Saint-Didier-en-Velay",
    "Saint-Didier-sous-Riverie",
    "Saint-Didier-sur-Chalaronne",
    "Saint-Didier-sur-Rochefort",
    "Saint-Die-des-Vosges",
    "Saint-Dionisy",
    "Saint-Disdier",
    "Saint-Divy",
    "Saint-Dizier",
    "Saint-Dizier-en-Diois",
    "Saint-Dolay",
    "Saint-Domet",
    "Saint-Domineuc",
    "Saint-Donan",
    "Saint-Doulchard",
    "Saint-Drezery",
    "Saint-Ebremond-de-Bonfosse",
    "Saint-Elier",
    "Saint-Eliph",
    "Saint-Elix-Seglan",
    "Saint-Eloi",
    "Saint-Eloi",
    "Saint-Eloy-de-Gy",
    "Saint-Eloy-la-Glaciere",
    "Saint-Eloy-les-Mines",
    "Saint-Emilion",
    "Saint-Erblon",
    "Saint-Erme-Outre-et-Ramecourt",
    "Saint-Esteve",
    "Saint-Esteve-Janson",
    "Saint-Etienne",
    "Saint-Etienne-au-Mont",
    "Saint-Etienne-au-Temple",
    "Saint-Etienne-de-Baigorry",
    "Saint-Etienne-de-Brillouet",
    "Saint-Etienne-de-Crossey",
    "Saint-Etienne-de-Cuines",
    "Saint-Etienne-de-Fontbellon",
    "Saint-Etienne-de-Montluc",
    "Saint-Etienne-de-Saint-Geoirs",
    "Saint-Etienne-de-Tinee",
    "Saint-Etienne-de-Tulmont",
    "Saint-Etienne-de-Valoux",
    "Saint-Etienne-des-Oullieres",
    "Saint-Etienne-du-Bois",
    "Saint-Etienne-du-Gres",
    "Saint-Etienne-du-Valdonnez",
    "Saint-Etienne-du-Vauvray",
    "Saint-Etienne-en-Cogles",
    "Saint-Etienne-la-Varenne",
    "Saint-Etienne-le-Molard",
    "Saint-Etienne-les-Orgues",
    "Saint-Etienne-sous-Bailleul",
    "Saint-Etienne-sur-Chalaronne",
    "Saint-Etienne-sur-Suippe",
    "Saint-Eulien",
    "Saint-Eustache-la-Foret",
    "Saint-Evarzec",
    "Saint-Faust",
    "Saint-Felicien",
    "Saint-Felix-de-Rieutord",
    "Saint-Felix-de-Villadeix",
    "Saint-Fiacre",
    "Saint-Fiacre-sur-Maine",
    "Saint-Firmin",
    "Saint-Firmin-des-Pres",
    "Saint-Florent",
    "Saint-Florent-le-Vieil",
    "Saint-Florent-sur-Auzonnet",
    "Saint-Florent-sur-Cher",
    "Saint-Florentin",
    "Saint-Floret",
    "Saint-Floris",
    "Saint-Flour",
    "Saint-Flovier",
    "Saint-Folquin",
    "Saint-Fons",
    "Saint-Forgeux",
    "Saint-Fort",
    "Saint-Fregant",
    "Saint-Fromond",
    "Saint-Front",
    "Saint-Front",
    "Saint-Fulgent",
    "Saint-Fuscien",
    "Saint-Galmier",
    "Saint-Gatien-des-Bois",
    "Saint-Gaudens",
    "Saint-Gaultier",
    "Saint-Gein",
    "Saint-Gelais",
    "Saint-Gely-du-Fesc",
    "Saint-Gence",
    "Saint-Generoux",
    "Saint-Genes-du-Retz",
    "Saint-Genest-Lerpt",
    "Saint-Genest-Malifaux",
    "Saint-Gengoux-de-Scisse",
    "Saint-Genies-Bellevue",
    "Saint-Genies-de-Comolas",
    "Saint-Genies-de-Malgoires",
    "Saint-Genies-des-Mourgues",
    "Saint-Genis-Laval",
    "Saint-Genis-les-Ollieres",
    "Saint-Genis-Pouilly",
    "Saint-Genix-sur-Guiers",
    "Saint-Genou",
    "Saint-Geoire-en-Valdaine",
    "Saint-Geoirs",
    "Saint-Georges-de-Baroille",
    "Saint-Georges-de-Didonne",
    "Saint-Georges-de-Luzencon",
    "Saint-Georges-de-Montaigu",
    "Saint-Georges-de-Pointindoux",
    "Saint-Georges-de-Poisieux",
    "Saint-Georges-de-Reneins",
    "Saint-Georges-des-Coteaux",
    "Saint-Georges-du-Bois",
    "Saint-Georges-du-Rosay",
    "Saint-Georges-du-Vievre",
    "Saint-Georges-Haute-Ville",
    "Saint-Georges-le-Flechard",
    "Saint-Georges-les-Baillargeaux",
    "Saint-Georges-Montcocq",
    "Saint-Georges-sur-Allier",
    "Saint-Georges-sur-Arnon",
    "Saint-Georges-sur-Baulche",
    "Saint-Georges-sur-Cher",
    "Saint-Georges-sur-Eure",
    "Saint-Georges-sur-Fontaine",
    "Saint-Georges-sur-Loire",
    "Saint-Georges-sur-Renon",
    "Saint-Gerand",
    "Saint-Gerand-de-Vaux",
    "Saint-Gereon",
    "Saint-Germain",
    "Saint-Germain-de-Joux",
    "Saint-Germain-de-la-Grange",
    "Saint-Germain-de-Princay",
    "Saint-Germain-de-Tournebut",
    "Saint-Germain-des-Fosses",
    "Saint-Germain-des-Pres",
    "Saint-Germain-du-Corbeis",
    "Saint-Germain-du-Plain",
    "Saint-Germain-du-Puch",
    "Saint-Germain-du-Puy",
    "Saint-Germain-en-Laye",
    "Saint-Germain-la-Blanche-Herbe",
    "Saint-Germain-la-Ville",
    "Saint-Germain-Langot",
    "Saint-Germain-Laprade",
    "Saint-Germain-Laxis",
    "Saint-Germain-Lembron",
    "Saint-Germain-les-Arpajon",
    "Saint-Germain-les-Belles",
    "Saint-Germain-les-Corbeil",
    "Saint-Germain-les-Paroisses",
    "Saint-Germain-les-Vergnes",
    "Saint-Germain-Nuelles",
    "Saint-Germain-sous-Doue",
    "Saint-Germain-sur-Avre",
    "Saint-Germain-sur-Moine",
    "Saint-Germain-sur-Morin",
    "Saint-Germain-sur-Rhone",
    "Saint-Germain-Village",
    "Saint-Germainmont",
    "Saint-Germer-de-Fly",
    "Saint-Gervais-en-Belin",
    "Saint-Gervais-la-Foret",
    "Saint-Gervais-les-Bains",
    "Saint-Gervais-sous-Meymont",
    "Saint-Gervais-sur-Roubion",
    "Saint-Gervazy",
    "Saint-Geyrac",
    "Saint-Gildas-des-Bois",
    "Saint-Gilles",
    "Saint-Gilles-des-Marais",
    "Saint-Gilles-les-Bois",
    "Saint-Gingolph",
    "Saint-Girons",
    "Saint-Goazec",
    "Saint-Gobain",
    "Saint-Gondon",
    "Saint-Gondran",
    "Saint-Gonnery",
    "Saint-Gratien",
    "Saint-Gratien",
    "Saint-Grave",
    "Saint-Gregoire",
    "Saint-Gregoire",
    "Saint-Guilhem-le-Desert",
    "Saint-Guinoux",
    "Saint-Heand",
    "Saint-Helen",
    "Saint-Herblain",
    "Saint-Herve",
    "Saint-Hilaire-Cusson-la-Valmitte",
    "Saint-Hilaire-de-Brethmas",
    "Saint-Hilaire-de-Chaleons",
    "Saint-Hilaire-de-Court",
    "Saint-Hilaire-de-la-Cote",
    "Saint-Hilaire-de-Loulay",
    "Saint-Hilaire-de-Riez",
    "Saint-Hilaire-de-Talmont",
    "Saint-Hilaire-de-Voust",
    "Saint-Hilaire-du-Harcouet",
    "Saint-Hilaire-le-Vouhis",
    "Saint-Hilaire-les-Andresis",
    "Saint-Hilaire-les-Courbes",
    "Saint-Hilaire-lez-Cambrai",
    "Saint-Hilaire-Peyroux",
    "Saint-Hilaire-Saint-Mesmin",
    "Saint-Hilarion",
    "Saint-Hilliers",
    "Saint-Hippolyte",
    "Saint-Hippolyte",
    "Saint-Hippolyte",
    "Saint-Hippolyte",
    "Saint-Hippolyte",
    "Saint-Hippolyte-de-Montaigu",
    "Saint-Hippolyte-du-Fort",
    "Saint-Honore-les-Bains",
    "Saint-Hostien",
    "Saint-Igneuc",
    "Saint-Illide",
    "Saint-Imoges",
    "Saint-Ismier",
    "Saint-Jacques-de-la-Lande",
    "Saint-Jean-aux-Amognes",
    "Saint-Jean-Bonnefonds",
    "Saint-Jean-Brevelay",
    "Saint-Jean-de-Boiseau",
    "Saint-Jean-de-Bournay",
    "Saint-Jean-de-Braye",
    "Saint-Jean-de-Chevelu",
    "Saint-Jean-de-Cornies",
    "Saint-Jean-de-Gonville",
    "Saint-Jean-de-la-Ruelle",
    "Saint-Jean-de-Laur",
    "Saint-Jean-de-Lier",
    "Saint-Jean-de-Liversay",
    "Saint-Jean-de-Losne",
    "Saint-Jean-de-Luz",
    "Saint-Jean-de-Marcel",
    "Saint-Jean-de-Marsacq",
    "Saint-Jean-de-Maurienne",
    "Saint-Jean-de-Moirans",
    "Saint-Jean-de-Monts",
    "Saint-Jean-de-Muzols",
    "Saint-Jean-de-Sauves",
    "Saint-Jean-de-Serres",
    "Saint-Jean-de-Sixt",
    "Saint-Jean-de-Soudain",
    "Saint-Jean-de-Vedas",
    "Saint-Jean-des-Baisants",
    "Saint-Jean-des-Champs",
    "Saint-Jean-des-Mauvrets",
    "Saint-Jean-des-Ollieres",
    "Saint-Jean-des-Vignes",
    "Saint-Jean-du-Bruel",
    "Saint-Jean-du-Cardonnay",
    "Saint-Jean-du-Falga",
    "Saint-Jean-en-Royans",
    "Saint-Jean-Kerdaniel",
    "Saint-Jean-Kourtzerode",
    "Saint-Jean-la-Poterie",
    "Saint-Jean-Lasseille",
    "Saint-Jean-le-Blanc",
    "Saint-Jean-le-Blanc",
    "Saint-Jean-le-Centenier",
    "Saint-Jean-le-Thomas",
    "Saint-Jean-les-Deux-Jumeaux",
    "Saint-Jean-Ligoure",
    "Saint-Jean-Pied-de-Port",
    "Saint-Jean-Rohrbach",
    "Saint-Jean-Saint-Germain",
    "Saint-Jean-sur-Erve",
    "Saint-Jean-sur-Vilaine",
    "Saint-Jean-Trolimon",
    "Saint-Jeannet",
    "Saint-Jeoire",
    "Saint-Jeoire-Prieure",
    "Saint-Jeures",
    "Saint-Joachim",
    "Saint-Jorioz",
    "Saint-Jory",
    "Saint-Joseph-de-Riviere",
    "Saint-Jouan-des-Guerets",
    "Saint-Jouvent",
    "Saint-Judoce",
    "Saint-Julien",
    "Saint-Julien-de-Cassagnas",
    "Saint-Julien-de-Chedon",
    "Saint-Julien-de-Concelles",
    "Saint-Julien-de-Coppel",
    "Saint-Julien-de-Lampon",
    "Saint-Julien-de-Vouvantes",
    "Saint-Julien-des-Landes",
    "Saint-Julien-du-Puy",
    "Saint-Julien-du-Sault",
    "Saint-Julien-en-Born",
    "Saint-Julien-en-Champsaur",
    "Saint-Julien-en-Genevois",
    "Saint-Julien-le-Roux",
    "Saint-Julien-le-Vendomois",
    "Saint-Julien-les-Metz",
    "Saint-Julien-les-Villas",
    "Saint-Julien-sur-Bibost",
    "Saint-Julien-sur-Sarthe",
    "Saint-Junien",
    "Saint-Just-Chaleyssin",
    "Saint-Just-de-Claix",
    "Saint-Just-en-Bas",
    "Saint-Just-en-Chaussee",
    "Saint-Just-en-Chevalet",
    "Saint-Just-le-Martel",
    "Saint-Just-Malmont",
    "Saint-Just-pres-Brioude",
    "Saint-Lactencin",
    "Saint-Lager",
    "Saint-Lager-Bressac",
    "Saint-Lambert-du-Lattay",
    "Saint-Lambert-la-Potherie",
    "Saint-Lambert-sur-Dive",
    "Saint-Lanne",
    "Saint-Lary-Boujean",
    "Saint-Lary-Soulan",
    "Saint-Launeuc",
    "Saint-Laurent-Blangy",
    "Saint-Laurent-Bretagne",
    "Saint-Laurent-de-la-Plaine",
    "Saint-Laurent-de-la-Pree",
    "Saint-Laurent-de-la-Salanque",
    "Saint-Laurent-de-Levezou",
    "Saint-Laurent-de-Mure",
    "Saint-Laurent-de-Neste",
    "Saint-Laurent-des-Autels",
    "Saint-Laurent-des-Combes",
    "Saint-Laurent-du-Bois",
    "Saint-Laurent-du-Mottay",
    "Saint-Laurent-du-Pont",
    "Saint-Laurent-du-Var",
    "Saint-Laurent-en-Beaumont",
    "Saint-Laurent-en-Gatines",
    "Saint-Laurent-la-Roche",
    "Saint-Laurent-Rochefort",
    "Saint-Laurent-sur-Gorre",
    "Saint-Laurent-sur-Sevre",
    "Saint-Laurs",
    "Saint-Leger-aux-Bois",
    "Saint-Leger-de-Rotes",
    "Saint-Leger-des-Vignes",
    "Saint-Leger-du-Bourg-Denis",
    "Saint-Leger-en-Bray",
    "Saint-Leger-les-Domart",
    "Saint-Leger-sous-Brienne",
    "Saint-Leger-sous-Cholet",
    "Saint-Leger-sur-Dheune",
    "Saint-Leonard",
    "Saint-Leonard-de-Noblat",
    "Saint-Leu-la-Foret",
    "Saint-Lezin",
    "Saint-Lieux-les-Lavaur",
    "Saint-Lormel",
    "Saint-Loubes",
    "Saint-Louis",
    "Saint-Louis-de-Montferrand",
    "Saint-Loup",
    "Saint-Loup-Cammas",
    "Saint-Loup-Hors",
    "Saint-Lubin-des-Joncherets",
    "Saint-Lumier-en-Champagne",
    "Saint-Lumine-de-Clisson",
    "Saint-Luperce",
    "Saint-Lupicin",
    "Saint-Lye",
    "Saint-Lye-la-Foret",
    "Saint-Lyphard",
    "Saint-Lys",
    "Saint-Macaire",
    "Saint-Macaire-du-Bois",
    "Saint-Macaire-en-Mauges",
    "Saint-Magne-de-Castillon",
    "Saint-Maigner",
    "Saint-Maigrin",
    "Saint-Maime",
    "Saint-Maixant",
    "Saint-Malo-de-Guersac",
    "Saint-Malo-de-la-Lande",
    "Saint-Malo-de-Phily",
    "Saint-Malo-du-Bois",
    "Saint-Malo-en-Donziois",
    "Saint-Mamert-du-Gard",
    "Saint-Mamet-la-Salvetat",
    "Saint-Mammes",
    "Saint-Mande",
    "Saint-Mandrier-sur-Mer",
    "Saint-Marc-Jaumegarde",
    "Saint-Marceau",
    "Saint-Marcel-Bel-Accueil",
    "Saint-Marcel-les-Valence",
    "Saint-Marcel-sur-Aude",
    "Saint-Marcellin",
    "Saint-Marcellin-en-Forez",
    "Saint-Mard",
    "Saint-Mard-de-Vaux",
    "Saint-Mariens",
    "Saint-Mars-de-Coutais",
    "Saint-Mars-de-Locquenay",
    "Saint-Mars-du-Desert",
    "Saint-Mars-la-Jaille",
    "Saint-Martial",
    "Saint-Martial-de-Vitaterne",
    "Saint-Martin-au-Laert",
    "Saint-Martin-Belle-Roche",
    "Saint-Martin-Bellevue",
    "Saint-Martin-Boulogne",
    "Saint-Martin-Choquel",
    "Saint-Martin-de-Belleville",
    "Saint-Martin-de-Bernegoue",
    "Saint-Martin-de-Blagny",
    "Saint-Martin-de-Crau",
    "Saint-Martin-de-Fontenay",
    "Saint-Martin-de-la-Brasque",
    "Saint-Martin-de-Lenne",
    "Saint-Martin-de-Londres",
    "Saint-Martin-de-Nigelles",
    "Saint-Martin-de-Re",
    "Saint-Martin-de-Riberac",
    "Saint-Martin-de-Seignanx",
    "Saint-Martin-de-Valamas",
    "Saint-Martin-de-Valgalgues",
    "Saint-Martin-des-Bois",
    "Saint-Martin-des-Champs",
    "Saint-Martin-des-Champs",
    "Saint-Martin-des-Noyers",
    "Saint-Martin-des-Olmes",
    "Saint-Martin-du-Bois",
    "Saint-Martin-du-Bois",
    "Saint-Martin-du-Bosc",
    "Saint-Martin-du-Manoir",
    "Saint-Martin-du-Mont",
    "Saint-Martin-du-Puy",
    "Saint-Martin-du-Tertre",
    "Saint-Martin-du-Tilleul",
    "Saint-Martin-du-Var",
    "Saint-Martin-en-Biere",
    "Saint-Martin-en-Bresse",
    "Saint-Martin-en-Campagne",
    "Saint-Martin-en-Gatinois",
    "Saint-Martin-en-Haut",
    "Saint-Martin-la-Campagne",
    "Saint-Martin-la-Plaine",
    "Saint-Martin-Lacaussade",
    "Saint-Martin-Lalande",
    "Saint-Martin-le-Beau",
    "Saint-Martin-le-Noeud",
    "Saint-Martin-le-Pin",
    "Saint-Martin-le-Vieil",
    "Saint-Martin-le-Vinoux",
    "Saint-Martin-les-Melle",
    "Saint-Martin-Longueau",
    "Saint-Martin-Osmonville",
    "Saint-Martin-Sainte-Catherine",
    "Saint-Martin-sur-Arve",
    "Saint-Martin-sur-le-Pre",
    "Saint-Martin-sur-Nohain",
    "Saint-Martin-sur-Ocre",
    "Saint-Martin-Terressus",
    "Saint-Martin-Valmeroux",
    "Saint-Mary",
    "Saint-Mathieu-de-Treviers",
    "Saint-Mathurin",
    "Saint-Maudan",
    "Saint-Maulvis",
    "Saint-Maurice",
    "Saint-Maurice-de-Cazevieille",
    "Saint-Maurice-de-Gourdans",
    "Saint-Maurice-de-Lignon",
    "Saint-Maurice-de-Remens",
    "Saint-Maurice-la-Clouere",
    "Saint-Maurice-la-Souterraine",
    "Saint-Maurice-les-Brousses",
    "Saint-Maurice-Montcouronne",
    "Saint-Maurice-sur-Aveyron",
    "Saint-Maurice-sur-Dargoire",
    "Saint-Maurice-sur-Eygues",
    "Saint-Maurice-sur-Fessard",
    "Saint-Maurice-sur-Moselle",
    "Saint-Max",
    "Saint-Maxent",
    "Saint-Maximin",
    "Saint-Maximin",
    "Saint-Maximin",
    "Saint-Maximin-la-Sainte-Baume",
    "Saint-Maxire",
    "Saint-Meard-de-Gurcon",
    "Saint-Medard-de-Guizieres",
    "Saint-Medard-en-Forez",
    "Saint-Meen-le-Grand",
    "Saint-Melaine-sur-Aubance",
    "Saint-Meloir-des-Ondes",
    "Saint-Meme-les-Carrieres",
    "Saint-Memmie",
    "Saint-Michel",
    "Saint-Michel",
    "Saint-Michel-Chef-Chef",
    "Saint-Michel-de-Chabrillanoux",
    "Saint-Michel-de-Maurienne",
    "Saint-Michel-de-Rieufret",
    "Saint-Michel-de-Volangis",
    "Saint-Michel-des-Andaines",
    "Saint-Michel-le-Cloucq",
    "Saint-Michel-lObservatoire",
    "Saint-Michel-Mont-Mercure",
    "Saint-Michel-sous-Bois",
    "Saint-Michel-sur-Meurthe",
    "Saint-Michel-sur-Orge",
    "Saint-Michel-sur-Savasse",
    "Saint-Michel-sur-Ternoise",
    "Saint-Michel-Treve",
    "Saint-Mihiel",
    "Saint-Mitre-les-Remparts",
    "Saint-Morillon",
    "Saint-Nabord",
    "Saint-Nauphary",
    "Saint-Nazaire",
    "Saint-Nazaire",
    "Saint-Nazaire-de-Valentane",
    "Saint-Nectaire",
    "Saint-Nicolas-de-Bliquetuit",
    "Saint-Nicolas-de-la-Grave",
    "Saint-Nicolas-de-Port",
    "Saint-Nicolas-de-Redon",
    "Saint-Nicolas-du-Pelem",
    "Saint-Nolff",
    "Saint-Nom-la-Breteche",
    "Saint-Offenge-Dessous",
    "Saint-Offenge-Dessus",
    "Saint-Omer",
    "Saint-Omer-en-Chaussee",
    "Saint-Orens-de-Gameville",
    "Saint-Orens-Pouy-Petit",
    "Saint-Ouen",
    "Saint-Ouen",
    "Saint-Ouen-de-Thouberville",
    "Saint-Ouen-des-Besaces",
    "Saint-Ouen-des-Toits",
    "Saint-Ouen-du-Breuil",
    "Saint-Ouen-du-Mesnil-Oger",
    "Saint-Ouen-du-Tilleul",
    "Saint-Ouen-en-Brie",
    "Saint-Ouen-les-Parey",
    "Saint-Ouen-sur-Iton",
    "Saint-Ouen-sur-Morin",
    "Saint-Oulph",
    "Saint-Ours",
    "Saint-Pabu",
    "Saint-Pair-du-Mont",
    "Saint-Pair-sur-Mer",
    "Saint-Pal-de-Mons",
    "Saint-Palais",
    "Saint-Pancre",
    "Saint-Pandelon",
    "Saint-Pantaleon-de-Larche",
    "Saint-Papoul",
    "Saint-Pardoux-du-Breuil",
    "Saint-Pargoire",
    "Saint-Parize-le-Chatel",
    "Saint-Parres-aux-Tertres",
    "Saint-Parres-les-Vaudes",
    "Saint-Paterne",
    "Saint-Paterne-Racan",
    "Saint-Pathus",
    "Saint-Patrice-de-Claids",
    "Saint-Paul",
    "Saint-Paul",
    "Saint-Paul-de-Jarrat",
    "Saint-Paul-de-Varces",
    "Saint-Paul-de-Vence",
    "Saint-Paul-de-Vezelin",
    "Saint-Paul-du-Vernay",
    "Saint-Paul-en-Chablais",
    "Saint-Paul-en-Cornillon",
    "Saint-Paul-en-Foret",
    "Saint-Paul-en-Jarez",
    "Saint-Paul-en-Pareds",
    "Saint-Paul-les-Dax",
    "Saint-Paul-les-Durance",
    "Saint-Paul-les-Romans",
    "Saint-Paul-Trois-Chateaux",
    "Saint-Paulet-de-Caisson",
    "Saint-Pee-sur-Nivelle",
    "Saint-Pellerin",
    "Saint-Peray",
    "Saint-Perdon",
    "Saint-Pere",
    "Saint-Pere-en-Retz",
    "Saint-Pern",
    "Saint-Philbert-de-Bouaine",
    "Saint-Philbert-de-Grand-Lieu",
    "Saint-Philbert-du-Pont-Charrault",
    "Saint-Philibert",
    "Saint-Piat",
    "Saint-Pierre-Bois",
    "Saint-Pierre-Brouck",
    "Saint-Pierre-dAllevard",
    "Saint-Pierre-de-Boeuf",
    "Saint-Pierre-de-Bressieux",
    "Saint-Pierre-de-Chandieu",
    "Saint-Pierre-de-Jards",
    "Saint-Pierre-de-Lages",
    "Saint-Pierre-de-Maille",
    "Saint-Pierre-de-Mons",
    "Saint-Pierre-de-Plesguen",
    "Saint-Pierre-de-Trivisy",
    "Saint-Pierre-de-Varengeville",
    "Saint-Pierre-de-Varennes",
    "Saint-Pierre-des-Corps",
    "Saint-Pierre-des-Echaubrognes",
    "Saint-Pierre-des-Fleurs",
    "Saint-Pierre-du-Chemin",
    "Saint-Pierre-du-Mont",
    "Saint-Pierre-du-Palais",
    "Saint-Pierre-du-Perray",
    "Saint-Pierre-du-Regard",
    "Saint-Pierre-du-Vauvray",
    "Saint-Pierre-en-Port",
    "Saint-Pierre-en-Val",
    "Saint-Pierre-la-Bourlhonne",
    "Saint-Pierre-la-Cour",
    "Saint-Pierre-la-Garenne",
    "Saint-Pierre-la-Palud",
    "Saint-Pierre-Lavis",
    "Saint-Pierre-le-Chastel",
    "Saint-Pierre-le-Moutier",
    "Saint-Pierre-le-Vieux",
    "Saint-Pierre-le-Vieux",
    "Saint-Pierre-les-Elbeuf",
    "Saint-Pierre-Montlimart",
    "Saint-Pierre-sur-Dives",
    "Saint-Pierre-Tarentaine",
    "Saint-Pierreville",
    "Saint-Pol-sur-Mer",
    "Saint-Pol-sur-Ternoise",
    "Saint-Pompont",
    "Saint-Pont",
    "Saint-Porchaire",
    "Saint-Porquier",
    "Saint-Pouange",
    "Saint-Pourcain-sur-Sioule",
    "Saint-Prest",
    "Saint-Priest-des-Champs",
    "Saint-Priest-en-Jarez",
    "Saint-Priest-la-Roche",
    "Saint-Priest-la-Vetre",
    "Saint-Priest-Taurion",
    "Saint-Prim",
    "Saint-Privat",
    "Saint-Privat",
    "Saint-Privat-de-Vallongue",
    "Saint-Privat-la-Montagne",
    "Saint-Prix",
    "Saint-Projet",
    "Saint-Prouant",
    "Saint-Pryve-Saint-Mesmin",
    "Saint-Python",
    "Saint-Quay-Perros",
    "Saint-Quay-Portrieux",
    "Saint-Quentin",
    "Saint-Quentin-de-Baron",
    "Saint-Quentin-du-Dropt",
    "Saint-Quentin-en-Mauges",
    "Saint-Quentin-Fallavier",
    "Saint-Quentin-la-Poterie",
    "Saint-Quentin-le-Verger",
    "Saint-Quentin-les-Chardonnets",
    "Saint-Quentin-sur-Indrois",
    "Saint-Quentin-sur-le-Homme",
    "Saint-Rambert-en-Bugey",
    "Saint-Regle",
    "Saint-Remeze",
    "Saint-Remimont",
    "Saint-Remy",
    "Saint-Remy",
    "Saint-Remy",
    "Saint-Remy-du-Nord",
    "Saint-Remy-en-Mauges",
    "Saint-Remy-en-Rollat",
    "Saint-Remy-la-Vanne",
    "Saint-Remy-la-Varenne",
    "Saint-Remy-les-Chevreuse",
    "Saint-Remy-sur-Avre",
    "Saint-Remy-sur-Durolle",
    "Saint-Renan",
    "Saint-Reverien",
    "Saint-Riquier",
    "Saint-Rirand",
    "Saint-Robert",
    "Saint-Roch",
    "Saint-Rogatien",
    "Saint-Romain-de-Colbosc",
    "Saint-Romain-de-Lerps",
    "Saint-Romain-en-Gal",
    "Saint-Romain-en-Gier",
    "Saint-Romain-la-Motte",
    "Saint-Romain-la-Virvee",
    "Saint-Romain-Lachalm",
    "Saint-Romain-le-Preux",
    "Saint-Romain-le-Puy",
    "Saint-Romain-les-Atheux",
    "Saint-Rome-de-Tarn",
    "Saint-Saens",
    "Saint-Saire",
    "Saint-Samson-sur-Rance",
    "Saint-Sandoux",
    "Saint-Sardos",
    "Saint-Saturnin-du-Limet",
    "Saint-Saturnin-les-Apt",
    "Saint-Saturnin-les-Avignon",
    "Saint-Saturnin-sur-Loire",
    "Saint-Saulge",
    "Saint-Saulve",
    "Saint-Sauvant",
    "Saint-Sauveur-dAunis",
    "Saint-Sauveur-de-Montagut",
    "Saint-Sauveur-Gouvernet",
    "Saint-Sauveur-la-Sagne",
    "Saint-Sauveur-le-Vicomte",
    "Saint-Sauveur-Lendelin",
    "Saint-Savin",
    "Saint-Savin",
    "Saint-Savin",
    "Saint-Savinien",
    "Saint-Saviol",
    "Saint-Savournin",
    "Saint-Sebastien",
    "Saint-Sebastien",
    "Saint-Sebastien-de-Morsent",
    "Saint-Secondin",
    "Saint-Segal",
    "Saint-Seglin",
    "Saint-Seine-sur-Vingeanne",
    "Saint-Selve",
    "Saint-Senoch",
    "Saint-Senoux",
    "Saint-Series",
    "Saint-Sernin",
    "Saint-Sernin-du-Bois",
    "Saint-Servant",
    "Saint-Seurin-de-Cadourne",
    "Saint-Seurin-de-Cursac",
    "Saint-Seurin-de-Prats",
    "Saint-Sever",
    "Saint-Sever-Calvados",
    "Saint-Siffret",
    "Saint-Sigismond",
    "Saint-Simeon-de-Bressieux",
    "Saint-Simon",
    "Saint-Simon-de-Pellouaille",
    "Saint-Sixt",
    "Saint-Sixte",
    "Saint-Sorlin-en-Valloire",
    "Saint-Souplet",
    "Saint-Soupplets",
    "Saint-Suliac",
    "Saint-Sulpice-de-Faleyrens",
    "Saint-Sulpice-de-Royan",
    "Saint-Sulpice-et-Cameyrac",
    "Saint-Sulpice-la-Foret",
    "Saint-Sulpice-le-Vieux",
    "Saint-Sulpice-les-Feuilles",
    "Saint-Sulpice-sur-Leze",
    "Saint-Sulpice-sur-Risle",
    "Saint-Sylvain",
    "Saint-Sylvestre-sur-Lot",
    "Saint-Symphorien-de-Lay",
    "Saint-Symphorien-dOzon",
    "Saint-Symphorien-sur-Coise",
    "Saint-Symphorien-sur-Saone",
    "Saint-Theodorit",
    "Saint-Thibaud-de-Couz",
    "Saint-Thibault",
    "Saint-Thibault-des-Vignes",
    "Saint-Thibery",
    "Saint-Thierry",
    "Saint-Thurial",
    "Saint-Trivier-de-Courtes",
    "Saint-Trivier-sur-Moignans",
    "Saint-Trojan-les-Bains",
    "Saint-Tropez",
    "Saint-Tugdual",
    "Saint-Urbain",
    "Saint-Urbain",
    "Saint-Urbain-Maconcourt",
    "Saint-Usage",
    "Saint-Uze",
    "Saint-Vaast-du-Val",
    "Saint-Vaast-en-Auge",
    "Saint-Vaast-la-Hougue",
    "Saint-Vaast-les-Mello",
    "Saint-Valery-en-Caux",
    "Saint-Valery-sur-Somme",
    "Saint-Vallier",
    "Saint-Vallier",
    "Saint-Vallier-de-Thiey",
    "Saint-Varent",
    "Saint-Vaury",
    "Saint-Venant",
    "Saint-Verand",
    "Saint-Viance",
    "Saint-Viatre",
    "Saint-Viaud",
    "Saint-Victor-de-Cessieu",
    "Saint-Victor-la-Coste",
    "Saint-Victor-sur-Arlanc",
    "Saint-Victor-sur-Loire",
    "Saint-Victor-sur-Rhins",
    "Saint-Victoret",
    "Saint-Victurnien",
    "Saint-Vigor",
    "Saint-Vincent-de-Connezac",
    "Saint-Vincent-de-Durfort",
    "Saint-Vincent-de-Paul",
    "Saint-Vincent-de-Tyrosse",
    "Saint-Vincent-des-Landes",
    "Saint-Vincent-sur-Graon",
    "Saint-Vit",
    "Saint-Vital",
    "Saint-Vite",
    "Saint-Vivien",
    "Saint-Vivien-de-Medoc",
    "Saint-Vrain",
    "Saint-Vulbas",
    "Saint-Witz",
    "Saint-Xandre",
    "Saint-Ybars",
    "Saint-Yorre",
    "Saint-Yrieix-la-Perche",
    "Saint-Yrieix-sur-Charente",
    "Saint-Yvi",
    "Saint-Yzan-de-Soudiac",
    "Saint-Zacharie",
    "Sainte-Adresse",
    "Sainte-Anastasie-sur-Issole",
    "Sainte-Anne-Saint-Priest",
    "Sainte-Anne-sur-Vilaine",
    "Sainte-Austreberthe",
    "Sainte-Bazeille",
    "Sainte-Blandine",
    "Sainte-Catherine",
    "Sainte-Catherine",
    "Sainte-Cecile",
    "Sainte-Cecile",
    "Sainte-Colombe-de-Duras",
    "Sainte-Colombe-sur-Gand",
    "Sainte-Colombe-sur-Guette",
    "Sainte-Consorce",
    "Sainte-Croix",
    "Sainte-Croix-aux-Mines",
    "Sainte-Croix-en-Jarez",
    "Sainte-Croix-en-Plaine",
    "Sainte-Croix-Grand-Tonne",
    "Sainte-Croix-Hague",
    "Sainte-Croix-Volvestre",
    "Sainte-Eanne",
    "Sainte-Enimie",
    "Sainte-Eulalie-en-Born",
    "Sainte-Eusoye",
    "Sainte-Fauste",
    "Sainte-Fereole",
    "Sainte-Flaive-des-Loups",
    "Sainte-Florence",
    "Sainte-Florine",
    "Sainte-Fortunade",
    "Sainte-Foy-de-Longas",
    "Sainte-Foy-de-Peyrolieres",
    "Sainte-Foy-la-Grande",
    "Sainte-Foy-les-Lyon",
    "Sainte-Gemme-la-Plaine",
    "Sainte-Gemme-Martaillac",
    "Sainte-Genevieve",
    "Sainte-Genevieve",
    "Sainte-Helene",
    "Sainte-Hermine",
    "Sainte-Honorine-du-Fay",
    "Sainte-Livrade-sur-Lot",
    "Sainte-Luce-sur-Loire",
    "Sainte-Lucie de Porto-Vecchio",
    "Sainte-Lucie-de-Tallano",
    "Sainte-Marguerite",
    "Sainte-Marguerite-des-Loges",
    "Sainte-Marie",
    "Sainte-Marie-aux-Chenes",
    "Sainte-Marie-aux-Mines",
    "Sainte-Marie-Cappel",
    "Sainte-Marie-de-Chignac",
    "Sainte-Marie-de-Cuines",
    "Sainte-Marie-de-Re",
    "Sainte-Marie-du-Mont",
    "Sainte-Marie-Kerque",
    "Sainte-Marie-Laumont",
    "Sainte-Maure-de-Touraine",
    "Sainte-Maxime",
    "Sainte-Menehould",
    "Sainte-Mere-Eglise",
    "Sainte-Neomaye",
    "Sainte-Opportune-du-Bosc",
    "Sainte-Ouenne",
    "Sainte-Pazanne",
    "Sainte-Preuve",
    "Sainte-Radegonde",
    "Sainte-Reine-de-Bretagne",
    "Sainte-Sabine-sur-Longeve",
    "Sainte-Savine",
    "Sainte-Seve",
    "Sainte-Sigolene",
    "Sainte-Solange",
    "Sainte-Soulle",
    "Sainte-Suzanne",
    "Sainte-Terre",
    "Sainte-Tulle",
    "Sainte-Valiere",
    "Sainte-Verge",
    "Saintes",
    "Saintes-Maries-de-la-Mer",
    "Saintry-sur-Seine",
    "Saints",
    "Saints-Geosmes",
    "Sainville",
    "Saissac",
    "Saisseval",
    "Saivres",
    "Saix",
    "Salagnon",
    "Salaise-sur-Sanne",
    "Salans",
    "Salbert",
    "Salbris",
    "Saleich",
    "Saleilles",
    "Salernes",
    "Salers",
    "Sales",
    "Saleux",
    "Salies-de-Bearn",
    "Salies-du-Salat",
    "Saligny",
    "Saligny-le-Vif",
    "Saligny-sur-Roudon",
    "Salin-de-Giraud",
    "Salindres",
    "Salins",
    "Salins-les-Bains",
    "Salins-les-Thermes",
    "Sallanches",
    "Sallaumines",
    "Salleboeuf",
    "Sallertaine",
    "Salles-de-Barbezieux",
    "Salles-la-Source",
    "Salles-Mongiscard",
    "Salles-sur-Mer",
    "Salome",
    "Salon-de-Provence",
    "Salouel",
    "Salses-le-Chateau",
    "Salvagnac",
    "Salviac",
    "Salvizinet",
    "Salzuit",
    "Samadet",
    "Samatan",
    "Samazan",
    "Sambin",
    "Sameon",
    "Samer",
    "Samognat",
    "Samois-sur-Seine",
    "Samoreau",
    "Sampigny-les-Maranges",
    "Samson",
    "San-Giuliano",
    "San-Nicolao",
    "Sanary-sur-Mer",
    "Sancerre",
    "Sanchey",
    "Sancourt",
    "Sand",
    "Sandillon",
    "Sandouville",
    "Sandrans",
    "Sangatte",
    "Sanguinet",
    "Sannerville",
    "Sannois",
    "Sansais",
    "Sant Andreu de Sureda",
    "Sant Genis de Fontanes",
    "Sant Joan de Pladecorts",
    "Santa-Maria-Poggio",
    "Santeny",
    "Santes",
    "Santeuil",
    "Santranges",
    "Sanvignes-les-Mines",
    "Sanxay",
    "Saone",
    "Sapogne-et-Feucheres",
    "Sapogne-sur-Marche",
    "Saramon",
    "Saran",
    "Sarcelles",
    "Sarcey",
    "Sarcicourt",
    "Sardan",
    "Sardieu",
    "Sardon",
    "Sare",
    "Sarge-sur-Braye",
    "Sarliac-sur-lIsle",
    "Sarralbe",
    "Sarraltroff",
    "Sarrant",
    "Sarras",
    "Sarrazac",
    "Sarre",
    "Sarre-Union",
    "Sarrebourg",
    "Sarreguemines",
    "Sarrey",
    "Sarrians",
    "Sarrola-Carcopino",
    "Sarry",
    "Sars-Poteries",
    "Sartrouville",
    "Sarzeau",
    "Sassay",
    "Sassenage",
    "Sassenay",
    "Sassey-sur-Meuse",
    "Sathonay-Camp",
    "Sathonay-Village",
    "Satillieu",
    "Satolas-et-Bonce",
    "Saubens",
    "Saubion",
    "Saubrigues",
    "Saubusse",
    "Saucats",
    "Saudoy",
    "Saugon",
    "Saugues",
    "Saujon",
    "Saulces-Monclin",
    "Saulcy-sur-Meurthe",
    "Saules",
    "Saulge",
    "Saulieu",
    "Saulnes",
    "Saulnieres",
    "Saulnot",
    "Saulny",
    "Saulon-la-Chapelle",
    "Saulon-la-Rue",
    "Sault",
    "Sault-Brenaz",
    "Sault-les-Rethel",
    "Sault-Saint-Remy",
    "Saultain",
    "Saulx-les-Chartreux",
    "Saulx-Marchais",
    "Saulxures",
    "Saulxures-les-Nancy",
    "Saulxures-sur-Moselotte",
    "Saulzoir",
    "Saumane-de-Vaucluse",
    "Saumeray",
    "Saumur",
    "Saurat",
    "Sauret-Besserve",
    "Sausheim",
    "Saussan",
    "Saussay-la-Campagne",
    "Saussemesnil",
    "Saussenac",
    "Sausset-les-Pins",
    "Saussey",
    "Saussines",
    "Sautel",
    "Sauteyrargues",
    "Sautron",
    "Sauvage",
    "Sauvagney",
    "Sauvagnon",
    "Sauvat",
    "Sauve",
    "Sauverny",
    "Sauvessanges",
    "Sauveterre",
    "Sauveterre-de-Bearn",
    "Sauveterre-de-Comminges",
    "Sauveterre-de-Guyenne",
    "Sauveterre-la-Lemance",
    "Sauvian",
    "Sauvigney-les-Pesmes",
    "Sauvigny-les-Bois",
    "Sauxillanges",
    "Sauze",
    "Sauze-Vaussais",
    "Sauzelles",
    "Sauzet",
    "Sauzet",
    "Sauzon",
    "Savasse",
    "Savenay",
    "Savennieres",
    "Saverdun",
    "Saverne",
    "Savieres",
    "Savignac-les-Eglises",
    "Savignac-sur-Leyze",
    "Savigne",
    "Savigne-sous-le-Lude",
    "Savigneux",
    "Savigny",
    "Savigny-en-Revermont",
    "Savigny-en-Septaine",
    "Savigny-le-Sec",
    "Savigny-le-Temple",
    "Savigny-les-Beaune",
    "Savigny-sur-Braye",
    "Savigny-sur-Clairis",
    "Savigny-sur-Orge",
    "Savoie",
    "Savonnieres",
    "Savouges",
    "Savoyeux",
    "Savy",
    "Savy-Berlette",
    "Sayat",
    "Scaer",
    "Sceaux",
    "Sceaux-du-Gatinais",
    "Sceaux-sur-Huisne",
    "Scharrachbergheim-Irmstett",
    "Scherwiller",
    "Schillersdorf",
    "Schiltigheim",
    "Schirmeck",
    "Schirrhein",
    "Schmittviller",
    "Schnersheim",
    "Schoeneck",
    "Schopperten",
    "Schorbach",
    "Schweighouse-Thann",
    "Schwenheim",
    "Scientrier",
    "Sciez",
    "Scionzier",
    "Scorbe-Clairvaux",
    "Scrignac",
    "Scy-Chazelles",
    "Sebazac-Concoures",
    "Sebecourt",
    "Sebourg",
    "Secheval",
    "Seclin",
    "Secondigny",
    "Sedan",
    "Sedzere",
    "Sees",
    "Seez",
    "Segalas",
    "Segny",
    "Segonzac",
    "Segonzat",
    "Segre",
    "Segrie",
    "Segry",
    "Seichamps",
    "Seiches-sur-le-Loir",
    "Seignalens",
    "Seignelay",
    "Seignosse",
    "Seilh",
    "Seilhac",
    "Seine-Port",
    "Seingbouse",
    "Seissan",
    "Selle",
    "Selles",
    "Selles-Saint-Denis",
    "Selles-sur-Cher",
    "Selles-sur-Nahon",
    "Sellieres",
    "Selommes",
    "Seloncourt",
    "Selongey",
    "Seltz",
    "Semalens",
    "Semblancay",
    "Semeac",
    "Semeacq-Blachon",
    "Semecourt",
    "Semoine",
    "Semoutiers-Montsaon",
    "Semoy",
    "Sempigny",
    "Semur-en-Brionnais",
    "Senan",
    "Senas",
    "Sendets",
    "Sene",
    "Senlecques",
    "Senlis",
    "Sennece-les-Macon",
    "Sennecey-le-Grand",
    "Sennely",
    "Senneville-sur-Fecamp",
    "Senonches",
    "Senones",
    "Senonges",
    "Senozan",
    "Sens",
    "Sens-de-Bretagne",
    "Sentheim",
    "Senuc",
    "Sepmeries",
    "Seppois-le-Bas",
    "Seppois-le-Haut",
    "Sept-Forges",
    "Sept-Saulx",
    "Septeme",
    "Septemes-les-Vallons",
    "Septeuil",
    "Septfonds",
    "Septvaux",
    "Sequedin",
    "Seraincourt",
    "Serans",
    "Seraucourt-le-Grand",
    "Serbannes",
    "Serdinya",
    "Sereilhac",
    "Serein",
    "Seremange-Erzange",
    "Serent",
    "Serezin-de-la-Tour",
    "Serezin-du-Rhone",
    "Sergeac",
    "Sergy",
    "Serifontaine",
    "Serignan",
    "Serignan-du-Comtat",
    "Serigne",
    "Sermaises",
    "Sermamagny",
    "Sermerieu",
    "Sermoise",
    "Sermoyer",
    "Sernhac",
    "Serocourt",
    "Seronville",
    "Serques",
    "Serquigny",
    "Serre-les-Sapins",
    "Serres",
    "Serres-Castet",
    "Serres-Gaston",
    "Serres-Morlaas",
    "Serres-Sainte-Marie",
    "Serres-sur-Arget",
    "Serrieres",
    "Serrieres-de-Briord",
    "Serrieres-en-Chautagne",
    "Serris",
    "Serrouville",
    "Sers",
    "Servais",
    "Servance",
    "Servant",
    "Servas",
    "Servaville-Salmonville",
    "Servian",
    "Servon",
    "Servon-sur-Vilaine",
    "Sery",
    "Sery-les-Mezieres",
    "Sessenheim",
    "Seugy",
    "Seuil",
    "Seuillet",
    "Seurre",
    "Sevelinges",
    "Severac",
    "Severac-le-Chateau",
    "Seveux",
    "Sevran",
    "Sevres-Anxaumont",
    "Sevrey",
    "Sevrier",
    "Sewen",
    "Sexey-aux-Forges",
    "Seyches",
    "Seyne-les-Alpes",
    "Seynod",
    "Seyre",
    "Seyssel",
    "Seysses",
    "Seyssinet",
    "Seyssinet-Pariset",
    "Seyssins",
    "Seyssuel",
    "Sezanne",
    "Sibiril",
    "Siccieu-Saint-Julien-et-Carisieu",
    "Siegen",
    "Sierck-les-Bains",
    "Sierentz",
    "Sierville",
    "Sigean",
    "Sigloy",
    "Signes",
    "Signy",
    "Signy-le-Petit",
    "Signy-Signets",
    "Sigogne",
    "Sigoules",
    "Sigournais",
    "Sigy-en-Bray",
    "Silfiac",
    "Sillans",
    "Sille-le-Guillaume",
    "Sillery",
    "Sillingy",
    "Silly-la-Poterie",
    "Silly-le-Long",
    "Silly-sur-Nied",
    "Silly-Tillard",
    "Simandre",
    "Simandres",
    "Simiane-Collongue",
    "Simorre",
    "Sin-le-Noble",
    "Sinard",
    "Sinceny",
    "Sion-les-Mines",
    "Siran",
    "Sireuil",
    "Siros",
    "Sisco",
    "Sissonne",
    "Sissy",
    "Sisteron",
    "Sivry-Courtry",
    "Sivry-sur-Meuse",
    "Six-Fours-les-Plages",
    "Sizun",
    "Smarves",
    "Sochaux",
    "Soignolles-en-Brie",
    "Soing-Cubry-Charentenay",
    "Soissons",
    "Soissons-sur-Nacey",
    "Soisy-sous-Montmorency",
    "Soisy-sur-Ecole",
    "Soisy-sur-Seine",
    "Soize",
    "Solaize",
    "Solaro",
    "Solers",
    "Solesmes",
    "Solesmes",
    "Soleymieux",
    "Solferino",
    "Solgne",
    "Soliers",
    "Solignac",
    "Solignac-sous-Roche",
    "Solignac-sur-Loire",
    "Soligny-la-Trappe",
    "Sollies-Pont",
    "Sollies-Toucas",
    "Sollies-Ville",
    "Sologny",
    "Somain",
    "Sombacour",
    "Sombernon",
    "Somloire",
    "Sommaing",
    "Somme",
    "Sommecaise",
    "Sommedieue",
    "Sommelonne",
    "Sommerance",
    "Sommervieu",
    "Sommerviller",
    "Sommieres",
    "Sommieres-du-Clain",
    "Sonchamp",
    "Sondernach",
    "Songeons",
    "Sonnay",
    "Sonnaz",
    "Sonzay",
    "Soorts-Hossegor",
    "Sorbey",
    "Sorbiers",
    "Sorcy-Saint-Martin",
    "Sore",
    "Sorede",
    "Sorel-en-Vimeu",
    "Sorel-Moussel",
    "Soreze",
    "Sorges",
    "Sorgues",
    "Sorigny",
    "Sormonne",
    "Sornay",
    "Sorquainville",
    "Sorrus",
    "Sort-en-Chalosse",
    "Sospel",
    "Sottevast",
    "Sotteville",
    "Sotteville-sur-Mer",
    "Souastre",
    "Soubise",
    "Soublecause",
    "Souchez",
    "Soucht",
    "Soucieu-en-Jarrest",
    "Soucy",
    "Soudan",
    "Soues",
    "Souesmes",
    "Souffelweyersheim",
    "Soufflenheim",
    "Sougy",
    "Sougy-sur-Loire",
    "Souilhanels",
    "Souillac",
    "Soulac-sur-Mer",
    "Soulaincourt",
    "Soulaines-Dhuys",
    "Soulaines-sur-Aubance",
    "Soulaire-et-Bourg",
    "Soulanges",
    "Soulangis",
    "Soulge-sur-Ouette",
    "Soulieres",
    "Souligne-sous-Ballon",
    "Soulitre",
    "Soullans",
    "Soultz-Haut-Rhin",
    "Soultz-sous-Forets",
    "Soultzeren",
    "Soultzmatt",
    "Soulvache",
    "Soumeras",
    "Soumoulou",
    "Souppes-sur-Loing",
    "Souprosse",
    "Souraide",
    "Sourcieux-les-Mines",
    "Sourdeval",
    "Sourdon",
    "Sourdun",
    "Sourribes",
    "Sours",
    "Souspierre",
    "Soustons",
    "Souvans",
    "Souvigne",
    "Souvigne-sur-Sarthe",
    "Souvigny",
    "Souvigny-en-Sologne",
    "Souzay-Champigny",
    "Souzy",
    "Souzy-la-Briche",
    "Soyaux",
    "Soyers",
    "Soyons",
    "Spay",
    "Spechbach-le-Bas",
    "Spechbach-le-Haut",
    "Speracedes",
    "Spezet",
    "Spicheren",
    "Spincourt",
    "Sponville",
    "Spycker",
    "Squiffiec",
    "St Chamas",
    "St Laurent des Arbres",
    "St-Malo",
    "Staffelfelden",
    "Stains",
    "Steenbecque",
    "Steenvoorde",
    "Steenwerck",
    "Steige",
    "Steinbach",
    "Steinbourg",
    "Stella-Plage",
    "Stenay",
    "Still",
    "Stiring-Wendel",
    "Stosswihr",
    "Strasbourg",
    "Strazeele",
    "Strueth",
    "Succieu",
    "Suce-sur-Erdre",
    "Sucy-en-Brie",
    "Suhescun",
    "Suin",
    "Suippes",
    "Sully-la-Chapelle",
    "Sully-sur-Loire",
    "Sulniac",
    "Sundhoffen",
    "Supt",
    "Surat",
    "Surba",
    "Suresnes",
    "Surgeres",
    "Surgy",
    "Surin",
    "Surques",
    "Surtainville",
    "Surville",
    "Surville",
    "Survilliers",
    "Sury-en-Vaux",
    "Sury-le-Comtal",
    "Surzur",
    "Sus",
    "Sussargues",
    "Suzanne",
    "Suze-la-Rousse",
    "Tabanac",
    "Tacoignieres",
    "Taden",
    "Taglio-Isolaccio",
    "Tagnon",
    "Taillades",
    "Taillebourg",
    "Taillis",
    "Tailly",
    "Taintrux",
    "Taissy",
    "Taize-Aizie",
    "Tajan",
    "Talange",
    "Talant",
    "Talence",
    "Talensac",
    "Tallard",
    "Tallende",
    "Taller",
    "Talloires",
    "Tallud-Sainte-Gemme",
    "Talon",
    "Taluyers",
    "Tamnay-en-Bazois",
    "Tancrou",
    "Taninges",
    "Tanneron",
    "Taradeau",
    "Tarare",
    "Tarascon",
    "Tarascon-sur-Ariege",
    "Tarbes",
    "Tarcenay",
    "Tarentaise",
    "Targassonne",
    "Targon",
    "Tarnes",
    "Tarnos",
    "Tartaras",
    "Tartas",
    "Tartigny",
    "Tassille",
    "Tassin-la-Demi-Lune",
    "Tatinghem",
    "Taule",
    "Taulignan",
    "Taupont",
    "Tauriac",
    "Tauriac-de-Camares",
    "Tautavel",
    "Tauxieres-Mutry",
    "Tauxigny",
    "Tavaux",
    "Tavel",
    "Tavera",
    "Tavernes",
    "Taverny",
    "Tavers",
    "Tavey",
    "Tayrac",
    "Tecou",
    "Teillet-Argenty",
    "Teissieres-de-Cornet",
    "Telgruc-sur-Mer",
    "Teloche",
    "Templemars",
    "Templeuve",
    "Tence",
    "Tende",
    "Tendon",
    "Terce",
    "Tercis-les-Bains",
    "Terdeghem",
    "Tergnier",
    "Ternand",
    "Ternay",
    "Ternay",
    "Terrasson-Lavilledieu",
    "Terraube",
    "Terrebasse",
    "Terssac",
    "Terves",
    "Terville",
    "Tessy-sur-Vire",
    "Teteghem",
    "Teuillac",
    "Teurtheville-Hague",
    "Teyjat",
    "Teyran",
    "Thaims",
    "Thaire",
    "Thal-Drulingen",
    "Thann",
    "Thaon",
    "Thaon-les-Vosges",
    "Thauron",
    "Theding",
    "Thegra",
    "Theil-sur-Vanne",
    "Theillay",
    "Theix",
    "Theize",
    "Thelus",
    "Themericourt",
    "Thenay",
    "Thenelles",
    "Thenezay",
    "Thenissey",
    "Thennelieres",
    "Thennes",
    "Thenon",
    "Theoule-sur-Mer",
    "Therdonne",
    "Therouanne",
    "Theuville",
    "Theuville-aux-Maillots",
    "Theys",
    "Thezan-les-Beziers",
    "Theze",
    "Theziers",
    "Thezy-Glimont",
    "Thiais",
    "Thiant",
    "Thiberville",
    "Thiefosse",
    "Thiergeville",
    "Thiers",
    "Thiers-sur-Theve",
    "Thierville",
    "Thierville-sur-Meuse",
    "Thietreville",
    "Thieuloy-Saint-Antoine",
    "Thieville",
    "Thil",
    "Thil",
    "Thilay",
    "Thilouze",
    "Thimert-Gatelles",
    "Thimory",
    "Thin-le-Moutier",
    "Thionville",
    "Thise",
    "Thivars",
    "Thivencelle",
    "Thiverval-Grignon",
    "Thiviers",
    "Thizy-les-Bourgs",
    "Thodure",
    "Thoirette",
    "Thoiry",
    "Thoiry",
    "Thoissey",
    "Thomery",
    "Thones",
    "Thonnance-les-Joinville",
    "Thonon-les-Bains",
    "Thoraise",
    "Thorens-Glieres",
    "Thorigny",
    "Thorigny-sur-Marne",
    "Thou",
    "Thouare-sur-Loire",
    "Thouars",
    "Thourotte",
    "Thoux",
    "Thuir",
    "Thuit-Hebert",
    "Thulay",
    "Thumeries",
    "Thun-Saint-Amand",
    "Thurageau",
    "Thure",
    "Thuret",
    "Thurins",
    "Thury-Harcourt",
    "Thusy",
    "Thyez",
    "Tierce",
    "Tiercelet",
    "Tigery",
    "Tignes",
    "Tignieu-Jameyzieu",
    "Tigy",
    "Til-Chatel",
    "Tillay-le-Peneux",
    "Tille",
    "Tillenay",
    "Tillieres",
    "Tillieres-sur-Avre",
    "Tilloy-et-Bellay",
    "Tilloy-lez-Cambrai",
    "Tilloy-lez-Marchiennes",
    "Tilly-sur-Meuse",
    "Tilques",
    "Tinchebray",
    "Tincques",
    "Tincry",
    "Tinqueux",
    "Tinteniac",
    "Tiranges",
    "Tirepied",
    "Tivernon",
    "Tocqueville",
    "Tocqueville",
    "Tocqueville-en-Caux",
    "Tollevast",
    "Tombeboeuf",
    "Tomblaine",
    "Tonnay-Boutonne",
    "Tonnay-Charente",
    "Tonneins",
    "Tonnerre",
    "Tonnoy",
    "Torce-en-Vallee",
    "Torcieu",
    "Torcy",
    "Torcy",
    "Torcy-le-Grand",
    "Torderes",
    "Torfou",
    "Torigni-sur-Vire",
    "Torpes",
    "Torreilles",
    "Torsac",
    "Torvilliers",
    "Torxe",
    "Tosse",
    "Tossiat",
    "Totes",
    "Touchay",
    "Toucy",
    "Toudon",
    "Toufflers",
    "Toul",
    "Toulaud",
    "Toulenne",
    "Toulon",
    "Toulon-sur-Arroux",
    "Toulouges",
    "Toulouse",
    "Toulouzette",
    "Toulx-Sainte-Croix",
    "Touques",
    "Touquin",
    "Tour-en-Sologne",
    "Tourbes",
    "Tourcoing",
    "Tourgeville",
    "Tourlaville",
    "Tourly",
    "Tournan-en-Brie",
    "Tournay-sur-Odon",
    "Tournecoupe",
    "Tournedos-Bois-Hubert",
    "Tournefeuille",
    "Tournehem-sur-la-Hem",
    "Tournieres",
    "Tournoisis",
    "Tournon-Saint-Martin",
    "Tournus",
    "Tourouvre",
    "Tourrette-Levens",
    "Tourrettes-sur-Loup",
    "Tourriers",
    "Tours",
    "Tours-en-Savoie",
    "Tours-sur-Marne",
    "Tours-sur-Meymont",
    "Tourtrol",
    "Tourves",
    "Tourville-en-Auge",
    "Tourville-la-Campagne",
    "Tourville-la-Chapelle",
    "Tourville-la-Riviere",
    "Tourville-les-Ifs",
    "Tourville-sur-Odon",
    "Tourville-sur-Sienne",
    "Toury",
    "Toussaint",
    "Toussieu",
    "Toussieux",
    "Toussus-le-Noble",
    "Toutlemonde",
    "Touville",
    "Touvois",
    "Touvre",
    "Touzac",
    "Tracy-le-Mont",
    "Tracy-le-Val",
    "Tracy-sur-Loire",
    "Traenheim",
    "Trainel",
    "Trainou",
    "Tramole",
    "Tramoyes",
    "Trange",
    "Trans-en-Provence",
    "Trappes",
    "Traubach-le-Bas",
    "Trausse",
    "Travecy",
    "Treauville",
    "Trebes",
    "Trebeurden",
    "Trebry",
    "Treclun",
    "Tredarzec",
    "Treduder",
    "Treffendel",
    "Treffieux",
    "Trefflean",
    "Treffort",
    "Treffrin",
    "Treflez",
    "Tregarantec",
    "Tregastel",
    "Treglamus",
    "Tregourez",
    "Treguidel",
    "Tregunc",
    "Treignac",
    "Treillieres",
    "Treize-Septiers",
    "Trelaze",
    "Trelevern",
    "Trelissac",
    "Trelivan",
    "Trelly",
    "Trelon",
    "Tremblay",
    "Tremblay",
    "Tremel",
    "Trementines",
    "Tremery",
    "Tremeven",
    "Tremilly",
    "Tremons",
    "Tremont-sur-Saulx",
    "Tremuson",
    "Treon",
    "Trepied",
    "Trept",
    "Tresilley",
    "Tresques",
    "Tressan",
    "Tressange",
    "Tresserve",
    "Tresses",
    "Trets",
    "Treuzy-Levelay",
    "Treve",
    "Treveneuc",
    "Treveray",
    "Treverien",
    "Treves",
    "Trevien",
    "Trevignin",
    "Trevoux",
    "Trezioux",
    "Triaize",
    "Tricot",
    "Trie-Chateau",
    "Trie-sur-Baise",
    "Triel-sur-Seine",
    "Trieux",
    "Trigavou",
    "Trignac",
    "Trigueres",
    "Trilbardou",
    "Trilport",
    "Triors",
    "Trith-Saint-Leger",
    "Trizac",
    "Trizay",
    "Troarn",
    "Troche",
    "Trogues",
    "Troissereux",
    "Troissy",
    "Troisvilles",
    "Tronville-en-Barrois",
    "Trosly-Breuil",
    "Trouhans",
    "Trouillas",
    "Trouley-Labarthe",
    "Troussencourt",
    "Troussey",
    "Trouville",
    "Trouville-la-Haule",
    "Trouville-sur-Mer",
    "Trouy",
    "Troyes",
    "Troyon",
    "Truchtersheim",
    "Trumilly",
    "Truyes",
    "Tubersent",
    "Tuchan",
    "Tucquegnieux",
    "Tuffe",
    "Tulle",
    "Tullins",
    "Tupigny",
    "Turckheim",
    "Turquant",
    "Tursac",
    "Tuzaguet",
    "Uberach",
    "Uchacq-et-Parentis",
    "Uchaud",
    "Uchaux",
    "Uckange",
    "Ueberstrass",
    "Ugine",
    "Ugny-sur-Meuse",
    "Uhrwiller",
    "Ully-Saint-Georges",
    "Umpeau",
    "Undurein",
    "Ungersheim",
    "Unieux",
    "Ur",
    "Urbeis",
    "Urcay",
    "Urcel",
    "Urcuit",
    "Urdos",
    "Uriage-les-Bains",
    "Urmatt",
    "Urou-et-Crennes",
    "Urrugne",
    "Urt",
    "Uruffe",
    "Urval",
    "Urville-Nacqueville",
    "Ury",
    "Urzy",
    "Us",
    "Ussac",
    "Usseau",
    "Ussel",
    "Usson-du-Poitou",
    "Ussy",
    "Ustaritz",
    "Utelle",
    "Uvernet-Fours",
    "Uxegney",
    "Uxem",
    "Uzein",
    "Uzerche",
    "Uzos",
    "Vaas",
    "Vacheresse",
    "Vacognes-Neuilly",
    "Vacon",
    "Vacquieres",
    "Vacquiers",
    "Vadenay",
    "Vadencourt",
    "Vagney",
    "Vahl-les-Benestroff",
    "Vailhauques",
    "Vaillant",
    "Vailly",
    "Vailly",
    "Vailly-sur-Aisne",
    "Vailly-sur-Sauldre",
    "Vaire",
    "Vaire-sous-Corbie",
    "Vaires-sur-Marne",
    "Vaison-la-Romaine",
    "Vaissac",
    "Vaivre-et-Montoille",
    "Val-de-Fier",
    "Val-de-la-Haye",
    "Val-et-Chatillon",
    "Valaire",
    "Valay",
    "Valberg",
    "Valbonne",
    "Valcourt",
    "Valdahon",
    "Valdampierre",
    "Valdoie",
    "Valdurenque",
    "Valencay",
    "Valence",
    "Valence",
    "Valence-sur-Baise",
    "Valenciennes",
    "Valencin",
    "Valencogne",
    "Valensole",
    "Valentigney",
    "Valenton",
    "Valescourt",
    "Valff",
    "Valflaunes",
    "Valines",
    "Vallangoujard",
    "Vallans",
    "Vallant-Saint-Georges",
    "Vallauris",
    "Valle-di-Mezzana",
    "Vallegue",
    "Valleiry",
    "Valleraugue",
    "Valleres",
    "Vallerois-Lorioz",
    "Valleroy-le-Sec",
    "Vallesvilles",
    "Vallet",
    "Vallier",
    "Valliguieres",
    "Valliquerville",
    "Vallon-en-Sully",
    "Vallouise",
    "Valmestroff",
    "Valmondois",
    "Valmont",
    "Valmont",
    "Valmy",
    "Valognes",
    "Valpuiseaux",
    "Valras-Plage",
    "Valreas",
    "Valros",
    "Vals-les-Bains",
    "Vals-pres-le-Puy",
    "Vanclans",
    "Vandieres",
    "Vandoeuvre-les-Nancy",
    "Vandoncourt",
    "Vandre",
    "Vandrimare",
    "Vandy",
    "Vannes",
    "Vanosc",
    "Vantoux",
    "Vanves",
    "Vanville",
    "Vanzac",
    "Varades",
    "Varages",
    "Varaignes",
    "Varaize",
    "Varanges",
    "Varangeville",
    "Varaville",
    "Varces-Allieres-et-Risset",
    "Varen",
    "Varennes",
    "Varennes",
    "Varennes-en-Argonne",
    "Varennes-Jarcy",
    "Varennes-le-Grand",
    "Varennes-les-Macon",
    "Varennes-sous-Dun",
    "Varennes-sur-Allier",
    "Varennes-sur-Loire",
    "Varennes-sur-Seine",
    "Varennes-Vauzelles",
    "Varesnes",
    "Varetz",
    "Varilhes",
    "Varinfroy",
    "Varois-et-Chaignot",
    "Varrains",
    "Varreddes",
    "Vars",
    "Vars",
    "Varsberg",
    "Varzay",
    "Varzy",
    "Vasles",
    "Vassel",
    "Vasselay",
    "Vassy",
    "Vasteville",
    "Vatan",
    "Vathimenil",
    "Vatimesnil",
    "Vauban",
    "Vaubecourt",
    "Vauchamps",
    "Vauchelles",
    "Vauchelles-les-Domart",
    "Vauchelles-les-Quesnoy",
    "Vauchonvilliers",
    "Vaucouleurs",
    "Vaucresson",
    "Vaudebarrier",
    "Vaudemange",
    "Vaudesson",
    "Vaudeville",
    "Vaudoy-en-Brie",
    "Vaudreching",
    "Vaudrey",
    "Vaugneray",
    "Vaugrigneuse",
    "Vauhallan",
    "Vaujours",
    "Vaulry",
    "Vault-de-Lugny",
    "Vaulx-en-Velin",
    "Vaulx-Milieu",
    "Vaumas",
    "Vaumoise",
    "Vaumort",
    "Vaureal",
    "Vaureilles",
    "Vauvert",
    "Vauville",
    "Vauvillers",
    "Vaux-en-Bugey",
    "Vaux-la-Petite",
    "Vaux-le-Penil",
    "Vaux-les-Pres",
    "Vaux-les-Saint-Claude",
    "Vaux-sur-Aure",
    "Vaux-sur-Blaise",
    "Vaux-sur-Eure",
    "Vaux-sur-Mer",
    "Vaux-sur-Poligny",
    "Vaux-sur-Seine",
    "Vauxbuin",
    "Vauxtin",
    "Vaxoncourt",
    "Vay",
    "Vayrac",
    "Vayres",
    "Veauche",
    "Veaugues",
    "Vecoux",
    "Vecquemont",
    "Vecqueville",
    "Vedene",
    "Veho",
    "Veigne",
    "Veigy-Foncenex",
    "Velaine-en-Haye",
    "Velaines",
    "Velars-sur-Ouche",
    "Velaux",
    "Velines",
    "Velle-le-Chatel",
    "Velleches",
    "Velleminfroy",
    "Velleron",
    "Velotte-et-Tatignecourt",
    "Velye",
    "Velzic",
    "Vemars",
    "Venables",
    "Venansault",
    "Venant",
    "Venarey-les-Laumes",
    "Venasque",
    "Vence",
    "Vendargues",
    "Vendat",
    "Vendays-Montalivet",
    "Vendegies-sur-Ecaillon",
    "Vendemian",
    "Vendenheim",
    "Vendeuil-Caply",
    "Vendeuvre-du-Poitou",
    "Vendeville",
    "Vendin-le-Vieil",
    "Vendin-les-Bethune",
    "Vendome",
    "Vendrennes",
    "Vendres",
    "Venejan",
    "Venelles",
    "Venerand",
    "Venerque",
    "Venette",
    "Veneux-les-Sablons",
    "Venizel",
    "Venizy",
    "Vennecy",
    "Venon",
    "Venoy",
    "Ventabren",
    "Ventes-Saint-Remy",
    "Venteuil",
    "Venthon",
    "Ventiseri",
    "Ver-sur-Launette",
    "Verac",
    "Verargues",
    "Verberie",
    "Verchaix",
    "Verchamp",
    "Verchin",
    "Verdelais",
    "Verderonne",
    "Verdon",
    "Verdun",
    "Verdun-en-Lauragais",
    "Verdun-sur-Garonne",
    "Verdun-sur-le-Doubs",
    "Veretz",
    "Verfeil",
    "Vergeze",
    "Vergongheon",
    "Vergt",
    "Veria",
    "Verigny",
    "Verines",
    "Verjux",
    "Verlinghem",
    "Vermand",
    "Vermelles",
    "Vermenton",
    "Vern-sur-Seiche",
    "Vernaison",
    "Verne",
    "Verne",
    "Vernegues",
    "Verneil-le-Chetif",
    "Vernet-les-Bains",
    "Verneugheol",
    "Verneuil",
    "Verneuil-en-Bourbonnais",
    "Verneuil-en-Halatte",
    "Verneuil-sous-Coucy",
    "Verneuil-sur-Avre",
    "Verneuil-sur-Seine",
    "Verneuil-sur-Vienne",
    "Vernierfontaine",
    "Vernines",
    "Verniolle",
    "Vernioz",
    "Vernon",
    "Vernon",
    "Vernosc-les-Annonay",
    "Vernou-sur-Brenne",
    "Vernouillet",
    "Vernouillet",
    "Vernoux-en-Vivarais",
    "Verny",
    "Veron",
    "Verpillieres",
    "Verquieres",
    "Verquigneul",
    "Verquin",
    "Verrey-sous-Salmaise",
    "Verrieres",
    "Verrieres",
    "Verrieres-le-Buisson",
    "Vers",
    "Vers-en-Montagne",
    "Vers-Pont-du-Gard",
    "Vers-sur-Selles",
    "Versailles",
    "Versailleux",
    "Versigny",
    "Verson",
    "Versonnex",
    "Vert",
    "Vert-en-Drouais",
    "Vert-le-Grand",
    "Vert-le-Petit",
    "Vert-Saint-Denis",
    "Vertaizon",
    "Verthemex",
    "Vertolaye",
    "Verton",
    "Vertou",
    "Vertrieu",
    "Vertus",
    "Vervezelle",
    "Vervins",
    "Very",
    "Verze",
    "Verzeille",
    "Verzenay",
    "Vescovato",
    "Vesigneul-sur-Coole",
    "Vesly",
    "Vesoul",
    "Vestric-et-Candiac",
    "Vetheuil",
    "Vetraz-Monthoux",
    "Veuil",
    "Veurey-Voroize",
    "Veynes",
    "Veyrac",
    "Veyras",
    "Veyre-Monton",
    "Veyrier-du-Lac",
    "Veyrignac",
    "Vezac",
    "Vezelise",
    "Vezenobres",
    "Vezezoux",
    "Vezin-le-Coquet",
    "Vezins",
    "Viabon",
    "Vialas",
    "Vianne",
    "Viarmes",
    "Vias",
    "Vibersviller",
    "Vibraye",
    "Vic-en-Bigorre",
    "Vic-Fezensac",
    "Vic-la-Gardiole",
    "Vic-le-Comte",
    "Vic-le-Fesq",
    "Vic-sur-Aisne",
    "Vic-sur-Cere",
    "Vic-sur-Seille",
    "Vichy",
    "Vico",
    "Vicq",
    "Vidauban",
    "Videix",
    "Vieille-Brioude",
    "Vieille-Eglise",
    "Vieille-Eglise-en-Yvelines",
    "Vieillevigne",
    "Vieillevigne",
    "Vieilley",
    "Vielle-Saint-Girons",
    "Vielle-Tursan",
    "Viellespeze",
    "Vielmur-sur-Agout",
    "Viels-Maisons",
    "Vienne",
    "Vienville",
    "Vierville",
    "Vierzon",
    "Viesly",
    "Viessoix",
    "Vieux",
    "Vieux",
    "Vieux Conde",
    "Vieux-Berquin",
    "Vieux-Boucau-les-Bains",
    "Vieux-Charmont",
    "Vieux-Conde",
    "Vieux-Ferrette",
    "Vieux-Fume",
    "Vieux-Manoir",
    "Vieux-Moulin",
    "Vieux-Rouen-sur-Bresle",
    "Vieux-Thann",
    "Vif",
    "Vigeois",
    "Vignacourt",
    "Vignes",
    "Vignes",
    "Vigneulles",
    "Vigneulles-les-Hattonchatel",
    "Vigneux-de-Bretagne",
    "Vigneux-sur-Seine",
    "Vignoc",
    "Vignolles",
    "Vignols",
    "Vignonet",
    "Vignot",
    "Vigny",
    "Vigny",
    "Vigny",
    "Vigoulet-Auzil",
    "Vigouroux",
    "Vigueron",
    "Vigy",
    "Vihiers",
    "Vilallonga dels Monts",
    "Villabe",
    "Villabon",
    "Villacourt",
    "Village-Neuf",
    "Villaines-la-Juhel",
    "Villaines-les-Rochers",
    "Villaines-sous-Bois",
    "Villaines-sous-Luce",
    "Villaines-sous-Malicorne",
    "Villamblard",
    "Villamee",
    "Villard",
    "Villard-Bonnot",
    "Villard-de-Lans",
    "Villard-Saint-Christophe",
    "Villard-sur-Doron",
    "Villards",
    "Villargondran",
    "Villaries",
    "Villarlurin",
    "Villars",
    "Villars-Brandis",
    "Villars-Colmars",
    "Villars-en-Pons",
    "Villars-et-Villenotte",
    "Villars-les-Dombes",
    "Villars-sur-Var",
    "Villarzel-Cabardes",
    "Villasavary",
    "Villaz",
    "Ville",
    "Ville",
    "Ville-du-Pont",
    "Ville-en-Tardenois",
    "Ville-la-Grand",
    "Ville-sous-Anjou",
    "Ville-sur-Cousances",
    "Ville-sur-Jarnioux",
    "Ville-sur-Lumes",
    "Ville-sur-Tourbe",
    "Villebarou",
    "Villebernier",
    "Villeblevin",
    "Villebois-Lavalette",
    "Villebon-sur-Yvette",
    "Villebret",
    "Villebrumier",
    "Villecerf",
    "Villeconin",
    "Villecresnes",
    "Villecroze",
    "Villedieu",
    "Villedieu",
    "Villedieu-les-Poeles",
    "Villedomer",
    "Villedoux",
    "Villefagnan",
    "Villefontaine",
    "Villefranche-de-Lauragais",
    "Villefranche-de-Rouergue",
    "Villefranche-du-Queyran",
    "Villefranche-le-Chateau",
    "Villefranche-sur-Cher",
    "Villefranche-sur-Mer",
    "Villefranque",
    "Villegailhenc",
    "Villegats",
    "Villegouge",
    "Villejesus",
    "Villejoubert",
    "Villejuif",
    "Villejust",
    "Villelaure",
    "Villelongue-de-la-Salanque",
    "Villemandeur",
    "Villemarechal",
    "Villembray",
    "Villemeux-sur-Eure",
    "Villemoirieu",
    "Villemoisson-sur-Orge",
    "Villemolaque",
    "Villemomble",
    "Villemort",
    "Villemotier",
    "Villemoustaussou",
    "Villemoyenne",
    "Villemur",
    "Villemur-sur-Tarn",
    "Villemurlin",
    "Villenauxe-la-Grande",
    "Villenave",
    "Villeneuve",
    "Villeneuve",
    "Villeneuve les beziers",
    "Villeneuve-au-Chemin",
    "Villeneuve-de-Berg",
    "Villeneuve-de-Duras",
    "Villeneuve-de-la-Raho",
    "Villeneuve-de-Marc",
    "Villeneuve-de-Marsan",
    "Villeneuve-des-Escaldes",
    "Villeneuve-Frouville",
    "Villeneuve-la-Garenne",
    "Villeneuve-la-Guyard",
    "Villeneuve-la-Riviere",
    "Villeneuve-le-Comte",
    "Villeneuve-le-Roi",
    "Villeneuve-Lecussan",
    "Villeneuve-les-Avignon",
    "Villeneuve-les-Bouloc",
    "Villeneuve-les-Genets",
    "Villeneuve-les-Maguelone",
    "Villeneuve-les-Sablons",
    "Villeneuve-Loubet",
    "Villeneuve-Minervois",
    "Villeneuve-Saint-Georges",
    "Villeneuve-Saint-Germain",
    "Villeneuve-sur-Allier",
    "Villeneuve-sur-Fere",
    "Villeneuve-sur-Lot",
    "Villeneuve-sur-Yonne",
    "Villeneuve-Tolosane",
    "Villennes-sur-Seine",
    "Villenouvelle",
    "Villenoy",
    "Villeny",
    "Villeparisis",
    "Villeperdue",
    "Villepinte",
    "Villepinte",
    "Villepreux",
    "Villequier-Aumont",
    "Villerable",
    "Villerest",
    "Villermain",
    "Villeron",
    "Villers",
    "Villers",
    "Villers-Allerand",
    "Villers-au-Bois",
    "Villers-au-Tertre",
    "Villers-aux-Vents",
    "Villers-Bocage",
    "Villers-Bocage",
    "Villers-Bretonneux",
    "Villers-Canivet",
    "Villers-Farlay",
    "Villers-Helon",
    "Villers-la-Montagne",
    "Villers-le-Lac",
    "Villers-le-Sec",
    "Villers-les-Nancy",
    "Villers-les-Ormes",
    "Villers-les-Pots",
    "Villers-les-Roye",
    "Villers-Outreaux",
    "Villers-Plouich",
    "Villers-Pol",
    "Villers-Saint-Frambourg",
    "Villers-Saint-Genest",
    "Villers-Saint-Paul",
    "Villers-Saint-Sepulcre",
    "Villers-Semeuse",
    "Villers-Sire-Nicole",
    "Villers-sous-Preny",
    "Villers-sous-Saint-Leu",
    "Villers-sur-Fere",
    "Villers-sur-le-Mont",
    "Villers-sur-Mer",
    "Villers-sur-Meuse",
    "Villers-sur-Port",
    "Villers-sur-Saulnot",
    "Villersexel",
    "Villerupt",
    "Villerville",
    "Villes-sur-Auzon",
    "Villesequelande",
    "Villesiscle",
    "Villetaneuse",
    "Villetelle",
    "Villeton",
    "Villetrun",
    "Villette",
    "Villettes",
    "Villeurbanne",
    "Villevallier",
    "Villevaude",
    "Villeve",
    "Villeveque",
    "Villeveyrac",
    "Villevieux",
    "Villevillon",
    "Villevocance",
    "Villexavier",
    "Villey-Saint-Etienne",
    "Villey-sur-Tille",
    "Villez-sous-Bailleul",
    "Villie-Morgon",
    "Villiers-Adam",
    "Villiers-au-Bouin",
    "Villiers-Couture",
    "Villiers-en-Desoeuvre",
    "Villiers-en-Plaine",
    "Villiers-le-Bacle",
    "Villiers-le-Bel",
    "Villiers-le-Mahieu",
    "Villiers-Saint-Georges",
    "Villiers-sous-Grez",
    "Villiers-sur-Chize",
    "Villiers-sur-Marne",
    "Villiers-sur-Morin",
    "Villiers-sur-Orge",
    "Villiers-sur-Seine",
    "Villiers-sur-Tholon",
    "Villieu-Loyes-Mollon",
    "Villing",
    "Villons-les-Buissons",
    "Villorceau",
    "Villotte-sur-Aire",
    "Villy-Bocage",
    "Villy-le-Bouveret",
    "Villy-le-Pelloux",
    "Vimines",
    "Vimory",
    "Vimoutiers",
    "Vimy",
    "Vinassan",
    "Vinay",
    "Vinca",
    "Vincelles",
    "Vincelles",
    "Vincennes",
    "Vincent",
    "Vincey",
    "Vincly",
    "Vindelle",
    "Vineuil",
    "Vineuil",
    "Vineuil-Saint-Firmin",
    "Vinneuf",
    "Vinon-sur-Verdon",
    "Vinzelles",
    "Vinzier",
    "Viodos-Abense-de-Bas",
    "Violaines",
    "Violay",
    "Viomenil",
    "Vion",
    "Vion",
    "Viplaix",
    "Vire",
    "Vire",
    "Virelade",
    "Vireux-Molhain",
    "Vireux-Wallerand",
    "Virey-le-Grand",
    "Viriat",
    "Virieu-le-Grand",
    "Virignin",
    "Viriville",
    "Viroflay",
    "Virville",
    "Viry",
    "Viry",
    "Viry-Noureuil",
    "Vitrac",
    "Vitrac-Saint-Vincent",
    "Vitreux",
    "Vitrimont",
    "Vitrolles",
    "Vitry-aux-Loges",
    "Vitry-en-Artois",
    "Vitry-en-Perthois",
    "Vitry-la-Ville",
    "Vitry-sur-Orne",
    "Vitry-sur-Seine",
    "Vitteaux",
    "Vittel",
    "Vittersbourg",
    "Viuz-en-Sallaz",
    "Viven",
    "Vivier",
    "Vivier-au-Court",
    "Vivieres",
    "Viviers",
    "Viviers-du-Lac",
    "Viviers-les-Montagnes",
    "Viviez",
    "Vivonne",
    "Vivy",
    "Vix",
    "Vizille",
    "Vocance",
    "Voglans",
    "Voillecomte",
    "Voiron",
    "Voisin",
    "Voisins-le-Bretonneux",
    "Voissant",
    "Vollore-Ville",
    "Volmerange-les-Boulay",
    "Volmerange-les-Mines",
    "Volonne",
    "Volvic",
    "Volx",
    "Vonnas",
    "Voreppe",
    "Vorey",
    "Vorges",
    "Vouel",
    "Vouhe",
    "Vouille",
    "Vouille-les-Marais",
    "Vouillers",
    "Voujeaucourt",
    "Voulangis",
    "Voultegon",
    "Voulton",
    "Voulx",
    "Vouneuil-sous-Biard",
    "Vouneuil-sur-Vienne",
    "Vourey",
    "Vourles",
    "Voutezac",
    "Voutre",
    "Vouvant",
    "Vouvray",
    "Vouzan",
    "Vouzeron",
    "Vouziers",
    "Vouzon",
    "Vouzy",
    "Voves",
    "Vraiville",
    "Vraux",
    "Vred",
    "Vriange",
    "Vrigne-aux-Bois",
    "Vrigny",
    "Vritz",
    "Vue",
    "Vulaines-sur-Seine",
    "Vulbens",
    "Wahagnies",
    "Wahlenheim",
    "Wail",
    "Wailly",
    "Wailly-Beaucamp",
    "Waldhouse",
    "Waldighofen",
    "Waldweistroff",
    "Waldwisse",
    "Walheim",
    "Wallers",
    "Walscheid",
    "Wambaix",
    "Wambercourt",
    "Wambrechies",
    "Wandignies-Hamage",
    "Wangen",
    "Wangenbourg-Engenthal",
    "Wanquetin",
    "Warcq",
    "Warendin",
    "Wargnies-le-Petit",
    "Warhem",
    "Warlaing",
    "Warlus",
    "Warluzel",
    "Warmeriville",
    "Warnecourt",
    "Wasnes-au-Bac",
    "Wasquehal",
    "Wasselonne",
    "Wassy",
    "Watten",
    "Wattignies",
    "Wattrelos",
    "Wattwiller",
    "Wavignies",
    "Waville",
    "Wavrechain-sous-Denain",
    "Wavrechain-sous-Faulx",
    "Wavrin",
    "Waziers",
    "Weislingen",
    "Weitbruch",
    "Wentzwiller",
    "Werentzhouse",
    "Wervicq-Sud",
    "Westbecourt",
    "Westhoffen",
    "Westhouse",
    "Weyersheim",
    "Wickerschwihr",
    "Widensolen",
    "Wierre-Effroy",
    "Wignehies",
    "Wihr-au-Val",
    "Wildersbach",
    "Willems",
    "Willencourt",
    "Willer-sur-Thur",
    "Willerwald",
    "Willgottheim",
    "Wimereux",
    "Wimille",
    "Wingen-sur-Moder",
    "Wingersheim",
    "Wingles",
    "Winnezeele",
    "Wintersbourg",
    "Wintzenbach",
    "Wintzenheim",
    "Wintzfelden",
    "Wirwignes",
    "Wissant",
    "Wissembourg",
    "Wissignicourt",
    "Wissous",
    "Witry-les-Reims",
    "Wittelsheim",
    "Wittenheim",
    "Witternheim",
    "Wittersdorf",
    "Wittersheim",
    "Wittes",
    "Wittisheim",
    "Wiwersheim",
    "Wizernes",
    "Woerth",
    "Woincourt",
    "Woippy",
    "Wolfgantzen",
    "Wolfisheim",
    "Wolschheim",
    "Wolxheim",
    "Wormhout",
    "Woustviller",
    "Wuenheim",
    "Xaintray",
    "Xanton-Chassenon",
    "Xertigny",
    "Xures",
    "Yaucourt-Bussus",
    "Ychoux",
    "Ydes",
    "Yebleron",
    "Yebles",
    "Yenne",
    "Yermenonville",
    "Yerres",
    "Yerville",
    "Yevre-la-Ville",
    "Yffiniac",
    "Ygos-Saint-Saturnin",
    "Ygrande",
    "Ymare",
    "Youx",
    "Yport",
    "Ypreville-Biville",
    "Yronde-et-Buron",
    "Yssac-la-Tourette",
    "Yssandon",
    "Yssingeaux",
    "Ytrac",
    "Yversay",
    "Yves",
    "Yvetot",
    "Yvette",
    "Yvias",
    "Yvoire",
    "Yvrac",
    "Yvrac-et-Malleyrand",
    "Yzernay",
    "Yzeure",
    "Yzeures-sur-Creuse",
    "Yzosse",
    "Zellwiller",
    "Zillisheim",
    "Zimmersheim",
    "Zimming",
    "Zinswiller",
    "Zittersheim",
    "Zouafques",
    "Zutzendorf"],
  },
  {
    name: "Gabon",
    cities: ["Libreville", "Port-Gentil", "Franceville"],
  },
  {
    name: "Gambia",
    cities: ["Banjul", "Serekunda", "Brikama"],
  },
  {
    name: "Georgia",
    cities: ["Tbilisi", "Kutaisi", "Batumi"],
  },
  {
    name: "Germany",
    cities: ["Berlin", "Hamburg", "Munich"],
  },
  {
    name: "Ghana",
    cities: ["Accra", "Kumasi", "Tamale"],
  },
  {
    name: "Greece",
    cities: ["Athens", "Thessaloniki", "Patras"],
  },
  {
    name: "Grenada",
    cities: ["St. George's", "Gouyave", "Grenville"],
  },
  {
    name: "Guatemala",
    cities: ["Guatemala City", "Mixco", "Villa Nueva"],
  },
  {
    name: "Guinea",
    cities: ["Conakry", "Nzerekore", "Kankan"],
  },
  {
    name: "Guinea-Bissau",
    cities: ["Bissau", "Bafata", "Gabu"],
  },
  {
    name: "Guyana",
    cities: ["Georgetown", "Linden", "New Amsterdam"],
  },
  {
    name: "Haiti",
    cities: ["Port-au-Prince", "Carrefour", "Delmas"],
  },
  {
    name: "Honduras",
    cities: ["Tegucigalpa", "San Pedro Sula", "Choloma"],
  },
  {
    name: "Hungary",
    cities: ["Budapest", "Debrecen", "Miskolc"],
  },
  {
    name: "Iceland",
    cities: ["Reykjavik", "Kopavogur", "Hafnarfjordur"],
  },
  {
    name: "India",
    cities: ["New Delhi", "Mumbai", "Bangalore"],
  },
  {
    name: "Indonesia",
    cities: ["Jakarta", "Surabaya", "Bandung"],
  },
  {
    name: "Iran",
    cities: ["Tehran", "Mashhad", "Isfahan"],
  },
  {
    name: "Iraq",
    cities: ["Baghdad", "Basra", "Mosul"],
  },
  {
    name: "Ireland",
    cities: ["Dublin", "Cork", "Galway"],
  },
  {
    name: "Israel",
    cities: ["Jerusalem", "Tel Aviv", "Haifa"],
  },
  {
    name: "Italy",
    cities: ["Rome", "Milan", "Naples"],
  },
  {
    name: "Jamaica",
    cities: ["Kingston", "Spanish Town", "Montego Bay"],
  },
  {
    name: "Japan",
    cities: ["Tokyo", "Osaka", "Kyoto"],
  },
  {
    name: "Jordan",
    cities: ["Amman", "Zarqa", "Irbid"],
  },
  {
    name: "Kazakhstan",
    cities: ["Nur-Sultan", "Almaty", "Karaganda"],
  },
  {
    name: "Kenya",
    cities: ["Nairobi", "Mombasa", "Kisumu"],
  },
  {
    name: "Kiribati",
    cities: ["Tarawa", "Betio", "Bikenibeu"],
  },
  {
    name: "Kuwait",
    cities: ["Kuwait City", "Al Ahmadi", "Hawalli"],
  },
  {
    name: "Kyrgyzstan",
    cities: ["Bishkek", "Osh", "Jalal-Abad"],
  },
  {
    name: "Laos",
    cities: ["Vientiane", "Pakse", "Savannakhet"],
  },
  {
    name: "Latvia",
    cities: ["Riga", "Daugavpils", "Liepaja"],
  },
  {
    name: "Lebanon",
    cities: ["Beirut", "Tripoli", "Sidon"],
  },
  {
    name: "Lesotho",
    cities: ["Maseru", "Teyateyaneng", "Mafeteng"],
  },
  {
    name: "Liberia",
    cities: ["Monrovia", "Gbarnga", "Bensonville"],
  },
  {
    name: "Libya",
    cities: ["Tripoli", "Benghazi", "Misrata"],
  },
  {
    name: "Liechtenstein",
    cities: ["Vaduz", "Schaan", "Triesen"],
  },
  {
    name: "Lithuania",
    cities: ["Vilnius", "Kaunas", "Klaipeda"],
  },
  {
    name: "Luxembourg",
    cities: ["Luxembourg City", "Esch-sur-Alzette", "Differdange"],
  },
  {
    name: "Madagascar",
    cities: ["Antananarivo", "Toamasina", "Antsirabe"],
  },
  {
    name: "Malawi",
    cities: ["Lilongwe", "Blantyre", "Mzuzu"],
  },
  {
    name: "Malaysia",
    cities: ["Kuala Lumpur", "George Town", "Ipoh"],
  },
  {
    name: "Maldives",
    cities: ["Male", "Addu City", "Fuvahmulah"],
  },
  {
    name: "Mali",
    cities: ["Bamako", "Sikasso", "Mopti"],
  },
  {
    name: "Malta",
    cities: ["Valletta", "Birkirkara", "Mosta"],
  },
  {
    name: "Marshall Islands",
    cities: ["Majuro", "Ebeye", "Jabor"],
  },
  {
    name: "Mauritania",
    cities: ["Nouakchott", "Nouadhibou", "Kiffa"],
  },
  {
    name: "Mauritius",
    cities: ["Port Louis", "Beau Bassin-Rose Hill", "Vacoas-Phoenix"],
  },
  {
    name: "Mexico",
    cities: ["Mexico City", "Guadalajara", "Monterrey"],
  },
  {
    name: "Micronesia",
    cities: ["Palikir", "Weno", "Colonia"],
  },
  {
    name: "Moldova",
    cities: ["Chisinau", "Balti", "Tiraspol"],
  },
  {
    name: "Monaco",
    cities: ["Monaco", "Monte Carlo", "La Condamine"],
  },
  {
    name: "Mongolia",
    cities: ["Ulaanbaatar", "Erdenet", "Darkhan"],
  },
  {
    name: "Montenegro",
    cities: ["Podgorica", "Niksic", "Herceg Novi"],
  },
  {
    name: "Morocco",
    cities: ["Rabat", "Casablanca", "Fes"],
  },
  {
    name: "Mozambique",
    cities: ["Maputo", "Matola", "Beira"],
  },
  {
    name: "Myanmar",
    cities: ["Naypyidaw", "Yangon", "Mandalay"],
  },
  {
    name: "Namibia",
    cities: ["Windhoek", "Rundu", "Walvis Bay"],
  },
  {
    name: "Nauru",
    cities: ["Yaren", "Denigomodu", "Baiti"],
  },
  {
    name: "Nepal",
    cities: ["Kathmandu", "Pokhara", "Lalitpur"],
  },
  {
    name: "Netherlands",
    cities: ["Amsterdam", "Rotterdam", "The Hague"],
  },
  {
    name: "New Zealand",
    cities: ["Wellington", "Auckland", "Christchurch"],
  },
  {
    name: "Nicaragua",
    cities: ["Managua", "León", "Masaya"],
  },
  {
    name: "Niger",
    cities: ["Niamey", "Zinder", "Maradi"],
  },
  {
    name: "Nigeria",
    cities: ["Abuja", "Lagos", "Kano"],
  },
  {
    name: "North Korea",
    cities: ["Pyongyang", "Hamhung", "Chongjin"],
  },
  {
    name: "North Macedonia",
    cities: ["Skopje", "Bitola", "Kumanovo"],
  },
  {
    name: "Norway",
    cities: ["Oslo", "Bergen", "Trondheim"],
  },
  {
    name: "Oman",
    cities: ["Muscat", "Salalah", "Sohar"],
  },
  {
    name: "Pakistan",
    cities: [ "Abbottabad",
    "Attock",
    "Batgram",
    "Bhimbar",
    "Burewala",
    "Cantt",
    "Chakwal",
    "Clifton",
    "Daska",
    "Daud Khel",
    "Dera Ghazi Khan",
    "Faisalabad",
    "Fazal",
    "Gilgit",
    "Goth Abad Magsi",
    "Gujar Khan",
    "Gujranwala",
    "Gujrat",
    "Gulberg",
    "Gulshan-e-Iqbal",
    "Habib Baihk",
    "Haripur",
    "Havelian",
    "Hyderabad",
    "Islamabad",
    "Jhang City",
    "Jhang Sadr",
    "Jhelum",
    "Jhumra",
    "Kabirwala",
    "Karachi",
    "Kasur",
    "Khan",
    "Khanewal",
    "Khanpur",
    "Kohat",
    "Lahore",
    "Mandi",
    "Mandi Bahauddin",
    "Mansehra",
    "Mardan",
    "Mian Channu",
    "Mianwali",
    "Miran Shah",
    "Multan",
    "Muzaffarabad",
    "Nangar",
    "Nankana Sahib",
    "Narowal",
    "New Mirpur",
    "Nowshera",
    "Okara",
    "Peshawar",
    "Pindi",
    "Plot",
    "Quetta",
    "Rawalpindi",
    "Rawlakot",
    "Saddar",
    "Sahiwal",
    "Sarai Sidhu",
    "Sargodha",
    "Sarwar",
    "Sheikhupura",
    "Sialkot",
    "Sukkur",
    "Toba Tek Singh",
    "Usman",
    "Wazirabad",
    "Ziauddin"],
  },
  {
    name: "Palau",
    cities: ["Ngerulmud", "Koror", "Meyungs"],
  },
  {
    name: "Panama",
    cities: ["Panama City", "San Miguelito", "Tocumen"],
  },
  {
    name: "Papua New Guinea",
    cities: ["Port Moresby", "Lae", "Mount Hagen"],
  },
  {
    name: "Paraguay",
    cities: ["Asunción", "Ciudad del Este", "San Lorenzo"],
  },
  {
    name: "Peru",
    cities: ["Lima", "Arequipa", "Trujillo"],
  },
  {
    name: "Philippines",
    cities: ["Manila", "Quezon City", "Davao City"],
  },
  {
    name: "Poland",
    cities: ["Warsaw", "Kraków", "Łódź"],
  },
  {
    name: "Portugal",
    cities: ["Lisbon", "Porto", "Vila Nova de Gaia"],
  },
  {
    name: "Qatar",
    cities: ["Doha", "Al Rayyan", "Umm Salal"],
  },
  {
    name: "Romania",
    cities: ["Bucharest", "Cluj-Napoca", "Timișoara"],
  },
  {
    name: "Russia",
    cities: ["Moscow", "Saint Petersburg", "Novosibirsk"],
  },
  {
    name: "Rwanda",
    cities: ["Kigali", "Butare", "Gitarama"],
  },
  {
    name: "Saint Kitts and Nevis",
    cities: ["Basseterre", "Charlestown", "Dieppe Bay Town"],
  },
  {
    name: "Saint Lucia",
    cities: ["Castries", "Vieux Fort", "Micoud"],
  },
  {
    name: "Saint Vincent and the Grenadines",
    cities: ["Kingstown", "Georgetown", "Byera Village"],
  },
  {
    name: "Samoa",
    cities: ["Apia", "Vaitele", "Faleula"],
  },
  {
    name: "San Marino",
    cities: ["San Marino", "Borgo Maggiore", "Serravalle"],
  },
  {
    name: "Sao Tome and Principe",
    cities: ["São Tomé", "Trindade", "Neves"],
  },
  {
    name: "Saudi Arabia",
    cities: ["Riyadh", "Jeddah", "Mecca"],
  },
  {
    name: "Senegal",
    cities: ["Dakar", "Thiès", "Kaolack"],
  },
  {
    name: "Serbia",
    cities: ["Belgrade", "Novi Sad", "Niš"],
  },
  {
    name: "Seychelles",
    cities: ["Victoria", "Anse Boileau", "Beau Vallon"],
  },
  {
    name: "Sierra Leone",
    cities: ["Freetown", "Bo", "Kenema"],
  },
  {
    name: "Singapore",
    cities: ["Singapore", "Jurong West", "Woodlands"],
  },
  {
    name: "Slovakia",
    cities: ["Bratislava", "Košice", "Prešov"],
  },
  {
    name: "Slovenia",
    cities: ["Ljubljana", "Maribor", "Celje"],
  },
  {
    name: "Solomon Islands",
    cities: ["Honiara", "Gizo", "Auki"],
  },
  {
    name: "Somalia",
    cities: ["Mogadishu", "Hargeisa", "Bosaso"],
  },
  {
    name: "South Africa",
    cities: ["Pretoria", "Johannesburg", "Cape Town"],
  },
  {
    name: "South Korea",
    cities: ["Seoul", "Busan", "Incheon"],
  },
  {
    name: "South Sudan",
    cities: ["Juba", "Wau", "Malakal"],
  },
  {
    name: "Spain",
    cities: ["Madrid", "Barcelona", "Valencia"],
  },
  {
    name: "Sri Lanka",
    cities: ["Colombo", "Gampaha", "Kandy"],
  },
  {
    name: "Sudan",
    cities: ["Khartoum", "Omdurman", "Port Sudan"],
  },
  {
    name: "Suriname",
    cities: ["Paramaribo", "Lelydorp", "Nieuw Nickerie"],
  },
  {
    name: "Sweden",
    cities: ["Stockholm", "Gothenburg", "Malmö"],
  },
  {
    name: "Switzerland",
    cities: ["Bern", "Zurich", "Geneva"],
  },
  {
    name: "Syria",
    cities: ["Damascus", "Aleppo", "Homs"],
  },
  {
    name: "Taiwan",
    cities: ["Taipei", "Kaohsiung", "Taichung"],
  },
  {
    name: "Tajikistan",
    cities: ["Dushanbe", "Khujand", "Kulob"],
  },
  {
    name: "Tanzania",
    cities: ["Dodoma", "Dar es Salaam", "Mwanza"],
  },
  {
    name: "Thailand",
    cities: ["Bangkok", "Nonthaburi", "Chiang Mai"],
  },
  {
    name: "Timor-Leste",
    cities: ["Dili", "Same", "Baucau"],
  },
  {
    name: "Togo",
    cities: ["Lomé", "Sokodé", "Kara"],
  },
  {
    name: "Tonga",
    cities: ["Nuku'alofa", "Neiafu", "Haveluliku"],
  },
  {
    name: "Trinidad and Tobago",
    cities: ["Port of Spain", "Chaguanas", "San Fernando"],
  },
  {
    name: "Tunisia",
    cities: ["Tunis", "Sfax", "Sousse"],
  },
  {
    name: "Turkey",
    cities: ["Ankara", "Istanbul", "Izmir"],
  },
  {
    name: "Turkmenistan",
    cities: ["Ashgabat", "Turkmenabat", "Dasoguz"],
  },
  {
    name: "Tuvalu",
    cities: ["Funafuti", "Vaiaku", "Fongafale"],
  },
  {
    name: "Uganda",
    cities: ["Kampala", "Gulu", "Lira"],
  },
  {
    name: "Ukraine",
    cities: ["Kyiv", "Kharkiv", "Dnipro"],
  },
  {
    name: "United Arab Emirates",
    cities: ["Abu Dhabi", "Dubai", "Sharjah"],
  },
  {
    name: "United Kingdom",
    cities: ["London", "Birmingham", "Manchester"],
  },
  {
    name: "United States",
    cities: ["Washington, D.C.", "New York City", "Los Angeles"],
  },
  {
    name: "Uruguay",
    cities: ["Montevideo", "Salto", "Paysandú"],
  },
  {
    name: "Uzbekistan",
    cities: ["Tashkent", "Samarkand", "Namangan"],
  },
  {
    name: "Vanuatu",
    cities: ["Port Vila", "Luganville", "Norsup"],
  },
  {
    name: "Vatican City",
    cities: ["Vatican City"],
  },
  {
    name: "Venezuela",
    cities: ["Caracas", "Maracaibo", "Valencia"],
  },
  {
    name: "Vietnam",
    cities: ["Hanoi", "Ho Chi Minh City", "Da Nang"],
  },
  {
    name: "Yemen",
    cities: ["Sana'a", "Aden", "Taiz"],
  },
  {
    name: "Zambia",
    cities: ["Lusaka", "Kitwe", "Ndola"],
  },
  {
    name: "Zimbabwe",
    cities: ["Harare", "Bulawayo", "Chitungwiza"],
  },
];
