import React, { useState, useEffect } from "react";
import moment from "moment/moment";
// import Routes
import { useLocation } from "react-router-dom";

// Styling import
import "./TeacherDetailPage.scss";
import { Table } from "antd";
import ImgURL from "../../../Utils/ImgUrlGen";
import CalendarStudent from "./CalendarStudent/CalendarStudent";
import { TeacherStatsAPI } from "./../../../API/users";
import { toast } from "react-toastify";
import { set } from "lodash";

export default function TeacherDetailPage() {
  const location = useLocation();
  const teacherDetail = location?.state?.teacherDetail;
  const [open, setOpen] = useState(false);
  const [showFullDescription, setshowFullDescription] = useState(false);
  const [teacherStats, setTeacherStats] = useState({
    students: 0,
    lectures: 0
  });

  useEffect(() => {
    const fetchTeacherStats = async () => {
      const res = await TeacherStatsAPI(teacherDetail._id);
      if (res.error) {
        toast.error(res.error);
      } else {
        setTeacherStats({
          students: res.data.totalUniqueParticipants,
          lectures: res.data.totalMeetings
        });
      }
    };
    fetchTeacherStats();
  }, [teacherDetail._id]);
  const shortenDescription = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    } else {
      return text;
    }
  };

  const outerColumns = [
    {
      title: "Job Title",
      dataIndex: "positionTitle",
      key: "positionTitle",
      ellipsis: true,
    },
    {
      title: "Subject",
      dataIndex: "experienceType",
      key: "experienceType",
      ellipsis: true,

    },
    {
      title: "From",
      dataIndex: "startDate",
      key: "startDate",
      ellipsis: true,

    },
    {
      title: "To",
      dataIndex: "endDate",
      key: "endDate",
      ellipsis: true,

    },
    {
      title: "Experience",
      dataIndex: "duration",
      key: "duration",
      ellipsis: true,

    },
  ];
  const outerColumns2 = [
    {
      title: "Institute",
      dataIndex: "instituteName",
      key: "instituteName",
      ellipsis: true,
    },
    {
      title: "Course",
      dataIndex: "degreeTitle",
      key: "degreeTitle",
      ellipsis: true,
    },
    {
      title: "From",
      dataIndex: "startDate",
      key: "startDate",
      ellipsis: true,
    },
    {
      title: "To",
      dataIndex: "endDate",
      key: "endDate",
      ellipsis: true,
    },
    {
      title: "Experience",
      dataIndex: "duration",
      key: "duration",
      ellipsis: true,
    },
  ];


  return (
    <>
      <div className="teacherDetailMainContainer">
        <div className="detailMain">
          <div className="hidden lg:block">
            <div
              // style={{ width: "23rem" }}
              className="w-full lg:w-96 h-max block  rounded-md bg-white shadow  border-4 border-transparent px-10 py-7"
            >
              <div className="flex justify-between">
                <p className="text-md text-black-500">Taught</p>
                <p className="text-md text-gray-400">{teacherStats.students} students</p>
              </div>
              <div className="flex justify-between py-2">
                <p className="text-md text-black-500">Conducted</p>
                <p className="text-md text-gray-400">{teacherStats.lectures} Lessons</p>
              </div>
              <div className="flex justify-between py-2">
                <p className="text-md text-black-500">Location</p>
                <p className="text-md text-gray-400">{teacherDetail?.residence}</p>
              </div>
              {/* <div className="flex justify-between py-2">
                <p className="text-md text-black-500">Last Online</p>
                <p className="text-md text-gray-400">1 hours</p>
              </div> */}
              <div className="flex justify-between py-2">
                <p className="text-md text-black-500">Member Since</p>
                <p className="text-md text-gray-400">{moment(teacherDetail?.createdAt).format("YYYY")}</p>
              </div>
              <div className="flex justify-between py-2">
                <p className="text-md text-black-500">Time Zone</p>
                <p className="text-md text-gray-400">{teacherDetail?.timezone}</p>
              </div>
            </div>
          </div>
          <div className="teachersContainer">
            <div className="teacherProfile">
              <div className="teacherImgName">
                <img
                  className="TeacherImg"
                  src={ImgURL(teacherDetail?.profileImage)}
                  alt="Uploading"
                />
                <div className="TeacherName">
                  <h1 className="teacherName">
                    {teacherDetail?.firstName} {teacherDetail?.lastName}
                  </h1>
                  <p>${teacherDetail?.rate || 0}/hour</p>
                </div>
              </div>
            </div>
            <div className="detail">
              {showFullDescription
                ? teacherDetail?.bio?.replace(/<[^>]+>/g, "")
                : shortenDescription(
                  teacherDetail?.bio?.replace(/<[^>]+>/g, ""),
                  100
                )}
            </div>
            <div className="">
              <div className="grid grid-cols-1 pt-7">
                <div className="w-full mx-auto">
                  <div className="relative">
                    <div className="block w-full rounded-md bg-white shadow border-4 border-transparent px-14 py-5">
                      <div className="flex">
                        <h1 className="pr-16 teacherdetailsMain">Age :</h1>
                        <div className=" text-gray-400">
                          {teacherDetail?.age}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="relative py-8">
                    <div className="block w-full rounded-md bg-white shadow border-4 border-transparent px-14 py-5">
                      <div className="flex">
                        <h1 className="pr-10 teacherdetailsMain">Gender :</h1>
                        <div className="genderTeacher text-gray-400">{teacherDetail?.gender}</div>
                      </div>
                    </div>
                  </div>
                  <div className="relative pb-2">
                    <div className="block w-full rounded-md bg-white shadow border-4 border-transparent px-14 py-5">
                      <div className="flex">
                        <h1 className="pr-10 teacherdetailsMain">Language :</h1>
                        <div className=" text-gray-400">{teacherDetail?.primaryLanguage}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h1 className="workText">Work Experience</h1>
            <div className="tableMain">
              <table className="table">
                <Table
                  columns={outerColumns}
                  dataSource={teacherDetail?.work}
                  pagination={false}
                  scroll={{ x: true }}
                />
              </table>
            </div>
            <h1 className="workText">Education History</h1>
            <div className="tableMain pb-10">
              <table className="table">
                <Table
                  columns={outerColumns2}
                  dataSource={teacherDetail?.education}
                  pagination={false}
                  scroll={{ x: true }}
                />
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="CalendarStudent">
        <CalendarStudent />
      </div>
    </>
  );
}
