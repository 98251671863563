import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; // Import the slick-carousel CSS
import "slick-carousel/slick/slick-theme.css"; // Import the slick-carousel theme CSS

// Components :
import PrimaryButton from "../../../Components/Atoms/PrimaryButton/PrimaryButton";
import PrimaryHeading from "../../../Components/Atoms/PrimaryHeading/PrimaryHeading";
import Stars from "./stars";

// Assets | ICONS :
import BackgroundIMG from "../../../Assets/Images/popover.svg";
import ProfileIMG from "../../../Assets/Images/profile.svg";
import One from "../../../Assets/svgs/1.png"
import Two from "../../../Assets/svgs/2.png"
import Three from "../../../Assets/svgs/3.png"
import Four from "../../../Assets/svgs/4.png"
import Five from "../../../Assets/svgs/5.png"
import Six from "../../../Assets/svgs/6.png"

import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";

// CSS
import "./Reviews.scss";

const Reviews = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      name: "Moshin Ali",
      rating: 5,
      content: "The Best Platform For Kids and Adults to Learn the Quran Online with the most qualified and experienced tutors serving 24/7 throughout the year. I strongly recommend you to get its services.",
      profileImg: One,
    },
    {
      name: "Farooq Khan",
      rating: 4,
      content: "A great platform for learning. The tutors are very supportive and the content is excellent. found the tutor of my native language. very help full for my children . i will recommend this service ",
      profileImg: Two,
    },
    {
      name: "Muhammad Ali",
      rating: 5,
      content: "Excellent service and very professional tutors. Highly recommended! My children have learned so much from this platform. The tutors are very patient and knowledgeable. ",
      profileImg: Three,
    },
    {
      name: "Abu Babar",
      rating: 5,
      content: "My children have learned so much from this platform. The tutors are very patient and knowledgeable. The best online learning experience we've had. The tutors are exceptional",
      profileImg: Four,
    },
    {
      name: "Ali Hassan",
      rating: 4,
      content: "A very effective learning platform with great support from the tutors. My kids love it!",
      profileImg: Five,
    },
    {
      name: "Waleed Abdullah",
      rating: 5,
      content: "The best online learning experience we've had. The tutors are exceptional. A very effective learning platform with great support from the tutors. My kids love it!",
      profileImg: Six,
    },
  ];

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className="review-containers">
      <div className="heading">
        <PrimaryHeading heading="Reviews" />
      </div>
      <div className="review__container block">
        <Slider ref={sliderRef} {...settings}>
          {testimonials.map((testimonial, i) => (
            <div className="review-card" key={i}>
              <div className="card-data cursor">
                <img src={BackgroundIMG} alt="" />
                <div className="data">
                  <div className="d-heading">{testimonial.name}</div>
                  {/* <div className="post">Post</div> */}
                  <div className="rating">
                    <div><Stars rating={testimonial.rating} /></div>
                  </div>
                  <div className="para">
                    {testimonial.content}
                  </div>

                </div>
              </div>
              <div className="profile_image">
                <img src={testimonial.profileImg} alt={testimonial.name} />
              </div>
            </div>
          ))}
        </Slider>
        <div className="flex justify-center items-center pt-28">
          <button
            onClick={goToPrev}
            className=" hover:text-white  mx-5 bg-gray-200 hover:bg-yellow  flex items-center justify-center w-8 h-8 rounded-full"
          >
            <BsArrowLeftShort size={30} />
          </button>
          <button
            onClick={goToNext}
            className=" hover:text-white  mx-5 bg-gray-200 hover:bg-yellow  flex items-center justify-center w-8 h-8 rounded-full"
          >
            <BsArrowRightShort size={30} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
