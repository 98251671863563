import React, { useState } from 'react'
import ModalVideo from 'react-modal-video';
import AboutImage from '../../../Assets/Images/about-img.png'
import { ReactComponent as Video } from '../../../Assets/Images/video.svg';

import PrimaryHeading from '../../../Components/Atoms/PrimaryHeading/PrimaryHeading'
import PrimaryButton from '../../../Components/Atoms/PrimaryButton/PrimaryButton'

import './AboutHome.scss'





const AboutHome = () => {
    const [isOpen, setOpen] = useState(false)
    return (
        <div className='about-container'>
            <div className="container">
                <div className="seeking-container">
                    <div className="left-img">
                        <img src={AboutImage} alt="" />
                    </div>
                    <div className="right-content">
                        <div className="about">About Us</div>
                        <PrimaryHeading heading="Seeking of knowledge is a duty of every Muslim " />
                        <p className='para'>The rise of Muslims to the zenith of civilization in a period of four decades was based on lslam's emphasis on learning. This is obvious when one takes a look at the Qur'an and the traditions of Prophet Muhammad which are filled with references to learning, education, observation.</p>
                        <div className="about-bio">
                            <PrimaryButton heading="Discover" />
                            <ModalVideo
                                channel='youtube'
                                autoplay="1" isOpen={isOpen} videoId="LrMSqRYruwQ" onClose={() => setOpen(false)} />
                            <div className="videoButton"
                                onClick={() => setOpen(true)}>
                                <Video className='icon' />
                            </div>
                            <a className='link'>Watch Our Video</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutHome
