import React, { useState } from "react";
import PrimaryButton from "../../../Components/Atoms/PrimaryButton/PrimaryButton";
import { PostSubscriptionAPI } from "../../../API/subscription";
import { toast } from "react-toastify";
import "./NewsLetter.scss";

const NewsLetter = () => {
  const [email, setEmail] = useState("");
  const [checkedBox, setCheckedBox] = useState(false);
  const [loading, setLoading] = useState(false)

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const subscription = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      email: email,
    };
    if (!email) {
      return toast.warn("Please enter your email")
    }
    if (!checkedBox) {
      return toast.warn("Please agree Terms & Policy")
    }
    const res = await PostSubscriptionAPI(data);
    if (res.error != null) {
      toast.error(res?.error.message);
    } else {
      toast?.success(res.data.message);
    }
    setEmail("");
    setCheckedBox(false);
    document.getElementById("checkbox").checked = false;
    setLoading(false);
  };

  return (
    <>
      <section className="NewsLetter DBlock  py-10 sm:py-16  w-full">
        <div className=" mx-auto py-5 bg-lightY text-center rounded-lg shadow-lg ">
          <div className="p-8  ">
            <p>Subscribe to Our</p>
            <h1 className="text-4xl font-extrabold py-5 tracking-wider">
              Newsletter
            </h1>
            <p className="pb-5">
              To Be Update With All The Latest Blogs And News
            </p>
            <form className="flex flex-col items-center w-full">
              <input type="email" value={email} onChange={handleEmailChange} className="border border-gray-100 shadowInput rounded-md p-3.5 w-full md:w-1/2 mb-8" placeholder="Enter your email address"
              />
              <div className="checkboxes md:w-1/2 ">
                <div className="chkBox">
                  <input type="checkbox" id="checkbox" name="terms" required onChange={(e) => setCheckedBox(e.target.checked)} />
                  <p>Agree with <span>Terms & Policy</span></p>
                </div>
              </div>
              <PrimaryButton heading="Subscribe" primaryFun={subscription} loading={loading}/>

            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsLetter;
