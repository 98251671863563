import axios from "../AxiosInstance";


const PostContactUsAPI = async (formData) =>{
    let resolved = {
        error : null,
        data : null
    }
    try{
        let res = await axios({
            url : '/contact/add',
            method : "POST",
            data : formData,
        })
        resolved.data = res.data
    }catch(err){
        if (err && err.response && err?.response?.data?.message) {
            resolved.error = err.response.data.message
        } else {
            resolved.error = "Something went Wrong"
        }
    }
    return resolved
}

export {PostContactUsAPI}