import React, { useState, useEffect } from "react";

// Asserts
// import FindTeachHead from "../../Assets/Images/FindTeacherHeader.png";
import FindTeachHead from 'Assets/Images/FindTechHead.png'
import FindTeachHead2 from 'Assets/Images/FindTechHead2.png'
import medal from "../../Assets/Images/icn_Medal.png";

// Routes
import { useNavigate } from "react-router-dom";

// Styling
import "./Teacher.scss";
import { Modal, Radio, Rate } from "antd";

// Other Modules
import TeacherSideBar from "./TeachersSideBar/TeacherSideBar";
import { toast } from "react-toastify";
import { GetAllTeachersAPI, TeacherStatsAPI } from "../../API/users";
import { useDispatch, useSelector } from "react-redux";
import { TeachersDataActions } from "../../Redux/Slice/TeacherData";
import ImgURL from "../../Utils/ImgUrlGen";
import PrimaryButton2 from "../../Components/Atoms/PrimaryButton2/PrimaryButton2";
import PreLoader from "Components/Atoms/PreLoader/PreLoader";
import { FaThLarge, FaThList } from "react-icons/fa";
import { Skeleton } from "@mui/material";
import PrimaryButton from "Components/Atoms/PrimaryButton/PrimaryButton";
import SecondaryButton from "Components/Atoms/SecondaryButton/SecondaryButton";
import { CiBoxList } from "react-icons/ci";
import { IoGridOutline } from "react-icons/io5";

export default function Teacher() {
  const navigate = useNavigate();

  const desc = ["terrible", "bad", "normal", "good", "wonderful"];
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOk = () => {
    setOpen(false);
  };
  const gotoBack = () => {
    setOpen(false);
  };
  const handleLogin = () => {
    setOpen(false);
    window.location.replace(`${window?.location?.DashboardURL}/login?s=c&l=teacher`)
  }
  const handleRegister = () => {
    setOpen(false);
    window.location.replace(`${window?.location?.DashboardURL}/register?s=c&l=teacher`)
  };


  const Dispatch = useDispatch()
  const teachersData = useSelector((state) => state?.TeachersData)
  const UserData = useSelector((state) => state.UserData);
  let teacherIds = [];
  const [teacherData, setTeacherData] = useState([]);

  const gettingAllTeachers = async () => {
    setLoading(true)
    const res = await GetAllTeachersAPI()
    if (res.error != null) {
      toast.error(res?.error)
    } else {
      let TeacherData = res?.data?.result || []
      teacherIds = TeacherData.map(teacher => teacher._id);
      fetchAllTeacherData()

      Dispatch(TeachersDataActions?.setTeachersData(TeacherData))
    }
    setLoading(false)
  }
  let allTeacherData = [];
  // Function to get data for each teacher ID
  const fetchAllTeacherData = async () => {
    for (let id of teacherIds) {
      const data = await TeacherStatsAPI(id);
      if (data) {
        allTeacherData.push(data);
      }
    }
    console.log(allTeacherData[0].data.totalMeetings, "hellobbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb");
    setTeacherData(allTeacherData);
    console.log(teacherData);
  }
  useEffect(() => {
    gettingAllTeachers()
  }, [])


  const [showFullDescription, setshowFullDescription] = useState(false);
  const [cardLayout, setCardLayout] = useState("single");
  const [cardLayoutActive, setCardLayoutActive] = useState({ single: "active", double: null });
  const [searchQuery, setSearchQuery] = useState("");

  const shortenDescription = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    } else {
      return text;
    }
  };

  const handleView = (data, activeClass) => {
    if (data == "single") {
      setCardLayout("single")
      setCardLayoutActive({ single: activeClass })
    }
    else if (data == "double") {
      setCardLayout("double")
      setCardLayoutActive({ double: activeClass })
    }
  }

  const gotoTeacherDetailPage = (data) => {
    navigate("/teacher/detailPage", { state: { teacherDetail: data } });
  }

  const gotoLogin = () => {
    setOpen(true)
  }

  const [filters, setFilters] = useState({
    gender: "",
    studyPurpose: "",
    language: [],
    timeZone: [],
    level: "",
    content: "",
  });

  const handleFilterChange = (filter, value) => {

    setFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: value,
    }));
  };

  const handleSearchChange = (newSearchQuery) => {
    setSearchQuery(newSearchQuery);
  };

  return (
    <>
      <div className="teacherMainContainer">
        <div className="FindTeacherHearder">
          <img src={FindTeachHead} alt="Uploading" className="imageFindTeacher" />
          {
            loading ?
              <></>
              :
              <img src={FindTeachHead2} alt="Uploading" className="imageFindTeacher2" />
          }
          <div className="FindTeachHead">
            <div className="teachHead">Find Tutors & Courses</div>
            <div className="teachdetail">Define your search criteria on left, click search button & our matching system would shortlist the available teachers’ courses on the basis of their suitability for your requirements</div>
          </div>
          {/* <img className="imageFindTeacher" src={FindTeachHead} alt="Error" /> */}
        </div>

        <div className="TeachContainer">
          <div className="filters">
            <TeacherSideBar
              onSearchChange={handleSearchChange}
              selectedGender={filters.gender}
              onGenderChange={(value) => handleFilterChange("gender", value)}
              selectedStudyPurpose={filters.studyPurpose}
              onStudyPurposeChange={(value) =>
                handleFilterChange("studyPurpose", value)}
              selectedLanguage={filters.language}
              onLanguageChange={(value) =>
                handleFilterChange("language", value)}
              selectedTimeZone={filters.timeZone}
              onTimeZoneChange={(value) =>
                handleFilterChange("timeZone", value)
              }
            />
          </div>
          <div className="teacherDetailCart">
            <div className="sortBtn">
              <CiBoxList size={26} style={cardLayoutActive.single == "active" ? { color: "#000" } : {}} className="cursor-pointer   text-grey hover:text-yellow" onClick={() => handleView("single", "active")} />
              <IoGridOutline size={22} style={cardLayoutActive.double == "active" ? { color: "#000" } : {}} className="cursor-pointer  text-grey hover:text-yellow" onClick={() => handleView("double", "active")} />
            </div>
            <div className="result">Search Result</div>
            {
              cardLayout === "single" ?
                <>
                  {
                    loading ?
                      // <PreLoader />
                      <div className="skeleton">
                        <Skeleton className="skel" variant="rectangular" />
                        <Skeleton className="skel" variant="rectangular" />
                        <Skeleton className="skel" variant="rectangular" />
                        <Skeleton className="skel" variant="rectangular" />
                      </div>
                      :
                      <>
                        <div className="singleCart">
                          {teachersData?.map((data, i) => {
                            const fullName = `${data.firstName} ${data.lastName}`;
                            console.log('fullName:', fullName); // Check the value of fullName
                            console.log('searchQuery:', searchQuery);

                            // Filter teachers based on gender, study purpose, language, and time zone
                            const filterGender = filters.gender !== "" && data.gender.toLowerCase() !== filters.gender.toLowerCase();
                            const filterStudyPurpose =
                              filters.studyPurpose !== "" &&
                              data.TeacherData?.every(
                                (course) =>
                                  !course.studyPurpose.toLowerCase().includes(filters.studyPurpose.toLowerCase())
                              ) &&
                              !data.TeacherData.some((course) =>
                                filters.studyPurpose.toLowerCase().includes(course.studyPurpose.toLowerCase())
                              );
                            const filterLanguage = filters.language.length > 0 && !filters.language.includes(data.primaryLanguage);
                            const filterTimeZone = filters.timeZone.length > 0 && !filters.timeZone.includes(data.timeZone);

                            // Filter based on search query (full name)
                            const filterSearchQuery = searchQuery && !fullName.toLowerCase().includes(searchQuery.toLowerCase());

                            // Combine all filtering conditions
                            if (filterGender || filterStudyPurpose || filterLanguage || filterTimeZone || filterSearchQuery) {
                              return null; // Skip rendering if any filtering condition is not met
                            }
                            return (
                              <>
                                <>
                                  <div className="carts" key={i}>
                                    <div className="courseView">
                                      {/* <div className="teacherProfile cursor-pointer" onClick={() => gotoTeacherDetailPage(data)}> */}
                                      <div className="teacherProfile cursor-pointer" onClick={() => gotoTeacherDetailPage(data)}>
                                        <img
                                          className="TeacherImg"
                                          src={ImgURL(data?.profileImage)}
                                          alt="Uploading"
                                        />
                                        <div className="teacherNameDesc">
                                          <div className="TeacherName ">{data?.firstName} {data?.lastName}</div>
                                          <div className="detail">{showFullDescription ? data?.bio?.replace(/<[^>]+>/g, '') : shortenDescription(data?.bio?.replace(/<[^>]+>/g, ''), 100)}</div>
                                          {/* <div className="para ratingPara">
                                            <Rate
                                              style={{ fontSize: "12px" }}
                                              tooltips={desc}
                                              disabled
                                              value={data?.rating || 5}
                                            />
                                          </div> */}
                                        </div>
                                        <div className="onlineMain">
                                          <Radio className="online" checked></Radio>
                                        </div>
                                      </div>
                                      <div className="teacherOffers">
                                        <table size="small" className="tableMain">
                                          <thead className="tableHead">
                                            <tr className="tableRow">
                                              <td className="tableh name">Course Name</td>
                                              <td className="tableh">Study Purpose</td>
                                              <td className="tableh lang">Language</td>
                                              <td className="tableh assign">Assignments</td>
                                              <td className="tableh">Cost/Call</td>
                                              <td className="tableh">Video Conference</td>
                                            </tr>
                                          </thead>
                                          <tbody className="tableBody">

                                            <tr className="tableRow" key={i}>
                                              <td className="tabled name">{"Hafiz-e-Quran"}</td>
                                              <td className="tabled">
                                                {"Hafiz-e-Quran"}
                                              </td>
                                              <td className="tabled lang">{data?.primaryLanguage || "-"}</td>
                                              <td className="tabled assign">{"-"}</td>
                                              <td className="tabled">$ {data?.rate || 0} {data?.currency}</td>
                                              <td className="tabled">
                                                {
                                                  UserData?.email ?
                                                    <PrimaryButton2 heading="Call Now" primaryFun={() => gotoTeacherDetailPage(data)} />
                                                    :
                                                    <PrimaryButton2 heading="Call Now" primaryFun={() => gotoLogin()} />
                                                }
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div className="detailView">



                                      {teacherData && teacherData.length > 0 ? (

                                        <div className="detail">
                                          <div className="head">Taught</div>
                                          <div className="para">
                                            {teacherData[i].data.totalUniqueParticipants} <span className="student">Students</span>
                                          </div>
                                        </div>
                                      )
                                        : (
                                          <div><Skeleton></Skeleton></div>
                                        )}
                                      {teacherData && teacherData.length > 0 ? (

                                        <div className="detail">
                                          <div className="head">Conducted</div>
                                          <div className="para">
                                            {teacherData[i].data.totalMeetings}{" "}
                                            <span className="student">Lessons</span>
                                          </div>
                                        </div>
                                      )
                                        : (
                                          <div><Skeleton></Skeleton></div>
                                        )}
                                      {
                                        data?.timezone &&
                                        <div className="detail">
                                          <div className="head">Time Zone</div>
                                          <div className="para">{data?.timezone}</div>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </>
                              </>
                            );
                          })
                          }
                        </div>
                      </>
                  }
                </>
                :
                <div className="doubleCart">
                  {teachersData?.map((data, i) => {
                    // if (
                    //   (filters.gender !== "" && data.gender.toLowerCase() !== filters.gender.toLowerCase()) ||
                    //   (filters.studyPurpose !== "" &&
                    //     data.TeacherData?.every(
                    //       (course) =>
                    //         !course.studyPurpose
                    //           .toLowerCase()
                    //           .includes(filters.studyPurpose.toLowerCase())
                    //     ) &&
                    //     !data.TeacherData.some((course) =>
                    //       filters.studyPurpose
                    //         .toLowerCase()
                    //         .includes(course.studyPurpose.toLowerCase())
                    //     )) ||
                    //   (filters.language.length > 0 &&
                    //     !filters.language.includes(data.primaryLanguage))
                    //   || (filters.timeZone.length > 0 && !filters.timeZone.includes(data.timeZone))
                    // ) {
                    //   return null;
                    // }
                    const fullName = `${data.firstName} ${data.lastName}`;
                    console.log('fullName:', fullName); // Check the value of fullName
                    console.log('searchQuery:', searchQuery);

                    // Filter teachers based on gender, study purpose, language, and time zone
                    const filterGender = filters.gender !== "" && data.gender.toLowerCase() !== filters.gender.toLowerCase();
                    const filterStudyPurpose =
                      filters.studyPurpose !== "" &&
                      data.TeacherData?.every(
                        (course) =>
                          !course.studyPurpose.toLowerCase().includes(filters.studyPurpose.toLowerCase())
                      ) &&
                      !data.TeacherData.some((course) =>
                        filters.studyPurpose.toLowerCase().includes(course.studyPurpose.toLowerCase())
                      );
                    const filterLanguage = filters.language.length > 0 && !filters.language.includes(data.primaryLanguage);
                    const filterTimeZone = filters.timeZone.length > 0 && !filters.timeZone.includes(data.timeZone);

                    // Filter based on search query (full name)
                    const filterSearchQuery = searchQuery && !fullName.toLowerCase().includes(searchQuery.toLowerCase());

                    // Combine all filtering conditions
                    if (filterGender || filterStudyPurpose || filterLanguage || filterTimeZone || filterSearchQuery) {
                      return null; // Skip rendering if any filtering condition is not met
                    }
                    return (
                      <>
                        <div className="doublecarts" key={i}>
                          <div className="doublecourseView">
                            <div className="doubleteacherProfile cursor-pointer" onClick={() => gotoTeacherDetailPage(data)}>
                              <img
                                className="doubleTeacherImg"
                                src={ImgURL(data?.profileImage)}
                                alt="Uploading"
                              />
                              <div className="doubleteacherNameDesc">
                                <div className="doubleTeacherName ">{data?.firstName} {data?.lastName}</div>
                                <div className="doubledetail">{showFullDescription ? data?.bio?.replace(/<[^>]+>/g, '') : shortenDescription(data?.bio?.replace(/<[^>]+>/g, ''), 100)}</div>
                                {/* <div className="para ratingPara">
                                  <Rate
                                    style={{ fontSize: "12px" }}
                                    tooltips={desc}
                                    disabled
                                    value={data?.rating || 4}
                                  />
                                </div> */}
                              </div>
                              <div className="onlineMain">
                                <Radio className="donline" checked></Radio>
                              </div>
                            </div>
                            <div className="doubleteacherOffers">
                              <table size="small" className="dtableMain">
                                <thead className="dtableHead">
                                  <tr className="dtableRow">
                                    <td className="dtableh name">Course Name</td>
                                    <td className="dtableh">Study Purpose</td>
                                    <td className="dtableh lang">Language</td>
                                    <td className="dtableh assign">Assignments</td>
                                    <td className="dtableh">Cost/Call</td>
                                    <td className="dtableh">Video Conference</td>
                                  </tr>
                                </thead>
                                <tbody className="dtableBody">

                                  <tr className="dtableRow" key={i}>
                                    <td className="dtabled name">{"Hafiz-e-Quran"}</td>
                                    <td className="dtabled">
                                      {"Hafiz-e-Quran"}
                                    </td>
                                    <td className="dtabled lang">{data?.primaryLanguage || "English"}</td>
                                    <td className="dtabled assign">{"-"}</td>
                                    <td className="dtabled">$ {data?.rate || 0} {data?.currency}</td>
                                    <td className="dtabled">
                                      {
                                        UserData?.email ?
                                          <PrimaryButton2 heading="Call Now" primaryFun={() => gotoTeacherDetailPage(data)} />
                                          :
                                          <PrimaryButton2 heading="Call Now" primaryFun={() => gotoLogin()} />

                                      }
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                            </div>
                          </div>
                          <div className="ddetailView">


                            {teacherData && teacherData.length > 0 ? (

                              <div className="ddetail">
                                <div className="dhead">Taught</div>
                                <div className="dpara">
                                  {teacherData[i].data.totalUniqueParticipants} <span className="dstudent">Students</span>
                                </div>
                              </div>
                            )
                              : (
                                <div><Skeleton></Skeleton></div>
                              )}
                            {teacherData && teacherData.length > 0 ? (

                              <div className="ddetail">
                                <div className="dhead">Conducted</div>
                                <div className="dpara">
                                  {teacherData[i].data.totalMeetings}{" "}
                                  <span className="dstudent">Lessons</span>
                                </div>
                              </div>
                            )
                              : (
                                <div><Skeleton></Skeleton></div>
                              )}
                            <div className="ddetail">
                              <div className="dhead">Time Zone</div>
                              <div className="dpara">{"Asia/Riyadh"}</div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                  }
                </div>
            }
          </div>
        </div>

      </div>
      <Modal
        title="Login / Register"
        style={{
          top: 20,
        }}
        open={open}
        onOk={handleOk}
        onCancel={gotoBack}
        footer={[
          <div className="mr-5">
            <SecondaryButton
              heading="Register"
              secondaryFun={handleRegister}
            />
          </div>,
          <PrimaryButton heading="Login" primaryFun={handleLogin} />,
        ]}
      >
        <p>To join course you need to Register/ login</p>
      </Modal>
    </>
  );
}
