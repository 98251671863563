import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Calendar Components
import { Calendar, Views, globalizeLocalizer } from "react-big-calendar";
import globalize from "globalize";
import "react-big-calendar/lib/css/react-big-calendar.css";

// Styling
import "./CalendarStudent.scss";
import { GetAllTeacherScheduleAPI } from "API/teacherSchedule";
import { toast } from "react-toastify";

const CalendarStudent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const localizer = globalizeLocalizer(globalize);

  const teacherDetail = location?.state?.teacherDetail;

  //   Calendar Functions
  // States
  const [myEvents, setEvents] = useState([]);

  const handleSelectEvent = useCallback((event) => {
    let currentTime = new Date().getTime();
    if (event?.start?.getTime() < currentTime) return toast.warn("Past Slots not Available")
    if (event?.bookingId) {
      return toast.warning("Slot Already Booked")
    } else {
      navigate("/teacher/detailPage/joiningForm", {
        state: { meetingDetail: event, teacherDetail: teacherDetail },
      });
    }
  }, []);

  const { defaultDate, scrollToTime, views } = useMemo(
    () => ({
      defaultDate: new Date(),
      scrollToTime: new Date(),
      views: ["day"],
    }),
    []
  )

  const gettingTeacherSchedule = async () => {
    let payload = {
      teacherID: teacherDetail?._id,
    };
    let res = await GetAllTeacherScheduleAPI(payload);
    if (res.error != null) {
      toast?.error(res?.error);
    } else {
      let availData = res?.data?.result?.Availibility?.map((data) => ({
        ...data,
        start: new Date(data?.start),
        end: new Date(data?.end)
      }))
      setEvents(availData || [])
    }
  };

  useEffect(() => {
    gettingTeacherSchedule();
  }, []);

  return (
    <>
      <div className="CalendarStudentMain">
        <div className="calendarAll">
          <Calendar
            localizer={localizer}
            events={myEvents}
            onSelectEvent={handleSelectEvent}
            startAccessor="start"
            endAccessor="end"
            defaultDate={defaultDate}
            defaultView={Views.WEEK}
            style={{ height: 700 }}
            resizable
            scrollToTime={scrollToTime}
            step={60}
          />
        </div>
        <div className="calendarDay">
          <Calendar
            localizer={localizer}
            events={myEvents}
            onSelectEvent={handleSelectEvent}
            startAccessor="start"
            endAccessor="end"
            defaultDate={defaultDate}
            defaultView={views.Day}
            style={{ height: 700 }}
            resizable
            views={views}
            view='day'
            scrollToTime={scrollToTime}
            step={60}
          />
        </div>
      </div>
    </>
  );
};

export default CalendarStudent;