import axios from "../AxiosInstance";


const PostSubscriptionAPI = async (email) => {
    let resolved = {
        error: null,
        data: null
    }

    try {
        let res = await axios({
            url: "/subscribe",
            method: "POST",
            data: email,
            // headers: AuthTokenGen()
        })
        resolved.data = res.data
    } catch (err) {
        if (err && err.response && err?.response?.data) {
            resolved.error = err.response.data
        } else {
            resolved.error = "Something went Wrong"
        }
    }
    return resolved;
}

export { PostSubscriptionAPI};