import { createSlice } from "@reduxjs/toolkit";

const initialState = null;


export const TagsData = createSlice({
    name: "TagsData",
    initialState,
    reducers: {
        setTagsData: (state, action) => {
            return action.payload
        }
    }

})

export default TagsData.reducer;
export const TagsDataActions = TagsData.actions;