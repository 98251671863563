import React from 'react'

// Componets :
import PrimaryButton from '../../../Components/Atoms/PrimaryButton/PrimaryButton'

// Assets | ICONS :
import fImage from '../../../Assets/Images/t-icon.svg'

//CSS
import './Teacher.scss'
import { useNavigate } from 'react-router-dom'




const Teachers = () => {
    const navigate = useNavigate()
    
  const handleOpen = () => {
    navigate('/teacher')
  }

    return (
        <div className='teacher-container'>
            <div className="t-container">
                <div className="heading">Learn From Online Teachers</div>
                <div className="para">Learn From Online Teachers Learn From Online Teachers</div>
                <div className="t-btn">
                    <PrimaryButton heading="Join Us" primaryFun={() => handleOpen()} />
                </div>

            </div>
        </div>
    )
}

export default Teachers 