import axios from "../AxiosInstance";

// Helper :
import AuthTokenGen from "../Utils/AuthTokenGen"





const FreeTransactionUser = async (formData) => {
    let resolved = {
        error: null,
        data: null
    }

    try {
        let res = await axios({
            url: '/transaction/free',
            method: "POST",
            data: formData,
            headers: AuthTokenGen()
        })
        resolved.data = res.data
    } catch (err) {
        if (err && err.response && err?.response?.data?.message) {
            resolved.error = err.response.data.message
        } else {
            resolved.error = "Something went Wrong"
        }
    }
    return resolved
}

const CreateTransactionAPI = async (formValues) => {
    let resolved = {
        error: null,
        data: null
    }

    try {
        let res = await axios({
            url: "/transaction",
            method: "POST",
            data: formValues,
            headers: AuthTokenGen()
        })
        resolved.data = res.data
    } catch (err) {
        if (err && err.response && err?.response?.data) {
            resolved.error = err.response.data
        } else {
            resolved.error = "Something went Wrong"
        }
    }
    return resolved;
}

const PostTransactionAPI = async (formValues) => {
    let resolved = {
        error: null,
        data: null
    }

    try {
        let res = await axios({
            url: "/transaction/pay",
            method: "POST",
            data: formValues,
            headers: AuthTokenGen()
        })
        resolved.data = res.data
    } catch (err) {
        if (err && err.response && err?.response?.data) {
            resolved.error = err.response.data
        } else {
            resolved.error = "Something went Wrong"
        }
    }
    return resolved;
}


const PostDonationAPI = async (formValues) => {
    let resolved = {
        error: null,
        data: null
    }

    try {
        let res = await axios({
            url: "/transaction/donation",
            method: "POST",
            data: formValues,
            // headers: AuthTokenGen()
        })
        resolved.data = res.data
    } catch (err) {
        if (err && err.response && err?.response?.data) {
            resolved.error = err.response.data
        } else {
            resolved.error = "Something went Wrong"
        }
    }
    return resolved;
}

export { CreateTransactionAPI, PostTransactionAPI, FreeTransactionUser, PostDonationAPI };