import { useEffect, useState } from "react";
import { Select } from "antd";
import { BiUser } from "react-icons/bi";
import { BsTelephone } from "react-icons/bs";
import { TfiEmail } from "react-icons/tfi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { RiFlag2Line } from "react-icons/ri";
import { FaCity } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../Atoms/PrimaryButton/PrimaryButton";
import { useSelector } from "react-redux";
import { CreateTransactionAPI } from "../../../API/transaction";
import { toast } from "react-toastify";
import { countries } from "./countries";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./CardInfo.scss";
import MessagePrompt from "Components/MessagePrompt/MessagePrompt";

const CardForm = () => {

  const navigate = new useNavigate();
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    country: "",
    city: "",
    postalCode: "",
    contactNumber: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formError, setFormError] = useState({
    firstName: null,
    lastName: null,
    email: null,
    address: null,
    country: null,
    city: null,
    postalCode: null,
    contactNumber: null,
  });
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState(false)
  const [msgType, setMsgType] = useState(null)
  const [AllreadyEmpty, setAllreadyEmpty] = useState(false)
  const showModal = () => {
    setIsModalOpen(true);
  }

  const [loading, setLoading] = useState(false)
  const userData = useSelector(state => state?.UserData)
  const source = useSelector((state) => state?.CartsData);
  const sourceId = [];
  source?.map((data) => {
    sourceId.push(data?._id);
  });

  const handleFormChange = (event) => {
    let { name, value } = event.target;

    switch (name) {
      case "firstName":
        if (value.length <= 0) {
          setFormError({
            ...formError,
            firstName: "A first name is requried.",
          });
        } else if (!/^[A-Za-z]*$/.test(value)) {
          setFormError({
            ...formError,
            firstName:
              "Invalid format. Please enter a first name without numbers or special characters.",
          });
        } else {
          setFormError({
            ...formError,
            firstName: null,
          });
        }
        break;
      case "lastName":
        if (value.length <= 0) {
          setFormError({
            ...formError,
            lastName: "A last name is requried.",
          });
        } else if (!/^[A-Za-z]*$/.test(value)) {
          setFormError({
            ...formError,
            lastName:
              "Invalid format. Please enter a last name without numbers or special characters.",
          });
        } else {
          setFormError({
            ...formError,
            lastName: null,
          });
        }
        break;
      case "email":
        if (
          !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            value
          )
        ) {
          setFormError({
            ...formError,
            email: "Invalid email address. Please enter a valid email address.",
          });
        } else {
          setFormError({
            ...formError,
            email: null,
          });
        }
        break;
      case "address":
        if (value.length <= 0) {
          setFormError({
            ...formError,
            address: "Address is required.",
          });
        } else {
          setFormError({
            ...formError,
            address: null,
          });
        }
        break;
      case "country":
        if (value.length <= 0) {
          setFormError({
            ...formError,
            country: "Country is required.",
          });
        } else {
          setFormError({
            ...formError,
            country: null,
          });
        }
        break;
      case "city":
        if (value.length <= 0) {
          setFormError({
            ...formError,
            city: "City is required.",
          });
        } else {
          setFormError({
            ...formError,
            city: null,
          });
        }
        break;
      case "postalCode":
        if (value.length <= 0) {
          setFormError({
            ...formError,
            postalCode: "Postal code is required.",
          });
        } else if (value.length > 5) {
          setFormError({
            ...formError,
            postalCode: "Postal code should not exceed 5 digits.",
          });
        } else if (!/^[0-9]*$/.test(value)) {
          setFormError({
            ...formError,
            contactNumber:
              "Invalid format. Please enter a phone number without letters or special characters.",
          });
        } else {
          setFormError({
            ...formError,
            postalCode: null,
          });
        }
        break;
      case "contactNumber":
        if (value.length <= 0) {
          setFormError({
            ...formError,
            contactNumber: "Contact number is required.",
          });
        } else {
          setFormError({
            ...formError,
            contactNumber: null,
          });
        }
        break;
      default:
        break;
    }
    setFormValues({
      ...formValues,
      [name]: value,
    });
    if (name === "country") {
      setSelectedCountry(value);
      setSelectedCity("");
    } else if (name === "city") {
      setSelectedCity(value);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const payment = async () => {
    setLoading(true)
    if (
      !formValues.firstName ||
      !formValues.lastName ||
      !formValues.email ||
      !formValues.address ||
      !formValues.country ||
      !formValues.city ||
      !formValues.postalCode
    ) {
      setFormError({
        firstName: formValues.firstName ? null : "First name is required.",
        lastName: formValues.lastName ? null : "Last name is required.",
        email: formValues.email ? null : "Email is required.",
        address: formValues.address ? null : "Address is required.",
        country: formValues.country ? null : "Country is required.",
        city: formValues.city ? null : "City is required.",
        postalCode: formValues.postalCode ? null : "Postal code is required.",
      });

      // Display an error message or handle it as desired
      // toast?.error("Please fill in all the required fields.");
      setMsgType("danger")
      setAllreadyEmpty(true)
      showModal()
      setTimeout(() => {
        setIsModalOpen(false)
        setAllreadyEmpty(false)
      }, 1200);
      setLoading(false)
      return;
    }
    const data = {
      sources: sourceId,
      orderType: "book",
      shippingDetails: formValues,
    };
    const res = await CreateTransactionAPI(data);
    if (res.error != null) {
      toast.error(res?.error.message);
    } else {
      navigate("/libarary/detail/payment", {
        state: { userInfo: res?.data?.result },
      });
    }
    setLoading(false)
  };


  useEffect(() => {
    if (userData == null || userData == undefined) {
      setFormValues({ ...formValues, email: "", firstName: "", lastName: "" })
    } else {
      setFormValues({ ...formValues, email: userData?.email, firstName: userData?.firstName, lastName: userData?.lastName, country: userData.nationality })
    }

  }, [])

  return (
    <>
      <section id="contactInfoSec" className=" DBlock py-16 px-6">
        <div className="max-w-7xl mx-auto py-28">
          <p className="font-semibold text-base px-5 pt-7">Contact Info</p>
          <div
            onSubmit={handleSubmit}
            className="max-w-7xl grid grid-cols-1 md:grid-cols-2  gap-4 sm:gap-10 py-12"
          >
            <div className="col-span-2 md:col-span-1">
              <div className="relative">
                <span className="absolute bottom-6 pl-10 flex items-center">
                  <BiUser className="h-5 w-5 text-gray-400" />
                </span>
                <input
                  onChange={handleFormChange}
                  required
                  type="text"
                  name="firstName"
                  value={formValues?.firstName}
                  id="first-name"
                  placeholder="First Name"
                  autoComplete="firstName"
                  className="block w-full rounded-md bg-white  border-4 border-transparent px-3 py-4 pl-16 shadow"
                />

              </div>
              {formError.firstName && (
                <div className=" pt-2 text-sm text-red-500">
                  {formError.firstName}
                </div>
              )}
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="relative">
                <span className="absolute bottom-6 pl-10 flex items-center">
                  <BiUser className="h-5 w-5 text-gray-400" />
                </span>
                <input
                  onChange={handleFormChange}
                  required
                  type="text"
                  name="lastName"
                  value={formValues?.lastName}
                  id="first-name"
                  placeholder="Last Name"
                  autoComplete="lastName"
                  className="block w-full rounded-md bg-white shadow   border-4 border-transparent px-3 py-4 pl-16 "
                />
              </div>
              {formError.lastName && (
                <div className="h-5 pt-2 pb-7 text-sm text-red-500">
                  {formError.lastName}
                </div>
              )}
            </div>
            <div className="col-span-2 block w-full">
              <div className="relative">
                <span className="absolute bottom-6 pl-10 flex items-center">
                  <TfiEmail className="h-5 w-5 text-gray-400" />
                </span>
                <input
                  onChange={handleFormChange}
                  required
                  type="text"
                  name="email"
                  value={formValues?.email}
                  id="email"
                  placeholder="Email"
                  autoComplete="email"
                  className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-4 pl-16 "
                />
              </div>
              {formError.email && (
                <div className="h-5 pt-2 pb-7 text-sm text-red-500">
                  {formError.email}
                </div>
              )}
            </div>
            <div className="col-span-2 block w-full">
              <div className="relative">
                <span className="absolute bottom-6 pl-10 flex items-center">
                  <HiOutlineLocationMarker className="h-5 w-5 text-gray-400" />
                </span>
                <input
                  onChange={handleFormChange}
                  required
                  type="text"
                  name="address"
                  value={formValues?.address}
                  id="address"
                  placeholder="Address"
                  autoComplete="address"
                  className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-4 pl-16 "
                />
              </div>
              {formError.address && (
                <div className="h-5 pt-2 text-sm text-red-500">
                  {formError.address}
                </div>
              )}
            </div>
            <div className="col-span-2 block w-full">
              <div className="relative">
                <span className="absolute bottom-4 pl-10 flex items-center">
                  <RiFlag2Line className="h-5 w-5 text-gray-400" />
                </span>
                <select
                  onChange={handleFormChange}
                  id="country"
                  name="country"
                  value={selectedCountry}
                  className="custom-select block w-full text-gray-400 rounded-md bg-white shadow  border-1 px-3 py-4 pl-16 "
                >
                  <option selected className="font-medium">
                    Country
                  </option>
                  {countries.map((country) => (
                    <option key={country.name} value={country.name} >
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
              {formError.country && (
                <div className="h-5 pt-2 text-sm text-red-500">
                  {formError.country}
                </div>
              )}
            </div>

            <div className="col-span-2 md:col-span-1">
              <div className="relative">
                <span className="absolute bottom-5 pl-10 flex items-center">
                  <FaCity className="h-5 w-5 text-gray-400" />
                </span>
                <select
                  onChange={handleFormChange}
                  id="city"
                  name="city"
                  value={selectedCity}
                  disabled={!selectedCountry}
                  className="block w-full text-gray-400 rounded-md bg-white shadow border-4 border-transparent px-3 py-4 pl-16 "
                >
                  <option value="" disabled>
                    City
                  </option>
                  {selectedCountry &&
                    countries
                      .find((country) => country.name === selectedCountry)
                      .cities.map((city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      ))}
                </select>
              </div>
              {formError.city && (
                <div className="h-5 pt-2 text-sm text-red-500">
                  {formError.city}
                </div>
              )}
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="relative">
                <span className="absolute bottom-6 pl-10 flex items-center">
                  <TfiEmail className="h-5 w-5 text-gray-400" />
                </span>
                <input
                  onChange={handleFormChange}
                  required
                  type="text"
                  name="postalCode"
                  value={formValues?.postalCode}
                  id="postalCode"
                  placeholder="Postal Code"
                  autoComplete="postalCode"
                  className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-4 pl-16 "
                />
              </div>
              {formError.postalCode && (
                <div className="h-5 pt-2 text-sm text-red-500">
                  {formError.postalCode}
                </div>
              )}
            </div>

            <div className="col-span-2 block w-full">
              <div className="relative">
                <span className="absolute bottom-6 pl-10 flex items-center">
                  <BsTelephone className="h-5 w-5 text-gray-400" />
                </span>
                <PhoneInput
                  country={"pk"}
                  value={formValues.contactNumber}
                  onChange={(value) =>
                    setFormValues({
                      ...formValues,
                      contactNumber: value,
                    })
                  }
                  inputClass="block w-full rounded-md bg-white shadow border-4 border-transparent px-3 py-4 pl-16 "
                />
              </div>
              {formError.contactNumber && (
                <div className="h-5 pt-2 text-sm text-red-500">
                  {formError.contactNumber}
                </div>
              )}
            </div>
          </div>
          <div className="justify-center flex">
            <PrimaryButton heading="Continue" primaryFun={payment} loading={loading} />
          </div>
        </div>
      </section>
      {isModalOpen && <MessagePrompt type={msgType} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} message={message ? "Item Remove Successfully" : AllreadyEmpty ? "Please fill in all the required fields." : "Items Remove Successfully"} />}

    </>
  );
};

export default CardForm;
