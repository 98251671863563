import React, { useEffect, useState } from "react";
import "./AllBlog.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoGridOutline } from "react-icons/io5";
import classNames from "classnames";
import { Select, Space } from "antd";
import { CiBoxList } from "react-icons/ci";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import ReactPaginate from "react-paginate";
import SideBar from '../../Components/SideBar/SideBar';
import { Rate } from "antd"

// import image Url basic
import ImgURLGEN from '../../../../Utils/ImgUrlGen'

// API's
import { GetAllBlogsAPI } from "../../../../API/blogs";
import { BlogsDataActions } from "../../../../Redux/Slice/Blogs";
import { CategoriesDataActions } from "Redux/Slice/Category";
import { TagsDataActions } from "Redux/Slice/Tag";
import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";
import { AllCategoriesAPI, AllTagsAPI } from "API/categoryTag";
import { Sort } from "iconsax-react";
import { BsGrid3X3Gap } from "react-icons/bs";

const MyCard = () => {
  let Navigate = useNavigate();
  const Dispatch = useDispatch()

  const [cardLayout, setCardLayout] = useState("single");
  const [cardLayoutActive, setCardLayoutActive] = useState({
    single: "active",
    double: null,
    tripple: null
  });
  const [currentPage, setCurrentPage] = useState(null);
  const [sortOrder, setSortOrder] = useState("recent");
  const [showFullDescription, setshowFullDescription] = useState(false);
  const [filteredData, setFilteredData] = useState(null)

  const [categories, setCategories] = useState(null)
  const [tags, setTags] = useState(null)

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);

  const BlogsData = useSelector((state) => state?.BlogsData);
  const CategoriesData = useSelector((state) => state?.CategoriesData);
  const TagsData = useSelector((state) => state?.TagsData);
  const [searchInput, setSearchInput] = useState("");

  const [loading, setLoading] = useState(false)



  const handleSorting = (sortVal, statusActive) => {
    setCardLayout(sortVal)
    if (sortVal == "single") {
      setCardLayoutActive({ single: statusActive })

    }
    else if (sortVal == "double") {
      setCardLayoutActive({ double: statusActive })

    }
    else if (sortVal == "triple") {
      setCardLayoutActive({ tripple: statusActive })

    }
  }

  const handleSearchInputChange = (input) => {
    setSearchInput(input);
  };
  const selectingCategory = (name) => {
    if (selectedCategory == name) {
      setSelectedCategory(null)
    } else {
      setSelectedCategory(name)
    }
  }
  const selectingTag = (name) => {
    if (selectedTag == name) {
      setSelectedTag(null)
    } else {
      setSelectedTag(name)
    }
  }



  const gettingAllBlogs = async () => {
    setLoading(true)
    const res = await GetAllBlogsAPI()
    if (res.error != null) {
      toast.error(res?.error)
    } else {
      let blogData = res?.data?.result || []
      Dispatch(BlogsDataActions?.setBlogsData(blogData))
    }
    setLoading(false)
  }
  const gettingAllCategories = async () => {
    setLoading(true)
    const res = await AllCategoriesAPI()
    if (res.error != null) {
      toast.error(res?.error)
    } else {
      let categoryData = res?.data?.result || []
      Dispatch(CategoriesDataActions?.setCategoriesData(categoryData))
    }
    setLoading(false)
  }
  const gettingAllTags = async () => {
    setLoading(true)
    const res = await AllTagsAPI()
    if (res.error != null) {
      toast.error(res?.error)
    } else {
      let tagsData = res?.data?.result || []
      Dispatch(TagsDataActions?.setTagsData(tagsData))
    }
    setLoading(false)
  }
  useEffect(() => {
    gettingAllBlogs()
    gettingAllCategories()
    gettingAllTags()
  }, [])


  let NavigateBlog = (id) => {
    Navigate("/blogs/blog", { state: { blogID: id } });
  };



  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };



  const cardClassNames = classNames("grid grid-cols-1 gap-3", {
    "grid md:grid-cols-2 gap-3 ": cardLayout === "double",
    "grid md:grid-cols-3": cardLayout === "triple",
  });


  const sortBlogs = (data) => {
    if (!Array.isArray(data)) {
      return [];
    }
    const sortedData = [...data];

    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a.title.localeCompare(b.title);
      } else if (sortOrder === "desc") {
        return b.title.localeCompare(a.title);
      } else if (sortOrder === "recent") {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA;
      }
      return 0;
    });

    return sortedData;
  };

  const sortedBlogs = sortBlogs(filteredData);

  const cardsPerPage = 6;
  const startCardIndex = currentPage * cardsPerPage;
  const endCardIndex = startCardIndex + cardsPerPage;
  const blogsToShow = sortedBlogs.slice(startCardIndex, endCardIndex);


  const handleSortOrderChange = (value) => {
    setSortOrder(value);
  };
  const filteredBlogs =
    blogsToShow &&
    blogsToShow.filter((blog) =>
      blog.title.toLowerCase().includes(searchInput.toLowerCase())
    );

  const shortenDescription = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    } else {
      return text;
    }
  };

  const calculatingCategoriesTags = async () => {
    let categoryObject = {}
    let tagObject = {}

    let Process = BlogsData.map(async blog => {
      let categories = await blog?.categories.map(cat => {
        categoryObject = {
          ...categoryObject,
          [cat.name]: categoryObject[cat.name] ? Number(categoryObject[cat.name]) + 1 : 1
        }
      });
      let tags = await blog?.tags.map(tag => {
        tagObject = {
          ...tagObject,
          [tag.name]: tagObject[tag.name] ? Number(tagObject[tag.name]) + 1 : 1
        }
      });

    });

    await Promise.all(Process);

    setCategories(categoryObject);
    setTags(tagObject);
  }
  useEffect(() => {

    if (!BlogsData && !Array.isArray(BlogsData)) return
    calculatingCategoriesTags()

  }, [BlogsData]);

  useEffect(() => {
    if (!BlogsData && !Array.isArray(BlogsData)) return
    let filterBlogs = []
    if (selectedCategory && !selectedTag) {
      BlogsData?.map(blog => {
        let categories = blog?.categories?.map(cat => cat.name)
        if (categories?.includes(selectedCategory)) {
          filterBlogs.push(blog)
        }
      })
    }

    else if (selectedTag && !selectedCategory) {
      BlogsData?.map(blog => {
        let selectedTagBySearch = blog?.tags?.map(tag => tag.name)
        if (selectedTagBySearch?.includes(selectedTag)) {
          filterBlogs.push(blog)
        }
      })
    }
    else if (selectedTag && selectedCategory) {
      BlogsData?.map(blog => {
        let selectedTagBySearch = blog?.tags?.map(tag => tag.name)
        let categories = blog?.categories?.map(cat => cat.name)
        if (selectedTagBySearch?.includes(selectedTag) && categories?.includes(selectedCategory)) {
          filterBlogs.push(blog)
        }
      })
    }
    else {
      filterBlogs = BlogsData
    }
    setFilteredData(filterBlogs)
  }, [BlogsData, selectedCategory, selectedTag])

  return (
    <>
      <div className="containerAllBlogs">
        <div className="AllBlogs">
          <div className="sidebar">

            <SideBar onSearchInputChange={handleSearchInputChange} tags={tags} categories={categories} selectedCategory={selectedCategory} selectedTag={selectedTag} selectingCategory={selectingCategory} selectingTag={selectingTag} />
          </div>
          <div className="flex-wrap  justify-center p-5">
            <div className="flex justify-end items-center pb-3 gap-4">
              <div className="mr-3 flex items-center justify-center font-semibold">Sort By : </div>

              <div className="">
                <Space wrap>
                  <Select
                    id="sortOrder"
                    name="sortOrder"
                    value={sortOrder}
                    style={{ width: 150 }}
                    suffixIcon={<Sort color="#1f1f1f" />}
                    className="selectorMain"
                    popupClassName="customSelector"
                    onChange={handleSortOrderChange}
                    options={[
                      { value: "asc", label: "Ascending " },
                      { value: "desc", label: "Descending" },
                      { value: "recent", label: "Most recent" },
                    ]}
                  />
                </Space>
              </div>
              <CiBoxList size={26} style={cardLayoutActive.single == "active" ? { color: "#000" } : {}} className="cursor-pointer   text-grey hover:text-yellow" onClick={() => handleSorting("single", "active")} />
              <IoGridOutline size={22} style={cardLayoutActive.double == "active" ? { color: "#000" } : {}} className="cursor-pointer  text-grey hover:text-yellow" onClick={() => handleSorting("double", "active")} />
              <BsGrid3X3Gap size={22} style={cardLayoutActive.tripple == "active" ? { color: "#000" } : {}} className="cursor-pointer  text-grey hover:text-yellow" onClick={() => handleSorting("triple", "active")} />
            </div>
            {
              loading ?
                <div className="skeleton">
                  <Skeleton className="skel" variant="rectangular" />
                  <Skeleton className="skel" variant="rectangular" />
                  <Skeleton className="skel" variant="rectangular" />
                  <Skeleton className="skel" variant="rectangular" />
                </div>
                // <PreLoader />
                :
                <div className="relative h-full py-10">
                  <div className="flex-grow ">
                    <div className={cardClassNames}>
                      {filteredBlogs?.length > 0 ? filteredBlogs?.map((blog) => {
                        let allRatings = blog?.review?.map(r => r?.value);
                        let rating = 0;
                        if (Array.isArray(allRatings) && allRatings.length >= 1) {
                          rating = allRatings.reduce((x, y) => x + y);
                          rating = rating / allRatings.length;
                        }
                        return (
                          <>
                            <div>
                              {cardLayout === "single" ? (
                                <>
                                  <div
                                    className="blog-page"
                                    onClick={() => NavigateBlog(blog?._id)}
                                  >
                                    <div

                                      className="singleBlogPageLayout flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-3xl xl:max-w-none hover:bg-lightPink"
                                    >
                                      <img
                                        className="img object-fill w-full  h-60  md:w-60 rounded-lg"
                                        src={ImgURLGEN(blog?.image)}
                                        alt=""
                                      />
                                      <div className="flex flex-col justify-between blogDetailContainer">
                                        <span className=" text-[12px] leading-6 font-normal text-yellow ">
                                          {new Date(blog?.createdAt).toLocaleDateString('en-US', {
                                            day: 'numeric',
                                            month: 'long',
                                            year: 'numeric',
                                          })}
                                        </span>
                                        <h5 className="blogTitle">
                                          {blog?.title}
                                        </h5>
                                        <ul className="blogStarts ">
                                          <li className="ratingValue">{rating}</li>
                                          <Rate className="starts" value={rating} />
                                        </ul>
                                        <p className=" blog-content">
                                          {showFullDescription ? blog?.detail?.replace(/<[^>]+>/g, '') : shortenDescription(blog?.detail?.replace(/<[^>]+>/g, ''), 200)}
                                        </p>
                                        <div className="author-data pt-[12px]">
                                          <ul className="author-list flex justify-between items-center">
                                            <li className="flex">
                                              {/* <span>By </span> */}

                                              <img
                                                className="avatar h-5 w-5 rounded-full mr-2"
                                                src={(ImgURLGEN(blog?.auther?.profileImage))}
                                                height="18"
                                                width="18"
                                              />

                                              {/* <a href="/" rel="author"> */}
                                              <span className=" profileData">
                                                <span className="fn ">{`${blog?.auther?.firstName} ${blog?.auther?.lastName}`}</span>
                                              </span>
                                              {/* </a> */}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (

                                <>
                                  <div className="my-card hover:bg-lightPink md:max-w-sm xl:max-w-none" key={blog._id}
                                    onClick={() => NavigateBlog(blog?._id)}
                                  >
                                    <div className="image" >
                                      <img
                                        src={ImgURLGEN(blog?.image)}
                                        alt="Card Image"
                                      />
                                    </div>
                                    <div className="content">
                                      <h2 className="title  text-yellow ">
                                        {shortenDescription(blog?.title?.replace(/<[^>]+>/g, ''), 20)}
                                      </h2>
                                      <ul className="flex items-end justify-center ">
                                        <li className="font-bold mr-2 ">{rating}</li>
                                        <Rate value={rating} />
                                      </ul>

                                      <p className="details">{showFullDescription ? blog?.detail?.replace(/<[^>]+>/g, '') : shortenDescription(blog?.detail?.replace(/<[^>]+>/g, ''), 150)}</p>

                                      <div className="author-data ">
                                        <ul className="author-list">
                                          <li className="flex items-center">
                                            <img
                                              className="avatar"
                                              src={(ImgURLGEN(blog?.auther?.profileImage))}
                                              height="18"
                                              width="18"
                                            />

                                            <a href="/" rel="author">
                                              <span className="vcard author author_name">
                                                <span className="fn">{`${blog?.auther?.firstName} ${blog?.auther?.lastName}`}</span>
                                              </span>
                                            </a>
                                          </li>

                                          <span className="pl-2 text-sm font-medium text-gray-600 dark:text-white">
                                            {new Date(blog?.createdAt).toLocaleDateString('en-US', {
                                              day: 'numeric',
                                              month: 'long',
                                              year: 'numeric',
                                            })}
                                          </span>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </>

                              )}
                            </div>
                          </>
                        );
                      }) :
                        <div className="text-2xl font-semibold block w-full mt-4 m-auto text-center">Nothing to show</div>

                      }
                    </div>
                  </div>
                  <div className="bottom-16 w-full py-5 items-center " style={filteredBlogs?.length <= 0 ? { display: "none" } : {}}>
                    <ReactPaginate
                      pageCount={Math.ceil(sortedBlogs.length / cardsPerPage)}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={6}
                      previousLabel={<MdKeyboardArrowLeft size={"30px"} />}
                      nextLabel={<MdKeyboardArrowRight size={"30px"} />}
                      containerClassName="flex justify-center space-x-1 items-center"
                      pageClassName="px-4 py-2 text-gray-700  rounded-full"
                      activeLinkClassName="bg-yellow text-white px-3 py-2 rounded-full "
                      previousClassName="px-3 py-2 text-gray-900 font-bold hover:bg-gray-200 "
                      nextClassName="px-3 py-2 text-gray-900 font-bold hover:bg-gray-200 "
                      onPageChange={handlePageChange}
                      forcePage={currentPage}
                    />
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default MyCard;
