import React from 'react';
import { FaFacebookF } from 'react-icons/fa';
import { RiInstagramFill } from 'react-icons/ri';
import { FaTwitter } from 'react-icons/fa';
import FooterLogo from '../../Assets/Images/f-logo.svg';
import { ReactComponent as Location } from '../../Assets/Images/f-locations.svg';
import { ReactComponent as Mail } from '../../Assets/Images/f-mails.svg';
import { ReactComponent as Phone } from '../../Assets/Images/f-phones.svg';
import { ReactComponent as CopyRight } from '../../Assets/Images/f-copyright.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import "./Footer.scss"
const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="bg-gray-900 text-white py-8 md:py-10">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
        {/* Footer Bio */}
        <div className="md:col-span-1 text-center ">
          <div className="mb-4">
            <img src={FooterLogo} alt="" className="w-36 md:max-w-none mx-auto md:w-full" />
          </div>
          <div className="text-sm font-light mb-4">
            Seeking Of Knowledge Is A Duty Of Every Muslim
          </div>
          <div className="flex space-x-4 justify-center ">
            <div className="rounded-full p-2 bg-blue-500 hover:bg-blue-600 cursor-pointer">
              <FaFacebookF className="text-white text-lg" />
            </div>
            <div className="rounded-full p-2 bg-pink-500 hover:bg-pink-600 cursor-pointer">
              <RiInstagramFill className="text-white text-lg" />
            </div>
            <div className="rounded-full p-2 bg-blue-400 hover:bg-blue-500 cursor-pointer">
              <FaTwitter className="text-white text-lg" />
            </div>
          </div>
        </div>

        {/* Contact Us */}
        <div className="md:col-span-1 mt-6 md:pt-5">
          <div className="font-semibold text-lg mb-4 md:ms-16 text-yellow ms-10">Contact Us</div>
          <div className="space-y-4 text-center md:text-left">
            <div className="flex items-center space-x-2 justify-start ms-10 ">
              <Phone className="w-5 h-5 fill-current text-gray-300" />
              <div>+000 00 0000</div>
            </div>
            <div className="flex items-center space-x-2 justify-start ms-10">
              <Location className="w-5 h-5 fill-current text-gray-300" />
              <div>City abc home town, xyz</div>
            </div>
            <div className="flex items-center space-x-2 justify-start ms-10">
              <Mail className="w-5 h-5 fill-current text-gray-300" />
              <div>Name@gmail.com</div>
            </div>
          </div>
        </div>

        {/* Services */}
        <div className="md:col-span-1 mt-6 md:pt-5">
          <div className="font-semibold text-lg mb-4  text-yellow ms-10 ">Services</div>
          <div className="space-y-4 items-center justify-start ms-10">
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate('/teacher');
              }}
            >
              Find Teachers
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate('/library');
              }}
            >
              Library
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate('/teacher');
              }}
            >
              Courses
            </div>
          </div>
        </div>

        {/* Company */}
        <div className="md:col-span-1 mt-6 md:pt-5">
          <div className="font-semibold text-lg mb-4 ms-10 text-yellow ">Company</div>
          <div className="space-y-4 ms-10  ">
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate('/about');
              }}
            >
              About
            </div>

            <div
              className="cursor-pointer"
              onClick={() => {
                navigate('/blogs');
              }}
            >
              Blogs
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate('/contact');
              }}
            >
              Contact Us
            </div>
          </div>
        </div>
      </div>

      {/* Copyright */}
      <div className="flex items-start justify-center mt-8 copyRightsPara">
        <CopyRight className="w-6 h-6 fill-current text-gray-300 text-start" />
        <div className="text-sm font-light">
          Copyright 2024 | Powered by FTS. All Right Reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
