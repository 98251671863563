import axios from "axios";



let baseURL, dashboardURL

if (process.env.REACT_APP_ENV == "development") {
    baseURL = process.env.REACT_APP_BASE_URL_DEVELOPMENT
    dashboardURL = process.env.REACT_APP_DASHBOARD_URL_DEVELOPMENT
} else if (process.env.REACT_APP_ENV == "staggingSub") {
    baseURL = process.env.REACT_APP_BASE_URL_STAGGING_SUB
    dashboardURL = process.env.REACT_APP_DASHBOARD_URL_STAGGING
} else {
    baseURL = process.env.REACT_APP_BASE_URL_LIVE
    dashboardURL = process.env.REACT_APP_DASHBOARD_URL_LIVE
}

const Instance = axios.create({
    baseURL: `${baseURL}/api`
});
window.location.CustomURL = `${baseURL}/static`
window.location.DashboardURL = `${dashboardURL}`

export default Instance;