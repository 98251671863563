import creditCard from "../../../../Assets/Images/creditcard.png";
import { BsCreditCard2Front, BsCalendar4Event } from "react-icons/bs";
import { TfiLock } from "react-icons/tfi";
import { useLocation } from "react-router-dom";
import { PostBookingCallApi } from "../../../../API/student";
import { useState } from "react";
import { toast } from "react-toastify";
import { BiUser } from "react-icons/bi";
import { TfiEmail } from "react-icons/tfi";
import "./StudentPaymentPage.scss";
import PrimaryButton from "../../../../Components/Atoms/PrimaryButton/PrimaryButton";
import { RiVisaFill, RiMastercardFill } from "react-icons/ri"
import { SiAmericanexpress } from "react-icons/si"
import { FaCcDinersClub, FaCcDiscover } from "react-icons/fa"
import { getCreditCardNameByNumber } from 'creditcard.js';


const StudentPaymentPage = () => {


  const location = new useLocation();



  const teacherDetail = location?.state?.teacherDetail;


  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    cardNumber: "",
    expMonth: "",
    expYear: "",
    cvc: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    cardNumber: null,
    expMonth: null,
    expYear: null,
    cvc: null,
  });

  const [loading, setLoading] = useState(false)

  const handleCardNumberInput = (e) => {
    const { value } = e.target;

    if (value.length > 16) {
      e.target.value = value.slice(0, 16); // Truncate the input value to 16 digits
    }
  };

  const handleMonthInput = (e) => {
    const { value } = e.target;

    if (value.length > 2) {
      e.target.value = value.slice(0, 2); // Truncate the input value to 2 digits
    }
  };

  const handleYearInput = (e) => {
    const { value } = e.target;

    if (value.length > 4) {
      e.target.value = value.slice(0, 4); // Truncate the input value to 4 digits
    }
  };

  const handleCvcInput = (e) => {
    const { value } = e.target;

    if (value.length > 3) {
      e.target.value = value.slice(0, 3); // Truncate the input value to 3 digits
    }
  };


  const handleFormChange = (e) => {
    const { name, value } = e.target;
    const numericRegex = /^\d+$/; // Regex pattern to match only numbers

    switch (name) {
      case "firstName":
        if (value.length <= 0) {
          setFormErrors({
            ...formErrors,
            firstName: "A first Name is requried."
          })

        } else {
          setFormErrors({
            ...formErrors,
            firstName: null
          })
        }
        break;

      case "lastName":
        if (value.length <= 0) {
          setFormErrors({
            ...formErrors,
            lastName: "A lastName is requried."
          })

        } else {
          setFormErrors({
            ...formErrors,
            lastName: null
          })
        }
        break;

      case "email":
        if (value.length <= 0) {
          setFormErrors({
            ...formErrors,
            email: "A email is requried."
          })

        } else {
          setFormErrors({
            ...formErrors,
            email: null
          })
        }
        break;

      case "cardNumber":
        if (value.length <= 0) {
          setFormErrors({
            ...formErrors,
            cardNumber: "Card number is required.",
          });
        } else if (!numericRegex.test(value)) {
          setFormErrors({
            ...formErrors,
            cardNumber: "Only numbers are allowed for card number.",
          });
        } else if (value.length > 16) {
          setFormErrors({
            ...formErrors,
            cardNumber: "Card number should not exceed 16 digits.",
          });
        } else {
          setFormErrors({
            ...formErrors,
            cardNumber: null,
          });
        }
        break;

      case "expMonth":
        if (value.length <= 0) {
          setFormErrors({
            ...formErrors,
            expMonth: "Expiry Month is required.",
          });
        }
        else if (!numericRegex.test(value)) {
          setFormErrors({
            ...formErrors,
            expMonth: "Only numbers are allowed for expiry month.",
          });
        }
        else if (value.length > 2) {
          setFormErrors({
            ...formErrors,
            expMonth: "Expiry month should be 2 digits.",
          });
        } else {
          setFormErrors({
            ...formErrors,
            expMonth: null,
          });
        }
        break;

      case "expYear":
        if (value.length <= 0) {
          setFormErrors({
            ...formErrors,
            expYear: "Year is required.",
          });
        }
        else if (!numericRegex.test(value)) {
          setFormErrors({
            ...formErrors,
            expYear: "Only numbers are allowed for year.",
          });
        }
        else if (value.length > 4) {
          setFormErrors({
            ...formErrors,
            expYear: "Year should be 4 digits.",
          });
        } else {
          setFormErrors({
            ...formErrors,
            expYear: null,
          });
        }
        break;

      case "cvc":
        if (value.length <= 0) {
          setFormErrors({
            ...formErrors,
            cvc: "CVC is required.",
          });
        }
        else if (!numericRegex.test(value)) {
          setFormErrors({
            ...formErrors,
            cvc: "Only numbers are allowed for CVC.",
          });
        }
        else if (value.length > 3) {
          setFormErrors({
            ...formErrors,
            cvc: "CVC should be 3 digits.",
          });
        } else {
          setFormErrors({
            ...formErrors,
            cvc: null,
          });
        }
        break;

      default:
        break;
    }

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleConfirmPayment = async (e) => {
    setLoading(true)
    if (
      !formValues.firstName ||
      !formValues.lastName ||
      !formValues.email ||
      !formValues.cardNumber ||
      !formValues.expMonth ||
      !formValues.expYear ||
      !formValues.cvc
    ) {
      setFormErrors({
        firstName: formValues.firstName ? null : "first Name is required.",
        lastName: formValues.lastName ? null : "Last Name is required.",
        email: formValues.email ? null : "Email required.",
        cardNumber: formValues.cardNumber ? null : "Card number is required.",
        expMonth: formValues.expMonth ? null : "Expiry Month is required.",
        expYear: formValues.expYear ? null : "Year is required.",
        cvc: formValues.cvc ? null : "CVC is required.",
      });
      toast?.error("Please fill in all the required fields.");
      setLoading(false)
      return;
    }
    const payload = {
      teacherID: teacherDetail?._id,
      firstName: formValues?.firstName,
      lastName: formValues?.lastName,
      email: formValues?.email,
      cardDetails: {
        cardNumber: formValues?.cardNumber,
        expMonth: formValues?.expMonth,
        expYear: formValues?.expYear,
        cvc: formValues?.cvc,
      }
    };
    const res = await PostBookingCallApi(payload);
    if (res?.error != null) {
      toast?.error(res.error.message);
    } else {
      toast?.success(res.data.message);
      setTimeout(() => {
        window.open(res.data.result?.link, "_blanck");
        const link = document.createElement('a');
        link.href = res?.data?.result?.invoice;
        link.download = "Invoice Meeting.pdf";
        link.click();
      }, 1000);
    }
    setLoading(false)
  };

  const breadcrumbItems = [
    { title: "Cart", url: "/libarary/bag" },
    { title: "Information", url: "/libarary/detail/checkOut" },
    { title: "Payment", url: "/libarary/detail/payment" },
  ];

  const validateCardName = (number) => {
    let name = getCreditCardNameByNumber(number)
    if (name == "Visa") {
      return <RiVisaFill className="h-6 w-6 text-gray-400" />
    }
    else if (name == "Mastercard") {
      return <RiMastercardFill className="h-6 w-6 text-gray-400" />
    }
    else if (name == "American Express") {
      return <SiAmericanexpress className="h-6 w-6 text-gray-400" />
    }
    else if (name == "Discover") {
      return <FaCcDiscover className="h-6 w-6 text-gray-400" />
    }
    else if (name == "Diners") {
      return <FaCcDinersClub className="h-6 w-6 text-gray-400" />
    }
    else {
      return <BsCreditCard2Front className="h-6 w-6 text-gray-400" />
    }
  }

  return (
    <>
      <section className="paymentSec DBlock py-16 px-6">
        <div className="max-w-7xl mx-auto py-20">

          <div className="grid grid-cols-1 pt-7">
            <div className="w-full mx-auto">
              <div className="relative">
                <div className="max-w-7xl mx-auto py-5">
                  <div
                    className="max-w-7xl grid grid-cols-1 md:grid-cols-2  gap-4 sm:gap-10 px-7 lg:px-4 pt-20 pb-12"
                  >
                    <div className="col-span-2 md:col-span-1">
                      <div className="relative">
                        <span className="absolute inset-y-0 left-0 pl-10 flex items-center">
                          <BiUser className="h-5 w-5 text-gray-400" />
                        </span>
                        <input
                          onChange={handleFormChange}
                          required
                          type="text"
                          name="firstName"
                          id="first-name"
                          placeholder="First Name"
                          autoComplete="name"
                          className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-4 pl-16"
                        />
                        {formErrors.firstName && (
                          <p className=" h-5 absolute bottom-[-30px] left-5 text-sm text-red-500">
                            {formErrors.firstName}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-span-2 md:col-span-1">
                      <div className="relative">
                        <span className="absolute inset-y-0 left-0 pl-10 flex items-center">
                          <BiUser className="h-5 w-5 text-gray-400" />
                        </span>
                        <input
                          onChange={handleFormChange}
                          required
                          type="text"
                          name="lastName"
                          id="last-name"
                          placeholder="Last Name"
                          autoComplete="name"
                          className="block w-full rounded-md bg-white shadow border-4 border-transparent px-3 py-4 pl-16"
                        />
                        {formErrors.lastName && (
                          <p className=" h-5 absolute bottom-[-30px] left-5 text-sm text-red-500">
                            {formErrors.lastName}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-span-2 block w-full">
                      <div className="relative">
                        <span className="absolute inset-y-0 left-0 pl-10 flex items-center">
                          <TfiEmail className="h-5 w-5 text-gray-400" />
                        </span>
                        <input
                          onChange={handleFormChange}
                          required
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Email"
                          autoComplete="name"
                          className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-4 pl-16"
                        />
                        {formErrors.email && (
                          <p className=" h-5 absolute bottom-[-30px] left-5 text-sm text-red-500">
                            {formErrors.email}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="grid grid-cols-2  gap-8">
            <div>
              <h1 className="text-2xl font-bold text-black py-8">
                Select a payment method:
              </h1>
              <div className="flex justify-between">
                <h1 className="text-lg font-bold text-black flex items-center">
                  {" "}
                  <span className="w-5 h-5 rounded-full bg-yellow flex items-center justify-center mr-2">
                    <span className="w-2 h-2 rounded-full bg-white"></span>
                  </span>
                  Bank Cards
                </h1>
                <img className="imageBag" src={creditCard} alt="credit card" />
              </div>
              <div className="max-w-7xl grid grid-cols-1 md:grid-cols-2  gap-4 sm:gap-10 px-7 lg:px-1 py-12">
                <div className="col-span-2 block w-full">
                  <div className="relative">
                    <span className="absolute inset-y-0 right-0 pr-10 flex items-center">
                      {validateCardName(formValues?.cardNumber)}
                    </span>
                    <input
                      required
                      type="text"
                      onChange={handleFormChange}
                      onInput={handleCardNumberInput}
                      name="cardNumber"
                      id="cardNumber"
                      placeholder="4242424242424242"
                      className="block w-full rounded-md bg-white shadow border-4 border-transparent px-3 py-4 pl-5"
                    />
                    {formErrors.cardNumber && (
                      <p className=" h-5 absolute bottom-[-30px] left-5 text-sm text-red-500">
                        {formErrors.cardNumber}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-2 md:col-span-1">
                  <div className="relative">
                    <span className="absolute inset-y-0 right-0 pr-10 flex items-center">
                      <BsCalendar4Event className="h-5 w-5 text-gray-400" />
                    </span>
                    <input
                      required
                      type="text"
                      name="expMonth"
                      onInput={handleMonthInput}
                      onChange={handleFormChange}
                      id="expiryMonth"
                      placeholder="08"
                      autoComplete="expiryMonth"
                      className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-4 pl-5"
                    />
                    {formErrors.expMonth && (
                      <p className=" h-5 absolute bottom-[-30px] left-5 text-sm text-red-500">
                        {formErrors.expMonth}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-2 md:col-span-1">
                  <div className="relative">
                    <span className="absolute inset-y-0 right-0 pr-10 flex items-center">
                      <BsCalendar4Event className="h-5 w-5 text-gray-400" />
                    </span>
                    <input
                      required
                      type="text"
                      name="expYear"
                      onInput={handleYearInput}
                      onChange={handleFormChange}
                      id="expiryYear"
                      placeholder="2026"
                      autoComplete="expiryYear"
                      className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-4 pl-5"
                    />
                    {formErrors.expYear && (
                      <p className=" h-5 absolute bottom-[-30px] left-5 text-sm text-red-500">
                        {formErrors.expYear}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-2 md:col-span-1">
                  <div className="relative">
                    <span className="absolute inset-y-0 right-0 pr-10 flex items-center">
                      <TfiLock className="h-5 w-5 text-gray-400" />
                    </span>
                    <input
                      required
                      type="text"
                      onInput={handleCvcInput}
                      onChange={handleFormChange}
                      name="cvc"
                      id="cvc"
                      placeholder="586"
                      autoComplete="cvc"
                      className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-4 pl-5"
                    />
                    {formErrors.cvc && (
                      <p className=" h-5 absolute bottom-[-30px] left-5 text-sm text-red-500">
                        {formErrors.cvc}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-span-2 block w-full">
                  <p className="text-xs text-gray-400">
                    Data is protected under the PCI DSS standard. We do not
                    store your data and do not share it with the merchant.
                  </p>
                </div>
                <div className=" paymentBtn col-span-2 block w-full justify-center flex">
                  <PrimaryButton heading="Confirm" primaryFun={handleConfirmPayment} loading={loading} />
                </div>
              </div>
            </div>
            <div className="flex justify-end items-center">
              <div
                style={{ width: "30rem" }}
                className=" max-w-lg h-max block  rounded-md bg-white shadow  border-4 border-transparent px-10 py-7"
              >

                <div className="flex justify-between">
                  <p className="text-md text-gray-500">Subtotal</p>
                  <p className="text-md text-gray-500">${teacherDetail?.rate ? teacherDetail?.rate : 6}</p>
                </div>
                <div className="flex justify-between py-2">
                  <p className="text-md text-gray-500">Service Charges</p>
                  <p className="text-md text-gray-500">${teacherDetail?.rate ? parseFloat(teacherDetail?.rate * 0.1).toFixed(1) : 0.6}</p>
                </div>

                <div className="border-y border-gray-300 my-3"></div>
                <div className="flex justify-between">
                  <p className="text-md text-gray-500">Total</p>
                  <p className="text-md text-gray-500">${teacherDetail?.rate ? parseFloat(teacherDetail?.rate + teacherDetail?.rate * 0.1).toFixed(1) : 6.6}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StudentPaymentPage;
