import axios from '../AxiosInstance';



const AllCategoriesAPI = async () => {

    let resolved = {
        data: null,
        error: null,
    };
    try {
        let res = await axios({
            url: '/category',
            method: "GET",
        })

        resolved.data = res?.data
    } catch (err) {
        if (err && err.response && err?.response?.data?.message) {
            resolved.error = err.response.data.message
        } else {
            resolved.error = "Something went wrong";
        }
    }
    return resolved;
}

const AllTagsAPI = async () => {

    let resolved = {
        data: null,
        error: null,
    };
    try {
        let res = await axios({
            url: '/tag',
            method: "GET",
        })

        resolved.data = res?.data
    } catch (err) {
        if (err && err.response && err?.response?.data?.message) {
            resolved.error = err.response.data.message
        } else {
            resolved.error = "Something went wrong";
        }
    }
    return resolved;
}

export { AllCategoriesAPI, AllTagsAPI }