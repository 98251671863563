import React from 'react'

import { HiMenuAlt2 } from 'react-icons/hi';
import { HiOutlineSearch } from 'react-icons/hi';
// import { FiShoppingCart } from 'react-icons/fi';
import { BsBag } from 'react-icons/bs';
import { Logout, MessageQuestion, Element3 } from "iconsax-react";

import { NavLink, useLocation, useNavigate } from 'react-router-dom'

// import { ReactComponent as Logo } from '../../Assets/Images/logo.svg';
import logo from '../../Assets/Images/logo.png'
import oldLogo from '../../Assets/Images/logo-old.png'

import PrimaryButton from '../../Components/Atoms/PrimaryButton/PrimaryButton';


import { Button, Drawer, Popover } from 'antd';
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import './Navbar.scss'
import ImgURL from 'Utils/ImgUrlGen';
import { toast } from 'react-toastify';
import SideBar from 'Components/SideBar/SideBar';
import PrimaryButton2 from 'Components/Atoms/PrimaryButton2/PrimaryButton2';
import { SideBarDataActions } from 'Redux/Slice/ToggleSideBar';




const Navbar = ({ LogOut }) => {
    const Navigate = useNavigate()

    let UserData = useSelector(state => state?.UserData)
    let LodingData = useSelector(state => state?.LoadingStates)

    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const handleLinkClick = () => {
        onClose(); // Close the drawer
    };


    const location = useLocation();
    const navbarColor = {
        color: "",
    }
    const shoppingLogo = {
        display: "none",
    }
    const logoColor = {
        display: "",
    }
    if (location.pathname === '/library' || location.pathname == '/teacher' || location.pathname == '/recite' || location.pathname == '/donation' || location.pathname == '/blogs' || location.pathname == '/blogs/blog') {
        navbarColor.color = '#fff';
        logoColor.display = 'none';
        shoppingLogo.display = 'block';
    }
    else if (location.pathname === '/blog') {
        navbarColor.color = '#fff';

    }
    else if (location.pathname === '/shopping') {
        logoColor.display = 'none';
        shoppingLogo.display = 'block';
    }

    const Dispatch = useDispatch()
    const sidebarManuOpen = () => {
        Dispatch(SideBarDataActions?.setSideBarData({ sidemanu: true }))
    }
    const carts = useSelector((state) => state?.CartsData)

    const content = (
        <div className='antPopover'>
            <div className='popoverItem' onClick={() => window.location.replace(`${window?.location?.DashboardURL}?s=c`)}>
                <Element3 className='icon' />
                Dashboard
            </div>
            <div className='popoverItem'>
                <MessageQuestion className='icon' />
                Help
            </div>
            <div className='popoverItem' onClick={LogOut}>
                <Logout className='icon' />
                Logout
            </div>
        </div>
    );

    return (
        <div className="navbar-container">
            <div className="navbar container" >
                <div className="logo">
                    <a href='/'>
                        <img style={logoColor} src={oldLogo} alt="ERROR" />
                        <img style={shoppingLogo} src={logo} alt="ERROR" />
                    </a>
                </div>
                <div className="webmenu" >
                    <NavLink to='/' style={navbarColor}>Home</NavLink>
                    <NavLink to='/teacher' style={navbarColor}>Find Teacher</NavLink>
                    <NavLink to='/library' style={navbarColor}>Library</NavLink>
                    <NavLink to='/blogs' style={navbarColor}>Blogs</NavLink>
                    <NavLink to='/recite' style={navbarColor}>Recite</NavLink>
                    <NavLink to='/donation' style={navbarColor}>Donate Us</NavLink>
                    <NavLink to='/about' style={navbarColor}>About Us</NavLink>
                    <NavLink to='/contact' style={navbarColor}>Contact Us</NavLink>
                    {/* <NavLink to='/donation' style={navbarColor}>Donation</NavLink> */}
                    <NavLink to='/search'><HiOutlineSearch style={navbarColor} className='icon' /></NavLink>
                    <span className="bagData">
                        <BsBag style={navbarColor} size={24} className='icon iconBag' onClick={sidebarManuOpen} />
                        {
                            carts?.length > 0 &&
                            <span className="bookedbooks">{carts?.length}</span>
                        }
                    </span>
                    {
                        UserData && UserData?.firstName && UserData?.email ?
                            <>
                                <div className="nav-popover">
                                    <Popover
                                        placement="bottomRight" title={
                                            <div className='popoverHeading'>
                                                <div className='name'>{UserData?.firstName} {UserData?.lastName}</div>
                                                <div className="skill">{UserData?.isSuperAdmin ? "SUPER ADMIN" : UserData?.role?.name?.toLocaleUpperCase()}</div>
                                            </div>
                                        } content={content} trigger="click">
                                        <div className='imgBox'>
                                            {
                                                <img src={ImgURL(UserData?.profileImage)} alt="" />
                                            }
                                        </div>
                                    </Popover>
                                </div>
                            </>
                            :
                            // : LodingData?.ProfileAPI == false &&
                            <PrimaryButton heading="Login" primaryFun={() => window.location.replace(`${window?.location?.DashboardURL}?s=c`)} />
                    }

                </div>
                <div className="mobilemenu">
                    <HiMenuAlt2 className='icon' onClick={showDrawer} />
                    <Drawer
                        placement="left"
                        onClose={onClose}
                        open={open}
                        width={300}
                    >
                        <img style={logoColor} src={oldLogo} alt="ERROR" />
                        <div className="flex-menu" >
                            <NavLink to='/search' ><HiOutlineSearch className='icon' /></NavLink>
                            <NavLink to='/' onClick={handleLinkClick}>Home</NavLink>
                            <NavLink to='/teacher' onClick={handleLinkClick} >Find Teacher</NavLink>
                            <NavLink to='/library' onClick={handleLinkClick}>Library</NavLink>
                            <NavLink to='/blogs' onClick={handleLinkClick}>Blogs</NavLink>
                            <NavLink to='/recite' onClick={handleLinkClick}>Recite</NavLink>
                            <NavLink to='/donation' onClick={handleLinkClick}>Donate Us</NavLink>
                            <NavLink to='/about' onClick={handleLinkClick}>About Us</NavLink>
                            <NavLink to='/contact' onClick={handleLinkClick}>Contact  Us</NavLink>
                            <div className="bagData">
                                <BsBag className='icon iconBag' onClick={sidebarManuOpen} />
                                {
                                    carts?.length > 0 &&
                                    <span className="bookedbooks">{carts?.length}</span>
                                }
                            </div>
                            <PrimaryButton2 heading="Login" primaryFun={() => window.location.replace(`${window?.location?.DashboardURL}?s=c`)} />
                            {/* <PrimaryButton heading="Login" primaryFun={() => window.open("http://stagging.dashboard.madrasa.io", "_blank")} /> */}
                        </div>


                    </Drawer>
                </div>
            </div>
        </div >
    )
}

export default Navbar
