import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

export const CommissionData = createSlice({
    name : "CommissionData",
    initialState,
    reducers : {
        setCommissionData : (state, action)=>{
            return action?.payload
        }
    }
})

export default CommissionData.reducer;
export const CommissionDataActions = CommissionData?.actions;