import React from "react";
import "./PrimaryHeading.scss";

const PrimaryHeading = (props) => {
  return (
    <div className="primary-heading">
      <div className="heading">{props.heading}</div>
    </div>
  );
};

export default PrimaryHeading;
