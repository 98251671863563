import { createSlice } from "@reduxjs/toolkit";

const initialState = null;


export const TeachersData = createSlice({
    name: "TeachersData",
    initialState,
    reducers: {
        setTeachersData: (state, action) => {
            return action.payload
        }
    }

})

export default TeachersData.reducer;
export const TeachersDataActions = TeachersData.actions;