import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';

// Assets | ICONS :
import home from '../../../Assets/Images/home.png';
import { ReactComponent as Video } from '../../../Assets/Images/video.svg';
import { ReactComponent as Search } from '../../../Assets/Images/search.svg';
import SearchBar from "../SearchBar/SearchBar";

//CSS :
import './MainHome.scss';





const MainHome = () => {
    const [isOpen, setOpen] = useState(false)
    return (
        <>
            <div className='home-container'>
                <div className="flex-home container">
                    <div className="content-section">
                        <div className="heading">Experience the Essence of Islamic Education: Online Madrasa</div>
                        <div className="para"> Welcome to Online Madrasa, the perfect place for individuals seeking to learn the Quran and embrace Islamic values. Through our user-friendly online platform, you and your children can experience the essence of Madrasa education right from the comfort of your home. Whether your goal is to become proficient in Tajweed, delve deeper into Islamic studies and the values derived from the Quran, or simply cultivate strong moral principles, Online Madrasa is here to cater to your needs.
                        </div>
                        <div className="input-field">
                            <SearchBar />
                        </div>
                    </div>
                    <div className="video-section">
                        <div className="image-border"></div>
                        <div className="image">
                            <img src={home} alt="Internet Error" />
                            <div className="video-btn">
                                {/* <ModalVideo
                                    channel='youtube'
                                    autoplay="1" isOpen={isOpen} videoId="LrMSqRYruwQ" onClose={() => setOpen(false)} /> */}
                                <div className="startContainer">
                                    <button className="button pulseBox" onClick={() => setOpen(true)}>
                                        <span className="span"></span>
                                        <span className="span second"></span>
                                        <span className="span third"></span>
                                        <Video className='icon' />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainHome
