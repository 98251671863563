import React from 'react';

// Componets :
import Reviews from './Reviews/Reviews';
import Classes from '../../Pages/Home/Classes/Classes';
import ChooseBooks from './ChooseBook/ChooseBook';
import MainHome from '../../Pages/Home/MainHome/MainHome'
import Teachers from '../../Pages/Home/Teachers/Teachers';
import PopularCourses from './PopularCourses/PopularCourses';
import PrayerTime from './PrayerTime/PrayerTime';
import PrayerBar from '../../Components/Atoms/PrayerScrollBar/PrayerBar';





const Home = () => {

  return (
    <>
      <MainHome />
      <ChooseBooks />
      <PopularCourses />
      <Teachers />
      <Classes />
      <PrayerTime />
      <Reviews />
      <PrayerBar />
    </>
  )
}

export default Home
