import React from 'react'

import './SecondaryButton.scss'





const SecondaryButton = (props) => {
  return (
    <div className='secondary-button'>
      <button className="sec-btn" onClick={props.secondaryFun}>{props.heading}</button>
    </div>
  )
}

export default SecondaryButton
