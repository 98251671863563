import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ImgURL from "../../../../Utils/ImgUrlGen";

// Assets | ICONS :
import Avater from "../../../../Assets/Images/img/profileAvater.png";
import PostTemp from "../../../../Assets/Images/img/sideBarPostTemp.jpg";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaGooglePlusG,
} from "react-icons/fa";
import { HiSearch } from "react-icons/hi";

// Redux :
import { useSelector } from "react-redux";

// CSS :
import "./SideBar.scss";

const SideBar = ({ padding, bgWhite, selectedBlog, onSearchInputChange, tags, categories, selectedCategory, selectedTag, selectingCategory, selectingTag }) => {
  const Location = useLocation();
  // selectedBlog

  const BlogsDataState = useSelector((state) => state?.BlogsData);

  const [allblogs, setAllBlogs] = useState(null);


  const [blogID, setBlogID] = useState(Location?.state?.blogID || null);
  const [selectedBlogSideBar, setSelectedBlogSideBar] = useState(null);
  // const [selectedBlogSideBar, setSelectedBlogSideBar] = useState(null);

  const [searchInput, setSearchInput] = useState("");
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchInput(inputValue);
    onSearchInputChange(inputValue);
  };

  useEffect(() => {
    if (Location?.state?.blogID) {
      setBlogID(Location?.state?.blogID);
    } else {
      setBlogID(null);
    }
  }, [Location.state]);
  useEffect(() => {
    console.log(BlogsDataState, "all blogs")
    if (BlogsDataState) {
      let copyData = [...BlogsDataState];
      let reversedArray = copyData ? copyData.reverse() : null;
      setAllBlogs(reversedArray);
      if (blogID) {
        const findData = BlogsDataState.find((val) => val?._id == blogID);
        let findBlog = {};
        if (findData) {
          findBlog = {
            ...findData,
          };
          setSelectedBlogSideBar(findBlog);
        }
      }
    }
  }, [BlogsDataState, blogID]);
  return (
    <>
      <div className="sidebarContainer" style={bgWhite == "#fff" ? { background: "#fff", padding: ".5rem 0" } : { background: "#f3f3f3", padding: ".5rem 2rem" }}>
        {Location.pathname == "/blogs/blog" && (
          // <div className="profileCard">
          //   <div className="card">
          //     <div className="imgBox">
          //       <img src={ImgURL(selectedBlogSideBar?.auther?.profileImage)} alt="ERROR" />
          //     </div>
          //     <div className="details">
          //       <div className="name">
          //         {selectedBlogSideBar?.user?.firstName} {selectedBlogSideBar?.user?.lastName}
          //       </div>
          //       <div className="bio">
          //         Hi! here is our blog. Read our post - and be with us
          //       </div>
          //     </div>
          //   </div>
          //   <div className="socialLinks">
          //     <FaFacebookF className="icon" /> <FaTwitter className="icon" />{" "}
          //     <FaInstagram className="icon" />{" "}
          //     <FaGooglePlusG className="icon" />
          //   </div>
          // </div>
          <>
            <div className="tagsBox">
              <div className="title text-[#505050] singleBlogTag">Tags</div>
              <div className="line">
                <div className="cline" /> <div className="gline" />
              </div>
              <div className="tags">
                {
                  // selectedBlog.tags && Object.keys(selectedBlog.tags).map(tag => {
                  selectedBlog?.tags?.map(tag => {
                    return (
                      <>
                        <div className="tag"
                        // style={selectedTag == tag ? { backgroundColor: "black" } : {}} onClick={() => selectingTag(tag)}
                        >{tag?.name}</div>
                      </>
                    )
                  })
                }
              </div>
            </div>


            <div className="recentPostBox">
              <div className="title singleBlogPosts">Popular Posts</div>
              <div className="line">
                <div className="cline" /> <div className="gline" />
              </div>
              <div className="posts">
                {BlogsDataState &&
                  BlogsDataState?.map((blog, index) => {
                    return (
                      index <= 2 && (
                        <>
                          <div className="post">
                            <div className="imgBox">
                              <img
                                src={ImgURL(blog?.image)}
                                // src={
                                //   blog?.image?.url
                                //     ? `${process.env.REACT_APP_STORAGE_URL}/${blog?.image?.url}`
                                //     : PostTemp
                                // }
                                alt="ERROR"
                              />
                            </div>
                            <div className="details">
                              <div className="heading">{blog?.title}</div>
                              <div className="shortDetail">Lorem dolor sit amet, consectetur adipisicing elit. Aut, perspiciatis!</div>
                              <div className="time">
                                {new Date(blog?.createdAt).toLocaleDateString('en-US', {
                                  day: 'numeric',
                                  month: 'short',
                                  year: 'numeric',
                                })}
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    );
                  })}
              </div>
            </div>
          </>
        )}

        {Location.pathname == "/blogs" && (
          <>
            <div className="searchBox ">
              <input
                type="text"
                name=""
                id=""
                placeholder="Search Posts..."
                className="hover:shadow-md"
                value={searchInput}
                onChange={handleInputChange}
              />
              <div className="imgBox">
                <HiSearch />
              </div>
            </div>

            <div className="categoryBox">
              <div className="title">Categories</div>
              <div className="line">
                <div className="cline" /> <div className="gline" />
              </div>
              <div className="categories">
                {
                  categories && Object.keys(categories).map(cat => {
                    return (
                      <>
                        <div className="category" onClick={() => selectingCategory(cat)}>
                          <div className="titleBox">
                            <label
                              for="checkbox1"
                              className="flex items-center "
                            >
                              <input
                                type="checkbox"
                                id="checkbox1"
                                className="form-checkbox h-5 w-5 "
                                checked={selectedCategory == cat ? true : false}
                              />
                              <span className="ml-4">{cat}</span>
                            </label>
                          </div>
                          <div className="counts">({categories[cat]})</div>
                        </div>
                      </>
                    )
                  })
                }
              </div>
            </div>

            <div className="tagsBox">
              <div className="title text-[#505050]">Tags</div>
              <div className="line">
                <div className="cline" /> <div className="gline" />
              </div>
              <div className="tags">
                {
                  tags && Object.keys(tags).map(tag => {
                    return (
                      <>
                        <div className="tag" style={selectedTag == tag ? { backgroundColor: "#FCD117" } : {}} onClick={() => selectingTag(tag)}>{tag}</div>
                      </>
                    )
                  })
                }
              </div>
            </div>

            <div className="recentPostBox">
              <div className="title">Popular Posts</div>
              <div className="line">
                <div className="cline" /> <div className="gline" />
              </div>
              <div className="posts">
                {BlogsDataState &&
                  BlogsDataState?.map((blog, index) => {
                    return (
                      index <= 2 && (
                        <>
                          <div className="post">
                            <div className="imgBox">
                              <img
                                src={ImgURL(blog?.image)}
                                // src={
                                //   blog?.image?.url
                                //     ? `${process.env.REACT_APP_STORAGE_URL}/${blog?.image?.url}`
                                //     : PostTemp
                                // }
                                alt="ERROR"
                              />
                            </div>
                            <div className="details">
                              <div className="heading">{blog?.title}</div>
                              <div className="time">
                                {new Date(blog?.createdAt).toLocaleDateString('en-US', {
                                  day: 'numeric',
                                  month: 'short',
                                  year: 'numeric',
                                })}
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    );
                  })}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
};

export default SideBar;
