import React from 'react'
import './PreLoader.scss'
import { CircularProgress } from '@mui/material'

const PreLoader = () => {
    return (
        <>
            <div className="PreLoaderMain">
            <CircularProgress className="progress"/>
            </div>
        </>
    )
}

export default PreLoader

