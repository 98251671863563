import React from 'react'
import { useNavigate } from "react-router-dom";
// Componets :
import PrimaryButton from '../../../Components/Atoms/PrimaryButton/PrimaryButton'
import PrimaryHeading from '../../../Components/Atoms/PrimaryHeading/PrimaryHeading'

// Assets | ICONS :
import { ReactComponent as FirstBook } from '../../../Assets/Images/book-one.svg'
import { ReactComponent as SecondBook } from '../../../Assets/Images/book-two.svg'
import { ReactComponent as ThirdBook } from '../../../Assets/Images/book-three.svg'
import { ReactComponent as FourBook } from '../../../Assets/Images/book-four.svg'

// CSS :
import './PopularCourses.scss'





const PopularCourses = () => {
    const navigate = new useNavigate();

    return (
        <div className='categories-container space '>
            <div className="container">
                <div className="heading">
                    <PrimaryHeading heading="Popular Courses " />
                    <PrimaryButton heading="Courses" primaryFun={() => { navigate('/teacher') }}/>
                </div>
                <div className="flex-categories">
                    <div className="category-card cursor">
                        <div className="logo">
                            <FirstBook className='icon' />
                        </div>
                        <div className="name">Name</div>
                        <div className="bio">
                            In publishing and graphic  design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                        </div>
                    </div>
                    <div className="category-card cursor light">
                        <div className="logo">
                            <SecondBook className='icon' />
                        </div>
                        <div className="name">Name</div>
                        <div className="bio">
                            In publishing and graphic  design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                        </div>
                    </div>
                    <div className="category-card cursor">
                        <div className="logo">
                            <ThirdBook className='icon' />
                        </div>
                        <div className="name">Name</div>
                        <div className="bio">
                            In publishing and graphic  design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                        </div>
                    </div>
                    <div className="category-card cursor dark">
                        <div className="logo">
                            <FourBook className='icon' />
                        </div>
                        <div className="name">Name</div>
                        <div className="bio">
                            In publishing and graphic  design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                        </div>
                    </div>
                    <div className="category-card cursor">
                        <div className="logo">
                            <FirstBook className='icon' />
                        </div>
                        <div className="name">Name</div>
                        <div className="bio">
                            In publishing and graphic  design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                        </div>
                    </div>
                    <div className="category-card cursor light">
                        <div className="logo">
                            <SecondBook className='icon' />
                        </div>
                        <div className="name">Name</div>
                        <div className="bio">
                            In publishing and graphic  design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                        </div>
                    </div>
                    <div className="category-card cursor">
                        <div className="logo">
                            <ThirdBook className='icon' />
                        </div>
                        <div className="name">Name</div>
                        <div className="bio">
                            In publishing and graphic  design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                        </div>
                    </div>
                    <div className="category-card cursor dark">
                        <div className="logo">
                            <FourBook className='icon' />
                        </div>
                        <div className="name">Name</div>
                        <div className="bio">
                            In publishing and graphic  design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default PopularCourses
