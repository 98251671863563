import { useEffect } from "react";
import Scrollbar from "smooth-scrollbar";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll"

const Scroll = () => {

    var options = {
        damping: 0.05,
        alwaysShowTracks: true,
        plugins: {
            overscroll: true,
            damping: 0.01,
            effect: 'bounce',
            maxOverscroll: 30
        }
    }

    useEffect(() => {
        // Scrollbar.use(OverscrollPlugin);
        // Scrollbar.init(document.body, options);
        return () => {
            // Scrollbar.destroy(document.body);
        }
    }, [])
    return null;
}

export default Scroll;