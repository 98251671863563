import { createSlice } from "@reduxjs/toolkit";

const initialState = {sidemanu:false};


export const SideBarData = createSlice({
    name: "ToggleSideBar",
    initialState,
    reducers: {
        setSideBarData: (state, action) => {
            return action.payload
        }
    }

})

export default SideBarData.reducer;
export const SideBarDataActions = SideBarData.actions;