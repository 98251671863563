import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

let localCart = localStorage.getItem("cartData")
const initialState = localCart ? JSON.parse(localCart) : [];


export const CartsData = createSlice({
    name: "CartsData",
    initialState,
    reducers: {
        setCartsData: (state, action) => {
            let data = [...state, action.payload]
            localStorage.setItem("cartData", JSON.stringify(data))
            return data
        },

        removeCartItem: (state, action) => {
            const itemId = action.payload;
            const updatedData = state.filter(item => item._id !== itemId);
            localStorage.setItem("cartData", JSON.stringify(updatedData));
            // toast.success("Item remove Success")
            return updatedData;
        },
    },
})

export default CartsData.reducer;
// export const {setCartsData, removeCartItem} = CartsData.actions;
export const CartsDataActions = CartsData.actions;