import React, { useState } from 'react'
import { BsGlobe } from 'react-icons/bs';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { FaHeadphonesAlt } from 'react-icons/fa';

// Components :
import PrimaryButton from '../../Components/Atoms/PrimaryButton/PrimaryButton'

//CSS :
import './Contact.scss'
import { PostContactUsAPI } from 'API/contactUs';
import { toast } from 'react-toastify';






const Contact = () => {
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        subject: "",
        message: ""
    })
    const [formError, setFormError] = useState({
        firstName: null,
        lastName: null,
        email: null,
        subject: null,
        message: null
    })

    const enterFormData = (event) => {
        let { name, value } = event.target;
        switch (name) {
            case "firstName":
            case "lastName":
                if (value.trim() === "") {
                    setFormError({
                        ...formError,
                        [name]: `${name === "firstName" ? "First" : "Last"} Name is required.`
                    });
                } else {
                    setFormError({
                        ...formError,
                        [name]: null
                    });
                }
                break;
            case "email":
                // You can add more complex email validation if needed
                if (!value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
                    setFormError({
                        ...formError,
                        email: "Invalid email address."
                    });
                } else {
                    setFormError({
                        ...formError,
                        email: null
                    });
                }
                break;
            case "subject":
                if (value.trim() === "") {
                    setFormError({
                        ...formError,
                        subject: "Subject is required."
                    });
                } else {
                    setFormError({
                        ...formError,
                        subject: null
                    });
                }
                break;
            case "message":
                if (value.length <= 10) {
                    setFormError({
                        ...formError,
                        message: "Your Query is less than 10 characters."
                    });
                } else {
                    setFormError({
                        ...formError,
                        message: null
                    });
                }
                break;
            default:
                break;
        }
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const submitData = async () => {
        setLoading(true)
        if (!formData.firstName || !formData.lastName || !formData.email || !formData.subject || !formData.message) {
            toast.error("Please fill in all required fields.");
            return;
        }
        if (formData?.message?.length <= 10) {
            setFormError({ ...formError, message: "Your Query is less then 10 Charactors." })
            return;
        }
        let res = await PostContactUsAPI(formData)
        if (res?.error != null) {
            toast?.error(res?.error)
        } else {
            setLoading(false)
            toast?.success(res?.data?.message)
            setFormData({
                firstName: "",
                lastName: "",
                email: "",
                subject: "",
                message: ""
            })
        }

    }
    return (
        <>
            <div className="ContactContainer">
                <div className="flexContact container">
                    <div className="contact">
                        <div className="heading">Get In Touch</div>
                        <div>
                            <div className="inputFields">
                                <div className="flexField">
                                    <div className='fieldWidth'>
                                        <input className='input' name='firstName' type="text" placeholder='First Name' onChange={enterFormData} value={formData?.firstName} />
                                        {formError.firstName && <div className="errorMessage">{formError.firstName}</div>}
                                    </div>
                                    <div className='fieldWidth'>
                                        <input className='input' name='lastName' type="text" placeholder='Last Name' onChange={enterFormData} value={formData?.lastName} />
                                        {formError.lastName && <div className="errorMessage">{formError.lastName}</div>}

                                    </div>
                                </div>
                                <input className='input' name='email' type="text" placeholder='Email' onChange={enterFormData} value={formData?.email} />
                                {formError.email && <div className="errorMessage">{formError.email}</div>}
                                <input className='input' name='subject' type="text" placeholder='Subject' onChange={enterFormData} value={formData?.subject} />
                                {formError.subject && <div className="errorMessage">{formError.subject}</div>}
                                <textarea placeholder='Message' name='message' onChange={enterFormData} value={formData?.message} ></textarea>
                                {formError.message && <div className="errorMessage">{formError.message}</div>}
                                <div className="button">
                                    <PrimaryButton heading="Send" primaryFun={submitData} loading={loading} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="map">
                        <iframe className='iframe' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29624007.584601704!2d115.22979863156776!3d-24.992915938390176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2b2bfd076787c5df%3A0x538267a1955b1352!2sAustralia!5e0!3m2!1sen!2s!4v1678978329103!5m2!1sen!2s" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
                <div className="contactCards container">
                    <div className="card">
                        <div className="heading">25 North Street,Australia</div>
                        <div className="flexInfo">
                            <div className="infoIcon"><BsGlobe className='icon' /></div>
                            <div className="infoheading">Office Address</div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="heading">madrasa.io@gmail.com</div>
                        <div className="flexInfo">
                            <div className="infoIcon"><HiOutlineMailOpen className='icon' /></div>
                            <div className="infoheading">Email</div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="heading">+61 7 7010 1111 </div>
                        <div className="flexInfo">
                            <div className="infoIcon"><FaHeadphonesAlt className='icon' /></div>
                            <div className="infoheading"> Phone Number</div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Contact
