import React, { useEffect, useState } from "react";
import "./CallJoiningForm.scss";
import profile from "../../../../Assets/Images/home.png";
import { TfiEmail, TfiLock } from "react-icons/tfi";
import { BiTimeFive, BiUser } from "react-icons/bi";
import { BsCalendar4Event, BsCreditCard2Front } from "react-icons/bs";
import { MdOutlineTimer, MdOutlineNotificationsActive } from "react-icons/md";
import CircularProgress from '@mui/material/CircularProgress';
import PrimaryButton from "../../../../Components/Atoms/PrimaryButton/PrimaryButton";
import { Zoom } from "iconsax-react";
import Cards from "react-credit-cards";
import ImgURL from "../../../../Utils/ImgUrlGen";

// import Styling
import "react-credit-cards/es/styles-compiled.css";

// Helpers
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { toast } from "react-toastify";

// API's
import { PostBookingCallApi } from "API/student";
import { StudentScheduleAPI } from "API/teacherSchedule";
import { useSelector } from "react-redux";
import { Button, Input, Spin } from "antd";
import Socket from "Utils/Socket";

const CallJoiningForm = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const eventData = location?.state?.meetingDetail;
  const teacherDetail = location?.state?.teacherDetail;
  const UserData = useSelector((state) => state.UserData);

  const initialValues = {
    firstName: "",
    email: "",
    cardNumber: "",
    focus: "",
    holderName: "",
    expiry: "",
    cvc: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({
    firstName: null,
    email: null,
    cardNumber: null,
    holderName: null,
    expiry: null,
    // thoughts: null,
    cvc: null,
  });



  const [loading, setLoading] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);



  const handleInputFocus = (e) => {
    setFormValues({ ...formValues, focus: e.target.name });
  };

  const handleInputBlur = () => {
    setFormValues({ ...formValues, focus: "" });
  };
  const handleCardNumberInput = (e) => {
    const { value } = e.target;

    if (value.length > 16) {
      e.target.value = value.slice(0, 16);
    }
  };

  const handleMonthInput = (e) => {
    const { value } = e.target;

    if (value.length > 4) {
      e.target.value = value.slice(0, 4);
    }
  };

  const handleCvcInput = (e) => {
    const { value } = e.target;

    if (value.length > 3) {
      e.target.value = value.slice(0, 3);
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    const numericRegex = /^\d+$/; // Regex pattern to match only numbers

    switch (name) {
      case "firstName":
        if (value.length <= 0) {
          setFormErrors({
            ...formErrors,
            firstName: "A first Name is requried.",
          });
        } else {
          setFormErrors({
            ...formErrors,
            firstName: null,
          });
        }
        break;

      case "email":
        if (value.length <= 0) {
          setFormErrors({
            ...formErrors,
            email: "A email is requried.",
          });
        } else {
          setFormErrors({
            ...formErrors,
            email: null,
          });
        }
        break;

      case "cardNumber":
        if (value.length <= 0) {
          setFormErrors({
            ...formErrors,
            cardNumber: "Card number is required.",
          });
        } else if (!numericRegex.test(value)) {
          setFormErrors({
            ...formErrors,
            cardNumber: "Only numbers are allowed for card number.",
          });
        } else if (value.length > 16) {
          setFormErrors({
            ...formErrors,
            cardNumber: "Card number should not exceed 16 digits.",
          });
        } else {
          setFormErrors({
            ...formErrors,
            cardNumber: null,
          });
        }
        break;

      case "expiry":
        if (value.length <= 0) {
          setFormErrors({
            ...formErrors,
            expiry: "Expiry Month and Year is required.",
          });
        } else if (!numericRegex.test(value)) {
          setFormErrors({
            ...formErrors,
            expiry: "Only numbers are allowed for expiry month.",
          });
        } else if (value.length > 6) {
          setFormErrors({
            ...formErrors,
            expiry: "Expiry month should be 6 digits.",
          });
        } else {
          setFormErrors({
            ...formErrors,
            expiry: null,
          });
        }
        break;

      case "cvc":
        if (value.length <= 0) {
          setFormErrors({
            ...formErrors,
            cvc: "CVC is required.",
          });
        } else if (!numericRegex.test(value)) {
          setFormErrors({
            ...formErrors,
            cvc: "Only numbers are allowed for CVC.",
          });
        } else if (value.length > 3) {
          setFormErrors({
            ...formErrors,
            cvc: "CVC should be 3 digits.",
          });
        } else {
          setFormErrors({
            ...formErrors,
            cvc: null,
          });
        }
        break;

      default:
        break;
    }

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  useEffect(() => {
    if (teacherDetail) {
      setFormValues({
        ...formValues,
        firstName: UserData?.firstName,
        email: UserData?.email
      })
    } else {
      setFormValues({
        ...formValues,
        firstName: "",
        email: "",
      })
    }
  }, [teacherDetail])


  const handleConfirmPayment = async (e) => {
    setLoading(true);
    if (
      !formValues.cardNumber ||
      !formValues.holderName ||
      !formValues.expiry ||
      !formValues.cvc
    ) {
      setFormErrors({
        firstName: formValues.firstName ? null : "First Name is required.",
        email: formValues.email ? null : "Email is required.",
        cardNumber: formValues.cardNumber ? null : "Card number is required.",
        holderName: formValues.holderName ? null : "Card Holder Name is required.",
        expiry: formValues.expiry ? null : "Expiry is required.",
        cvc: formValues.cvc ? null : "CVC is required.",
      });
      toast?.error("Please fill in all the required fields.");
      setLoading(false)
      return;
    }

    // This is the Schedule API
    let studentEvent = {
      eventData: {
        start: eventData?.start,
        end: eventData?.end,
        title: "Booked",
        bookingId: UserData?._id,
      },
      userID: UserData?._id,
      teacherID: teacherDetail?._id,
    };


    // Now start the payment module
    const payload = {
      teacherID: teacherDetail?._id,
      firstName: formValues?.firstName,
      email: formValues?.email,
      // thoughts: formValues?.thoughts,
      startDate: eventData?.start,
      cardDetails: {
        cardNumber: formValues?.cardNumber,
        holderName: formValues?.holderName,
        expiry: formValues?.expiry,
        cvc: formValues?.cvc,
      },
    };
    const res = await PostBookingCallApi(payload);
    if (res?.error != null) {
      toast?.error(res.error);
    } else {
      toast?.success(res?.data?.message);
      let resTeacher = await StudentScheduleAPI(studentEvent);
      if (resTeacher?.error != null) {
        return toast.error(resTeacher?.error);
      }
      Socket.emit("newNotification", res?.data?.notificationId, (res) => {
        console.log("--------- SOCKET HIT --------->", res);
      })
      if (UserData?.rate >= 1) {
        setTimeout(() => {
          navigate(-1)
          window.open(res?.data?.result?.invoice, "_blank");
        }, 2000);
      } else {
        setTimeout(() => {
          navigate(-1)
        }, 2000);

      }
    }
    setLoading(false);
  };

  const handleLogin = () => {
    window.open(`${window?.location?.DashboardURL}/login`, "_blank");
  };

  return (
    <>

      <div className="callMainContainer">
        <div className="detailMain">
          <div className=" ">
            <div
              // style={{ width: "23rem" }}
              className="max-w-full lg:max-w-lg h-max block  rounded-md bg-white shadow  border-4 border-transparent px-6 py-7"
            >
              <divv className=" py-3 ">
                <img
                  className=" w-28 h-28 rounded-full"
                  src={ImgURL(teacherDetail?.profileImage)}
                  // src={data?.Img}
                  alt="Uploading"
                />
              </divv>
              <h1 className=" font-normal text-base py-2">{teacherDetail?.firstName} {teacherDetail?.lastName}</h1>
              <h1 className=" font-semibold text-2xl py-5 capitalize">
                Study and guidance by {teacherDetail?.firstName} {teacherDetail?.lastName}
              </h1>

              <div className=" flex justify-between py-2">
                <p className="font-normal text-base flex items-center">
                  <BsCreditCard2Front />
                  <span className="px-2">
                    Cost
                  </span>
                </p>
                <p className=" font-semibold">${teacherDetail?.rate ? teacherDetail?.rate : 0}</p>
              </div>

              <div className=" flex justify-between pb-2">
                <p className="font-normal text-base flex items-center">
                  <BsCalendar4Event />
                  <span className="px-2">
                    Date
                  </span>
                </p>
                <p className=" font-semibold">{moment(eventData?.start).format("DD-MM-YY")}</p>
              </div>

              <div className=" flex justify-between pb-2">
                <p className="font-normal text-base flex items-center">
                  <BiTimeFive />
                  <span className="px-2">
                    Time
                  </span>
                </p>
                <p className=" font-semibold">{moment(eventData?.start).format("hh:mm A")}-{moment(eventData?.end).format("hh:mm A")}</p>
              </div>


              {/* <div className=" flex justify-between pb-2">
                <p className="font-normal text-base flex items-center">
                  <MdOutlineNotificationsActive />
                  <span className="px-2">
                    Notify Before
                  </span>
                </p>
                <p className=" font-semibold">15 Minutes</p>
              </div> */}

              <div className=" flex justify-between pb-2">
                <p className="font-normal text-base flex items-center">
                  <BiUser />
                  <span className="px-2">
                    Host
                  </span>
                </p>
                <p className=" font-semibold">{teacherDetail?.firstName} {teacherDetail?.lastName}</p>
              </div>

              <div className=" flex justify-between pb-2">
                <p className="font-normal text-base flex items-center">
                  <MdOutlineTimer />
                  <span className="px-2">
                    Duration
                  </span>
                </p>
                <p className=" font-semibold">1 Hr</p>
              </div>


              <p className="pt-5 pb-3">
                {teacherDetail?.bio}
              </p>
            </div>
          </div>
          <div className="callFormContainer">
            <div className=" paymentCart max-w-full h-max block  rounded-md bg-white shadow  border-4 border-transparent px-3 md:px-10 py-7">
              <h1 className="font-normal text-base">Enter Details</h1>
              <div className="max-w-7xl grid grid-cols-1 md:grid-cols-2  ">
                <div className="col-span-2 block w-full">
                  <div className="relative">
                    <label for="fname" className=" text-gray-500">
                      Name
                    </label>
                    <span className="absolute inset-y-0 left-0 top-5 pl-5 flex items-center">
                      <BiUser className="h-5 w-5 text-gray-400" />
                    </span>
                    <input
                      onChange={handleFormChange}
                      value={formValues?.firstName}
                      required
                      type="text"
                      name="firstName"
                      id="firstName"
                      placeholder="Enter Your Name"
                      autoComplete="name"
                      className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-4 pl-16"
                    />
                  </div>
                </div>
                <div className="col-span-2 block w-full">
                  <div className="relative">
                    <label for="fname" className=" text-gray-500">
                      Email
                    </label>
                    <span className="absolute inset-y-0 left-0 top-5 pl-5 flex items-center">
                      <TfiEmail className="h-5 w-5 text-gray-400" />
                    </span>
                    <input
                      onChange={handleFormChange}
                      value={formValues?.email}
                      required
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Enter Your Email"
                      autoComplete="email"
                      className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-4 pl-16"
                    />
                  </div>
                </div>
              </div>
              <h1 className="font-normal text-base py-4">Location</h1>
              <div className="flex items-center">
                <Zoom size="32" color="#0a71f0" />
                <h1 className="px-5">Madrasa Dashboard</h1>
              </div>
              {/* <h1 className="font-normal text-base py-4">
                Please share your thoughts for our meeting (optional)
              </h1>
              <textarea className=" max-w-full h-20 max-h-max block  rounded-md bg-white shadow  border-4 border-transparent px-2" onChange={handleFormChange} name="thoughts"></textarea> */}
              <h1 className="font-normal text-base py-4">
                Payment Information
              </h1>
              <div className=" max-w-full h-max block  rounded-md bg-white shadow  border-4 border-transparent px-3 md:px-10 py-7">
                <div id="PaymentForm">
                  <Cards
                    cvc={formValues?.cvc}
                    expiry={formValues?.expiry}
                    focused={formValues?.focus}
                    name={formValues?.holderName}
                    number={formValues?.cardNumber}
                    flipped={isFlipped}
                  />
                  <div className="col-span-2 block w-full">
                    <div className="relative">
                      <label for="fname" className=" text-gray-500">
                        Card Number
                      </label>
                      <span className="absolute inset-y-0 right-0 pr-10 flex items-center">
                      </span>
                      <input
                        required
                        type="text"
                        onChange={handleFormChange}
                        onInput={handleCardNumberInput}
                        name="cardNumber"
                        id="cardNumber"
                        placeholder="4242424242424242"
                        className="block w-full rounded-md bg-white shadow border-4 border-transparent px-3 py-4 pl-5"
                      />
                      {formErrors.cardNumber && (
                        <p className=" h-5 absolute bottom-[-30px] left-5 text-sm text-red-500">
                          {formErrors.cardNumber}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-2 md:col-span-1">
                    <div className="relative">
                      <label for="fname" className=" text-gray-500">
                        Card Holder Name
                      </label>
                      <input
                        required
                        type="text"
                        name="holderName"
                        onChange={handleFormChange}
                        id="holderName"
                        placeholder="Enter Card Holdar Name"
                        autoComplete="name"
                        className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-4 pl-5"
                      />
                      {formErrors.holderName && (
                        <p className=" h-5 absolute bottom-[-30px] left-5 text-sm text-red-500">
                          {formErrors.holderName}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-2 md:col-span-1">
                    <div className="relative">
                      <label for="fName" className=" text-gray-500">
                        Expiry (Month/Year)
                      </label>
                      <input
                        required
                        type="text"
                        name="expiry"
                        onInput={handleMonthInput}
                        onChange={handleFormChange}
                        id="expiry"
                        placeholder="0826"
                        value={formValues?.expiry}
                        autoComplete="expiry"
                        className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-4 pl-5 expiry"
                      />
                      {formErrors.expiry && (
                        <p className=" h-5 absolute bottom-[-30px] left-5 text-sm text-red-500">
                          {formErrors.expiry}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-span-2 md:col-span-1">
                    <div className="relative">
                      <label for="fname" className=" text-gray-500">
                        CVV
                      </label>
                      <input
                        required
                        type="text"
                        onInput={handleCvcInput}
                        onChange={handleFormChange}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        name="cvc"
                        id="cvc"
                        placeholder="586"
                        autoComplete="cvc"
                        className="block w-full rounded-md bg-white shadow  border-4 border-transparent px-3 py-4 pl-5"
                      />
                      {formErrors.cvc && (
                        <p className=" h-5 absolute bottom-[-30px] left-5 text-sm text-red-500">
                          {formErrors.cvc}
                        </p>
                      )}
                    </div>
                  </div>

                </div>
              </div>
              <div className="payBtnMain">

                {
                  loading ?
                    <>
                      <Button disabled style={{ width: "10rem", color: "white" }}>{<CircularProgress size={18} className="icon" />} Submit</Button>
                    </>
                    :
                    <PrimaryButton
                      heading={"Submit"}
                      primaryFun={handleConfirmPayment}
                    />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallJoiningForm;
