import React from 'react'
import { toast } from "react-toastify";
import cardImage from '../../../Assets/Images/card-img.jpg'
import PrimaryHeading from '../../../Components/Atoms/PrimaryHeading/PrimaryHeading'
import { useEffect, useState } from "react";
import { AiOutlineClockCircle } from 'react-icons/ai';
import { MdLocationPin } from 'react-icons/md';
import { GetAllEventsAPI } from "API/events";
import './AboutEvents.scss'



const AboutEvents = () => {

const [events, setEvents] = useState([])
const [loading, setLoading] = useState(false)
const [toggleMarquee, setToggleMarque] = useState(true);

const gettingAllEvents = async () => {

    setLoading(true)
    const res = await GetAllEventsAPI()
    if (res.error != null) {
      toast.error(res?.error)
    } else {
      let eventData = res?.data?.result || []
      setEvents(eventData)
    }
    setLoading(false)
  }
  useEffect(() => {
    gettingAllEvents()
  }, [])

    return (
        <div className='events-container'>
            <div className="container">
                <div className="events">
                    <div className="header">
                        <div className="event">Our Events</div>
                        <PrimaryHeading heading="Our Upcoming event" />
                    </div>
                     <div className="cards">
                     {events?.length >= 1 ?
                <>
                  {events.map((event) => (
                        <div className="card">
                            <div className="img">
                                <img src={event?.image?.url} alt="" />
                                <div className="badge">
                                    <span>25</span>
                                    <span>Nov</span>
                                </div>
                            </div>
                            <div className="content">
                                <div className="heading"> {event?.title}</div>
                                <div className="bio">
                                    <div className="time"><AiOutlineClockCircle className='icon' /> 8:00 AM - 5:00 PM</div>
                                    <div className="time"><MdLocationPin /> New York City</div>
                                </div>
                                <div className="para">
                                {event?.description}
                                </div>
                                <div className="link cursor">Learn More...</div>

                            </div>
                        </div>
                ))}
                </>
                :
                <>
                <div className="card">
                    <h2>NO  Data Found</h2>
                </div>
                </>
                        }
                        
                    </div> 
                </div>
            </div>

        </div>
    )
}

export default AboutEvents;
