import { createSlice } from "@reduxjs/toolkit";

let localUserData = localStorage.getItem("madrasaUserData")
const initialState = localUserData && localUserData != undefined && localUserData != "undefined" ? JSON.parse(localUserData) : null


export const UserData = createSlice({
    name: "UserData",
    initialState,
    reducers: {
        setUserData: (state, action) => {
            return action.payload
        }
    }

})

export default UserData.reducer;
export const userDataActions = UserData.actions;